import React, {useEffect} from 'react'
function useOutsideAlerterMenu(refe, setState) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutsideMenu(event) {
          if (refe.current && !refe.current.contains(event.target)) {
            setState(false)
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutsideMenu);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutsideMenu);
        };
      }, [refe]);
}
  export default useOutsideAlerterMenu;