import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';

const goToUnLinkCat = (dataCatToLink, entId, ) => {
  console.log('antes de enviar', dataCatToLink);
  
    return axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/v1/category/${entId}/unlink`, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
        data: {
          categories: dataCatToLink
        }
    })
}

export default goToUnLinkCat