import React, { useContext, useState } from 'react'
import UpdateAndPublish from '../../../../assets/css/components/admin/update-and-publish/UpdateAndPublis'
import { BiAnalyse, BiCloudRain, BiEdit, BiPlay, BiRefresh, BiSelectMultiple, } from 'react-icons/bi'
import masiveScraping from '../functions/masiveScraping';
import axios from 'axios';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import pausePublications from '../functions/pausePublications';
import activePublications from '../functions/activatePublications';
import updatePricesAndStocks from '../functions/updatePricesAndStocks';
import updateDescriptions from '../functions/updateDescriptions';
export default function ButonActions(props) {
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const globalContext = useContext(GlobalContext)
    const { 
            tipoAction, setProductsSelecteds, 
            setProductsSelectedsWithErrors, 
            dataCard, setDataCard, productSelecteds,
            productSelectedsWithErrors, setTotalScraped, 
            totalScraped, setTotalPaused, setTotalPausedVariants,
            totalPaused, totalPausedVariants, isLoader,
            setIsLoader, setTotalUpdatedVariants, setTotalUpdated, templateDesc,
            deliveryDaysPlusValue
        } = props;

    // method for add products with errors
    const addProductsWithErrors = () => {
        const arrayProductsWithError = dataCard.filter(v => v.hasError === true || v.noMlData);
        if(arrayProductsWithError.length > 0) {
            console.log('se agg con ', arrayProductsWithError)
            setProductsSelectedsWithErrors(arrayProductsWithError)
        }else {
            return
        }
    }
    //method for add products without errors
    const addProductsWithOutErrors = () => {
        const arrayProductsWithOutError = dataCard.filter(v => !v.hasError && !v.noMlData);
        if(arrayProductsWithOutError.length > 0) {
            console.log('se agg sin',arrayProductsWithOutError)
            setProductsSelecteds(arrayProductsWithOutError)
        }else {
            return
        }
    }

    const clearAllProductsAdds = () => {
        setProductsSelecteds([]);
        setProductsSelectedsWithErrors([]);
    }
    const iconCard = (valor) => {
        switch (valor) {
            case "2":
                return (
                    <UpdateAndPublish.BtnAction disabled={isLoader} isLoader={isLoader}  onClick={
                            () => masiveScraping( 
                                globalContext.currentEnterpriseId, 
                                dataCard, setDataCard, productSelecteds, 
                                productSelectedsWithErrors, axiosCancelTokenSource, 
                                globalContext, setTotalScraped, totalScraped, setIsLoader
                                )}>
                        <UpdateAndPublish.ContIconBtnAction>
                            <BiAnalyse />
                        </UpdateAndPublish.ContIconBtnAction>
                        <UpdateAndPublish.Text size="12pt" fw="400">
                            Escrapear seleccionados
                        </UpdateAndPublish.Text>
                    </UpdateAndPublish.BtnAction>
                );
            case "3":
                return (
                    <UpdateAndPublish.BtnAction disabled={isLoader} isLoader={isLoader} 
                        onClick={
                            () => updatePricesAndStocks(
                                    globalContext.currentEnterpriseId, productSelecteds,  
                                    productSelectedsWithErrors, dataCard,
                                    setDataCard, setTotalUpdated, setTotalUpdatedVariants, setIsLoader, deliveryDaysPlusValue)}>
                        <UpdateAndPublish.ContIconBtnAction>
                            <BiRefresh />
                        </UpdateAndPublish.ContIconBtnAction>
                        <UpdateAndPublish.Text size="12pt" fw="400">
                            Actualizar en ML
                        </UpdateAndPublish.Text>
                    </UpdateAndPublish.BtnAction>
                )
            case "4":
                return (
                    <UpdateAndPublish.BtnAction disabled={isLoader} isLoader={isLoader}>
                        <UpdateAndPublish.ContIconBtnAction>
                            <BiCloudRain />
                        </UpdateAndPublish.ContIconBtnAction>
                        <UpdateAndPublish.Text size="12pt" fw="400">
                            Actualizar en ML y Escrapear
                        </UpdateAndPublish.Text>
                    </UpdateAndPublish.BtnAction>
                );
            case "5":
                return (
                    <UpdateAndPublish.BtnAction 
                        disabled={isLoader} isLoader={isLoader}
                        onClick={() => updateDescriptions(
                                globalContext.currentEnterpriseId, 
                                productSelecteds, productSelectedsWithErrors, 
                                dataCard, setDataCard, setTotalUpdated, 
                                setTotalUpdatedVariants, setIsLoader,templateDesc)}
                        >
                        <UpdateAndPublish.ContIconBtnAction>
                            <BiEdit />
                        </UpdateAndPublish.ContIconBtnAction>
                        <UpdateAndPublish.Text size="12pt" fw="400">
                            Actualizar Descripcion en ML
                        </UpdateAndPublish.Text>
                    </UpdateAndPublish.BtnAction>
                )
            case "6":
                return (
                    <UpdateAndPublish.BtnAction disabled={isLoader} isLoader={isLoader} onClick={() => pausePublications(
                        globalContext.currentEnterpriseId,
                        productSelecteds,
                        productSelectedsWithErrors,
                        dataCard, setDataCard,
                        setTotalPaused, setTotalPausedVariants, setIsLoader)}>
                        <UpdateAndPublish.ContIconBtnAction>
                            <BiPlay />
                        </UpdateAndPublish.ContIconBtnAction>
                        <UpdateAndPublish.Text size="12pt" fw="400">
                            Pausar Publicaciones
                        </UpdateAndPublish.Text>
                    </UpdateAndPublish.BtnAction>
                );
            case "7":
                    return (
                        <UpdateAndPublish.BtnAction disabled={isLoader} isLoader={isLoader} onClick={() => addProductsWithErrors()}>
                            <UpdateAndPublish.ContIconBtnAction>
                                <BiSelectMultiple />
                            </UpdateAndPublish.ContIconBtnAction>
                            <UpdateAndPublish.Text size="12pt" fw="400">
                                Seleccionar con Errores
                            </UpdateAndPublish.Text>
                        </UpdateAndPublish.BtnAction>
                    );
            case "8":
                return (
                    <UpdateAndPublish.BtnAction disabled={isLoader} isLoader={isLoader} onClick={() => addProductsWithOutErrors()}>
                        <UpdateAndPublish.ContIconBtnAction>
                            <BiSelectMultiple />
                        </UpdateAndPublish.ContIconBtnAction>
                        <UpdateAndPublish.Text size="12pt" fw="400">
                            Seleccionar sin Errores
                        </UpdateAndPublish.Text>
                    </UpdateAndPublish.BtnAction>
                );
            case "9":
                return (
                    <UpdateAndPublish.BtnAction disabled={isLoader} isLoader={isLoader} onClick={() => clearAllProductsAdds()}>
                        <UpdateAndPublish.ContIconBtnAction>
                            <BiSelectMultiple />
                        </UpdateAndPublish.ContIconBtnAction>
                        <UpdateAndPublish.Text size="12pt" fw="400">
                            Borrar Seleccion
                        </UpdateAndPublish.Text>
                    </UpdateAndPublish.BtnAction>
                );

            case "10":
                return (
                    <UpdateAndPublish.BtnAction disabled={isLoader} isLoader={isLoader} onClick={() => activePublications(
                        globalContext.currentEnterpriseId,
                        productSelecteds,
                        productSelectedsWithErrors,
                        dataCard, setDataCard,
                        setTotalPaused, setTotalPausedVariants,
                        totalPaused, totalPausedVariants, setIsLoader
                        )}>
                        <UpdateAndPublish.ContIconBtnAction>
                            <BiPlay />
                        </UpdateAndPublish.ContIconBtnAction>
                        <UpdateAndPublish.Text size="12pt" fw="400">
                            Activar Publicaciones
                        </UpdateAndPublish.Text>
                    </UpdateAndPublish.BtnAction>
                );
            default:
                return;
        }
    }
    return iconCard(tipoAction)
}
