import React, {useState, useEffect } from 'react'
import CronReportsStyle from '../../../../assets/css/components/admin/cron-reports/CronReportsStyle'
import DateTime from '../components/DateTime';
import { BiSearchAlt2 } from "react-icons/bi";
import ListErrors from '../components/ListErrors';
import { useContext } from 'react';
import getDataErrorCron from '../functions/getDataerrorCron';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { Spinner } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import Routes from '../../../../Routes';
import verifyAccessRole from '../../../../assets/js/verifyAccessRole';
export default function CronErrors() {
    const [dateOne, setDataeOne] = useState('');
    const [dateTwo, setDataeTwo] = useState('');
    const [data, setData] = useState(null);
    const [noData, setNoData] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [countData, setCountData] = useState(0);
    const [typeReport, setTypeReport] = useState(1);
    const globalContext = useContext(GlobalContext);
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 16);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);

    const getData = async () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg(
                `Seleccione una empresa!`
                );
            };
            
        if (dateOne.length === 0) {
            globalContext.showModalWithMsg(
                `Seleccione una fecha de inicio!`
                );
            };
        if (dateTwo.length === 0) {
            globalContext.showModalWithMsg(
                `Seleccione una fecha de fin!`
                );
            };
        setIsLoad(true);         
        await getDataErrorCron(dateOne, dateTwo, entId, typeReport)
            .then(res => {
                console.log(res)
                const data = res.data.data.products;
                const totalProducts = res.data.data.count;
                if (data.length === 0) {
                    setNoData(true)
                    console.log('la data', data)
                    setIsLoad(false);
                } else {
                    setCountData(totalProducts);
                    setData(data);
                    setNoData(false);
                    setIsLoad(false);
                }
            })
            .catch(err => {
                console.log('error', err)
            })
    }

    return (
        <CronReportsStyle.Cont>
            {redirect}
            <CronReportsStyle.Header>
                <CronReportsStyle.Text size="15pt" fw="600">
                    Reporte del Cron
                </CronReportsStyle.Text>
            </CronReportsStyle.Header>
            <CronReportsStyle.ContHeaderOptions>
                <CronReportsStyle.ContInputAndLabel>
                    <CronReportsStyle.Text>
                        Tipo de Reporte
                    </CronReportsStyle.Text>
                    <CronReportsStyle.contInputRadio>
                        <CronReportsStyle.inputRadio type="radio" checked={typeReport === 1} onChange={() => setTypeReport(1)}/>
                            <label htmlFor="st1" className="mr-2">Errores</label>
                        <CronReportsStyle.inputRadio type="radio" checked={typeReport === 2} onChange={() => setTypeReport(2)} />
                            <label htmlFor="st2">Actualizados</label>
                        </CronReportsStyle.contInputRadio>
                </CronReportsStyle.ContInputAndLabel>
                <CronReportsStyle.ContInputAndLabel mr="10px">
                    <CronReportsStyle.Text size="12pt">
                        Fecha Desde
                    </CronReportsStyle.Text>
                    <DateTime
                        setValue={setDataeOne}
                        valueDefaultText="Fecha Desde"
                    />
                </CronReportsStyle.ContInputAndLabel>
                <CronReportsStyle.ContInputAndLabel mr="10px">
                    <CronReportsStyle.Text size="12pt">
                        Fecha Hasta
                    </CronReportsStyle.Text>
                    <DateTime
                        setValue={setDataeTwo}
                        valueDefaultText="Fecha de Hasta"
                    />
                </CronReportsStyle.ContInputAndLabel>
                <CronReportsStyle.ButtonSearch onClick={() => getData()}>
                    <BiSearchAlt2 />
                </CronReportsStyle.ButtonSearch>
            </CronReportsStyle.ContHeaderOptions>
            <CronReportsStyle.ContBodyList>
                <CronReportsStyle.ContTotalData>
                    <CronReportsStyle.Text cl="#FFFFFF" size="12pt" fw="500">
                        {`Total de Items: ${countData}`}
                    </CronReportsStyle.Text>
                </CronReportsStyle.ContTotalData>
                {
                    isLoad ? (
                        <CronReportsStyle.contLoader>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Cargando...</span>
                            </Spinner>
                        </CronReportsStyle.contLoader>
                    ):
                    data ?
                        (<ListErrors data={data} />)
                        :
                        (
                            !noData ?
                            (
                                <CronReportsStyle.Text fw="500" size="14pt">
                                    Agrege un rango de fechas
                                </CronReportsStyle.Text>
                            ) :
                            (
                                <CronReportsStyle.Text fw="500" size="14pt">
                                    No se encontro ningun registro para este rango de fechas!
                                </CronReportsStyle.Text>
                            )
                            

                        )
                }
            </CronReportsStyle.ContBodyList>
        </CronReportsStyle.Cont>
    )
}
