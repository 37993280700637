import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Routes from "../../../Routes";

export default function MyPlugins() {
  return (
    <Switch>
      {Routes.map((prop, key) => {
        if (prop.father === "/plugins/my-plugins") {
          return (
            <Route
              path={prop.layout + prop.father + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect
        from="/admin/plugins/my-plugins"
        to="/admin/plugins/my-plugins/my-menu-plugins"
      />
    </Switch>
  );
}
