import axios from 'axios';
import { keyRefreshToken, keyAccessToken, keyUserId } from '../js/SessionStorageNames'

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger

    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    
    //If token expired
    if (error.response?.data?.statusCode === "10003") {
      console.log('Expiro el token de acceso');

      axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/token/refresh`, {
        refreshToken: localStorage.getItem(keyRefreshToken)
      }, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'x-access-token': localStorage.getItem(keyAccessToken),
          'x-user-id': localStorage.getItem(keyUserId),
        }
      })
        .then(resp => {
          localStorage.setItem(keyAccessToken, resp.data.accessToken);
          localStorage.setItem(keyRefreshToken, resp.data.refreshToken);

          //Resend the petition
          if (error.config) {
            error.config.headers['x-access-token'] = resp.data.accessToken;
            return axios.request(error.config);
          }
        })
        .catch(err => console.error(err));
    }
    return Promise.reject(error);
  });