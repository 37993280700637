
import React, {useRef, useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import RangosStepsCss from '../../../../../../assets/css/components/admin/products/ml/rangos-publicacion/RangosStepsCss';
import { GlobalContext } from '../../../../../../contexts/GlobalContext';

function One(props) {
    const globalContext = useContext(GlobalContext);
    const {
        tariff, 
        dataSave,
        amount,
        arrayItemsByDelete,
        porcentaje,
        setTariff,
        setDataSaveOne,
        setAmountOne,
        setPorcentajeOne,
        setArrayItemsByDeleteOne,
        isLoader
    } = props;

    const amountInput = useRef(null);
    const porcentajeRef = useRef(null);

    const setDataSave = (v) => {
        setDataSaveOne(v)
    }
    const setAmount = (v) => {
        setAmountOne(v)
    }
    const setPorcentaje = (v) => {
        setPorcentajeOne(v)
    }
    const setArrayItemsByDelete = (v) => {
        setArrayItemsByDeleteOne(v)
    }

    const addTariffArray = (v) => {
        setTariff(v)
    }

    const cleanInputsAndState = () => {
        globalContext.setCategorieTextRateMlAm('');
        globalContext.setCategorieIdRateMlAm('');
        amountInput.current.value = ''
        porcentajeRef.current.value = true
        setAmount('')
        setPorcentaje(true)
    }
    const addValueToTable = () => {
        setArrayItemsByDelete([])
        /**
        * * primero validamos que los state no esten vacios
        */
        if(amount.length === 0 && globalContext.categorieIdMlAm.length === 0 ) {
            alert('Sorry, these values cannot be empty')
        }else {
        //addTariffArray()
        setDataSave([...dataSave, {
            categoryText: globalContext.categorieTextMlAm,
            categoryId: globalContext.categorieIdMlAm,
            amount:parseInt(amount),
            isPercent:porcentaje,
        }]);
        addTariffArray([...tariff, {
            categoryId: globalContext.categorieIdMlAm,
            amount:parseInt(amount),
            isPercent:porcentaje,
        }]);
        cleanInputsAndState();
        }
    }

    const addItemByDelete = (index) => {
        const isHere = arrayItemsByDelete.find((e) => (e === index));
        if (isHere === undefined) {
            setArrayItemsByDelete([...arrayItemsByDelete, index]);
        }else {
            setArrayItemsByDelete(arrayItemsByDelete.filter((e) => (e !== index)))
        }
        
    }
    const deleteAnyItem = () => {
        let itemDeleteTariff = [...tariff];
        let itemDelete = [...dataSave];
        let arr = itemDelete.filter(item => !arrayItemsByDelete.includes(item.categoryId));
        let arr2 = itemDeleteTariff.filter(item => !arrayItemsByDelete.includes(item.categoryId))
        setDataSave(arr);
        addTariffArray(arr2);
    }
    
    return (
        <RangosStepsCss.cont>
            {
                isLoader ?
                (
                    <RangosStepsCss.contLoader>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Cargando...</span>
                        </Spinner>
                    </RangosStepsCss.contLoader>
                ):
                (
                    <>
                        <RangosStepsCss.contInputsAndButtonsActions>
                            
                            <RangosStepsCss.contInputAndLabel>
                                <RangosStepsCss.text fw="bolder" size="13pt" mb="5px">
                                    Categoria para Amazom
                                </RangosStepsCss.text>
                                <RangosStepsCss.btnSelecCategory onClick={() => globalContext.setActiveModalMlAm(true)}>
                                    {
                                        globalContext.categorieTextMlAm.length > 0 ? globalContext.categorieTextMlAm : 'Seleccione una categoria...' 
                                    }
                            </RangosStepsCss.btnSelecCategory>
                            </RangosStepsCss.contInputAndLabel>

                            <RangosStepsCss.contInputAndLabel>
                                <RangosStepsCss.text fw="bolder" size="13pt" mb="5px">
                                    Monto($)
                                </RangosStepsCss.text>
                                <RangosStepsCss.input placeholder="Amount($)" onChange={(e)=> setAmount(e.target.value)}  ref={amountInput} type="number"/>
                            </RangosStepsCss.contInputAndLabel>

                            <RangosStepsCss.contInputAndLabel jsc="space-between">
                                <RangosStepsCss.text fw="bolder" mb="5px">
                                    ¿Es un porcentaje?
                                </RangosStepsCss.text>
                                <RangosStepsCss.contInputRadio>
                                    <RangosStepsCss.inputRadio type="radio" id="tp1" value={true} defaultChecked={porcentaje } name="tipoPublicacion" onChange={(e) =>  setPorcentaje(true)} ref={porcentajeRef}/>
                                    <label htmlFor="tp1">Si</label>
                                    <RangosStepsCss.inputRadio type="radio" id="tp2" value={false} defaultChecked={!porcentaje} name="tipoPublicacion" ml="10px" onChange={(e) =>  setPorcentaje(false)} ref={porcentajeRef}/>
                                    <label htmlFor="tp2">No</label>
                                </RangosStepsCss.contInputRadio>
                            </RangosStepsCss.contInputAndLabel>

                            <RangosStepsCss.contInputAndLabel jsc="flex-end" fld="row" ali="flex-end">
                                <RangosStepsCss.btn mr="10px" onClick={() => addValueToTable()}>
                                    Agregar
                                </RangosStepsCss.btn>
                                <RangosStepsCss.btn 
                                    bg="#FFFFFF"
                                    color="#EA5455"
                                    bdc="#EA5455"
                                    bgHover="#EA5455"
                                    colorHover="#FFFFFF"
                                    onClick={() => {deleteAnyItem();setArrayItemsByDelete([])}}
                                                    >
                                    Eliminar
                                </RangosStepsCss.btn>
                            </RangosStepsCss.contInputAndLabel>

                        </RangosStepsCss.contInputsAndButtonsActions>
                        <div className="table-responsive">
                        <RangosStepsCss.tableRangos className="table table-bordered ">
                            <thead>
                                <tr>
                                    <th className="input-td " scope="col">
                                    </th>
                                    <th scope="col">
                                        Numero
                                    </th>
                                    <th scope="col">
                                        Categoria de Amazom
                                    </th>
                                    <th scope="col">
                                        Monto ($)
                                    </th>
                                    <th scope="col">
                                        ¿Es un porcentaje?
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataSave.length > 0 && 
                                    dataSave.map((e, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className="input-td">
                                                    <RangosStepsCss.inputChecbox 
                                                    type="checkbox"  
                                                    id={`id-input-${e.categoryId}`} 
                                                    onChange={() => addItemByDelete(e.categoryId)}
                                                    checked={arrayItemsByDelete.includes(e.categoryId)}
                                                    />
                                                </td>
                                                <td>
                                                    {i + 1}
                                                </td>
                                                <td>
                                                    {e.categoryText}
                                                </td>
                                                <td>
                                                {e.amount} $
                                                </td>
                                                <td>
                                                    {e.isPercent ? 'Si' : 'No'}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </RangosStepsCss.tableRangos>
                        </div>
                    </>
                )
            }
        </RangosStepsCss.cont>
    );
}
export default One;