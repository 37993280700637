import React, { useContext, useEffect, useState } from 'react'
import ConfigStyled from '../../../../assets/css/components/admin/config/ConfigStyled'
import axios from 'axios'
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
export default function One() {
    const globalContext = useContext(GlobalContext);
    const [pluginOptions, setPluginOptions] = useState([]);
    const [actualSelectedPlugin, setActualSelectedPlugin] = useState("");
    const [selectedPlugin, setSelectedPlugin] = useState("");
    const [somethingChanged, setSomethingChanged] = useState(false);
    const [usdRate, setUsdRate] = useState('');
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [isSending, setIsSending] = useState(false);

    /**
     * Change the selected plugin
     * @param {Event} e
     */
    const updateSelectedPlugin = (e) => {
        const selected = e.target.value;

        if (selected !== actualSelectedPlugin && selected !== "") {
            setSomethingChanged(true);
        } else {
            setSomethingChanged(false);
        }

        setSelectedPlugin(selected);
    };

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };
    /**
     * Send the new main plugin to the backend
     */

    const updateUsdRate = () => {
        const entId = globalContext.currentEnterpriseId;
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/general`, {
            config: {
                usdRate: usdRate,
            }
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));

    }

    const getUsdRate = () => {
        const entId = globalContext.currentEnterpriseId;
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/general`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => {
            const data = resp.data.data;
            //console.log(data);
            setUsdRate(data.configData.usdRate)
        }).catch(err => handleAxiosError(err));

    }

    const updateMainPlugin = (e) => {
        setIsSending(true);
        updateUsdRate()
        axios
            .put(
                `${process.env.REACT_APP_BACKEND_URL}/v1/main-plugin/`,
                {
                    plugin: selectedPlugin,
                },
                {
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((res) => {
                setActualSelectedPlugin(selectedPlugin);
                setSomethingChanged(false);
                globalContext.showModalWithMsg(
                    "Datos guardados exitosamente"
                );
            })
            .catch((err) => {
                globalContext.showModalWithMsg(
                    "Disculpe ha ocurrido un error al intentar actualizar el plugin principal"
                );
                if (err.response) {
                    globalContext.showModalWithMsg(err.response.data);
                } else {
                    globalContext.showModalWithMsg(err);
                }
            })
            .finally(() => setIsSending(false));
    };

    /**
     * Load the main plugin options
     */
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        getUsdRate();
        setIsSending(true)
        axios({
            method: "get",
            url: `${process.env.REACT_APP_BACKEND_URL}/v1/main-plugin/available`,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
            .then((res) => {
                setPluginOptions(res.data.data);
                setIsSending(false)
            })
            .catch((err) => {
                globalContext.showModalWithMsg(
                    "Disculpe ha ocurrido un error al intentar cargar la lista de plugins"
                );
                setIsSending(false)
                if (err.response) {
                    console.log(err.response.data);
                } else {
                    console.log(err);
                }
            });
        //eslint-disable-next-line
    }, [globalContext.currentEnterpriseId]);

    /**
     * Load the currently selected main plugin
     */
    useEffect(() => {
        setIsSending(true)
        axios({
            method: "get",
            url: `${process.env.REACT_APP_BACKEND_URL}/v1/main-plugin/`,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
            .then((res) => {
                setActualSelectedPlugin(res.data.data);
                setSelectedPlugin(res.data.data);
                setIsSending(false);
            })
            .catch((err) => {
                setIsSending(false);
                globalContext.showModalWithMsg(
                    "Disculpe ha ocurrido un error al intentar cargar el plugin principal actual"
                );
                if (err.response) {
                    console.log(err.response.data);
                } else {
                    console.log(err);
                }
            });
    }, []);

    const updatePriceByRate = () => {
        const entId = globalContext.currentEnterpriseId;

        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        const processCheckIntervalTime = 5000;
        let processModitorId = 0;
        setIsSending(true)
        globalContext.setLoaderPage(true);
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/product-price-rate/${entId}/sync/amazonScrap`, {}, {
            headers,
        }).then((resp) => {
            const data = resp.data.data;

            if (data.monitor) {
                processModitorId = data.monitor;

                //If the process ID was received, start monitoring
                let waitingMonitorResponse = false;
                const interval = setInterval(() => {
                    if (!waitingMonitorResponse) {
                        waitingMonitorResponse = true;

                        axios
                            .get(
                                `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                                {
                                    cancelToken: axiosCancelTokenSource.token,
                                    headers,
                                }
                            )
                            .then((resp) => {
                                const data = resp.data.data;

                                if (data.advance < 100) {
                                    const advance = data.advance.toFixed(2);
                                    globalContext.setLoaderPage(true, `${advance}% (${data.data.currentProduct}/${data.data.totalProducts})`);
                                } else {
                                    clearInterval(interval);
                                    globalContext.setLoaderPage(false);
                                    setIsSending(false)
                                    const errors = data.data.errors;

                                    if (errors && errors.length) {
                                        const msg = (<div>
                                            Durante la actualización se encontraron los siguientes errores:
                                            {errors.map((e, eIdx) => (<span key={eIdx}><br />{e}</span>))}
                                        </div>)
                                        globalContext.showModalWithMsg(msg)
                                    }
                                }
                            })
                            .catch((err) => {
                                handleAxiosError(
                                    err,
                                    "Disculpe, no se pudo culminar la actualizacion de los productos."
                                );
                                clearInterval(interval);
                                globalContext.setLoaderPage(false);
                                setIsSending(false)
                            })
                            .finally(() => {
                                waitingMonitorResponse = false;
                            });
                    }
                }, processCheckIntervalTime);
            } else {
                globalContext.setLoaderPage(false);
                setIsSending(false)
            }
        }).catch(err => {
            handleAxiosError(err)
            globalContext.setLoaderPage(false);
            setIsSending(false)
        });
    }
    return (
        <ConfigStyled.ContForm isSingle={true}>
            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size="12pt">
                    Plugin por defecto
                </ConfigStyled.text>
                <ConfigStyled.select 
                    value={selectedPlugin}
                    disabled={isSending}
                    onChange={updateSelectedPlugin}>
                    <option value="">
                        Seleccione uno...
                    </option>
                    {
                        pluginOptions.map((opt) => {
                        return (
                            <option key={opt.id} value={opt.id}>
                            {opt.name}
                            </option>
                        );
                        })
                    }
                </ConfigStyled.select>
            </ConfigStyled.ContInputAndLabel>

            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size="12pt">
                    Tasa del Dolar
                </ConfigStyled.text>
                <ConfigStyled.input
                    readOnly={isSending}
                    value={usdRate || ''}
                    onChange={(e) => setUsdRate(e.target.value)} 
                    placeholder="coloque su tasa del dolar..." />
            </ConfigStyled.ContInputAndLabel>

            <ConfigStyled.ARow jc="space-between">
                <ConfigStyled.btnSend onClick={() => updatePriceByRate()} disabled={isSending}>
                    Actualizar Precios
                </ConfigStyled.btnSend>
                <ConfigStyled.btnSend onClick={updateMainPlugin} disabled={isSending}>
                    Guardar
                </ConfigStyled.btnSend>
            </ConfigStyled.ARow>
        </ConfigStyled.ContForm>
    )
}
