import React, { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import DebugScreenStyle from "../../../assets/css/components/admin/debug/DebugScreen";
import Pager from "../../general/Pager";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Routes from "../../../Routes";
import verifyAccessRole from "../../../assets/js/verifyAccessRole";
function MyVerticallyCenteredModal(props) {
  const { closeModal, stack, doing } = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Logs del sistema
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="body-stack">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h2>Stack</h2>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {stack}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h2>Doing</h2>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {doing}
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeModal}
          className="btn-modal-logs"
          style={{ background: "#5e548e", border: "none" }}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}


export default function DebugScreen() {
  const globalContext = useContext(GlobalContext);
  const pageItemCount = 20;
  const axios = require("axios");
  const accessTokenKey = localStorage.getItem("accessTokenKey");
  const idUser = localStorage.getItem("_id");
  const [totalErrors, setTotalErrors] = useState(0);
  const [datos, setDatos] = useState([]);
  const [filter, setFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [modalShow, setModalShow] = useState(false);
  const [modalMsgOne, setModalMsgOne] = useState('')
  const [modalMsgTwo, setModalMsgTwo] = useState("");
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const hasAccess = verifyAccessRole(Routes, 54);
    if(!hasAccess) {
        setRedirect(
            <Redirect to={`/admin/dashboard`} />
        )
    }
},[]);



  const ViewStack = (stack, doing) => {
    setModalMsgOne(stack);
    setModalMsgTwo(doing);
    setModalShow(true);
  }
  
  const closeModal = () => {
    setModalShow(false);
  }


  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/api-error/`,
      params: {
        pageNumber: currentPage,
        pageItemCount: pageItemCount,
      },
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": accessTokenKey,
        "x-user-id": idUser,
      },
    })
      .then((res) => {
        console.log(res)
        const data = res.data.data;
        setDatos(data.errors);
        setTotalErrors(data.totalErrors);
      })
      .catch((err) => {
        globalContext.showModalWithMsg(
          "Disculpe ha ocurrido un error al intentar cargar los bugs del sistema"
        );
        if (err.response) {
          console.log(err.response.data);
        } else {
          console.log(err);
        }
      });
    //eslint-disable-next-line
  }, [filter, currentPage]);



  return (
    <DebugScreenStyle>
      {redirect}
      <MyVerticallyCenteredModal
        show={modalShow}
        closeModal={closeModal}
        stack={modalMsgOne}
        doing={modalMsgTwo}
      />
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="p-0">
            <div className="limiter">
              <div className="container-table100">
                <div className="wrap-table100 col-12">
                  <div className="table100">
                    <table>
                      <thead>
                        <tr className="table100-head">
                          <th className="column1" colSpan="2.5">
                            DATA
                            </th>
                          <th className="column2" colSpan="2.5">
                            ERROR
                            </th>
                        </tr>
                        <tr>
                          <th className="column3">URL</th>
                          <th className="column4">IP</th>
                          <th className="column5">USER</th>
                          <th className="column6">MESSAGE</th>
                          <th className="column7"> </th>
                        </tr>
                      </thead>
                      <tbody>
                        {datos.map((value) => (
                          <tr
                            key={value._id}
                          >
                            <td className="column3">
                              {value.data.method}: {value.data.url}
                            </td>

                            <td className="column4">{value.data.ip}</td>

                            <td className="column5">
                              {value.user && (
                                <div>
                                  <span>{value.user.name}</span>
                                  <span>{value.user.email}</span>
                                  <span>{value.user.roles.join(", ")}</span>
                                </div>
                              )}
                            </td>

                            <td className="column6">{value.error.message}</td>

                            <td className="column8">
                              <button
                                className="btn-action-trash"
                                onClick={() => {
                                  ViewStack(value.error.stack, "")
                                }}
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            xl={12}
            lg={12}
            className="d-flex justify-content-center mt-2"
          >
            <Pager
              handleSetPage={setCurrentPage}
              totalResults={totalErrors}
              currentPage={currentPage}
              resultsPerPage={pageItemCount}
            />
          </Col>
        </Row>
      </Container>
    </DebugScreenStyle>
  );
}
