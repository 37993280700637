import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';

const getDataReport = (entId) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/category/${entId}/report/amazonScrap`, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
}

export default getDataReport;