import React, { useState } from 'react'
import { useContext } from 'react';
import { ContainerRemoveBackground, ContMenu, InputFile, ButtonSend, ContCards, ContImages } from '../../../../assets/css/components/admin/remove-background/RemoveBackground'
import { GlobalContext } from '../../../../contexts/GlobalContext';
import saveMany from '../functions/saveMany';
import processImg from '../functions/processImg';


export default function IndexRemoveBackground() {
    const [listUrl, setListUrl] = useState([]);
    const [listurlToSend, setListurlToSend] = useState([]);
    const [readyToProcess, setReadyToProcess] = useState(false);
    const globalContext = useContext(GlobalContext);

    const seleccImage = async (e) => {
        //setFile(fileSelect[0])
        console.log('valores', typeof e.target.files);
        let copyArr = [...listUrl];
        let copyArrToSend = [...listurlToSend];

        const objToArr = Object.keys(e.target.files)

        await objToArr.map(async (key,index) => {

            const url = e.target.files[index];
            console.log('valmos por ', e.target.files[index])
            const url2 = URL.createObjectURL(e.target.files[index]);
            
            //? para mostrar en el efront
            copyArr.push(url2)
            await getBase64(url)
            .then((res) => {
                const strImage = res.replace(/^data:image\/[a-z]+;base64,/, "");
                copyArrToSend.push(strImage);
                
            }).catch(err => {
                console.log(err);
            });
            console.log('valorr', copyArrToSend)
            
            setListurlToSend(copyArrToSend);
            setListUrl(copyArr);
            
        });
    }

    const sendImgs = async () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        globalContext.setLoaderPage(true);
        if (listurlToSend.length === 0) return;
        try {
            const resVal = await saveMany(listurlToSend,entId);
            if (resVal.error && resVal.error.length > 0) {
                processImg(entId)
                .then((resp) => {
                    setListUrl([])
                    setListurlToSend([])
                    console.log(resp.data)
                    globalContext.showModalWithMsg(`Las ${resVal.hashSaved} imagenes se estan procesando en segundo plano con exito`);
                }).catch(err => {
                    setListUrl([])
                    setListurlToSend([])
                    globalContext.showModalWithMsg(`Ocurrio un error al procesar las img, ${err}`);
                })
                setReadyToProcess(true);
                globalContext.setLoaderPage(false);
            }else {
                processImg(entId)
                .then((resp) => {
                    setListUrl([])
                    setListurlToSend([])
                    console.log(resp.data)
                    globalContext.showModalWithMsg(`Las ${resVal.hashSaved} imagenes se estan procesando en segundo plano con exito`);
                }).catch(err => {
                    setListUrl([])
                    setListurlToSend([])
                    globalContext.showModalWithMsg(`Ocurrio un error al procesar las img, ${err}`);
                })
                globalContext.setLoaderPage(false);
                setReadyToProcess(true);
            }
            
        } catch (error) {
            globalContext.setLoaderPage(false);
            setReadyToProcess(false);
            globalContext.showModalWithMsg(`Ocurrio un error al enviar las img, ${error}`);
        }
    }

    const processImgFunc = () => {
        const entId = globalContext.currentEnterpriseId;
        try {
            processImg(entId)
            .then((resp) => {
                console.log(resp.data)
                globalContext.showModalWithMsg(`Las imagenes se estan procesando en segundo plano con exito`);
            }).catch(err => {
                globalContext.showModalWithMsg(`Ocurrio un error al procesar las img, ${err}`);
            })
            
        } catch (err) {
            globalContext.showModalWithMsg(`Ocurrio un error al procesar las img, ${err}`);
        }
    }

    const getBase64 = file => {
        return new Promise(resolve => {
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const deleteAImg = i => {
        const newArr = listUrl.filter((value, index) => index !== i);
        const newArrTwo = listurlToSend.filter((value, index) => index !== i);

        setListUrl(newArr);
        setListurlToSend(newArrTwo);
    }

    return (
        <ContainerRemoveBackground>
            <ContMenu>
                <InputFile>
                    <label className="file">
                        <input type="file" aria-label="File browser example" onChange={e => seleccImage(e)} multiple/>
                        <span className="file-custom"></span>
                    </label>
                </InputFile>
                {
                    (listUrl.length > 0) &&
                    (
                        <ButtonSend onClick={() => sendImgs()}>
                            Enviar
                        </ButtonSend>
                    )
                }
                
            </ContMenu>
            <ContCards>
                {
                    listUrl.length > 0 &&

                    listUrl.map((url, i) => {
                        return (
                            <ContImages url={url} key={i} onClick={() => deleteAImg(i)}/>
                        )
                    })
                }
            </ContCards>
        </ContainerRemoveBackground>
    )
}
