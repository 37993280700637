import React, { useState, useContext, useEffect } from "react";
import { BiX } from 'react-icons/bi'
import { GlobalContext } from "../../../contexts/GlobalContext";
import { Link } from "react-router-dom";

import {
  keyUserId,
  keyAccessToken,
} from "../../../assets/js/SessionStorageNames";

import axios from 'axios';
import FormNewUserStyle from "../../../assets/css/components/admin/config/FormNewUser";
import Select from "../../general/Select";
import { Redirect } from "react-router-dom";
import Routes from "../../../Routes";
import verifyAccessRole from "../../../assets/js/verifyAccessRole";


export default function UserConfigUpdateUserAdmin({ match }) {
  const globalContext = useContext(GlobalContext);
  const userId = match.params.id;
  const [redirect, setRedirect] = useState(null);
  
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [name, setName] = useState("");
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");
  const [showBadge, setShowBadge] = useState(false)
  const [isLoader, setIsLoader] = useState(false);
  const [rolSend, setRolSend] = useState(null);
  const [dailyGoalForKbt, setDailyGoalForKbt] = useState('');
  const [isKbtUser, setIsKbtuser] = useState(false);
  const [country, setcountry] = useState('');
  const rolesSend = [
    {id: 'SuperAdmin', name: 'SuperAdmin'},
    {id: 'Admin', name: 'Admin'},
    {id: 'Gerente', name: 'Gerente'},
    {id: 'Champion', name: 'Champion'},
    {id: 'Preguntas', name: 'Preguntas'},
  ];
  useEffect(() => {
    const hasAccess = verifyAccessRole(Routes, 10);
    if(!hasAccess) {
        setRedirect(
            <Redirect to={`/admin/dashboard`} />
        )
    }
},[]);
  /**
   *
   * @param {*} e
   * save user data
   */
  const saveUser = () => {
    globalContext.setLoaderPage(true);
    let data;
    if(password.length > 0) {
      if(password !== checkPassword) {
        setHasError(true)
        setError("Verifique su clave de acceso");
        setShowBadge(true)
      }else {
        data = {
          name: name,
          email: correo,
          rolRoute: rolSend,
          password: password,
          ...( dailyGoalForKbt.length > 0 && {dailyGoalForKbt:parseInt(dailyGoalForKbt)}),
          isKbtUser:isKbtUser,
          country:country
        };
      }
    }else {
      data = {
        name: name,
        email: correo,
        rolRoute: rolSend,
        dailyGoalForKbt:parseInt(dailyGoalForKbt),
        isKbtUser:isKbtUser,
        country:country
      };
    }

    setIsLoader(true);

    axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/user/admin/${userId}`,
      cancelToken: axiosCancelTokenSource.token,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
      data,
    })
      .then((res) => {
        setHasError(false)
        setShowBadge(true)
        setIsLoader(false);
      })
      .catch((err) => {
        setHasError(true)
        setIsLoader(false);
        if(err.response.data.message) {
          setError(err.response.data.message)
        }else {
          setError(err.response.data);
        }
        setShowBadge(true)
      })
      .finally(() => {
        globalContext.setLoaderPage(false);
      });
  };


  /**
   * Load the user data
   */
  useEffect(() => {
    globalContext.setLoaderPage(true);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/user/admin/${userId}`,
      cancelToken: axiosCancelTokenSource.token,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
    })
      .then((res) => {
        const data = res.data.data;
        setName(data.name);
        setCorreo(data.email);
        setRolSend(data.rolRoute);
        data.dailyGoalForKbt && setDailyGoalForKbt(data.dailyGoalForKbt)
        data.isKbtUser && setIsKbtuser(data.isKbtUser)
        data.country && setcountry(data.country)
      })
      .catch((err) => {
        setHasError(true);
        if(err.response) {
          setError(err.response.data.message)
        }else {
          setError(err.data)
        }
        setShowBadge(true);
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        console.log(err, err.response);
      })
      .finally(() => {
        globalContext.setLoaderPage(false);
      });
    //eslint-disable-next-line
  }, []);

  /**
   * Cancel axios requests before unmount
   */
  useEffect(() => {
    return () => {
      axiosCancelTokenSource.cancel("Canceled by unmount");
    };
    //eslint-disable-next-line
  }, []);

  const closeBadge = (e) => {
    e.preventDefault();
    setShowBadge(false);
}

  return (
    <FormNewUserStyle.ContForm>
      {redirect}
      <FormNewUserStyle.ContHeader>
        <Link to={`/admin/user-config/dashboard`}>
          <FormNewUserStyle.TextLink size="15pt" mr="10px" cl="#7367f0" fw="500">
            Volver
          </FormNewUserStyle.TextLink>
        </Link>
        <FormNewUserStyle.Text size="15pt" fw="500" mr="10px">
          |
        </FormNewUserStyle.Text>
        <FormNewUserStyle.Text size="15pt" fw="500">
          Formulario de Usuarios
        </FormNewUserStyle.Text>
      </FormNewUserStyle.ContHeader>
      <FormNewUserStyle.Form>
        <FormNewUserStyle.ContBadgeError active={showBadge} isError={hasError}>
          <FormNewUserStyle.Text size=".875rem" cl="#FFFFFF">
              {hasError ? error : 'Proceso finalizado con exito!'}
          </FormNewUserStyle.Text>
          <FormNewUserStyle.BtnToggleBadge onClick={(e) => closeBadge(e)}>
              <BiX />
          </FormNewUserStyle.BtnToggleBadge>
        </FormNewUserStyle.ContBadgeError>
        <FormNewUserStyle.GridForm>
          <FormNewUserStyle.contInputAndLabel>
            <FormNewUserStyle.Text>
              Nombre
            </FormNewUserStyle.Text>
            <FormNewUserStyle.Input placeholder="Escriba aqui el nombre del usuario" onChange={(e) => setName(e.target.value)} value={name} />
          </FormNewUserStyle.contInputAndLabel>
          <FormNewUserStyle.contInputAndLabel>
            <FormNewUserStyle.Text>
              Correo
            </FormNewUserStyle.Text>
            <FormNewUserStyle.Input placeholder="Escriba aqui el correo del usuario" onChange={(e) => setCorreo(e.target.value)} value={correo} />
          </FormNewUserStyle.contInputAndLabel>
          <FormNewUserStyle.contInputAndLabel>
            <FormNewUserStyle.Text>
              Clave
            </FormNewUserStyle.Text>
            <FormNewUserStyle.Input placeholder="Escriba aqui el clave del usuario" onChange={(e) => setPassword(e.target.value)} />
          </FormNewUserStyle.contInputAndLabel>
          <FormNewUserStyle.contInputAndLabel>
            <FormNewUserStyle.Text>
              Verifique la Clave
            </FormNewUserStyle.Text>
            <FormNewUserStyle.Input placeholder="Verifique la clave del usuario" onChange={(e) => setCheckPassword(e.target.value)} />
          </FormNewUserStyle.contInputAndLabel>
          <FormNewUserStyle.contInputAndLabel>
              <FormNewUserStyle.Text>
                Es usuario KBT?
              </FormNewUserStyle.Text>
              <Select
                StatusDropDown={[{id:true, name:'Si'},{id:false, name:'No'}]}
                idOption={isKbtUser}
                setIdOption={setIsKbtuser} 
                />
          </FormNewUserStyle.contInputAndLabel>
          {
            isKbtUser && (
              <FormNewUserStyle.contInputAndLabel>
                <FormNewUserStyle.Text>
                  Meta diaria de publicacion
                </FormNewUserStyle.Text>
                <FormNewUserStyle.Input placeholder="Coloque la meta diaria de publicacion" onChange={(e) => setDailyGoalForKbt(e.target.value)} />
              </FormNewUserStyle.contInputAndLabel>
            )
          }
        </FormNewUserStyle.GridForm>
          <FormNewUserStyle.contInputAndLabel>
              <FormNewUserStyle.Text>
                Role a asignar
              </FormNewUserStyle.Text>
              <Select
                StatusDropDown={rolesSend}
                idOption={rolSend}
                setIdOption={setRolSend} 
                />
          </FormNewUserStyle.contInputAndLabel>
          <FormNewUserStyle.contInputAndLabel>
              <FormNewUserStyle.Text>
                Pais
              </FormNewUserStyle.Text>
              <Select
                StatusDropDown={[{id:'CO', name:'Colombia'},{id:'VE', name:'Venezuela'}]}
                idOption={country}
                setIdOption={setcountry} 
                />
          </FormNewUserStyle.contInputAndLabel>
        <FormNewUserStyle.BtnSend onClick={() => saveUser()}>
          {isLoader ? 'Verificando...' : 'Guardar'}
        </FormNewUserStyle.BtnSend>
      </FormNewUserStyle.Form>
    </FormNewUserStyle.ContForm>
  );
}
