import styled from 'styled-components';

const cont = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items:center;
justify-content: flex-start;
height:100%;
`;
const header = styled.div`
width:100%;
align-self: flex-start;
display:flex;
align-items:center;
justify-content:flex-start;
margin-top:10px;
`;
const btnRedirect = styled.button`
    width: ${props=> props.wd || 'auto'};
    height: auto;
    background-color:${props => props.active ?  "#7367f0" : "#FFFFFF"};
    text-align: center;
    color: ${props => props.active ? "#FFFFFF" : "#7367f0"};
    padding:6px 16px;
    font-size:12pt;
    border: 1px solid #7367f0;
    margin-top: ${props => props.mt || 0};
    margin-left:${props => props.ml || 0};
    margin-right:${props => props.mr || 0};
    border-radius:5px;
    outline: none!important;
    transition: all ease 0.5s;
    font-weight: bold;
    &:hover {
        color: white;
        background-color: #7367f0;
    }
`;
const text = styled.p`
margin-bottom:  ${props=> props.mb || '0'};;
color: ${props=> props.cl || '#707070'};
font-size: ${props=> props.size || '12px'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
font-weight: ${props=> props.fw || 'lighter'};
margin-top: ${props=> props.mt || '0'};
`;
const inputOne = styled.input`
    width: 100%;
    height: 38px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    border: 0.5px solid rgba(112, 112, 112, 0.7);
    outline: none !important;
    padding:6px 16px;
    font-size:12pt;
    color: rgb(112, 112, 112);
    font-weight: 600;
    padding-right: 40px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12px;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus, &:active {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
        }
    margin-top : ${props => props.mt || 0};
`;
const btnBack = styled.button`
width:30px;
height:30px;
outline: none !important;
border: 1px solid #7367F0;
display: flex;
justify-content:center;
align-items: center;
font-size: 12pt;
color: #7367F0;
border-radius:50px;
background-color: #FFFFFF;
transition: all ease 0.02s;
margin-right:10px;
&:hover {
    background: #7377f0;
    color: #FFFFFF;
}
`;
const btnFloat = styled.button`
    width: 30px;
    height: 30px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:50px;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    border: 0.5px solid rgba(112, 112, 112, 0.7);
    outline: none !important;
    position: absolute;
    margin-right: 5px;
    transition: all ease 0.5s;
    font-size:12pt;
    color: #707070;
    &:hover {
        border: 0.5px solid rgba(115, 103, 240, 0.7);
    }
`;
const contInputAndLabel = styled.div`
width:100%;
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'space-between'};
align-items:${props => props.ali || 'flex-start'};
margin-top: 10px;
margin-bottom:10px;
input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
    }
`;

const contForm = styled.div`
box-shadow: 0px 0px 5px 2px rgb(112 112 112 / 42%);
height: auto;
min-width: 250px;
max-width: 500px;
width: 500px;
max-height: 500px !important;
overflow-x: hidden;
overflow-y: ${props => props.active ? 'auto' : 'hidden'};
border-radius: .3rem;
background-color: #FFFFFF;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
transition: all ease-in 0.2s;
/* width */
::-webkit-scrollbar {
    width: 2.5px;
}
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(94, 84, 142);
    border-radius: 50px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #7367f0;
}
`;
const headerForm = styled.div`
width:100%;
margin-top:0;
height: 40px;
padding:10px;
display:flex;
justify-content: center;
align-items: center;
`;
const bodyForm = styled.div`
width:100%;
margin-top:10px;
padding: 10px;
`;
const footerForm = styled.div`
width:100%;
padding: 10px;
margin-top:auto;
margin-bottom: 0;
height: auto;
padding:10px;
display:flex;
justify-content: flex-end;
align-items: center;
`;
const btnSave = styled.button`
padding: 8px 16px;
border-radius: .5rem;
color: white;
background: linear-gradient(#7367f0 0%, rgba(115, 103, 240, 0.7) 100%);
font-size:12pt;
font-weight:500;
text-align:center;
border:none;
outline: none!important;
transition: all ease-out 0.02s;
&:hover {
    box-shadow: 0 1px 20px 1px #7367F0!important;
}
`;
const MassiveScrapingCss = {
    cont,
    contForm,
    headerForm,
    bodyForm,
    text,
    inputOne,
    btnFloat,
    contInputAndLabel,
    btnSave,
    footerForm,
    header,
    btnBack,
    btnRedirect
}

export default MassiveScrapingCss;