import React, { useState, useContext, useEffect } from "react";
import { Form, Col, Row, Button, Container } from "react-bootstrap";
import OdooStyle from "../../../../assets/css/components/admin/plugins/OdooStyle.css";
import {
  keyUserId,
  keyAccessToken,
} from "../../../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import axios from 'axios';
import { Redirect } from "react-router-dom";
import Routes from "../../../../Routes";
import verifyAccessRole from "../../../../assets/js/verifyAccessRole";

export default function AmazonConf() {
    const globalContext = useContext(GlobalContext);
    const [redirect, setRedirect] = useState(null);
    const [inputs, setInputs] = useState({});
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());

    useEffect(() => {
      const hasAccess = verifyAccessRole(Routes, 48);
      if(!hasAccess) {
          setRedirect(
              <Redirect to={`/admin/dashboard`} />
          )
      }
  },[]);

  /**
   * Handle the axios error
   * @param {Error} err The thrown error
   * @param {string} msg A message to show in the modal
   * @param {boolean} consoleLog If should log the error in the console
   */
  const handleAxiosError = (err, msg = null, consoleLog = true) => {
    if (axios.isCancel(err)) return; //Don't show axios cancel error

    if (err.response && err.response.data) {
      if (err.response.data.message) {
        globalContext.showModalWithMsg(err.response.data.message);
      } else {
        globalContext.showModalWithMsg(err.response.data);
      }
    } else if (msg) {
      globalContext.showModalWithMsg(msg);
    }

    if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
  };

  const updateInputState = (e) => {
    const name = e.target.name;
    const newState = Object.assign({}, inputs);

    switch (e.target.type) {
      case "checkbox":
        newState[name] = e.target.checked;
        break;

      default:
        newState[name] = e.target.value;
        break;
    }

    setInputs(newState);
  };

  const getDataToPublishFromSku = () => {
    const entId = globalContext.currentEnterpriseId;
    const skus = inputs.skusToPublish;

    if (!entId) {
      globalContext.showModalWithMsg("La empresa...");
      return;
    } else if (!skus) {
      globalContext.showModalWithMsg("Los sku...");
      return;
    }

    const processCheckIntervalTime = 5000;
    let processModitorId = 0;
    const headers = {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "x-access-token": localStorage.getItem(keyAccessToken),
      "x-user-id": localStorage.getItem(keyUserId),
    };

    globalContext.setLoaderPage(true);
    
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/report-data-to-publish`, {
      skus,
    }, {
      headers,
    }).then((resp) => {
      const data = resp.data.data;

      if (data.monitor) {
        processModitorId = data.monitor;

        //If the process ID was received, start monitoring
        let waitingMonitorResponse = false;
        const interval = setInterval(() => {
          if (!waitingMonitorResponse) {
            waitingMonitorResponse = true;

            axios
              .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                {
                  cancelToken: axiosCancelTokenSource.token,
                  headers,
                }
              )
              .then((resp) => {
                const data = resp.data.data;

                if (data.advance < 100) {
                  const advance = data.advance.toFixed(2);
                  globalContext.setLoaderPage(true, `${advance}% (${data.data.currentProduct}/${data.data.totalProducts})`);
                } else {
                  clearInterval(interval);
                  globalContext.setLoaderPage(true, "Descargando archivo");

                  //Download the file
                  axios
                    .get(
                      `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${processModitorId}/download`,
                      {
                        cancelToken: axiosCancelTokenSource.token,
                        headers,
                        responseType: 'json',
                      }
                    ).then(respMonitorFile => {
                      globalContext.setLoaderPage(false);
                      const FileDownload = require("js-file-download");
                      FileDownload(respMonitorFile.data.data, "Reporte.csv");
                    }).catch(err => {
                      handleAxiosError(
                        err,
                        "Disculpe, no se pudo culminar la generacion del reporte."
                      );
                      globalContext.setLoaderPage(false);
                    });
                }
              })
              .catch((err) => {
                handleAxiosError(
                  err,
                  "Disculpe, no se pudo culminar la descarga de los productos."
                );
                clearInterval(interval);
                globalContext.setLoaderPage(false);
              })
              .finally(() => {
                waitingMonitorResponse = false;
              });
          }
        }, processCheckIntervalTime);
      } else {
        globalContext.setLoaderPage(false);
      }
    }).catch(err => handleAxiosError(err));
  }

  return (
    <OdooStyle>
      {redirect}
      <Container>
        <Form className="amazon">
          <Row className="mt-2">
            <Col md={12}>
              <Form.Control
                size="sm"
                as="textarea"
                placeholder="SKUs"
                name="skusToPublish"
                value={inputs.skusToPublish || ""}
                onChange={updateInputState}
              />
              <Button onClick={getDataToPublishFromSku}>Reporte</Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </OdooStyle>
  );
}
