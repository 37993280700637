import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { BiPlus } from 'react-icons/bi'
import PedidosYaStyle from '../../../../../../../assets/css/components/admin/plugins/PedidosYaStyle'
import getAllRestaurant from '../../../../../plugins/pedidosYa/functions/getAllRestaurant';
import { GlobalContext } from '../../../../../../../contexts/GlobalContext';
import getSections from '../../../../../plugins/pedidosYa/functions/getSections';
import { Spinner } from 'react-bootstrap';
export default function StepTwo({ sectionId, setSectionId, activeModal}) {
    const [isLoad, setIsLoad] = useState(false);
    const globalContext = useContext(GlobalContext);
    const [isLoadTwo, setIsLoadTwo] = useState(true);
    const [restaurants, setRestaurants] = useState([]);
    const [sectionList, setSectionList] = useState([])

    useEffect(() => {
        if(!activeModal) return;
        const entId = globalContext.currentEnterpriseId
        if (!entId) return;
        getAllRestaurant(entId)
            .then((res) => {
                const datRes = res.data.data;
                setRestaurants(datRes);
            })
            .catch(err => {
                console.log('el error res', err)
            })
    }, [globalContext.currentEnterpriseId, activeModal])

    useEffect(() => {
        if(!activeModal) return;
        const entId = globalContext.currentEnterpriseId
        if (!entId) return;
        getSections(entId)
            .then((res) => {
                const dataSections = res.data.data;
                setSectionList(dataSections);
                setIsLoadTwo(false)
            })
            .catch(err => {
                setIsLoadTwo(false)
                console.log('el error', err)
            })
    }, [globalContext.currentEnterpriseId, activeModal]);

    const renderOneRestauran = (id) => {
        if (restaurants.length > 0) {
            const dataRes = restaurants.find(v => v._id === id);
            if (!dataRes) return '';
            return dataRes.store;
            //return 'si hay algo'
        } else {
            return ''
        }
    }

    const selectSeccionId = (v) => {
        setSectionId(v)
    }

    const isSelected = (v) => {
        if(!sectionId) return;
        if(sectionId._id === v) {
            return true;
        }else {
            return false;
        }
    }

    return (
        <>
            <PedidosYaStyle.ContTable>
                <PedidosYaStyle.HeaderContTable>
                    <PedidosYaStyle.Text size="13pt" fw="500">
                        Listado de Secciones o Categorias
                    </PedidosYaStyle.Text>
                </PedidosYaStyle.HeaderContTable>
                <PedidosYaStyle.TableResult>
                    <PedidosYaStyle.TableResultThead>
                        <PedidosYaStyle.TableResultTr>
                            <PedidosYaStyle.TableResultTh width="300px" minWidth="100px" textAlign="center">
                                Nombre de la seccion
                            </PedidosYaStyle.TableResultTh>
                            <PedidosYaStyle.TableResultTh width="300px" minWidth="100px" textAlign="center">
                                Sucursal
                            </PedidosYaStyle.TableResultTh>
                            <PedidosYaStyle.TableResultTh width="100px" minWidth="100px" textAlign="center">
                                Accion
                            </PedidosYaStyle.TableResultTh>
                        </PedidosYaStyle.TableResultTr>
                    </PedidosYaStyle.TableResultThead>
                    {
                        isLoadTwo ? (
                            <PedidosYaStyle.TableResultTbody>
                                <PedidosYaStyle.TableResultTr>
                                    <PedidosYaStyle.TableResultTd width="100%" minWidth="100px" textAlign="center">
                                        <PedidosYaStyle.contLoader>
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Cargando...</span>
                                            </Spinner>
                                        </PedidosYaStyle.contLoader>
                                    </PedidosYaStyle.TableResultTd>
                                </PedidosYaStyle.TableResultTr>

                            </PedidosYaStyle.TableResultTbody>
                        ) :
                            (
                                <PedidosYaStyle.TableResultTbody>
                                    {
                                        sectionList.length > 0 &&
                                        sectionList.map(v => {
                                            return (
                                                <PedidosYaStyle.TableResultTr key={v._id}>
                                                    <PedidosYaStyle.TableResultTd width="300px" minWidth="100px" textAlign="center">
                                                        {v.name}
                                                    </PedidosYaStyle.TableResultTd>
                                                    <PedidosYaStyle.TableResultTd width="300px" minWidth="100px" textAlign="center">
                                                        {
                                                            restaurants.length > 0 &&
                                                            //v.restaurant
                                                            renderOneRestauran(v.restaurant)
                                                        }
                                                    </PedidosYaStyle.TableResultTd>
                                                    <PedidosYaStyle.TableResultTd width="100px" minWidth="100px" textAlign="center" backGroundColor="#F3F2F7">
                                                        <div className="d-flex justify-content-center align-items-center" style={{ with: '100%' }}>
                                                            {
                                                                isLoad ?
                                                                    (
                                                                        <PedidosYaStyle.contLoader>
                                                                            <Spinner animation="border" role="status">
                                                                                <span className="sr-only">Cargando...</span>
                                                                            </Spinner>
                                                                        </PedidosYaStyle.contLoader>
                                                                    )
                                                                    :
                                                                    (
                                                                        <>
                                                                            {
                                                                                v.enabled &&
                                                                                    (
                                                                                        <PedidosYaStyle.BtnIcon
                                                                                            onClick={() => selectSeccionId(v)}
                                                                                            mRight="auto"
                                                                                            mLeft="auto"
                                                                                            width="30px"
                                                                                            height="30px"
                                                                                            bgColor={isSelected(v._id) && "#7367F0"}
                                                                                            color={isSelected(v._id) && "#FFFFFF"}
                                                                                            >
                                                                                                <BiPlus />
                                                                                        </PedidosYaStyle.BtnIcon>
                                                                                    )
                                                                            }
                                                                        </>
                                                                    )
                                                            }
                                                        </div>
                                                    </PedidosYaStyle.TableResultTd>
                                                </PedidosYaStyle.TableResultTr>
                                            )
                                        })
                                    }
                                </PedidosYaStyle.TableResultTbody>
                            )
                    }
                </PedidosYaStyle.TableResult>
            </PedidosYaStyle.ContTable>
        </>
    )
}
