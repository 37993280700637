import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';

const getChilds = (parentNode, axiosCancelTokenSource) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/categories`, {
        params: {
            pageNumber: 1,
            pageItemCount: 200,
            parent: parentNode,
            filter: {
                includeTree: true,
                getAll: false,
                search: '',
                lang: 'en',
                linked: true,
            },
        },
        cancelToken: axiosCancelTokenSource.token,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
}

export default getChilds;