import React, { useEffect, useState } from 'react'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle'
import { BiHide, BiShow, BiX } from 'react-icons/bi'
import Select from '../../../../general/Select';
import sendDatauserFtp from '../functions/sendDataUserFtp';
import validateDataBeforeSend from '../functions/validateDataBeforeSend';
export default function FormuserFtp(props) {
    const {StatusDropDown} = props;
    const [showPass, setShowPass] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [showBadge, setShowBadge] = useState(false);
    const [username, setUsername] =  useState(null);
    const [password, setPassword] =  useState(null);
    const [port, setPort] =  useState(null);
    const [host, setHost] =  useState(null);
    const [enterpriseId, setEnterpriseId] =  useState(null);
    const [enterpriseArr, setEnterpriseArr] =  useState([]);
    const [error, setError] = useState('');
    const [colorError, setColorError] = useState(true)
    
    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPass(!showPass)
    }

    const closeBadge = (e) => {
        e.preventDefault();
        setShowBadge(false);
    }

    useEffect(() => {
        if(StatusDropDown) {
            setEnterpriseArr(StatusDropDown);
        }
    },[StatusDropDown]);

    const sendData = () => {
        setIsLoad(true)
        const hasAnyError = validateDataBeforeSend([username, password, port, host, enterpriseId]);
        if(hasAnyError) {
            sendDatauserFtp(username, password, port, host, enterpriseId)
            .then(success => {
                setColorError(false);
                setError('Registro exitoso');
                setShowBadge(true);
                setIsLoad(false)
            })
            .catch((err) => {
                setColorError(false);
                setError(`Algo salio mal ${err}`);
                setShowBadge(true);
                setIsLoad(false)
            })
        }else {
            setError('Verifique que todos los datos son correctos o si le falta algun valor!')
            setShowBadge(true);
            setIsLoad(false)
        }
    }
    return (
        <>
            <PedidosYaStyle.ContBadgeError active={showBadge} error={colorError}>
                <PedidosYaStyle.Text size=".875rem" cl="#FFFFFF">
                    {error}
                </PedidosYaStyle.Text>
                <PedidosYaStyle.BtnToggleBadge onClick={(e) => closeBadge(e)}>
                    <BiX />
                </PedidosYaStyle.BtnToggleBadge>
            </PedidosYaStyle.ContBadgeError>
            <PedidosYaStyle.ContForm>
                <PedidosYaStyle.HeaderForm>
                    <PedidosYaStyle.Text size="14pt" fw="500" cl="#898989">
                        Ingrese los datos para registrar su usuario FTP
                    </PedidosYaStyle.Text>
                </PedidosYaStyle.HeaderForm>
                <PedidosYaStyle.BodyForm>
                    <PedidosYaStyle.BodyFormGrid>
                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Nombre de Usuario
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input placeholder="Escriba aqui el nombre de usuario" onChange={(e) => setUsername(e.target.value)} />
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Clave de acceso
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.ContIconNadInputPass>
                                <PedidosYaStyle.Input type={showPass ? 'text' : 'password'} placeholder="Escriba aqui su clave de acceso" onChange={(e) => setPassword(e.target.value)} />
                                <PedidosYaStyle.BtnShowPass onClick={(e) => toggleShowPassword(e)}>
                                    {
                                        showPass ? (
                                            <BiHide />
                                        ) : (
                                            <BiShow />
                                        )
                                    }
                                </PedidosYaStyle.BtnShowPass>
                            </PedidosYaStyle.ContIconNadInputPass>
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Puerto
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input placeholder="Escriba aqui el puerto de acceso" onChange={(e) => setPort(e.target.value)} />
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Host
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input placeholder="Escriba aqui el host de acceso" onChange={(e) => setHost(e.target.value)} />
                        </PedidosYaStyle.ContInputAndLabel>
                    </PedidosYaStyle.BodyFormGrid>
                    <PedidosYaStyle.ContInputAndLabel w="100%">
                            <PedidosYaStyle.Text size="12pt">
                                Empresa
                            </PedidosYaStyle.Text>
                            <Select
                                StatusDropDown={enterpriseArr}
                                
                                setIdOption={setEnterpriseId}
                            />
                        </PedidosYaStyle.ContInputAndLabel>
                </PedidosYaStyle.BodyForm>
                <PedidosYaStyle.FooterForm>
                    <PedidosYaStyle.BtnSend disabled={isLoad} onClick={() => sendData()}>
                        {isLoad ? 'Enviando' : 'Guardar'}
                    </PedidosYaStyle.BtnSend>
                </PedidosYaStyle.FooterForm>
            </PedidosYaStyle.ContForm>
        </>
    )
}
