import React, { useContext, useState } from 'react'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle'

import { GlobalContext } from '../../../../../contexts/GlobalContext';
import buildToken from '../functions/buildToken';
export default function FormuserFtp(props) {
    const globalContext = useContext(GlobalContext);
    const [showBadge, setShowBadge] = useState(false);
    const [error, setError] = useState('');
    const [colorError, setColorError] = useState(true)

    const closeBadge = (e) => {
        e.preventDefault();
        setShowBadge(false);
    }

    const buildT = () => {
        buildToken()
            .then(res => {
                globalContext.showModalWithMsg('Las token para anicam se genero con exito!')
            }).catch(err => {
                globalContext.showModalWithMsg('Las token para anicam no se genero con exito!', err)
            })
    }



    return (
        <>
            <PedidosYaStyle.ContForm>
                <PedidosYaStyle.HeaderForm>
                    <PedidosYaStyle.Text size="14pt" fw="500" cl="#898989">
                        Complete los datos para las credenciales de Anican
                    </PedidosYaStyle.Text>
                </PedidosYaStyle.HeaderForm>
                <PedidosYaStyle.BodyForm>
                    <PedidosYaStyle.ContInputAndLabel w="100%">
                        <PedidosYaStyle.Text size="12pt">
                            Generar Token
                        </PedidosYaStyle.Text>
                        <PedidosYaStyle.BtnSend onClick={() => buildT()}>
                            Generar
                        </PedidosYaStyle.BtnSend>
                    </PedidosYaStyle.ContInputAndLabel>
                </PedidosYaStyle.BodyForm>
            </PedidosYaStyle.ContForm>
        </>
    )
}
