import { faAngleDown, faCalculator, faInfo, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { set } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react'
import CardProductCss from '../../../../../assets/css/components/admin/products/ml/rangos-publicacion/CardProductCss'
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
//setOpenModalFormula
export default function CardProduct(props) {
    const {    
        tariff,
        earning,
        courierPlus,
        courierPlusIsPercent,
        poundPrice,
        additionalPoundPrice,
        safe,
        safeIsPercent,
        ccTax,
        ccTaxIsPercent,
        defaultTariff,
        defaultTariffIsPercent,
        iva,
        ivaIsPercent,
        defaultComission,
        defaultComissionIsPercent
    } = props;
    const numberFormat = new Intl.NumberFormat('de-DE');
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [asin, setAsin] = useState('');
    const [data, setData] = useState(null)
    const [precioFinal, setPrecioFinal] = useState('');
    const [formula, setFormula] = useState('');
    const [earningUsd,setEarningUsd] = useState('')
    const [totalUsd,setTotalUsd] = useState('');
    const [showFormula, setShowFormula] = useState(false);
    const [positionActiveIfVariant, setPositionActiveIfVariant] = useState(0);
    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
        const handleAxiosError = (err, msg = null, consoleLog = true) => {
            if (axios.isCancel(err)) return; //Don't show axios cancel error
            if (err.response && err.response.data) {
                if (err.response.data.message) {
                    globalContext.showModalWithMsg(err.response.data.message);
                } else {
                    globalContext.showModalWithMsg(err.response.data);
                }
            } else if (msg) {
                globalContext.showModalWithMsg(msg);
            }
            if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
        };

    const getProduct = async () => {
        if(asin.length === 0) return;
        const entId = globalContext.currentEnterpriseId;
        globalContext.setLoaderPage(true);
            const resp = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/products`, {
                params: {
                    pageNumber: 1,
                    pageItemCount: 15,
                    filter: {
                        asin: asin,
                        lang: 'es',
                    },
                },
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            })
            return resp;
    }

    const getPublishingPrice = async (prodId) => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("Espere a que todos los datos esten cargados");
            return;
        }
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/calculate-publish-price`, {
            prodId: prodId,
            config: {
                tariff:tariff,
                earning:earning,
                courierPlus:parseInt(courierPlus),
                courierPlusIsPercent:parseInt(courierPlusIsPercent),
                poundPrice:parseInt(poundPrice),
                additionalPoundPrice:parseInt(additionalPoundPrice),
                safe:safe,
                safeIsPercent:safeIsPercent,
                ccTax:ccTax,
                ccTaxIsPercent:ccTaxIsPercent,
                defaultTariff:parseInt(defaultTariff),
                defaultTariffIsPercent:defaultTariffIsPercent,
                iva:parseInt(iva),
                ivaIsPercent:true,
                defaultComission:defaultComission,
                defaultComissionIsPercent:defaultComissionIsPercent,
                countryCode: entId === "60944575461add5b1957ef8b" ? 'VE' : 'CO'
            }
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res
    }

    const getAllData = async (e) => {
        e.preventDefault();
        const numberFormat = new Intl.NumberFormat('de-DE');
        await getProduct()
        .then(async(r) => {
            const data = r.data.data.products[0];
            //console.log('primera data',data);
            setData(data)
            await getPublishingPrice(data._id)
            .then((res) => {
                const valores = res.data.data;
                if(valores.variants) {
                    setPrecioFinal(valores);
                    setFormula(valores);
                    setEarningUsd(valores);
                    setTotalUsd(valores)
                }else {
                    setPrecioFinal(valores.total);
                    setFormula(valores.formula);
                    setEarningUsd(valores.earningUsd);
                    setTotalUsd(valores.totalUsd)
                }
                //console.log('el precio',res)
            }).catch((e) => { handleAxiosError(e, 'Presentamos un error 508'); globalContext.setLoaderPage(false); })
            globalContext.setLoaderPage(false);
        })
        .catch((e) => { handleAxiosError(e, 'Presentamos un error 509'); globalContext.setLoaderPage(false); })
    }

    useEffect(() => {
        if (globalContext.skuByRang.length > 0) {
            setAsin(globalContext.skuByRang)
        }
    }, [globalContext.skuByRang])
    return (
        <>
        <CardProductCss.cont>
            <CardProductCss.contFormSearch onSubmit={(e) => getAllData(e)}>
                <CardProductCss.contInputAndLabel>
                    <CardProductCss.text mb='10px' fw='600'>
                        SKU del producto
                    </CardProductCss.text>
                    <CardProductCss.contInputAndButton>
                        <CardProductCss.input placeholder='Escriba el sku del producto' defaultValue={globalContext.skuByRang} wd='220px' onChange={(e) => setAsin(e.target.value)} />
                        <CardProductCss.btn colorFont='#FFFFFF' bgColor='#7367F0' pd='8px 12px'>
                            Buscar
                        </CardProductCss.btn>
                    </CardProductCss.contInputAndButton>
                </CardProductCss.contInputAndLabel>
            </CardProductCss.contFormSearch>
        {
            data && (
                <CardProductCss.contDataproductCard>
                    <CardProductCss.carouselImagesProduct>
                        {data && 
                            (data.variants.length > 0) &&
                            data.variants.map((v,i) => {
                                return (
                                    <CardProductCss.carouselImageProducts 
                                    img={v.images[0]}
                                    key={i}
                                    onClick={
                                        () => {
                                            setPositionActiveIfVariant(i)
                                        }
                                    }
                                    />
                                )
                            })
                        }
                    </CardProductCss.carouselImagesProduct>
                <CardProductCss.contInputAndLabel fd='row'>
                    <CardProductCss.text  mr='5px' size='14pt'>
                        Titulo del producto:
                    </CardProductCss.text>
                    <CardProductCss.text  fw='600' size='14pt'>
                        {
                            data &&
                            (data.variants.length > 0) ? 
                            (data.variants[positionActiveIfVariant].title.es || data.variants[positionActiveIfVariant].title.es)
                            :
                            (data.title.es || data.title.en)
                        }
                    </CardProductCss.text>
                </CardProductCss.contInputAndLabel>

                <CardProductCss.contGrid>
                <CardProductCss.contInputAndLabel fd='row'>
                    <CardProductCss.text  mr='5px' size='14pt'>
                        USD Provedor:
                    </CardProductCss.text>
                    <CardProductCss.text  fw='600' size='14pt'>
                        {
                            data &&
                            (data.variants.length > 0) ?
                            `$${numberFormat.format(data.variants[positionActiveIfVariant].price)}`
                            :
                        `$${numberFormat.format(data.price)}`
                        }
                    </CardProductCss.text>
                </CardProductCss.contInputAndLabel>

                <CardProductCss.contInputAndLabel fd='row'>
                    <CardProductCss.text  mr='5px' size='14pt'>
                    Peso de envio (Lbs):
                    </CardProductCss.text>
                    <CardProductCss.text  fw='600' size='14pt'>
                    {
                            data &&
                            (data.variants.length > 0) ?
                            `${data.variants[positionActiveIfVariant].weight}(LBS)`
                            :
                           `${data.weight}(LBS)`
                        }
                    </CardProductCss.text>
                </CardProductCss.contInputAndLabel>

                {data.dimensions && (
                <CardProductCss.contInputAndLabel fd='row'>
                    <CardProductCss.text  mr='5px' size='14pt'>
                        Dimensiones del paquete:
                    </CardProductCss.text>
                    <CardProductCss.text  fw='600' size='14pt'>
                        
                    {
                        data &&
                        (data.variants.length > 0) ?
                        `Alto ${data.variants[positionActiveIfVariant].dimensions.height } Largo ${data.variants[positionActiveIfVariant].dimensions.width} Longitud ${data.variants[positionActiveIfVariant].dimensions.length}`
                        :
                        `Alto ${data.dimensions.height } Largo ${data.dimensions.width} Longitud ${data.dimensions.length}`
                    }
                    </CardProductCss.text>
                </CardProductCss.contInputAndLabel>
                )}
                {data.dimensions && (<CardProductCss.contInputAndLabel fd='row'>
                    <CardProductCss.text  mr='5px' size='14pt'>
                        Peso volumetrico:
                    </CardProductCss.text>
                    <CardProductCss.text  fw='600' size='14pt'>
                    {
                        data &&
                        (data.variants.length > 0) ?
                        `${(((parseFloat(data.variants[positionActiveIfVariant].dimensions.height) *parseFloat(data.variants[positionActiveIfVariant].dimensions.width) * parseFloat(data.variants[positionActiveIfVariant].dimensions.length)) * 1.2) / 166).toString().slice(0,5)} (LBS) `    
                        :
                    `${(((parseFloat(data.dimensions.height) * parseFloat(data.dimensions.width) * parseFloat(data.dimensions.length)) * 1.2) / 166).toString().slice(0,5)} (LBS) `
                    }
                    </CardProductCss.text>
                </CardProductCss.contInputAndLabel>)}
                </CardProductCss.contGrid>
                <CardProductCss.contGrid>
                    <CardProductCss.contInputAndLabel fd='row'>
                    <CardProductCss.text  mr='5px' size='14pt'>
                        Precio en (USD):
                    </CardProductCss.text>
                    <CardProductCss.text  fw='600' size='14pt'>
                        {
                            totalUsd.variants ?
                            `$${numberFormat.format(Math.ceil(totalUsd.variants[positionActiveIfVariant].totalUsd))}`
                            :
                            `$${numberFormat.format(Math.ceil(totalUsd))}`
                        }
                    </CardProductCss.text>
                </CardProductCss.contInputAndLabel>
                    <CardProductCss.contInputAndLabel fd='row'>
                        <CardProductCss.text  mr='5px' size='14pt'>
                            Precio en (COB/BSF):
                        </CardProductCss.text>
                        <CardProductCss.text  fw='600' size='14pt'>
                            {
                                precioFinal.variants ? 
                                numberFormat.format(Math.ceil(precioFinal.variants[positionActiveIfVariant].total))
                                :
                                numberFormat.format(Math.ceil(precioFinal))
                            }
                        </CardProductCss.text>
                    </CardProductCss.contInputAndLabel>
                <CardProductCss.contInputAndLabel fd='row'>
                    <CardProductCss.text  mr='5px' size='14pt'>
                        Monto total de la ganancia (USD):
                    </CardProductCss.text>
                    <CardProductCss.text  fw='600' size='14pt'>
                        {
                            earningUsd.variants ?
                            `$${numberFormat.format( Math.ceil(earningUsd.variants[positionActiveIfVariant].earningUsd))}`
                            :
                            `$${numberFormat.format(Math.ceil(earningUsd))}`}
                    </CardProductCss.text>
                </CardProductCss.contInputAndLabel>
                <CardProductCss.contInputAndLabel fd='row'>
                    <CardProductCss.text  mr='5px' size='14pt'>
                        Monto total de la ganancia (%):
                    </CardProductCss.text>
                    <CardProductCss.text  fw='600' size='14pt'>
                        {
                            earningUsd.variants ?
                            `${earningUsd.variants[positionActiveIfVariant].earningUsd > 0 ? Math.ceil((earningUsd.variants[positionActiveIfVariant].earningUsd / earningUsd.variants[positionActiveIfVariant].totalUsd) * 100): 0}%`
                            :
                            `${earningUsd > 0 ? Math.ceil((earningUsd / totalUsd) * 100) : 0}%`}
                    </CardProductCss.text>
                </CardProductCss.contInputAndLabel>
                </CardProductCss.contGrid>
                <CardProductCss.contGrid>
                    <CardProductCss.btn
                    style={{marginLeft: '0px'}} 
                        colorFont='#FFFFFF' bgColor='#7367F0' pd='8px 12px'
                        onClick={() => setShowFormula(!showFormula)}
                    >
                        {showFormula ? 'Ocultar formula' : 'Mostrar formula'}
                    </CardProductCss.btn>
                </CardProductCss.contGrid>
                {showFormula && (<CardProductCss.contGrid>
                    <CardProductCss.text  fw='600' size='14pt' ml="10px">
                            {
                            formula.variants ?
                            formula.variants[positionActiveIfVariant].formula
                            :
                            formula
                            }
                    </CardProductCss.text>
                </CardProductCss.contGrid>)}
            </CardProductCss.contDataproductCard>
            )
        }
        </CardProductCss.cont>
        </>
    )
}
