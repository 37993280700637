import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';

const getDataPy = (
    entId,
    currentPage,
pageItemCount,
filter,
filterLinkedTo,
currentBrand,
filterPrice,
filterStock,
axiosCancelTokenSource
    ) => {
    const res = axios.get(
        `${process.env.REACT_APP_BACKEND_URL_PEYA}/v1/peya-sdk/get-all-products-local/${entId}`,
        {
            params: {
                pageNumber: currentPage,
                pageItemCount: pageItemCount,
                filter: {
                    title: filter,
                    barCode: filter,
                    variBarCode: filter,
                    sellerSku: filter,
                    variSku: filter,
                    filterLinkedTo,
                    brand: currentBrand,
                    skipVariants: true,
                    filterPrice,
                    filterStock,
                },
                checkIsFullyLinked: true,
                includeVariantData: true,
            },
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(
                    keyAccessToken
                ),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
    )
    return res;
}

export default getDataPy;