import styled from "styled-components";
const TableDashboardStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  * {
    margin: 0px;
    box-sizing: border-box;
  }

  body,
  html {
    font-family: "Roboto Slab", serif !important;
  }

  /* ------------------------------------ */

  /* ------------------------------------ */

  ul,
  li {
    margin: 0px;
    list-style-type: none;
  }

  /* ------------------------------------ */

  /* ------------------------------------ */

  button:hover {
    cursor: pointer;
  }

  .btn-publish-in-another-store {
    background: #7367f0;
    color: #FFFFFF;
    outline: none !important;
    border: none;
    border-radius: 0.429rem;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-publish-in-another-store-clear {
    background: #7367f0;
    color: #FFFFFF;
    outline: none !important;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-refresh {
    outline: none;
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 35px;
    height: 35px;
    align-items: center;
    border: 1px solid rgb(94, 84, 142);
    color: rgb(94, 84, 142);
    background: transparent;
    font-size: 12pt;
    padding: 5px;
    border-radius: 50px;
    transition: all ease 450ms;
    .icon {
      font-size: 11pt;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:hover {
      background: rgb(94, 84, 142);
      color: white;
    }
    &:focus,
    &:active,
    &:active:focus {
      outline: none;
    }
  }

  /*//////////////////////////////////////////////////////////////////
[ Utiliti ]*/

  /*//////////////////////////////////////////////////////////////////
[ Table ]*/
  .limiter,
  .limiterHeader {
    width: 100%;
    margin: 0 auto;
  }
  .ActionsContent {
    padding: 8px 0;
  }
  .colHeader {
    padding: 10px 10px;
  }
  .linkProduct {
    color: #808080;
    text-decoration: none;
    font-family: "Roboto Slab", serif !important;
    font-weight: bold;
    &:hover {
      color: #5e548e;
    }
  }
  .container-table100,
  .container-header {
    width: 100%;
    min-height: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px 30px;
  }
  .btn-container button {
    padding: 18px 32px;
    border: none;
    border-radius: 5px;
    background-color: #5e548e;
    color: white;
    -webkit-box-shadow: -1px 11px 35px 0px rgba(94, 84, 142, 1);
    -moz-box-shadow: -1px 11px 35px 0px rgba(94, 84, 142, 1);
    box-shadow: -1px 11px 35px 0px rgba(94, 84, 142, 1);
    transition: all ease 450ms;

    &:hover {
      color: #5e548e;
      background-color: #cec28e;
    }
  }

  .wrap-table100 {
    width: 820px;
  }
  .ActionsContent {
    border: 1px solid #e5e9f2;
    background: white;
    border-radius: 10px;
    padding: 0 5px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  table {
    border-spacing: 1;
    border-collapse: collapse;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    font-weight: bold;
    width: 100%;
    margin: 0 auto;
    position: relative;
    font-family: "Roboto Slab", serif !important;
    -webkit-box-shadow: 0px 25px 55px -9px rgba(201, 201, 201, 1);
    -moz-box-shadow: 0px 25px 55px -9px rgba(201, 201, 201, 1);
    box-shadow: 0px 25px 55px -9px rgba(201, 201, 201, 1);
  }
  table * {
    position: relative;
  }
  table td,
  table th {
    padding-left: 8px;
    font-family: "Roboto Slab", serif !important;
  }
  table thead tr {
    height: 60px;
    background-color: #ffffff;
  }
  table tbody tr {
    height: 55px;
    padding: 10px 0;
  }
  table tbody tr:last-child {
    border: 0;
  }
  table td,
  table th {
    text-align: left;
  }
  table td.l,
  table th.l {
    text-align: right;
  }
  table td.c,
  table th.c {
    text-align: center;
  }
  table td.r,
  table th.r {
    text-align: center;
  }

  .table100-head th {
    font-size: 15px;
    font-weight: bold;
    color: #5e548e;
    box-shadow: rgba(0, 0, 0, 0.07) 0 1px;
    line-height: 1.2;
    font-weight: unset;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: "Roboto Slab", serif !important;
  }

  tbody tr {
    font-family: "Roboto Slab", serif !important;
    font-size: 15px;
    color: #808080;
    line-height: 1.2;
    font-weight: unset;
    box-shadow: rgba(0, 0, 0, 0.11) 0 1px;
    .cbx {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    }
    .cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
    }
    .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #9098A9;
    transition: all 0.2s ease;
    }
    .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
    }
    .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #7367f0;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
    }
    .cbx:hover span:first-child {
    border-color: #7367f0;
    }

    .inp-cbx:checked + .cbx span:first-child {
    background: #7367f0;
    border-color: #7367f0;
    animation: wave 0.4s ease;
    }
    .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
    }
    .inp-cbx:checked + .cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
    }

    @keyframes wave {
    50% {
        transform: scale(0.9);
    }
    }
    @media(max-width: 600px) {
        width:100%;
    }
  }

  tbody tr:hover {
    color: #555555;
    background-color: #f5f5f5;
  }
  .fa-meh,
  .fa-sad-tear {
    color: rgb(94, 84, 142);
    font-size: 18pt;
  }

  .column1 img {
    min-width: 60px !important;
    width: 60px !important;
    max-width: 60px !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .column1 {
    width: 120px;
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .column2 {
    width: 170px;
  }

  .column3 {
    width: 170px;
  }
  .column4 {
    width: 70px;
  }
  .column5 {
    width: 70px;
  }
  .column6 {
    width: 70px;
  }
  .column7 {
    width: 100px;
  }
  .column8 {
    width: 50px;
    .publish-btn {
      outline: none !important;
      border-radius: 50px;
      width: 30px;
      height: 30px;
      border: 1px solid rgb(94,84,142);
      background: white;
      color: rgb(94,84,142);
      &:hover {
        background-color: rgb(94,84,142);
        color: #ffffff;
      }
    }
  }
  .sincronizado.active {
    color: #ff6b6b;
  }

  .sincronizado.active2 {
    color: #4ecdc4;
  }
  .sincronizado {
    color: #e8f1f2;
  }

  @media screen and (max-width: 992px) {
    table {
      display: block;
    }
    table > *,
    table tr,
    table td,
    table th {
      display: block;
    }
    table thead {
      display: none;
    }
    table tbody tr {
      height: auto;
      padding: 37px 0;
    }
    table tbody tr td {
      padding-left: 40% !important;
      margin-bottom: 24px;
    }
    table tbody tr td:last-child {
      margin-bottom: 0;
    }
    table tbody tr td:before {
      font-family: "Roboto Slab", serif !important;
      font-size: 14px;
      color: #999999;
      line-height: 1.2;
      font-weight: unset;
      position: absolute;
      width: 40%;
      left: 30px;
      top: 0;
    }
    table tbody tr td:nth-child(1):before {
      content: "imagen";
    }
    table tbody tr td:nth-child(2):before {
      content: "Nombre";
    }
    table tbody tr td:nth-child(3):before {
      content: "Precio";
    }
    table tbody tr td:nth-child(4):before {
      content: "Stock";
    }
    table tbody tr td:nth-child(5):before {
      content: "SKU";
    }
    table tbody tr td:nth-child(6):before {
      content: "";
    }
    table tbody tr td:nth-child(7):before {
      content: "";
    }

    .column1,
    .column2,
    .column3,
    .column4,
    .column5,
    .column6,
    .column7,
    .column8 {
      width: 100%;
    }
    .column7,
    .column8 {
      text-align: center;
    }

    tbody tr {
      font-size: 14px;
    }
  }

  @media (max-width: 576px) {
    .container-header {
      padding: 10px 0;
    }

    .container-table100 {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;
export default TableDashboardStyle;
