import React, { useState, useEffect, useContext } from "react";
import {
    keyAccessToken,
    keyUserId,
} from "../../../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import ListFiltersStyles from "../../../../assets/css/components/admin/filters/ListFiltersCss";
import PublishProduct from "../../../../assets/css/components/admin/products/amazon/ModalPublishProduct";
import SelectcategorieCss from "../../../../assets/css/components/admin/products/post-product/steps/SelectcategorieCss";

export default function ModalTestFilter() {
    const globalContext = useContext(GlobalContext);
    const [isLoader, setIsLoader] = useState(true);
    const [data, setData] = useState([]);
    const [storeId, setStoreId] = useState(null);
    const [mlCaegory, setMlCaegory] = useState(null);
    const [title, setTitle] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [listingType, setListingType] = useState('gold_special');
    const [tienda, setTienda] = useState('');
    const [howToPost, setHowToPost] = useState('paused')
    const [idProd, setIdProd] = useState('')


    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const getProduct = async (entId, id) => {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/products/${id}`, {
            params: {
                pageNumber: 1,
                pageItemCount: 1,
                filter: {
                    lang: 'es',
                },
            },
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        
        if (globalContext.currentEnterpriseId === "60944575461add5b1957ef8b") {
            setIdProd('63823c8361ff3109306f04f3')
        } else {
            setIdProd('63823c8361ff3109306f04f9')
        }
        if(!globalContext.testFiltres) return;
        getProduct(entId, idProd)
            .then((res) => {
                const data = res.data.data.product;
                setData(data);
                setIsLoader(false);
                setTitle(data.title.es || data.title.en);
                setDescripcion(data.desc.es || data.desc.en)
            })
            .catch((err) => {
                handleAxiosError(err);
                setIsLoader(false);
            });
    }, [globalContext.currentEnterpriseId, globalContext.testFiltres]);

    const publicOneProduct = (entId) => {
        const res = axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/publish-to-ml`, {
            productId: idProd, //Id del producto del scraper
            title: title, //si el titulo esta seteado se manda
            body: descripcion,
            categoryMl: globalContext.currentEnterpriseId === "60944575461add5b1957ef8b" ? '605265bccf9ece052449070f' : '606e8655418f4625a4994169', //Id de la categoria de ml
            condition: 'new',
            images: ['https://http2.mlstatic.com/resources/frontend/statics/processing-image/1.0.0/O-ES.jpg'],
            listingType: "gold_special",
            pausePublication: howToPost === 'active' ? false : true,
            onlySimulate: true
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        )
        return res;
    }

    const sendProd = (ent) => {
        setIsLoader(true);
        publicOneProduct(ent)
        .then((resp) => {
            setIsLoader(false);
            const data = resp.data.data;
            console.log('esto de ', data)
            if(data.errors.length > 0) {
                globalContext.showModalWithMsg(data.errors.join('/'));
                setIsLoader(false);
            }else { 
                setTitle(data.dataToSend.title);
                setDescripcion(data.dataToSend.description.plain_text)
            }

        }).catch(error => {
            setIsLoader(false);
            console.log(error.message);
        })
    }

    return (
        <ListFiltersStyles.ContModal active={globalContext.testFiltres}>
            <ListFiltersStyles.ModalTest active={globalContext.testFiltres}>
                <ListFiltersStyles.HeaderModal>
                    Test de los filtros
                </ListFiltersStyles.HeaderModal>
                <ListFiltersStyles.BodyModal>
                    {
                        isLoader ?
                        (
                            <SelectcategorieCss.contLoader>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Cargando...</span>
                                </Spinner>
                            </SelectcategorieCss.contLoader>
                        ) :
                        (
                            <PublishProduct.contForm>
                                <div styled={{ width: '100%' }}>
                                    <div className='text-left'>
                                        <PublishProduct.textBody mb='5px' fw='bolder' >
                                            Titulo de la publicacion
                                        </PublishProduct.textBody>
                                        <PublishProduct.input wd='100%' value={title} onChange={(e) => setTitle(e.target.value)} />
                                    </div>
                                </div>
                                <div className='text-left' styled={{ width: 100 + '%' }}>
                                    <PublishProduct.textBody mb='5px' fw='bolder' mt='5px' >
                                        Descripcion
                                    </PublishProduct.textBody>
                                    <PublishProduct.textArea onChange={(e) => setDescripcion(e.target.value)} value={descripcion} />
                                </div>
                            </PublishProduct.contForm>
                        )
                    }
                    
                </ListFiltersStyles.BodyModal>
                <ListFiltersStyles.FooterModal>
                <ListFiltersStyles.btnAction
                    onClick={() => globalContext.setTestFiltres(false)}
                >
                    Cerrar
                </ListFiltersStyles.btnAction>
                <ListFiltersStyles.btnAction
                onClick={() => {sendProd(globalContext.currentEnterpriseId)}}
                >
                    Enviar
                </ListFiltersStyles.btnAction>
                </ListFiltersStyles.FooterModal>
            </ListFiltersStyles.ModalTest>
        </ListFiltersStyles.ContModal>
    )
}
