import React, { useState, useRef, useContext } from 'react'
import { useEffect } from 'react';
import { Text } from '../../../../../../assets/css/components/admin/marketplace-to-marketplace/modals/ModalSelectStoreAndSection';
import FormProduct from '../../../../../../assets/css/components/admin/marketplace-to-marketplace/products/FormProduct';
import ListOfProductsToSetParams from './ListProductsToSetParams'
import { GlobalContext } from '../../../../../../contexts/GlobalContext';

export default function SetParamProdToPost({ isLoad, setIsLoad, paramsToSend, setParamsToSend, listinType, setListinType, setPublishGroup,  publishGroup }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [urlImgPlus, setUrlImgPlus] = useState('');
  const [imgPlusArr, setImgPlusArr] = useState([]);
  const urlPlusInput = useRef(null);
  const globalContext = useContext(GlobalContext);
  const [blockField, setBlockField] = useState(true);

  useEffect(() => {
    if (globalContext.pluginSearchForpost === 'anicam') {
      setBlockField(true)
    } else {
      setBlockField(false)
    }
  }, [globalContext.pluginSearchForpost]);


  const sendToFormData = async (v, currentPosition) => {
    setCurrentIndex(currentPosition);

  }

  const titleFunc = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].title = v;
    setParamsToSend(copyArray)
  }
  const pausePublicationFunc = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].pausePublication = v;
    setParamsToSend(copyArray)
  }

  const publishGroupedV = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].publishGroup = v;
    setParamsToSend(copyArray)
  }
  const brandFunc = (v) => {
    if (blockField) return;
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].brand = v;
    setParamsToSend(copyArray)
  }
  const modelFunc = (v) => {
    if (blockField) return;
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].model = v;
    setParamsToSend(copyArray)
  }
  const weightFunc = (v) => {
    if (blockField) return;
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].weight = v;
    setParamsToSend(copyArray)
  }
  const heightFunc = (v) => {
    if (blockField) return;
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].height = v;
    setParamsToSend(copyArray)
  }
  const widthFunc = (v) => {
    if (blockField) return;
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].width = v;
    setParamsToSend(copyArray)
  }
  const length_prodFunc = (v) => {
    if (blockField) return;
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].length_prod = v;
    setParamsToSend(copyArray)
  }
  const bodyFunc = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].body = v;
    setParamsToSend(copyArray)
  }

  const warrantyTypeFunc = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].warranty_type = v;
    setParamsToSend(copyArray)
  }

  const warrantyValueFunc = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].warranty_value = v;
    setParamsToSend(copyArray)
  }

  const addImgPlus = (event) => {
    event.preventDefault();
    if (urlImgPlus.length > 0) {
      
      let copyArray = [...paramsToSend];
      let copyArrImg = copyArray[currentIndex].imagesPlus === '' ? [] : copyArray[currentIndex].imagesPlus.split(',');
      
      copyArrImg.push(urlImgPlus);
      copyArray[currentIndex].imagesPlus = copyArrImg.join(',');
      setParamsToSend(copyArray)
      setUrlImgPlus('');
      urlPlusInput.current.value = ''
    }
  }

  const removeImg = (url) => {
    const existUrl = imgPlusArr.find(v => v === url);
    if (existUrl !== undefined) {
      let copyImgPlusArr = [...imgPlusArr.filter(v => v !== existUrl)];
      setImgPlusArr(copyImgPlusArr)
      let copyArray = [...paramsToSend];
      copyArray[currentIndex].imagesPlus = copyImgPlusArr.join(',');
      setParamsToSend(copyArray)
    }
  }
  console.log('validamos el index', currentIndex, paramsToSend)
  return (
    <div style={{width: "100%", height: "auto"}}>
      <ListOfProductsToSetParams
        sendToFormData={sendToFormData}
      />
      {
        paramsToSend.length > 0 && (

          <FormProduct.ContForms>
            <FormProduct.Contform>
              <FormProduct.HeaderContForm>
                <Text size="13pt" fw="500" cl="#FFFFFF">
                  Parametros Generales
                </Text>
              </FormProduct.HeaderContForm>
              <FormProduct.BodyContForm>
                
                <FormProduct.ContInputAndLabel w="340px" mr="5px">
                  <Text size="12pt" fw="500">
                    Tipo de publicacion
                    <sup>*</sup>
                  </Text>
                  <FormProduct.Select onChange={(e) => setListinType(e.target.value)}>
                    <option value="">Seleccionar</option>
                    <option value='gold_pro'>Premiun CO</option>
                    <option value='gold_premium'>Oro Premiun CO</option>
                    <option value='gold'>Oro CO</option>
                    <option value='gold_special'>Premiun VE</option>
                  </FormProduct.Select>
                </FormProduct.ContInputAndLabel>
              </FormProduct.BodyContForm>
            </FormProduct.Contform>

            <FormProduct.Contform>
              <FormProduct.HeaderContForm>
                <Text size="13pt" fw="500" cl="#FFFFFF">
                  Llene los  parametros para el producto a publicar
                </Text>
              </FormProduct.HeaderContForm>
              <FormProduct.BodyContForm>
                <FormProduct.ContInputAndLabel mr="5px">
                  <Text size="12pt" fw="500">
                    Titulo
                    <sup>*</sup>
                  </Text>
                  <FormProduct.Input
                    placeholder='Titulo de la Publicacion'
                    value={paramsToSend[currentIndex].title || ''}
                    onChange={(e) => titleFunc(e.target.value)}
                    maxLength={60}
                  />
                </FormProduct.ContInputAndLabel>

                <FormProduct.ContInputAndLabel w="340px">
                  <Text size="12pt" fw="500">
                    Precio
                    <sup>*</sup>
                  </Text>
                  <FormProduct.Input
                    disabled={true}
                    placeholder='Precio para la publicacion'
                    value={paramsToSend[currentIndex].price}
                  />
                </FormProduct.ContInputAndLabel>

                <FormProduct.ContInputAndLabel w="340px">
                  <Text size="12pt" fw="500">
                    Como publicar variantes
                    <sup>*</sup>
                  </Text>
                  <FormProduct.Select
                    value={paramsToSend[currentIndex].publishGroup}
                    onChange={(e) => { publishGroupedV(e.target.value) }}
                  >
                    <option value="">Seleccionar</option>
                    <option value='groupd'>Agrupadas</option>
                    <option value='ungroup'>Separadas</option>
                  </FormProduct.Select>
                </FormProduct.ContInputAndLabel>

                <FormProduct.ContInputAndLabel w="340px">
                  <Text size="12pt" fw="500">
                    Estatus de la Publicacion
                    <sup>*</sup>
                  </Text>
                  <FormProduct.Select
                    value={paramsToSend[currentIndex].pausePublication}
                    onChange={(e) => { pausePublicationFunc(e.target.value) }}
                  >
                    <option value="">Seleccionar</option>
                    <option value='active'>Activa</option>
                    <option value='paused'>Pausada</option>
                  </FormProduct.Select>
                </FormProduct.ContInputAndLabel>

                <FormProduct.ContInputAndLabel w="340px">
                  <Text size="12pt" fw="500">
                    Marca de Producto
                    <sup>*</sup>
                  </Text>
                  <FormProduct.Input
                    disabled={blockField}
                    placeholder='La marca para el producto'
                    value={paramsToSend[currentIndex].brand}
                    onChange={(e) => brandFunc(e.target.value)}
                  />
                </FormProduct.ContInputAndLabel>

                <FormProduct.ContInputAndLabel w="340px">
                  <Text size="12pt" fw="500">
                    Modelo del Producto
                    <sup>*</sup>
                  </Text>
                  <FormProduct.Input
                    disabled={blockField}
                    placeholder='Modelo del producto'
                    value={paramsToSend[currentIndex].model}
                    onChange={(e) => modelFunc(e.target.value)}
                  />
                </FormProduct.ContInputAndLabel>

                <FormProduct.ContInputAndLabel w="340px">
                  <Text size="12pt" fw="500">
                    Tipo de Garantia
                  </Text>
                  <FormProduct.Select
                    value={paramsToSend[currentIndex].warranty_type}
                    onChange={(e) => warrantyTypeFunc(e.target.value)}
                  >
                    <option value="">Seleccionar</option>
                    <option value='2230280'>Garantía del vendedor</option>
                    <option value='2230279'>Garantía de fábrica</option>
                    <option value='6150835'>Sin garantía</option>
                  </FormProduct.Select>
                </FormProduct.ContInputAndLabel>

                <FormProduct.ContInputAndLabel w="340px">
                  <Text size="12pt" fw="500">
                    Tiempo expresado dias o meses
                  </Text>
                  <FormProduct.Input
                    placeholder='Ex: 90'
                    value={paramsToSend[currentIndex].warranty_value}
                    onChange={(e) => warrantyValueFunc(e.target.value)}
                  />
                </FormProduct.ContInputAndLabel>

                <FormProduct.ContInputAndLabel w="160px">
                  <Text size="12pt" fw="500">
                    Peso P
                  </Text>
                  <FormProduct.Input
                    disabled={blockField}
                    placeholder='Peso para el producto'
                    value={paramsToSend[currentIndex].weight}
                    onChange={(e) => weightFunc(e.target.value)}
                  />
                </FormProduct.ContInputAndLabel>

                <FormProduct.ContInputAndLabel w="160px">
                  <Text size="12pt" fw="500">
                    Altura P
                  </Text>
                  <FormProduct.Input
                    disabled={blockField}
                    placeholder='Altura para el producto'
                    value={paramsToSend[currentIndex].height}
                    onChange={(e) => heightFunc(e.target.value)}
                  />
                </FormProduct.ContInputAndLabel>

                <FormProduct.ContInputAndLabel w="160px">
                  <Text size="12pt" fw="500">
                    Ancho P
                  </Text>
                  <FormProduct.Input
                    disabled={blockField}
                    placeholder='Ancho para el producto'
                    value={paramsToSend[currentIndex].width}
                    onChange={(e) => widthFunc(e.target.value)}
                  />
                </FormProduct.ContInputAndLabel>

                <FormProduct.ContInputAndLabel w="160px">
                  <Text size="12pt" fw="500">
                    Longitud P
                  </Text>
                  <FormProduct.Input
                    disabled={blockField}
                    placeholder='Longitud para el producto'
                    value={paramsToSend[currentIndex].length_prod}
                    onChange={(e) => length_prodFunc(e.target.value)}
                  />
                </FormProduct.ContInputAndLabel>

                <FormProduct.ContInputAndLabel>
                  <Text size="12pt" fw="500">
                    Descripcion del Producto
                  </Text>
                  <FormProduct.TextArea
                    placeholder='Descripcion para el producto'
                    value={paramsToSend[currentIndex].body}
                    onChange={(e) => bodyFunc(e.target.value)}
                  />
                </FormProduct.ContInputAndLabel>

                {
                  blockField ? (

                    (paramsToSend[currentIndex].imagesPlus.length > 0) && (
                      <FormProduct.ContImgPlus>
                        {
                          paramsToSend[currentIndex].imagesPlus.map((v, i) => {
                            
                            return (
                              <FormProduct.ImgPlus url={v} key={i} >

                              </FormProduct.ImgPlus>
                            )
                          })
                        }
                      </FormProduct.ContImgPlus>
                    )


                  ) : (
                    <>
                      <form onSubmit={(e) => addImgPlus(e)} style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <FormProduct.ContInputAndLabel>
                          <Text size="12pt" fw="500">
                            Imagenes adicionales
                          </Text>
                          <FormProduct.Input

                            placeholder='URLs de la imagen'
                            ref={urlPlusInput}
                            onChange={(e) => setUrlImgPlus(e.target.value)}
                          />
                        </FormProduct.ContInputAndLabel>
                      </form>
                      {
                        (!blockField && paramsToSend[currentIndex].imagesPlus.length > 0) && (
                          <FormProduct.ContImgPlus>
                            {
                              paramsToSend[currentIndex].imagesPlus.split(',').map((v, i) => {
                                return (
                                  <FormProduct.ImgPlus url={v} key={i} onClick={() => removeImg(v)}>

                                  </FormProduct.ImgPlus>
                                )
                              })
                            }
                          </FormProduct.ContImgPlus>
                        )
                      }
                    </>
                  )
                }
              </FormProduct.BodyContForm>
            </FormProduct.Contform>
          </FormProduct.ContForms>
        )
      }
    </div>
  )
}
