import axios from "axios";
import { keyAccessToken, keyUserId } from "../../../../../assets/js/SessionStorageNames";

export const updatePriceAndStock = (enterpriseId, brand) => {

    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/update-all-odoo-price-products-by-ent`,
        {
            enterpriseId,
            ...(brand.length > 0 && {brand})
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export const sendAllProducts = (enterpriseId, brand) => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/send-all-odoo-products-by-ent`,
        {
            enterpriseId,
            ...(brand.length > 0 && {brand})
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}


export const sendOneProduct = (enterpriseId, id) => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/send-one-odoo-product`,
        {
            enterpriseId,
            id
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export const updateImages = (enterpriseId, idProd) => {

    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/update-all-odoo-images-products-by-ent`,
        {
            enterpriseId,
            ...(idProd.length > 0 && {idProd})
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export const updateDimensions = (enterpriseId, brand) => {

    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/update-all-odoo-dimensions-and-descriptions-products-by-ent`,
        {
            enterpriseId,
            ...(brand.length > 0 && {brand})
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export const updateOneProduct = (enterpriseId, idProd) => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/update-one-odoo-products-by-ent`,
        {
            enterpriseId,
            idProd
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export const linkOdooProd = (entId, xlsx) => {

    const headers = {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
    };
    const headersMultipart = { ...headers };
    headersMultipart["Content-Type"] = "multipart/form-data";

    const formData = new FormData();
    formData.append("xlsx", xlsx);

    return axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/link-product-odoo-xsls/${entId}`,
        formData,
        {
            headers: headersMultipart,
        }
    )
}


export const getCredencials = async  (enterpriseId) => {
    const resp = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/v1/woo-acc/get-cred/${enterpriseId}`,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return resp
}

export const sendAllProductVariants = (enterpriseId, brand) => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/send-all-odoo-variants-by-ent`,
        {
            enterpriseId,
            ...(brand.length > 0 && {brand})
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}


export const updateAllProductVariants = (enterpriseId, brand) => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/update-all-odoo-variants-price-and-stock-by-ent`,
        {
            enterpriseId,
            ...(brand.length > 0 && {brand})
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}