import React, { useState, useContext, useEffect } from 'react'
import { ContModal } from '../../../../../assets/css/components/admin/marketplace-to-marketplace/modal-post-product/ModalContStyles';

import { GlobalContext } from '../../../../../contexts/GlobalContext';
import LayoutModalPy from './py/LayoutModalPy';
import SelectPluginToPost from './SelectPluginToPost';
import LayoutModalMl from './ml/LayoutModalMl';
export default function ModalPostProduct() {
    const globalContext = useContext(GlobalContext);
    const [currentStep, setCurrentStep] = useState(2);
    const [currentPlugin, setCurrentPlugin] = useState('ml');

    useEffect(() => {
        if(!globalContext.modalPostProduct) {
            setCurrentStep(1)
            setCurrentPlugin(null)
        }
    }, [globalContext.modalPostProduct])

    const renderStepByPlugin = () => {
        switch (currentPlugin) {
            case 'py':
                return (
                    <LayoutModalPy 
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        defaultPlugin={defaultPlugin}
                    />
                )
            case 'ml':
                return (
                    <LayoutModalMl 
                        currentPlugin={currentPlugin} 
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        defaultPlugin={defaultPlugin}
                        />
                )
            case 'not_have_plugin':
                return (
                    <SelectPluginToPost 
                        currentPlugin={currentPlugin} 
                        setCurrentPlugin={setCurrentPlugin}
                        nextStep={nextStep}
                    />
                )
            default:
                return (
                    <SelectPluginToPost 
                        currentPlugin={currentPlugin} 
                        setCurrentPlugin={setCurrentPlugin}
                        nextStep={nextStep}
                    />
                );
        }
    }

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    }

    const defaultPlugin = () => {
        setCurrentPlugin('not_have_plugin')
    }


// / active={globalContext.modalPostProduct}
    return (
        <ContModal active={globalContext.modalPostProduct}>
            {
                renderStepByPlugin()
            }
        </ContModal>
    )
}
