import React from 'react'
import { useContext } from 'react'
import PublishProduct from '../../../../../assets/css/components/admin/products/amazon/ModalPublishProduct'
import { GlobalContext } from '../../../../../contexts/GlobalContext'
import { faAngleLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ModalOtherMarketplace() {
    const globalContext = useContext(GlobalContext);

    const closeModalPublish = () => {
        globalContext.setModalOrdenOtherMarketplace(false);
    }
    return (
        <PublishProduct.ContModal d={globalContext.modalOrdenOtherMarketplace ? 'flex' : 'none'}>
            <PublishProduct.ModalProduct>
            <PublishProduct.headerModal>
                    <PublishProduct.btnCloseModal onClick={() => { closeModalPublish() }}>
                        <FontAwesomeIcon icon={faTimes} />
                    </PublishProduct.btnCloseModal>
                    <PublishProduct.textHeader size='15pt' ml='auto' mr='auto'>
                        Datos para una orden de otro Marketplace
                    </PublishProduct.textHeader>
                </PublishProduct.headerModal>
                <PublishProduct.bodyModal>
                    inputs
                </PublishProduct.bodyModal>
            </PublishProduct.ModalProduct>
        </PublishProduct.ContModal>
    )
}
