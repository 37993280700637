import styled from "styled-components";

export const ContModal = styled.div.attrs(props => ({
    display: props.active ? 'flex' : 'none',
}))`
width: 100%;
position: absolute;
height: 100%;
top:0;
left: 0;
bottom: 0;
right: 0;
display: ${props => props.display};
background: #FFF;
z-index: 9;
padding: 1rem;
flex-direction: column;
justify-content: center;
align-items: center;
overflow: auto;
`;

export const BtnClose = styled.div.attrs(props => ({
    w: props.w || '30px',
    h: props.h || '30px',
    bgc: props.bgc || 'transparent',
    c: props.c || '#6e6b7b',
    br: props.br || '1px solid #6e6b7b',
    t:  props.t || '10px',
    l:  props.l || 'auto',
    r:  props.r || '10px',
    b:  props.b || 'auto',
}))`
color: ${props => props.c};
background: ${props => props.bgc};
width: ${props => props.w};
height: ${props => props.h};
outline: none !important;
border: ${props => props.br};
border-radius: 50px;
display: flex;
justify-content: center;
align-items: center;
/* position: absolute;
top: ${props => props.t};
left: ${props => props.l};
right: ${props => props.r};
bottom: ${props => props.b}; */
font-size: 13pt;
cursor: pointer;
`;

export const ContLabelStep = styled.div`
width: auto;
height: auto;
margin-bottom: 10px;
`;

export const ContStep  = styled.div`
width: 100%;
margin-top: 10px;
height: auto;
display: flex;
justify-content: center;
align-items: center;
`

export const BtnGeneral = styled.button.attrs(props => ({
    bgc: props.bgc || '#7367F0',
    c: props.color || "#FFFFFF"
}))`
width: 100px;
height: 38px;
border: none;
border-radius: 40px;
outline: none !important;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
color: ${props => props.c};
background: ${props => props.bgc};
`;

export const ModalContainer = styled.div`
    width: 100%;
    height: auto;
    min-height: 100%;
`