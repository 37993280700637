const isChecked = (dataCard, isInclude, isIncludeErrors) => {
    if(!dataCard.hasError) {
        const isHere = isInclude.find( v => v._id === dataCard._id);
        if(isHere !== undefined) {
            return true;
        }else {
            return false;
        }
    }else {
        const isHere = isIncludeErrors.find( v => v._id === dataCard._id);
        if(isHere !== undefined) {
            return true;
        }else {
            return false;
        }
    }
}

export default isChecked;