import React from 'react'
import SendDataCss from '../../../../../assets/css/components/admin/products/post-product/steps/SendDataCss'

export default function SendData(props) {
    const {
        imgInArraySend,
        listOfLastParents,
        valuesCategorys
    } = props;
    return (
        <SendDataCss.cont>
            <SendDataCss.header>
                <SendDataCss.text size="15pt" fw="600">
                    Publique su producto
                </SendDataCss.text>
            </SendDataCss.header>
            <SendDataCss.body>
                <SendDataCss.contRow>
                    <SendDataCss.text size="15pt" fw="500" ml="10px">
                        Categoria en la que se publicara
                    </SendDataCss.text>
                </SendDataCss.contRow>
                <SendDataCss.contRow style={{paddingLeft: '15px'}}>
                    {
                        listOfLastParents.length > 0 &&
                            listOfLastParents.map((v, i) => {
                                if (listOfLastParents.length -1 === i) {
                                    return (
                                        <SendDataCss.text key={v._id} size="15pt" style={{color: "#7367F0"}}>
                                            {v.name}
                                        </SendDataCss.text>
                                    )
                                }else {
                                    return (
                                        <SendDataCss.text key={v._id} size="15pt" style={{color: "#7367F0"}} >
                                            {v.name} {" > "}
                                        </SendDataCss.text>
                                    )
                                }
                                })
                    }
                </SendDataCss.contRow>
                <SendDataCss.contRow>
                    <SendDataCss.text size="15pt" fw="500" ml="10px">
                        Imagenes del producto
                    </SendDataCss.text>
                </SendDataCss.contRow>
                <SendDataCss.gridData>
                    {
                        imgInArraySend &&
                        imgInArraySend.map((v,i) =>{
                            return (
                                <SendDataCss.contImage img={v} key={i} />
                            )
                        })
                    }
                </SendDataCss.gridData>
                <SendDataCss.contRow>
                    <SendDataCss.text size="15pt" fw="500" ml="10px">
                        Detalles del producto
                    </SendDataCss.text>
                </SendDataCss.contRow>
                <SendDataCss.gridDataLabels>
                    {
                        valuesCategorys && 
                        valuesCategorys.map((v,i) => {
                            return (
                                <SendDataCss.labelInput key={i}>
                                    <SendDataCss.text size="13pt" fw="600">
                                        {v.label}
                                    </SendDataCss.text>
                                    {
                                        v.inputType === 'dropdown' ?
                                        (
                                        <SendDataCss.text size="12pt" fw="300" ml="5px">
                                            {v.textByDropDown.length > 0 ? v.textByDropDown : 'No se agrego'}
                                        </SendDataCss.text>
                                        )
                                        :
                                        (
                                            <SendDataCss.text size="12pt" fw="300" ml="5px">
                                                {v.value.length > 0 ? v.value : 'No se agrego'}
                                            </SendDataCss.text>
                                        )
                                    }
                                </SendDataCss.labelInput>
                            )})
                    }
                </SendDataCss.gridDataLabels>
            </SendDataCss.body>
        </SendDataCss.cont>
    )
}
