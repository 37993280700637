import React, { useState } from "react";
import DropDownButtonStyle from "../../../../assets/css/components/admin/products/DropDownButton.css";
import SyncProductsOption from './dropDownButtonOptions/SyncProductsOption';
import DownloadProductsDataOption from "./dropDownButtonOptions/DownloadProductsDataOption";
import SyncLinkedOption from "./dropDownButtonOptions/SyncLinkedOption";
import DetailedReportOption from "./dropDownButtonOptions/DetailedReportOption";
import PricelistReportOption from "./dropDownButtonOptions/PricelistReportOption";
import SyncImagePlus from "./dropDownButtonOptions/SyncImagePlus";
import SyncImagesNew from "./dropDownButtonOptions/SyncImagesNew";
import DownloadLinioOrders from "./dropDownButtonOptions/DownloadLinioOrders";
import SyncProdByCategories from "./dropDownButtonOptions/SyncProdByCategories";
import SyncPrice from "./dropDownButtonOptions/SyncPrice";

export default function DropDownButtonOptions(props) {
    const [openDropContent, setOpenDropContent] = useState(false);
    const [changeIcon, setChangeIcon] = useState(false);

    const changeStatusDorpFocus = (e) => {
        e.preventDefault();
        setOpenDropContent(!openDropContent);
        setChangeIcon(!changeIcon);
    };
    const changeStatusDorpBlur = (e) => {
        e.preventDefault();
        setOpenDropContent(false);
        setChangeIcon(false);
    };


    return (
        <DropDownButtonStyle>
            <button
                className={`DropBtn ${changeIcon ? "open" : ""}`}
                onClick={(e) => changeStatusDorpFocus(e)}
                onBlur={(e) => changeStatusDorpBlur(e)}
            >
                <div className="content-text-icon">
                    <div className="content-text">
                        <p>{props.buttonTittle}</p>
                    </div>
                    <div className="content-icon">
                        <span className="icon">
                            <span
                                className={`rectangle rectangle1 ${changeIcon ? "open" : ""}`}
                            />
                            <span
                                className={`rectangle rectangle2 ${changeIcon ? "open" : ""}`}
                            />
                        </span>
                    </div>
                </div>
                <div className={`optionsDrop ${changeIcon ? "open" : ""}`}>
                    <SyncProductsOption
                        currentPlugin={props.currentPlugin}
                        numRefresh={props.numRefresh}
                        setNumRefresh={props.setNumRefresh}
                    />
                    {
                        props.currentPlugin === "odoo"
                        && 
                        <>
                        <SyncPrice currentPlugin={props.currentPlugin} />
                        <SyncImagePlus
                            currentPlugin={props.currentPlugin}
                            numRefresh={props.numRefresh}
                            setNumRefresh={props.setNumRefresh}
                        />
                        <SyncImagesNew 
                            currentPlugin={props.currentPlugin}
                            numRefresh={props.numRefresh}
                            setNumRefresh={props.setNumRefresh}
                        />
                        </>
                    }
                    <DownloadProductsDataOption
                        format='csv'
                        currentPlugin={props.currentPlugin}
                        filter={props.filter}
                        filterLinkedTo={props.filterLinkedTo}
                        filterPrice={props.filterPrice}
                        filterStock={props.filterStock}
                        filterWithCompany={props.filterWithCompany}
                    />
                    <DownloadProductsDataOption
                        format='xlsx'
                        currentPlugin={props.currentPlugin}
                        filter={props.filter}
                        filterLinkedTo={props.filterLinkedTo}
                        filterPrice={props.filterPrice}
                        filterStock={props.filterStock}
                        filterWithCompany={props.filterWithCompany}
                    />
                    {
                        props.currentPlugin === props.mainPlugin
                        && <SyncLinkedOption
                            currentPlugin={props.currentPlugin}
                            numRefresh={props.numRefresh}
                            setNumRefresh={props.setNumRefresh}
                        />
                    }
                    {
                        props.currentPlugin === "odoo"
                        && (<>
                            <DetailedReportOption
                                format="csv"
                                currentPlugin={props.currentPlugin}
                                filter={props.filter}
                                filterLinkedTo={props.filterLinkedTo}
                                filterPrice={props.filterPrice}
                                filterStock={props.filterStock}
                                filterWithCompany={props.filterWithCompany}
                            />
                            <DetailedReportOption
                                format="xlsx"
                                currentPlugin={props.currentPlugin}
                                filter={props.filter}
                                filterLinkedTo={props.filterLinkedTo}
                                filterPrice={props.filterPrice}
                                filterStock={props.filterStock}
                                filterWithCompany={props.filterWithCompany}
                            />
                            <PricelistReportOption
                                format="xlsx"
                            />
                        </>)
                    }

                    {
                        props.currentPlugin === 'odoo'
                        && (
                            <SyncProdByCategories />
                        )
                    }
                    {
                        (props.currentPlugin === 'ln' || props.currentPlugin === 'df')
                        &&(
                            <DownloadLinioOrders
                            currentPlugin={props.currentPlugin}
                            />
                        )
                    }
                </div>
            </button>
        </DropDownButtonStyle>
    );
}
