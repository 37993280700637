import React, { useState } from 'react'
import QuestionsListStyle from '../../../../assets/css/components/admin/ml-questions/QuestionsListStyle'
import { IoCheckmarkDoneOutline } from 'react-icons/io5'
import { GoPrimitiveDot } from 'react-icons/go'
export default function CardQuestion(props) {
    const {
        data, itemActive, setItemActive, 
        setProductIdSearch, setDataQuestionSelected, setShowList,
        setIndexChange, index
    } = props;
    const addActiveItem = (v, v2, v3) => {
        setItemActive(v); 
        setProductIdSearch(v2);
        setDataQuestionSelected(v3)
        setShowList(false)
        setIndexChange(index);
        //console.log('index a usar',index)
    }
    const changeDate = date => {
        const repOne = date.replace('T', ' ');
        const repTwo = repOne.replace('Z', 'S');
        return repTwo
    }
    return (
        <QuestionsListStyle.cardQuestionCont  onClick={() => addActiveItem(data._id, data.product, data)}>
            <QuestionsListStyle.isFocusAnyCard active={itemActive === data._id}>
                <GoPrimitiveDot />
            </QuestionsListStyle.isFocusAnyCard>
            <QuestionsListStyle.text fw="500" style={{alignSelf: 'flex-end'}}>
                {changeDate(data.date)}
            </QuestionsListStyle.text>
            <QuestionsListStyle.cardQuestionTextAndStatus>
                <div className="cont-text">
                    {data.text}
                </div>
                <QuestionsListStyle.isAnswer active={data.status === 'ANSWERED'}>
                    <IoCheckmarkDoneOutline />
                </QuestionsListStyle.isAnswer>
            </QuestionsListStyle.cardQuestionTextAndStatus>
        </QuestionsListStyle.cardQuestionCont>
    )
}
