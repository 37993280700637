import styled from 'styled-components';

const cont = styled.div`
    box-shadow: 0px 0px 5px 2px rgb(112 112 112 / 42%);
    height: auto;
    width: 250px;
    max-height: 250px !important;
    overflow-x: hidden;
    overflow-y: ${props => props.active ? 'auto' : 'hidden'};
    border-radius: .3rem;
    background-color: #FFFFFF;
    position: absolute;
    transform: translate(-22px, 23px);
    display: ${props => props.active ? 'block' : 'none'};
    transition: all ease-in 0.2s;
    /* width */
    ::-webkit-scrollbar {
        width: 2.5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgb(94, 84, 142);
        border-radius: 50px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #7367f0;
    }
`;

const listOption = styled.ul`
list-style: none;
padding-left: 0;
width: 100%;
`;
const listOptionItems = styled.li`
padding:10px;
border-bottom: 1px solid rgb(112 112 112/ 36%);
color: #707070;
font-size:12pt;
cursor: pointer;
text-align: start;
&:hover {
    background-color: rgb(112 112 112/ 11%);
}
`;

const OptionsProductsHistoryCss = {
    cont,
    listOption,
    listOptionItems
}

export default OptionsProductsHistoryCss;