import styled from 'styled-components';

const ContListProd = styled.div`
width: 100%;
height: auto;
position: relative;
margin-top: 10px;
margin-bottom: 10px;
padding: 10px;
border-radius: 10px;
`;

const ListProduts = styled.ul`
    list-style: none;
    padding-left: 0px;
    width: 100%;
    max-width: 100%
    height: 140px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
`;

const ItemsListProduts = styled.li.attrs(props => ({
    brc: props.active ? 'solid 5px #7367F0' : 'solid 5px transparent',
    }))`
    background-image: ${props => `url(${props.url})`};
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
    width: auto;
    height: 70px;
    padding: 1rem;
    color: #7367F0;
    font-weight: 500;
    margin-left: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: ${props => props.brc};
    cursor: pointer;
    box-shadow: 0 2px 8px 0 rgb(34 41 47 / 14%);
`
const ListOfficalStores = {
    ContListProd,
    ListProduts,
    ItemsListProduts
}

export default ListOfficalStores;