import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';


const processImg = (entId) => {
    const resp = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/clean-img/process-list-img-to-clean`, {
        enterpriseId: entId
    }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return resp
}

export default processImg