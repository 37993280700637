import React from 'react'
import GraphicTwoStyle from '../../../../../../assets/css/components/admin/dashboard/dashboard-graphics-body/GraphicTwoStyle.css'
import {Doughnut} from "react-chartjs-2";
export default function GraphicTwo(props) {
  
    const data = {
      labels: props.labelsData,
      datasets: props.dataSets,
    };
    const options = {
      responsive: true,
      maintainAspectRatio: true,

      title: {
        display: true,
        text: props.titleText,
      },
      legend: {
        display: true,
        labels: {
          fontColor: "rgb(94,84,142)",
          usePointStyle:true,
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              display: false,
            },
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };
    return (
      <GraphicTwoStyle>
        <div className="doughnut-chart-content">
          <Doughnut data={data} options={options} width={500} height={300} />
        </div>
      </GraphicTwoStyle>
    );
}
