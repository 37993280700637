import React, { useContext, useState } from 'react'
import { BiX } from 'react-icons/bi'
import { BtnCloseMenu, ContMenuCard, ContTitleMenu, ListOptions, OptionList } from '../../../../../../../assets/css/components/admin/marketplace-to-marketplace/cardItem/CardItemStyle'
import { Text } from '../../../../../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts'
import { GlobalContext } from '../../../../../../../contexts/GlobalContext';
import powerOff from '../../../functions/powerOff';
import powerOn from '../../../functions/powerOn';
import axios from 'axios'
import deleteOne from '../../../functions/deleteOne';
export default function MenuCard({ active, setActive, data, pluginSearch }) {
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const globalContext = useContext(GlobalContext);
    const closeMenu = () => {
        setActive(false);
    }
    // const openLinkModal = () => {
    //     globalContext.setidProductLink(data._id);
    //     globalContext.setModalLinkOneProduct(true)
    // }

    const turnOff = () => {
        const entId = globalContext.currentEnterpriseId;
        console.log('valores a enviar', data)
        powerOff(entId, data._id, axiosCancelTokenSource)
            .then(res => {
                console.log('lo hizo', res);
            }).catch(error => {
                console.log('dio error', error);
            })
    }
    const turnOn = () => {
        const entId = globalContext.currentEnterpriseId;
        console.log('valores a enviar', data)
        powerOn(entId, data._id, axiosCancelTokenSource)
            .then(res => {
                console.log('lo hizo', res);
            }).catch(error => {
                console.log('dio error', error);
            })
    }

    const deleteOneProd = () => {
        const entId = globalContext.currentEnterpriseId;
        deleteOne(entId, data._id)
            .then(resp => console.log(resp))
            .catch(err => console.log(err))
    }

    /**?
     * <OptionList onClick={() => openLinkModal()}>
                        <Text size="12pt" fw="500">
                            Linkear Producto
                        </Text>
                    </OptionList>
     */

    return (
        <ContMenuCard active={active}>
            <BtnCloseMenu onClick={() => closeMenu()}>
                <BiX />
            </BtnCloseMenu>
            <ContTitleMenu>
                <Text size="12pt" fw="500">
                    Opciones
                </Text>
            </ContTitleMenu>
            <ListOptions>
                <OptionList>
                    <Text size="12pt" fw="500">
                        Detalle del Producto
                    </Text>
                </OptionList>
                {
                    data.peyaInfo &&
                        (
                            <>
                                {
                                    (data.peyaInfo.status || !data.peyaInfo.status) ?
                                        (
                                            <OptionList onClick={() => turnOff()}>
                                                <Text size="12pt" fw="500">
                                                    Pausar Producto
                                                </Text>
                                            </OptionList>
        
                                        ) :
                                        (
                                            <OptionList onClick={() => turnOn()}>
                                                <Text size="12pt" fw="500">
                                                    Activar Producto
                                                </Text>
                                            </OptionList>
                                        )
                                }
                                <OptionList onClick={() => deleteOneProd()}>
                                    <Text size="12pt" fw="500">
                                        Eliminar en Pedidos Ya
                                    </Text>
                                </OptionList>
                            </>
                        )
                }
            </ListOptions>
        </ContMenuCard>
    )
}
