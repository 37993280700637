import React from 'react'
import { ContCard, ContDataInfoOne, ContDataInfoTitle } from '../../../../../../../assets/css/components/admin/marketplace-to-marketplace/cardItem/CardItemStyle'
import { ContInputAndLabel, Text } from '../../../../../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts';
import CardContImageView from './CardContImageView';

export default function CardViewProd({data}) {

    return (
        <ContCard>
            <CardContImageView urlImage={data.thumbnail} id={data._id} />
            <ContDataInfoOne>
                <Text fw="bold" cl="#515151" size="12px">
                    {`${data.stock} Cant.`}
                </Text>
                <Text fw="bold" cl="#515151" size="12px">
                    {data.price}
                </Text>
            </ContDataInfoOne>
            <ContDataInfoTitle>
                {data.title}
            </ContDataInfoTitle>
            <ContDataInfoOne>
                <ContInputAndLabel>
                    <Text fw="bold" cl="#515151" size="12px" mb="0">
                        Cod Bar:
                    </Text>
                    <Text cl="#515151" size="12px" mt="2px" ml="10px">
                        {data.barCode}
                    </Text>
                </ContInputAndLabel>
                <ContInputAndLabel>
                    <Text fw="bold" cl="#515151" size="12px" mb="0">
                        SKU:
                    </Text>
                    <Text cl="#515151" size="12px" mt="2px" ml="10px">
                        {data.sku}
                    </Text>
                </ContInputAndLabel>
            </ContDataInfoOne>
        </ContCard>
    )
}
