import React, { useState } from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
/**
 * ? reutilizamos los estilos para el filtro
 */
import FilterEnterpriseStyle from "../../../assets/css/components/admin/enterprise/dashboard/FilterEnterprise.css";

export default function UserConfigUserFilter(props) {
  const [currentFilter, setCurrentFilter] = useState(props.filter);

  /**
   * Updates the filter value
   */
  const updateCurrentFilter = (e) => {
    setCurrentFilter(e.target.value);
  }

  return (
    <div>
      <FilterEnterpriseStyle>
        <InputGroup>
          <FormControl
            placeholder="Nombre"
            aria-label="Nombre"
            aria-describedby="basic-addon2"
            onChange={updateCurrentFilter}
          />
          <InputGroup.Append>
            <Button
              onClick={() => {props.updateFilter(currentFilter)}}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </FilterEnterpriseStyle>
    </div>
  );
}
