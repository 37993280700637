import styled from "styled-components";
const PluginWorksStyles = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  select {
    font-family: "Roboto Slab", serif !important;
    box-shadow: none;
    outline: none;
    border: 0.5px solid #5e548e;
    transition: all ease 450ms;
    &:hover {
      box-shadow: none;
      color: #5e548e;
      border-color: #cec28e;
    }
    &:focus {
      box-shadow: none;
      color: #5e548e;
      border-color: #cec28e;
    }
    &:active {
      box-shadow: none;
      color: #5e548e;
      border-color: #cec28e;
    }
  }
`;
export default PluginWorksStyles;
