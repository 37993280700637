import axios from "axios";
import { keyAccessToken, keyUserId } from "../../../../../assets/js/SessionStorageNames";
const buildToken = () => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL_ANICAN}/v1/anicam/anicam`,
    {},
        {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export default buildToken