import { BsPencil } from 'react-icons/bs';
import { BiPauseCircle } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import React, { useContext } from 'react'
import ListFiltersStyles from '../../../../assets/css/components/admin/filters/ListFiltersCss'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
import { GlobalContext } from '../../../../contexts/GlobalContext';
export default function TableDataAdmin(props) {
    const { data } = props;
    const globalContext = useContext(GlobalContext);

    const deleteUser = (id) => {
        let r = window.confirm("¿Desea eliminar este usuario?");
        if (r === true) {
            axios({
                method: "delete",
                url: `${process.env.REACT_APP_BACKEND_URL}/v1/user/admin/${id}`,
                params: {
                    pageNumber: 1,
                    pageItemCount: 1,
                    filter: { name: '' },
                },
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            })
                .then((res) => {
                    globalContext.showModalWithMsg(
                        "El usuario fue eliminado exitosamente"
                    );
                    props.refreshUsersList();
                })
                .catch((err) => {
                    globalContext.showModalWithMsg(
                        "Disculpe ha ocurrido un error al intentar eliminar el usuario"
                    );
                });
        }
    };

    return (
        <ListFiltersStyles.tableData>
            <ListFiltersStyles.tableDataThead>
                <tr>
                    <ListFiltersStyles.tableDataTh
                        w="200px"
                    >
                        Correo
                    </ListFiltersStyles.tableDataTh>
                    <ListFiltersStyles.tableDataTh
                        w="100px"
                    >
                        Nombre
                    </ListFiltersStyles.tableDataTh>
                    <ListFiltersStyles.tableDataTh
                        w="100px"
                    >
                        RutesRol
                    </ListFiltersStyles.tableDataTh>
                    <ListFiltersStyles.tableDataTh
                        w="100px"
                    >
                        Status
                    </ListFiltersStyles.tableDataTh>
                    <ListFiltersStyles.tableDataTh
                        w="100px"
                        ta="center"
                    >
                        Accion
                    </ListFiltersStyles.tableDataTh>
                </tr>
            </ListFiltersStyles.tableDataThead>
            <ListFiltersStyles.tableDataTbody>
                {
                    data.length > 0 &&
                    data.map((v, i) => {
                        return (
                            <ListFiltersStyles.tableDataTr key={v._id}>
                                <ListFiltersStyles.tableDataTd
                                    w="200px"
                                >
                                    {v.email}
                                </ListFiltersStyles.tableDataTd>
                                <ListFiltersStyles.tableDataTd
                                    w="100px"
                                >
                                    {v.name}
                                </ListFiltersStyles.tableDataTd>
                                <ListFiltersStyles.tableDataTd
                                    w="100px"
                                >
                                    {v.rolRoute}
                                </ListFiltersStyles.tableDataTd>
                                <ListFiltersStyles.tableDataTd
                                    w="100px"
                                >
                                    {v.status ? "Habilitado" : "Inhabilitado"}
                                </ListFiltersStyles.tableDataTd>

                                <ListFiltersStyles.tableDataTd
                                    w="100px"
                                    ta="center !important"
                                >
                                    <ListFiltersStyles.contIcons>

                                        <Link to={`update-user-admin/${v._id}`}>
                                            <ListFiltersStyles.btnActionIcon >
                                                <BsPencil size="25px" />
                                            </ListFiltersStyles.btnActionIcon>
                                        </Link>
                                        <ListFiltersStyles.btnActionIcon onClick={() => deleteUser(v._id, i)}>
                                            <AiOutlineDelete size="25px" />
                                        </ListFiltersStyles.btnActionIcon>
                                    </ListFiltersStyles.contIcons>
                                </ListFiltersStyles.tableDataTd>
                            </ListFiltersStyles.tableDataTr>

                        )
                    })
                }
            </ListFiltersStyles.tableDataTbody>
        </ListFiltersStyles.tableData>
    )
}
