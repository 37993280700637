import styled from 'styled-components';

const cont = styled.div`
position: fixed;
top:0;
right:0;
bottom:0;
left:0;
width:100%;
height:100%;
background: rgba(112, 112, 112, 0.38);
z-index:100000;
display: ${props=>props.active ? 'flex' : 'none'};
justify-content:center;
align-items:center;
padding:10px;
overflow: auto;
`

const modalImg = styled.div`
display: flex;
flex-direction: column;
justify-content:flex-start;
align-items:center;
background-color: #ffffff;
border-radius:5px;
min-width:300px;
min-height: 500px;
margin-top:auto;
margin-bottom:auto;
width:600px;
margin-left: 10px;
margin-right:10px;
`
const headerModal = styled.div`
padding:10px;
height: 60px;
width:100%;
background:#F5F5F6;
display: flex;
justify-content:flex-start;
align-items:center;
align-self: flex-start;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
`
const footerModal = styled.div`
padding:10px;
height: 60px;
width:100%;
display: flex;
justify-content:flex-end;
align-items:center;
align-self: flex-end;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
border-top:0.5px solid rgba(112, 112, 112, 0.5);
margin-top: auto;
`;
const contInputAndLabel = styled.div`
width:100%;
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'space-between'};
align-items:${props => props.ali || 'flex-start'};
`;
const btnCloseModal = styled.button`
width:38px;
height: 38px;
border-radius:50px;
border:none;
color: #ffffff;
background-color: #7367f0;
display:flex;
justify-content:center;
align-items:center;
outline:none !important;
cursor: pointer;
margin-right: 10px;
`
const textHeader = styled.p`
margin-bottom: 0;
color: #707070;
font-size: ${props=> props.size || '12px'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
`
const Select = styled.select`
    padding-left:10px;
    padding-right:10px;
    font-family: "Berlin Rounded";
    width: ${props => props.wd || '100%'};
    margin-left: ${props => props.ml || '0'};
    margin-right: ${props => props.mr || '0'};
    height: 38px;
    border-radius: .357rem;
    background: #fff;
    border: 1px solid #d8d6de;
    outline: none !important;
    color: rgba(112, 112, 112, 0.61);
    font-size: 12pt;
    font-weight: 300;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
            box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
        }
        @media(max-width: ${props => props.selectMq || '989px'}) {
        width:100%;
    }
`;
const btnActionFooter = styled.button`
border: 0.5px solid;
border-color: ${props=> props.bc || '#707070'};
background-color: ${props=> props.bgc || 'transparent'};
padding: 8px 16px;
font-size: 12pt;
margin-left: ${props=> props.ml || '0'};
color: ${props=> props.cl || '#707070'};
border-radius:5px;
font-weight:600;
outline:none !important;
`;
const textBody = styled.p`
margin-bottom: ${props=> props.mb || '0'};
color: ${props=> props.cl || '#707070'};
font-size: ${props=> props.size || '12pt'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
font-weight: ${props=> props.fw || 'lighter'};
margin-top: ${props=> props.mt || '10px'};
text-align:justify;
@media (max-width:500px){
    margin-left: ${props=> props.mlr || '0'};
    margin-right: ${props=> props.mrr || '0'};
}
`;
const bodyModal = styled.div`
width:100%;
min-width:300px;
display:flex;
flex-direction:column;
justify-content: center;
align-items: center;
align-self:center;
padding: 0 10px;
.cont-text-body-modal {
    width:100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left:10px;
    padding-right:10px;
    margin-left:auto;
    margin-right:auto;
}
@media (max-width:550px){
    .cont-text-body-modal {
        flex-direction: column;
        justify-content: space-around;
        align-content: flex-start;
        align-items: flex-start;
    }
}
.load-screen {
        background-color: #ffffff;
        height:300px;
        width:100%;
        display:flex;
        justify-content:center;
        align-items:center;
        z-index:100000;
        .spinner-border {
            border: .25em solid #7367f0;
            border-right-color: transparent;
        }
    }
`;

const input = styled.input`
    padding-left:10px;
    font-family: "Berlin Rounded";
    width: ${props=> props.wd || '100%'};
    margin-left: ${props=> props.ml || '0'};
    margin-right: ${props=> props.mr || '0'};
    height: 38px;
    border-radius: 5px;
    background: #fff;
    border: 0.5px solid rgba(112, 112, 112, 0.7);
    outline: none !important;
    color: rgba(112, 112, 112, 0.61);
    font-size: 12pt;
    font-weight: 300;
    margin-bottom: 10px;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
        }
    @media(max-width: ${props => props.inputMq || '989px'}) {
        width:100%;
    }
`;
const inputFile = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    align-self: flex-start;
   .file {
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 2.5rem;
    }
    .file input {
    min-width: 14rem;
    margin: 0;
    filter: alpha(opacity=0);
    opacity: 1;
    }
    .file-custom {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2.5rem;
    padding: .5rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #fff;
    border: .075rem solid #ddd;
    border-radius: .25rem;
    box-shadow: inset 0 .2rem .4rem rgba(0,0,0,.05);
    -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    }
    .file-custom:after {
    content: "Seleccione su archivo";
    }
    .file-custom:before {
    position: absolute;
    top: -.075rem;
    right: -.075rem;
    bottom: -.075rem;
    z-index: 6;
    display: block;
    content: 'Buscar';
    height: 2.5rem;
    padding: .5rem 1rem;
    line-height: 1.5;
    color: #ffffff;
    background-color: #7367f0;
    border: .075rem solid #7367f0;
    border-radius: 0 .25rem .25rem 0;
    }

    /* Focus */
    .file input:focus ~ .file-custom {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
    }
`;
const contForm = styled.div`
padding:10px;
margin-left:auto;
margin-right: auto;
margin-top:10px;
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}`

const contMesaggeSucces = styled.div`
width: 100%;
margin-top: 10px;

`

const contImgSend = styled.div`
min-width: 200px;
min-height: 200px;
max-width: 500px;
min-height: 200px;
width: 100%;
height: 500px;
border: 1px solid #f5f5f6;
border-radius: .5rem;
margin-top: 10px;
background-image: url(${props=> props.img ? `${props.img}` : "/photo.jpg"});
background-repeat: no-repeat;
background-size: contain;
background-position: center center;
background-color: #ffffff;
`
const text = styled.p`
margin-bottom:  ${props => props.mb || '0'};
color: ${props => props.color || '#707070'};
font-size: ${props => props.size || '13pt'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '0'};
text-align: start;
transition: all ease-in-out 0.2s;
`;
const contLoader = styled.div`
width: 100%;
height: 100%;
margin-top: auto;
margin-bottom: auto;
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
.spinner-border {
    border: .25em solid #7367f0;
    border-right-color: transparent;
}
`;

const DefaultimgPostCss = {
    cont,
    modalImg,
    headerModal,
    footerModal,
    btnCloseModal,
    textHeader,
    btnActionFooter,
    textBody,
    bodyModal,
    input,
    contForm,
    inputFile,
    contImgSend,
    contInputAndLabel,
    text,
    contLoader,
    Select,
    contMesaggeSucces
}

export default DefaultimgPostCss;