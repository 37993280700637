import React, { useState, useEffect, useRef } from "react";
import { BiChevronDown } from 'react-icons/bi'
import SelectEnterpriseStyle from '../../assets/css/components/admin/menus/components/SelectEnterpriseStyle'
export default function Select(props) {

    const {StatusDropDown, idOption, setIdOption} = props;
    const [openList, setOpenList] = useState(false);
    const [textSelect, setTextSelect] = useState('Seleccione uno');
    const wrapperRef = useRef(null);
    
    const selectAnyValue = (v) => {
        setIdOption(v);
      };

function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpenList(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    if(idOption) {
        const defaultValueDropDown = StatusDropDown.find(v => v.id === idOption);
        if (defaultValueDropDown) {
            setTextSelect(defaultValueDropDown.name);
        }else {
            setTextSelect('')
        }
    }
  }, [idOption, StatusDropDown])

    return (
        <SelectEnterpriseStyle.ContSelect ref={wrapperRef} w="100%" maxW="100%">
            <SelectEnterpriseStyle.SelectCustom onClick={() => setOpenList(!openList)}>
                <SelectEnterpriseStyle.Text size="12pt" fw="500" cl={!idOption && textSelect === "Seleccione uno" ? '#ea5455' : '#707070'}>
                    {
                        textSelect
                    }
                </SelectEnterpriseStyle.Text>
                <SelectEnterpriseStyle.BtnShowOptions>
                    <BiChevronDown />
                </SelectEnterpriseStyle.BtnShowOptions>
            </SelectEnterpriseStyle.SelectCustom>
            <SelectEnterpriseStyle.ContListOptions active={openList} w="100%" maxW="100%">
                <SelectEnterpriseStyle.ListEnterprises>
                {
                    StatusDropDown.length > 0 && StatusDropDown.map((values) => (
                        <SelectEnterpriseStyle.ItemListEnterprise key={values.id || values._id} onClick={() => {
                            selectAnyValue(values.id || values._id)
                            setTextSelect(values.name.toUpperCase())
                            setOpenList(false)
                            }}>
                        <SelectEnterpriseStyle.Text size="12pt">
                            {values.name.toUpperCase()}
                        </SelectEnterpriseStyle.Text>
                    </SelectEnterpriseStyle.ItemListEnterprise>
                    ))
                }
                    
                </SelectEnterpriseStyle.ListEnterprises>
            </SelectEnterpriseStyle.ContListOptions>
        </SelectEnterpriseStyle.ContSelect>
    )
}
