import React from 'react'
import { ContHeader, Text } from '../../../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts'

export default function HeaderGrid() {
    return (
        <ContHeader>
            <Text size="14pt" cl="#636363" fw="500" >
                Productos
            </Text>
        </ContHeader>
    )
}
