import styled from "styled-components";

const UserEnterpriseNewStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  .EnterPriceFormBody {
    /*box-shadow: rgba(0, 0, 0, 0.11) 1px 1px 1px 1px;*/
    font-family: "Roboto Slab", serif !important;
    width: 70%;
    background-color: #f8f9fa;
    padding: 20px 10px;
  }
  input,
  .react-select-container {
    font-family: "Roboto Slab", serif !important;
    border-color: #5e548e;
    outline: none;

    &:focus {
      border-color: #cec28e;
      outline: none !important;
      outline-width: 0 !important;
      box-shadow: 0 0 0 1px #cec28e;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
  .btn-save-enterprise {
    font-family: "Roboto Slab", serif !important;
    margin-bottom: 3%;
    border: none;
    border: 1px solid rgb(94, 84, 142);
    color: rgb(94, 84, 142);
    background-color: transparent;
    padding: 8px 16px !important;
    border-radius: 10px;

    transition: all ease 450ms;

    &:hover {
      color: white;
      background-color: rgb(94, 84, 142);
      -webkit-box-shadow: -1px 11px 35px 0px rgba(94, 84, 142, 1);
      -moz-box-shadow: -1px 11px 35px 0px rgba(94, 84, 142, 1);
      box-shadow: -1px 11px 35px 0px rgba(94, 84, 142, 1);
    }
    &:active,
    &:focus,
    &:visited,
    &active:focus {
      outline: none;
      border: none;
    }
  }
  .goBack {
    .btn-back {
      border: 1px solid rgb(94, 84, 142);
      color: rgb(94, 84, 142);
      width: 30px;
      height: 30px;
      background: transparent;
      border-radius: 50%;
      transition: all ease 450ms;
      &:hover {
        background: rgb(94, 84, 142);
        color: white;
      }
      &:focus,
      &:active,
      &:active:focus {
        outline: none;
      }
    }
  }
`;
export default UserEnterpriseNewStyle;
