import styled from "styled-components";

export const ContainerRemoveBackground = styled.div`
width: 100%;
min-height: 80vh;
display: flex;
position: relative;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
`;

export const ContMenu = styled.div`
width: 100%;
border-radius: .325rem;
min-height: 50px;
background: #7367F0;
position: sticky;
top: 0;
bottom: auto;
left: 0;
right: 0;
padding: 1rem;
position: relative;
display: flex;
justify-content: space-between;
align-items: center;
`;

export const InputFile = styled.div`
width: 100px;
align-self: flex-start;

.file {
position: relative;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
height: 2.5rem;
margin-top: 0;
margin-bottom: 0;
width: 100%;

}

.file input {
display: none;
/* min-width: 14rem; */
margin: 0;
filter: alpha(opacity=0);
opacity: 0;
}

.file-custom {
background: transparent;
width: 100%;
    //height: 2.5rem;
//line-height: 1.5;
//box-shadow: inset 0 .2rem .4rem rgba(0,0,0,.05);
-webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
        user-select: none;
}

.file-custom:after {
content: "";
}

.file-custom:before {
/* position: absolute;
top: 0;
left: 1rem;
right: auto;
bottom: 0; */
width: 100%;
display: flex;
justify-content: center;
align-items: center;
content: 'Agregar';
height: 2.5rem;
padding: .5rem 1rem;
color: #7367f0;
background-color: #FFFFFF;
border-radius: .25rem;
}

/* Focus */
/* .file input:focus ~ .file-custom {
box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
} */
`;

export const ButtonSend = styled.div`
min-width: 100px;
width: auto;
outline: none !important;
display: flex;
justify-content: center;
align-items: center;
height: 2.5rem;
padding: .5rem 1rem;
color: #FFFFFF;
font-weight: 500;
background-color: #7367f0;
border-radius: .25rem;
transition: all ease-in-out .2s;
border: 1px solid #FFFFFF;
cursor: pointer;
&:hover {
    background-color: #FFFFFF;
    color: #7367f0;
    border: none;
}
`;

export const ContCards = styled.div`
width: 100%;
display: grid;
margin-top: 1rem;
grid-gap: 1rem;
grid-auto-rows: auto;
  //Using auto-fill and auto-fit
  grid-template-rows: repeat(2, minmax(150px, min-content));
  grid-template-columns: repeat(auto-fill,100px);
  grid-template-columns: repeat(auto-fit,100px);
  grid-template-columns: repeat(auto-fit,minmax(200px, 300px));
transition: all ease-in-out .2s;
/* border: 1px solid red; */
min-height: 5rem;
`;

export const ContImages = styled.div`
width: 100%;
min-height: 200px;
max-height: 200px;
border-radius: 0.325rem;
/* border: 1px solid red; */
background-color: #FFFFFF;
-webkit-box-shadow: 5px 5px 32px -3px rgba(0,0,0,0.12); 
box-shadow: 5px 5px 32px -3px rgba(0,0,0,0.12);
background-image: url(${props => props.url || ''});
background-size: contain;
background-repeat: no-repeat;
background-position: center;
position: relative;
&:hover {
    cursor: pointer;
}
`;