import axios from "axios";
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
const getDataThree = async (dateTo, dateFrom) => {

        const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-report/report-post-products-for-user`, {
            dateTo,
            ...(dateFrom && {dateFrom: dateFrom})
            }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
    return res;
}
export default getDataThree;