import React, { useContext, useEffect, useState } from 'react'
import { BtnSendProduct, ContFilterAndListGrid, ContGeneral, BtnSendProductClear } from '../../../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts'
import ContGrid from '../components/ContGrid'
import FiltersProducts from '../components/FiltersProducts'
import HeaderGrid from '../components/HeaderGrid'
import axios from 'axios';
import getDataOdoo from '../functions/getDataOdoo';
import getDataMl from '../functions/getDataMl'
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import getDataPy from '../functions/getDataPy'
import getProductsAnicam from '../functions/getProductsAnicam'
import getAnicamToken from '../functions/getAnicamToken'
import { tokenAnicam } from '../../../../../assets/js/SessionStorageNames'
export default function GridListProducts() {

    const [pluginSearch, setPluginSearch] = useState('odoo');
    const globalContext = useContext(GlobalContext)
    const pageItemCount = 20;
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [currentPlugin, setCurrentPlugin] = useState("");
    const [mainPlugin, setMainPlugin] = useState("");
    const [products, setProducts] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [redirect, setRedirect] = useState(null);
    const [numRefresh, setNumRefresh] = useState(0);
    const [filter, setFilter] = useState("");
    const [filterLinkedTo, setFilterLinkedTo] = useState("");
    const [filterPrice, setFilterPrice] = useState("");
    const [filterStock, setFilterStock] = useState("");
    const [filterWithCompany, setFilterWithCompany] = useState(null);
    const [filterWithTaxes, setFilterWithTaxes] = useState(null);
    const [currentBrand, setCurrentBrand] = useState("");
    const [brands, setBrands] = useState([]);
    const [itemsChangeStore, setItemsChangeStore] = useState([]);
    const [load, setLoad] = useState(true)

    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (msg) globalContext.showModalWithMsg(msg);
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const getTokenAnicam = () => {
        getAnicamToken()
        .then(res => {
            const data = res.data.data.access_token;
            localStorage.setItem(
                tokenAnicam, data
            );
            console.log('get token success');
        }).catch(err => {
            console.log('err', err.message);
        })
    }

    useEffect(() => {
        setLoad(true)
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        if (pluginSearch === "") return

        switch (pluginSearch) {
            case "odoo":
                getDataOdoo(
                    entId,
                    currentPage,
                    pageItemCount,
                    filter,
                    filterLinkedTo,
                    filterPrice,
                    filterStock,
                    filterWithCompany,
                    filterWithTaxes,
                    axiosCancelTokenSource
                )
                .then((res) => {
                setLoad(false)
                const data = res.data.data;
                //console.log('la data odoo', data)
                setTotalProducts(data.totalProducts);
                //hacer el setDataProduct
                console.log('print table')
                    console.table(data.products);
                setProducts(data.products.map((prod) => {
                    const variBarCodeOrig = prod.variants.map(v => v.attrib.barCode).filter(b => b !== undefined);
                    const variBarCode = variBarCodeOrig.filter((b, bIdx) => variBarCodeOrig.indexOf(b) === bIdx);
                    const variSkuOrig = prod.variants.map(v => v.attrib.ref_int).filter(s => s !== undefined);
                    const variSku = variSkuOrig.filter((s, sIdx) => variSkuOrig.indexOf(s) === sIdx);
                    const isPublicOnPeya = (prod.variants.length > 0) ? prod.variants.find(v => v.integrationCodePeya ? true : false) : prod.integrationCodePeya ? true : false;  
                    return {
                        _id: prod._id,
                        title: prod.title,
                        price: new Intl.NumberFormat("de-DE").format(prod.price),
                        stock: prod.stock,
                        linked: prod.linkedTo && prod.linkedTo.length > 0,
                        isFullyLinked: prod.isFullyLinked,
                        isPartiallyLinked: prod.isPartiallyLinked,
                        thumbnail:[
                            ((prod.thumbnail && typeof prod.thumbnail == "object" && prod.thumbnail.url) ? prod.thumbnail.url
                                : (prod.thumbnail && typeof prod.thumbnail == "string") ? `data:image/png;base64,${prod.thumbnail}`
                                    : false)],
                        barCode: prod.attrib.barCode,
                        variBarCode,
                        sku: prod.attrib.ref_int,
                        variSku,
                        hasTaxes: prod.hasTaxes,
                        hasVariants: prod.hasVariants,
                        isVariant: prod.isVariant,
                        peyaInfo: isPublicOnPeya ,
                        description: prod.attrib.description ? prod.attrib.description : '',
                        brand: prod.attrib.x_studio_marca ? prod.attrib.x_studio_marca : 'Sin asignar'
                    };
                }))
            })
                    .catch((err) =>
                        handleAxiosError(
                            err,
                            err
                        )
                    );
                break; //End odoo

            case "ml":
                getDataMl(
                    entId, currentPage, pageItemCount,
                    filter, filterLinkedTo, currentBrand,
                    filterStock, filterPrice, axiosCancelTokenSource
                )
                .then((res) => {
                setLoad(false)
                const data = res.data.data;
                setTotalProducts(data.totalProducts);
                setProducts(data.products.map((prod) => {
                    const variBarCodeOrig = prod.variants.map(v => v.attrib.barCode).filter(b => b !== undefined);
                    const variBarCode = variBarCodeOrig.filter((b, bIdx) => variBarCodeOrig.indexOf(b) === bIdx);
                    const variSkuOrig = prod.variants.map(v => v.attrib.sku).filter(s => s !== undefined);
                    const variSku = variSkuOrig.filter((s, sIdx) => variSkuOrig.indexOf(s) === sIdx);

                    return {
                        _id: prod._id,
                        title: prod.title,
                        price: new Intl.NumberFormat("de-DE").format(prod.price),
                        stock: prod.stock,
                        linked: prod.linkedTo && prod.linkedTo.length > 0,
                        isFullyLinked: prod.isFullyLinked,
                        isPartiallyLinked: prod.isPartiallyLinked,
                        thumbnail: [(prod.thumbnail)],
                        officialStoreId: prod.attrib.official_store_id,
                        barCode: prod.attrib.barCode,
                        variBarCode,
                        sku: prod.attrib.sku,
                        variSku,
                        status: prod.attrib.status,
                    };
                }))
            })
                    .catch((err) =>
                        handleAxiosError(
                            err,
                            err
                        )
                    );
                break; //End ml
            case "py":
                getDataPy(
                    entId,
                    currentPage,
                    pageItemCount,
                    filter,
                    filterLinkedTo,
                    currentBrand,
                    filterPrice,
                    filterStock,
                    axiosCancelTokenSource
                    )
                .then((res) => {
                const data = res.data.data;
                console.log(res)
                setTotalProducts(data.totalProducts)
                if (data.totalProducts === 0) {
                    handleAxiosError(
                        'No se encontro nada',
                        "Disculpe, no tiene registrado ningun productos para Dafiti."
                    );
                    setLoad(false)
                } else {
                    setLoad(false)
                    setTotalProducts(data.total)
                    setProducts(data.products.map((prod) => {
                        const variBarCodeOrig = prod.variants.map(v => v.attrib.barCode).filter(b => b !== undefined);
                        const variBarCode = variBarCodeOrig.filter((b, bIdx) => variBarCodeOrig.indexOf(b) === bIdx);
                        const variSkuOrig = prod.variants.map(v => v.attrib.sellerSku).filter(s => s !== undefined);
                        const variSku = variSkuOrig.filter((s, sIdx) => variSkuOrig.indexOf(s) === sIdx);

                        return {
                            _id: prod._id,
                            title: prod.title,
                            price: new Intl.NumberFormat("de-DE").format(prod.price),
                            stock: prod.stock,
                            linked: prod.linkedTo && prod.linkedTo.length > 0,
                            isFullyLinked: prod.isFullyLinked,
                            isPartiallyLinked: prod.isPartiallyLinked,
                            thumbnail:[
                                ((prod.thumbnail && typeof prod.thumbnail == "object" && prod.thumbnail.url) ? prod.thumbnail.url
                                    : (prod.thumbnail && typeof prod.thumbnail == "string") ? `data:image/png;base64,${prod.thumbnail}`
                                        : false)],
                            officialStoreId: prod.attrib.official_store_id,
                            barCode: prod.attrib.barCode,
                            variBarCode,
                            sku: prod.attrib.ref_int,
                            variSku,
                            status: prod.attrib.status,
                            peyaInfo: prod.integrationCodePeya
                        };
                    }))
                }
                
            })
                    .catch((err) =>
                        handleAxiosError(
                            err,
                            err
                        )
                    );
                break; //End peya
                case "anicam":
                    getTokenAnicam();
                    getProductsAnicam(
                        entId,
                        currentPage,
                        pageItemCount,
                        filter)
                        .then(res => {
                            setLoad(false);
                            const data = res.data.data;
                            console.log('lo de anicam', data)
                            setTotalProducts(data.total)
                            setProducts(data.products.map((prod) => {
                                return {
                                    _id: prod._id,
                                    title: prod.title,
                                    price: new Intl.NumberFormat("de-DE").format(prod.high_price),
                                    stock: prod.quantity,
                                    linked: [],
                                    isFullyLinked: false,
                                    isPartiallyLinked: false,
                                    thumbnail:[prod.large_image],
                                    barCode: prod.code,
                                    sku: prod.code,   
                                    status: true,
                                    brand: prod.brand,
                                    ...(prod.publishedOnMl && { ml: prod.publishedOnMl })
                                };
                            }))

                        }).catch(err => {
                            setLoad(false);
                            handleAxiosError(
                                err,
                                err
                            )
                        });
                        break;
            default:
                break;
        }

    }, [
        globalContext.currentEnterpriseId,
        pageItemCount, currentPlugin,
        currentPage, filter, filterLinkedTo,
        filterPrice, filterStock, filterWithCompany,
        filterWithTaxes, currentBrand, pluginSearch

    ])
    return (
        <ContGeneral>
            <HeaderGrid />
            <ContFilterAndListGrid>
                <FiltersProducts
                    setPluginSearch={setPluginSearch}
                    pluginSearch={pluginSearch}
                    setCurrentPage={setCurrentPage}
                    total={totalProducts}
                    currentPage={currentPage}
                    load={load}
                    pageItemCount={pageItemCount}
                    setFilterLinkedTo={setFilterLinkedTo}
                    setFilterPrice={setFilterPrice}
                    setFilterStock={setFilterStock}
                    setFilterWithCompany={setFilterWithCompany}
                    setFilterWithTaxes={setFilterWithTaxes}
                    filterLinkedTo={filterLinkedTo}
                    filterPrice={filterPrice}
                    filterStock={filterStock}
                    filterWithCompany={filterWithCompany}
                    filterWithTaxes={filterWithTaxes}
                />
                <ContGrid
                    setFilter={setFilter}
                    pluginSearch={pluginSearch}
                    load={load}
                    totalProducts={totalProducts}
                    listProd={products}
                />
            </ContFilterAndListGrid>
            {
                globalContext.listProductsToSend.length > 0 && (
                    <>
                        <BtnSendProduct onClick={() => globalContext.setModalPostProduct(true)}>
                        Publicar {globalContext.listProductsToSend.length}
                    </BtnSendProduct>
                    <BtnSendProductClear onClick={() => globalContext.setListProductsToSend([])}>
                        Limpiar
                    </BtnSendProductClear>
                    </>
                )
            }
        </ContGeneral>
    )
}
