import React, { useContext, useState, useEffect } from "react";
import { Form, Col, Row, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import UserEnterpriseNewStyle from "../../../assets/css/components/admin/enterprise/new/UserEnterpriseNewStyle.css";
import { Link } from "react-router-dom";
import {
  keyUserId,
  keyAccessToken,
} from "../../../assets/js/SessionStorageNames";
import ModalMsm from "../../general/ModalMsm";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { Redirect } from "react-router-dom";
import Routes from "../../../Routes";
import verifyAccessRole from "../../../assets/js/verifyAccessRole";

export default function UserEnterpriseUpdate({ match }) {
  /**
   * * id enterprice
   */
  const globalContext = useContext(GlobalContext);
  const enterpriceId = match.params.id;
  const [redirect, setRedirect] = useState(null);
  const axios = require("axios");
  const [name, setName] = useState("");
  const [tlf, setTlf] = useState("");
  const [direccion, setDireccion] = useState("");
  const [responsable, setResponsable] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const handleClose = () => setShow(false);
  /**
   * session storage
   */

   useEffect(() => {
    const hasAccess = verifyAccessRole(Routes, 52);
    if(!hasAccess) {
        setRedirect(
            <Redirect to={`/admin/dashboard`} />
        )
    }
  },[]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/enterprise/${enterpriceId}`,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
    })
      .then((res) => {
        setName(res.data.data.name);
        setTlf(res.data.data.tlf);
        setDireccion(res.data.data.direccion);
        setResponsable(res.data.data.responsable);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setShow(true);
      });
    //eslint-disable-next-line
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/enterprise/${enterpriceId}`,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
      data: {
        name: name,
        tlf: tlf,
        direccion: direccion,
      },
    })
      .then((res) => {
        setError(res.data.message);
        globalContext.reloadNavEnterpriseOptions();
        setShow(true);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setShow(true);
      });
  };
  return (
    <UserEnterpriseNewStyle>
      {redirect}
      <ModalMsm
        show={show}
        error={error}
        handleClose={handleClose}
        txtButton="Cerrar"
      />
      <Row>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="d-flex justify-content-left mt-3 align-items-center content-breadcrumb"
        >
          <div className="goBack d-flex justify-content-start mt-2">
            <Link to="/admin/enterprise/das-enterprise">
              <button className="btn-back">
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
            </Link>
          </div>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="d-flex justify-content-center"
        >
          <div className="EnterPriceFormBody">
            <Form onSubmit={submitHandler}>
              <Container fluid>
                <Row>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Form.Group>
                      <Form.Label>Nombre de la Empresa</Form.Label>
                      <Form.Control
                        placeholder="Nombre de la empresa"
                        name="nombre"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      ></Form.Control>
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Form.Group>
                      <Form.Label>Telefono</Form.Label>
                      <Form.Control
                        placeholder="Telefono de la empresa"
                        name="telefono"
                        value={tlf}
                        onChange={(e) => setTlf(e.target.value)}
                      ></Form.Control>
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Form.Group>
                      <Form.Label>Reponsable</Form.Label>
                      <Form.Control
                        placeholder="Responsable de la empresa"
                        name="responsable"
                        value={responsable}
                        onChange={(e) => setResponsable(e.target.value)}
                      ></Form.Control>
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form.Group>
                      <Form.Label>Direccion</Form.Label>
                      <Form.Control
                        placeholder="Direccion de la empresa"
                        name="direccion"
                        value={direccion}
                        onChange={(e) => setDireccion(e.target.value)}
                      ></Form.Control>
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <button className="btn-save-enterprise">Guardar</button>
                  </Col>
                </Row>
              </Container>
            </Form>
          </div>
        </Col>
      </Row>
    </UserEnterpriseNewStyle>
  );
}
