import styled from "styled-components";
const OdooToMlStyle = styled.div`
  .goBack {
    .btn-back {
      border: 1px solid rgb(94, 84, 142);
      color: rgb(94, 84, 142);
      width: 30px;
      height: 30px;
      background: transparent;
      border-radius: 50%;
      transition: all ease 450ms;
      &:hover {
        background: rgb(94, 84, 142);
        color: white;
      }
      &:focus,
      &:active,
      &:active:focus {
        outline: none;
      }
    }
  }
  .btn-add-img {
    border: 1px solid rgb(94, 84, 142);
    color: rgb(94, 84, 142);
    width: 30px;
    height: 30px;
    background: transparent;
    border-radius: 50%;
    transition: all ease 450ms;
    &:hover {
      background: rgb(94, 84, 142);
      color: white;
    }
    &:focus,
    &:active,
    &:active:focus {
      outline: none;
    }
  }
  .img-product-div {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    .img-product {
      width: 200px;
      height: 200px;
      border-radius: 100px;
    }
  }
`;
export default OdooToMlStyle;
