import styled from 'styled-components';

const Cont = styled.div`
width: 100%;
height: 100vh;
height: calc(var(--vh, 1vh) * 100);
display:  flex;
justify-content: flex-start;
align-items: center;
background: #F8F8F8;
`;

const ContForm = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
position: relative;
padding: 1rem;
min-width: 300px;
width: 500px;
max-width: 500px;
margin-left: auto;
margin-right: 0;
height: 100%;
margin-top: 0;
margin-bottom: 0;
background: #FFFFFF;
@media (max-width: 990px){
    max-width: 100%;
    width: 100%;
}
`;
const ContImgMessage = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
position: relative;
padding: 4rem;
min-width: 600px;
max-width: 70%;
width: 70%;
height: 100%;
margin-top: 0;
margin-bottom: 0;
@media (max-width: 990px){
    display: none;
}
`
const ContImg = styled.div`
    width: 100%;
    height: 500px;
    background-image: url(${props => props.url || ''});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat ;
`;

const ContIconAndTitle = styled.div`
    min-width: 300px;
    position: absolute;
    left: 10px;
    top: 10px;
    right: auto;
    bottom: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const ContLogo = styled.div`
width: 50px;
height: 50px;
background-image: url(${props => props.url || ''});
background-position: center;
background-size: 100%;
background-repeat: no-repeat;
`


const FormLogin = styled.form`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
border-bottom: 2px solid #ebe9f1;
padding-bottom: 1rem;
width: 100%;
min-height: 300px;
max-height: 700px;
overflow: hidden;
@media (max-width: 990px){
    max-width: 500px;
    width: 100%;
}
`
const ContInputAndLabel = styled.div `
width:100%;
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'space-between'};
align-items:${props => props.ali || 'flex-start'};
`;

const Text = styled.p `
color: ${props => props.cl || '#707070'};
font-size: ${props => props.size || '.857rem'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '.2857rem'};
margin-bottom: ${props => props.mb || '.2857rem'};
text-overflow:${props => props.longText ? 'ellipsis' : ''};
overflow:${props => props.longText ? 'hidden' : ''};
white-space:${props => props.longText ? 'nowrap' : ''};
`;

const Input = styled.input`
display: block;
width: 100%;
height: 38px;
font-size: .875rem;
font-weight: 400;
line-height: 1.45;
color: #6e6b7b;
padding: 0.438rem 1rem;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #d8d6de;
border-radius: 0.357rem;
position: relative;
&:focus {
    color: #6e6b7b;
    outline: 0;
    background-color: #fff;
    border-color: #7367f0;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}
::placeholder {
    color: #c0c0c1;
    font-size: 1rem;
}
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
`;


const BtnSend = styled.button`
outline: none;
border: none;
border-color: #7367f0;
width: 100%;
height: 38px;
color: white;
font-size: 12pt;
font-weight: 500;
text-align: center;
background-color: #7367f0;
padding: .150rem;
border-radius: 0.358rem;
margin-top: 1rem;
&:disabled {
    border-color: #a49df5;
    background-color: #a49df5;
}
`
const ContBadgeError = styled.div`
border-radius: 0.358rem;
background: #ea5455!important;
width: 100%;
height: ${props => props.active ? 'auto' : '0px'};
padding: ${props => props.active ? '1rem' : '0'};
opacity:  ${props => props.active ? '1' : '0'};
position: relative;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
margin-bottom: 1rem;
overflow: hidden;
transition: opacity .15s ease-in-out, height .15s ease-in-out, padding .1s ease-in-out;
`;

const BtnToggleBadge = styled.button`
width: 25px;
height: 25px;
outline: none !important;
border-radius: 50px;
display: flex;
justify-content: center;
align-items: center;
border: 1px solid #FFFFFF;
color: #FFFFFF;
background-color: transparent;
cursor: pointer;
position: absolute;
left: auto;
top: auto;
right: 10px;
bottom: auto;
`;

const ContIconNadInputPass = styled.div`
width: 100%;
position: relative;
height: auto;
box-sizing: border-box;
display: flex;
justify-content: center;
align-items: center;
`
const BtnShowPass = styled.button`
width: 30px;
height: 30px;
outline: none !important;
border-radius: 50px;
display: flex;
justify-content: center;
align-items: center;
border: 1px solid transparent;
color: #707070;
background-color: transparent;
cursor: pointer;
position: absolute;
left: auto;
top: auto;
right: 10px;
bottom: auto;
&:hover {
    background-color: #f0f0f0;
}
`;

const LoginStyle = {
    Cont,
    ContImgMessage,
    ContForm,
    ContImg,
    ContIconAndTitle,
    ContInputAndLabel,
    Text,
    FormLogin,
    ContLogo,
    Input,
    BtnSend,
    ContBadgeError,
    BtnToggleBadge,
    BtnShowPass,
    ContIconNadInputPass
}

export default LoginStyle;