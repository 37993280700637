import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { keyUserId, keyAccessToken } from "../../../../../assets/js/SessionStorageNames";
import axios from "axios";
import FileSaver from 'file-saver';
import { Form } from "react-bootstrap";

export default function PricelistReportOption(props) {
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [priceLists, setPriceLists] = useState([]);
    const [chosenPriceLists, setChosenPriceLists] = useState(["3992"]);
    const configData = {};

    switch (props.format) {
        case 'xlsx':
            configData.icon = faFileExcel;
            configData.title = "Reporte Tarifas xlsx sin img";
            configData.responseType = 'arraybuffer';
            break;
    }

    /**
     * Cancel axios requests before unmount
     */
    useEffect(() => {
        return () => {
            axiosCancelTokenSource.cancel("Canceled by unmount");
        };
        //eslint-disable-next-line
    }, []);

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        
        if (err.response && err.response.data && err.response.data.message) {
            globalContext.showModalWithMsg(err.response.data.message);
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    /**
     * Load the pricelists
     */
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-pricelist/${entId}`, {
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            }
        ).then(resp => {
            const data = resp.data.data;
            setPriceLists(data.priceLists);
        }).catch((err) =>
            handleAxiosError(
                err,
                "Disculpe, no se pudo cargar la lista de tarifas."
            )
        );
    }, [globalContext.currentEnterpriseId]);

    /**
     * Show the modal to select the pricelists to download
     * @param {*} selectValue The values currently selected
     */
    const showModal = (selectValue) => {
        globalContext.showModalWithMsg(<div>
            <Form.Control
                as="select"
                size="sm"
                style={{height: '200px'}}
                name="chosenPriceLists"
                multiple={true}
                value={selectValue}
                onChange={(e) => {
                    const val = e.target.value;

                    if (!selectValue.includes(val)) {
                        selectValue.push(val)
                    } else {
                        selectValue.splice(selectValue.indexOf(val), 1)
                    }
                    
                    showModal(selectValue);
                }}
            >
                {
                    priceLists.length > 0 && priceLists.map(p => {
                        return <option value={p.id} key={p.id}>{p.name}</option>;
                    })
                }
            </Form.Control>
        </div>, "Seleccione las tarifas a descargar", {
            size: "lg",
            useConfirmButton: true,
            confirmButtonText: 'Descargar',
            handleConfirm: () => {
                if (selectValue.length == 0) return;

                globalContext.setShowModal(false);
                pricelistReportWithOutImage(selectValue);
            }
        })
    }

    /**
     * Odoo Pricelist Report
     */
     
    const pricelistReport = (pricelistIds) => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        globalContext.setLoaderPage(true);
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-report/${entId}/report-pricelists/${props.format}`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers,
                    params: {
                        pricelistIds,
                    }
                }
            )
            .then(() => {
                globalContext.setLoaderPage(false);
                globalContext.showModalWithMsg(
                    `La descarga a iniciado correctamente, puede visualizar su estatus en la vista de segundo plano`
                );
            })
            .catch((err) => {
                globalContext.setLoaderPage(false);
                console.error(err);
                globalContext.showModalWithMsg(
                    `Ocurrio un error al descargar su archivo`
                );
            });
    }
    const pricelistReportWithOutImage = (pricelistIds) => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        globalContext.setLoaderPage(true);
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-report/${entId}/report-pricelists-with-out-image/${props.format}`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers,
                    params: {
                        pricelistIds,
                    }
                }
            )
            .then(() => {
                globalContext.setLoaderPage(false);
                globalContext.showModalWithMsg(
                    `La descarga a iniciado correctamente, puede visualizar su estatus en la vista de segundo plano`
                );
            })
            .catch((err) => {
                globalContext.setLoaderPage(false);
                console.error(err);
                globalContext.showModalWithMsg(
                    `Ocurrio un error al descargar su archivo`
                );
            });
    }

    return (
        <div
            className={`items`}
            onClick={() => {showModal([])}}
        >
            <span className="ic">
                <FontAwesomeIcon icon={configData.icon} />
            </span>
            <p>{configData.title}</p>
        </div>
    );
}