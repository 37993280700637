import getDataScraper from "./getDataScraper";
import getDataMonitor from "./getDataMonitor";
const runScrapeOne = async (dataArr, setArrayData, axiosCancelTokenSource, entId, processCheckIntervalTime, arrayData, currentIndex) => {
    let arrayDataSeter = [...arrayData];
    const resp = await getDataScraper(dataArr.url, entId, dataArr.limit);
    return await new Promise((resolve, reject) => {
        try {
            const data = resp.data.data;
            console.log('data Amazon', data)
            if (data.monitor) {
                console.log('tiene monitor');
                let waitingMonitorResponse = false;
                const interval = setInterval(async () => {
                    console.log('empezo el interval');
                    if (!waitingMonitorResponse) {
                        console.log('waitingMonitorResponse se paso a true');
                        waitingMonitorResponse = true;
                        console.log('enviamos la consulta');
                        try {
                            const resp2 = await getDataMonitor(data, axiosCancelTokenSource);
                            console.log('lllega la respuesta', resp2.data.data);
                            const datamazonScrap = resp2.data.data;
                            if (datamazonScrap.advance >= 100) {
                                arrayDataSeter[currentIndex].result = 'Exitoso!'
                                arrayDataSeter[currentIndex].message = 'el proceso finalizo exitosamente!';
                                arrayDataSeter[currentIndex].statusProcess = 100;
                                setArrayData(arrayDataSeter);
                                console.log('se hizo', datamazonScrap)
                                clearInterval(interval);
                                resolve('finalizo');
                            }
                                console.log('avance', datamazonScrap.advance++)
                                arrayDataSeter[currentIndex].statusProcess = datamazonScrap.advance;
                                setArrayData(arrayDataSeter);
                        } catch (error) {
                            console.log('algo fallo para el index', currentIndex, arrayDataSeter)
                            arrayDataSeter[currentIndex].result = 'Fallido!'
                            arrayDataSeter[currentIndex].message = 'No posee data del monmitor';
                            setArrayData(arrayDataSeter);
                            clearInterval(interval);
                            reject('No tiene data Monitor');
                        }
                    }
                    console.log('monitor en espera? parea el index', currentIndex, arrayDataSeter)
                    waitingMonitorResponse = false;
                }, processCheckIntervalTime);
            }else {
                console.log('algo fallo en el index', currentIndex, arrayDataSeter)
                arrayDataSeter[currentIndex].result = 'Fallido!'
                arrayDataSeter[currentIndex].message = 'No posee data del monmitor';
                setArrayData(arrayDataSeter);
                reject('No tiene data Monitor');
            }
        } catch (error) {
            console.log(error, currentIndex, arrayDataSeter);
            arrayDataSeter[currentIndex].result = 'Fallido!'
            arrayDataSeter[currentIndex].message = error;
            setArrayData(arrayDataSeter);
            reject(error);
        }
    }).catch((error) => {
        console.log(error, currentIndex, arrayDataSeter);
        arrayDataSeter[currentIndex].result = 'Fallido!'
        arrayDataSeter[currentIndex].message = error;
        setArrayData(arrayDataSeter);
        console.log(error, 'el error');
        return error;
        
    });
}

const runScrape = async (arrayDataoriginal, setArrayData, axiosCancelTokenSource, entId, setLoader) => {
    setLoader(true);
    if(!entId) {
        alert('Debe seleccionar una empresa para seguir');
        return;
    }
     // ? ahora llamamos al metodo para hacer el scraping
    const processCheckIntervalTime = 5000;
    let currentIndex = 0;
    for(let dataArr of arrayDataoriginal) {
        await runScrapeOne(dataArr, setArrayData, axiosCancelTokenSource, entId, processCheckIntervalTime, arrayDataoriginal, currentIndex);
        currentIndex++;
    }
    setLoader(false);
}

export default runScrape;