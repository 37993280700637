/**
 * @param {*} father
 * @param {*} propC
 * @param {*} keyC
 * ? este metodo  imprime en pantalla el menu en forma de tarjetas
 */
import React from "react";
import { Link } from "react-router-dom";

export default function printCardList(father, propC, keyC) {
  if (
    propC.layout === "/admin" &&
    propC.isFather === false &&
    propC.son === true &&
    propC.grandSon === true &&
    father === propC.father
  ) {
    return (
      <Link to={propC.layout + father + propC.path} key={keyC}>
        <div className="card">
          <div className="card_image">
            <img src={propC.img} alt="d" />
          </div>
          <div className="card_title">
            <p>{propC.name}</p>
          </div>
        </div>
      </Link>
    );
  }
}
