import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';

const getChildsML = (entId, parentNode, axiosCancelTokenSource) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-category/${entId}`, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
        cancelToken: axiosCancelTokenSource.token,
        params: {
            pageNumber: 1,
            pageItemCount: 100000,
            parent: parentNode,
            filter: {
                getAll: true,
                name: "",
                
            },
        }
    })
}

export default getChildsML;