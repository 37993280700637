import React from 'react'
import BuildFormCss from '../../../../../../assets/css/components/admin/products/post-product/steps/BuildFormCss'
export default function DropDown(props) {
    const {data, setValue, valuesCategorys, index} = props;
    const handleChangeValue = (e) => {
        const copyArray = [...valuesCategorys];
        copyArray[index].value = e.target.value;
        copyArray[index].textByDropDown = e.target.options[e.target.selectedIndex].text
        setValue(copyArray);
        console.log('valores', valuesCategorys);

    }
    
    return (
        <BuildFormCss.select 
            onChange={handleChangeValue} 
            value={valuesCategorys[index].value} >
            <option value="">Seleccione uno...</option>
            {
                (data.options && Array.isArray(data.options) ) && 
                data.options.map((v,i)=>
                {
                    return (
                        <option key={i} value={v.id}>{v.Name}</option>
                    )
                })
            }
        </BuildFormCss.select>
)
}
