import styled from "styled-components";
const UserConfigUserLockStyled = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  * {
    margin: 0px;
    box-sizing: border-box;
  }
  .UserConfigLockContainer {
    .headerList,
    .namePLugin {
      font-weight: bold;
      color: #808080;
    }
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    background: white;
    border-radius: 10px;
    border: 1px solid #e5e9f2;
    padding: 10px 5px;
    font-family: "Roboto Slab", serif !important;
    min-height: 500px !important;
    max-height: 500px !important;
    height: 500px !important;
    overflow: hidden scroll;
    margin-top: 50px;
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(94, 84, 142);
      border-radius: 50px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  /**
*? table style
 */
  /*//////////////////////////////////////////////////////////////////
[ FONT ]*/

  /*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

  /* ------------------------------------ */

  /* ------------------------------------ */
  .add-new-user {
    border: 1px solid #5e548e;
    text-align: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: transparent;
    margin: 0 3% 0 5%;
    color: #5e548e;
    font-size: 12pt;
    transition: all ease 450ms;
    &:hover {
      background: #5e548e;
      color: white;
    }
    &:active,
    &:focus,
    &:visited,
    &active:focus {
      outline: none;
      border: none;
    }
  }
  .container-table100,
  .container-header {
    width: 100%;
    min-height: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .IconsActions {
    display: flex;
    .btn-action-trash {
      border: solid 1px #d3514d;
      color: #d3514d;
      width: 25px;
      height: 25px;
      text-align: center;
      border-radius: 50px;
      background: transparent;
      margin: 0 3% 0 5%;
      font-size: 9pt;
      &:hover {
        background: #d3514d;
        color: white;
      }
      &:active,
      &:focus,
      &:visited,
      &active:focus {
        outline: none;
        border: none;
      }
    }
    .btn-action-update {
      border: 1px solid #5e548e;
      width: 25px;
      height: 25px;
      text-align: center;
      border-radius: 50px;
      background: transparent;
      margin: 0 3% 0 5%;
      color: #5e548e;
      font-size: 9pt;
      &:hover {
        background: #5e548e;
        color: white;
      }
      &:active,
      &:focus,
      &:visited,
      &active:focus {
        outline: none;
        border: none;
      }
    }
  }

  .btn-refresh {
    outline: none;
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 35px;
    height: 35px;
    align-items: center;
    border: 1px solid rgb(94, 84, 142);
    color: rgb(94, 84, 142);
    background: transparent;
    font-size: 12pt;
    padding: 5px;
    border-radius: 50px;
    transition: all ease 450ms;
    margin-left: 3px;
    margin-right: 3px;
    .icon {
      font-size: 11pt;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:hover {
      background: rgb(94, 84, 142);
      color: white;
    }
    &:focus,
    &:active,
    &:active:focus {
      outline: none;
    }
  }

  .wrap-table100 {
    width: 520px;
  }
  .limiterHeader {
    width: 100%;
    margin: 0 auto;
  }
  .ActionsContent {
    padding: 8px 0;
  }
  .colHeader {
    padding: 10px 10px;
  }
  .ActionsContent {
    padding: 0 5px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  table {
    border-spacing: 1;
    border-collapse: collapse;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    font-weight: bold;
    width: 100%;
    margin: 0 auto;
    position: relative;
    font-family: "Roboto Slab", serif !important;
    -webkit-box-shadow: 0px 25px 55px -9px rgba(201, 201, 201, 1);
    -moz-box-shadow: 0px 25px 55px -9px rgba(201, 201, 201, 1);
    box-shadow: 0px 25px 55px -9px rgba(201, 201, 201, 1);
  }
  table * {
    position: relative;
  }
  table td,
  table th {
    padding-left: 8px;
    font-family: "Roboto Slab", serif !important;
  }
  table thead tr {
    height: 60px;
    background-color: #ffffff;
  }
  table tbody tr {
    height: 55px;
    padding: 10px 0;
  }
  table tbody tr:last-child {
    border: 0;
  }
  table td,
  table th {
    text-align: left;
  }
  table td.l,
  table th.l {
    text-align: right;
  }
  table td.c,
  table th.c {
    text-align: center;
  }
  table td.r,
  table th.r {
    text-align: center;
  }

  .table100-head th {
    font-size: 15px;
    font-weight: bold;
    color: #5e548e;
    box-shadow: rgba(0, 0, 0, 0.07) 0 1px;
    line-height: 1.2;
    font-weight: unset;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: "Roboto Slab", serif !important;
  }

  tbody tr {
    font-family: "Roboto Slab", serif !important;
    font-size: 15px;
    color: #808080;
    line-height: 1.2;
    font-weight: unset;
    box-shadow: rgba(0, 0, 0, 0.11) 0 1px;
  }

  tbody tr:hover {
    color: #555555;
    background-color: #f5f5f5;
  }
  .fa-meh,
  .fa-sad-tear {
    color: rgb(94, 84, 142);
    font-size: 18pt;
  }

  .column1 {
    width: 150px;
  }
  .column2 {
    width: 150px;
  }

  .column3 {
    width: 100px;
  }
  .column4 {
    width: 120px;
  }

  @media screen and (max-width: 992px) {
    table {
      display: block;
    }
    table > *,
    table tr,
    table td,
    table th {
      display: block;
    }
    table thead {
      display: none;
    }
    table tbody tr {
      height: auto;
      padding: 37px 0;
    }
    table tbody tr td {
      padding-left: 40% !important;
      margin-bottom: 24px;
    }
    table tbody tr td:last-child {
      margin-bottom: 0;
    }
    table tbody tr td:before {
      font-family: OpenSans-Regular;
      font-size: 18px;
      color: #999999;
      line-height: 1.2;
      font-weight: unset;
      position: absolute;
      width: 40%;
      left: 30px;
      top: 0;
    }
    table tbody tr td:nth-child(1):before {
      content: "Correo";
    }
    table tbody tr td:nth-child(2):before {
      content: "Nombre";
    }
    table tbody tr td:nth-child(3):before {
      content: "Status";
    }
    table tbody tr td:nth-child(4):before {
      content: "Accion";
    }

    .column1,
    .column2,
    .column3,
    .column4 {
      width: 100%;
    }
    .column4 {
      
    }

    tbody tr {
      font-size: 18px;
    }
  }

  @media (max-width: 576px) {
    .container-table100 {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;
export default UserConfigUserLockStyled;
