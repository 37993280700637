import styled from "styled-components";
const Cont = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
`;

const ContForm = styled.div`
min-width: 300px;
max-width: 500px;
width: 500px;
height: auto;
padding: 1rem;
max-height: 300px;
border-radius: .429rem;
background: #FFFFFF;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
margin-bottom: 1rem;
`;

const HeaderForm = styled.div.attrs(props => ({
    marginBottom: props.marginBottom || '',
}))`
width: 100%;
height: 50px;
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 0;
margin-bottom: ${props => props.marginBottom};
`;

const BodyForm = styled.div`
width: 100%;
min-height: 100px;
max-height: 350px;
height: auto;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
margin-top: 0;
margin-bottom: 1rem;
`;
const FooterForm = styled.div`
width: 100%;
height: 50px;
display: flex;
justify-content: flex-end;
align-items: center;
margin-top: 1rem;
margin-bottom: 0;
`;

const BtnSend = styled.button.attrs(props => ({
    width: props.width || '100%'
}))`
outline: none;
border: none;
border-color: #7367f0;
width: ${props => props.width};
height: 38px;
color: white;
outline: none !important;
font-size: 12pt;
font-weight: 500;
text-align: center;
background-color: #7367f0;
padding: .150rem;
border-radius: 0.358rem;
margin-top: 1rem;
&:disabled {
    border-color: #a49df5;
    background-color: #a49df5;
}`;

const ContInputAndLabel = styled.div `
width:100%;
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'space-between'};
align-items:${props => props.ali || 'flex-start'};
`;

const Text = styled.p `
color: ${props => props.cl || '#707070'};
font-size: ${props => props.size || '.857rem'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '.2857rem'};
margin-bottom: ${props => props.mb || '.2857rem'};
text-overflow:${props => props.longText ? 'ellipsis' : ''};
overflow:${props => props.longText ? 'hidden' : ''};
white-space:${props => props.longText ? 'nowrap' : ''};
`;

const Input = styled.input.attrs(props => ({
    // we can define static props
    marginTop: props.marginTop || '0',
    marginBottom: props.marginBotton || '0',
    marginLeft: props.marginLeft || '0',
    marginRight: props.marginRight || '0'
}))`
display: block;
width: 100%;
height: 38px;
font-size: .875rem;
font-weight: 400;
line-height: 1.45;
color: #6e6b7b;
padding: 0.438rem 1rem;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #d8d6de;
border-radius: 0.357rem;
position: relative;
margin-top: ${props => props.marginTop};
margin-bottom: ${props => props.marginBottom};
margin-left: ${props => props.marginLeft};
margin-right: ${props => props.marginRight};
&:focus {
    color: #6e6b7b;
    outline: 0;
    background-color: #fff;
    border-color: #7367f0;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}
::placeholder {
    color: #c0c0c1;
    font-size: 1rem;
}
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
`;

const TextArea = styled.textarea`
display: block;
width: 100%;
height: 38px;
font-size: .875rem;
font-weight: 400;
line-height: 1.45;
color: #6e6b7b;
padding: 0.438rem 1rem;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #d8d6de;
border-radius: 0.357rem;
position: relative;
min-height: 70px;
height: 70px;
max-height: 75px;
resize: none;
&:focus {
    color: #6e6b7b;
    outline: 0;
    background-color: #fff;
    border-color: #7367f0;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}
::placeholder {
    color: #c0c0c1;
    font-size: 1rem;
}
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
`;


const ContTable = styled.div`
padding: 1rem;
width: 100%;
min-height: 100px;
border-radius: .429rem;
background: #FFFFFF;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
overflow: auto;
`;

const TableResultScraping = styled.table`
width: 100%;
border-collapse: collapse;
border: none;
font-size: .857rem;
letter-spacing: .5px;
color: #6e6b7b;
`;
const TableResultThead = styled.thead`
width: 100%;
background: #F3F2F7;
border-radius: .429rem;
border-bottom: 2px solid #ebe9f1;
`
const TableResultTh = styled.th.attrs(props => ({
    // we can define static props
    width: props.width || '100px',
    minWidth: props.minWidth || '100px',
    textAlign: props.textAlign || 'start',
    padding: props.padding || '0.50rem',
}))`
text-align: ${props => props.textAlign};
padding: ${props => props.padding};
width: ${props => props.width};
min-width: ${props => props.minWidth};
`

const TableResultTbody = styled.tbody`
width: 100%;
display: table-row-group;
vertical-align: middle;
border-color: inherit;
`;

const TableResultTr = styled.tr`
width: 100%;
border-bottom: 2px solid #ebe9f1;
`
const TableResultTd = styled.td.attrs(props => ({
    // we can define static props
    width: props.width || '100px',
    minWidth: props.minWidth || '100px',
    textAlign: props.textAlign || 'justify',
    backGroundColor: props.backGroundColor || '#FFFFFF',
    padding: props.padding || '0.50rem',
    verticalAlign: props.verticalAlign || 'text-top',
}))`
text-align: ${props => props.textAlign};
padding: ${props => props.padding};
width: ${props => props.width};
max-width: ${props => props.width};
min-width: ${props => props.minWidth};
word-break: break-all;
vertical-align: ${props => props.verticalAlign};
`;

const BtnIcon = styled.button.attrs(props => ({
    bgColor: props.bgColor || '#FFFFFF',
    color: props.color || '#707070',
    bRadius: props.bRadius || '50px',
    width: props.width || '100px',
    height: props.height || '38px',
    mRight: props.mRight || '0',
    mLeft: props.mLeft || '0',
    mTop: props.mTop || '0',
    mBottom: props.mBottom || '0',
}))`
width: ${props=> props.width};
height: ${props=> props.height};
margin-right: ${props=> props.mRight};
margin-left: ${props=> props.mLeft};
margin-top: ${props=> props.mTop};
margin-bottom: ${props=> props.mBottom};
height: ${props=> props.height};
border: none;
outline: none;
background: ${props=> props.bgColor};
color: ${props=> props.color};
display: flex;
justify-content: center;
align-items: center;
border-radius: ${props=> props.bRadius};
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
`

const ScrapingUrlStyle = {
    Cont,
    ContForm,
    BtnSend,
    ContInputAndLabel,
    Text,
    Input,
    HeaderForm,
    FooterForm,
    BodyForm,
    TextArea,
    ContTable,
    TableResultScraping,
    TableResultThead,
    TableResultTh,
    TableResultTbody,
    TableResultTr,
    TableResultTd,
    BtnIcon
}

export default ScrapingUrlStyle