import React, { useContext, useState } from 'react'
import GridStyles from '../../../../assets/css/components/admin/products/Orders/GridStyles'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import DateTime from '../../productos/orders/components/DateTime';
import getDataThree from '../functions/getDataThree';

export default function ReportPostproductsByUser() {
    const globalContext = useContext(GlobalContext);
    const [dateTo, setDateTo] = useState(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [dataUsers, setDataUsers] = useState([]);
    const [load, setLoad] = useState(false);
    const [filterClean, setFilterClean] = useState(false)

    const getDataReport = () => {
        setLoad(true)
        getDataThree(dateTo, dateFrom)
            .then(res => {
                setDataUsers(res.data.data.result)
                console.log(res);
                setLoad(false)
            }).catch(err => {
                console.log(err)
                setLoad(false)
            })
    }

    const clearFilters = () => {
        setFilterClean(true)
        setDateTo(null)
        setDateFrom(null)
    }
    const internationalNumberFormat = new Intl.NumberFormat('en-US')
    return (
        <GridStyles.BackgroundModal active={globalContext.modalReporPostProducts}>
            <GridStyles.ContModal active={globalContext.modalReporPostProducts} w="650px">
                <GridStyles.ContHeaderModal>
                    <GridStyles.text size="12pt" fw="500">
                        Reporte de Publicaciones por usuario
                    </GridStyles.text>
                </GridStyles.ContHeaderModal>
                <GridStyles.ContBodyModal>
                    <GridStyles.ContInputsToSearch>
                        <GridStyles.contInputAndLabel style={{ width: 300 }}>
                            <GridStyles.text size="12pt" fw="500">
                                Fecha Desde:
                            </GridStyles.text>
                            <DateTime
                                setValue={setDateTo}
                                valueDefaultText={dateTo}
                                filterClean={filterClean}
                                setFilterClean={setFilterClean}
                            />
                        </GridStyles.contInputAndLabel>
                        <GridStyles.contInputAndLabel style={{ width: 300 }}>
                            <GridStyles.text size="12pt" fw="500">
                                Fecha Hasta:
                            </GridStyles.text>
                            <DateTime
                                setValue={setDateFrom}
                                valueDefaultText={dateFrom}
                                filterClean={filterClean}
                                setFilterClean={setFilterClean}
                            />
                        </GridStyles.contInputAndLabel>
                    </GridStyles.ContInputsToSearch>
                    <GridStyles.ContTable>
                        <GridStyles.TableList>
                            <GridStyles.Thead>
                                <GridStyles.TrList style={{ backGround: "#F0F0F0" }}>
                                    <GridStyles.TdList backGroundColor="#F0F0F0" style={{ fontWeight: 500 }}>
                                        Usuario
                                    </GridStyles.TdList>
                                    <GridStyles.TdList backGroundColor="#F0F0F0" style={{ fontWeight: 500 }}>
                                        Total de Productos Publicados
                                    </GridStyles.TdList>
                                </GridStyles.TrList>
                            </GridStyles.Thead>

                            {
                                load ?
                                    (
                                        <GridStyles.Tbody>
                                            <GridStyles.TrList border="1px solid #F0F0F0">
                                                <GridStyles.TdListLoad>

                                                </GridStyles.TdListLoad>
                                                <GridStyles.TdListLoad>

                                                </GridStyles.TdListLoad>
                                            </GridStyles.TrList>
                                        </GridStyles.Tbody>
                                    ) :
                                    (
                                        <GridStyles.Tbody>
                                            {
                                                dataUsers.length > 0 &&
                                                dataUsers.map(v => {
                                                    return (
                                                        <GridStyles.TrList border="1px solid #F0F0F0" key={v._id}>
                                                            <GridStyles.TdList border="1px solid #F0F0F0">
                                                                {v.user}
                                                            </GridStyles.TdList>
                                                            <GridStyles.TdList border="1px solid #F0F0F0">
                                                                {internationalNumberFormat.format(v.count)}
                                                            </GridStyles.TdList>
                                                        </GridStyles.TrList>
                                                    )
                                                })
                                            }
                                        </GridStyles.Tbody>
                                    )
                            }

                        </GridStyles.TableList>
                    </GridStyles.ContTable>
                </GridStyles.ContBodyModal>
                <GridStyles.ContFooterModal>
                    <GridStyles.BtnClose onClick={() => globalContext.setModalReporPostProducts(false)}>
                        Cerrar
                    </GridStyles.BtnClose>
                    {
                        (dateTo) && (
                            <>
                                <GridStyles.BtnClose onClick={() => clearFilters()}>
                                    Limpiar Filtro
                                </GridStyles.BtnClose>
                                <GridStyles.btnSave onClick={() => getDataReport()}>
                                    Buscar
                                </GridStyles.btnSave>
                            </>
                        )
                    }

                </GridStyles.ContFooterModal>
            </GridStyles.ContModal>
        </GridStyles.BackgroundModal>
    )
}
