import React, { useEffect, useRef, useState } from 'react'
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as locales from 'react-date-range/dist/locale';
import { Calendar } from 'react-date-range';
import CronReportsStyle from '../../../../../assets/css/components/admin/cron-reports/CronReportsStyle';
import useOutsideAlerterMenu from '../functions/useOutsideAlerterMenu';
import { BiCalendarAlt } from "react-icons/bi";

export default function DateTime({setValue, valueDefaultText, filterClean, setFilterClean}) {

    const [date, setDate] = useState(null);
    const [open, setOpen] = useState(false);
    const [text, setText] = useState(null);
    const dataRefInput = useRef(null);

    const changeDate = date => {
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        setDate(date)
        //setState(yyyy +'-'+ mm +'-'+ dd);
        setText(`${dd}-${mm}-${yyyy}`);
        handleChangeValue(`${yyyy}-${mm}-${dd}`)
        setOpen(false)
    }

    useEffect(() => {
        //console.log('el vacacac', filterClean);
        if(filterClean) {
            //console.log('se ejecuta', filterClean)
            setText(null);
            setDate(null)
        } 
    },[filterClean]);

    const handleChangeValue = (valor) => {
        setValue(valor);
    }

    useOutsideAlerterMenu(dataRefInput, setOpen);
    return (
        <CronReportsStyle.dataPikerInput ref={dataRefInput}>
            <div 
                className="input-date d-flex"
                onClick={() => {
                    setOpen(!open);
                    setFilterClean(false)
                }}
            >
            {text? text : valueDefaultText}
            <button className={`btn-date ${open && 'active'}`} >
                <BiCalendarAlt />
            </button>
            </div>
            <div className={`cont-calendar ${open && 'active'}`} >
            <Calendar 
                onChange={item => changeDate(item)}
                locale={locales['es']} date={date} />
            </div>
        </CronReportsStyle.dataPikerInput>
    )
}
