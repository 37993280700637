import React, { useContext, useRef, useState } from 'react'
import {
    ContListcategories,
    HeaderListCategories,
    BodyListCategories,
    FooterListCategories,
    ListCategories,
    ItemListCategories,
    ContLabelItem,
    ContIconOpenSubList,
    SubListCategories,
    ContbtnAddCategoryToLink,
    BtnAddToList,
    ContLabelItemLinked,
    BtnAddToDelete,
} from '../../../assets/css/components/admin/categories/LinkCategories'
import { Text } from '../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts'
import Pager from "../../general/Pager";
import { BiCaretDown, BiCheckCircle, BiTrashAlt, BiGridAlt } from 'react-icons/bi';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useEffect } from 'react';
import { GlobalContext } from '../../../contexts/GlobalContext';
import getAllParents from './functions/getAllParents';
import getChilds from './functions/getChilds';
import MassiveScrapingCss from '../../../assets/css/components/admin/products/amazon/MassiveScrapingCss'
import goToUnLinkCat from './functions/goToUnLinkCat';
import updateCategorie from './functions/updateOneCat';


export default function ListCategoriesAmazon({ listUrlToLinkWithMl, setListUrlToLinkWithMl }) {

    const globalContext = useContext(GlobalContext);
    const pageItemCount = 20;
    const [totalCategories, setTotalCategories] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [actualCategory, setActualCategory] = useState(null);
    const [loadChild, setLoadChild] = useState(null);
    const [load, setLoad] = useState(true);
    const [filter, setFilter] = useState('');
    const [filterTSearch, setFilterTSearch] = useState('')
    const [categories, setCategories] = useState([]);
    const [hasChild, setHasChild] = useState([]);
    const [childOnFocus, setChildOnFocus] = useState([]);
    const [childByparent, setChildByparent] = useState([]);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [idReadyToLink, setIdReadyToLink] = useState([]);
    const urlRef = useRef(null);

    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    //? buscamos los primeros padres
    useEffect(() => {
        setLoad(true);
        getAllParents(currentPage, pageItemCount, filterTSearch, axiosCancelTokenSource)
            .then((r) => {
                setTotalCategories(r.data.data.totalCategories)
                setCategories(r.data.data.categories);
                setLoad(false);
            })
            .catch((e) => { handleAxiosError(e, 'Presentamos un error al cargar las categorias'); console.log(e) })
    }, [currentPage, filterTSearch]);

    /**
     * 
     * @param {*} node
     *  @description buscamos los hijos de los padres principales o de los que no tiene padre
     */
    const searchChild = async (node) => {
        if (actualCategory === node) {
            removeAll();
            return;
        }
        setLoadChild(node);
        setActualCategory(node);
        getChilds(node, axiosCancelTokenSource)
            .then((r) => {
                if (r.data.data.categories.length > 0) {
                    setHasChild([...hasChild, node]);
                    setChildByparent([...childByparent, {
                        parent: node,
                        childs: r.data.data.categories
                    }])
                }else {
                    setHasChild([...hasChild, node]);
                    setChildByparent([...childByparent, {
                        parent: node,
                        childs: []
                    }])
                  }
                setLoadChild(null)
            }).catch(e => { handleAxiosError(e, 'Presentamos un error al cargar las categorias'); setLoadChild(null); console.log(e) })
        //? agg el node al array de padres con hijos
    }

    const goToAddVariants = (_id) => {
        
        updateCategorie(_id, true)
        .then(resp => {
            setLoad(false);
            console.log(resp)
            globalContext.showModalWithMsg('La activacion fue con exito!')
        })
        .catch(e => {
            handleAxiosError(e, 'Ocurrio un error al eliminar el linkeo');
        })
    }

    const autoPublisron = (_id, valor) => {
        
        updateCategorie(_id, null, valor)
        .then(resp => {
            setLoad(false);
            console.log(resp)
            globalContext.showModalWithMsg('La activacion fue con exito!')
        })
        .catch(e => {
            handleAxiosError(e, 'Ocurrio un error al eliminar el linkeo');
        })
    }
    const goToUnlink = async (catAmazon, catMl) => {
        setLoad(true);
        const entId = globalContext.currentEnterpriseId;
        const dataToSend = [
        {
            id: catAmazon,
            plugin: "amazonScrap"
        }, 
        {
            id: catMl,
            plugin: "ml"
        }
    ]
        await goToUnLinkCat(dataToSend, entId,  )
        .then((response) => {
            //eliminamos la categoria
            setLoad(false);
            console.log(response)
            globalContext.showModalWithMsg('La eliminacion del linkeo fue exito!')
        })
        .catch((e) => { handleAxiosError(e, 'Ocurrio un error al eliminar el linkeo'); console.log(e) })
    }

    /**
     * 
     * @param {*} node 
     * @description buscamos los hijos para las categorias que si tiene padre
     */
    const searchSubChild = (node, id) => {

        if (childOnFocus.includes(node)) {
            removeChild(node);
            return
        }

        setLoadChild(node);
        setChildOnFocus([...childOnFocus, node]);
        getChilds(node, axiosCancelTokenSource)
            .then((r) => {
                let copyhasChildArray = [...hasChild];
                let copyChildByParent = [...childByparent];

                if (r.data.data.categories.length > 0) {
                    copyhasChildArray.push(node);
                    copyChildByParent.push({
                        parent: node,
                        childs: r.data.data.categories
                    })
                    setChildByparent(copyChildByParent)
                    setHasChild(copyhasChildArray);
                } else {
                    copyhasChildArray.push(node);
                    copyChildByParent.push({
                        parent: node,
                        childs: []
                    })
                    setChildByparent(copyChildByParent)
                    setHasChild(copyhasChildArray);
                    let copyArrayReadyToLink = [...idReadyToLink];
                    copyArrayReadyToLink.push(id);
                    setIdReadyToLink(copyArrayReadyToLink);
                }
                setLoadChild(null)
            }).catch(e => { handleAxiosError(e, 'Presentamos un error al cargar las categorias'); setLoadChild(null); console.log(e) })
        //? agg el node al array de padres con hijos
    }

    const renderChild = ({ node, name, _id, linked = null }) => {

        if (node) {
            const childs = childByparent.find((c) => c.parent === node);
            if (typeof childs !== undefined) {
                //? mapeamos el array de child
                if (childs.childs.length > 0) {
                    return (
                        <SubListCategories>
                            {
                                childs.childs.map((v) => {
                                    return (
                                        <ItemListCategories key={v._id}>
                                            <ContLabelItem active={childOnFocus.includes(v.node)} onClick={() => searchSubChild(v.node, v._id)} >
                                                {v.name.en} - {v._id}
                                                {
                                                    loadChild === v.node ?
                                                        (
                                                            <ContIconOpenSubList>
                                                                <Spinner animation="border" role="status">
                                                                    <span className="sr-only">Cargando...</span>
                                                                </Spinner>
                                                            </ContIconOpenSubList>
                                                        ) :
                                                        idReadyToLink.includes(v._id) ?
                                                            (
                                                                <ContIconOpenSubList>
                                                                    <BiCheckCircle />
                                                                </ContIconOpenSubList>
                                                            )
                                                            :
                                                            (
                                                                <ContIconOpenSubList active={childOnFocus.includes(v.node)}>
                                                                    <BiCaretDown />
                                                                </ContIconOpenSubList>
                                                            )
                                                }
                                            </ContLabelItem>
                                            {
                                                hasChild.includes(v.node) &&
                                                renderChild(v)
                                            }
                                        </ItemListCategories>
                                    )
                                })
                            }
                        </SubListCategories>
                    )
                } else {
                    
                    if (linked) {
                        
                        if (linked.ml.length > 0) {
                            
                            return (
                                <SubListCategories>
                                    <ContbtnAddCategoryToLink>
                                        <BtnAddToList style={{background: '#7367F0', marginRight: 5}} onClick={() => goToAddVariants(_id)}>
                                            <BiGridAlt /> publicar por variantes
                                        </BtnAddToList>
                                        <BtnAddToList style={{background: '#7367F0', marginRight: 5}} onClick={() => autoPublisron(_id,true)}>
                                             Activar public. Auto

                                        </BtnAddToList>
                                        <BtnAddToList onClick={() => addCategoryToLink(node, name, _id)}>
                                            Agregar
                                        </BtnAddToList>
                                    </ContbtnAddCategoryToLink>
                                    {
                                        linked.ml.map(v => {
                                            return (
                                                <ItemListCategories key={v._id}>
                                                    <div style={{display:"flex", justifyContent: "start", alignItems: "center"}}>
                                                        <BtnAddToDelete style={{marginRight: 5, background: '#dc3545'}} onClick={() => goToUnlink(_id, v._id)}>
                                                            <BiTrashAlt />
                                                        </BtnAddToDelete>
                                                    </div>
                                                    <ContLabelItemLinked>
                                                        -- {v.name} - {v.id}
                                                    </ContLabelItemLinked>
                                                </ItemListCategories>
                                            )
                                        })
                                    }
                                </SubListCategories>
                            )
                        } else {
                            
                            if (listUrlToLinkWithMl.find((v) => v.plugin === 'amazonScrap' && v.nodeCategoryamazon === node) === undefined) {
                                return (
                                    <ContbtnAddCategoryToLink>
                                        <BtnAddToList onClick={() => addCategoryToLink(node, name, _id)}>
                                            Agregar
                                        </BtnAddToList>
                                    </ContbtnAddCategoryToLink>
                                )
                            }
                        }
                    } else {
                        
                        if (listUrlToLinkWithMl.find((v) => v.plugin === 'amazonScrap' && v.nodeCategoryamazon === node) === undefined) {
                            return (
                                <ContbtnAddCategoryToLink>
                                    <BtnAddToList onClick={() => addCategoryToLink(node, name, _id)}>
                                        Agregar
                                    </BtnAddToList>
                                </ContbtnAddCategoryToLink>
                            )
                        }
                    }
                }

            } else {
                return (
                    <ContbtnAddCategoryToLink>
                        <BtnAddToList onClick={() => addCategoryToLink(node, name, _id)}>
                            Agrega
                        </BtnAddToList>
                    </ContbtnAddCategoryToLink>
                )
            }
        }
    }

    const removeChild = (node) => {
        //? buscamos el padre y lo eliminamos

        let copyhasChildArray = hasChild.filter((nv) => nv !== node)
        let copyChildByParent = childByparent.filter((nv) => nv.parent !== node)
        const copyChildOnFocus = childOnFocus.filter((nv) => nv !== node)

        setChildOnFocus(copyChildOnFocus)
        setChildByparent(copyChildByParent)
        setHasChild(copyhasChildArray);
    }

    const removeAll = () => {
        //? buscamos el padre y lo eliminamos
        setHasChild([])
        setChildOnFocus([])
        setChildByparent([])
        setActualCategory(null)
    }


    /**
     * @description metodo para agreagar las categorias a la lista de linkeadas
     */
    const addCategoryToLink = (node, name, _id) => {
        //? hacemos la copia del arreeglo
        let copyArrayCategoriesToLink = [...listUrlToLinkWithMl];
        //? luego validamos que el valor que vamos a agregar no exista
        const existInList = copyArrayCategoriesToLink.find((v) => v.plugin === 'amazonScrap' && v.id === _id);
        if (existInList === undefined) {
            copyArrayCategoriesToLink.push(
                {
                    nodeCategoryamazon: node,
                    nameCategory: name,
                    hasMlId: false,
                    mlCaTegoryNode: '',
                    mlCategoryName: '',
                    plugin: 'amazonScrap',
                    id: _id,
                    dataToSend: [
                        {
                            plugin: 'amazonScrap',
                            id: _id,
                        }
                    ],
                }
            )
            setListUrlToLinkWithMl(copyArrayCategoriesToLink);
        }
    }

    const setFilterToPublish = (e) => {
        e.preventDefault();
        setFilterTSearch(filter);
    }

    return (
        <ContListcategories w="400px">
            <HeaderListCategories>
                <Text size='10pt' fw="bold" cl="#696969">
                    Categorias de Amazon
                </Text>
                <form style={{ width: '100%' }} onSubmit={(e) => setFilterToPublish(e)}>
                    <MassiveScrapingCss.inputOne placeholder="URL" type="text" onChange={(e) => setFilter(e.target.value)} ref={urlRef} />
                </form>
            </HeaderListCategories>
            <BodyListCategories>
                <ListCategories>
                    {
                        categories.length > 0 &&
                        categories.map((v) => {
                            return (
                                <ItemListCategories key={v._id} >
                                    <ContLabelItem active={actualCategory === v.node} onClick={() => { searchChild(v.node) }}>
                                        {v.name.en} - {v._id}
                                        {
                                            loadChild === v.node ?
                                                (
                                                    <ContIconOpenSubList>
                                                        <Spinner animation="border" role="status">
                                                            <span className="sr-only">Cargando...</span>
                                                        </Spinner>
                                                    </ContIconOpenSubList>
                                                ) :
                                                (
                                                    <ContIconOpenSubList active={actualCategory === v.node}>
                                                        <BiCaretDown />
                                                    </ContIconOpenSubList>
                                                )
                                        }
                                    </ContLabelItem>
                                    {
                                        loadChild !== v.node &&
                                        (actualCategory === v.node) &&
                                        hasChild.includes(v.node) &&
                                        renderChild(v)
                                    }
                                </ItemListCategories>
                            )
                        })
                    }
                </ListCategories>
            </BodyListCategories>
            <FooterListCategories>
                <Pager
                    handleSetPage={setCurrentPage}
                    totalResults={totalCategories}
                    currentPage={currentPage}
                    resultsPerPage={pageItemCount}
                />
            </FooterListCategories>
        </ContListcategories>
    )
}
