import React from "react";
import Routes from "../../../../Routes";
/**
 * * import Cards menu
 */
import CardsMenu from "../../menus/CardsMenu";

export default function Productos() {
  return <CardsMenu routes={Routes} father="/productos" />;
}
