import styled from 'styled-components';
const cont = styled.div`
position: relative;
width: 100%;
height:100%;
padding:10px;
overflow-y: auto;
@media (max-width: 990px){
    position: relative;
}
text-align: left;
`;

const constGrid = styled.div`
position: ${props=> props.p || 'absolute'};
top: 30px;
bottom: 0;
border-radius: .429rem;
background: #FFF;
min-width:300px;
width: ${props=> props.w || '100%'};
border-radius:5px;
box-shadow: ${props=> props.shadow || "0px 3px 6px rgba(0, 0, 0, 0.16)"};
margin-bottom:10px;
display:flex;
max-height: 100%;
flex-direction: column;
justify-content:space-between;
align-items:center;
margin-bottom: 10px;
overflow: hidden;
right: 0;
left: 0;
margin-top: ${props=> props.mt || '20px'};

`;

const heaterGrid = styled.div`
background: #FFF;
height: 100px;
width:100%;
padding:10px;
border-radius: .429rem;
display: flex;
justify-content: space-between;
align-items: center;
`;
const bodyGrid = styled.div`
background: #FFF;
height: ${props=> props.h || 'auto'};
max-height: 100%;
width:100%;
padding:10px;
overflow-y: auto;
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg,#7367f0,rgba(115,103,240,.7));
        border-radius: 50px;
        
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {        
        box-shadow: 2px 0px 6px #7367f0;
        
    }

    .load-screen {
        background-color: #ffffff;
        height:300px;
        width:100%;
        display:none;
        justify-content:center;
        align-items:center;
        z-index:100000;
        .spinner-border {
            border: .25em solid #7367f0 !important;
            border-right-color: transparent;
        }
    }
    .load-screen.active {
        display:flex;
    }
    .list-c.active{
        display:none;
    }
`;
const footerGrid = styled.div`
background: #FFF;
height: 50px;
width:100%;
display:flex;
justify-content:center;
align-items:center;
padding:10px;
`;
const inputSearch = styled.input`
width: 100%;
height: 40px;
border-radius: .429rem;
padding-left:10px;
background: #fff;
border: 0.5px solid  rgba(112, 112, 112, 0.44);
outline:none;
color: rgb(112, 112, 112);
::placeholder{
    font-family: "Berlin Rounded";
    font-weight: 300;
    font-size: 12px;
    color: rgba(112, 112, 112, 0.44);
}
`;

const constDataGrid = styled.div`
width:100%;
border-radius: .429rem;
min-height: 70px;
background: #fff;
filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
display:flex;
flex-direction: column;
align-self: flex-start;
justify-content:space-between;
align-items:center;
margin-bottom: 10px;
@media (min-width: 300px) {
    flex-direction: column;
    justify-content:center;
}
@media (min-width: 900px) {
    flex-direction: column;
    justify-content:space-between;
}
`;

const constDataGridHeader = styled.div`
border-top-left-radius: .429rem;
border-top-right-radius: .429rem;
margin-top: 0;
padding: 10px;
flex-wrap: wrap;
display: flex;
justify-content: flex-start;
align-items: center;
background: #7367F0;
width: 100%;
`;
const constDataGridFooter = styled.div`
border-bottom-left-radius: .429rem;
border-bottom-right-radius: .429rem;
margin-bottom: 0;
padding: 5px;
display: flex;
justify-content: flex-end;
align-items: center;
background: #fff;
width: 100%;
flex-wrap: wrap;
`;

const contGridData = styled.div`
width:100%;
max-width:100%;
display: grid;
grid-gap: 0.5rem;
padding: 10px;
grid-template-columns: repeat(auto-fit, minmax(50px, 280px));
@media (min-width: 300px) {
    justify-content: flex-start;
    grid-template-columns: repeat(auto-fit, minmax(50px, 300px));
}
@media (min-width: 900px) {
    justify-content:flex-start;
    grid-template-columns: repeat(auto-fit, minmax(50px, 200));
}
@media (min-width: 1044px) {
    justify-content:flex-start;
    grid-template-columns: repeat(auto-fit, minmax(50px, 280px));
}
`;
const contGridDataTwo = styled.div`
width:100%;
max-width:100%;
display: grid;
grid-gap: 0.5rem;
grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`;

const dataGrid = styled.div`
text-align: left;
color:#707070;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
p:nth-child(1) {
  font-weight:600;
}
p {
    margin-bottom:0;
}
`;
const btnUpdate = styled.button`
background: #7367f0;
padding: 8px 16px;
color:#FFF;
font-size: 12px;
border-radius: .429rem;
border: none;
outline:none !important;

`;
const btnAdd = styled.button`
background: #7367f0;
height: 40px;
width: 40px;
border-radius:50px;
display:flex;
justify-content:center;
align-items:center;
color:#FFF;
font-size: 12px;
border: none;
outline:none !important;
margin-right: 10px;
`
const btnDelete = styled.button`
background: #FFFF;
padding: 8px 16px;
color:#707070;
font-size: 12px;
border-radius: .429rem;
border: 1px solid #707070;
outline:none !important;
@media (min-width: 300px) {
    margin-top:10px;
    margin-left:${props=> props.ml || '10px'};
}
@media (min-width: 900px) {
    margin-left:10px;
}
`;

const contModal = styled.div`
    height: 100%;
    transition: all ease-in 0.01s;
    width:100%;
    background-color:rgba(112,112,112,0.50);
    position: fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    transition: all ease-out 0.1s;
    transform: translateY(${props=> props.active ? '0px' : '-2000px'});
    z-index:100000000 !important;
    display:flex;
    justify-content:center;
    align-items:center;
    display: flex;
    justify-content:center;
    align-items:center;
    overflow-y: auto;
`;
const modalCategorie = styled.div`
min-width:300px;
min-height: 300px;
width: ${props=> props.w || "50%"};
margin-top:auto;
margin-bottom:auto;
margin-left:10px;
background: #FFF;
border-radius: .429rem;
filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
transition: all ease-out 0.30s;
transform: translateY(${props=> props.active ? '0px' : '-2000px'});
display: flex;
flex-direction: column;
justify-content:space-between;
align-items:center;
margin-left:60px;

@media (max-width: 990px) {
    margin-left:10%;
    margin-right:10%;
}
`
const headerModal = styled.div`
padding:10px;
height: 60px;
width:100%;
background:#F5F5F6;
display: flex;
justify-content:space-between;
align-items:center;
align-self: flex-start;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
`
const footerModal = styled.div`
padding:10px;
height: 60px;
width:100%;
display: flex;
justify-content:flex-end;
align-items:center;
align-self: flex-end;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
border-top:0.5px solid rgba(112, 112, 112, 0.5);
margin-top: auto;
margin-bottom:0;
`;
const bodyModal = styled.div`
padding:10px;
height: auto;
width:100%;
display: flex;
flex-direction: column;
justify-content:center;
align-items:center;
margin-top: auto;
margin-bottom:auto;
`;
const btnCloseModal = styled.button`
width:38px;
height: 38px;
border-radius:50px;
border:none;
color: #ffffff;
background-color: #7367f0;
display:flex;
justify-content:center;
align-items:center;
outline:none !important;
cursor: pointer;
margin-right: 0;
`
const textHeader = styled.p`
margin-bottom: 0;
color: #707070;
font-size: ${props=> props.size || '12px'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || 'auto'};
`
const text = styled.p`
margin-bottom: 0;
color: ${props=> props.cl || '#707070'};
font-weight: ${props=> props.fw  || 'lighter'};
font-size: ${props=> props.size || '12px'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
@media (max-width: 990px) {
    margin-left:0;
}
`

const input = styled.input`
    width: ${props => props.w || '100%'};
    height: 38px;
    border-radius: .357rem;
    background: #fff;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    border: 1px solid #d8d6de;
    outline: none !important;
    padding:6px 16px;
    font-size:12pt;
    color: rgb(112, 112, 112);
    font-weight: 600;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12px;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus, &:active {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
            box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
        }
    margin-top : ${props => props.mt || 0};
`;
const contInputAndLabel = styled.div`
width:100%;
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'space-between'};
align-items:${props => props.ali || 'flex-start'};
`;

const CityCss = {
    cont,
    constGrid,
    inputSearch,
    heaterGrid,
    bodyGrid,
    constDataGrid,
    footerGrid,
    btnUpdate,
    contGridData,
    contGridDataTwo,
    dataGrid,
    contModal,
    modalCategorie,
    headerModal,
    footerModal,
    btnCloseModal,
    textHeader,
    bodyModal,
    btnDelete,
    btnAdd,
    text,
    constDataGridHeader,
    constDataGridFooter,
    input,
    contInputAndLabel
};

export default CityCss;