import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import FormPlantillaCss from '../../../../assets/css/components/admin/products/ml/FormPlantillaCss'
import { GlobalContext } from "../../../../contexts/GlobalContext";
import { Redirect } from "react-router-dom";
import {
    keyUserId,
    keyAccessToken,
} from "../../../../assets/js/SessionStorageNames";
import Routes from '../../../../Routes';
import verifyAccessRole from '../../../../assets/js/verifyAccessRole';
export default function FormPlantilla() {
    const [data, setData] = useState('')
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const globalContext = useContext(GlobalContext);
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 58);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);
        /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };
        /**
     * Load the plugin config
     */
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then(resp => {
            const data = resp.data.data.configData.descriptionTemplate;
            setData(data)
            //console.log(data)
        }).catch((err) =>
            handleAxiosError(
                err,
                "Disculpe, no se pudo cargar la configuracion actual."
            )
        );
    }, [globalContext.currentEnterpriseId]);
    //todo verificar da error
    const saveChanges = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if (!window.confirm("Desea guardar los cambios?")) return;

        globalContext.setLoaderPage(true);
        
        axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
                config: {
                    descriptionTemplate: data,
                }
            }, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then(resp => {
            //Set the actual config as the default data
            globalContext.showModalWithMsg(`Los datos fueron guardados`);
        }).catch((err) =>
            handleAxiosError(
                err,
                "Disculpe, no se pudieron guardar los cambios."
            )
        ).finally(() => {
            globalContext.setLoaderPage(false);
        });
    }
    return (
        <FormPlantillaCss.cont>
            {redirect}
            <FormPlantillaCss.contForm>
                <FormPlantillaCss.header>
                Nueva plantilla de publicacion
                </FormPlantillaCss.header>
                <FormPlantillaCss.body>
                    <FormPlantillaCss.subTitle>
                        Etiquetas disponibles
                    </FormPlantillaCss.subTitle>
                    <FormPlantillaCss.contGrid  gtcr="auto" gtcrt="auto">
                        <FormPlantillaCss.contInputs>
                            <p>@titulo</p>
                        </FormPlantillaCss.contInputs>
                        <FormPlantillaCss.contInputs>
                            <p>@titulo_en_ingles</p>
                        </FormPlantillaCss.contInputs>
                        <FormPlantillaCss.contInputs>
                            <p>@detalles_tecnicos</p>
                        </FormPlantillaCss.contInputs>
                        <FormPlantillaCss.contInputs>
                            <p>@sku</p>
                        </FormPlantillaCss.contInputs>
                        <FormPlantillaCss.contInputs>
                            <p>@stock</p>
                        </FormPlantillaCss.contInputs>
                        <FormPlantillaCss.contInputs>
                            <p>@tiempo_envio</p>
                        </FormPlantillaCss.contInputs>
                    </FormPlantillaCss.contGrid>
                    <FormPlantillaCss.contGrid gtcr="auto" gtcrt="auto">
                    <FormPlantillaCss.contInputs>
                            <p>@caracteristicas</p>
                        </FormPlantillaCss.contInputs>
                        <FormPlantillaCss.contInputs>
                            <p>@descripcion</p>
                        </FormPlantillaCss.contInputs>
                        <FormPlantillaCss.contInputs>
                            <p>@informacion_adicional</p>
                        </FormPlantillaCss.contInputs>
                        <FormPlantillaCss.contInputs>
                            <p>@especificaciones_producto</p>
                        </FormPlantillaCss.contInputs>
                        <FormPlantillaCss.contInputs>
                            <p>@variations</p>
                        </FormPlantillaCss.contInputs>
                    </FormPlantillaCss.contGrid>
                    <FormPlantillaCss.contGrid>
                        <FormPlantillaCss.contInputs>
                            <p>Información</p>
                            <FormPlantillaCss.textArea type="text" onChange={(e) => setData(e.target.value)} defaultValue={data} />
                        </FormPlantillaCss.contInputs>
                    </FormPlantillaCss.contGrid>
                    
                </FormPlantillaCss.body>
                <FormPlantillaCss.footer>
                    <FormPlantillaCss.btn onClick={() => saveChanges()}>
                        Guardar
                    </FormPlantillaCss.btn>
                </FormPlantillaCss.footer>
            </FormPlantillaCss.contForm>
        </FormPlantillaCss.cont>
    )
}
