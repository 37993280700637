import React, {useEffect, useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import CronReportsStyle from '../../../../assets/css/components/admin/cron-reports/CronReportsStyle'
export default function ItemListError(props) {
    const { data } = props;
    const [isActive, setIsActive] = useState(false);
    const [product, setProduct] = useState(null);
    const [variants, setVariants] = useState(null)
    const [defaultIndex, setDefaultIndex] = useState(0);
    const [idRedirect, setIdRedirect] = useState('');

    useEffect(() => {
        if (!data) return;
        if (data.data.amazonProductOriginal.variants.length > 0) {
            setVariants(data.data.amazonProductOriginal.variants);
            setIdRedirect(data.data.amazonProductOriginal._id)
            setProduct(data.data.amazonProductOriginal.variants[defaultIndex])
        } else {
            setProduct(data.data.amazonProductOriginal);
            setIdRedirect(data.data.amazonProductOriginal._id)
        }
    }, [data, defaultIndex]);

    return (
        <CronReportsStyle.ItemListError>
            <CronReportsStyle.HeeaderItemList>
                <CronReportsStyle.TextLink textDecoration="true" href={`/admin/productos/detail-kbt/${idRedirect}`} size="12pt" fw="500" style={{ textAlign: 'justify' }}>
                    {product && (product.title ? (`${product.title.es || product.title.en} - SKU: ${product.asin} - Fecha de actualizacion: ${data.updatedAt}`):`No posee Titulo - Fecha de Actualización: ${data.updatedAt}`)}
                </CronReportsStyle.TextLink>
                <CronReportsStyle.BtnExpanList onClick={() => setIsActive(!isActive)}>
                    <BiChevronDown />
                </CronReportsStyle.BtnExpanList>
            </CronReportsStyle.HeeaderItemList>
            <CronReportsStyle.BodyItemList active={isActive}>
                {
                    variants &&
                    (
                        <CronReportsStyle.ContVariants>
                            <CronReportsStyle.ContVariantsHeader>
                                <CronReportsStyle.Text size="12pt" fw="500">
                                    Variantes
                                </CronReportsStyle.Text>
                            </CronReportsStyle.ContVariantsHeader>
                            <CronReportsStyle.ContVariantsBody>
                                <CronReportsStyle.ContImgVariants>
                                    {
                                        variants.map((v, i) => {
                                            return (
                                                <CronReportsStyle.ImgVariants
                                                    key={v.asin + '-' + i}
                                                    url={v.images[0]}
                                                    onClick={() => setDefaultIndex(i)}
                                                />
                                            )
                                        })
                                    }
                                </CronReportsStyle.ContImgVariants>
                            </CronReportsStyle.ContVariantsBody>
                        </CronReportsStyle.ContVariants>

                    )
                }

                <CronReportsStyle.Text style={{ textAlign: 'start' }}>
                    {data.error && data.error.error}
                </CronReportsStyle.Text>
                <CronReportsStyle.Text style={{ textAlign: 'start' }}>
                    {data.error && data.error.stack}
                </CronReportsStyle.Text>
            </CronReportsStyle.BodyItemList>
        </CronReportsStyle.ItemListError>
    )
}
