import React, { useContext, useEffect, useState } from 'react'
import ConfigStyled from '../../../../assets/css/components/admin/config/ConfigStyled'
import { BiRadioCircleMarked } from 'react-icons/bi'
import axios from 'axios';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
export default function Three() {
    const [isSending, setIsSending] = useState(false);
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [priceLists, setPriceLists] = useState([]);
    const [defaultConfig, setDefaultConfig] = useState({});
    const [config, setConfig] = useState({});
    const [dataComercialUser, setDataComercialUser] = useState([]);
    const [dataTeam, setDataTeam] = useState([]);
    const [dataWareHose, setDataWareHouse] = useState([]);
    const [dataStockLocation, setDataStockLocation] = useState([]);
    const [dataShippingProduct, setDataShippingProduct] = useState([]);
    const [dataShippingVariant, setDataShippingVariant] = useState([]);
    const [dataTypeObligation, setDataTypeObligation] = useState([]);
    const [dataPaymentTerms, setDataPaymentTerms] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [dataTags, setDataTags] = useState([]);
    const [dataPartnerDocumentType, setDataPartnerDocumentType] = useState([]);
    const [postConfigIdState, setPostConfigIdState] = useState([]);

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (msg) globalContext.showModalWithMsg(msg);
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    /**
     * Change the config state
     * @param {Event} e The change event
     */
    const updateConfigState = (e) => {
        const name = e.target.name;
        const newConfig = Object.assign({}, config);

        switch (e.target.type) {
            case "checkbox":
                newConfig[name] = e.target.checked;
                break;

            default:
                newConfig[name] = e.target.value;
                break;
        }

        setConfig(newConfig);
    };

    //* obtenemos los usuarios comerciales
    const getDataConfig = async (entId) => {
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo/getDataByConfiguration`,
            {
                enterpriseId: entId
            }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }

    /**
     * Send the changes to the server
     */
    const saveChanges = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if (!window.confirm("Desea guardar los cambios?")) return;
        setIsSending(true)
        globalContext.setLoaderPage(true);
        // console.log('data to send ', config)
        axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/odoo`, {
            config: {
                thumbnailField: config.thumbnailField,
                orderPriceList: config.orderPriceList,
                defaultPricelist: config.defaultPricelist,
                stockField: config.stockField,
                stockModel: config.stockModel,
                syncStock: config.syncStock,
                syncPrice: config.syncPrice,
                syncProductsWithoutCompany: config.syncProductsWithoutCompany,
                createSaleOrders: config.createSaleOrders,
                createUserWithSaleOrders: config.createUserWithSaleOrders,
                saleOrderUserId: config.saleOrderUserId,
                saleOrderMlFulfillmentWarehouseId: config.saleOrderMlFulfillmentWarehouseId,
                saleOrderMlFulfillmentUserId: config.saleOrderMlFulfillmentUserId,
                saleOrderMlShippingProductId: config.saleOrderMlShippingProductId,
                saleOrderMlShippingVariantId: config.saleOrderMlShippingVariantId,
                saleOrderMlSuperExpressShippingUserId: config.saleOrderMlSuperExpressShippingUserId,
                saleOrderMlSuperExpressShippingWarehouseId: config.saleOrderMlSuperExpressShippingWarehouseId,
                partnerMlUserIdField: config.partnerMlUserIdField === '' ? null : config.partnerMlUserIdField,
                partnerMlObligationTypeIds: config.partnerMlObligationTypeIds,
                saleOrderMlTeamId: config.saleOrderMlTeamId,
                partnerMlPropertyProductPricelistId: config.partnerMlPropertyProductPricelistId,
                partnerTeamId: config.partnerTeamId,
                partnerUserId: config.partnerUserId,
                partnerDocumentType: config.partnerDocumentType,
                defaultStockLocationId: config.defaultStockLocationId,
                lang: config.lang,
                source: config.source,
                paymentTerms: config.paymentTerms,
                pricePricelistName: config.pricePricelistName,
                pricePosConfigId: parseInt(config.pricePosConfigId),
                pricePosWooConfigId: parseInt(config.pricePosWooConfigId),

                lnPriceListId: config.lnPriceListId,
                lnSaleOrderUserId: config.lnSaleOrderUserId,
                lnWarehouseId: config.lnWarehouseId,
                lnTeamId: config.lnTeamId,
                lnClientOrderRef: config.lnClientOrderRef,
                lnStock: config.lnStock,
                lnSaleOrder: config.lnSaleOrder,
                lnDocumentType: config.lnDocumentType,
                lnObligation: config.lnObligation,
                lnCarrier: config.lnCarrier,
                lnPayment: config.lnPayment,
                lnPolicy: config.lnPolicy,
                lnSource: config.lnSource,
                lnTag: config.lnTag,

                dfStock: config.dfStock,
                dfSaleOrder: config.dfSaleOrder,
                dfPriceListId: config.dfPriceListId,
                dfSaleOrderUserId: config.dfSaleOrderUserId,
                dfWarehouseId: config.dfWarehouseId,
                dfTeamId: config.dfTeamId,
                dfClientOrderRef: config.dfClientOrderRef,
                dfDocumentType: config.dfDocumentType,
                dfObligation: config.dfObligation,
                dfCarrier: config.dfCarrier,
                dfPayment: config.dfPayment,
                dfPolicy: config.dfPolicy,
                dfSource: config.dfSource,
                dfTag: config.dfTag || "",

                pyStock: config.pyStock,
                pySaleOrder: config.pySaleOrder,
                pyPriceListId: config.pyPriceListId,
                pySaleOrderUserId: config.pySaleOrderUserId,
                pyWarehouseId: config.pyWarehouseId,
                pyTeamId: config.pyTeamId,
                pyClientOrderRef: config.pyClientOrderRef,
                pyDocumentType: config.pyDocumentType,
                pyObligation: config.pyObligation,
                pyCarrier: config.pyCarrier,
                pyPayment: config.pyPayment,
                pyPolicy: config.pyPolicy,
                pySource: config.pySource,
                pyTag: config.pyTag || "",

                isScraping: config.isScraping || false,

                descriptionTemplate: ''
            }
        }, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then(resp => {
            //Set the actual config as the default data
            setDefaultConfig(Object.assign(defaultConfig, config));
            setIsSending(false)
            globalContext.showModalWithMsg(`Los datos fueron guardados`);
        }).catch((err) =>
            {
                handleAxiosError(
                    err,
                    "Disculpe, no se pudieron guardar los cambios."
                )
                setIsSending(false)
            }
        ).finally(() => {
            globalContext.setLoaderPage(false);
            setIsSending(false)
        });
    }

    /**
     * Load the pricelists
     */
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-pricelist/${entId}`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then(resp => {
            const data = resp.data.data;
            setPriceLists(data.priceLists);
        }).catch((err) => {
            console.log(err)
            handleAxiosError(
                err,
                "Disculpe, no se pudo cargar la lista de tarifas."
            )
        }
        );

        // ? get all values by data on dropDown
        // * firts get the valu by comercial user;
        getDataConfig(entId)
            .then((res) => {
                console.log('data odoo', res.data.data);
                const data = res.data.data;
                setDataComercialUser(data.odooComercial);
                setDataPartnerDocumentType(data.partnerDocumentType);
                setDataTeam(data.odooTeam);
                setDataWareHouse(data.odooWarehouse);
                setDataStockLocation(data.OdooStockLocations);
                setDataShippingProduct(data.OdooShippingProduct);
                setDataShippingVariant(data.OdooShippingVariant);
                setDataTypeObligation(data.OdooTypeObligation);
                setDataPaymentTerms(data.paymentTerms);
                setDataSource(data.sources);
                setDataTags(data.partnerCategory)
                setPostConfigIdState(data.posConfigs)
            })
            .catch((err) => {
                console.log(err)
                handleAxiosError(
                    err,
                    'Lo sentimos presentamos un error con los usuarios comerciales'
                )
            });
    }, [globalContext.currentEnterpriseId]);

    /**
     * Load the plugin config
     */
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        setIsSending(true)
        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/odoo`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then(resp => {
            const data = resp.data.data.configData;
            setIsSending(false)
            console.log('la data', data);
            //Set the default config
            const defaultConf = {
                thumbnailField: data.thumbnailField || "image_medium",
                orderPriceList: data.orderPriceList || "",
                defaultPricelist: data.defaultPricelist || "",
                stockField: data.stockField || "virtual_available",
                stockModel: data.stockModel || "product",
                syncStock: data.syncStock || false,
                syncPrice: data.syncPrice || false,
                syncProductsWithoutCompany: data.syncProductsWithoutCompany || false,
                createSaleOrders: data.createSaleOrders || false,
                createUserWithSaleOrders: data.createUserWithSaleOrders || false,
                saleOrderUserId: data.saleOrderUserId || "",
                saleOrderMlFulfillmentWarehouseId: data.saleOrderMlFulfillmentWarehouseId || "",
                saleOrderMlFulfillmentUserId: data.saleOrderMlFulfillmentUserId || "",
                saleOrderMlShippingProductId: data.saleOrderMlShippingProductId || "",
                saleOrderMlShippingVariantId: data.saleOrderMlShippingVariantId || "",
                saleOrderMlSuperExpressShippingUserId: data.saleOrderMlSuperExpressShippingUserId || "",
                saleOrderMlSuperExpressShippingWarehouseId: data.saleOrderMlSuperExpressShippingWarehouseId || "",
                partnerMlUserIdField: data.partnerMlUserIdField || "",
                partnerMlObligationTypeIds: data.partnerMlObligationTypeIds || "",
                saleOrderMlTeamId: data.saleOrderMlTeamId || "",
                partnerMlPropertyProductPricelistId: data.partnerMlPropertyProductPricelistId || "",
                partnerTeamId: data.partnerTeamId || "",
                partnerUserId: data.partnerUserId || "",
                partnerDocumentType: data.partnerDocumentType || "",
                defaultStockLocationId: data.defaultStockLocationId || "",
                lang: data.lang || "",
                source: data.source || "",
                paymentTerms: data.paymentTerms || "",
                pricePricelistName: data.pricePricelistName || "",
                pricePosConfigId: data.pricePosConfigId || "",
                pricePosWooConfigId: data.pricePosWooConfigId || "",

                lnPriceListId: data.lnPriceListId || "",
                lnSaleOrderUserId: data.lnSaleOrderUserId || "",
                lnWarehouseId: data.lnWarehouseId || "",
                lnTeamId: data.lnTeamId || "",
                lnClientOrderRef: data.lnClientOrderRef || "",
                lnStock: data.lnStock || "",
                lnSaleOrder: data.lnSaleOrder || "",
                lnDocumentType: data.lnDocumentType || "",
                lnObligation: data.lnObligation || "",
                lnCarrier: data.lnCarrier || "",
                lnPayment: data.lnPayment || "",
                lnPolicy: data.lnPolicy || "",
                lnSource: data.lnSource || "",
                lnTag: data.lnTag || "",

                dfStock: data.dfStock || "",
                dfSaleOrder: data.dfSaleOrder || "",
                dfPriceListId: data.dfPriceListId || "",
                dfSaleOrderUserId: data.dfSaleOrderUserId || "",
                dfWarehouseId: data.dfWarehouseId || "",
                dfTeamId: data.dfTeamId || "",
                dfClientOrderRef: data.dfClientOrderRef || "",
                dfDocumentType: data.dfDocumentType || "",
                dfObligation: data.dfObligation || "",
                dfCarrier: data.dfCarrier || "",
                dfPayment: data.dfPayment || "",
                dfPolicy: data.dfPolicy || "",
                dfSource: data.dfSource || "",
                dfTag: data.dfTag || "",

                pyStock: data.pyStock || "",
                pySaleOrder: data.pySaleOrder || "",
                pyPriceListId: data.pyPriceListId || "",
                pySaleOrderUserId: data.pySaleOrderUserId || "",
                pyWarehouseId: data.pyWarehouseId || "",
                pyTeamId: data.pyTeamId || "",
                pyClientOrderRef: data.pyClientOrderRef || "",
                pyDocumentType: data.pyDocumentType || "",
                pyObligation: data.pyObligation || "",
                pyCarrier: data.pyCarrier || "",
                pyPayment: data.pyPayment || "",
                pyPolicy: data.pyPolicy || "",
                pySource: data.pySource || "",
                pyTag: data.pyTag || "",

                isScraping: data.isScraping || false
            };
            setDefaultConfig(defaultConf);

            //Set the actual config as a copy of the default
            setConfig(Object.assign({}, defaultConf));
        }).catch((err) =>
            {
                setIsSending(false)
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo cargar la configuracion actual."
                )
            }
        );
    }, [globalContext.currentEnterpriseId]);


    /**
     * Cancel axios requests before unmount
     */
    useEffect(() => {
        return () => {
            axiosCancelTokenSource.cancel("Canceled by unmount");
        };
        //eslint-disable-next-line
    }, []);

    return (
        <ConfigStyled.ContFormTwo>

            <ConfigStyled.ContSectionFomr>
                <ConfigStyled.TagSectionForm>
                    Odoo {<BiRadioCircleMarked />}
                </ConfigStyled.TagSectionForm>
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Campo que almacena los Thumbnails
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        placeholder="Nombre Campo"
                        name="thumbnailField"
                        value={config.thumbnailField || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Tarifa para los presupuestos
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={isSending}
                        name="orderPriceList"
                        value={config.orderPriceList || ""}
                        onChange={updateConfigState}
                    >
                        <option>
                            Seleccione...
                        </option>
                        {
                            priceLists.length > 0 && priceLists.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Lista de precio
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        name="defaultPricelist"
                        disabled={isSending}
                        value={config.defaultPricelist || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            priceLists.length > 0 && priceLists.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Modelo del cual tomar el stock de los productos
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={isSending}
                        name="stockModel"
                        value={config.stockModel || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione</option>
                        <option value="product">Producto</option>
                        <option value="stock.quant">Stock.Quant</option>
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Campo para llevar stock
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        placeholder="Escriba aqui"
                        name="stockField"
                        value={config.stockField || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Sincronizar stock
                    </ConfigStyled.text>
                    <ConfigStyled.inputChecbox>
                        <input
                            className="inp-cbx"
                            style={{ display: 'none' }}
                            id='cbx-sync-stock'
                            type="checkbox"
                            name="syncStock"
                            disabled={isSending}
                            checked={config.syncStock ? true : false}
                            onChange={updateConfigState}
                        />
                        <label className="cbx" htmlFor='cbx-sync-stock'>
                            <span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                    </ConfigStyled.inputChecbox>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Sincronizar precio
                    </ConfigStyled.text>
                    <ConfigStyled.inputChecbox>
                        <input
                            className="inp-cbx"
                            style={{ display: 'none' }}
                            id='cbx-sync-price'
                            type="checkbox"
                            name="syncPrice"
                            disabled={isSending}
                            checked={config.syncPrice ? true : false}
                            onChange={updateConfigState}
                        />
                        <label className="cbx" htmlFor='cbx-sync-price'>
                            <span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                    </ConfigStyled.inputChecbox>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Sincronizar producto sin empresa
                    </ConfigStyled.text>
                    <ConfigStyled.inputChecbox>
                        <input
                            className="inp-cbx"
                            style={{ display: 'none' }}
                            id='cbx-sync-product-havent-enteprice'
                            type="checkbox"
                            name="syncProductsWithoutCompany"
                            disabled={isSending}
                            checked={config.syncProductsWithoutCompany ? true : false}
                            onChange={updateConfigState}
                        />
                        <label className="cbx" htmlFor='cbx-sync-product-havent-enteprice'>
                            <span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                    </ConfigStyled.inputChecbox>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Crear orden al vender por medio de otro Plugin
                    </ConfigStyled.text>
                    <ConfigStyled.inputChecbox>
                        <input
                            className="inp-cbx"
                            style={{ display: 'none' }}
                            id='cbx-sync-oreden-another-plugin'
                            type="checkbox"
                            name="createSaleOrders"
                            disabled={isSending}
                            checked={config.createSaleOrders ? true : false}
                            onChange={updateConfigState}
                        />
                        <label className="cbx" htmlFor='cbx-sync-oreden-another-plugin'>
                            <span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                    </ConfigStyled.inputChecbox>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Registrar al cliente un usuario en odoo al crear la orden de venta
                    </ConfigStyled.text>
                    <ConfigStyled.inputChecbox>
                        <input
                            className="inp-cbx"
                            style={{ display: 'none' }}
                            id='cbx-sync-orden-new-user-odoo'
                            type="checkbox"
                            disabled={isSending}
                            checked={config.createUserWithSaleOrders ? true : false}
                            onChange={updateConfigState}
                        />
                        <label className="cbx" htmlFor='cbx-sync-orden-new-user-odoo'>
                            <span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                    </ConfigStyled.inputChecbox>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        ID de usuario para el campo "Comercial" del presupuesto
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataComercialUser.length === 0}
                        name="saleOrderUserId"
                        value={config.saleOrderUserId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataComercialUser.length > 0 &&
                            dataComercialUser.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Lista de precio para descargar los productos
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={priceLists.length === 0}
                        name="pricePricelistName"
                        value={config.pricePricelistName || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una Lista de precio</option>
                        {
                            priceLists.length > 0 && priceLists.map(p => {
                                return <option value={p.name} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Id para la configuracion del precio
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={postConfigIdState.length === 0}
                        name="pricePosConfigId"
                        value={config.pricePosConfigId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione un id de configuracion</option>
                        {
                            postConfigIdState.length > 0 && postConfigIdState.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Id para la configuracion del precio Pagina Web
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={postConfigIdState.length === 0}
                        name="pricePosWooConfigId"
                        value={config.pricePosWooConfigId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione un id de configuracion</option>
                        {
                            postConfigIdState.length > 0 && postConfigIdState.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

            </ConfigStyled.ContSectionFomr>

            <ConfigStyled.ContSectionFomr>
                <ConfigStyled.TagSectionForm>
                    Mercado Libre {<BiRadioCircleMarked />}
                </ConfigStyled.TagSectionForm>
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        ID para el campo "Equipo de ventas" del presupuesto para compras realizadas por Mercadolibre
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataTeam.length === 0}
                        name="saleOrderMlTeamId"
                        value={config.saleOrderMlTeamId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataTeam.length > 0 &&
                            dataTeam.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Campo para guardar el ID del cliente de Mercadolibre
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        disabled={isSending}
                        placeholder="Coloque el nombre o modelo del campo"
                        name="partnerMlUserIdField"
                        value={config.partnerMlUserIdField || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        ID del almacen para compras de Mercadolibre de tipo Fulfillment
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataWareHose.length === 0}
                        name="saleOrderMlFulfillmentWarehouseId"
                        value={config.saleOrderMlFulfillmentWarehouseId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione uno</option>
                        {
                            dataWareHose.length > 0 &&
                            dataWareHose.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        ID del usuario "Comercial" para compras de Mercadolibre de tipo Fulfillment
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataComercialUser.length === 0}
                        name="saleOrderMlFulfillmentUserId"
                        value={config.saleOrderMlFulfillmentUserId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataComercialUser.length > 0 &&
                            dataComercialUser.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        ID del producto a utilizar para el item "Costo de envio de ML" en el presupuesto
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataShippingProduct.length === 0}
                        name="saleOrderMlShippingProductId"
                        value={config.saleOrderMlShippingProductId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione uno</option>
                        {
                            dataShippingProduct.length > 0 &&
                            dataShippingProduct.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        ID de la variante a utilizar para el item "Costo de envio de ML" en el presupuesto
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataShippingVariant.length === 0}
                        name="saleOrderMlShippingVariantId"
                        value={config.saleOrderMlShippingVariantId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione uno</option>
                        {
                            dataShippingVariant.length > 0 &&
                            dataShippingVariant.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        ID del almacen para compras de Mercadolibre con envio Super Express
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataWareHose.length === 0}
                        name="saleOrderMlSuperExpressShippingWarehouseId"
                        value={config.saleOrderMlSuperExpressShippingWarehouseId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione uno</option>
                        {
                            dataWareHose.length > 0 &&
                            dataWareHose.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        ID del usuario "Comercial" para compras de Mercadolibre con envio Super Express
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataComercialUser.length === 0}
                        name="saleOrderMlSuperExpressShippingUserId"
                        value={config.saleOrderMlSuperExpressShippingUserId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataComercialUser.length > 0 &&
                            dataComercialUser.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        ID del Campo de Obligacion del Cliente para compras realizadas por Mercadolibre
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataTypeObligation === 0}
                        name="partnerMlObligationTypeIds"
                        value={config.partnerMlObligationTypeIds || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione uno</option>
                        {
                            dataTypeObligation.length > 0 &&
                            dataTypeObligation.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Tarifa del Cliente para compras por Mercadolibre
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={priceLists.length === 0}
                        name="partnerMlPropertyProductPricelistId"
                        value={config.partnerMlPropertyProductPricelistId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            priceLists.length > 0 && priceLists.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        ID de usuario para el campo "Comercial" del cliente
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataComercialUser.length === 0}
                        name="partnerUserId"
                        value={config.partnerUserId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataComercialUser.length > 0 &&
                            dataComercialUser.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        ID para el campo "Equipo de ventas" del cliente
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataTeam.length === 0}
                        name="partnerTeamId"
                        value={config.partnerTeamId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataTeam.length > 0 &&
                            dataTeam.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Tipo de documento para los clientes
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        name="partnerDocumentType"
                        disabled={isSending}
                        value={config.partnerDocumentType || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataPartnerDocumentType.length > 0 &&
                            dataPartnerDocumentType.map(p => {
                                return <option value={p.id} key={p.id}>{`${p.name} - ${!p.country ? '' :  p.country[2]}`}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        ID de localizacion en el almacen por default
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataStockLocation.length === 0}
                        name="defaultStockLocationId"
                        value={config.defaultStockLocationId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione uno</option>
                        {
                            dataStockLocation.length > 0 &&
                            dataStockLocation.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Idioma a utilizar
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        placeholder="Escriba aqui"
                        name="lang"
                        value={config.lang || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Fuentes
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataSource.length === 0}
                        name="source"
                        value={config.source || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataSource.length > 0 && dataSource.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Plazos de pago
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataPaymentTerms.length === 0}
                        name="paymentTerms"
                        value={config.paymentTerms || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataPaymentTerms.length > 0 && dataPaymentTerms.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>
            </ConfigStyled.ContSectionFomr>

            <ConfigStyled.ContSectionFomr>
                <ConfigStyled.TagSectionForm>
                    Linio {<BiRadioCircleMarked />}
                </ConfigStyled.TagSectionForm>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Etiquetas
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataTags.length === 0}
                        name="lnTag"
                        value={config.lnTag || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataTags.length > 0 && dataTags.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Id de la lista de precio Linio
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={priceLists.length === 0}
                        name="lnPriceListId"
                        value={config.lnPriceListId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            priceLists.length > 0 && priceLists.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        ID de usuario de orden de venta Linio
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataComercialUser.length === 0}
                        name="lnSaleOrderUserId"
                        value={config.lnSaleOrderUserId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataComercialUser.length > 0 && dataComercialUser.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Id del alamcen de Linio
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataWareHose.length === 0}
                        name="lnWarehouseId"
                        value={config.lnWarehouseId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataWareHose.length > 0 && dataWareHose.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Id del Team Linio
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataTeam.length === 0}
                        name="lnTeamId"
                        value={config.lnTeamId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataTeam.length > 0 && dataTeam.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Referencia de orden para el cliente en Linio
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        disabled={isSending}
                        name="lnClientOrderRef"
                        value={config.lnClientOrderRef || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Stock Linio
                    </ConfigStyled.text>
                    <ConfigStyled.inputChecbox>
                        <input
                            className="inp-cbx"
                            style={{ display: 'none' }}
                            id='cbx-stock-ln'
                            type="checkbox"
                            name="lnStock"
                            disabled={isSending}
                            checked={config.lnStock ? true : false}
                            onChange={updateConfigState}
                        />
                        <label className="cbx" htmlFor='cbx-stock-ln'>
                            <span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                    </ConfigStyled.inputChecbox>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Orden de venta Linio
                    </ConfigStyled.text>
                    <ConfigStyled.inputChecbox>
                        <input
                            className="inp-cbx"
                            style={{ display: 'none' }}
                            id='cbx-lnSaleOrder'
                            type="checkbox"
                            name="lnSaleOrder"
                            disabled={isSending}
                            checked={config.lnSaleOrder ? true : false}
                            onChange={updateConfigState}
                        />
                        <label className="cbx" htmlFor='cbx-lnSaleOrder'>
                            <span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                    </ConfigStyled.inputChecbox>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Tipo de documento para los clientes
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={isSending}
                        name="lnDocumentType"
                        value={config.lnDocumentType || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataPartnerDocumentType.length > 0 &&
                            dataPartnerDocumentType.map(p => {
                                return <option value={p.id} key={p.id}>{`${p.name} - ${!p.country ? '' :  p.country[2]}`}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Obligaciones y responsabilidades para Linio
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataTypeObligation.length === 0}
                        name="lnObligation"
                        value={config.lnObligation || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataTypeObligation.length > 0 && dataTypeObligation.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Metodo de envio para Linio
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        disabled={isSending}
                        name="lnCarrier"
                        value={config.lnCarrier || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Plazos de pago para Linio
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataPaymentTerms.length === 0}
                        name="lnPayment"
                        value={config.lnPayment || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataPaymentTerms.length > 0 && dataPaymentTerms.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Politica de envio para Linio
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        disabled={isSending}
                        name="lnPolicy"
                        value={config.lnPolicy || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Fuentes para Linio
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataSource.length === 0}
                        name="lnSource"
                        value={config.lnSource || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataSource.length > 0 && dataSource.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>
            </ConfigStyled.ContSectionFomr>

            <ConfigStyled.ContSectionFomr>
                <ConfigStyled.TagSectionForm>
                    Dafiti {<BiRadioCircleMarked />}
                </ConfigStyled.TagSectionForm>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Etiquetas
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataTags.length === 0}
                        name="dfTag"
                        value={config.dfTag || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataTags.length > 0 && dataTags.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Id de la lista de precio Dafiti
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={priceLists.length === 0}
                        name="dfPriceListId"
                        value={config.dfPriceListId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            priceLists.length > 0 && priceLists.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        ID de usuario de orden de venta Dafiti
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataComercialUser.length === 0}
                        name="dfSaleOrderUserId"
                        value={config.dfSaleOrderUserId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataComercialUser.length > 0 && dataComercialUser.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Id del alamcen de Dafiti
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataWareHose.length === 0}
                        name="dfWarehouseId"
                        value={config.dfWarehouseId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataWareHose.length > 0 && dataWareHose.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Id del Team Dafiti
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataTeam.length === 0}
                        name="dfTeamId"
                        value={config.dfTeamId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataTeam.length > 0 && dataTeam.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Referencia de orden para el cliente en Dafiti
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        disabled={isSending}
                        name="dfClientOrderRef"
                        value={config.dfClientOrderRef || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Stock Dafiti
                    </ConfigStyled.text>
                    <ConfigStyled.inputChecbox>
                        <input
                            className="inp-cbx"
                            style={{ display: 'none' }}
                            id='cbx-stock-df'
                            type="checkbox"
                            name="dfStock"
                            disabled={isSending}
                            checked={config.dfStock ? true : false}
                            onChange={updateConfigState}
                        />
                        <label className="cbx" htmlFor='cbx-stock-df'>
                            <span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                    </ConfigStyled.inputChecbox>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Orden de venta Dafiti
                    </ConfigStyled.text>
                    <ConfigStyled.inputChecbox>
                        <input
                            className="inp-cbx"
                            style={{ display: 'none' }}
                            id='cbx-dfSaleOrder'
                            type="checkbox"
                            name="dfSaleOrder"
                            disabled={isSending}
                            checked={config.dfSaleOrder ? true : false}
                            onChange={updateConfigState}
                        />
                        <label className="cbx" htmlFor='cbx-dfSaleOrder'>
                            <span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                    </ConfigStyled.inputChecbox>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Tipo de documento para los clientes Dafiti
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={isSending}
                        name="dfDocumentType"
                        value={config.dfDocumentType || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataPartnerDocumentType.length > 0 &&
                            dataPartnerDocumentType.map(p => {
                                return <option value={p.id} key={p.id}>{`${p.name} - ${!p.country ? '' :  p.country[2]}`}</option>;
                            })
                        }

                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Obligaciones y responsabilidades para Dafiti
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataTypeObligation.length === 0}
                        name="dfObligation"
                        value={config.dfObligation || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataTypeObligation.length > 0 && dataTypeObligation.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Metodo de envio para Dafiti
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        disabled={isSending}
                        name="dfCarrier"
                        value={config.dfCarrier || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Plazos de pago para Dafiti
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataPaymentTerms.length === 0}
                        name="dfPayment"
                        value={config.dfPayment || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataPaymentTerms.length > 0 && dataPaymentTerms.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Politica de envio para Dafiti
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        disabled={isSending}
                        name="dfPolicy"
                        value={config.dfPolicy || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Fuentes para Dafiti
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataSource.length === 0}
                        name="dfSource"
                        value={config.dfSource || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataSource.length > 0 && dataSource.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>
            </ConfigStyled.ContSectionFomr>

            <ConfigStyled.ContSectionFomr>
                <ConfigStyled.TagSectionForm>
                    Pedisos Ya {<BiRadioCircleMarked />}
                </ConfigStyled.TagSectionForm>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Etiquetas Pedidos Ya
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataTags.length === 0}
                        name="pyTag"
                        value={config.pyTag || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataTags.length > 0 && dataTags.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Id de la lista de precio Pedidos Ya
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={priceLists.length === 0}
                        name="pyPriceListId"
                        value={config.pyPriceListId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            priceLists.length > 0 && priceLists.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        ID de usuario de orden de venta Pedidos Ya
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataComercialUser.length === 0}
                        name="pySaleOrderUserId"
                        value={config.pySaleOrderUserId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataComercialUser.length > 0 && dataComercialUser.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Id del alamcen de Pedidos Ya
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataWareHose.length === 0}
                        name="pyWarehouseId"
                        value={config.pyWarehouseId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataWareHose.length > 0 && dataWareHose.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Id del Team Pedidos Ya
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataTeam.length === 0}
                        name="pyTeamId"
                        value={config.pyTeamId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataTeam.length > 0 && dataTeam.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Referencia de orden para el cliente en Pedidos Ya
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        disabled={isSending}
                        name="pyClientOrderRef"
                        value={config.pyClientOrderRef || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Stock Pedidos Ya
                    </ConfigStyled.text>
                    <ConfigStyled.inputChecbox>
                        <input
                            className="inp-cbx"
                            style={{ display: 'none' }}
                            id='cbx-stock-py'
                            type="checkbox"
                            name="pyStock"
                            disabled={isSending}
                            checked={config.pyStock ? true : false}
                            onChange={updateConfigState}
                        />
                        <label className="cbx" htmlFor='cbx-stock-py'>
                            <span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                    </ConfigStyled.inputChecbox>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Orden de venta Pedidos Ya
                    </ConfigStyled.text>
                    <ConfigStyled.inputChecbox>
                        <input
                            className="inp-cbx"
                            style={{ display: 'none' }}
                            id='cbx-pySaleOrder'
                            type="checkbox"
                            name="pySaleOrder"
                            disabled={isSending}
                            checked={config.pySaleOrder ? true : false}
                            onChange={updateConfigState}
                        />
                        <label className="cbx" htmlFor='cbx-pySaleOrder'>
                            <span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                    </ConfigStyled.inputChecbox>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Tipo de documento para los clientes Pedidos Ya
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={isSending}
                        name="pyDocumentType"
                        value={config.pyDocumentType || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataPartnerDocumentType.length > 0 &&
                            dataPartnerDocumentType.map(p => {
                                return <option value={p.id} key={p.id}>{`${p.name} - ${!p.country ? '' :  p.country[2]}`}</option>;
                            })
                        }

                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Obligaciones y responsabilidades para Pedidos Ya
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataTypeObligation.length === 0}
                        name="pyObligation"
                        value={config.pyObligation || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataTypeObligation.length > 0 && dataTypeObligation.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Metodo de envio para Pedidos Ya
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        disabled={isSending}
                        name="pyCarrier"
                        value={config.pyCarrier || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Plazos de pago para Pedidos Ya
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataPaymentTerms.length === 0}
                        name="pyPayment"
                        value={config.pyPayment || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataPaymentTerms.length > 0 && dataPaymentTerms.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Politica de envio para Pedidos Ya
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        disabled={isSending}
                        name="pyPolicy"
                        value={config.pyPolicy || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Fuentes para Pedidos Ya
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataSource.length === 0}
                        name="pySource"
                        value={config.pySource || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataSource.length > 0 && dataSource.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>
            </ConfigStyled.ContSectionFomr>


            <ConfigStyled.ContSectionFomr>
                <ConfigStyled.TagSectionForm>
                    Anican {<BiRadioCircleMarked />}
                </ConfigStyled.TagSectionForm>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Etiquetas Anican
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataTags.length === 0}
                        name="anicanTag"
                        value={config.anicanTag || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataTags.length > 0 && dataTags.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Id de la lista de precio Anican
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={priceLists.length === 0}
                        name="anicanPriceListId"
                        value={config.anicanPriceListId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            priceLists.length > 0 && priceLists.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        ID de usuario de orden de venta Anican
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataComercialUser.length === 0}
                        name="anicanSaleOrderUserId"
                        value={config.anicanSaleOrderUserId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataComercialUser.length > 0 && dataComercialUser.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Id del alamcen de Anican
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataWareHose.length === 0}
                        name="anicanWarehouseId"
                        value={config.anicanWarehouseId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataWareHose.length > 0 && dataWareHose.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Id del Team Anican
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataTeam.length === 0}
                        name="anicanTeamId"
                        value={config.anicanTeamId || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataTeam.length > 0 && dataTeam.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Referencia de orden para el cliente en Anican
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        disabled={isSending}
                        name="anicanClientOrderRef"
                        value={config.anicanClientOrderRef || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Stock Anican
                    </ConfigStyled.text>
                    <ConfigStyled.inputChecbox>
                        <input
                            className="inp-cbx"
                            style={{ display: 'none' }}
                            id='cbx-stock-anican'
                            type="checkbox"
                            name="anicanStock"
                            disabled={isSending}
                            checked={config.anicanStock ? true : false}
                            onChange={updateConfigState}
                        />
                        <label className="cbx" htmlFor='cbx-stock-anican'>
                            <span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                    </ConfigStyled.inputChecbox>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Orden de venta Anican
                    </ConfigStyled.text>
                    <ConfigStyled.inputChecbox>
                        <input
                            className="inp-cbx"
                            style={{ display: 'none' }}
                            id='cbx-anicanSaleOrder'
                            type="checkbox"
                            name="anicanSaleOrder"
                            disabled={isSending}
                            checked={config.anicanSaleOrder ? true : false}
                            onChange={updateConfigState}
                        />
                        <label className="cbx" htmlFor='cbx-anicanSaleOrder'>
                            <span>
                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                        </label>
                    </ConfigStyled.inputChecbox>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Tipo de documento para los clientes Anican
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={isSending}
                        name="anicanDocumentType"
                        value={config.anicanDocumentType || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataPartnerDocumentType.length > 0 &&
                            dataPartnerDocumentType.map(p => {
                                return <option value={p.id} key={p.id}>{`${p.name} - ${!p.country ? '' :  p.country[2]}`}</option>;
                            })
                        }

                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Obligaciones y responsabilidades para Anican
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataTypeObligation.length === 0}
                        name="anicanObligation"
                        value={config.anicanObligation || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataTypeObligation.length > 0 && dataTypeObligation.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Metodo de envio para Anican
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        disabled={isSending}
                        name="anicanCarrier"
                        value={config.anicanCarrier || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Plazos de pago para Anican
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataPaymentTerms.length === 0}
                        name="anicanPayment"
                        value={config.anicanPayment || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataPaymentTerms.length > 0 && dataPaymentTerms.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Politica de envio para Anican
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        disabled={isSending}
                        name="anicanPolicy"
                        value={config.anicanPolicy || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                        Fuentes para Anican
                    </ConfigStyled.text>
                    <ConfigStyled.select
                        disabled={dataSource.length === 0}
                        name="anicanSource"
                        value={config.anicanSource || ""}
                        onChange={updateConfigState}
                    >
                        <option value="">Seleccione una</option>
                        {
                            dataSource.length > 0 && dataSource.map(p => {
                                return <option value={p.id} key={p.id}>{p.name}</option>;
                            })
                        }
                    </ConfigStyled.select>
                </ConfigStyled.ContInputAndLabel>
            </ConfigStyled.ContSectionFomr>

            <ConfigStyled.ARow >
                <ConfigStyled.btnSend disabled={isSending} onClick={saveChanges}>
                    Guardar
                </ConfigStyled.btnSend>
            </ConfigStyled.ARow>
        </ConfigStyled.ContFormTwo>
    )
}
