import React from 'react'
import PublishProduct from "../../../../../assets/css/components/admin/products/amazon/ModalPublishProduct";
import { Spinner } from 'react-bootstrap';
export default function ParametrosPublicacion(props) {
    
    const { template, setTemplate, setTienda, setTiendaText, tienda, positionItemActive, anotherIsLoad, stores, howToPost, setHowToPost, setListingType, listingType, setListingTypeText} = props;

    const functSetTienda = (v) => {
        setTienda(v)
    }
    const functSetTiendaText = (v) => {
        setTiendaText(v)
    }
    const funcSetHowToPost = (v) => {
        setHowToPost(v)
    }
    const setvalueTemplate = (v) => {
        setTemplate(v);
    }
    const funcSetListingType = (v) => {
        setListingType(v);
    }
    const funcSetListingTypeText = (v) => {
        setListingTypeText(v);
    }
    const handleTitulo = (v) => {
        const arrayCopy = [...template]
        arrayCopy[positionItemActive].titulo = v;
        setvalueTemplate(arrayCopy);
    }
    const handleDescripcion = (v) => {
        const arrayCopy = [...template]
        arrayCopy[positionItemActive].descripcion = v;
        setvalueTemplate(arrayCopy);
    }
    return (
        <PublishProduct.contForm>
            <div style={{width:'100%'}}>
                <div className='text-left'>
                    <PublishProduct.textBody mb='5px'  fw='bolder' >
                        Titulo de la publicacion
                    </PublishProduct.textBody>
                    <PublishProduct.input wd='100%' onChange={(e) => handleTitulo(e.target.value)} value={template[positionItemActive].titulo} />
                </div>
            </div>
            <PublishProduct.contRowForm>
                <div className='text-left' style={{ width: '100%', padding: '0 10px' }}>
                    <PublishProduct.textBody mb='5px' fw='bolder' mt='5px' >
                        Tipo de Publicacion
                    </PublishProduct.textBody>
                    <PublishProduct.select 
                        value={listingType} 
                        onChange={(e) => { 
                            funcSetListingType(e.target.value); 
                            funcSetListingTypeText(e.target.options[e.target.selectedIndex].text) 
                            }} >
                        <option value="">Seleccionar</option>
                        <option value='gold_pro'>Premiun CO</option>
                        <option value='gold_premium'>Oro Premiun CO</option>
                        <option value='gold'>Oro CO</option>
                        <option value='gold_special'>Premiun VE</option>
                        
                    </PublishProduct.select>
                </div>
            </PublishProduct.contRowForm>
            <PublishProduct.contRowForm>
                <div className='text-left' style={{width:'50%', padding: '0 10px'}}>
                    <PublishProduct.textBody mb='5px'  fw='bolder' mt='5px' >
                        Tienda oficial
                    </PublishProduct.textBody>
                    <PublishProduct.select defaultValue={tienda} onChange={
                        (e) => {
                            functSetTienda(e.target.value); 
                            functSetTiendaText(e.target.options[e.target.selectedIndex].text);
                            }  
                        }>
                        <option value="">Seleccionar</option>
                        {   
                        stores &&
                            stores.map((s) => {
                                return (
                                    <option key={s.id} value={s.id}>{s.name}</option>
                                )
                            })
                        }
                    </PublishProduct.select>
                </div>
                <div className='text-left' style={{width:'50%', padding: '0 10px'}}>
                    <PublishProduct.textBody mb='5px'  fw='bolder' mt='5px' >
                        Estatus de la publicacion
                    </PublishProduct.textBody>
                    <PublishProduct.select value={howToPost} onChange={(e) => {funcSetHowToPost(e.target.value)}}>
                        <option value="">Seleccionar</option>
                        <option value='active'>Activa</option>
                        <option value='paused'>Pausada</option>
                    </PublishProduct.select>
            </div>
            </PublishProduct.contRowForm>
            {
                anotherIsLoad ? (
                    <PublishProduct.bodyModal>
                            <div className={`load-screen`}>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                        </PublishProduct.bodyModal>
                ) : (
                    <div className='text-left' styled={{ width: 100 + '%' }}>
                        <PublishProduct.textBody mb='5px'  fw='bolder' mt='5px' >
                            Descripcion
                            </PublishProduct.textBody>
                        <PublishProduct.textArea value={template.length > 0 && template[positionItemActive].descripcion} onChange={(e) => handleDescripcion(e.target.value)} />
                    </div>
                )
            }
        </PublishProduct.contForm>
    )
}
