import React from 'react'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle';
import { BiX } from 'react-icons/bi'
// import Select from '../../../../general/Select';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import { useState, useContext } from 'react';
import { sendAllProducts, updateDimensions, updateImages, updatePriceAndStock, sendAllProductVariants, updateAllProductVariants, sendOneProduct, linkOdooProd } from '../functions/productMethos';
import getOdooCat from '../functions/getCatodoo';
import getAttribOdoo from '../functions/getAttributes';
import sendCatOdoo from '../functions/sendCatOdoo';
import getAttribodoo from '../functions/getAttribOdoo';
import updateCatOdoo from '../functions/updateCatOdoo';
import sendOdooAttr from '../functions/sendOdooAttr';

export default function Productos() {
    const [showBadge, setShowBadge] = useState(false);
    const [colorError, setColorError] = useState(true);
    const [error, setError] = useState('');
    // const [fileproductsToOff, setFileProductsToOff] = useState(null);
    const [fileproductsToLink, setFileProductsToLink] = useState(null);
    const globalContext = useContext(GlobalContext);
    const [isLoad, setIsLoad] = useState(false);
    const [brandPriceAndStock, setBrandPriceAndStock] = useState('');
    const [brandImages, setBrandImages] = useState('');
    const [brandDescription, setBrandDescription] = useState('');
    const [brandToSendProd, setBrandToSendProd] = useState('');
    const [brandVariants, setBrandVariants] = useState('');
    const [idProd, setIdProd] = useState('');
    const [websiteId, setWebsiteId] = useState('');

    const closeBadge = (e) => {
        e.preventDefault();
        setShowBadge(false);
    }

    const sendAllProductsByEnt = () => {
        const entId = globalContext.currentEnterpriseId
        setIsLoad(true)
        if (!entId) {
            setColorError(true);
            setError(`Por favor seleccione una empresa`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }
        sendAllProducts(entId, brandToSendProd)
            .then(() => {
                setColorError(false);
                setError('EL proceso se ejecuta en segundo plano!');
                setShowBadge(true);
                setIsLoad(false);
            })
            .catch((err) => {
                setColorError(true);
                setError(`Algo salio mal ${err}`);
                setShowBadge(true);
                setIsLoad(false)
            })
    }

    const sendAllProductsVariantsByEnt = () => {
        const entId = globalContext.currentEnterpriseId
        setIsLoad(true)
        if (!entId) {
            setColorError(true);
            setError(`Por favor seleccione una empresa`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }
        sendAllProductVariants(entId, brandVariants)
            .then(() => {
                setColorError(false);
                setError('EL proceso se ejecuta en segundo plano!');
                setShowBadge(true);
                setIsLoad(false);
            })
            .catch((err) => {
                setColorError(true);
                setError(`Algo salio mal ${err}`);
                setShowBadge(true);
                setIsLoad(false)
            })
    }

    
    const updateAllProductsVariantsByEnt = () => {
        const entId = globalContext.currentEnterpriseId
        setIsLoad(true)
        if (!entId) {
            setColorError(true);
            setError(`Por favor seleccione una empresa`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }
        updateAllProductVariants(entId, brandVariants)
            .then(() => {
                setColorError(false);
                setError('EL proceso se ejecuta en segundo plano!');
                setShowBadge(true);
                setIsLoad(false);
            })
            .catch((err) => {
                setColorError(true);
                setError(`Algo salio mal ${err}`);
                setShowBadge(true);
                setIsLoad(false)
            })
    }

    const updateStockAndPrice = () => {
        const entId = globalContext.currentEnterpriseId
        setIsLoad(true)
        if (!entId) {
            setColorError(true);
            setError(`Por favor seleccione una empresa`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }
        updatePriceAndStock(entId, brandPriceAndStock)
            .then(() => {
                setColorError(false);
                setError('EL proceso se ejecuta en segundo plano!');
                setShowBadge(true);
                setIsLoad(false);
            })
            .catch((err) => {
                setColorError(true);
                setError(`Algo salio mal ${err}`);
                setShowBadge(true);
                setIsLoad(false)
            })
    }

    const updateAllImages = () => {
        const entId = globalContext.currentEnterpriseId
        setIsLoad(true)
        if (!entId) {
            setColorError(true);
            setError(`Por favor seleccione una empresa`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }
        updateImages(entId, brandImages)
            .then(() => {
                setColorError(false);
                setError('EL proceso se ejecuta en segundo plano!');
                setShowBadge(true);
                setIsLoad(false);
            })
            .catch((err) => {
                setColorError(true);
                setError(`Algo salio mal ${err}`);
                setShowBadge(true);
                setIsLoad(false)
            })
    }

    const updateDimensionsAndDescription = () => {
        const entId = globalContext.currentEnterpriseId
        setIsLoad(true)
        if (!entId) {
            setColorError(true);
            setError(`Por favor seleccione una empresa`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }
        updateDimensions(entId, brandDescription)
            .then(() => {
                setColorError(false);
                setError('EL proceso se ejecuta en segundo plano!');
                setShowBadge(true);
                setIsLoad(false);
            })
            .catch((err) => {
                setColorError(true);
                setError(`Algo salio mal ${err}`);
                setShowBadge(true);
                setIsLoad(false)
            })
    }

    const sendOneProd = () => {
        const entId = globalContext.currentEnterpriseId
        setIsLoad(true)
        if (!entId) {
            setColorError(true);
            setError(`Por favor seleccione una empresa`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }

        if (idProd.length === 0) {
            setColorError(true);
            setError(`Por favor debe colocar el id del producto`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }
        sendOneProduct(entId, idProd)
            .then(() => {
                setColorError(false);
                setError('EL proceso se ejecuta en segundo plano!');
                setShowBadge(true);
                setIsLoad(false);
            })
            .catch((err) => {
                setColorError(true);
                setError(`Algo salio mal ${err}`);
                setShowBadge(true);
                setIsLoad(false)
            })
    }

    const linkProducts = () => {
        const entId = globalContext.currentEnterpriseId;

        setIsLoad(true)
        if (!entId) {
            setColorError(true);
            setError(`Por favor seleccione una empresa`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }

        if (!fileproductsToLink) {
            setColorError(true);
            setError(`Por favor abjunte el archivo xlsx`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }

        linkOdooProd(entId, fileproductsToLink)
        .then(() => {
            setColorError(false);
            setError('EL proceso se ejecuta en segundo plano!');
            setShowBadge(true);
            setIsLoad(false);
        })
        .catch((err) => {
            setColorError(true);
            setError(`Algo salio mal ${err}`);
            setShowBadge(true);
            setIsLoad(false)
        })
    }

    const downloadOdooCat = () => {
        const entId = globalContext.currentEnterpriseId
        setIsLoad(true)
        if (!entId) {
            setColorError(true);
            setError(`Por favor seleccione una empresa`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }

        if (websiteId.length === 0) {
            setColorError(true);
            setError(`Por favor debe colocar el id del sitio web`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }
        getOdooCat(entId, websiteId)
            .then(() => {
                setColorError(false);
                setError('EL proceso se ejecuta en segundo plano!');
                setShowBadge(true);
                setIsLoad(false);
            })
            .catch((err) => {
                setColorError(true);
                setError(`Algo salio mal ${err}`);
                setShowBadge(true);
                setIsLoad(false)
            })
    }

    

    const sendCatOdooWoo = () => {
        const entId = globalContext.currentEnterpriseId
        setIsLoad(true)
        if (!entId) {
            setColorError(true);
            setError(`Por favor seleccione una empresa`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }

        sendCatOdoo(entId)
            .then(() => {
                setColorError(false);
                setError('EL proceso se ejecuta en segundo plano!');
                setShowBadge(true);
                setIsLoad(false);
            })
            .catch((err) => {
                setColorError(true);
                setError(`Algo salio mal ${err}`);
                setShowBadge(true);
                setIsLoad(false)
            })
    }

    const updateCatOdooWoo = () => {
        const entId = globalContext.currentEnterpriseId
        setIsLoad(true)
        if (!entId) {
            setColorError(true);
            setError(`Por favor seleccione una empresa`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }

        updateCatOdoo(entId)
            .then(() => {
                setColorError(false);
                setError('EL proceso se ejecuta en segundo plano!');
                setShowBadge(true);
                setIsLoad(false);
            })
            .catch((err) => {
                setColorError(true);
                setError(`Algo salio mal ${err}`);
                setShowBadge(true);
                setIsLoad(false)
            })
    }

    const downloadAttrib = () => {
        const entId = globalContext.currentEnterpriseId
        setIsLoad(true)
        if (!entId) {
            setColorError(true);
            setError(`Por favor seleccione una empresa`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }

        getAttribodoo(entId)
            .then(() => {
                setColorError(false);
                setError('EL proceso se ejecuta en segundo plano!');
                setShowBadge(true);
                setIsLoad(false);
            })
            .catch((err) => {
                setColorError(true);
                setError(`Algo salio mal ${err}`);
                setShowBadge(true);
                setIsLoad(false)
            })
    }
    const sendAttrib = () => {
        const entId = globalContext.currentEnterpriseId
        setIsLoad(true)
        if (!entId) {
            setColorError(true);
            setError(`Por favor seleccione una empresa`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }

        sendOdooAttr(entId)
            .then(() => {
                setColorError(false);
                setError('EL proceso se ejecuta en segundo plano!');
                setShowBadge(true);
                setIsLoad(false);
            })
            .catch((err) => {
                setColorError(true);
                setError(`Algo salio mal ${err}`);
                setShowBadge(true);
                setIsLoad(false)
            })
    }

    


    return (
        <>
            <PedidosYaStyle.ContBadgeError active={showBadge} error={colorError}>
                <PedidosYaStyle.Text size=".875rem" cl="#FFFFFF">
                    {error}
                </PedidosYaStyle.Text>
                <PedidosYaStyle.BtnToggleBadge onClick={(e) => closeBadge(e)}>
                    <BiX />
                </PedidosYaStyle.BtnToggleBadge>
            </PedidosYaStyle.ContBadgeError>
            <PedidosYaStyle.ContForm>
                <PedidosYaStyle.HeaderForm>
                    <PedidosYaStyle.Text size="14pt" fw="500" cl="#898989">
                        Acciones para los productos en la pagina web
                    </PedidosYaStyle.Text>
                </PedidosYaStyle.HeaderForm>
                <PedidosYaStyle.BodyForm>
                    <PedidosYaStyle.BodyFormGrid>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Descargar categorias de odoo
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input style={{marginBottom: 10}} placeholder='id sitio web' onChangeCapture={(e) => setWebsiteId(e.target.value)} />
                            <PedidosYaStyle.BtnSend onClick={() => downloadOdooCat()}>
                                {isLoad ? 'Esperando..' : 'Enviar'}
                            </PedidosYaStyle.BtnSend>
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Enviar categorias de odoo a woo
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.BtnSend onClick={() => sendCatOdooWoo()}>
                                {isLoad ? 'Esperando..' : 'Enviar'}
                            </PedidosYaStyle.BtnSend>
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Actualizar categorias de odoo a woo
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.BtnSend onClick={() => updateCatOdooWoo()}>
                                {isLoad ? 'Esperando..' : 'Enviar'}
                            </PedidosYaStyle.BtnSend>
                        </PedidosYaStyle.ContInputAndLabel>
                        
                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Descargar atributos de odoo
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.BtnSend onClick={() => downloadAttrib()}>
                                {isLoad ? 'Esperando..' : 'Enviar'}
                            </PedidosYaStyle.BtnSend>
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Enviar atributos de Odoo a Woo
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.BtnSend onClick={() => sendOdooAttr()}>
                                {isLoad ? 'Esperando..' : 'Enviar'}
                            </PedidosYaStyle.BtnSend>
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Enviar un producto
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input style={{marginBottom: 10}} placeholder='Id del producto' onChangeCapture={(e) => setIdProd(e.target.value)} />
                            <PedidosYaStyle.BtnSend onClick={() => sendOneProd()}>
                                {isLoad ? 'Esperando..' : 'Enviar'}
                            </PedidosYaStyle.BtnSend>
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Enviar todos los productos
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input style={{marginBottom: 10}} placeholder='Marca (opcional)' onChangeCapture={(e) => setBrandToSendProd(e.target.value)} />
                            <PedidosYaStyle.BtnSend onClick={() => sendAllProductsByEnt()}>
                                {isLoad ? 'Esperando..' : 'Enviar'}
                            </PedidosYaStyle.BtnSend>
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Actualizar el stock y precio de todos los productos
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input style={{marginBottom: 10}} placeholder='Marca (opcional)' onChangeCapture={(e) => setBrandPriceAndStock(e.target.value)} />
                            <PedidosYaStyle.BtnSend onClick={() => updateStockAndPrice()}>
                                {isLoad ? 'Esperando..' : 'Enviar'}
                            </PedidosYaStyle.BtnSend>
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Actualizar la descripcion y dimensiones de todos los productos
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input style={{marginBottom: 10}} placeholder='Marca (opcional)' onChangeCapture={(e) => setBrandDescription(e.target.value)} />
                            <PedidosYaStyle.BtnSend onClick={() => updateDimensionsAndDescription()}>
                                {isLoad ? 'Esperando..' : 'Enviar'}
                            </PedidosYaStyle.BtnSend>
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Actualizar las imagesnes de todos los productos
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input style={{marginBottom: 10}} placeholder='Marca (opcional)' onChangeCapture={(e) => setBrandImages(e.target.value)} />
                            <PedidosYaStyle.BtnSend onClick={() => updateAllImages()}>
                                {isLoad ? 'Esperando..' : 'Enviar'}
                            </PedidosYaStyle.BtnSend>
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Excell de productos a vincular "Solo una columna (slug, id, type, visible)"
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.inputFile>
                                <label className="file">
                                    <input type="file" aria-label="File browser example" onChange={(e) => setFileProductsToLink(e.target.files[0])} />
                                    <span className="file-custom"></span>
                                </label>
                            </PedidosYaStyle.inputFile>
                            {fileproductsToLink &&
                                (
                                    <PedidosYaStyle.Text size="12pt" fw="500" cl="#898989">
                                        {fileproductsToLink.name}
                                    </PedidosYaStyle.Text>
                                )
                            }
                            <PedidosYaStyle.BtnSend onClick={() => linkProducts()}>
                                {isLoad ? 'Esperando..' : 'Enviar'}
                            </PedidosYaStyle.BtnSend>
                        </PedidosYaStyle.ContInputAndLabel>
                        
                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Enviar todas las variantes
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input style={{marginBottom: 10}} placeholder='Marca (opcional)' onChangeCapture={(e) => setBrandVariants(e.target.value)} />
                            <PedidosYaStyle.BtnSend onClick={() => sendAllProductsVariantsByEnt()}>
                                {isLoad ? 'Esperando..' : 'Enviar'}
                            </PedidosYaStyle.BtnSend>
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Actualizar todas las variantes
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input style={{marginBottom: 10}} placeholder='Marca (opcional)' onChangeCapture={(e) => setBrandVariants(e.target.value)} />
                            <PedidosYaStyle.BtnSend onClick={() => updateAllProductsVariantsByEnt()}>
                                {isLoad ? 'Esperando..' : 'Enviar'}
                            </PedidosYaStyle.BtnSend>
                        </PedidosYaStyle.ContInputAndLabel>

                        

                    </PedidosYaStyle.BodyFormGrid>
                </PedidosYaStyle.BodyForm>
            </PedidosYaStyle.ContForm>
        </>
    )
}
