import React, { useState } from 'react'
import UpdateAndPublish from '../../../../assets/css/components/admin/update-and-publish/UpdateAndPublis';

export default function MenuCard(props) {
    const { showSubContent, dataCard, variants, setDefaultindex, mlBrandName, dataPrice, numberFormat } = props;

    const functSetDefaultindex = (v) => {
        setDefaultindex(v)
    }
    
    // funcion para crear el link de ml
    const productUrl = (str, countrie, coLink) => {
        let newstr = str.split('');
        const countrieValid = countrie.substr(0, 3);
        let urlML;
        const complemetoUrl = '-producto-sin-url-_JM#';
        if (countrieValid === 'MCO') {
            let newLink = coLink.split('');
            newLink.splice(4, 0, 's');
            newLink.push('#');
            return newLink.join('')
        } else {
            urlML = 'https://articulo.mercadolibre.com.ve/';
            newstr.splice(3, 0, '-');
            const subFinalStr = newstr.join('');
            return `${urlML}${subFinalStr}${complemetoUrl}`;
        }
    }

    const returnDetails = v => {
        if (v.details.productDetails) {
            return v.details.productDetails.map((r, idx) =>
            (
                <UpdateAndPublish.GeneralListItem key={idx}>
                    <UpdateAndPublish.Text>
                        {`# ${r.name.es || r.name.en}: ${r.value.es || r.value.en}`}
                    </UpdateAndPublish.Text>
                </UpdateAndPublish.GeneralListItem>
            )

            )
        }
        else if (v.details.additionalInfo) {
            if (v.details.additionalInfo.additionalInfo) {
                return v.details.additionalInfo.additionalInfo.map((r, idx) =>
                (
                    <UpdateAndPublish.GeneralListItem key={idx}>
                        <UpdateAndPublish.Text>
                            {`${idx++}-) ${r.name.es || r.name.en}: ${r.value.es || r.value.en}`}
                        </UpdateAndPublish.Text>
                    </UpdateAndPublish.GeneralListItem>
                )

                )
            }
            else if (v.details.additionalInfo.technicalDetails) {
                return v.details.additionalInfo.technicalDetails.map((r, idx) =>
                (
                    <UpdateAndPublish.GeneralListItem key={idx}>
                        <UpdateAndPublish.Text>
                            {`#${idx++} ${r.name.es || r.name.en}: ${r.value.es || r.value.en}`}
                        </UpdateAndPublish.Text>
                    </UpdateAndPublish.GeneralListItem>
                )

                )
            }
        }
    }


    const renderMarckeplace = () => {
        switch (showSubContent) {
            case 1:
                return (
                    <UpdateAndPublish.GeneralList>

                        <UpdateAndPublish.GeneralListItem>
                            <UpdateAndPublish.Text>
                                {`SKU: ${dataCard.asin}`}
                            </UpdateAndPublish.Text>
                        </UpdateAndPublish.GeneralListItem>

                        <UpdateAndPublish.GeneralListItem>
                            <UpdateAndPublish.TextLink longText={true} href={dataCard.url} target="_blank" rel="noopener noreferrer">
                                {`LINK: ${dataCard.url}`}
                            </UpdateAndPublish.TextLink>
                        </UpdateAndPublish.GeneralListItem>
                        {
                            dataCard.ml && (
                                <UpdateAndPublish.GeneralListItem>
                                    <UpdateAndPublish.TextLink
                                        longText={true}
                                        href={productUrl(dataCard.ml.attrib.id, dataCard.ml.attrib.category_id, dataCard.ml.attrib.permalink)}
                                        target="_blank" rel="noopener noreferrer">
                                        {
                                            `LINK ML: ${productUrl(dataCard.ml.attrib.id, dataCard.ml.attrib.category_id, dataCard.ml.attrib.permalink)}}`
                                        }
                                    </UpdateAndPublish.TextLink>
                                </UpdateAndPublish.GeneralListItem>
                            )
                        }

                        <UpdateAndPublish.GeneralListItem>
                            <UpdateAndPublish.Text>
                                {`STOCK: ${dataCard.stock}`}
                            </UpdateAndPublish.Text>
                        </UpdateAndPublish.GeneralListItem>

                        <UpdateAndPublish.GeneralListItem>
                            <UpdateAndPublish.Text>
                                {`STOCK ML: ${dataCard.ml && dataCard.ml.stock}`}
                            </UpdateAndPublish.Text>
                        </UpdateAndPublish.GeneralListItem>

                        <UpdateAndPublish.GeneralListItem>
                            <UpdateAndPublish.Text>
                                {`PESO: ${dataCard.weight}`}
                            </UpdateAndPublish.Text>
                        </UpdateAndPublish.GeneralListItem>

                        <UpdateAndPublish.GeneralListItem>
                            <UpdateAndPublish.Text>
                                {dataCard.dimensions && `DIMENSIONES: A ${dataCard.dimensions.height}/ L ${dataCard.dimensions.width}/ LONG ${dataCard.dimensions.length}`}
                            </UpdateAndPublish.Text>
                        </UpdateAndPublish.GeneralListItem>

                        <UpdateAndPublish.GeneralListItem>
                            <UpdateAndPublish.Text>
                                {dataCard.dimensions ?
                                    `P. VOLUMETRICO: ${(((parseFloat(dataCard.dimensions.height) *
                                        parseFloat(dataCard.dimensions.width) *
                                        parseFloat(dataCard.dimensions.length)) * 1.2) / 166).toString().slice(0, 5)}
                                    (LBS) `
                                    : 'No disponble'}
                            </UpdateAndPublish.Text>
                        </UpdateAndPublish.GeneralListItem>

                        <UpdateAndPublish.GeneralListItem>
                            <UpdateAndPublish.Text>
                                T. OFICIAL: {mlBrandName}
                            </UpdateAndPublish.Text>
                        </UpdateAndPublish.GeneralListItem>

                        <UpdateAndPublish.GeneralListItem>
                            <UpdateAndPublish.Text>
                                {`ESTATUS EN ML: ${dataCard.ml && dataCard.ml.attrib.status}`}
                            </UpdateAndPublish.Text>
                        </UpdateAndPublish.GeneralListItem>

                        <UpdateAndPublish.GeneralListItem>
                            <UpdateAndPublish.Text>
                                {`CANT. VENTAS EN ML: ${dataCard.ml && dataCard.ml.timesSold}`}
                            </UpdateAndPublish.Text>
                        </UpdateAndPublish.GeneralListItem>

                    </UpdateAndPublish.GeneralList>
                )
            case 2:
                return (
                    <UpdateAndPublish.GeneralList>

                        <UpdateAndPublish.GeneralListItem>
                            <UpdateAndPublish.Text>
                                {`USD PROVEEDOR: ${dataCard ? `$${dataCard.price}` : 'No Posee'}`}
                            </UpdateAndPublish.Text>
                        </UpdateAndPublish.GeneralListItem>

                        <UpdateAndPublish.GeneralListItem>
                            <UpdateAndPublish.Text>
                                {`P. ENVIO PROVEEDOR: ${dataCard ? `$${dataCard.shippingPrice}` : 'No Posee'}`}
                            </UpdateAndPublish.Text>
                        </UpdateAndPublish.GeneralListItem>

                        <UpdateAndPublish.GeneralListItem>
                            <UpdateAndPublish.Text>
                                {`PRECIO EN (USD): 
                                    ${!dataPrice ? 'Calculando...' : `$${numberFormat.format(Math.ceil(dataPrice.totalUsd))}`}`}
                            </UpdateAndPublish.Text>
                        </UpdateAndPublish.GeneralListItem>

                        <UpdateAndPublish.GeneralListItem>
                            <UpdateAndPublish.Text>
                                {`PRECIO EN (COL/BSF): 
                                    ${!dataPrice ? 'Calculando...' : numberFormat.format(Math.ceil(dataPrice.total))}`}
                            </UpdateAndPublish.Text>
                        </UpdateAndPublish.GeneralListItem>

                        <UpdateAndPublish.GeneralListItem>
                            <UpdateAndPublish.Text>
                                {`PRECIO EN ML: ${!dataCard.ml ? 'No Posee' : numberFormat.format(Math.ceil(dataCard.ml.price))}`}
                            </UpdateAndPublish.Text>
                        </UpdateAndPublish.GeneralListItem>

                        <UpdateAndPublish.GeneralListItem>
                            <UpdateAndPublish.Text>
                                {`MONTO TOTAL DE LA GANANCIA: 
                                ${!dataPrice ? 'Calculando...' : `${numberFormat.format(dataPrice.earningUsd)}`}`}
                            </UpdateAndPublish.Text>
                        </UpdateAndPublish.GeneralListItem>

                    </UpdateAndPublish.GeneralList>
                )
            case 3:
                return (
                    <UpdateAndPublish.GeneralList>
                        {
                            dataCard.features &&
                            dataCard.features.map((f, idx) =>
                                <UpdateAndPublish.GeneralListItem key={idx}>
                                    <UpdateAndPublish.Text>
                                        {`# ${f.es || f.en}`}
                                    </UpdateAndPublish.Text>

                                </UpdateAndPublish.GeneralListItem>
                            )
                        }

                    </UpdateAndPublish.GeneralList>
                )
            case 4:
                return (
                    <UpdateAndPublish.GeneralList>
                        {
                            returnDetails(dataCard)
                        }

                    </UpdateAndPublish.GeneralList>
                )
            case 5:
                return (
                    <UpdateAndPublish.ContGridVariantsCard>
                        {
                            (variants.length > 0) &&
                            variants.map((v, i) => {
                                return (
                                    <UpdateAndPublish.contImgVariantsCard
                                        key={`${v.asin}-${i}`}
                                        url={v.images[0]}
                                        onClick={() => functSetDefaultindex(i)}
                                    />
                                )
                            })
                        }
                    </UpdateAndPublish.ContGridVariantsCard>
                )
            default:
                return (
                    <UpdateAndPublish.GeneralList>
                        <UpdateAndPublish.GeneralListItem>
                            cosas de los precio
                        </UpdateAndPublish.GeneralListItem>
                    </UpdateAndPublish.GeneralList>
                );
        }
    }
    return renderMarckeplace()
}
