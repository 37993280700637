import SendData from "./SendData";

const saveMany = async (arrayHash, entId) => {
    if(arrayHash.length) {
        console.log('se envia', arrayHash);
        let hashSaved = 0;
        let error = [];
        for (const hash of arrayHash) {
            await new Promise(async(reject) =>{
                await SendData(entId, hash)
                .then(() => {
                    hashSaved = hashSaved +1
                    reject('')
                }).catch(err => {
                    error.push(err.message)
                    reject('')
                })
            })
        }
        return {
            hashSaved,
            error
        }
    }else {
        return false;
    }
}


export default saveMany;