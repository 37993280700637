import React, { useState } from "react";
import { Form, Col, Row, InputGroup, ProgressBar } from "react-bootstrap";
import ModalMsm from "../../../general/ModalMsm";
import {
  keyUserId,
  keyAccessToken,
  keyUserActiveEnterpriseId,
} from "../../../../assets/js/SessionStorageNames";

export default function StepOne(props) {
  const axios = require("axios");
  const { nextStep } = props;
  let nextStepTwo = (e) => {
    e.preventDefault();
    nextStep();
  };
  //const { nextStep } = props;
  const [usuario, setUsuario] = useState("");
  const [bd, setbd] = useState("");
  const [clave, setClave] = useState("");
  const [puerto, setPuerto] = useState("");
  const [url, setUrl] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const handleClose = () => setShow(false);
  //let nextStepOne = (e) => {
  //e.preventDefault();
  //nextStep();
  //};
  const submitHandler = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/odooConnect`,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
      data: {
        usuario: usuario,
        clave: clave,
        basedatos: bd,
        url: url,
        puerto: puerto,
        company: "74",
        enterpriseId: localStorage.getItem(keyUserActiveEnterpriseId),
      },
    })
      .then((res) => {
        setError(res.data.message);
        setShow(true);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setShow(true);
      });
  };
  return (
    <div className="StepOne">
      <ModalMsm
        show={show}
        error={error}
        handleClose={handleClose}
        txtButton="Cerrar"
      />
      <Row>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="mb-3 progressBar"
        >
          <ProgressBar animated now={33.3} label="Credenciales de Odoo" />
        </Col>
      </Row>
      <Form onSubmit={submitHandler}>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Form.Group>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Usuario"
                  id="formContacInput-three"
                  name="usuario"
                  onChange={(e) => setUsuario(e.target.value)}
                ></Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Form.Group>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Base de Datos"
                  id="formContacInput-three"
                  name="bd"
                  onChange={(e) => setbd(e.target.value)}
                ></Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Form.Group>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Clave de acceso"
                  type="password"
                  id="formContacInput-three"
                  name="clave"
                  onChange={(e) => setClave(e.target.value)}
                ></Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Form.Group>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Puerto"
                  id="formContacInput-three"
                  name="puerto"
                  onChange={(e) => setPuerto(e.target.value)}
                ></Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Form.Group>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Url"
                  id="formContacInput-three"
                  name="url"
                  onChange={(e) => setUrl(e.target.value)}
                ></Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <button id="save" type="submit">
              Guardar
            </button>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <button id="btnNext" onClick={nextStepTwo}>
              Siguiente
            </button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
