import React, { useContext, useEffect, useState } from "react";
import AdminStyle from "../assets/css/layouts/AdminStyle.css";
import {
  //BrowserRouter as Router,

  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import PageContent from "../components/admin/menus/PageContent";
import ModalMsm from "../components/general/ModalMsm";
import { GlobalContext } from "../contexts/GlobalContext";
import LoaderSpinner from "../components/general/LoaderSpinner";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Routes from "../Routes";
import ModalPublishProduct from "../components/admin/productos/amazon/ModalPublishProduct";
import MultiplePost from "../components/admin/productos/amazon/MultiplePost";
import AnotherMenu from "../components/admin/menus/AnotherMenu";
//import ModalLinkCategories from "../components/admin/categories/ModalLinkCategories";
//import ModalTranslateCategories from "../components/admin/categories/ModalTranslateCategories";
import SelectCategory from "../components/admin/productos/mercado-libre/rangos-publicacion/rangos-step/SelectCategory";
import DefaultimgPost from "../components/admin/productos/amazon/DefaultimgPost";
import InfoIcons from "../components/admin/productos/amazon/InfoIcons";
import ModalTracking from "../components/admin/productos/orders/components/ModalTracking";
import ModalLinkCity from "../components/admin/city/ModalLinkCity";
import ListProcess from "../components/admin/offline-process/views/ListProcess";
import ModalPublisInAnotherStore from "../components/admin/productos/product/ModalPublisInAnotherStore";
import VerifyLogin from "../components/general/VerifyLogin";
import ModalAddProductFtp from "../components/admin/productos/product/components/ModalAddProductFtp";
import ModalSelectStoreAndSection from "../components/admin/productos/public-products-m-to-m/components/py/ModalSelectStoreAndSection";
import ModalLinkProduct from "../components/admin/productos/public-products-m-to-m/components/py/ModalLinkProduct";
import ModalPostProduct from "../components/admin/productos/public-products-m-to-m/components/ModalPostProduct"
import FiltersGrid from "../components/admin/productos/orders/components/FiltersGrid";
import ReportPostproductsByUser from "../components/admin/reports/amazon/ReportPostproductsByUser";
import ModalOtherMarketplace from "../components/admin/productos/amazon/modal-other-marketplace/ModalOtherMarketplace";
import ModalTestFilter from "../components/admin/filters/views/ModalTestFilter";
require("../assets/js/axiosInterceptor");

const switchRoutes = (
  <Switch>
    {
      Routes.map((val, key) => {
        if (val.layout === "/admin") {
          return (
            <Route
              path={val.layout + val.path}
              component={val.component}
              key={key}
            />
          );
        }
        return null;
      }
      )
    }
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);


function Admin() {
  const globalContext = useContext(GlobalContext);
  
  const LoaderPage = () => {
    if (globalContext.loaderPage) {
      return (
        <div className="spinnerLoader d-flex justify-content-center align-items-center active">
          <LoaderSpinner />
        </div>
      );
    }
  };

  return (
    <>
      <AdminStyle>
        <ModalMsm
          show={globalContext.modal.show}
          title={globalContext.modal.title}
          error={globalContext.modal.msg}
          config={globalContext.modal.config}
          handleClose={globalContext.modal.handleClose}
          txtButton="Cerrar"
        />
        {LoaderPage()}
        <div className="page-wrapper chiller-theme toggled" id="pageWrapper">
          <PageContent >
            {switchRoutes}
          </PageContent>
        </div>
      </AdminStyle>
      <ModalPublishProduct />
      <MultiplePost />
      <AnotherMenu />
      
      <ModalLinkCity active={globalContext.activeModalLinkCity} setActive={globalContext.setActiveModalLinkCity} cityText={globalContext.cityText} cityId={globalContext.cityId} />
      <SelectCategory active={globalContext.activeModalMlAm} setActive={globalContext.setActiveModalMlAm} />
      <ListProcess active={globalContext.activeModalProcces} />
      <ModalPublisInAnotherStore active={globalContext.activeModalPublicOtherStore} />
      <InfoIcons />
      <ModalTracking />
      <DefaultimgPost />
      <VerifyLogin />
      <ModalAddProductFtp />
      <ModalSelectStoreAndSection />
      <ModalLinkProduct />
      <ModalPostProduct />
      <FiltersGrid />
      <ReportPostproductsByUser />
      <ModalOtherMarketplace />
      <ModalTestFilter />
    </>
  );
}

export default Admin;