import React, { useContext, useEffect, useState } from 'react'
import OfflineProccesStyle from '../../../../assets/css/components/admin/offline-process/OfflineProccesStyle'
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { AiOutlineClose } from 'react-icons/ai'
import TableData from '../components/TableData';
import Pager from '../../../general/Pager';
import { Spinner } from 'react-bootstrap';
import axios from 'axios'
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
export default function ListProcess(props) {
    const globalContext = useContext(GlobalContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageItemCount, setPageItemCount] = useState(15);
    const [totalRecords, setTotalrecords] = useState(1);
    const [listImgToDowload, setListImgToDowload] = useState([])
    const [isLoader, setIsLoader] = useState(true);
    const [numPendingAmazonUrlsToScrap, setNumPendingAmazonUrlsToScrap] = useState(0);
    const [numScrapingAmazonUrls, setNumScrapingAmazonUrls] = useState(0);
    const [data, setData] = useState([]);
    const { active } = props;

    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const getAllProcess = (entId) => {
        const resp = axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/get-all`,
            {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
                params: {
                    // enterpriseId: entId,
                },
            }
        );
        return resp;
    };

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!active) return;
        // if (!entId) return;

        getAllProcess(entId)
            .then((res) => {
                const data = res.data.data.processes;
                let copyArrListImgToDownload = [...listImgToDowload];
                if (res.data.data.listImg) {
                    res.data.data.listImg.map((v, i) => {
                        copyArrListImgToDownload.push({
                            url: res.data.data.listImg,
                            isDowloaded: false
                        });
                        setListImgToDowload(copyArrListImgToDownload);
                    })
                }
                console.log(data.data)
                setNumPendingAmazonUrlsToScrap(res.data.data.numPendingAmazonUrlsToScrap);
                setNumScrapingAmazonUrls(res.data.data.numScrapingAmazonUrls);
                setData(data);
                setIsLoader(false);
            })
            .catch((err) => {
                handleAxiosError(err);
                setIsLoader(false);
            });
    }, [globalContext.currentEnterpriseId, active, currentPage, pageItemCount]);

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if (!active) return;
        let consulta = false;
        const interval = setInterval(() => {
            if (consulta) return;
            consulta = true;
            getAllProcess(entId)
                .then((res) => {
                    const data = res.data.data.processes;
                    let copyArrListImgToDownload = [...listImgToDowload];
                    if (res.data.data.listImg) {
                        res.data.data.listImg.map((v, i) => {
                            copyArrListImgToDownload.push({
                                url: res.data.data.listImg,
                                isDowloaded: false
                            });
                            setListImgToDowload(copyArrListImgToDownload);
                        })
                    }
                    console.log(data)
                    setData(data);
                    setIsLoader(false);
                })
                .catch((err) => {
                    handleAxiosError(err);
                    setIsLoader(false);
                }).finally(() => {
                    consulta = false;
                });
        }, 5000);
        return () => clearInterval(interval);

    }, [globalContext.currentEnterpriseId, active]);

    const dowloadimg = (imgList) => {
        if (imgList.length > 0) {
            imgList.map(async (v, i) => {
                console.log('url a buscar', v)
                await fetch(`${process.env.REACT_APP_BACKEND_URL_IMG}uploads/2022May09191556923031_processed.png`, {
                    mode: 'no-cors',
                })
                    .then(async (response) => {
                        console.log('adadae245363', response)
                        const blob = await response.blob()
                        console.log('ressddsd', blob)
                        let blobUrl = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.download = v.replace(/^.*[\\\/]/, '');
                        a.href = blobUrl;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    })

            })
        }
    }

    return (
        <OfflineProccesStyle.ContModal d={active ? 'flex' : 'none'}>
            <OfflineProccesStyle.ModalProcess>
                <OfflineProccesStyle.headerModal>
                    <OfflineProccesStyle.btnCloseModal
                        onClick={
                            () => {
                                globalContext.setActiveModalProccess(false);
                                setIsLoader(true);
                            }
                        }>
                        <AiOutlineClose />
                    </OfflineProccesStyle.btnCloseModal>
                    <OfflineProccesStyle.text size="15pt" fw="500">
                        Procesos en segundo plano
                    </OfflineProccesStyle.text>
                </OfflineProccesStyle.headerModal>
                <OfflineProccesStyle.bodyModal>
                    {
                        numPendingAmazonUrlsToScrap > 0 &&
                        (
                            <div style={{
                                alignSelf: 'end',
                                width: '100%',
                                height: '40px',
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'center',
                                padding: '5px 10px',
                                marginBottom: 0,
                                position: 'sticky',
                                top: 0
                            }}>
                                <OfflineProccesStyle.text size="13pt" fw="500">
                                    {`urls en cola para scraping: ${numPendingAmazonUrlsToScrap}`}
                                </OfflineProccesStyle.text>
                            </div>
                        )
                    }
                    {
                        numScrapingAmazonUrls > 0 &&
                        (
                            <div style={{
                                alignSelf: 'end',
                                width: '100%',
                                height: '40px',
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'center',
                                padding: '5px 10px',
                                marginBottom: 0,
                                position: 'sticky',
                                top: 0
                            }}>
                                <OfflineProccesStyle.text size="13pt" fw="500">
                                    {`urls en porceso de scraping: ${numScrapingAmazonUrls}`}
                                </OfflineProccesStyle.text>
                            </div>
                        )
                    }
                    <OfflineProccesStyle.contTable>
                        {!isLoader ? (
                            <>
                                <OfflineProccesStyle.bodyContentTable>
                                    <TableData
                                        data={data}
                                        imgToDownload={listImgToDowload}
                                        dowloadimg={dowloadimg}
                                    />
                                </OfflineProccesStyle.bodyContentTable>
                                <OfflineProccesStyle.footerContTable>
                                </OfflineProccesStyle.footerContTable>
                            </>
                        ) : (
                            <OfflineProccesStyle.contLoader>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Cargando...</span>
                                </Spinner>
                            </OfflineProccesStyle.contLoader>
                        )}
                    </OfflineProccesStyle.contTable>
                </OfflineProccesStyle.bodyModal>
                <OfflineProccesStyle.footerModal>
                    <OfflineProccesStyle.btnActionFooter onClick={() => globalContext.setActiveModalProccess(false)}>
                        Cerrar
                    </OfflineProccesStyle.btnActionFooter>
                </OfflineProccesStyle.footerModal>
            </OfflineProccesStyle.ModalProcess>
        </OfflineProccesStyle.ContModal>
    )
}
