import React from 'react'
import { ContInputAndLabel, Text, Input } from '../../../../../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts'

export default function StepTwo({barCode, setBarCode}) {
    const handlerValue = (v) => {
        setBarCode(v)
    }
    return (
        <ContInputAndLabel>
            <Text size="12pt" fw="500">
                Codigo de Barras
            </Text>
            <Input 
                placeholder='Escriba aqui el codigo de barras'
                onChange={(e) => handlerValue(e.target.value)}
                defaultValue={barCode}
                />
        </ContInputAndLabel>
    )
}
