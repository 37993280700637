import axios from "axios";
import { keyAccessToken, keyUserId } from "../../../../../assets/js/SessionStorageNames";
const saveDataSeccion = (index, enabled, name, restaurant, enterprise, ipUpdate= null) => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL_PEYA}/v1/peya-sdk/new-section`,
        {
            index: index,
            enabled: enabled === 1 ? true: false ,
            name: name,
            restaurant: restaurant,
            enterprise: enterprise,
            ...(ipUpdate && {idUpdate: ipUpdate} )
            //idUpdate: ipUpdate ? ipUpdate : null
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export default saveDataSeccion;