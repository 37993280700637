import { faCloudDownloadAlt, faInfo, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Image } from 'react-bootstrap';
import AmazonStyles from "../../../assets/css/components/admin/products/amazon/listProducts"
import React, { useState, useContext, useEffect } from "react";
import axios from 'axios';
import {
    keyUserId,
    keyAccessToken,
} from "../../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { Link, Redirect } from "react-router-dom";
import Routes from '../../../Routes';
import verifyAccessRole from '../../../assets/js/verifyAccessRole';
function ProductCbt(props) {
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const globalContext = useContext(GlobalContext);
    const [url, setUrl] = useState('');
    const [valueInput, setValueInput] = useState(null)
    const [data, setProductData] = useState(null);
    const [variantsData, setVariantsData] = useState(null)
    const [imgP, setImgP] = useState('');
    const [imgActive, setImgActive] = useState(0);
    const [imgActiveTwo, setImgActiveTwo] = useState(0);
    const [isClothing, setIsClothing] = useState(null);
    const [showVariation, setShowVariation] = useState([]);
    const [imagesProduct, setImagesProduct] = useState([]);
    const [idProduct, setIdProduct] = useState(null);
    const numberFormat = new Intl.NumberFormat('de-DE');
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 32);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);
    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };
    /**
     * 
     * @param {object} obj el objeto que recibe son las url de las imagenes
     * @param {int} originImgs este parametro nos sirve para determinar el origen y dependediendo de eso mostramos las imagenes
     * @param {int} positionArray este parametro es para determinar la posicion del las imagenes en el obj op arreglo de las variantes
     */
   
    const downloadProduct = (e) => {
        e.preventDefault()
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        /**
         * ? if any values are null
         */
        if (!url) return;
        setProductData(null);
        globalContext.setLoaderPage(true);
        const processCheckIntervalTime = 5000;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/parse-product`, {
            cancelToken: axiosCancelTokenSource.token,
            headers,
            params: {
                url,
                langEs: true,
                langEn: true,
            }
        })
            .then(resp => {
                const data = resp.data.data;
                globalContext.setLoaderPage(false);
                globalContext.showModalWithMsg(
                    `El proceso se esta ejecutando en segundo plano!`
                );
                if (data.monitor) {
                    //If the process ID was received, start monitoring
                    let waitingMonitorResponse = false;
                    const interval = setInterval(() => {
                        if (!waitingMonitorResponse) {
                            waitingMonitorResponse = true;
                            axios
                                .get(
                                    `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                                    {
                                        cancelToken: axiosCancelTokenSource.token,
                                        headers,
                                    }
                                )
                                .then((resp) => {
                                    const data = resp.data.data;
                                    
                                    if (data.advance >= 100) {
                                        clearInterval(interval);
                                        globalContext.showModalWithMsg(
                                            `Producto descargado exitosamente!`
                                        );
                                        //console.log(data);
                                        //? verificamos si el atributo iene variantes
                                        setIdProduct(data.data.productData._id)
                                        setProductData(data.data.productData.variants.length > 0 ? data.data.productData.variants[0] : data.data.productData );
                                        //* seteamos el state para las variantes si trae algo
                                        setVariantsData(data.data.productData.variants.length > 0 && data.data.productData.variants);
                                        // * seteamos elstate de variaciones si es que el producto es una variante
                                        setShowVariation(data.data.productData.variants.length > 0 && data.data.productData.variants[0].variations)
                                        if (data.data.productData.variants.length === 0) {
                                            setImgP(data.data.productData.images[0])
                                            setImagesProduct(data.data.productData.images);
                                        } else {
                                            setImgP(data.data.productData.variants[0].images[0])
                                            setImagesProduct(data.data.productData.variants[0].images);
                                        }
                                        //console.log(data.data.productData)
                                        //* validamos que la categoria sea la de ropa y si es otro mostramos las variantes de una manera distinta
                                        setIsClothing(
                                            data.data.productData.variants.length > 0 ? 
                                            data.data.productData.variants[0].categoryTree.find(c =>
                                                        c.node === '7141123011'
                                                ):
                                                data.data.productData.categoryTree.find(c =>
                                                    c.node === '7141123011' || c === '6006105c6d22d645939dbadb'
                                                    )
                                        )
                                        globalContext.setLoaderPage(false);
                                    }
                                })
                                .catch((err) => {
                                    handleAxiosError(
                                        err,
                                        "Disculpe, no se pudo culminar la descarga del producto."
                                    );
                                    clearInterval(interval);
                                    globalContext.setLoaderPage(false);
                                })
                                .finally(() => {
                                    waitingMonitorResponse = false;
                                });
                        }
                    }, processCheckIntervalTime);
                }
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo cargar la informacion."
                );
                globalContext.setLoaderPage(false);
            });
    }
    return (
        <AmazonStyles.Contenedor>
            {redirect}
            <AmazonStyles.HeaderContainer>
                <p style={{ color: ' #707070', fontSize: 20, marginBottom: 5 }}>Coloque la URL de su producto</p>
                <AmazonStyles.headerOptions>
                        <div className="cont" style={{ width: '100%' }}>
                            <form onSubmit={(e) => downloadProduct(e)} style={{ width: '100%' }}>
                                <input type="text" placeholder="Url del producto" onChange={(e) => { setUrl(e.target.value) }} />
                            </form>
                            <button className="btn-action-one" onClick={(e) => downloadProduct(e)}>
                                <FontAwesomeIcon icon={faCloudDownloadAlt} />
                            </button>
                        </div>
                        <Link to="masive-scraping">
                            <button className="button-redirect" style={{ marginLeft: 10 }}>
                                Masiva
                            </button>
                        </Link>
                        <Link to="scraping-by-url">
                            <button className="button-redirect" style={{ marginLeft: 10 }}>
                                URLs
                            </button>
                        </Link>
                        <Link to="list-products-cbt-history-products">
                            <button className="button-redirect" style={{ marginLeft: 10 }}>
                                Historial
                            </button>
                        </Link>
                    </AmazonStyles.headerOptions>
            </AmazonStyles.HeaderContainer>

            {
                data && (
                    <AmazonStyles.cardProduct>
                        <div className="product-cont">
                            <div className="item-photo">
                                <AmazonStyles.contImgCardModal img={imgP} />
                                <AmazonStyles.contImgManyImgCards>
                                    {
                                       
                                        imagesProduct.length > 0 &&
                                            imagesProduct.map((imgItem, i) => {
                                                return (
                                                    <AmazonStyles.contImgGrid key={i} sh={i === imgActive ? true : false} img={imgItem} onClick={() => { setImgP(imgItem); setImgActive(i) }} />
                                                )
                                            })
                                    }
                                </AmazonStyles.contImgManyImgCards>
                            </div>
                            <div className="info-product">
                                <div className="item-text">
                                    <p className="h3 texto-n">{data.title['es'].length > 0 ? data.title['es'] : data.title['en']}</p>
                                    <div className="d-flex justify-content-start">
                                        <p style={{ marginBottom: 2 }} className="sub-texto">USD PROVEDOR - &nbsp;</p>
                                        <p style={{ marginBottom: 2 }} className="text-color">{data.price ? `${numberFormat.format(data.price)}` : ''}</p>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <p style={{ marginBottom: 2 }} className="sub-texto">PRECIO DE ENVIO - &nbsp;</p>
                                        <p style={{ marginBottom: 2 }} className="text-color">{data.shippingPrice ? `$${numberFormat.format(data.shippingPrice)}` : ''}</p>
                                    </div>
                                    <p className="sub-texto" style={{ marginBottom: 5 }}>CARACTERISTICAS:</p>
                                    <ul className="list-caracteristicas">
                                        {data.features ? data.features.map((f, idx) =>
                                            <li key={idx}>{f['es'].length > 0 ? f['es'] : f['en']}</li>
                                        ) : ''}
                                    </ul>
                                    <p className="sub-texto" style={{ marginBottom: 5 }}>DETALLES:</p>
                                    {
                                        (data.details) &&
                                        data.details.productDetails ? 
                                            data.details.productDetails.map((r, idx) =>
                                            <p
                                                className="text-long"
                                                style={{ marginBottom: 2, marginRight: 10 }}
                                                key={idx}>- {r.name['es'].length > 0 ? r.name['es'] : r.name['en']}: {r.value['es'].length > 0 ? r.value['es'] : r.value['en']}
                                            </p>
                                        ) : ''
                                    }
                                    <div className="text-left">
                                        <p style={{ marginBottom: 2 }} className="sub-texto">DESCRIPCION - &nbsp;</p>
                                        <p style={{ marginBottom: 2 }} className="text-long">{data.desc['es'].length > 0 ? data.desc['es'] : data.desc['en']}</p>
                                    </div>
                                </div>
                                {
                                    showVariation.length > 0 ? (
                                        <>
                                            <p className="sub-texto" style={{ marginBottom: 5 }}>VARIACIONES:</p>
                                            {
                                                showVariation.map((v, idx) => {
                                                    return (
                                                        <p
                                                            className="text-long"
                                                            style={{ marginBottom: 2, marginRight: 10 }}
                                                            key={idx}>
                                                            - {v.name['es'].length > 0 ? v.name['es'] : v.name['en']}: {v.value['es'].length > 0 ? v.value['es'] : v.value['es']}
                                                        </p>
                                                    )
                                                }
                                                )
                                            }
                                        </>
                                    ) : ''
                                }
                                <div className="variants text-left mb-2">
                                    <p className="text-color-light" style={{ marginRight: 10 }}>VARIANTES</p>
                                    <AmazonStyles.contImgManyImgVariants isClothing={isClothing ? true : false} >
                                        {
                                            /**
                                             * ? verificamos la categoria padre y asi 
                                             * ? tendremos en cuenta que modelo de variante renderizar
                                             */
                                            variantsData &&

                                            variantsData.map((v, idx) => {
                                                if (isClothing) {
                                                    return (
                                                        <AmazonStyles.contImgGridVariants
                                                            className="card-variant"
                                                            key={idx} img={v.images[0].length > 0 ? v.images[0] : ''}
                                                            sh={idx === imgActiveTwo ? true : false}
                                                            onClick={() => {
                                                                setShowVariation(v.variations)
                                                                setImgActiveTwo(idx)
                                                                setImagesProduct(v.images)
                                                                setProductData(v)
                                                            }
                                                            } />
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <AmazonStyles.constDataTextVariant
                                                            key={idx}
                                                            isActive={idx === imgActiveTwo ? true : false}
                                                            onClick={() => {
                                                                setShowVariation(v.variations)
                                                                setImgActiveTwo(idx)
                                                                setImagesProduct(v.images)
                                                                setProductData(v)
                                                            }
                                                            }
                                                        >
                                                            {
                                                                v.variations[0].name.es.length > 0 ? v.variations[0].name.es : v.variations[0].name.en
                                                            }
                                                -
                                                            {
                                                                v.variations[0].value.es.length > 0 ? v.variations[0].value.es :  v.variations[0].value.en
                                                            }
                                                        </AmazonStyles.constDataTextVariant>
                                                    )
                                                }
                                            })

                                        }
                                    </AmazonStyles.contImgManyImgVariants>
                                </div>
                            <button className="btn-procesar mt-2" onClick={() => { globalContext.setModalPublishProduct(true); globalContext.setModalPublishProductData(data, idProduct) }}>
                                Procesar
                            </button>
                            </div>
                        </div>
                    </AmazonStyles.cardProduct>
                )
            }

        </AmazonStyles.Contenedor>
    );
}

export default ProductCbt;