import React, { useContext } from 'react'
import { ListItemsToSend, ItemToSend, ContInputAndLabel, ContGrid, ContGridCards } from '../../../../../../../assets/css/components/admin/marketplace-to-marketplace/modal-post-product/StepsStyle'
import { Text } from '../../../../../../../assets/css/components/admin/marketplace-to-marketplace/modals/ModalSelectStoreAndSection'
import { GlobalContext } from '../../../../../../../contexts/GlobalContext'
import CardViewProd from '../../cards/general/CardViewProd';

export default function SendData({ restaurantId, sectionId }) {
    const globalContext = useContext(GlobalContext);
    return (
        <div className="d-block" style={{ width: '50%' }}>
            <ListItemsToSend>
                <ItemToSend>
                    <ContInputAndLabel>
                        <Text size='12pt' fw="500">
                            Restaurante o Tienda
                        </Text>
                        <Text size='12pt'>
                            {`${restaurantId.storeId} - ${restaurantId.store}`}
                        </Text>
                    </ContInputAndLabel>
                </ItemToSend>
                <ItemToSend>
                    <ContInputAndLabel>
                        <Text size='12pt' fw="500">
                            Categoria en la cual se publicara
                        </Text>
                        <Text size='12pt'>
                            {sectionId.name}
                        </Text>
                    </ContInputAndLabel>
                </ItemToSend>
            </ListItemsToSend>
            <ContGrid>
                <ContGridCards>
                    {
                        globalContext.listProductsToSend.length > 0 &&
                        globalContext.listProductsToSend.map(v => {
                            return (
                                <CardViewProd data={v} key={v._id} />
                            )
                        })
                    }
                </ContGridCards>
            </ContGrid>
        </div>
    )
}
