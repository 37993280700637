import React, { useEffect, useContext } from 'react'
import QuestionsListStyle from '../../../../assets/css/components/admin/ml-questions/QuestionsListStyle'
import { AiOutlineMenuUnfold } from 'react-icons/ai'
import { IoInformationOutline } from 'react-icons/io5'
import { useState } from 'react'
import { useRef } from 'react'
import ListQuestions from '../components/ListQuestions'
import ChatQuestions from '../components/ChatQuestions'
import InfoProduct from '../components/InfoProduct'
import axios from 'axios';
import { Redirect } from "react-router-dom";
import {
    keyAccessToken,
    keyUserId,
} from "../../../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import Routes from '../../../../Routes'
import verifyAccessRole from '../../../../assets/js/verifyAccessRole'

export default function QuestionsList() {
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const globalContext = useContext(GlobalContext);
    const [redirect, setRedirect] = useState(null);
    const [showList, setShowList] = useState(false);
    const [showInfoProduct, setShowInfoProduct] = useState(false);
    const [pageItemCount, setPageItemCount] = useState(15);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [isLoader, setIsLoader] = useState(true);
    const [productIdSearch, setProductIdSearch] = useState(null)
    const [dataQuestions, setDataQuestions] = useState([]);
    const [dataMlProd, setDataMlProd] = useState(null);
    const [dataQuestionSelected, setDataQuestionSelected] = useState(null);
    const [indexChange, setIndexChange] = useState('');
    const refList = useRef(null);
    const refInfoProduct = useRef(null);
    const [isGetproduct, setIsGetproduct] = useState(false);
    const [idAmazonSearch, setIdAmazonSearch] = useState(null);
    const [dataPrice, setDataPrice] = useState(null);
    const [dataAmazon, setDataAmazon] = useState(null);

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 20);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);

    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    function useOutsideAlerterMenu(refe, isActive, setClose) {
        useEffect(() => {
            if (isActive) {
                /**
                 * Alert if clicked on outside of element
                 */
                function handleClickOutsideMenu(event) {
                    if (refe.current && !refe.current.contains(event.target)) {
                        setClose(false)
                    }
                }
                // Bind the event listener
                document.addEventListener("mousedown", handleClickOutsideMenu);
                return () => {
                    // Unbind the event listener on clean up
                    document.removeEventListener("mousedown", handleClickOutsideMenu);
                };
            }
        }, [refe, isActive]);
    }
    useOutsideAlerterMenu(refList, showList, setShowList);
    useOutsideAlerterMenu(refInfoProduct, showInfoProduct, setShowInfoProduct);

    const getQuestions = async (entId, pageNumber, pageItemCount) => {
        const resp = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/ml-question/${entId}`,
            {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
                params: {
                    filter: {
                        unAnswered: true,
                    },
                    pageNumber: pageNumber,
                    pageItemCount: pageItemCount,
                },
            }
        );
        return resp;
    };

    const getProductMl = async (entId, id) => {
        setIsGetproduct(true);
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/${id}`, {
            params: {
                variants: true,
                linked: false,
            },
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }

    //* optenemos la data de amazon
    const getProductAmazon = async (entId, id) => {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/products/${id}`, {
            params: {
                pageNumber: 1,
                pageItemCount: 1,
                filter: {
                    lang: 'es',
                },
            },
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }

    //* onbtener valores de costo
    const getValues = async (entId) => {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }

    //* actualizar la descripcion de ml
    const updateMlDescription = (mlPrductId, descriptionProduct) => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        const resp = axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/${mlPrductId}/description`, {
            body: descriptionProduct
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return resp
    }
    //* actualizar la publicacion de mercado libre
    const updateMl = (price, stock, mlPrductId, description) => {
        globalContext.setLoaderPage(true)
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/${mlPrductId}`, {
            price: price,
            stock: stock,
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
            .then(resp => {
                console.log(resp.data)
                updateMlDescription(mlPrductId,description)
                .then(resp => {
                    console.log(resp.data)
                    globalContext.showModalWithMsg("Producto actualizado con exito");
                    globalContext.setLoaderPage(false);
                    window.location.reload();
                })
                .catch((e) => { 
                    handleAxiosError(e, 'El precio y el stock fueron actualizados pero no se logro actualizar la descripcion'); 
                    globalContext.setLoaderPage(false); });
            })
            .catch((e) => { handleAxiosError(e, 'Presentamos un error 569'); globalContext.setLoaderPage(false); });
    }
    
    //* obtener el precio de publicacion
    const getPublishingPrice = async (prodId, obj, entId) => {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/calculate-publish-price`, {
            prodId: prodId,
            config: {
                tariff: obj.tariff,
                earning: obj.earning,
                courierPlus: parseInt(obj.courierPlus),
                courierPlusIsPercent: parseInt(obj.courierPlusIsPercent),
                poundPrice: parseInt(obj.poundPrice),
                additionalPoundPrice: parseInt(obj.additionalPoundPrice),
                safe: obj.safe,
                safeIsPercent: obj.safeIsPercent,
                ccTax: obj.ccTax,
                ccTaxIsPercent: obj.ccTaxIsPercent,
                defaultTariff: parseInt(obj.defaultTariff),
                defaultTariffIsPercent: obj.defaultTariffIsPercent,
                iva: parseInt(obj.iva),
                ivaIsPercent: true,
                defaultComission: obj.defaultComission,
                defaultComissionIsPercent: obj.defaultComissionIsPercent,
                countryCode: entId === "60944575461add5b1957ef8b" ? 'VE' : 'CO'
            }
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res
    }
    //* pausar  publicacion
    const pauseMl = (idProduc) => {
        globalContext.setLoaderPage(true);
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/pause/${idProduc}`, {}, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => {
            console.log(resp.data);
            globalContext.setLoaderPage(false);
            globalContext.showModalWithMsg(
                `Producto pausado exitosamente!`
            );
        }).catch(err => { handleAxiosError(err); globalContext.setLoaderPage(false); });
    }
    //* activar publicacion de ml
    const activateMl = (idProduc) => {
        globalContext.setLoaderPage(true);
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }
        if(!idProduc) {
            globalContext.showModalWithMsg("Este producto no tiene data de KBT asociada");
            return;
        }

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/activate/${idProduc}`, {}, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => {
            console.log(resp.data);
            globalContext.showModalWithMsg(
                `Producto activado exitosamente!`
            );
            globalContext.setLoaderPage(false);
        }).catch(err => { handleAxiosError(err); globalContext.setLoaderPage(false); });
    }
    
    //* volver a descargar el producto
    const scarpingAgain = (url) => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if (!url) return;
        globalContext.setLoaderPage(true);
        const processCheckIntervalTime = 5000;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/parse-product`, {
            cancelToken: axiosCancelTokenSource.token,
            headers,
            params: {
                url,
                langEs: true,
                langEn: true,
            }
        })
            .then(resp => {
                const data = resp.data.data;
                if (data.monitor) {
                    //If the process ID was received, start monitoring
                    let waitingMonitorResponse = false;
                    const interval = setInterval(() => {
                        if (!waitingMonitorResponse) {
                            waitingMonitorResponse = true;
                            axios
                                .get(
                                    `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                                    {
                                        cancelToken: axiosCancelTokenSource.token,
                                        headers,
                                    }
                                )
                                .then((resp) => {
                                    const data = resp.data.data;

                                    if (data.advance >= 100) {
                                        clearInterval(interval);
                                        globalContext.showModalWithMsg(
                                            `Producto actualizado exitosamente!`
                                        );
                                        window.location.reload();
                                        globalContext.setLoaderPage(false);
                                    }
                                })
                                .catch((err) => {
                                    handleAxiosError(
                                        err,
                                        "Disculpe, no se pudo culminar la descarga del producto."
                                    );
                                    clearInterval(interval);
                                    globalContext.setLoaderPage(false);
                                })
                                .finally(() => {
                                    waitingMonitorResponse = false;
                                });
                        }
                    }, processCheckIntervalTime);

                }
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, ocurrio un error al actualizar la informacion."
                );
                globalContext.setLoaderPage(false);
            });
    }

    //* obtenemos los datos de las preguntas;
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        setIsLoader(true);
        getQuestions(entId, currentPage, pageItemCount)
            .then((res) => {
                const data = res.data.data;
                setDataQuestions(data.questions);
                setTotalRecords(res.data.data.total);
                setIsLoader(false);
            })
            .catch((err) => {
                handleAxiosError(err);
                setIsLoader(false);
            });
    }, [globalContext.currentEnterpriseId, currentPage])

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if (!productIdSearch) return;
        getProductMl(entId, productIdSearch)
            .then((res) => {
                setIsGetproduct(false);
                const data = res.data.data;
                setDataMlProd(data);
                // *seteamos el state para el id de amazon
                setIdAmazonSearch(data.attrib.amazonScrapProduct)
                console.log('data de ml',data)
            })
            .catch((err) => {
                setIsGetproduct(true);
                handleAxiosError(err);
            })

    }, [globalContext.currentEnterpriseId, productIdSearch])

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if (!idAmazonSearch) return;
        getProductAmazon(entId, idAmazonSearch)
        .then(res => {
            const data = res.data.data.product
            setDataAmazon(data)
            console.log('Lo de amazon',res);
        })
        .catch(err => {
            handleAxiosError(err);
        })
    }, [globalContext.currentEnterpriseId, idAmazonSearch])

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if(!dataMlProd) return;
        getValues(entId)
        .then(async resp => {
            const data = resp.data.data.configData.publishPriceCalculation;
            //const dataTwo = resp.data.data.configData
            //console.log(data)
            await getPublishingPrice(dataMlProd.attrib.amazonScrapProduct, data, entId)
                .then((res) => {
                    const data = res.data.data
                    setDataPrice(data);
                }).catch((e) => { 
                    handleAxiosError(e, 'Presentamos un error 598'); 
                    globalContext.setLoaderPage(false); 
                })
        }).catch(err => {
            handleAxiosError(err);
            globalContext.setLoaderPage(false);
        });
    }, [dataMlProd, globalContext.currentEnterpriseId])

    return (
        <QuestionsListStyle.cont>
            {redirect}
            <QuestionsListStyle.header>
                <QuestionsListStyle.text fw='700' size='15pt' mr='10px'>
                    Pregunstas
                </QuestionsListStyle.text>
                <QuestionsListStyle.text size='15pt'>
                    | Lista de pregunstas de Mercado Libre
                </QuestionsListStyle.text>
            </QuestionsListStyle.header>
            <QuestionsListStyle.contListQuestions>
                <QuestionsListStyle.btnShowMenus right="auto" brr="50%" onClick={() => setShowList(!showList)}>
                    <AiOutlineMenuUnfold />
                </QuestionsListStyle.btnShowMenus>
                {
                    dataMlProd && (
                        <QuestionsListStyle.btnShowMenus display="flex" left="auto" blr="50%" onClick={() => setShowInfoProduct(!showInfoProduct)}>
                            <IoInformationOutline />
                        </QuestionsListStyle.btnShowMenus>
                    )
                }
                <ListQuestions
                    refList={refList}
                    data={dataQuestions}
                    isActiva={showList}
                    isLoader={isLoader}
                    setShowList={setShowList}
                    setProductIdSearch={setProductIdSearch}
                    setDataQuestionSelected={setDataQuestionSelected}
                    setIndexChange={setIndexChange}
                    currentPage={currentPage}
                    pageItemCount={pageItemCount}
                    setCurrentPage={setCurrentPage}
                    totalRecords={totalRecords}
                />
                <ChatQuestions
                    data={dataQuestionSelected}
                    isGetproduct={isGetproduct}
                    dataQuestions={dataQuestions}
                    setDataQuestions={setDataQuestions}
                    indexChange={indexChange}
                />
                <InfoProduct
                    showInfoProduct={showInfoProduct}
                    refInfoProduct={refInfoProduct}
                    data={dataMlProd}
                    dataPrice={dataPrice}
                    dataAmazon={dataAmazon}
                    pauseMl={pauseMl}
                    activateMl={activateMl}
                />
            </QuestionsListStyle.contListQuestions>
        </QuestionsListStyle.cont>
    )
}
