import React from 'react'
import CronReportsStyle from '../../../../assets/css/components/admin/cron-reports/CronReportsStyle'
import ItemListError from './ItemListError'

export default function ListErrors(props) {
    const {data} = props;
    return (
        <CronReportsStyle.ListErrors>
            {
                data && 
                data.map((v,i) => {
                    return (
                        <ItemListError key={i} data={v} />
                    )
                })
            }
        </CronReportsStyle.ListErrors>
    )
}
