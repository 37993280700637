import { keyAccessToken, keyUserId } from "../../../../assets/js/SessionStorageNames";
import axios from 'axios';
//get data price
const getPublishingPrice = async (prodId, obj, entId) => {
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/calculate-publish-price`, {
        prodId: prodId,
        config: {
            tariff: obj.tariff,
            earning: obj.earning,
            courierPlus: parseInt(obj.courierPlus),
            courierPlusIsPercent: obj.courierPlusIsPercent,
            poundPrice: parseInt(obj.poundPrice),
            additionalPoundPrice: parseInt(obj.additionalPoundPrice),
            safe: obj.safe,
            safeIsPercent: obj.safeIsPercent,
            ccTax: obj.ccTax,
            ccTaxIsPercent: obj.ccTaxIsPercent,
            defaultTariff: parseInt(obj.defaultTariff),
            defaultTariffIsPercent: obj.defaultTariffIsPercent,
            iva: parseInt(obj.iva),
            ivaIsPercent: true,
            defaultComission: obj.defaultComission,
            defaultComissionIsPercent: obj.defaultComissionIsPercent,
            countryCode: entId === "60944575461add5b1957ef8b" ? 'VE' : 'CO'
        }
    }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res
}

export default getPublishingPrice;