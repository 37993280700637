import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
const updatePriceAndStockOne = async (entId, mlPrductId, price, stock, manufacturingTime) => {
    const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/${mlPrductId}`, {
        price: price,
        stock: stock,
        manufacturingTime: manufacturingTime
    }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    });
    return res;
}

const updatePricesAndStocks = async (entId, productSelecteds,  productSelectedsWithErrors, dataCard, setDataCard, setTotalUpdate, setTotalUpdateVariants, setIsLoader, deliveryDaysPlusValue) => {
    setIsLoader(true)
    let arrayCopy = [...dataCard];
    let totalUpdate = 0;
    let totalUpdateVariants = 0;
    if (productSelecteds.length > 0) {
        await Promise.all(productSelecteds.map( async (v) => {
            const indexOne = arrayCopy.findIndex(vc => vc._id === v._id)
            if (v.variants.length > 0) {
                
                v.variants.forEach(async(va) => {
                    if(va.ml) {
                        const indexTwo = arrayCopy[indexOne].variants.findIndex(v => v.asin === va.asin);
                        try {
                            const resp = await updatePriceAndStockOne(entId, va.ml._id, Math.ceil(va.dataPrice.total), va.stock, (v.deliveryDays + deliveryDaysPlusValue))
                            totalUpdateVariants++;
                            setTotalUpdateVariants(totalUpdateVariants)
                            arrayCopy[indexOne].variants[indexTwo].itHasUpdateMl = true;
                            setDataCard(arrayCopy)
                            console.log('se actualizo', va.asin, resp.data.message)
                        } catch (error) {
                            console.log('no se actualizo', va.asin, error)
                        }
                    }
                });
            }else {
                if(v.ml) {
                    //console.log('precios sin v', v); return;
                    try {
                        const resp = await updatePriceAndStockOne(entId, v.ml._id, Math.ceil(v.dataPrice.total), v.stock, (v.deliveryDays + deliveryDaysPlusValue))
                        totalUpdate++;
                        setTotalUpdate(totalUpdate);
                        arrayCopy[indexOne].itHasUpdateMl = 'paused';
                        setDataCard(arrayCopy)
                        console.log('se actualizo', v.asin, resp.data.message)
                    } catch (error) {
                        console.log('no se actualizo', v.asin, error)
                    }
                }
            }
        }))
        setIsLoader(false)
    }else {
        await Promise.all(productSelectedsWithErrors.map( async (v) => {
            const indexOne = arrayCopy.findIndex(vc => vc._id === v._id)
            if (v.variants.length > 0) {
                for await (let va of v.variants) {
                    if(va.ml) {
                        const indexTwo = arrayCopy[indexOne].variants.findIndex(v => v.asin === va.asin);
                        try {
                            const resp = await updatePriceAndStockOne(entId, va.ml._id, Math.ceil(va.dataPrice.total), va.stock)
                            totalUpdateVariants++;
                            setTotalUpdateVariants(totalUpdateVariants);
                            arrayCopy[indexOne].variants[indexTwo].itHasUpdateMl = true;
                            setDataCard(arrayCopy)
                            console.log('se actualizo', va.asin, resp.data.message)
                            
                        } catch (error) {
                            console.log('no se actualizo', va.asin, error)
                        }
                    }
                }
            }else {
                if(v.ml) {
                    try {
                        const resp = await updatePriceAndStockOne(entId, v.ml._id, Math.ceil(v.dataPrice.total), v.stock)
                        totalUpdate++;
                        arrayCopy[indexOne].itHasUpdateMl = true;
                        setTotalUpdate(totalUpdate);
                        setDataCard(arrayCopy)
                        console.log('se actualizo', v.asin, resp.data.message)
                    }catch(err) {
                        console.log('no se actualizo', v.asin, err)
                    }
                }
            }
        }))
        setIsLoader(false);
    }
}

export default updatePricesAndStocks