import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';

const deleteOne = (ent, id) => {
    return axios.delete(`${process.env.REACT_APP_BACKEND_URL_PEYA}/v1/peya-sdk/delete-one/${ent}/${id}`, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        }
    })
}
export default deleteOne;