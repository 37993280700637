import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import ConfigStyled from '../../../../assets/css/components/admin/config/ConfigStyled'
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import axios from 'axios';
export default function Six() {
    const [isSending, setIsSending] = useState(false);
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [defaultConfig, setDefaultConfig] = useState({});
    const [config, setConfig] = useState({});
    
    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (msg) globalContext.showModalWithMsg(msg);
    };

    /**
     * Change the config state
     * @param {Event} e The change event
     */
    const updateConfigState = (e) => {
        const name = e.target.name;
        const newConfig = Object.assign({}, config);

        switch (e.target.type) {
            case "checkbox":
                newConfig[name] = e.target.checked;
                break;

            default:
                newConfig[name] = e.target.value;
                break;
        }

        setConfig(newConfig);
    };

    /**
     * Send the changes to the server
     */
    const saveChanges = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if (!window.confirm("Desea guardar los cambios?")) return;

        setIsSending(true)

        axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/df`, {
            config: {
                version: config.version,
                format: config.format,
                url: config.url
            }
        }, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then(resp => {
            //Set the actual config as the default data
            setDefaultConfig(Object.assign(defaultConfig, config));
            globalContext.showModalWithMsg(`Los datos fueron guardados`);
        }).catch((err) =>
            handleAxiosError(
                err,
                "Disculpe, no se pudieron guardar los cambios."
            )
        ).finally(() => {
            setIsSending(false)
        });
    }

    /**
     * Load the plugin config
     */
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        setIsSending(true)
        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/df`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then(resp => {
            const data = resp.data.data.configData;
            //Set the default config
            const defaultConf = {
                version:data.version || "",
                format:data.format || "",
                url:data.url || "",
            };
            setDefaultConfig(defaultConf);
            setIsSending(false)
            
            //Set the actual config as a copy of the default
            setConfig(Object.assign({}, defaultConf));
        }).catch((err) =>
            {
                setIsSending(false)
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo cargar la configuracion actual."
                )
            }
        );
    }, [globalContext.currentEnterpriseId]);

    /**
     * Cancel axios requests before unmount
     */
    useEffect(() => {
        return () => {
            axiosCancelTokenSource.cancel("Canceled by unmount");
        };
        //eslint-disable-next-line
    }, []);

    return (
        <ConfigStyled.ContForm isSingle={true}>

            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size="12pt">
                    Version del API
                </ConfigStyled.text>
                <ConfigStyled.input
                    readOnly={isSending}
                    placeholder="coloque su version del api..."
                    name="version"
                    value={config.version || ""}
                    onChange={updateConfigState}
                />
            </ConfigStyled.ContInputAndLabel>

            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size="12pt">
                    Formato (JSON O XML)
                </ConfigStyled.text>
                <ConfigStyled.input
                    readOnly={isSending}
                    placeholder="coloque su formato"
                    name="format"
                    value={config.format || ""}
                    onChange={updateConfigState}
                />
            </ConfigStyled.ContInputAndLabel>

            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size="12pt">
                    Url
                </ConfigStyled.text>
                <ConfigStyled.input
                    readOnly={isSending}
                    placeholder="coloque su url..."
                    name="url"
                    value={config.url || ""}
                    onChange={updateConfigState}
                />
            </ConfigStyled.ContInputAndLabel>

            <ConfigStyled.ARow>
                <ConfigStyled.btnSend disabled={isSending} onClick={saveChanges}>
                    Guardar
                </ConfigStyled.btnSend>
            </ConfigStyled.ARow>
        </ConfigStyled.ContForm>
    )
}
