import React, { useState } from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import FilterEnterpriseStyle from "../../../../assets/css/components/admin/enterprise/dashboard/FilterEnterprise.css";

export default function FilterEnterprise(props) {
  const [currentFilter, setCurrentFilter] = useState(props.filter);

  /**
   * Updates the filter value
   */
  const updateCurrentFilter = (e) => {
    setCurrentFilter(e.target.value);
  };

  return (
    <div>
      <FilterEnterpriseStyle>
        <InputGroup>
          <FormControl
            placeholder="Nombre de tu empresa"
            aria-label="Nombre de tu empresa"
            aria-describedby="basic-addon2"
            value={currentFilter}
            onChange={updateCurrentFilter}
          />
          <InputGroup.Append>
            <Button
              onClick={() => {
                props.updateFilter(currentFilter);
              }}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </FilterEnterpriseStyle>
    </div>
  );
}
