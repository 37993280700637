import styled from "styled-components";

const Cont = styled.div.attrs(props => ({
    opa: props.a ? '1':'0',
    translateY: props.a ? 'translateY(0)' : 'translateY(90px)',
    show: props.a ? 'block' : 'none'
}))`
min-width: 300px;
width: 300px;
max-width: 300px;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
background: #FFFFFF;
border-radius: 0.428rem;
position: absolute;
left: auto;
right: 15px;
bottom: auto;
z-index: 9;
opacity: ${props => props.opa};
transition: 1s opacity ease-out, translate 1.5s ease-in-out, display .3s ease;
transform: ${props => props.translateY};
display: ${props => props.show};
`
const HeaderNotifications = styled.div`
width: 100%;
padding: .5rem;
display: flex;
justify-content: flex-start;
align-items: center;
`
const BodyNotifications = styled.div`
width: 100%;
padding: .5rem;
min-height: 150px;
max-height: 400px;
overflow-y: auto;
`

const Text = styled.p.attrs(props => ({
    cl: props.cl || '#707070',
    size: props.size || '.857rem',
    ml: props.ml || '0',
    mr: props.mr || '0',
    fw: props.fw || 'lighter',
    mt: props.mt || '.2857rem',
    mb: props.mb || '.2857rem',
    longText: props.longText ? 'ellipsis' : '',
    longTextTwo: props.longText ? 'hidden' : '',
    longTextThree: props.longText ? 'nowrap' : '',
})) `
color: ${props => props.cl };
font-size: ${props => props.size };
margin-left: ${props => props.ml };
margin-right: ${props => props.mr };
font-weight: ${props => props.fw };
margin-top: ${props => props.mt };
margin-bottom: ${props => props.mb };
text-overflow:${props => props.longText };
overflow:${props => props.longTextTwo };
white-space:${props => props.longTextThree };
`;

const ListItems = styled.ul`
width: 100%;
list-style: none;
padding-left: 0;
`
const ItemList = styled.li`
width: 100%;
padding: 5px;
min-height: 50px;
height: auto;
border-bottom: 1px solid #d8d6de;
text-align: left;
word-break: break-all;
`;

const NotificationsStyle = {
    Cont,
    HeaderNotifications,
    BodyNotifications,
    Text,
    ListItems,
    ItemList
}

export default NotificationsStyle;