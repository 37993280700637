import styled from 'styled-components';

const Cont = styled.div`
display: block;
width: 100%;
padding-right:10px;
@media (max-width: 990px){
    padding: 10px;
}

`;

const Header = styled.div `
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
/* border: 1px solid; */
margin-top: 10px;
margin-bottom: 10px;
`;

const ContInputAndLabel = styled.div `
width:${props=> props.w || '300px'};
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'space-between'};
align-items:${props => props.ali || 'flex-start'};
margin-right: ${props=> props.mr || '0'};
`;

const Text = styled.p `
color: ${props => props.cl || '#707070'};
font-size: ${props => props.size || '.857rem'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '.2857rem'};
margin-bottom: ${props => props.mb || '.2857rem'};
text-overflow:${props => props.longText ? 'ellipsis' : ''};
overflow:${props => props.longText ? 'hidden' : ''};
white-space:${props => props.longText ? 'nowrap' : ''};
`;

const TextLink = styled.a `
color: ${props => props.cl || '#707070'};
font-size: ${props => props.size || '.857rem'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '.2857rem'};
margin-bottom: ${props => props.mb || '.2857rem'};
text-overflow:${props => props.longText ? 'ellipsis' : ''};
overflow:${props => props.longText ? 'hidden' : ''};
white-space:${props => props.longText ? 'nowrap' : ''};
text-decoration: ${props => props.textDecoration ? 'underline' : 'none'};
`

const ContHeaderOptions = styled.div` 
width: auto;
max-width: 1000px;
border-radius: .428rem;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
background: #FFFFFF;
padding: 10px;
display: flex;
justify-content: center;
align-items: flex-end;
flex-wrap: wrap;
margin-left: auto;
margin-right: auto;
position: relative;
`;

const ContTotalData = styled.div`
background: #7367f0;
border-radius: 0.428rem 0 0.428rem 0 ;
padding: 5px;
position: absolute;
left: 0;
right: auto;
top: 0;
bottom: auto;
width: 200px;
height: 30px;
display: flex;
justify-content: flex-start;
align-items: center;

`;

const dataPikerInput = styled.div`
width: 100%;
.input-date {
    width: 100%;
    outline:none !important;
    height: 40px;
    background: #fff;
    color: rgba(112, 112, 112, 0.61);
    font-weight: 600;
    cursor: pointer;
    font-size:14px;
    padding: .438rem 1rem;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: .357rem;
    position: relative;
}
.btn-date {
    border-radius: 50px;
    width: 35px;
    height: 35px;
    outline: none !important;
    background: #7367F0;
    box-shadow: 2px 0px 12px #7367f0;
    border: none;
    color: white;
    transition: all ease-in-out 0.02s;
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    left: auto;
}
.cont-calendar {
    display: 'flex'; 
    flex-flow: 'column nowrap';
    background: #fff;
    box-shadow: 0px 3px 21px rgba(0, 0, 0, 0.16);
    display: none;
    transition: ease-out;
    position: absolute;
    border-radius:15px;
    margin-top:10px;
}
.btn-date.active{
    color: white;
    font-weight: 600;
    background-color: #7367F0;
    border-color: transparent;
}
.cont-calendar.active {
    display: block;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: translate(0px, 5px);
    z-index: 9999;
}
`;

const ButtonSearch = styled.button`
outline: none !important;
border: 1px solid #7367f0;
border-radius: 50px;
color: #7367f0;
font-size: 12pt;
font-weight: lighter;
background: #FFFFFF;
width: 35px;
height: 35px;
display: flex;
align-items: center;
justify-content: center;
transition: all ease-in-out 0.2s;
&:hover {
    font-weight: 500;
    color: #FFFFFF;
    background: #7367f0;
}
`;

const ContBodyList = styled.div`
width: 100%;
min-height: 100px;
border-radius: .428rem;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
background: #FFFFFF;
padding: 2rem;
margin-left: auto;
margin-right: auto;
margin-top: 1rem;
display: block;
position: relative;
`;

const ContBodyListUsers = styled.div`
max-width: 1000px;
width: 1000px;
min-width: 250px;
min-height: 100px;
border-radius: .428rem;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
background: #FFFFFF;
padding: 2rem;
margin-left: auto;
margin-right: auto;
margin-top: 1rem;
display: block;
position: relative;
`;

const ListErrors = styled.ul`
width: 100%;
padding-left: 0;
list-style: none;
`;

const ContListItems = styled.div`
width: 100%;
display: grid;
grid-gap: .5rem;
grid-auto-rows: auto;
grid-template-columns: repeat(auto-fill, minmax(300px, 300px));
transition: all ease-in-out .2s;
`;



const ItemListError = styled.li`
width: 100%;
padding: 1rem 2.8rem 1rem 1rem;
border-bottom: 1px solid #ebe9f1;
height: auto;
`;
const HeeaderItemList = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
padding: .2rem;
`;
const BtnExpanList = styled.button`
width: 25px;
height: 25px;
border-radius: 50px;
font-size: 12pt;
border: none;
display: flex;
justify-content: center;
align-items: center;
color: #707070;
background: #FFFFFF;
border: none !important;
outline: none !important;
transition: all ease-in-out .2s;
&:hover {
    background: #F0F0F0;
}
`;

const BodyItemList = styled.div`
width: 100%;
height: ${props=> props.active ? 'auto' : '0px'};
max-height: 250px;
overflow: ${props=> props.active ? 'auto' : 'hidden'};
padding: 0 .5rem;
text-align: justify;
color: #707070;
font-size: 12pt;
transition: all ease-in-out .3s;
/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 50px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #7367f0;
  }
`;

const ContVariants = styled.div`
border-radius: .429rem;
width: 100%;
border: 1px solid #ebe9f1;
`;
const ContVariantsHeader = styled.div`
width: 100%;
border-bottom: 1px solid #ebe9f1;
padding: .3rem;
`;
const ContVariantsBody = styled.div`
width: 100%;
padding: 1rem;
`;
const ContImgVariants = styled.div`
width: 100%;
display: grid;
grid-gap: 1rem;
grid-auto-rows: auto;
grid-template-columns: repeat(auto-fill, minmax(100px, auto));
`;
const ImgVariants = styled.div`
background-color: #f0f0f0;
width: 100%;
height: 100px;
background-image: url(${props => props.url || ''});
background-size: cover;
background-repeat: no-repeat;
background-position: center;
border-radius: .429rem;
cursor: pointer;
`;

const contLoader = styled.div`
width: 100%;
height: 100%;
margin-top: auto;
margin-bottom: auto;
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
.spinner-border {
    border: .25em solid #7367f0;
    border-right-color: transparent;
}
`;

const inputRadio = styled.input`

@supports(-webkit-appearance: none) or (-moz-appearance: none) {

  --active: #7367f0;
  --active-inner: #fff;
  --focus: 2px rgba(39, 94, 254, .3);
  --border: #7367f0;
  --border-hover: #7367f0;
  --background: #fff;
  --disabled: #F6F8FF;
  --disabled-inner: #E1E6F9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background .3s, border-color .3s, box-shadow .2s;
  margin-left: ${props => props.ml || '0'};
  margin-right: ${props => props.mr || '0'};
  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
  }
  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }
  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: .9;
    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
    & + label {
      cursor: not-allowed;
    }
  }
  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }
  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  &:not(.switch) {
    width: 21px;
    &:after {
      opacity: var(--o, 0);
    }
    &:checked {
      --o: 1;
    }
  }
  & + label {
    font-size: 12pt;
    line-height: 14px;
    display: inline-block;
    vertical-align: inherit;
    cursor: pointer;
    margin-left: 5px;
    color: #707070;
    margin-bottom: 0 !important;
  }


  border-radius: 50%;
  &:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, .7));
  }
  &:checked {
    --s: .5;
  }
}
`;
const contInputRadio = styled.div`
display: flex;
align-items:center;
width:100%;
justify-content: flex-start;
margin-bottom:10px;
`;
const CronReportsStyle = {
    Cont,
    Header,
    ContInputAndLabel,
    Text,
    TextLink,
    ContHeaderOptions,
    dataPikerInput,
    ButtonSearch,
    ContBodyList,
    ListErrors,
    ItemListError,
    HeeaderItemList,
    BtnExpanList,
    BodyItemList,
    ContImgVariants,
    ImgVariants,
    ContVariants,
    ContVariantsHeader, 
    ContVariantsBody,
    contLoader,
    inputRadio,
    contInputRadio,
    ContTotalData,
    ContBodyListUsers,
    ContListItems
}

export default CronReportsStyle;