import React from "react";
import { Redirect } from "react-router-dom";

export default function MlCode() {
    //Get the Mercadolibre code
    if (window.opener) {
        const params = new URLSearchParams(window.location.search);
        window.opener.postMessage({code: params.get('code')});
        window.close();
    }

    return <Redirect to="/" />;
}