import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
const pauseOne = async (entId,idProduc) => {
    const res = await  axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/pause/${idProduc}`, {}, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    });
    return res;
}

const pausePublications = async (entId, productSelecteds,  productSelectedsWithErrors, dataCard, setDataCard, setTotalPaused, setTotalPausedVariants, setIsLoader) => {
    setIsLoader(true);
    let arrayCopy = [...dataCard];
    let totalPaused = 0;
    let totalPausedVariants = 0;
    if (productSelecteds.length > 0) {
        await Promise.all(productSelecteds.map( async (v) => {
            const indexOne = arrayCopy.findIndex(vc => vc._id === v._id)
            if (v.variants.length > 0) {
                for await (let va of v.variants) {
                    if(va.ml) {
                        const indexTwo = arrayCopy[indexOne].variants.findIndex(v => v.asin === va.asin);
                        await pauseOne(entId, va.ml._id)
                        .then(res => {
                            totalPausedVariants++;
                            setTotalPausedVariants(totalPausedVariants)
                            arrayCopy[indexOne].variants[indexTwo].ml.attrib.status = 'paused';
                            setDataCard(arrayCopy)
                            console.log('se pauso', va.asin, res)
                        }).catch(err => {
                            console.log('no se pauso', va.asin, err)
                        })
                    }
                }
            }else {
                if(v.ml) {
                    await pauseOne(entId, v.ml._id)
                    .then(res => {
                        totalPaused++;
                        setTotalPaused(totalPaused);
                        arrayCopy[indexOne].ml.attrib.status = 'paused';
                        setDataCard(arrayCopy)
                        console.log('se pauso', v.asin, res)
                    }).catch(err => {
                        console.log('no se pauso', v.asin, err)
                    })
                }
            }
        }))
        setIsLoader(false);
    }else {
        await Promise.all(productSelectedsWithErrors.map( async (v) => {
            const indexOne = arrayCopy.findIndex(vc => vc._id === v._id)
            if (v.variants.length > 0) {
                for await (let va of v.variants) {
                    if(va.ml) {
                        const indexTwo = arrayCopy[indexOne].variants.findIndex(v => v.asin === va.asin);
                        await pauseOne(entId, va.ml._id)
                        .then(res => {
                            totalPausedVariants++;
                            setTotalPausedVariants(totalPausedVariants);
                            arrayCopy[indexOne].variants[indexTwo].ml.attrib.status = 'paused';
                            setDataCard(arrayCopy)
                            console.log('se pauso', va.asin, res)
                        }).catch(err => {
                            console.log('no se pauso', va.asin, err)
                        })
                    }
                }
            }else {
                if(v.ml) {
                    await pauseOne(entId, v.ml._id)
                    .then(res => {
                        totalPaused++;
                        arrayCopy[indexOne].ml.attrib.status = 'paused';
                        setTotalPaused(totalPaused);
                        setDataCard(arrayCopy)
                        console.log('se pauso', v.asin, res)
                    }).catch(err => {
                        console.log('no se pauso', v.asin, err)
                    })
                }
            }
        }))
        setIsLoader(false);
    }
}
export default pausePublications;