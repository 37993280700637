import React from 'react'
import { BackgroundModal, ContModal, HeaderModal, Text, BtnCloseModal, BodyModal, FooterModal, BtnGeneral } from '../../../../../../assets/css/components/admin/marketplace-to-marketplace/modals/ModalSelectStoreAndSection';
import { GlobalContext } from '../../../../../../contexts/GlobalContext';
import { useContext } from 'react';
import {  BiX } from 'react-icons/bi'
import { useState } from 'react';
import { useEffect } from 'react';
import StepOne from './modal-select-store-and-section/StepOne'
import StepTwo from './modal-select-store-and-section/StepTwo'
import SendData from './modal-select-store-and-section/SendData';
import axios from 'axios';
import downloadProduct from '../../functions/downloadProducts';
export default function ModalSelectStoreAndSection() {
    const globalContext = useContext(GlobalContext);
    const [currentStep, setCurrentStep] = useState(1);
    const [load, setLoad] = useState(false);
    const [restaurantId, setRestaurantId] = useState(null);
    const [sectionId, setSectionId] = useState(null);
    const [hasError, setHasError] = useState(null);
    const [success, setSuccess] = useState(false)
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());

    const downLoadProducts = () => {
        const entId = globalContext.currentEnterpriseId
        if(!entId) return;
        setLoad(true);
        downloadProduct(entId, restaurantId.storeId, sectionId._id,axiosCancelTokenSource)
            .then(res => {
                console.log(res);
                setLoad(false)
                setSuccess(true)
            }).catch(error => {
                console.log(error)
                setHasError(error)
            })
    }

    useEffect(() => {
        if (!globalContext.modalStoreAndSection) {
            setCurrentStep(1)
            setRestaurantId(null)
            setSectionId(null)
        }
    }, [globalContext.modalStoreAndSection])

    const renderBotonSteps = () => {
        switch (currentStep) {
            case 1:
                return (
                    <BtnGeneral onClick={() => setCurrentStep(currentStep + 1)} disabled={!restaurantId}>
                        Siguiente
                    </BtnGeneral>
                    )
                    case 2:
                        return (
                            <>
                        <BtnGeneral
                        bgc="#E3E3E3"
                        left="10px"
                        right="auto"
                        color="#515151"
                        disabled={!sectionId}
                        onClick={() => setCurrentStep(currentStep - 1)}>
                            Volver
                        </BtnGeneral>
                        <BtnGeneral onClick={() => setCurrentStep(currentStep + 1)}>
                            Siguiente
                        </BtnGeneral>
                    </>
                )
                case 3:
                    return (
                        <>
                            <BtnGeneral
                            bgc="#E3E3E3"
                            left="10px"
                            right="auto"
                            color="#515151"
                            onClick={() => setCurrentStep(currentStep - 1)}>
                                Volver
                            </BtnGeneral>
                            <BtnGeneral onClick={() => downLoadProducts()}>
                                Descargar
                            </BtnGeneral>
                        </>
                        )
        
            default:
                break;
        }
    } 

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return ( 
                    <StepOne
                        activeModal={globalContext.modalStoreAndSection} 
                        restaurantId={restaurantId}
                        setRestaurantId={setRestaurantId}
                    />
                    )
                
            case 2:
                return ( 
                <StepTwo
                    activeModal={globalContext.modalStoreAndSection}
                    sectionId={sectionId} 
                    setSectionId={setSectionId}
                />
                )
            case 3:
                return (
                <SendData
                    restaurantId={restaurantId}
                    sectionId={sectionId}
                    hasError={hasError}
                    load={load}
                    success={success}
                />
                )
        
            default:
                break;
        }
    }

    const renderLabelModal = () => {
        switch (currentStep) {
            case 1:
                return '1: Seleccione su Tienda';
                
            case 2:
                return '2: Seleccione su Categoria o Seccion'
            case 3:
                return '3: Descargue los productos'
        
            default:
                break;
        }
    }

    return (
        <BackgroundModal active={globalContext.modalStoreAndSection}>
            <ContModal active={globalContext.modalStoreAndSection} w="auto">
                <HeaderModal>
                    <Text fw="500" size="13pt">
                        {renderLabelModal()}
                    </Text>
                    <BtnCloseModal onClick={() => globalContext.setModalStoreIdAndSection(false)} >
                        <BiX/>
                    </BtnCloseModal>
                </HeaderModal>
                <BodyModal>
                    {renderStep()}
                </BodyModal>
                <FooterModal>
                    {
                        renderBotonSteps()
                    }
                    
                </FooterModal>
            </ContModal> 
        </BackgroundModal>
    )
}
