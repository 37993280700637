import React from 'react'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle';
import { BiHide, BiShow, BiX } from 'react-icons/bi'
import Select from '../../../../general/Select';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import { useState, useContext } from 'react';
import { getCredencials, sendCredencials } from '../functions/credentialsMethods';
import validateDataBeforeSend from "../functions/validateDataBeforeSend";
import { useEffect } from 'react';

export default function CredentialsWoo() {

    /* 
    * states 
    */
    const [showBadge, setShowBadge] = useState(false);
    const [colorError, setColorError] = useState(true);
    const [error, setError] = useState('');
    const [showPass, setShowPass] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [url, setUrl] = useState(null);
    const [consumerKey, setConsumerKey] = useState(null);
    const [consumerSecret, setConsumerSecret] = useState(null);
    const [version, setVersion] = useState(null);
    const globalContext = useContext(GlobalContext)
    /* 
    * states  end       
    */

    useEffect(() => {
        const ent = globalContext.currentEnterpriseId;
        if(!ent) return;

        getCredencials(ent)
        .then((response) => {
            const data = response.data.data;
            console.log(data);
        }).catch((error) => {
            console.log('error al obtener las credenciales', error)
        })
    }, [globalContext])
    

    const closeBadge = (e) => {
        e.preventDefault();
        setShowBadge(false);
    }

    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPass(!showPass)
    }

    const sendCred = async () => {
        const enterpriseId = globalContext.currentEnterpriseId
        if (!enterpriseId) return;

        const hasAnyError = validateDataBeforeSend([url, consumerKey, consumerSecret, version, enterpriseId]);
        if (hasAnyError) {
            await sendCredencials({url, consumerKey, consumerSecret, version, enterpriseId })
            .then((response) => {
                    setColorError(false);
                    setError('Registro exitoso');
                    setShowBadge(true);
                    setIsLoad(false);
            })
            .catch((error) => {
                setColorError(true);
                setError(`Error al enviar las credenciales, ${error.message}`);
                setShowBadge(true);
                setIsLoad(false);
            })
        } else {
            setError('Verifique que todos los datos son correctos o si le falta algun valor!')
            setShowBadge(true);
            setIsLoad(false)
        }
    }

    return (
    <>
            <PedidosYaStyle.ContBadgeError active={showBadge} error={colorError}>
                <PedidosYaStyle.Text size=".875rem" cl="#FFFFFF">
                    {error}
                </PedidosYaStyle.Text>
                <PedidosYaStyle.BtnToggleBadge onClick={(e) => closeBadge(e)}>
                    <BiX />
                </PedidosYaStyle.BtnToggleBadge>
            </PedidosYaStyle.ContBadgeError>
            <PedidosYaStyle.ContForm>
                <PedidosYaStyle.HeaderForm>
                    <PedidosYaStyle.Text size="14pt" fw="500" cl="#898989">
                        Ingrese los datos para registrar las credenciales de su tienda en woocommerce
                    </PedidosYaStyle.Text>
                </PedidosYaStyle.HeaderForm>
                <PedidosYaStyle.BodyForm>
                    <PedidosYaStyle.BodyFormGrid>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Clave del cliente 
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.ContIconNadInputPass>
                                <PedidosYaStyle.Input type={showPass ? 'text' : 'password'} placeholder="Escriba aqui su clave del cliente" onChange={(e) => setConsumerKey(e.target.value)}/>
                                <PedidosYaStyle.BtnShowPass onClick={(e) => toggleShowPassword(e)}>
                                    {
                                        showPass ? (
                                            <BiHide />
                                        ) : (
                                            <BiShow />
                                        )
                                    }
                                </PedidosYaStyle.BtnShowPass>
                            </PedidosYaStyle.ContIconNadInputPass>
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Clave secreta de cliente 
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.ContIconNadInputPass>
                                <PedidosYaStyle.Input type={showPass ? 'text' : 'password'} placeholder="Escriba aqui su clave secreta de cliente" onChange={(e) => setConsumerSecret(e.target.value)} />
                                <PedidosYaStyle.BtnShowPass onClick={(e) => toggleShowPassword(e)}>
                                    {
                                        showPass ? (
                                            <BiHide />
                                        ) : (
                                            <BiShow />
                                        )
                                    }
                                </PedidosYaStyle.BtnShowPass>
                            </PedidosYaStyle.ContIconNadInputPass>
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Url de la pagina
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input placeholder="Escriba aqui el puerto de acceso" onChange={(e) => setUrl(e.target.value)} />
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Vesion del API
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input placeholder="Escriba aqui el host de acceso" onChange={(e) => setVersion(e.target.value)} />
                        </PedidosYaStyle.ContInputAndLabel>
                    </PedidosYaStyle.BodyFormGrid>
                </PedidosYaStyle.BodyForm>
                <PedidosYaStyle.FooterForm>
                    <PedidosYaStyle.BtnSend disabled={isLoad} onClick={sendCred}>
                        {isLoad ? 'Enviando' : 'Guardar'}
                    </PedidosYaStyle.BtnSend>
                </PedidosYaStyle.FooterForm>
            </PedidosYaStyle.ContForm>
        </>
  )
}
