import React from 'react'
import { ItemToSend, ListItemsToSend, Text } from '../../../../../../../assets/css/components/admin/marketplace-to-marketplace/modals/ModalSelectStoreAndSection'

export default function Success({info}) {
    console.log('la info', info);
    return (
        <ListItemsToSend>
            {info.data.length > 0 && 
                info.data.map((v,i) => {
                    return (
                        <ItemToSend key={i}>
                            <Text size="12pt">
                                {`${v.message} ${v.barCode}`}
                            </Text>
                        </ItemToSend>
                    )
                })}
        </ListItemsToSend>
    )
}
