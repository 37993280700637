import React, { useContext, useEffect, useState } from "react";
import { BiError, BiInfoCircle, BiPlay } from "react-icons/bi";
import UpdateAndPublish from "../../../../assets/css/components/admin/update-and-publish/UpdateAndPublis";
import { keyAccessToken, keyUserId } from "../../../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import MenuCard from "./MenuCard";
import axios from 'axios'
import getPublishingPrice from "../functions/getPublishingPrice";
import funcAdditem from "../functions/funcAdditem";
import isChecked from "../functions/isChecked";
import { MdSystemUpdateAlt } from "react-icons/md";

export default function CardProduct(props) {
    const globalContext = useContext(GlobalContext);
    const [defaultIndex, setDefaultindex] = useState(0);
    const [showSubContent, setSubContent] = useState(1);
    const [data, setData] = useState(null);
    const [mlBrandName, setMlBrandName] = useState(null);
    const [dataPrice, setDataPrice] = useState(null)
    const { 
            dataCard, isInclude, brands, dataTarif, numberFormat, 
            isIncludeErrors, setProductsSelectedsWithErrors, setProductsSelecteds, dataCardAll, setDataCard
    } = props;

    useEffect(() => {
        if (!dataCard) return;
        if (dataCard.variants.length > 0) {
            setData(dataCard.variants[defaultIndex])
        } else {
            setData(dataCard)
        }
    }, [dataCard, defaultIndex]);

    useEffect(() => {
        let name = "";
        if(!data) return;
        const ml = data.ml;
        if (!ml) return
        if (brands.length > 0) {
            const brand = brands.filter((b) => b.id === ml.attrib.official_store_id);
            if (brand.length > 0) {
                name = brand[0].name;
            }
        }
        setMlBrandName(name);
    }, [brands, data]);

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if(!entId) return;
        if(!dataCard) return;
        if(!data) return;
        if(!dataTarif) return;
        getPublishingPrice(dataCard._id, dataTarif, entId)
        .then(res => {
            let dataCopy = [...dataCardAll];
            const indexOne = dataCopy.findIndex(v => v._id === dataCard._id);
            const dataPrice = res.data.data;
            if (!dataPrice.variants) {
                setDataPrice(dataPrice);
                dataCopy[indexOne].dataPrice = dataPrice;
                setDataCard(dataCopy);
            } else {
                const dataPriceVariant = dataPrice.variants.find(v => v.asin === data.asin);
                setDataPrice(dataPriceVariant)
                for(let dataPriceVariants of dataPrice.variants) {
                    const indexTwo =  dataCopy[indexOne].variants.findIndex(v => v.asin === dataPriceVariants.asin);
                    dataCopy[indexOne].variants[indexTwo].dataprice = dataPriceVariant;
                }
                setDataCard(dataCopy)
            }
    
        }).catch(err => {
            console.log('no se pudo obtener el precio', err)
        })
    },[dataCard, data, dataTarif, globalContext.currentEnterpriseId]);

    const renderErrorsProducts = (dataML, hasError) => {
        if(hasError) {
            return (
                <UpdateAndPublish.ContIconInfo cl="rgba(234, 84, 85, 0.1)" cli="#ea5455">
                    <BiInfoCircle />
                </UpdateAndPublish.ContIconInfo>
            )
        }
        else if(!dataML) {
            return (
                <UpdateAndPublish.ContIconInfo cl="rgba(234, 84, 85, 0.1)" cli="#ea5455">
                    <BiError />
                </UpdateAndPublish.ContIconInfo>
            )
        }
        else {
            if (dataML.attrib.status === 'paused') {
                return (
                    <UpdateAndPublish.ContIconInfo cl="rgba(234, 84, 85, 0.1)" cli="#ea5455">
                        <BiPlay />
                    </UpdateAndPublish.ContIconInfo>
                )
            }
        }
    }

    console.log('el array de ,os errorree', isIncludeErrors)
    return (
        <UpdateAndPublish.CardProduct>
            <UpdateAndPublish.CardProductHeader>
                <UpdateAndPublish.ContIconsInfo>
                    {data && renderErrorsProducts(data.ml, dataCard.hasError)}
                    {data && data.itHasScraping && (
                        <UpdateAndPublish.ContIconInfo cl="rgba(40, 199, 111, 0.1)" cli="#28c76f">
                            <MdSystemUpdateAlt  />
                        </UpdateAndPublish.ContIconInfo>
                    )}
                </UpdateAndPublish.ContIconsInfo>
                
                <UpdateAndPublish.ContInputChecbox>
                    <input
                        className="inp-cbx"
                        style={{ display: "none" }}
                        id={`cbx-${dataCard._id}`}
                        type="checkbox"
                        onChange={
                            () => funcAdditem(dataCard, setProductsSelectedsWithErrors, setProductsSelecteds, isInclude, isIncludeErrors)
                        }
                        checked={isChecked(dataCard,isInclude,isIncludeErrors)}
                    />
                    <label className="cbx" htmlFor={`cbx-${dataCard._id}`}>
                        <span>
                            <svg width="12px" height="10px" viewBox="0 0 12 10">
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </svg>
                        </span>
                    </label>
                </UpdateAndPublish.ContInputChecbox>
                
            </UpdateAndPublish.CardProductHeader>
            <UpdateAndPublish.CardProductBody>
                <UpdateAndPublish.CardProductImgBody url={data && data.images[0]} />
                <UpdateAndPublish.CardProductTextBody>
                    {
                        data &&
                        (
                            <MenuCard
                                showSubContent={showSubContent}
                                mlBrandName={mlBrandName}
                                dataCard={data}
                                variants={dataCard.variants.length > 0 ? dataCard.variants : []}
                                setDefaultindex={setDefaultindex}
                                dataPrice={dataPrice}
                                numberFormat={numberFormat}
                            />
                        )
                    }
                </UpdateAndPublish.CardProductTextBody>
            </UpdateAndPublish.CardProductBody>

            <UpdateAndPublish.CardProductFooter>
                <UpdateAndPublish.NavMarcketplace>
                    <UpdateAndPublish.NavMarcketplaceItem
                        onClick={() => setSubContent(1)}
                    >
                        <UpdateAndPublish.Text>
                            Específicos
                        </UpdateAndPublish.Text>
                        <UpdateAndPublish.NavMarcketplaceSpan
                            isActive={showSubContent === 1}
                        />
                    </UpdateAndPublish.NavMarcketplaceItem>
                    <UpdateAndPublish.NavMarcketplaceItem
                        onClick={() => setSubContent(2)}
                    >
                        <UpdateAndPublish.Text>
                            Precios
                        </UpdateAndPublish.Text>
                        <UpdateAndPublish.NavMarcketplaceSpan
                            isActive={showSubContent === 2}
                        />
                    </UpdateAndPublish.NavMarcketplaceItem>
                    <UpdateAndPublish.NavMarcketplaceItem
                        onClick={() => setSubContent(3)}
                    >
                        <UpdateAndPublish.Text>
                            Caracteristicas
                        </UpdateAndPublish.Text>
                        <UpdateAndPublish.NavMarcketplaceSpan
                            isActive={showSubContent === 3}
                        />
                    </UpdateAndPublish.NavMarcketplaceItem>
                    <UpdateAndPublish.NavMarcketplaceItem
                        onClick={() => setSubContent(4)}
                    >
                        <UpdateAndPublish.Text>
                            Detalles
                        </UpdateAndPublish.Text>
                        <UpdateAndPublish.NavMarcketplaceSpan
                            isActive={showSubContent === 4}
                        />
                    </UpdateAndPublish.NavMarcketplaceItem>
                    {
                        dataCard.variants.length > 0 &&
                        (
                            <UpdateAndPublish.NavMarcketplaceItem
                                onClick={() => setSubContent(5)}
                            >
                                <UpdateAndPublish.Text>
                                    Variantes
                                </UpdateAndPublish.Text>
                                <UpdateAndPublish.NavMarcketplaceSpan
                                    isActive={showSubContent === 5}
                                />
                            </UpdateAndPublish.NavMarcketplaceItem>
                        )
                    }
                </UpdateAndPublish.NavMarcketplace>
            </UpdateAndPublish.CardProductFooter>
        </UpdateAndPublish.CardProduct>
    );
}
