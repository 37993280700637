import React, { useContext, useEffect, useState } from 'react'
import UpdateAndPublish from '../../../../assets/css/components/admin/update-and-publish/UpdateAndPublis';
import { keyAccessToken, keyUserId, produtsUpdateAndPublish } from '../../../../assets/js/SessionStorageNames';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import CardProduct from '../components/CardProduct';
import axios from 'axios';
import CardStadistic from '../components/CardStadistic';
import { ProgressBar } from 'react-bootstrap'
import ButonActions from '../components/ButonActions';
import handleAxiosError from '../functions/handleAxiosError';
import renderCards from '../functions/renderCards';
import getValues from '../functions/getValues';
import getTemplateDesc from '../functions/getTemplateDesc';
import { Redirect } from "react-router-dom";
import verifyAccessRole from '../../../../assets/js/verifyAccessRole';
import Routes from '../../../../Routes';
export default function ListOfProducts() {
    const [redirect, setRedirect] = useState(null);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [dataIdsProducts, setDataIdsProducts] = useState(null);
    const [isLoad, setIsLoad] = useState(true);
    const [dataCard, setDataCard] = useState([]);
    const [totalGetProducts, setTotalGetProducts] = useState(0);
    const [totalGetVariants, setTotalVariants] = useState(0);
    const [totalScraped, setTotalScraped] = useState(0);
    const [totalUpdated, setTotalUpdated] = useState(0);
    const [totalUpdatedVariants, setTotalUpdatedVariants] = useState(0);
    const [totalPaused, setTotalPaused] = useState(0);
    const [totalPausedVariants, setTotalPausedVariants] = useState(0);
    const [totalWithError, setTotalWithError] = useState(0);
    const [totalVariantWithError, setTotalVariantWithError] = useState(0);
    const [percent, setPercent] = useState(0)
    const [productSelecteds, setProductsSelecteds] = useState([]);
    const [productSelectedsWithErrors, setProductsSelectedsWithErrors] = useState([]);
    const [brands, setBrands] = useState([]);
    const [dataTarif, setDataTarif] = useState(null);
    const [isLoader, setIsLoader] = useState(true);
    const [deliveryDaysPlusValue, setDeliveryDaysPlusValue] = useState(0)
    const globalContext = useContext(GlobalContext);
    const [templateDesc, setTemplateDesc] = useState('');
    const numberFormat = new Intl.NumberFormat('de-DE');

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 36);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/ml-brand/${entId}`,
            {
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            }
        ).then((resp) => {
            const brands = resp.data.data.brands;
            setBrands(brands);
        }).catch((err) => {
            if (axios.isCancel(err)) return; //Don't show axios cancel error
            console.error(err, err.response);
        });
    }, [globalContext.currentEnterpriseId]);

    useEffect(() => {
        if (localStorage.getItem(produtsUpdateAndPublish)) {
            setDataIdsProducts(localStorage.getItem(produtsUpdateAndPublish).split(","))
        }
    }, [localStorage.getItem(produtsUpdateAndPublish)]);

    const updatePercentState = (totalProducts, actualProduct) => {
        return (actualProduct * 100) / totalProducts;
    }

    // /**
    //  * Cancel axios requests before unmount
    //  */
    // useEffect(() => {
    //     return () => {
    //         localStorage.setItem(produtsUpdateAndPublish, null)
    //     };
    //     //eslint-disable-next-line
    // }, []);

    //ya esxistiendo la data en el session storage procedemos a setear el state
        //ya esxistiendo la data en el session storage procedemos a setear el state
        useEffect(() => {
            const entId = globalContext.currentEnterpriseId;
            if (!entId) return;
                getTemplateDesc(entId, axiosCancelTokenSource)
                .then(res => {
                    const data = res.data.data.configData.descriptionTemplate;
                    setTemplateDesc(data);
                }).catch(err => handleAxiosError(err, 'Presentamos un error 680', globalContext))
        }, [globalContext.currentEnterpriseId])

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if(templateDesc.length === 0) return;
        if (dataIdsProducts) {
            getTemplateDesc(entId, axiosCancelTokenSource)
            .then(res => {
                const data = res.data.data.configData.descriptionTemplate;
                setTemplateDesc(data);
            }).catch(err => handleAxiosError(err, 'Presentamos un error 680', globalContext))

            renderCards(
                    entId, dataIdsProducts, 
                    axiosCancelTokenSource, setDataCard, 
                    setTotalVariants, setTotalGetProducts, 
                    setTotalWithError, setTotalVariantWithError, 
                    setTotalPaused, setTotalPausedVariants, setPercent, 
                    updatePercentState);

            getValues(entId)
                .then(res => {
                    //console.log('valores de confi',res.data.data.configData);
                    setDeliveryDaysPlusValue(res.data.data.configData.deliveryDaysPlus)
                    const data = res.data.data.configData.publishPriceCalculation;
                    setDataTarif(data);
                })
                .catch(err => {
                    handleAxiosError(err, 'Presentamos un error 598', globalContext)
                })
        }
    }, [dataIdsProducts, templateDesc, globalContext.currentEnterpriseId])

    useEffect(() => {
        if(percent === 100) {
            setTimeout(() => {
                setIsLoader(false);
            }, 1000)
        }
    }, [percent])

    //console.log('data de la card', dataCard);

    return (
        <UpdateAndPublish.Cont>
            {redirect}
            <UpdateAndPublish.Header>
                <UpdateAndPublish.Text size="15pt" fw="600">
                    Listado de productos para actualizar
                </UpdateAndPublish.Text>
            </UpdateAndPublish.Header>

            <UpdateAndPublish.ContHeaderProgressBar hiddenBar={percent === 100}>
                <UpdateAndPublish.Text size="13pt" fw="400">
                    Obteniendo datos de los productos {Math.ceil(percent)}%
                </UpdateAndPublish.Text>
                <ProgressBar striped={percent < 100} now={percent} />
            </UpdateAndPublish.ContHeaderProgressBar>

            {
                dataIdsProducts && (
                    <>
                        {percent === 100 &&
                            (
                                <UpdateAndPublish.ContHeaderGridActions>
                                    {
                                        productSelecteds.length === 0 && productSelectedsWithErrors.length === 0 ? (
                                            <>
                                                <ButonActions
                                                    tipoAction="7"
                                                    setProductsSelectedsWithErrors={setProductsSelectedsWithErrors}
                                                    dataCard={dataCard}
                                                    isLoader={isLoader}
                                                    setIsLoader={setIsLoader}
                                                    deliveryDaysPlusValue={deliveryDaysPlusValue}
                                                />
                                                <ButonActions
                                                    tipoAction="8"
                                                    setProductsSelecteds={setProductsSelecteds}
                                                    dataCard={dataCard}
                                                    entId={globalContext.currentEnterpriseId}
                                                    isLoader={isLoader}
                                                    setIsLoader={setIsLoader}
                                                    deliveryDaysPlusValue={deliveryDaysPlusValue}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <ButonActions
                                                    tipoAction="9"
                                                    setProductsSelecteds={setProductsSelecteds}
                                                    setProductsSelectedsWithErrors={setProductsSelectedsWithErrors}
                                                    isLoader={isLoader}
                                                    setIsLoader={setIsLoader}
                                                    deliveryDaysPlusValue={deliveryDaysPlusValue}
                                                />
                                                <ButonActions
                                                    tipoAction="2"
                                                    dataCard={dataCard}
                                                    productSelecteds={productSelecteds}
                                                    productSelectedsWithErrors={productSelectedsWithErrors}
                                                    setDataCard={setDataCard}
                                                    setTotalScraped={setTotalScraped}
                                                    totalScraped={totalScraped}
                                                    isLoader={isLoader}
                                                    setIsLoader={setIsLoader}
                                                    deliveryDaysPlusValue={deliveryDaysPlusValue}
                                                />
                                                <ButonActions 
                                                tipoAction="3" 
                                                setTotalUpdated={setTotalUpdated}
                                                setTotalUpdatedVariants={setTotalUpdatedVariants}
                                                productSelecteds={productSelecteds}
                                                productSelectedsWithErrors={productSelectedsWithErrors}
                                                dataCard={dataCard}
                                                setDataCard={setDataCard}
                                                isLoader={isLoader}
                                                setIsLoader={setIsLoader}
                                                deliveryDaysPlusValue={deliveryDaysPlusValue}
                                                />
                                                <ButonActions 
                                                    tipoAction="5" 
                                                    isLoader={isLoader}
                                                    setTotalUpdated={setTotalUpdated}
                                                    setTotalUpdatedVariants={setTotalUpdatedVariants}
                                                    setIsLoader={setIsLoader}
                                                    productSelecteds={productSelecteds}
                                                    productSelectedsWithErrors={productSelectedsWithErrors}
                                                    dataCard={dataCard}
                                                    setDataCard={setDataCard}
                                                    templateDesc={templateDesc}
                                                    deliveryDaysPlusValue={deliveryDaysPlusValue}
                                                    />
                                                <ButonActions
                                                    tipoAction="6"
                                                    productSelecteds={productSelecteds}
                                                    productSelectedsWithErrors={productSelectedsWithErrors}
                                                    dataCard={dataCard}
                                                    setDataCard={setDataCard}
                                                    setTotalPaused={setTotalPaused}
                                                    setTotalPausedVariants={setTotalPausedVariants}
                                                    isLoader={isLoader}
                                                    setIsLoader={setIsLoader}
                                                    deliveryDaysPlusValue={deliveryDaysPlusValue}
                                                />
                                                <ButonActions
                                                    tipoAction="10"
                                                    productSelecteds={productSelecteds}
                                                    productSelectedsWithErrors={productSelectedsWithErrors}
                                                    setTotalPaused={setTotalPaused}
                                                    setTotalPausedVariants={setTotalPausedVariants}
                                                    dataCard={dataCard}
                                                    setDataCard={setDataCard}
                                                    totalPaused={totalPaused}
                                                    totalPausedVariants={totalPausedVariants}
                                                    isLoader={isLoader}
                                                    setIsLoader={setIsLoader}
                                                    deliveryDaysPlusValue={deliveryDaysPlusValue}
                                                />
                                            </>

                                        )
                                    }
                                </UpdateAndPublish.ContHeaderGridActions>
                            )
                        }

                        <UpdateAndPublish.ContHeaderGridOptions>
                            <CardStadistic 
                                title="Total de Productos" 
                                valueNumber={`${totalGetProducts}/${dataIdsProducts.length}`} 
                                icon="1" isLoader={isLoader} 
                                />
                            <CardStadistic 
                                title="Total de Variantes" 
                                valueNumber={`${totalGetVariants}`} icon="7" 
                                isLoader={isLoader}
                                />
                            <CardStadistic 
                                title="Productos Pausados" 
                                valueNumber={`${totalPaused}/${dataIdsProducts.length}`} 
                                icon="6" isLoader={isLoader} 
                                />
                            <CardStadistic 
                                title="Variantes Pausados" valueNumber={`${totalPausedVariants}/${totalGetVariants}`} 
                                icon="6" isLoader={isLoader}
                                />
                            <CardStadistic 
                                title="Productos con Error" 
                                valueNumber={`${totalWithError}/${dataIdsProducts.length}`} 
                                icon="5" isLoader={isLoader}
                                />
                            <CardStadistic 
                                title="Variantes con Error" valueNumber={`${totalVariantWithError}/${totalGetVariants}`} 
                                icon="5" isLoader={isLoader} />
                            <CardStadistic 
                                title="Seleccionados sin Error" 
                                valueNumber={`${productSelecteds.length}`} icon="2" isLoader={isLoader} 
                                />
                            <CardStadistic 
                                title="Seleccionados con Error" 
                                valueNumber={`${productSelectedsWithErrors.length}`} icon="2" isLoader={isLoader}
                                />
                            {
                                totalScraped > 0 && (
                                    <CardStadistic 
                                        title="Scrapeados" 
                                        valueNumber={`${totalScraped}/${dataIdsProducts.length}`} icon="3" 
                                        isLoader={isLoader}
                                        />
                                )}
                            {
                                totalUpdated > 0 && (
                                    <CardStadistic 
                                        title="P. Actualizados" 
                                        valueNumber={`${totalUpdated}`}
                                        isLoader={isLoader}
                                        icon="4" />
                                    )
                            }
                            {
                                totalUpdatedVariants > 0 && (
                                    <CardStadistic 
                                        title="V. Actualizados" 
                                        valueNumber={`${totalUpdatedVariants}`}
                                        isLoader={isLoader}
                                        icon="4" />
                                )
                            }

                        </UpdateAndPublish.ContHeaderGridOptions>
                    </>
                )
            }
            <UpdateAndPublish.ContGrid>
                {
                    (dataCard.length > 0) &&
                    dataCard.map((v) => {
                        return (
                            <CardProduct
                                brands={brands}
                                key={v._id}
                                dataCard={v}
                                isInclude={productSelecteds}
                                isIncludeErrors={productSelectedsWithErrors}
                                dataTarif={dataTarif}
                                numberFormat={numberFormat}
                                setProductsSelectedsWithErrors={setProductsSelectedsWithErrors}
                                setProductsSelecteds={setProductsSelecteds}
                                dataCardAll={dataCard}
                                setDataCard={setDataCard}
                            />
                        )
                    }
                    )
                }

            </UpdateAndPublish.ContGrid>
        </UpdateAndPublish.Cont>
    )
}
