const validateDataBeforeSend = (dataValues) => {
    for (const key in dataValues) {
        if(!dataValues[key]) {
            return false;
        }else {
            continue;
        }
    }
    return true;
}

export default validateDataBeforeSend;