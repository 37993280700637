import React, { useContext, useRef, useState } from 'react'
import GridStyles from '../../../../../assets/css/components/admin/products/Orders/GridStyles'
import { GlobalContext } from '../../../../../contexts/GlobalContext'
import Select from '../../../../general/Select';
import DateTime from './DateTime';

export default function FiltersGrid() {
    const globalContext = useContext(GlobalContext);
    const [date, setDate] = useState(null);
    const [status, setStatus] = useState(null);
    const [orderId, setOrderId] = useState(null)
    const [filterClean, setFilterClean] = useState(false);
    const inputOrderId = useRef(null)
    const statusValues = [
        { id: 'paid', name: 'Pedido de venta' },
        { id: 'confirmed', name: 'Orden pagada' },
        { id: 'bought', name: 'Comprado' },
        { id: 'bought_delayed', name: 'Comprado en demora' },
        { id: 'received_warehouse_int', name: 'Recibido bodega miami' },
        { id: 'transit_int', name: 'En transito internacional' },
        { id: 'customs_received', name: 'Recibido aduana' },
        { id: 'received_warehouse_local', name: 'Recivido bodega venezuela' },
        { id: 'transit_local', name: 'Transito cliente' },
        { id: 'delivered', name: 'Producto entregado cliente' },
        { id: 'lost', name: 'Perdido' },
        { id: 'canceled', name: 'Orden cancelada' },
        { id: 'defective', name: 'Orden defectuosa' },
        { id: 'return_provider', name: 'Devolucion a provedor' },
        { id: 'return_store', name: 'Devolucion a tienda' },
        { id: 'other', name: 'Otros' }
    ];

    const apliChange = async () => {
        if(status) await globalContext.setStatusFilterOrder(status)
        if(date) await globalContext.setDateFilterOrder(date)
        if(orderId) await globalContext.setOrderIdFilterOrder(orderId);
        globalContext.setModalFilterOrdes(false)
    }

    const clearFilters = () => {
        //console.log('el valor de filterClean', filterClean);
        setFilterClean(true)
        globalContext.setStatusFilterOrder(null);
        globalContext.setDateFilterOrder(null);
        globalContext.setOrderIdFilterOrder(null);
        inputOrderId.current.value = '';
        setDate(null);
        setStatus(null);
        setOrderId(null);
        //setFilterClean(false);
    }

    return (
        <GridStyles.BackgroundModal active={globalContext.modalFilterOrdes}>
            <GridStyles.ContModal active={globalContext.modalFilterOrdes}>
                <GridStyles.contInputAndLabel>
                    <GridStyles.text size="12pt" fw="500">
                        Fecha de creación
                    </GridStyles.text>
                    <DateTime 
                        setValue={setDate} 
                        valueDefaultText={globalContext.dateFilterOrder} 
                        filterClean={filterClean}
                        setFilterClean={setFilterClean}
                        />
                </GridStyles.contInputAndLabel>
                <GridStyles.contInputAndLabel>
                    <GridStyles.text size="12pt" fw="500">
                        Id de la Orden de ML
                    </GridStyles.text>
                    <GridStyles.input
                        ref={inputOrderId}
                        placeholder='Ejm: 393930443'
                        onChange={(e) => setOrderId(e.target.value)}
                        defaultValue={globalContext.orderIdFilterOrder}
                        />
                </GridStyles.contInputAndLabel>
                <GridStyles.contInputAndLabel>
                    <GridStyles.text size="12pt" fw="500">
                        Estatus de la Orden
                    </GridStyles.text>
                    <Select
                        StatusDropDown={statusValues}
                        idOption={globalContext.statusFilterOrder}
                        setIdOption={setStatus}
                    />
                </GridStyles.contInputAndLabel>
                <GridStyles.ContFooterModal>
                    <GridStyles.BtnClose onClick={() => globalContext.setModalFilterOrdes(false)}>
                        Cerrar
                    </GridStyles.BtnClose>
                    {
                        (
                            globalContext.dateFilterOrder || globalContext.statusFilterOrder || globalContext.orderIdFilterOrder || date || status || orderId
                        ) && 
                        (
                        <GridStyles.BtnClose onClick={() => clearFilters()}>
                            Limpiar Filtros
                        </GridStyles.BtnClose>
                        )
                        }
                    <GridStyles.btnSave onClick={() => apliChange()}>
                        Aplicar
                    </GridStyles.btnSave>
                </GridStyles.ContFooterModal>
            </GridStyles.ContModal>
        </GridStyles.BackgroundModal>
    )
}
