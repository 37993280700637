import React, { useContext, useState, useEffect, useRef } from 'react'
import { BiPlus, BiRotateRight, BiSend, BiX } from 'react-icons/bi'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle'
import { Spinner } from 'react-bootstrap';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import linkRestaurantsList from '../functions/linkRestaurantsList';
import sendListRestaurants from '../functions/sendListRestaurants';


export default function FormLinkedSucursal() {
    const globalContext = useContext(GlobalContext);
    const [listRestaurants, setlistRestaurants] = useState([]);
    const [restaurant, setRestaurant] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [messageSucces, setMessageSucces] = useState('');
    const refInputIdRestaurant = useRef(null);

    const sendDataIds = () => {
        if (!globalContext.linkRestaurants) return;
        const entId = globalContext.currentEnterpriseId
        if (!entId) return;
        setIsLoading(true)
        if(listRestaurants.length < 1) return;
        sendListRestaurants(listRestaurants.toString(), entId)
            .then((res) => {
                //const datRes = res.data.data;
                setMessageSucces('la lista de restaurantes se sincronizo con exito')
                setIsLoading(false)
            })
            .catch(err => {
                console.log('el error res', err)
            })
    }

    const linkSectionsByRestaurant = () => {
        if (isSending) return;
        const entId = globalContext.currentEnterpriseId;
        if (!restaurant) return;
        if (!entId) return;
        setIsSending(true)
        setIsLoading(true);
        linkRestaurantsList(entId)
            .then(() => {
                setMessageSucces('Los restaurantes se sincronizaron con exito!')
                setIsSending(false);
                setIsLoading(false);
            }).catch(err => {
                setIsSending(false);
                setIsLoading(false);
                setMessageSucces('Los restaurantes no se pudieron sincronizar con exito!')
            })
    }

    useEffect(() => {
        if (!globalContext.linkRestaurants) {
            setlistRestaurants([]);
            setRestaurant(null);
            setIsLoading(false);
            setIsSending(false);
            setMessageSucces('');

        }
    }, [globalContext.linkRestaurants]);

    const setIdRestaurant = (e) => {
        const invalidChars = [
            "-",
            "+",
            "e",
        ];
        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        } else {
            setRestaurant(e.target.value);
        }
    }

    const addOneId = (e) => {
        e.preventDefault();
        if (!restaurant) return;
        let copyArr = [...listRestaurants];
        //? buscamos el valor para verificar q no este repitido
        const existId = copyArr.find(v => v === restaurant);
        if (!existId) {
            //? lo agg
            copyArr.push(restaurant);
            setlistRestaurants(copyArr);
            refInputIdRestaurant.current.value = '';
            setRestaurant(null)
        }
    }

    const removeOne = (valorB) => {
        let copyArr = [...listRestaurants];
        const existId = copyArr.filter(valorA => valorA !== valorB);
        setlistRestaurants(existId);
    }


    return (
        <PedidosYaStyle.BackgroundModal active={globalContext.linkRestaurants}>
            <PedidosYaStyle.contModal w="400px" active={globalContext.linkRestaurants}>
                <PedidosYaStyle.HeaderModal>
                    <PedidosYaStyle.Text size="14pt" fw="500">
                        Sincronizacion de Restaurantes
                    </PedidosYaStyle.Text>
                    <PedidosYaStyle.BtnCloseModal onClick={() => globalContext.setLinkSucursal(false)}>
                        <BiX />
                    </PedidosYaStyle.BtnCloseModal>
                </PedidosYaStyle.HeaderModal>
                <PedidosYaStyle.BodyModal>
                    {
                        !isLoading ?
                            messageSucces.length > 0 ?
                                (
                                    <PedidosYaStyle.ContInputAndLabel w="100%">
                                        <PedidosYaStyle.Text size="14pt" fw="400">
                                            {messageSucces}
                                        </PedidosYaStyle.Text>
                                    </PedidosYaStyle.ContInputAndLabel>
                                ) :
                                (
                                    <form onSubmit={e => addOneId(e)}>
                                        <PedidosYaStyle.ContInputAndLabel w="100%">
                                            <PedidosYaStyle.Text size="13pt" fw="500">
                                                Id del Restaurante a agregar
                                            </PedidosYaStyle.Text>
                                            <PedidosYaStyle.ContInputBtn>
                                                <PedidosYaStyle.Input
                                                    placeholder="Id del Restaurant"
                                                    type="number"
                                                    onKeyDown={e => setIdRestaurant(e)}
                                                    ref={refInputIdRestaurant}
                                                />
                                                <PedidosYaStyle.BtnAdd>
                                                    <BiPlus />
                                                </PedidosYaStyle.BtnAdd>
                                            </PedidosYaStyle.ContInputBtn>
                                        </PedidosYaStyle.ContInputAndLabel>
                                        <PedidosYaStyle.ContListOptions>
                                            {
                                                listRestaurants.length > 0 &&
                                                listRestaurants.map((v) => {
                                                    return (
                                                        <PedidosYaStyle.ListItems key={v}>
                                                            <PedidosYaStyle.Text cl="#FFFFFF"
                                                                size="10pt">
                                                                {v}
                                                            </PedidosYaStyle.Text>
                                                            <PedidosYaStyle.CirculBtn
                                                                onClick={() => removeOne(v)}
                                                                right="0"
                                                                marginLeft="5px"
                                                                position="relative"
                                                                bColorActive="#FFFFFF"
                                                                fSize="10pt"
                                                                w="25px"
                                                                h="25px"
                                                                color="#FFFFFF"
                                                                bColor="#FFFFFF"
                                                                bgColor="transparent"
                                                                type="submit"
                                                            >
                                                                <BiX />
                                                            </PedidosYaStyle.CirculBtn>
                                                        </PedidosYaStyle.ListItems>
                                                    )
                                                })
                                            }
                                        </PedidosYaStyle.ContListOptions>
                                    </form>
                                ) :
                            (
                                <PedidosYaStyle.contLoader>
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Cargando...</span>
                                    </Spinner>
                                </PedidosYaStyle.contLoader>
                            )
                    }
                </PedidosYaStyle.BodyModal>
                <PedidosYaStyle.FooterModal>
                    {
                        messageSucces.length === 0 && (
                            <PedidosYaStyle.BtnAndIcon
                                disabled={listRestaurants.length < 1}
                                onClick={() => sendDataIds()}
                            >
                                {
                                    isSending ?
                                        (
                                            <PedidosYaStyle.ContIconBtnLoad>
                                                <BiRotateRight />
                                            </PedidosYaStyle.ContIconBtnLoad>
                                        ) :
                                        (
                                            <PedidosYaStyle.ContIconBtn
                                            >
                                                <BiSend />
                                            </PedidosYaStyle.ContIconBtn>
                                        )
                                }
                                <PedidosYaStyle.Text size="12pt" cl="#FFFFFF">
                                    {isSending ? 'Enviando..' : 'Procesar'}
                                </PedidosYaStyle.Text>
                            </PedidosYaStyle.BtnAndIcon>

                        )
                    }
                </PedidosYaStyle.FooterModal>
            </PedidosYaStyle.contModal>
        </PedidosYaStyle.BackgroundModal>
    )
}
