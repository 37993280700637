export const keyUserActiveEnterpriseId = "usuarioEnterprise";
export const keyUserId = "_id";
export const keyUserName = "name";
export const keyAccessToken = "accessTokenKey";
export const keyRefreshToken = "refreshTokenKey";
export const keyProductsListDropdownPlugin = "productsListDropdownPlugin";
export const keyUserRoles = 'userRoles';
export const userEmail = 'userEmail';
export const amazonCategory = 'amazonCategory';
export const amazonCategoryText = 'amazonCategoryText';
export const produtsUpdateAndPublish = 'produtsUpdateAndPublish';
export const userRole = 'userRole';
export const tokenAnicam = 'tokenAnicam';