/**
 * Get a variant description
 * @param {object} product The product
 * @param {string} plugin The product's plugin
 */
export function getVariantDescription(product, plugin) {
    let desc = undefined;

    if (product.attrib && product.attrib.varAttrib) {
        switch (plugin) {
            case "odoo":
                desc = product.attrib.varAttrib.join(', ');
                //console.log('desc ',desc)
                break;
        
            case "ml":
                desc = [];

                for (const vari of product.attrib.varAttrib) {
                    desc.push( `${vari.name} ${vari.value}` );
                }

                desc = desc.join(', ');
                break;
            case "ln":
                desc = product.attrib.variation+' '+product.attrib.sellerSku
                break;

            case "coco":
                desc = product.attrib.variation+' '+product.attrib.sku
                break;
            default:
                return
        }
    }

    return desc;
}

