import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router';
import AmazonStyles from '../../../../assets/css/components/admin/products/amazon/listProducts'
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
import verifyAccessRole from '../../../../assets/js/verifyAccessRole';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import Routes from '../../../../Routes';

export default function DetailProduct({ match }) {
    const id = match.params.id;
    const [showFormula, setShowFormula] = useState(false);
    const globalContext = useContext(GlobalContext);
    const [data, setProductData] = useState(null);
    const [variantsData, setVariantsData] = useState(null)
    const [imgP, setImgP] = useState('');
    const [imgActive, setImgActive] = useState(0);
    const [imgActiveTwo, setImgActiveTwo] = useState(0);
    const [isClothing, setIsClothing] = useState(null);
    const [showVariation, setShowVariation] = useState(null);
    const [imagesProduct, setImagesProduct] = useState([]);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [dataPrice, setDataPrice] = useState(null);
    const [redirectTo, setRedirectTo] = useState(null);
    const [mlData, setMlData] = useState(null);
    const [brands, setBrands] = useState([]);
    const [mlBrandName, setMlBrandName] = useState(null);
    const [idByPublish, setIdByPublish] = useState(null);
    const [idByLink, setIdByLink] = useState('');
    const [descriptionProduct, setDescriptionProduct] = useState('');
    const [asin, setAsin] = useState('');
    const numberFormat = new Intl.NumberFormat('de-DE');
    const [deliveryDayPlus, setDeliveryDayPlus] = useState(0);
    const [titleUpdate, settitleUpdate] = useState(null);
    const [heightProd, setHeightProd] = useState(0);
    const [widthProd, setwidthProd] = useState(0);
    const [lengthProd, setlengthProd] = useState(0);
    const [stockProd, setStockProd] = useState(0);
    const [volumetricWight, setVolumetricWight] = useState('')

    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    
    const getProduct = async (entId, id) => {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/products/${id}`, {
            params: {
                pageNumber: 1,
                pageItemCount: 1,
                filter: {
                    lang: 'es',
                },
            },
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }

    const sendUpdateTitle = (e) => {
        console.log('====================================');
        console.log('sepp');
        console.log('====================================');
        e.preventDefault();
        if(!data._id) return;
        globalContext.setLoaderPage(true)
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amaz-prod/fix-title/`, {
            prodId: data._id,
            prodTitle: titleUpdate,
            prodStock: parseInt(stockProd),
            prodLength: parseInt(lengthProd),
            prodHeight: parseInt(heightProd),
            prodWidth: parseInt(widthProd)
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then((res) => {
            console.log(res.data);
            globalContext.setLoaderPage(false);
        }).catch((error) => {
            handleAxiosError(error, 'Presentamos un error al actualizar el titulo');
            console.log(error); 
            globalContext.setLoaderPage(false);
        })
    }

    const getValues = async (entId) => {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }

    const updateMl = (price, stock, mlPrductId, description, manufacturingTime) => {
        globalContext.setLoaderPage(true)
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/${mlPrductId}`, {
            price: price,
            stock: stock,
            manufacturingTime: manufacturingTime + deliveryDayPlus
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
            .then(resp => {
                updateMlDescription(mlPrductId, description)
                    .then(resp => {
                        globalContext.showModalWithMsg("Producto actualizado con exito");
                        globalContext.setLoaderPage(false);
                        window.location.reload();
                    })
                    .catch((e) => {
                        handleAxiosError(e, 'El precio y el stock fueron actualizados pero no se logro actualizar la descripcion');
                        globalContext.setLoaderPage(false);
                    });
            })
            .catch((e) => { handleAxiosError(e, 'Presentamos un error 569'); globalContext.setLoaderPage(false); });
    }
    const getIdMl = async (entId, id) => {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/${id}`, {
            params: {
                variants: true,
                linked: false,
            },
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }
    const updateMlDescription = (mlPrductId, descriptionProduct) => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        const resp = axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/${mlPrductId}/description`, {
            body: descriptionProduct
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return resp
    }

    const getPublishingPrice = async (prodId, obj, entId) => {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/calculate-publish-price`, {
            prodId: prodId,
            config: {
                tariff: obj.tariff,
                earning: obj.earning,
                courierPlus: parseInt(obj.courierPlus),
                courierPlusIsPercent: obj.courierPlusIsPercent,
                poundPrice: parseInt(obj.poundPrice),
                additionalPoundPrice: parseInt(obj.additionalPoundPrice),
                safe: obj.safe,
                safeIsPercent: obj.safeIsPercent,
                ccTax: obj.ccTax,
                ccTaxIsPercent: obj.ccTaxIsPercent,
                defaultTariff: parseInt(obj.defaultTariff),
                defaultTariffIsPercent: obj.defaultTariffIsPercent,
                iva: parseInt(obj.iva),
                ivaIsPercent: true,
                defaultComission: obj.defaultComission,
                defaultComissionIsPercent: obj.defaultComissionIsPercent,
                countryCode: entId === "60944575461add5b1957ef8b" ? 'VE' : 'CO'
            }
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res
    }

    function productUrl(str, countrie, coLink) {
        let newstr = str.split('');
        const countrieValid = countrie.substr(0, 3);
        let urlML;
        const complemetoUrl = '-producto-sin-url-_JM#';
        if (countrieValid === 'MCO') {
            if(coLink) {
                let newLink = coLink.split('');
                newLink.splice(4, 0, 's');
                newLink.push('#');
                return newLink.join('')
            }else {
                return 'no link'
            }
        } else {
            urlML = 'https://articulo.mercadolibre.com.ve/';
            newstr.splice(3, 0, '-');
            const subFinalStr = newstr.join('');
            return `${urlML}${subFinalStr}${complemetoUrl}`;
        }
    }

    useEffect(() => {
        if (id.length === 0) return;
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        globalContext.setLoaderPage(true);
        getProduct(entId, id)
            .then((res) => {
                const data = res.data.data.product;
                if (data.variants.length > 0) {
                    setProductData(data.variants[0]);
                    setVariantsData(data.variants)
                    setShowVariation(data.variants[0].variations);
                    setImgP(data.variants[0].images[0]);
                    setImagesProduct(data.variants[0].images);
                    setAsin(data.variants[0].asin)
                    setIsClothing(data.variants[0].categoryTree.find(c => c.node === '7141123011'))
                    settitleUpdate(data.title ? data.title.es ? data.title.es : data.title.en ? data.title.en : 'NP' : 'NP')
                    data.variants[0].ml && setMlData(data.variants[0].ml);
                    if (data.variants[0].ml) {
                        getIdMl(entId, data.variants[0].ml._id)
                            .then(res => {
                                setIdByLink(res.data.data.attrib.id)
                            }).catch(err => console.log('el err del id', err))
                    }
                    setStockProd(data.variants[0].stock);
                    setIdByPublish(data._id);
                    setHeightProd(data.variants[0].dimensions.height)
                    setwidthProd(data.variants[0].dimensions.width)
                    setlengthProd(data.variants[0].dimensions['length'])
                } else {
                    setImgP(data.images[0])
                    setImagesProduct(data.images);
                    setProductData(data);
                    setIsClothing(data.categoryTree.find(c => c.node === '7141123011'))
                    setAsin(data.asin);
                    data.ml && setMlData(data.ml);
                    setIdByPublish(data._id);
                    settitleUpdate(data.title ? data.title.es ? data.title.es : data.title.en ? data.title.en : 'NP' : 'NP')
                    if (data.ml) {
                        getIdMl(entId, data.ml._id)
                            .then(res => {
                                setIdByLink(res.data.data.attrib.id)
                            }
                            ).catch(err => console.log('el err del id', err))
                    }
                    setStockProd(data.stock);
                    setHeightProd(data.dimensions.height)
                    setwidthProd(data.dimensions.width)
                    setlengthProd(data.dimensions['length'])
                }
                globalContext.setLoaderPage(false);
            }).catch((e) => { handleAxiosError(e, 'Presentamos un error 598'); globalContext.setLoaderPage(false); })

        getValues(entId).then(async resp => {
            console.log('valores de config', resp)
            const data = resp.data.data.configData.publishPriceCalculation;
            const deliveryDayPlus = resp.data.data.configData.deliveryDaysPlus;
            setDeliveryDayPlus(deliveryDayPlus);
            await getPublishingPrice(id, data, entId)
                .then((res) => {
                    const data = res.data.data
                    
                    setDataPrice(data);
                }).catch((e) => { handleAxiosError(e, 'Presentamos un error 598'); globalContext.setLoaderPage(false); })
        }).catch(err => {
            handleAxiosError(err)
            globalContext.setLoaderPage(false);
        });
    }, [id, globalContext.currentEnterpriseId])

    const redirectRangos = (asin) => {
        globalContext.setSkuByRang(asin);
        setRedirectTo(
            <Redirect to='/admin/rangos-publicacion' />
        )
    }

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 38);
        if (!hasAccess) {
            setRedirectTo(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    }, []);

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/ml-brand/${entId}`,
            {
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            }
        ).then((resp) => {
            const brands = resp.data.data.brands;
            setBrands(brands);
        }).catch((err) => {
            if (axios.isCancel(err)) return; //Don't show axios cancel error
            console.error(err, err.response);
        });
    }, [globalContext.currentEnterpriseId]);

    useEffect(() => {
        let name = "";
        if (mlData) {
            if (brands.length > 0) {
                const brand = brands.filter((b) => b.id === mlData.attrib.official_store_id);
                if (brand.length > 0) {
                    name = brand[0].name;
                }
            }
        }

        setMlBrandName(name);
    }, [brands, mlData]);

    const pauseMl = (idProduc) => {
        globalContext.setLoaderPage(true);
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/pause/${idProduc}`, {}, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => {
            globalContext.setLoaderPage(false);
            globalContext.showModalWithMsg(
                `Producto pausado exitosamente!`
            );
        }).catch(err => { handleAxiosError(err); globalContext.setLoaderPage(false); });
    }

    const activateMl = (idProduc) => {
        globalContext.setLoaderPage(true);
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/activate/${idProduc}`, {}, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => {
            globalContext.showModalWithMsg(
                `Producto activado exitosamente!`
            );
            globalContext.setLoaderPage(false);
        }).catch(err => { handleAxiosError(err); globalContext.setLoaderPage(false); });
    }

    const scarpingAgain = (url) => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if (!url) return;
        globalContext.setLoaderPage(true);
        const processCheckIntervalTime = 5000;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/parse-product`, {
            cancelToken: axiosCancelTokenSource.token,
            headers,
            params: {
                url,
                langEs: true,
                langEn: true,
            }
        })
            .then(resp => {
                const data = resp.data.data;
                if (data.monitor) {
                    //If the process ID was received, start monitoring
                    let waitingMonitorResponse = false;
                    const interval = setInterval(() => {
                        if (!waitingMonitorResponse) {
                            waitingMonitorResponse = true;
                            axios
                                .get(
                                    `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                                    {
                                        cancelToken: axiosCancelTokenSource.token,
                                        headers,
                                    }
                                )
                                .then((resp) => {
                                    const data = resp.data.data;

                                    if (data.advance >= 100) {
                                        clearInterval(interval);
                                        globalContext.showModalWithMsg(
                                            `Producto actualizado exitosamente!`
                                        );
                                        window.location.reload();
                                        globalContext.setLoaderPage(false);
                                    }
                                })
                                .catch((err) => {
                                    handleAxiosError(
                                        err,
                                        "Disculpe, no se pudo culminar la descarga del producto."
                                    );
                                    clearInterval(interval);
                                    globalContext.setLoaderPage(false);
                                })
                                .finally(() => {
                                    waitingMonitorResponse = false;
                                });
                        }
                    }, processCheckIntervalTime);

                }
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, ocurrio un error al actualizar la informacion."
                );
                globalContext.setLoaderPage(false);
            });
    }

    // * get product description
    const getDescriptionProduct = (entId) => {

        const res = axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        )
        return res
    }
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (data) {
            getDescriptionProduct(entId)
                .then(resp => {
                    let dataReplace = resp.data.data.configData.descriptionTemplate;
                    // * este objeto es el que remplazara la etiquetas
                    //console.log(data)
                    const mapObjToReplace = {
                        titulo: data.title.es || data.title.es + '\n',
                        titulo_en: data.title.en + '\n',
                        d_tecnicos: (data.details) ?
                            data.details.productDetails ?
                            data.details.productDetails.length > 0 ?
                                data.details.productDetails.map(v =>
                                    `- ${v.name['es'] || v.name['en']} ${v.value['es'] || v.value['en']} '\n'`
                                ) : '' : '' : ''
                        ,

                        informacion_adicional: data.details ? 
                        data.details.additionalInfo ?
                            data.details.additionalInfo > 0 ?
                                data.details.additionalInfo.technicalDetails ?
                                    data.details.additionalInfo.technicalDetails.length > 0 ?
                                        data.details.additionalInfo.technicalDetails.map(v =>
                                            `- ${v.name['es'] || v.name['en']} ${v.value['es'] || v.value['en']} '\n'`
                                        ) : '' : '' : '' : '' : '',

                        caracteristicas: data.features.length > 0 ?
                            data.features.map(v =>
                                `- ${v['es'] || v['en']} '\n'`
                            )
                            : '',

                        especificaciones_producto: data.details ? data.details.productSpecifications ?
                            data.details.productSpecifications > 0 ?
                                data.details.productSpecifications.map(v =>
                                    `- ${v.name['es'] || v.name['en']} ${v.value['es'] || v.value['es']} '\n'`
                                ) : "" : '' : "",

                        sku: data.asin + '\n',
                        descripcion: data.desc.es || data.desc.en,
                        stock: data.stock ? data.stock : '',
                        dias_entrega: data.deliveryDays ? data.deliveryDays : '',
                        variations: data.variations ? data.variations : ''
                    }
                    /**
                     * ? remplazamos el lugar de las etiqutas por sus valores
                     */
                    dataReplace = dataReplace.replace('@titulo', mapObjToReplace.titulo);
                    dataReplace = dataReplace.replace('@titulo_en_ingles', mapObjToReplace.titulo_en);
                    dataReplace = dataReplace.replace('@detalles_tecnicos', mapObjToReplace.d_tecnicos);
                    dataReplace = dataReplace.replace('@caracteristicas', mapObjToReplace.caracteristicas);
                    dataReplace = dataReplace.replace('@sku', mapObjToReplace.sku);
                    dataReplace = dataReplace.replace('@descripcion', mapObjToReplace.descripcion);
                    dataReplace = dataReplace.replace('@informacion_adicional', mapObjToReplace.informacion_adicional);
                    dataReplace = dataReplace.replace('@especificaciones_producto', mapObjToReplace.especificaciones_producto);
                    dataReplace = dataReplace.replace('@stock', mapObjToReplace.stock);
                    dataReplace = dataReplace.replace('@tiempo_envio', mapObjToReplace.dias_entrega);
                    dataReplace = dataReplace.replace('@variations', mapObjToReplace.variations);
                    setDescriptionProduct(dataReplace)
                }).catch((err) =>
                    handleAxiosError(
                        err,
                        "Ocurrio un Error al cargar la plantilla de publicacion"
                    )
                );
        }
    }, [data, globalContext.currentEnterpriseId]);

    //? validar cuando las dimensiones cambian
    useEffect(() => {
    setVolumetricWight(`${(((parseFloat(heightProd) * parseFloat(widthProd) * parseFloat(lengthProd)) * 1.2) / 166).toString().slice(0, 5)} (LBS) `);

    }, [heightProd, lengthProd, widthProd])
    

    return (
        <AmazonStyles.Contenedor>
            {redirectTo}
            <AmazonStyles.breadCrumbs>
                <AmazonStyles.btnBack onClick={() => window.history.back()}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                </AmazonStyles.btnBack>
                <p className="h4 texto-n" style={{ color: "#707070", marginBottom: '0px' }}>Detalles del producto</p>
            </AmazonStyles.breadCrumbs>
            {
                data && (
                    <AmazonStyles.cardProduct>
                        <div className="product-cont">
                            <div className="item-photo">
                                <AmazonStyles.contImgCardModal img={imgP} />
                                <AmazonStyles.contImgManyImgCards>
                                    {
                                        (imagesProduct) &&
                                        imagesProduct.length > 0 &&
                                        imagesProduct.map((imgItem, i) => {
                                            return (
                                                <AmazonStyles.contImgGrid key={i} sh={i === imgActive ? true : false} img={imgItem} onClick={() => { setImgP(imgItem); setImgActive(i) }} />
                                            )
                                        })
                                    }
                                </AmazonStyles.contImgManyImgCards>
                            </div>
                            <div className="info-product">
                                <div className="item-text">
                                    <form style={{width: '100%'}} onSubmit={(e) => {sendUpdateTitle(e)}}>
                                        <AmazonStyles.inputTitle 
                                            defaultValue={titleUpdate}
                                            onChange={(e) => settitleUpdate(e.target.value)}
                                            placeholder="Titulo del producto"
                                        />
                                        
                                    </form>
                                    <div className="d-flex justify-content-start">
                                        <p style={{ marginBottom: 1 }} className="sub-texto">LINK AMAZON - &nbsp;</p>
                                        <a
                                            style={{
                                                color: "#707070",
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                width: '300px',
                                            }}
                                            href={data.url}
                                            rel="noopener noreferrer"
                                            target="_blank">{data.url}
                                        </a>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <p style={{ marginBottom: 2 }} className="sub-texto">Marca - &nbsp;</p>
                                        <p style={{ marginBottom: 2 }} className="text-color ">{data.brand}</p>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <div className="d-flex justify-content-start">
                                            <p style={{ marginBottom: 1 }} className="text-color">
                                                {
                                                    data.categoryTree.length > 0 ?
                                                        data.categoryTree.map((v, i) => {
                                                            if (i < data.categoryTree.length - 1) {
                                                                return `${v.name.en} >`
                                                            } else {
                                                                return v.name.en
                                                            }
                                                        })
                                                        : 'No tiene'
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    {
                                        (mlData) &&
                                        (
                                            <div className="d-flex justify-content-start">
                                                <p style={{ marginBottom: 2 }} className="sub-texto">LINK ML - &nbsp;</p>
                                                <a
                                                    style={{
                                                        color: "#707070",
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        width: '300px',
                                                    }}
                                                    href={
                                                        mlData &&
                                                        productUrl(idByLink, mlData.attrib.category_id, mlData.attrib.permalink)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >{
                                                        mlData &&
                                                        productUrl(idByLink, mlData.attrib.category_id, mlData.attrib.permalink)}
                                                </a>
                                            </div>
                                        )
                                    }
                                    {
                                        (mlData) &&
                                        mlBrandName && (
                                            <div className="d-flex justify-content-start">
                                                <p style={{ marginBottom: 2 }} className="sub-texto">TIENDA OFICIAL - &nbsp;</p>
                                                <p style={{ marginBottom: 2 }} className="text-color">{mlBrandName}</p>
                                            </div>
                                        )
                                    }
                                    <div className="d-flex justify-content-start">
                                        <p style={{ marginBottom: 2 }} className="sub-texto">SKU - &nbsp;</p>
                                        <p style={{ marginBottom: 2 }} className="text-color text-link" onClick={() => redirectRangos(asin)}>{asin}</p>
                                    </div>
                                    {
                                        (mlData) &&
                                        (
                                            <div className="d-flex justify-content-start">
                                                <p style={{ marginBottom: 2 }} className="sub-texto">SKU ML - &nbsp;</p>
                                                <p style={{ marginBottom: 2 }} className="text-color">{mlData.attrib.sku}</p>
                                            </div>
                                        )
                                    }
                                    <div className="d-flex justify-content-start">
                                        <p style={{ marginBottom: 2 }} className="sub-texto">STOCK - &nbsp;</p>
                                        <form style={{width: '100%'}} onSubmit={(e) => {sendUpdateTitle(e)}}>
                                            <AmazonStyles.inputTitle2 
                                                placeholder='Stock del producto' 
                                                value={stockProd} 
                                                onChange={(e) => setStockProd(e.target.value)} 
                                                w="50px" 
                                            />
                                        </form>
                                    </div>
                                    {
                                        (mlData) &&
                                        (
                                            <>
                                                <div className="d-flex justify-content-start">
                                                    <p style={{ marginBottom: 2 }} className="sub-texto">STOCK ML - &nbsp;</p>
                                                    <p style={{ marginBottom: 2 }} className="text-color">{mlData.stock}</p>
                                                </div>
                                                <div className="d-flex justify-content-start">
                                                    <p style={{ marginBottom: 2 }} className="sub-texto">CANTIDAD DE VENTAS EN ML - &nbsp;</p>
                                                    <p style={{ marginBottom: 2 }} className="text-color">{mlData.timesSold}</p>
                                                </div>
                                                <div className="d-flex justify-content-start">
                                                    <p style={{ marginBottom: 2 }} className="sub-texto">ESTATUS EN ML - &nbsp;</p>
                                                    <p style={{ marginBottom: 2 }} className="text-color">{mlData.attrib.status}</p>
                                                </div>
                                            </>
                                        )
                                    }
                                    <div className="d-flex justify-content-start">
                                        <p style={{ marginBottom: 2 }} className="sub-texto">USD PROVEEDOR - &nbsp;</p>
                                        <p style={{ marginBottom: 2 }} className="text-color">{data.price ? `$${numberFormat.format(data.price)}` : 'No disponible'}</p>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <p style={{ marginBottom: 2 }} className="sub-texto">PRECIO DE ENVIO PROVEEDOR - &nbsp;</p>
                                        <p style={{ marginBottom: 2 }} className="text-color">
                                            {data.shippingPrice ? `$${numberFormat.format(data.shippingPrice)}` : 'No disponible'}
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <p style={{ marginBottom: 2 }} className="sub-texto">PESO DE ENVIO PROVEEDOR (LBS) - &nbsp;</p>
                                        <p style={{ marginBottom: 2 }} className="text-color">
                                            {data.weight ? data.weight : 'No disponible'}
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <p style={{ marginBottom: 2 }} className="sub-texto">DIMENSIONES DEL PAQUETE - &nbsp;</p>
                                            <div className='d-flex'>
                                                <p style={{ marginBottom: 2 }} className="text-color">
                                                    Alto:
                                                </p>
                                                <form onSubmit={(e) => {sendUpdateTitle(e)}}>
                                                    <AmazonStyles.inputTitle2 w="120px" value={heightProd} onChange={(e) => setHeightProd(e.target.value)} placeholder='Alto'/>
                                                </form>
                                                <p style={{ marginBottom: 2 }} className="text-color">
                                                    Largo:
                                                </p>
                                                <form onSubmit={(e) => {sendUpdateTitle(e)}}>
                                                    <AmazonStyles.inputTitle2 w="120px" value={widthProd} onChange={(e) => setwidthProd(e.target.value)} placeholder='Largo' />
                                                </form>
                                                <p style={{ marginBottom: 2 }} className="text-color">
                                                    Longitud:
                                                </p>
                                                <form onSubmit={(e) => {sendUpdateTitle(e)}}>
                                                    <AmazonStyles.inputTitle2 w="120px" value={lengthProd} onChange={(e) => setlengthProd(e.target.value)} placeholder='Longitud' />
                                                </form>
                                            </div>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <p style={{ marginBottom: 2 }} className="sub-texto">PESO VOLUMETRICO - &nbsp;</p>
                                        <p style={{ marginBottom: 2 }} className="text-color">
                                            {volumetricWight}
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <p style={{ marginBottom: 2 }} className="sub-texto">PRECIO EN (USD) - &nbsp;</p>
                                        <p style={{ marginBottom: 2 }} className="text-color">
                                            {
                                                (dataPrice) &&
                                                    dataPrice ?
                                                    (!dataPrice.variants) ?
                                                        `$ ${numberFormat.format(Math.ceil(dataPrice.totalUsd))}`
                                                        :
                                                        dataPrice.variants.map(v => v.asin === asin && `$${numberFormat.format(Math.ceil(v.totalUsd))}`)
                                                    : ""
                                            }
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <p style={{ marginBottom: 2 }} className="sub-texto">PRECIO EN (COL/BSF) - &nbsp;</p>
                                        <p style={{ marginBottom: 2 }} className="text-color">
                                            {
                                                (dataPrice) &&
                                                    dataPrice ?
                                                    (!dataPrice.variants) ?
                                                        `${numberFormat.format(Math.ceil(dataPrice.total))}`
                                                        :
                                                        dataPrice.variants.map(v => {
                                                            if (v.asin === asin) {
                                                                return numberFormat.format(Math.ceil(v.total))
                                                            }
                                                        })
                                                    : ""
                                            }
                                        </p>
                                    </div>
                                    {
                                        (mlData) &&
                                        (
                                            <div className="d-flex justify-content-start">
                                                <p style={{ marginBottom: 2 }} className="sub-texto">PRECIO EN ML - &nbsp;</p>
                                                <p style={{ marginBottom: 2 }} className="text-color">{numberFormat.format(Math.ceil(mlData.price))}</p>
                                            </div>
                                        )
                                    }
                                    <div className="d-flex justify-content-start">
                                        <p style={{ marginBottom: 2 }} className="sub-texto">MONTO TOTAL DE LA GANANCIA - &nbsp;</p>
                                        <p style={{ marginBottom: 2 }} className="text-color">
                                            {
                                                dataPrice ?
                                                    (!dataPrice.variants) ?
                                                        `$ ${numberFormat.format(dataPrice.earningUsd)}`
                                                        :
                                                        dataPrice.variants.map(v => {
                                                            if (v.asin === asin) {
                                                                return numberFormat.format(v.earningUsd)
                                                            }
                                                        })
                                                    : ""
                                            }
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <AmazonStyles.btn
                                            style={{ marginLeft: '0px' }}
                                            colorFont='#FFFFFF' bgColor='#7367F0' pd='8px 12px'
                                            onClick={() => setShowFormula(!showFormula)}
                                        >
                                            {showFormula ? 'Ocultar formula' : 'Mostrar formula'}
                                        </AmazonStyles.btn>
                                    </div>
                                    {
                                        showFormula &&
                                        (
                                            <div className="d-flex justify-content-start">
                                                <p style={{ marginBottom: 2 }} className="text-color">
                                                    {
                                                        dataPrice ?
                                                            (!dataPrice.variants) ?
                                                                `${dataPrice.formula}`
                                                                :
                                                                dataPrice.variants.map(v => {
                                                                    if (v.asin === asin) {
                                                                        return v.formula
                                                                    }
                                                                })
                                                            : ""
                                                    }
                                                </p>
                                            </div>
                                        )
                                    }
                                    <p className="sub-texto" style={{ marginBottom: 5 }}>CARACTERISTICAS:</p>
                                    <ul className="list-caracteristicas">
                                        {data.features ? data.features.map((f, idx) =>
                                            <li key={idx}>{f.es || f.en}</li>
                                        ) : ''}
                                    </ul>
                                    <p className="sub-texto" style={{ marginBottom: 5 }}>DETALLES:</p>
                                    {
                                        data.details &&
                                        data.details.productDetails ?
                                            data.details.productDetails.length > 0 &&
                                            data.details.productDetails.map((r, idx) =>
                                                <p
                                                    className="text-long"
                                                    style={{ marginBottom: 2, marginRight: 10 }}
                                                    key={idx}>- {r.name.es || r.name.en}: {r.value.es || r.value.en}
                                                </p>
                                            ) : ''
                                    }
                                    <div className="text-left">
                                        <p style={{ marginBottom: 2 }} className="sub-texto">DESCRIPCION - &nbsp;</p>
                                        <p style={{ marginBottom: 2 }} className="text-long">{data.desc ? data.desc.es || data.desc.en : 'NP'}</p>
                                    </div>
                                    <div className="text-left">
                                        <p style={{ marginBottom: 2 }} className="sub-texto">DESCRIPCION ML - &nbsp;</p>
                                        <AmazonStyles.textArea
                                            defaultValue={descriptionProduct}
                                            onClick={(e) => setDescriptionProduct(e.target.value)} />
                                    </div>
                                </div>
                                {
                                    (showVariation) &&
                                        showVariation.length > 0 ? (
                                        <>
                                            <p className="sub-texto" style={{ marginBottom: 5 }}>VARIACIONES:</p>
                                            {
                                                showVariation.map((v, idx) => {
                                                    return (
                                                        <p
                                                            className="text-long"
                                                            style={{ marginBottom: 2, marginRight: 10 }}
                                                            key={idx}>
                                                            - {v.name.es || v.name.en}: {v.value.es || v.value.en}
                                                        </p>
                                                    )
                                                }
                                                )
                                            }
                                        </>
                                    ) : ''
                                }
                                {
                                    variantsData && (
                                        <div className="variants text-left mb-2">
                                            <p className="text-color-light" style={{ marginRight: 10 }}>VARIANTES</p>
                                            <AmazonStyles.contImgManyImgVariants isClothing={isClothing ? true : false} >
                                                {
                                                    variantsData.map((v, idx) => {
                                                        if (isClothing) {
                                                            return (
                                                                <AmazonStyles.contImgGridVariants
                                                                    className="card-variant"
                                                                    key={idx} img={v.images.length > 0 ? v.images[0] : ''}
                                                                    sh={idx === imgActiveTwo ? true : false}
                                                                    onClick={() => {
                                                                        setShowVariation(v.variations)
                                                                        setImgActiveTwo(idx)
                                                                        setImagesProduct(v.images)
                                                                        setProductData(v)
                                                                        setHeightProd(v.dimensions.height)
                                                                        setwidthProd(v.dimensions.width)
                                                                        setStockProd(v.stock);
                                                                        setlengthProd(v.dimensions['length'])
                                                                        setAsin(v.asin)
                                                                        setMlData(v.ml);
                                                                        setImgActive(0)
                                                                        setImgP(v.images[0]);
                                                                    }
                                                                    } />
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <AmazonStyles.constDataTextVariant
                                                                    key={idx}
                                                                    isActive={idx === imgActiveTwo ? true : false}
                                                                    onClick={() => {
                                                                        setShowVariation(v.variations)
                                                                        setImgActiveTwo(idx)
                                                                        setImagesProduct(v.images)
                                                                        setProductData(v)
                                                                        setHeightProd(v.dimensions.height)
                                                                        setwidthProd(v.dimensions.width)
                                                                        setStockProd(v.stock)
                                                                        setlengthProd(v.dimensions['length'])
                                                                        setAsin(v.asin)
                                                                        setMlData(v.ml);
                                                                        setImgActive(0)
                                                                        setImgP(v.images[0]);
                                                                    }
                                                                    }
                                                                >
                                                                    {
                                                                        (v.variations.length > 0) ? 
                                                                            v.variations[0].name ? 
                                                                                v.variations[0].name.es : 
                                                                                v.variations[0].name.en 
                                                                            : ''
                                                                    }
                                                                    -
                                                                    {
                                                                        (v.variations.length > 0) ? 
                                                                            v.variations[0].value ? 
                                                                            v.variations[0].value.en : 
                                                                            v.variations[0].value.es : 
                                                                            ''
                                                                    }
                                                                </AmazonStyles.constDataTextVariant>
                                                            )
                                                        }
                                                    })

                                                }
                                            </AmazonStyles.contImgManyImgVariants>
                                        </div>
                                    )
                                }
                                <div className="cont-btn-actions">
                                    {
                                        (mlData) ?
                                            mlData.attrib.status === 'active' ? (
                                                <button className="btn-procesar mt-2" onClick={() => pauseMl(mlData._id)}>
                                                    Pausar publicacion
                                                </button>
                                            )
                                                :
                                                (
                                                    <button className="btn-procesar mt-2" onClick={() => activateMl(mlData._id)}>
                                                        Activar publicacion
                                                    </button>
                                                )
                                            : ''
                                    }
                                    <button className="btn-update mt-2" onClick={() => scarpingAgain(data.url)}>
                                        Volver a scrapear
                                    </button>
                                    {
                                        (dataPrice) &&
                                            (mlData) ?
                                            !dataPrice.variants ?
                                                (
                                                    <button 
                                                    className='btn-update mt-2' 
                                                    onClick={() => updateMl(
                                                        Math.ceil(dataPrice.total), 
                                                        data.stock, mlData._id, 
                                                        descriptionProduct,
                                                        data.deliveryDays
                                                        )}
                                                    >
                                                        Actualizar publicacion en ML
                                                    </button>
                                                )
                                                :
                                                (
                                                    <button className='btn-update mt-2' onClick={
                                                        () => {
                                                            updateMl(
                                                                Math.ceil(dataPrice.variants.find(e => e.asin === asin).total),
                                                                data.stock, 
                                                                mlData._id, 
                                                                descriptionProduct,
                                                                data.deliveryDays
                                                                )
                                                        }
                                                    }>
                                                        Actualizar publicacion en ML
                                                    </button>
                                                )
                                            : ''

                                    }
                                </div>
                            </div>

                        </div>
                    </AmazonStyles.cardProduct>
                )
            }
        </AmazonStyles.Contenedor>
    )
}
