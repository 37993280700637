import styled from 'styled-components';

const Cont = styled.div`
display: block;
width: 100%;
padding-right:10px;
@media (max-width: 990px){
    padding: 10px;
}

`;

const Header = styled.div `
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
/* border: 1px solid; */
margin-top: 10px;
margin-bottom: 10px;
`;

const ContInputAndLabel = styled.div `
width:auto;
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'space-between'};
align-items:${props => props.ali || 'flex-start'};
`;

const Text = styled.p `
color: ${props => props.cl || '#707070'};
font-size: ${props => props.size || '.857rem'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '.2857rem'};
margin-bottom: ${props => props.mb || '.2857rem'};
text-overflow:${props => props.longText ? 'ellipsis' : ''};
overflow:${props => props.longText ? 'hidden' : ''};
white-space:${props => props.longText ? 'nowrap' : ''};
`;

const TextLink = styled.a `
color: ${props => props.cl || '#707070'};
font-size: ${props => props.size || '.857rem'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '.2857rem'};
margin-bottom: ${props => props.mb || '.2857rem'};
text-overflow:${props => props.longText ? 'ellipsis' : ''};
overflow:${props => props.longText ? 'hidden' : ''};
white-space:${props => props.longText ? 'nowrap' : ''};
text-decoration: none;
`
const ContHeaderGridOptions = styled.div`
width: 100%;
display: grid;
grid-gap: 1rem;
grid-auto-rows: auto;
grid-template-columns: repeat(auto-fill, minmax(200px, auto));
`;

const ContHeaderGridActions = styled.div`
width: 100%;
display: grid;
grid-gap: 1rem;
grid-auto-rows: auto;
grid-template-columns: repeat(auto-fill, minmax(200px, auto));
margin-bottom: 1rem;
`;

const BtnAction = styled.button`
margin-top: 10px;
width: 100%;
height: 60px;
border-radius: .428rem;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
background: #FFFFFF;
position: relative;
display: flex;
justify-content: flex-start;
align-items: center;
outline: none !important;
border: none;
transition: all ease-in-out 0.2s;
background-image: ${props=> props.isLoader ? 'linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px)':''};
animation: ${props=> props.isLoader ? 'shine-lines 1.6s infinite linear':''};
@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 140px;
  }
}
@keyframes shine-avatar {
  0% {
    background-position: -32px;
  }
  40%, 100% {
    background-position: 208px;
  }
}
`;

const ContHeaderProgressBar = styled.div`
width: 100%;
text-align: right;
margin-bottom: 1rem;
.progress-bar{
  transition: all ease-in-out .9s;
  background-color: ${props=> props.hiddenBar ? "#28c76f" : "#7367f0"};
}

`;

const ContGrid = styled.div`
width: 100%;
display: grid;
grid-gap: 1rem;
grid-auto-rows: auto;
grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
@media (max-width: 400px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));    
}
`;

const CardProduct = styled.div`
margin-top: 10px;
width: 100%;
height: 300px;
border-radius: .428rem;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
background: #FFFFFF;
position: relative;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
overflow: hidden;
`;
const CardProductHeader = styled.div`
width: 100%;
height: 35px;
display: flex;
justify-content: space-between;
align-items: flex-start;
border-top-left-radius: .428rem;
border-top-right-radius: .428rem;
padding-bottom: 5px;
box-shadow: rgb(0 0 0 / 11%) 0 1px;
`;

const ContIconsInfo = styled.div`
padding: 5px 10px;
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
`;
const ContIconInfo = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 25px;
height: 25px;
border-radius: 50%;
border: none;
background: ${props=> props.cl || '#707070'};
color: ${props=> props.cli || '#7367f0'};
`;
const CardProductBody = styled.div`
margin-top: 0;
margin-bottom: 0;
width: 100%;
height: 100%;
padding: 6px;
display: flex;
align-items: center;
justify-content: flex-start;
`;

const CardProductImgBody = styled.div`
min-width: 100px;
min-height: 100px;
max-width: 100px;
max-height: 100px;
background-color: #f0f0f0;
border-radius: 0.428rem;
margin-right: 5px;
background-image: url(${props => props.url || ''});
background-size: cover;
background-repeat: no-repeat;
background-position: center;
`;

const CardProductTextBody = styled.div`
width: 100%;
height: 200px;
overflow: hidden;
margin-top: 0;
margin-bottom: 0;
overflow-y: auto;
padding-right: 5px;
/* width */
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 50px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #7367f0;
  }

`;

const CardProductFooter = styled.div`
width: 100%;
height: 40px;
display: flex;
justify-content: flex-end;
align-items: center;
border-bottom-left-radius: .428rem;
border-bottom-right-radius: .428rem;
margin-bottom: 0;
box-shadow: rgb(0 0 0 / 11%) 0 0 0 1px;
`;

const ContInputChecbox = styled.div`
    border-top-right-radius: .428rem;
    border-bottom-left-radius: .428rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #7367F0;
    .cbx {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    }
    .cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
    }
    .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #FFFFFF;
    transition: all 0.2s ease;
    }
    .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #7367f0;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
    }
    .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
    }
    .cbx:hover span:first-child {
    border-color: #FFFFFF;
    }

    .inp-cbx:checked + .cbx span:first-child {
    background: #FFFFFF;
    border-color: #FFFFFF;
    animation: wave 0.4s ease;
    }
    .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
    }
    .inp-cbx:checked + .cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
    }

    @keyframes wave {
    50% {
        transform: scale(0.9);
    }
    }
    
`;

const NavMarcketplace = styled.ul`
list-style: none;
width: 100%;
display: flex;
padding-left: 0;
margin-bottom: 0;
overflow-x: auto;
/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 50px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #7367f0;
  }
`;
const NavMarcketplaceItem = styled.li`
padding: 5px;
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
cursor: pointer;
`
const NavMarcketplaceSpan = styled.span`
align-self: flex-end;
background-color: #7367f0;
height: 3px;
width: 100%;
transition: all ease .2s;
opacity: ${props=> props.isActive ? '1' : '0'};
`
const GeneralList = styled.ul`
margin-bottom: 0;
padding-left: 0;
`;
const GeneralListItem = styled.li`
margin-bottom: 0;
text-align: justify;
`;
const ContGridVariantsCard = styled.div`
width: 100%;
display: grid;
grid-gap: 5px;
grid-auto-rows: auto;
grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
`;
const contImgVariantsCard = styled.div`
width: 100%;
height: 50px;
background-color: #7367F0;
border-radius: 0.428rem;
overflow: auto;
border: ${props=> props.active ? '1px solid #7367f0' : '1px solid #707070'};
background-image: url(${props => props.url || ''});
background-size: cover;
background-repeat: no-repeat;
background-position: center;
margin-right: 5px;
`;
const CardStadistic = styled.div`
min-width: 140px;
width: 100%;
height: 150px;
padding: 1rem;
border-radius: .428rem;
background-color: #FFFFFF;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
margin-right: 1rem;
background-image: ${props=> props.isLoader ? 'linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px)':''};
animation: ${props=> props.isLoader ? 'shine-lines 1.6s infinite linear':''};
@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 140px;
  }
}
@keyframes shine-avatar {
  0% {
    background-position: -32px;
  }
  40%, 100% {
    background-position: 208px;
  }
}
`;

const ContIconCardStadistic = styled.div`
border-radius: 50px;
width: 40px;
height: 40px;
display: flex;
justify-content: center;
align-items: center;
color: ${props => props.cli ? props.cli : '#f0f0f0'};
background-color: ${props => props.cl ? props.cl : '#f0f0f0'};
`;
const ContIconBtnAction = styled.div`
border-radius: 50px;
width: 30px;
height: 30px;
display: flex;
justify-content: center;
align-items: center;
margin-right: 5px;
border: ${props => props.cli ? props.cli : '1px solid #7367f0'};
color: ${props => props.cli ? props.cli : '#7367f0'};
background-color: ${props => props.cl ? props.cl : '#FFFFFF'};
transition: all ease-in-out 0.2s;
&:hover {
  border: ${props => props.clih ? props.clih : '1px solid #7367f0'};
  color: ${props => props.clih ? props.clih : '#FFFFFF'};
  background-color: ${props => props.clh ? props.clh : '#7367f0'};
}
`;
const UpdateAndPublish = {
    Cont,
    Header,
    ContInputAndLabel,
    Text,
    TextLink,
    ContHeaderGridOptions,
    ContGrid,
    CardProduct,
    CardProductHeader,
    CardProductFooter,
    ContInputChecbox,
    CardProductBody,
    CardProductImgBody,
    CardProductTextBody,
    NavMarcketplace,
    NavMarcketplaceItem,
    NavMarcketplaceSpan,
    GeneralList,
    GeneralListItem,
    ContGridVariantsCard,
    contImgVariantsCard,
    CardStadistic,
    ContIconCardStadistic,
    ContHeaderProgressBar,
    ContHeaderGridActions,
    BtnAction,
    ContIconBtnAction,
    ContIconsInfo,
    ContIconInfo
}
export default UpdateAndPublish