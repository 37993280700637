import React, { useState, useEffect, useContext } from 'react'
import NavbarAdminMenuStyle from '../../../assets/css/components/admin/menus/NavbarAdminMenuStyle'
import { BiBell, BiBug, BiCloudRain, BiCodeBlock, BiCodeCurly, BiFile, BiImageAlt, BiMenu, BiPowerOff, BiUserX, BiLineChart, BiRocket } from "react-icons/bi";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { Redirect } from "react-router-dom";
import axios from "axios";
import {
    keyUserId,
    keyAccessToken,
    keyUserRoles,
    userEmail
} from "../../../assets/js/SessionStorageNames";
import { Link } from "react-router-dom";
import SelectEnterprise from './components/SelectEnterprise';
import Notifications from '../notifications/Notifications';

export default function NavbarAdmin() {

    const globalContext = useContext(GlobalContext);
    const [redirect, setRedirect] = useState(null);
    const [nameUser, setNameUser] = useState(null);
    const [userRoles, setUserRoles] = useState(null);
    const [userEmailVali, setUserEmailVali] = useState(null)

    useEffect(() => {

        const v_keyUserRoles = localStorage.getItem(keyUserRoles);
        const v_userEmail = localStorage.getItem(userEmail);
        const v_keyAccessToken = localStorage.getItem(keyAccessToken);
        const v_keyUserId = localStorage.getItem(keyUserId);

        if (!v_keyUserRoles || !v_userEmail || !v_keyAccessToken || !v_keyUserId) {
            // setRedirect(<Redirect to="/login" />);
            globalContext.setVerifyLogin(true);
        } else {
            globalContext.setVerifyLogin(false);
            setUserRoles(v_keyUserRoles.split(",").filter(String));
            setUserEmailVali(v_userEmail);

        }
    }, [localStorage.getItem(keyUserRoles),
    localStorage.getItem(userEmail),
    localStorage.getItem(keyAccessToken),
    localStorage.getItem(keyUserId)]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/v1/profile/my`, {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            })
            .then((res) => {
                setNameUser(res.data.data.name);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [nameUser]);

    const logOut = () => {
        globalContext.setLoaderPage(true);
        axios
            .delete(`${process.env.REACT_APP_BACKEND_URL}/v1/logout`, {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            })
            .then(() => {
                globalContext.setLoaderPage(false);
                window.localStorage.clear();
                setRedirect(<Redirect to="/login" />);
            })
            .catch((err) => {
                globalContext.setLoaderPage(false);
                globalContext.showModal('Presentamos errores de comunicación, por favor cierre la pestaña y abra otra.');
            });
    };


    const showSidebarNotifications = (e) => {
        e.preventDefault();
        globalContext.setShowNotifications()
    }

    return (
        <NavbarAdminMenuStyle.ContNavBarAdmin>
            {redirect}
            <NavbarAdminMenuStyle.NavBarAdmin>
                <NavbarAdminMenuStyle.ListIcons>
                    <NavbarAdminMenuStyle.ItemListIcon>
                        <NavbarAdminMenuStyle.BtnIconList onClick={() => globalContext.setStatusMenu(true)}>
                            <BiMenu />
                        </NavbarAdminMenuStyle.BtnIconList>
                    </NavbarAdminMenuStyle.ItemListIcon>
                </NavbarAdminMenuStyle.ListIcons>
                <SelectEnterprise />
                <NavbarAdminMenuStyle.ListIcons>
                    <NavbarAdminMenuStyle.ItemListIcon>
                        <NavbarAdminMenuStyle.BtnIconList onClick={() => globalContext.setModalReporPostProducts(true)}>
                            <BiLineChart />
                        </NavbarAdminMenuStyle.BtnIconList>
                    </NavbarAdminMenuStyle.ItemListIcon>
                    <NavbarAdminMenuStyle.ItemListIcon>
                        <NavbarAdminMenuStyle.BtnIconList onClick={() => alert('add somenthing')}>
                            <BiRocket />
                        </NavbarAdminMenuStyle.BtnIconList>
                    </NavbarAdminMenuStyle.ItemListIcon>
                    
                    <NavbarAdminMenuStyle.ItemListIcon>
                        <NavbarAdminMenuStyle.BtnIconList onClick={(e) => showSidebarNotifications(e)}>
                            <BiBell />
                        </NavbarAdminMenuStyle.BtnIconList>
                    </NavbarAdminMenuStyle.ItemListIcon>
                    {
                        userRoles && userRoles.map((e, k) => {
                            if (
                                e === "ADMIN_ENTERPRISE" || userEmailVali === 'jesmir.f@212global.com' || 
                                userEmailVali === 'windaly.z@licoresfactory.com' || userEmailVali === 'erick.m@licoresfactory.com' 
                                || userEmailVali === 'barbara.o@212global.com' || userEmailVali === 'rudy.v@212global.com') {
                                return (
                                    <Link to="/admin/user-config/user-block-grid" key={k}>
                                        <NavbarAdminMenuStyle.ItemListIcon>
                                            <NavbarAdminMenuStyle.BtnIconList>
                                                <BiUserX />
                                            </NavbarAdminMenuStyle.BtnIconList>
                                        </NavbarAdminMenuStyle.ItemListIcon>
                                    </Link>
                                );
                            }
                        })
                    }
                    <NavbarAdminMenuStyle.ItemListIcon>
                        <NavbarAdminMenuStyle.BtnIconList onClick={() => globalContext.setActiveModalProccess(true)}>
                            <BiCloudRain />
                        </NavbarAdminMenuStyle.BtnIconList>
                    </NavbarAdminMenuStyle.ItemListIcon>

                    <NavbarAdminMenuStyle.ItemListIcon>
                        <NavbarAdminMenuStyle.BtnIconList onClick={() => globalContext.setModalSelectDefaultImg(true)}>
                            <BiImageAlt />
                        </NavbarAdminMenuStyle.BtnIconList>
                    </NavbarAdminMenuStyle.ItemListIcon>

                    <Link to="/admin/debug-screen">
                        <NavbarAdminMenuStyle.ItemListIcon>
                            <NavbarAdminMenuStyle.BtnIconList>
                                <BiBug />
                            </NavbarAdminMenuStyle.BtnIconList>
                        </NavbarAdminMenuStyle.ItemListIcon>
                    </Link>

                    {[
                        'kronos.sytes.net:3000',
                        'www.e-kommers.com',
                    ].includes(window.location.host) && (<>
                        <Link to="/admin/testing/admin-enterprise">
                            <NavbarAdminMenuStyle.ItemListIcon>
                                <NavbarAdminMenuStyle.BtnIconList>
                                    <BiCodeBlock />
                                </NavbarAdminMenuStyle.BtnIconList>
                            </NavbarAdminMenuStyle.ItemListIcon>
                        </Link>
                    </>)}
                    {[
                        'kronos.sytes.net:3000',
                        'www.e-kommers.com',
                        'e-kommers.com'
                    ].includes(window.location.host) && (<>
                        <Link to="/admin/testing/admin">
                            <NavbarAdminMenuStyle.ItemListIcon>
                                <NavbarAdminMenuStyle.BtnIconList>
                                    <BiCodeCurly />
                                </NavbarAdminMenuStyle.BtnIconList>
                            </NavbarAdminMenuStyle.ItemListIcon>
                        </Link>
                    </>)}

                    <Link to="/admin/testing/xlsx">
                        <NavbarAdminMenuStyle.ItemListIcon>
                            <NavbarAdminMenuStyle.BtnIconList>
                                <BiFile />
                            </NavbarAdminMenuStyle.BtnIconList>
                        </NavbarAdminMenuStyle.ItemListIcon>
                    </Link>

                    <NavbarAdminMenuStyle.ItemListIcon>
                        <NavbarAdminMenuStyle.BtnIconList onClick={() => logOut()}>
                            <BiPowerOff />
                        </NavbarAdminMenuStyle.BtnIconList>
                    </NavbarAdminMenuStyle.ItemListIcon>
                </NavbarAdminMenuStyle.ListIcons>
            </NavbarAdminMenuStyle.NavBarAdmin>
            <Notifications />
        </NavbarAdminMenuStyle.ContNavBarAdmin>
    )
}
