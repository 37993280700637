import React, { useContext, useEffect, useRef, useState } from 'react'
import MultiScraperStyles from '../../../../../assets/css/components/admin/products/amazon/MultiScraperStyles';
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import axios from 'axios';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import getCategory from '../functions/getCategory';
import Pager from '../../../../general/Pager';
export default function CategoriesList({findCategoryByList}) {
    const [categoryArray, setCategoryArray] = useState([]);
    const pageItemCount = 15;
    const [totalCategories, setTotalCategories] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const globalContext = useContext(GlobalContext);
    const [categoriesP, setCategoriesP] = useState(null);
    const [categoriesParentArray, setCategoriesParentArray] = useState([]);
    const [includeTree, setIncludeTree] = useState(false);
    const [getAll, setGetAll] = useState(false);
    const [categorieTextList, setCategorieTextList] = useState([])
    const [filterBySearch, setFilterBySearch] = useState('');
    const [filterBySearchTwo, setFilterBySearchTwo] = useState('');
    const [categories, setCategories] = useState([]);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [isLoad, setIsLoad] = useState(true);
    const [notMore, setNotMore] = useState(true);
    const anotherMenuRef = useRef(null);
    const inputSeacrCategory = useRef(null)
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    useEffect(() => {
        if(!globalContext.listCategories) return;
        setNotMore(false);
        setIsLoad(true);
        getCategory(currentPage, pageItemCount, categoriesP, includeTree, getAll, filterBySearch, axiosCancelTokenSource, globalContext.currentEnterpriseId)
            .then(res => {
                const data = res.data.data;
                console.log('data');
                console.table(data.categories);
                setTotalCategories(data.totalCategories);
                setCategories(data.categories);
                setIsLoad(false);
                if (res.data.data.totalCategories > 0) {
                    
                    setNotMore(false);
                }
                //else {
                    
                //     finishSearch()
                //     setNotMore(true);
                // }
            }).catch(error => {
                handleAxiosError(error);
            })
    }, [currentPage, pageItemCount, categoriesP, includeTree, getAll, filterBySearch, axiosCancelTokenSource, globalContext.listCategories, globalContext.currentEnterpriseId]);

    function useOutsideAlerterMenu(refe) {
        useEffect(() => {
            if(globalContext.listCategories) {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutsideMenu(event) {
              if (refe.current && !refe.current.contains(event.target)) {
                globalContext.setLisCategories(false);
                inputSeacrCategory.current.value = ''
              }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutsideMenu);
            return () => {
              // Unbind the event listener on clean up
              document.removeEventListener("mousedown", handleClickOutsideMenu);
            };
          }
          }, [refe, globalContext.listCategories]);
        }
        useOutsideAlerterMenu(anotherMenuRef);

    const addCategoryToSearch = ({_id, node, name}) => {
        
        let copyArray = categoryArray;
        let copyArrParent = categoriesParentArray;
        let coppyArrCateText = categorieTextList;
        copyArray.push(_id);
        copyArrParent.push(node);
        coppyArrCateText.push(name.en);
        setFilterBySearch('')
        setIncludeTree(true);
        setCategorieTextList(coppyArrCateText);
        setCategoriesParentArray(copyArrParent);
        setCategoriesP(copyArrParent[copyArrParent.length - 1]);
        setCategoryArray(copyArray);
    }

    const finishSearch = async () => {

        
        await findCategoryByList([...categoryArray], categorieTextList);
        let copyArray = categoryArray;
        let copyArrParent = categoriesParentArray;
        let coppyArrCateText = categorieTextList;

        copyArray.pop();
        copyArrParent.pop();
        coppyArrCateText.pop();
        
        globalContext.setLisCategories(false);

        setCategoriesP(copyArrParent[copyArrParent.length - 1]);
        setCategoryArray(copyArray);
        setCategorieTextList(coppyArrCateText);
        setCategoriesParentArray(copyArrParent);
        setCurrentPage(1);
        setIncludeTree(false);
        inputSeacrCategory.current.value = ''
    }

    const backCategory = () => {
        if(categoryArray.length > 0 && categoriesParentArray.length > 0 && categorieTextList.length > 0) {

            let copyArray = categoryArray;
            let copyArrParent = categoriesParentArray;
            let coppyArrCateText = categorieTextList;
    
            copyArray.pop();
            copyArrParent.pop();
            coppyArrCateText.pop();
    
            setCategorieTextList(coppyArrCateText);
            setCategoriesParentArray(copyArrParent);
            setCategoriesP(copyArrParent[copyArrParent.length - 1]);
            setCategoryArray(copyArray);

        }else {
            setCategorieTextList([])
            setCategoriesParentArray([])
            setCategoriesP(null)
            setCategoryArray([])
            setIncludeTree(false);
        }

    }

    const setFilterToSearch = (e) => {
        e.preventDefault();
        if(filterBySearchTwo.length > 0) {
            setFilterBySearch(filterBySearchTwo);
            setGetAll(true)
            setIncludeTree(true);
        }else {
            setGetAll(false)
            setIncludeTree(false);
            setFilterBySearch('')
        }
    }

    return (
        <MultiScraperStyles.ContCategorySelection active={globalContext.listCategories}>
            <MultiScraperStyles.CategorySelector active={globalContext.listCategories} ref={anotherMenuRef}>
                <MultiScraperStyles.ListCategoryHeader>
                    {
                        categoryArray.length > 0 &&
                        (
                            <MultiScraperStyles.BtnPrevCategory onClick={() => backCategory()}>
                                <BiChevronLeft />
                            </MultiScraperStyles.BtnPrevCategory>
                        )
                    }
                    Categorias de Amazon
                    
                    {
                        categoryArray.length > 0 && 
                        (
                            <MultiScraperStyles.BtnApliCategory
                                onClick={() => finishSearch()}
                            >
                                Aplicar
                            </MultiScraperStyles.BtnApliCategory>
                        )
                    }
                </MultiScraperStyles.ListCategoryHeader>
                <MultiScraperStyles.ContInputSearch onSubmit={(e) => setFilterToSearch(e)}>
                    <MultiScraperStyles.InputSearch
                        ref={inputSeacrCategory}
                        placeholder='Nombre de la Categoria'
                        onChange={(e) => setFilterBySearchTwo(e.target.value)}
                        />
                </MultiScraperStyles.ContInputSearch>
                {(!isLoad && categorieTextList.length > 0) && (
                    <MultiScraperStyles.ListCategoryHeaderTwo>
                    {
                        categorieTextList.length > 0 &&
                        (
                            <MultiScraperStyles.text size="14px">
                                {categorieTextList.join(' > ')}
                            </MultiScraperStyles.text>
                        )
                    }
                </MultiScraperStyles.ListCategoryHeaderTwo>
                )
                }
                <MultiScraperStyles.ContListCategory>
                    {
                        !notMore &&
                            (
                                <MultiScraperStyles.ListCategory>
                                    {
                                        isLoad ?
                                            (
                                                [0,2,3,4,5].map(v => {
                                                    return(
                                                        <MultiScraperStyles.ItemLoader key={v} />
                                                    )
                                                    })
                                                    )
                                                :
                                                categories.length > 0 &&
                                                    categories.map((v) => {
                                                        return (
                                                            <MultiScraperStyles.ListcategoryItem key={v._id} onClick={() => addCategoryToSearch(v)}>
                                                            <div style={{width: '100%'}}>
                                                            <MultiScraperStyles.text size="9pt" cl="#111" fw="200">
                                                            {v.tree && 
                                                                v.tree.length > 0 ?
                                                                v.tree.map(((t,i) => {
                                                                     return t.name ? t.name.en + " > " : ''
                                                                }))
                                                                : ''    
                                                            }
                                                            </MultiScraperStyles.text>
                                                            <MultiScraperStyles.text size="12pt" cl="#111" fw="450">
                                                             {`${v.name.en} - ${v.productsCount}`}
                                                            </MultiScraperStyles.text>
                                                            </div>
                                                            <BiChevronRight />
                                                            </MultiScraperStyles.ListcategoryItem>
                                                        )
                                                    }
                                            )
                                    }
                                </MultiScraperStyles.ListCategory>
                            ) 

                    }
                </MultiScraperStyles.ContListCategory>
                {!isLoad && ( 
                        <Pager
                            handleSetPage={setCurrentPage}
                            totalResults={totalCategories}
                            currentPage={currentPage}
                            resultsPerPage={pageItemCount}
                        />
                    )}
            </MultiScraperStyles.CategorySelector>
        </MultiScraperStyles.ContCategorySelection>
    )
}
