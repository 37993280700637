import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFileImage } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import OdooToMlStyle from "../../../../assets/css/components/admin/products/new-product-forms/OdooToMlStyle.css";
import { Container, Row, Col, Form, Image } from "react-bootstrap";
import PrintSteps from "./OdooToMlComponents/printSteps";
import { Redirect } from "react-router-dom";
import Routes from "../../../../Routes";
import verifyAccessRole from "../../../../assets/js/verifyAccessRole";

export default function OdooToMl() {
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const hasAccess = verifyAccessRole(Routes, 29);
    if(!hasAccess) {
        setRedirect(
            <Redirect to={`/admin/dashboard`} />
        )
    }
  },[]);

  return (
    <OdooToMlStyle>
      {redirect}
      <div className="goBack d-flex justify-content-start mt-2">
        <Link to="admin/productos/new-product/new-product-menu">
          <button className="btn-back">
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        </Link>
      </div>
      <Container>
        <Form>
          <Row>
            <Col xs={12} sm={12} md={12} xl={12} lg={12}>
              <p>
                Publica tus productos de odoo a ml de manera rapida y sencilla
              </p>
            </Col>
          </Row>
          <Row>
            <PrintSteps />
          </Row>
        </Form>
      </Container>
    </OdooToMlStyle>
  );
}
