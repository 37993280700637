import styled from 'styled-components';

const contSidebar = styled.div`
    height: 100%;
    transition: all ease-in 0.01s;
    width:100%;
    background-color:rgba(112,112,112,0.50);
    position: fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    transform: translateX(${props=> props.active ? '0px' : '-2000px'});
    z-index:100000;
`;
const cont = styled.div`
    width: 300px;
    height: 100%;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items:center;
    padding:10px;
    transition: all ease-in 0.3s;
    overflow-y: auto;
    transform: translateX(${props => props.active ? '0px' : '-260px'});
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg,#7367f0,rgba(115,103,240,.7));
        border-radius: 50px;
        
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {        
        box-shadow: 2px 0px 6px #7367f0;
        
    }
    
`;
const header = styled.div`
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
width:100%;
height: 60px;
display: flex;
justify-content:space-between;
align-items:center;
font-weight: 500;

p {
    margin-bottom:0;
    font-weight: 500;
    color: #5e548e;
}

button {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background: #FFF;
    border: 0.5px solid #5e548e;
    display: flex;
    color: #5e548e;
    justify-content: center;
    align-items: center;
    outline: none;
    &:hover {
        background: #5e548e;
        color: #FFF;
    }
}
`

const AnotherMenuCss = {
    contSidebar,
    cont,
    header
}

export default AnotherMenuCss;