import React from 'react'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle'

export default function Navbar(props) {
    const {setCurrentStep, currentStep} = props;

    const changeStep = (v) => {
        setCurrentStep(v)
    }
    return (
        <PedidosYaStyle.ContNavbar>
            <PedidosYaStyle.MenuOptions>
                <PedidosYaStyle.OptionMenu active={currentStep === 1} onClick={() => changeStep(1)}>
                    Credenciales
                </PedidosYaStyle.OptionMenu>
                <PedidosYaStyle.OptionMenu active={currentStep === 2 || currentStep === 3} onClick={() => changeStep(2)}>
                    Descarga de marcas
                </PedidosYaStyle.OptionMenu>
            </PedidosYaStyle.MenuOptions>
        </PedidosYaStyle.ContNavbar>
    )
}
