import styled from 'styled-components';
export const ContDivConst = styled.div`
width: 100%;
min-height: 100vh;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`;

export const ContDivImg = styled.div`
    background-image: url('/underconst.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 500px;
    height: 500px;
`;

export const Text = styled.p`
margin-bottom: 0;
color: ${props=> props.cl || '#707070'};
font-weight: ${props=> props.fw  || 'lighter'};
font-size: ${props=> props.size || '12px'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
@media (max-width: 990px) {
    margin-left:0;
}
`