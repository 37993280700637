/* eslint-disable default-case */
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import BuildFormCss from '../../../../../assets/css/components/admin/products/post-product/steps/BuildFormCss'
import InputText from './components/InputText';
import Dropdown from './components/DropDown';
import DateTime from './components/DateTime';
import {Spinner} from 'react-bootstrap';
export default function BuildForm(props) {
    const {listOfLastParents, buildProp, isLoader, backStep, valuesCategorys, clearCategory} = props;

    const renderInput = (v, valuesCategorys, i) => {
        switch (v.inputType) {
            case "dropdown":
                return <Dropdown data={v} valuesCategorys={valuesCategorys} setValue={buildProp} index={i} />;
            case "textfield":
                return <InputText typeInput="text" data={v} valuesCategorys={valuesCategorys} setValue={buildProp} index={i} />;
            case "textarea":
                return <InputText typeInput="text" data={v} valuesCategorys={valuesCategorys} setValue={buildProp} index={i} />;
            case "numberfield":
                return <InputText typeInput="number" data={v} valuesCategorys={valuesCategorys} setValue={buildProp} index={i} />;
            case "datetime":
                return <DateTime valuesCategorys={valuesCategorys} setValue={buildProp} index={i} />
            case "multiselect":
                return <Dropdown data={v} valuesCategorys={valuesCategorys} setValue={buildProp} index={i} />;
            case "":
                return <Dropdown data={v} valuesCategorys={valuesCategorys} setValue={buildProp} index={i} />;
        }
    }
    
    return (
        <BuildFormCss.cont>
            <BuildFormCss.header>
            {listOfLastParents.length > 0 &&
                listOfLastParents.map((v, i) => {
                    if (listOfLastParents.length -1 === i) {
                        return (
                            <BuildFormCss.text key={v._id} size="15pt" style={{color: "#7367F0"}}>
                                {v.name}
                            </BuildFormCss.text>
                        )
                    }else {
                        return (
                            <BuildFormCss.text key={v._id} size="15pt" style={{color: "#7367F0"}}>
                                {v.name} {" > "}
                            </BuildFormCss.text>
                        )
                    }
                    })
                    }
            </BuildFormCss.header>
            <BuildFormCss.body>
                {
                    isLoader ?
                    (<BuildFormCss.contLoader>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Cargando...</span>
                        </Spinner>
                    </BuildFormCss.contLoader>)
                    :
                    (
                        <BuildFormCss.gridData>
                    {
                        valuesCategorys && 
                            valuesCategorys.map((v,i) => {
                                    return (
                                        <BuildFormCss.labelInput key={i}>
                                            <BuildFormCss.text mb="10px">{v.label} {v.isMandatory && (<FontAwesomeIcon icon={faAsterisk} />)}</BuildFormCss.text>
                                            {renderInput(v, valuesCategorys, i)}
                                        </BuildFormCss.labelInput>
                                    )
                                
                            })
                    }
                        </BuildFormCss.gridData>

                    )
                }
            </BuildFormCss.body>
            <BuildFormCss.footer>
                <BuildFormCss.btnBack onClick={() => {backStep(); clearCategory()}}>
                    Volver
                </BuildFormCss.btnBack>
            </BuildFormCss.footer>
        </BuildFormCss.cont>
    )
}
