const addValuesToTemplate = async (dataReplace, data) => {
    // * este objeto es el que remplazara la etiquetas
    //console.log(data)
    const mapObjToReplace = {
        titulo: data.title.es || data.title.es + '\n',
        titulo_en: data.title.en + '\n',
        d_tecnicos: data.details.productDetails ?
            data.details.productDetails.length > 0 ?
                data.details.productDetails.map(v =>
                    `- ${v.name['es'] || v.name['en']} ${v.value['es'] || v.value['en']} '\n'`
                ) : '' : ''
        ,

        informacion_adicional: data.details.additionalInfo ?
            data.details.additionalInfo > 0 ?
                data.details.additionalInfo.technicalDetails ?
                    data.details.additionalInfo.technicalDetails.length > 0 ?
                        data.details.additionalInfo.technicalDetails.map(v =>
                            `- ${v.name['es'] || v.name['en']} ${v.value['es'] || v.value['en']} '\n'`
                        ) : '' : '' : '' : '',

        caracteristicas: data.features.length > 0 ?
            data.features.map(v =>
                `- ${v['es'] || v['en']} '\n'`
            )
            : '',

        especificaciones_producto: data.details.productSpecifications ?
            data.details.productSpecifications > 0 ?
                data.details.productSpecifications.map(v =>
                    `- ${v.name['es'] || v.name['en']} ${v.value['es'] || v.value['es']} '\n'`
                ) : "" : '',

        sku: data.asin + '\n',
        descripcion: data.desc.es || data.desc.en,
        stock: data.stock ? data.stock : '',
        dias_entrega: data.deliveryDays ? data.deliveryDays : ''
    }
    /**
     * ? remplazamos el lugar de las etiqutas por sus valores
     */
    dataReplace = dataReplace.replace('@titulo', mapObjToReplace.titulo);
    dataReplace = dataReplace.replace('@titulo_en_ingles', mapObjToReplace.titulo_en);
    dataReplace = dataReplace.replace('@detalles_tecnicos', mapObjToReplace.d_tecnicos);
    dataReplace = dataReplace.replace('@caracteristicas', mapObjToReplace.caracteristicas);
    dataReplace = dataReplace.replace('@sku', mapObjToReplace.sku);
    dataReplace = dataReplace.replace('@descripcion', mapObjToReplace.descripcion);
    dataReplace = dataReplace.replace('@informacion_adicional', mapObjToReplace.informacion_adicional);
    dataReplace = dataReplace.replace('@especificaciones_producto', mapObjToReplace.especificaciones_producto);
    dataReplace = dataReplace.replace('@stock', mapObjToReplace.stock);
    dataReplace = dataReplace.replace('@tiempo_envio', mapObjToReplace.dias_entrega);
    return dataReplace;
}

export default addValuesToTemplate;