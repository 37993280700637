
import axios from "axios";
import { keyAccessToken, keyUserId } from "../../../../../assets/js/SessionStorageNames";
const sendListRestaurants = (restaurant, enterprise) => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL_PEYA}/v1/peya-sdk/get-restaurants-by-id`,
        {
            restaurants: restaurant,
            enterprise: enterprise,
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export default sendListRestaurants;