import styled from 'styled-components';

const GraphicsStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  * {
    margin: 0px;
    box-sizing: border-box;
  }
  .lineChartContent {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 0;
    overflow: hidden;
    width: 285px;
    height: 250px;
    border-radius: 10px;
  }
  /**
  * ? background by the charts
   */
  .background-one {
    background: #4e54c8; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to bottom,
      #8f94fb,
      #4e54c8
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to bottom,
      #8f94fb,
      #4e54c8
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  .background-two {
    background: #673ab7; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to top,
      #512da8,
      #673ab7
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to top,
      #512da8,
      #673ab7
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  .background-three {
    background-image: -moz-linear-gradient(90deg, #11998e 0%, #38ef7d 100%);
    background-image: -webkit-linear-gradient(90deg, #11998e 0%, #38ef7d 100%);
    background-image: -ms-linear-gradient(90deg, #11998e 0%, #38ef7d 100%);
  }
  .background-four {
    background: #4776e6; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to top,
      #8e54e9,
      #4776e6
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to top,
      #8e54e9,
      #4776e6
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  .icon-graphic {
    font-size: 60px;
    color: rgba(255, 255, 255, 0.8);
  }
  .text-graphic {
    font-family: "Roboto Slab", serif !important;
    text-align: left !important;
  }
  .text-graphic .title {
    color: #ffffff;
    font-size: 25px;
  }
  .text-graphic .sub-title {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
  }

  @media (min-width: 1101px) and (max-width: 1270px) {
    .lineChartContent {
      margin-top: 15px;
      margin-bottom: 10px;
      padding-top: 15px;
      width: 230px;
    }
    .icon-graphic {
      font-size: 50px;
    }
    .text-graphic .title {
      font-size: 20px;
    }
  }

  
  @media (min-width: 992px) and (max-width: 1100px) {
    .lineChartContent {
      margin-top: 15px;
      margin-bottom: 10px;
      padding-top: 15px;
      width: 210px;
    }
    .icon-graphic {
      font-size: 50px;
    }
    .text-graphic .title {
      font-size: 20px;
    }
  }

  @media (min-width: 576px) and (max-width: 649px) {
    .lineChartContent {
      width: 250px;
    }
  }
  @media (min-width: 500px) and (max-width: 575px) {
    .lineChartContent {
      width: 420px;
    }
  }
`;
export default GraphicsStyle;