import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';

const updateCategorie = (idCat, publisVariants=null, activeToPublish=null) => {
    return axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/amazons-crap/categories`, {
          categories: [{
            id: idCat,
            ...(publisVariants !== null && {publishMlVariants: publisVariants}),
            ...(activeToPublish !== null && {activeToPublish: activeToPublish})
        }]
        }, {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
        }
      )
}

export default updateCategorie