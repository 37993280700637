import React from 'react'
import { useState, useEffect } from 'react';
import ListOfficalStores from '../../../../../../assets/css/components/admin/marketplace-to-marketplace/products/ListOfficalStores';



export default function ViewsBeforeToSend({ isLoad, setIsLoad, paramsToSend, listinType, officialStoreId, setOfficialStoreId, stores }) {
    const [idSelected, setIdSelected] = useState([]);

    useEffect(() => {
      if(officialStoreId) {
        setIdSelected(officialStoreId)
      }
    }, [officialStoreId])
    
    
    const selectStore = (id) => {
        console.log('---', id);
        // hacemos la copia del array
        let copyArrayStore = [...officialStoreId];
        // ahora validamos si el id seleccionado existe ya en la data
        const existsValue = copyArrayStore.find(v => v === id);
        console.log('el valor', copyArrayStore);
        if(existsValue === 'undefined' || !existsValue) {
            console.log('ok undefined');
            copyArrayStore.push(id);
            setIdSelected([...idSelected, id]);
            setOfficialStoreId(copyArrayStore)
        } else {
            console.log('deberia borrar');
            copyArrayStore = copyArrayStore.filter(v => v !== id);
            setIdSelected(copyArrayStore);
            setOfficialStoreId(copyArrayStore)
        }
    }
    return (
        <div style={{width: "100%", height: "auto"}}>
            <ListOfficalStores.ContListProd>
                <ListOfficalStores.ListProduts>
                {
                    (stores) &&
                    (stores.length > 0) &&
                        stores.map((v => {
                            return (
                                <ListOfficalStores.ItemsListProduts 
                                    onClick={() => selectStore(v.id)} 
                                    key={v.id}
                                    active={idSelected.includes(v.id)}
                                    >
                                        {v.name}
                                </ListOfficalStores.ItemsListProduts>
                            )
                        }))

                }
                </ListOfficalStores.ListProduts>
            </ListOfficalStores.ContListProd>
            
        </div>
    )
}
