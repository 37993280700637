import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
const getProducstCampaing = async (entId, axiosCancelTokenSource, campId) => {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/df-camp/${entId}/${campId}/campaign-products`, {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}
export default getProducstCampaing;