import styled from 'styled-components';
const cont = styled.div`
display:flex;
justify-content:center;
align-items:center;
width:100%;
padding:10px;
`;
const contInputLabel = styled.div`
width: ${props=> props.wd || '100%'};
display:flex;
flex-direction: column;
justify-content:center;
align-items:flex-start;
margin-top: ${props=> props.mt || '0'};
@media (max-width: 989px) {
    width:100%;
}
`;
const input = styled.input`
    padding-left:10px;
    font-family: "Berlin Rounded";
    width: ${props=> props.wd || '100%'};
    margin-left: ${props=> props.ml || '0'};
    margin-right: ${props=> props.mr || '0'};
    height: 38px;
    border-radius: 5px;
    background: #fff;
    border: 0.5px solid rgba(112, 112, 112, 0.7);
    outline: none !important;
    color: rgba(112, 112, 112, 0.61);
    font-size: 12pt;
    font-weight: 300;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
        }
    @media(max-width: ${props => props.inputMq || '989px'}) {
        width:100%;
    }
`;

const select = styled.select`
    padding-left:10px;
    padding-right:10px;
    font-family: "Berlin Rounded";
    width: ${props=> props.wd || '100%'};
    margin-left: ${props=> props.ml || '0'};
    margin-right: ${props=> props.mr || '0'};
    height: 38px;
    border-radius: 5px;
    background: #fff;
    border: 0.5px solid rgba(112, 112, 112, 0.7);
    outline: none !important;
    color: rgba(112, 112, 112, 0.61);
    font-size: 12pt;
    font-weight: 300;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
        }
        @media(max-width: ${props => props.selectMq || '989px'}) {
        width:100%;
    }
`;

const textArea = styled.textarea`
    padding-left:10px;
    padding-right:10px;
    font-family: "Berlin Rounded";
    width: 100%;
    margin-left: ${props=> props.ml || '0'};
    margin-right: ${props=> props.mr || '0'};
    height: ${props=> props.hg || '300px'};
    border-radius: 5px;
    background: #fff;
    border: 0.5px solid ${props=> props.bdgc || 'rgba(112, 112, 112, 0.7)'};
    outline: none !important;
    color: #707070;
    font-size: 12pt;
    font-weight: 300;
    resize:none;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
    &:focus {
        border: 0.5px solid rgba(115, 103, 240, 0.7);
    }
        /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg,#7367f0,rgba(115,103,240,.7));
        border-radius: 50px;
        
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {        
        box-shadow: 2px 0px 6px #7367f0;
        
    }
`;
const contForm = styled.div`
padding:10px;
margin-left:auto;
margin-right: auto;
margin-top:10px;
background:#FFFFFF;
border-radius:5px;
min-width: 300px;
width: 700px;
filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
display:flex;
flex-direction: column;
justify-content:center;
align-items:center;

`;
const contRowForm = styled.div`
display: flex;
align-items: center;
justify-content: ${props => props.j || 'space-between'};
width:100%;
@media (max-width: 989px) {
    display:block;
}
`;
const textBody = styled.p`
margin-bottom: ${props=> props.mb || '0'};
color: ${props=> props.cl || '#707070'};
font-size: ${props=> props.size || '12pt'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
font-weight: ${props=> props.fw || 'lighter'};
margin-top: ${props=> props.mt || '10px'};
text-align:justify;
@media (max-width:500px){
    margin-left: ${props=> props.mlr || '0'};
    margin-right: ${props=> props.mrr || '0'};
}
`;

const btnActionFooter = styled.button`
margin-top:10px;
border: 0.5px solid;
border-color: ${props=> props.bc || '#707070'};
background-color: ${props=> props.bgc || 'transparent'};
padding: 8px 16px;
font-size: 12pt;
margin-left: ${props=> props.ml || '0'};
color: ${props=> props.cl || '#707070'};
border-radius:5px;
font-weight:600;
outline:none !important;
`;

const LinkeoMasivoCss = {
    cont,
    input,
    select,
    textArea,
    contForm,
    contRowForm,
    textBody,
    contInputLabel,
    btnActionFooter
}

export default LinkeoMasivoCss;