import styled from 'styled-components';

const cont = styled.div`
width: 100%;
padding:10px;
display: flex;
justify-content:flex-start;
align-items:  flex-start;
flex-direction: column;
`;

const formCont = styled.div `
width: 100%;
background: transparent;
display:flex;
justify-content:flex-start;
align-items:center;
align-self:center;
margin-top:10px;
margin-left: 10px;
margin-right: 10px;
@media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
`;

const contSiderBar = styled.div `
padding: 10px 20px;
display: flex;
flex-direction: column;
height: 100%;
margin-top:0;
margin-bottom:0;
justify-content: space-evenly;
align-items: center;
width: 300px;
background: transparent;
@media (max-width: 800px) {
    width: 100%;
    border-right: none;
    flex-direction: row;
    justify-content: space-between;
    padding:10px;
}
`;
const contBodyForm = styled.div `
width: 100%;
background: transparent;
display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: column;
align-self: flex-start;
border-radius: 5px;

`;

const contInputAndLabel = styled.div`
display:flex;
flex-direction: ${props=> props.fd || 'column'};
justify-content: flex-start;
align-items: center;
width:100%;
margin-top: ${props=> props.mt || '0'};
&:hover{
    cursor: pointer;
}
@media (max-width: 800px) {
    flex-direction: column;
    margin-top: 10px;
}
`;

const btn = styled.button`
padding:${props => props.pd || "8px 16px"};
display:flex;
width: ${props => props.w || "100%"};
height: ${props => props.h || "100%"};
justify-content:center;
align-items:center;
font-size:${props => props.fontSize || "12pt"};
outline:none !important;
color: ${props => props.active ? "#FFFFFF" : "#707070"};
background: ${props => props.active ?  'linear-gradient(#7367f0 0%, rgba(115, 103, 240, 0.7) 100%)':  "#dddaf2"};
border:none;
border-radius: 5px;
margin-top: ${props=> props.mt || '0'};
font-weight: ${props=> props.fw || 'lighter'};
box-shadow : ${props => props.active ? '2px 0px 6px #7367f0' : 'none'};
transition: all ease-in-out 0.2s;
`;

const text = styled.p`
margin-bottom:  ${props=> props.mb || '0'};;
color: ${props=> props.active ? "#7367F0" : '#707070'};
font-size: ${props=> props.size || '12px'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
font-weight: ${props=> props.active ? '500' : 'lighter'};
margin-top: ${props=> props.mt || '0'};
transition: all ease-in-out 0.2s;
`;

const PostProductCss = {
    cont,
    formCont,
    contSiderBar,
    contBodyForm,
    contInputAndLabel,
    btn,
    text
}

export default PostProductCss;