import React from 'react'
import { useState, useEffect } from 'react'
import { Redirect } from 'react-router'
import ConfigStyled from '../../../assets/css/components/admin/config/ConfigStyled'
import verifyAccessRole from '../../../assets/js/verifyAccessRole'
import Routes from '../../../Routes'
import ContRenderStep from './componets/ContRenderStep'
import MenuSidebar from './componets/MenuSidebar';

export default function IndexConfig() {
    const [redirect, setRedirect] = useState(null);
    const [step, setStep] = useState(1);
    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 6);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);
    return (
        <ConfigStyled.ContainerGeneral>
            {redirect}
            <ConfigStyled.header>
                <ConfigStyled.text size="15pt" fw="600">
                    Configuracion de la cuenta
                </ConfigStyled.text>
            </ConfigStyled.header>
            <ConfigStyled.ContMenuAndForms>
                <MenuSidebar 
                step={step}
                setStep={setStep} 
                />
                <ContRenderStep step={step} />
            </ConfigStyled.ContMenuAndForms>
        </ConfigStyled.ContainerGeneral>
    )
}
