import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import PostProductCss from '../../../../assets/css/components/admin/products/post-product/PostProductCss';
import { Link, Redirect  } from 'react-router-dom';
import SelectCategorie from './steps/SelectCategorie';
import BuildForm from './steps/BuildForm';
import AddImages from './steps/AddImages';
import SendData from './steps/SendData';
import Routes from '../../../../Routes';
import verifyAccessRole from '../../../../assets/js/verifyAccessRole';

export default function IndexPost({ match }) {
    const [step, setStep] = useState(1);
    const [redirect, setRedirect] = useState(null);
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [category, setCategory] = useState(null);
    const [valuesCategorys, setValuesCategorys] = useState(null)
    const [listOfLastParents, setListOfLastParents] = useState([]);
    const [dataProductOdoo, setDataProductOdoo] = useState(null);
    const prodId = match.params.idProduct;
    //const entId = globalContext.currentEnterpriseId;
    const [isLoader, setIsLoader] = useState(true);
    const [imgByAdd, setImgByAdds] = useState(null);
    const [imgInArraySend, setImgInArraySend] = useState([]);

    
    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 22);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);
        /**
         * Handle the axios error
         * @param {Error} err The thrown error
         * @param {string} msg A message to show in the modal
         * @param {boolean} consoleLog If should log the error in the console
         */
        const handleAxiosError = (err, msg = null, consoleLog = true) => {
                if (axios.isCancel(err)) return; //Don't show axios cancel error
        
                if (err.response && err.response.data) {
                    if (err.response.data.message) {
                        globalContext.showModalWithMsg(err.response.data.message);
                    } else {
                        globalContext.showModalWithMsg(err.response.data);
                    }
                } else if (msg) {
                    globalContext.showModalWithMsg(msg);
                }
        
                if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
        };
    // * get product odoo
    const getProductOdoo = (entId, prodId) => {
        axios
            .get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}/${prodId}`,
            {
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
                params: {
                variants: true,
                linked: true,
                },
            }
            )
            .then((resp) => {
                const data = resp;
                setDataProductOdoo(data.data.data)
                //console.log('respuesta prod odoo',resp);
            })
            .catch((err) => {
                handleAxiosError(err, 'error al traer el producto de odoo')
                console.log('error odoo',err);
            })
    }
    
    const nextStep = () => {
        setStep(step + 1);
    }
    
    const backStep = () => {
        setStep(step - 1);
    }
    
    const clearCategory = () => {
        if (step > 1) {
            setListOfLastParents([])
            setCategory(null);
            setValuesCategorys(null)
        }
    }
    const renderStep = () => {
        switch (step) {
            case 1:
                return  <SelectCategorie 
                listOfLastParents={listOfLastParents}
                setListOfLastParents={setListOfLastParents}
                setCategoryAttri={setCategory}
                nextStep={nextStep}
                />;
                case 2:
                    return <BuildForm
                    listOfLastParents={listOfLastParents}
                    valuesCategorys={valuesCategorys}
                    backStep={backStep}
                    isLoader={isLoader}
                    buildProp={buildProp}
                    clearCategory={clearCategory}
                    />;
            case 3:
                return <AddImages 
                        dataProduct={dataProductOdoo} 
                        backStep={backStep}
                        imgByAdd={imgByAdd}
                        setImgByAdds={setImgByAdds}
                        imgInArraySend={imgInArraySend}
                        setImgInArraySend={setImgInArraySend}
                        />;
                case 4:
                    return <SendData
                        imgInArraySend={imgInArraySend}
                        listOfLastParents={listOfLastParents}
                        valuesCategorys={valuesCategorys}
                        />;
            default:
                return 'paso uno';
            }
        };

    /**
     * 
     * ! %%%%%%%%%%%%%%%%%%%%%%%%%%% method for step two %%%%%%%%%%%%%%%%%%%%%%%%%%%
     */
    const buildProp = (v) => {
        setValuesCategorys(v)
    }
    
    const getAttribsbyCategory = (category, entId) => {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/linio-cat/catAtt/${entId}/${category}`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                }
            }).then((resp) => {
                const data = resp.data.data;
                let arrayinsert = [];
                //console.log('mi dd',data);
                data.map((v) => {
                    return (arrayinsert.push(
                        {
                            label: v.Label, 
                            inputType: v.InputType, 
                            valuePlaceholder: 'Ejemplo: '+v.ExampleValue, 
                            maxLength: v.MaxLength, 
                            nameInput: v.Name, 
                            options: v.Options.Option,
                            isMandatory: v.isMandatory,
                            value: '',
                            textByDropDown: '',
                        }
                        ))
                })
                buildProp(arrayinsert)
                setIsLoader(false);
            }).catch((err) => {
                console.log(err)
                buildProp(null);
                setIsLoader(false);
            })
        }

    /**
     * 
     * ! %%%%%%%%%%%%%%%%%%%%%%%%%%% method for step two %%%%%%%%%%%%%%%%%%%%%%%%%%%
     */

    
    useEffect(() =>{
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return; 
        getProductOdoo(entId, prodId)
        if (!category) return; 
        getAttribsbyCategory(category, entId)
    }, [prodId,globalContext.currentEnterpriseId,category]);

        return (
            <PostProductCss.cont>
                {redirect}
            <div className="d-flex justify-content-start align-items-center">
                <Link to="/admin/productos/list-products" style={{textDecoration: "none"}}>
                    <PostProductCss.text size="18pt" style={{fontWeight:500}}>
                        Productos
                    </PostProductCss.text>
                </Link>
                <PostProductCss.text ml="5px" size="18pt" style={{fontWeight:500}}>
                    |
                </PostProductCss.text>
                <PostProductCss.text ml="5px" size="18pt" style={{fontWeight:500}}>
                    Publicaion de productos
                </PostProductCss.text>
            </div>
            <PostProductCss.formCont>
                <PostProductCss.contSiderBar>

                    <PostProductCss.contInputAndLabel fd="row" onClick={() => {setStep(1); clearCategory()}}>
                        <PostProductCss.btn pd="10px" w="50px" h="50px" fw="500" active={1 === step}>
                            1
                        </PostProductCss.btn>
                        <PostProductCss.text ml="10px" active={1 === step}>
                            Seleccione su categoria
                        </PostProductCss.text>
                    </PostProductCss.contInputAndLabel>
                    <PostProductCss.contInputAndLabel fd="row" mt="10px" onClick={() => {setStep(2)}}>
                        <PostProductCss.btn pd="10px" w="50px" h="50px" fw="500" active={2 === step} >
                            2
                        </PostProductCss.btn>
                        <PostProductCss.text ml="10px" active={2 === step}>
                            Detalles del producto
                        </PostProductCss.text>
                    </PostProductCss.contInputAndLabel>
                    <PostProductCss.contInputAndLabel fd="row" mt="10px" onClick={() => {setStep(3)}}>
                        <PostProductCss.btn pd="10px" w="50px" h="50px" fw="500" active={3 === step} >
                            3
                        </PostProductCss.btn>
                        <PostProductCss.text ml="10px" active={3 === step}>
                            Agregar imagenes
                        </PostProductCss.text>
                    </PostProductCss.contInputAndLabel>
                    <PostProductCss.contInputAndLabel fd="row" mt="10px" onClick={() => {setStep(4)}}>
                        <PostProductCss.btn pd="10px" w="50px" h="50px" fw="500" active={4 === step} >
                            4
                        </PostProductCss.btn>
                        <PostProductCss.text ml="10px" active={4 === step}>
                            Publicar
                        </PostProductCss.text>
                    </PostProductCss.contInputAndLabel>
                </PostProductCss.contSiderBar>
                <PostProductCss.contBodyForm>
                    {renderStep()}
                </PostProductCss.contBodyForm>
            </PostProductCss.formCont>
        </PostProductCss.cont>
    )
}
