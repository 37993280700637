import styled from "styled-components";

const ContModal = styled.div`
position: fixed;
top:0;
right:0;
bottom:0;
left:0;
width:100%;
height:100%;
background: rgba(112, 112, 112, 0.38);
z-index:100000;
display: ${props=>props.d || 'none'};
justify-content:center;
align-items:center;
padding:10px;
overflow: auto;
`
const ModalProduct = styled.div`
display: flex;
flex-direction: column;
justify-content:flex-start;
align-items:center;
background-color: #ffffff;
border-radius:5px;
min-width:300px;
min-height: 500px;
margin-top:auto;
margin-bottom:auto;
width:80%;
`
const headerModal = styled.div`
padding:10px;
height: 60px;
width:100%;
background:#F5F5F6;
display: flex;
justify-content:flex-start;
align-items:center;
align-self: flex-start;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
`
const footerModal = styled.div`
padding:10px;
height: 60px;
width:100%;
display: flex;
justify-content:space-between;
align-items:center;
align-self: flex-end;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
border-top:0.5px solid rgba(112, 112, 112, 0.5);
margin-top: auto;
`;
const btnCloseModal = styled.button`
width:38px;
height: 38px;
border-radius:50px;
border:none;
color: #ffffff;
background-color: #7367f0;
display:flex;
justify-content:center;
align-items:center;
outline:none !important;
cursor: pointer;
margin-right: auto;
`
const textHeader = styled.p`
margin-bottom: 0;
color: #707070;
font-size: ${props=> props.size || '12px'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
`
const btnActionFooter = styled.button`
border: 0.5px solid;
border-color: ${props=> props.bc || '#707070'};
background-color: ${props=> props.bgc || 'transparent'};
padding: 8px 16px;
font-size: 12pt;
margin-left: ${props=> props.ml || '0'};
color: ${props=> props.cl || '#707070'};
border-radius:5px;
font-weight:600;
outline:none !important;
`;
const textBody = styled.p`
margin-bottom: ${props=> props.mb || '0'};
color: ${props=> props.cl || '#707070'};
font-size: ${props=> props.size || '12pt'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
font-weight: ${props=> props.fw || 'lighter'};
margin-top: ${props=> props.mt || '10px'};
text-align:justify;
@media (max-width:500px){
    margin-left: ${props=> props.mlr || '0'};
    margin-right: ${props=> props.mrr || '0'};
}
`;
const bodyModal = styled.div`
width:100%;
min-width:300px;
display:flex;
flex-direction:column;
justify-content: center;
align-self:flex-start;
.cont-text-body-modal {
    width:100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left:10px;
    padding-right:10px;
    margin-left:auto;
    margin-right:auto;
}
@media (max-width:550px){
    .cont-text-body-modal {
        flex-direction: column;
        justify-content: space-around;
        align-content: flex-start;
        align-items: flex-start;
    }
}
.load-screen {
        background-color: #ffffff;
        height:300px;
        width:100%;
        display:flex;
        justify-content:center;
        align-items:center;
        z-index:100000;
        .spinner-border {
            border: .25em solid #7367f0;
            border-right-color: transparent;
        }
    }
`;
/**
 * list categories style
 */
const categoriesList = styled.div`
.contenerdor-categories {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-around;
    padding:10px;
    .grid-text-categorie {
        width:100%;
        max-width:100%;
        display: grid;
        grid-gap: 0.5rem;
        grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
        @media (min-width: 300px) {
            justify-content: center;
            grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
        }
        @media (min-width: 900px) {
            justify-content:flex-start  ;
            grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
        }
        @media (min-width: 1044px) {
            justify-content:flex-start  ;
            grid-template-columns: repeat(auto-fit, minmax(50px, 200px));
        }
    }
}
.header {
    align-self: flex-start;
    font-size: 18pt;
    color: #707070;
    
}
.cont-categories {
    width: 100%;
    height: 448px;
    border-radius: 5px;
    background: #fff;
    border: 0.5px solid rgba(112,112,112, 0.70);
    padding:10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .tittle {
        color: #707070;
        font-weight:300;
        font-size: 15pt;
        align-self: flex-start;
    }
    .header-cont-categories {
        width:100%;
        display:flex;
        justify-content: flex-start;
        align-items:center;
    }
}
`;
const bodyCategories = styled.div`

    width:100%;
    height:300px;
    overflow: hidden scroll;
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(94, 84, 142);
      border-radius: 50px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #7367f0;
    }
    .pagination {
        margin-bottom: 0px !important;
    }
    .list-c {
        list-style: none;
        padding:0;
        li {
            text-align: left;
            border-bottom: 0.3px solid rgba(112, 112, 112, 0.1);
            padding: 15px 10px;
            transition: all ease 0.2s;
        &:hover {
            background-color: rgba(112, 112, 112, 0.3);
            cursor:pointer;
        }
        }
    }
    .load-screen {
        background-color: #ffffff;
        height:300px;
        width:100%;
        display:none;
        justify-content:center;
        align-items:center;
        z-index:100000;
        .spinner-border {
            border: .25em solid #7367f0;
            border-right-color: transparent;
        }
    }
    .load-screen.active {
        display:flex;
    }
    .list-c.active{
        display:none;
    }
    .msm-no-more {
        width:100%;
        color:#7367f0;
        text-align: center;
    }
`;
const input = styled.input`
    padding-left:10px;
    font-family: "Berlin Rounded";
    width: ${props=> props.wd || '100%'};
    margin-left: ${props=> props.ml || '0'};
    margin-right: ${props=> props.mr || '0'};
    height: 38px;
    border-radius: 5px;
    background: #fff;
    border: 0.5px solid rgba(112, 112, 112, 0.7);
    outline: none !important;
    color: rgba(112, 112, 112, 0.61);
    font-size: 12pt;
    font-weight: 300;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
        }
    @media(max-width: ${props => props.inputMq || '989px'}) {
        width:100%;
    }
`;
const select = styled.select`
    padding-left:10px;
    padding-right:10px;
    font-family: "Berlin Rounded";
    width: ${props=> props.wd || '100%'};
    margin-left: ${props=> props.ml || '0'};
    margin-right: ${props=> props.mr || '0'};
    height: 38px;
    border-radius: 5px;
    background: #fff;
    border: 0.5px solid rgba(112, 112, 112, 0.7);
    outline: none !important;
    color: rgba(112, 112, 112, 0.61);
    font-size: 12pt;
    font-weight: 300;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
        }
        @media(max-width: ${props => props.selectMq || '989px'}) {
        width:100%;
    }
`;
const textArea = styled.textarea`
    padding-left:10px;
    padding-right:10px;
    font-family: "Berlin Rounded";
    width: 100%;
    margin-left: ${props=> props.ml || '0'};
    margin-right: ${props=> props.mr || '0'};
    height: ${props=> props.hg || '300px'};
    border-radius: 5px;
    background: #fff;
    border: 0.5px solid ${props=> props.bdgc || 'rgba(112, 112, 112, 0.7)'};
    outline: none !important;
    color: #707070;
    font-size: 12pt;
    font-weight: 300;
    resize:none;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
    &:focus {
        border: 0.5px solid rgba(115, 103, 240, 0.7);
    }
        /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg,#7367f0,rgba(115,103,240,.7));
        border-radius: 50px;
        
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {        
        box-shadow: 2px 0px 6px #7367f0;
        
    }
`;

const buttonBack = styled.button`
border-radius: 5px;
background: #ffffff;;
outline: none!important;
border:1px solid #7367f0;
color: #7367f0;
font-size: 12pt;
font-weight: 400;
padding: 6px 18px;
transition: all ease 0.2s;
margin-right:10px;
&:hover {
    background-color:#7367f0;
    color:#ffffff;
}
`;
/**
 * styles forms
 */
const contForm = styled.div`
padding:10px;
margin-left:auto;
margin-right: auto;
margin-top:10px;
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`;
const contRowForm = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width:100%;
@media (max-width: 989px) {
    display:block;
}
`;
/**
*succes styles
 */
const contSuccess = styled.div`
padding:15px;
`;

const listImagesCont = styled.div`
margin-top:10px;
width:100%;
min-width:300px;
max-width: 100%;
padding:10px;

`;

const listImagesBody = styled.div`
width:${props => props.w || '100%'};
max-width:100%;
background: #f5f5f6;
padding:10px;
margin-left:auto !important;
margin-right:auto !important;
`;
const listImages = styled.ul`
width:100%;
overflow-x: auto;
max-width:100%;
border-radius: 5px;
background: #f5f5f6;
border: 1px solid #f5f5f6;
min-height:${props => props.minh || '140px'};
height:${props => props.h || 'auto'};
display: flex;
align-items: center;
justify-content: flex-start;
list-style: none;
margin-bottom: 0;
padding: 10px 0;
padding-left: 0;
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(94, 84, 142);
      border-radius: 50px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #7367f0;
    }
`;

const listImageCont = styled.div`
background-image: url(${props=> props.img ? `${props.img}` : ""});
min-width: ${props => props.minw || '140px'};
max-width: ${props => props.maxw || '140px'};
background-repeat: no-repeat;
background-size: contain;
background-color: #FFFFFF;
min-height: ${props => props.minh || '140px'};
max-height: ${props => props.maxh || '140px'};
background-position: center;
border: 1px solid ${props => props.active ? 'transparent' : '#707070'};
border-radius: 5px;
box-sizing:border-box;
box-shadow: ${props => props.active ? '0px 0px 9px 2px #7367f0' : '0px 0px 0px transparent'};
transition: all ease-in-out 0.3s;
&:hover {
    cursor: pointer;
}
`;
const iconStatus = styled.span`
display: flex;
align-items: center;
justify-content: center;
margin-right: 5px;
margin-top: 5px;
border-radius:50px;
width:20px;
height:20px;
color: #FFFFFF;
font-size: 10pt;
background-color: ${props=> props.status === 'send' ? '#7367F0' :  '#ea5455'};
`;
const contRowCard = styled.div`
display: flex;
align-items: center;
justify-content: center;
width:100%;
margin-top: 20px;
margin-bottom: 20px;
@media (max-width: 989px) {
    display:block;
}
`;
const cardTotal = styled.div`
margin-left: 10px;
margin-right:10px;
min-width: 300px;
width: auto;
min-height: 100px;
border-radius: 5px;
background: ${props => props.bgc || 'linear-gradient(#7367f0 0%, rgba(115, 103, 240, 0.7) 100%)'};
box-shadow: ${props => props.s || '2px 0px 6px #7367f0'};
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
padding: 10px;
`;

const carouselImagesProduct = styled.ul`
width:100%;
overflow-y: auto;
max-width:100%;
border-radius: 5px;
background: #f5f5f6;
min-height:90px;
display: flex;
align-items: center;
justify-content: flex-start;
list-style: none;
margin-bottom: 0;
padding: 10px 0;
padding-left: 0;
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: #FFFFFF;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(#7367f0 0%, rgba(115, 103, 240, 0.7) 100%);
      border-radius: 50px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(#7367f0 0%, rgba(115, 103, 240, 0.7) 100%);
    }
`;

const carouselImageProducts = styled.li`
display:flex;
justify-content: flex-end;
background-image: url(${props=> props.img ? `${props.img}` : ""});
min-width: 80px;
max-width: 80px;
background-repeat: no-repeat;
background-size: contain;
background-color: #FFFFFF;
min-height: 80px;
max-height: 80px;
background-position: center;
margin-left: 5px;
margin-right:5px;
border: 1px solid ${props => props.active ? 'transparent' : '#FFFFFF'};
border-radius: 5px;
box-sizing:border-box;
box-shadow: ${props => props.active ? '0px 0px 9px 2px #7367f0' : '0px 0px 0px transparent'};
transition: all ease-in-out 0.3s;
`;

const BtnDeleteProductList = styled.button`
width: 140px;
height: 38px;
border: none;
border-radius: 5px;
outline: none !important;
display: flex;
justify-content: center;
align-items: center;
margin-top: 5px;
background-color: #ea5455;
color: #FFFFFF;
font-weight: 500px;
font-size: 11pt;
`;

const ContBtnAndImg = styled.li`
margin-left: 5px;
margin-right:5px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: auto;
max-width: 140px;
`;

const PublishProduct = {
    ContBtnAndImg,
    BtnDeleteProductList,
    ModalProduct,
    ContModal,
    headerModal,
    footerModal,
    btnCloseModal,
    textHeader,
    btnActionFooter,
    bodyModal,
    textBody,
    categoriesList,
    bodyCategories,
    buttonBack,
    input,
    contForm,
    contRowForm,
    select,
    textArea,
    contSuccess,
    listImagesCont,
    listImages,
    iconStatus,
    listImageCont,
    listImagesBody,
    cardTotal,
    contRowCard,
    carouselImagesProduct,
    carouselImageProducts
}
export default PublishProduct;