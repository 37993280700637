import React from 'react'
import UpdateAndPublish from '../../../../assets/css/components/admin/update-and-publish/UpdateAndPublis'
import { FiShoppingCart } from 'react-icons/fi'
import { BiSelectMultiple, BiErrorAlt, BiPauseCircle, BiCubeAlt } from 'react-icons/bi'
import { BsCloudDownload } from 'react-icons/bs'
import { MdSystemUpdateAlt } from 'react-icons/md'
export default function CardStadistic(props) {
    const {title, valueNumber, icon, isLoader} = props;

    const iconCard = (valor) => {
        switch (valor) {
            case "1":
                return (
                    <UpdateAndPublish.ContIconCardStadistic cl="rgba(255, 159, 67, 0.1)" cli="rgb(255, 159, 67)">
                        <FiShoppingCart />
                    </UpdateAndPublish.ContIconCardStadistic>
                );
            case "2":
                return (
                    <UpdateAndPublish.ContIconCardStadistic cl="rgba(0, 207, 232, 0.1)" cli="rgb(0, 207, 232)">
                        <BiSelectMultiple />
                    </UpdateAndPublish.ContIconCardStadistic>
                );
            case "3":
                return (
                    <UpdateAndPublish.ContIconCardStadistic cl="rgba(115, 103, 240, 0.1)" cli="#7367f0">
                        <BsCloudDownload />
                    </UpdateAndPublish.ContIconCardStadistic>
                );
            case "4":
                return (
                    <UpdateAndPublish.ContIconCardStadistic cl="rgba(40, 199, 111, 0.1)" cli="#28c76f">
                        <MdSystemUpdateAlt  />
                    </UpdateAndPublish.ContIconCardStadistic>
                )
            case "5":
                return (
                    <UpdateAndPublish.ContIconCardStadistic cl="rgba(234, 84, 85, 0.1)" cli="#ea5455">
                        <BiErrorAlt />
                    </UpdateAndPublish.ContIconCardStadistic>
                )
            case "6":
                return (
                    <UpdateAndPublish.ContIconCardStadistic cl="rgba(234, 84, 85, 0.1)" cli="#ea5455">
                        <BiPauseCircle />
                    </UpdateAndPublish.ContIconCardStadistic>
                )
            case "7":
                return (
                    <UpdateAndPublish.ContIconCardStadistic cl="rgba(255, 159, 67, 0.1)" cli="rgb(255, 159, 67)">
                        <BiCubeAlt />
                    </UpdateAndPublish.ContIconCardStadistic>
                )
            default:
                return (
                    <UpdateAndPublish.ContIconCardStadistic cl="rgba(255, 159, 67, 0.1)" cli="rgb(255, 159, 67)">
                        <FiShoppingCart />
                    </UpdateAndPublish.ContIconCardStadistic>
                );
        }
    }
    return (
        <UpdateAndPublish.CardStadistic isLoader={isLoader}>
            {iconCard(icon)}
            <UpdateAndPublish.Text size="15pt" fw="700">
                {valueNumber}
            </UpdateAndPublish.Text>
            <UpdateAndPublish.Text size="11pt">
                {title}
            </UpdateAndPublish.Text>
        </UpdateAndPublish.CardStadistic>
        
    )
}
