import { keyAccessToken, keyUserId } from "../../../../assets/js/SessionStorageNames";
import axios from 'axios';
import hasErrorData from "./hasErrorData";
import pausePublication from "./pausePublication";
import activePublication from "./activePublication";
import handleAxiosError from "./handleAxiosError";
import getDataScraper from "./getDataScraper";
import getDataMonitor from "./getDataMonitor";
//todo verificar el state de incrementar el conteo de scrapeados;
const downloadProduct = async (entId, url, dataCard, setDataProducts, axiosCancelTokenSource, globalContext, setTotalScraped, totalScraped) => {

    if (!entId) {
        console.log('le falto empresa'); return;
    };
    if (!url) {
        console.log('le falto url'); return;
    };
    const processCheckIntervalTime = 5000;
    const resp = await getDataScraper(url, entId, axiosCancelTokenSource);
    await new Promise((resolveOne, rejectOne) => {
        try {
            const data = resp.data.data;
            if (data.monitor) {
                //If the process ID was received, start monitoring
                let waitingMonitorResponse = false;
                const interval = setInterval(async () => {
                    if (!waitingMonitorResponse) {
                        waitingMonitorResponse = true;
                        //
                        const resp2 = await getDataMonitor(data, axiosCancelTokenSource);
                        const datamazonScrap = resp2.data.data;
                        // verificamops q se acabe el intervval
                        if (datamazonScrap.advance >= 100) {
                            //*varificamos q todo este bien y lanzamos lo otro
                            //? instanciamos la data del producto y hacemos l acopia del array
                            const dataProducto = datamazonScrap.data.productData;
                            let ArrayCopy = [...dataCard];
                            if (dataProducto.variants.length > 0) {
                                //* 1-search the position of object in the arr
                                const indexObject = dataCard.findIndex(v => v._id === dataProducto._id);
                                //* 2-if we got the index will instans the data corresponding in a variable
                                const objectUpdate = ArrayCopy[indexObject];
                                //* 3-now we'll update the data in the old object
                                //* 3.3- we make a variable for the errors
                                let hasNewError = false;
                                for (let variant of objectUpdate.variants) {
                                    const indexFind = ArrayCopy[indexObject].variants.findIndex(v => v.asin === variant.asin);
                                    if (variant.ml) {
                                        //? validate that the data does not have any errors
                                        //console.log('funciona', dataProducto.variants.find(v => v.asin === variant.asin))
                                        hasNewError = hasErrorData(dataProducto.variants.find(v => v.asin === variant.asin))
                                        if (hasNewError) {
                                            // ? if the data has any error we will paused the product in ml
                                            pausePublication(entId, variant.ml._id)
                                                .then(() => {
                                                    //todo add el set para actualizar
                                                    ArrayCopy[indexObject].variants[indexFind].ml.attrib.status = 'paused';
                                                }).catch(err => {
                                                    //todo agg algo para q se sepa que dio erro ral pausar
                                                    console.log('error al pausar', err)
                                                }).finally(() => {
                                                    const dataVariantsUpdate = dataProducto.variants[indexFind];
                                                    ArrayCopy[indexObject].variants[indexFind] = {
                                                        asin: dataVariantsUpdate.asin,
                                                        brand: dataVariantsUpdate.brand,
                                                        categoryTree: dataVariantsUpdate.categoryTree,
                                                        dealPrice: dataVariantsUpdate.dealPrice,
                                                        deliveryDays: dataVariantsUpdate.deliveryDays,
                                                        desc: dataVariantsUpdate.desc,
                                                        details: dataVariantsUpdate.details,
                                                        dimensions: dataVariantsUpdate.dimensions,
                                                        features: dataVariantsUpdate.features,
                                                        images: dataVariantsUpdate.images,
                                                        ml: ArrayCopy[indexObject].variants[indexFind].ml,
                                                        oldPrice: dataVariantsUpdate.oldPrice,
                                                        price: dataVariantsUpdate.price,
                                                        shippingPrice: dataVariantsUpdate.shippingPrice,
                                                        stock: dataVariantsUpdate.stock,
                                                        title: dataVariantsUpdate.title,
                                                        url: dataVariantsUpdate.url,
                                                        variations: dataVariantsUpdate.variations,
                                                        weight: dataVariantsUpdate.weight,
                                                        itHasScraping: true,
                                                        itHasUpdateMl: false,
                                                    }
                                                })
                                        } else {
                                            // ? if not have error just update the data and 
                                            // ? verific if the publications is paused
                                            if (variant.ml.attrib.status === 'paused') {
                                                activePublication(entId, variant.ml._id)
                                                    .then(() => {
                                                        ArrayCopy[indexObject].variants[indexFind].ml.attrib.status = 'active';
                                                        console.log('se pudo activar', ArrayCopy[indexObject].variants[indexFind])
                                                    }).catch(() => {
                                                        console.log('no se pudo activar')
                                                    }).finally(() => {
                                                        //update the data
                                                        const dataVariantsUpdate = dataProducto.variants[indexFind];
                                                        ArrayCopy[indexObject].variants[indexFind] = {
                                                            asin: dataVariantsUpdate.asin,
                                                            brand: dataVariantsUpdate.brand,
                                                            categoryTree: dataVariantsUpdate.categoryTree,
                                                            dealPrice: dataVariantsUpdate.dealPrice,
                                                            deliveryDays: dataVariantsUpdate.deliveryDays,
                                                            desc: dataVariantsUpdate.desc,
                                                            details: dataVariantsUpdate.details,
                                                            dimensions: dataVariantsUpdate.dimensions,
                                                            features: dataVariantsUpdate.features,
                                                            images: dataVariantsUpdate.images,
                                                            ml: ArrayCopy[indexObject].variants[indexFind].ml,
                                                            oldPrice: dataVariantsUpdate.oldPrice,
                                                            price: dataVariantsUpdate.price,
                                                            shippingPrice: dataVariantsUpdate.shippingPrice,
                                                            stock: dataVariantsUpdate.stock,
                                                            title: dataVariantsUpdate.title,
                                                            url: dataVariantsUpdate.url,
                                                            variations: dataVariantsUpdate.variations,
                                                            weight: dataVariantsUpdate.weight,
                                                            itHasScraping: true,
                                                            itHasUpdateMl: false,
                                                        }
                                                    })
                                            } else {
                                                const dataVariantsUpdate = dataProducto.variants[indexFind];
                                                ArrayCopy[indexObject].variants[indexFind] = {
                                                    asin: dataVariantsUpdate.asin,
                                                    brand: dataVariantsUpdate.brand,
                                                    categoryTree: dataVariantsUpdate.categoryTree,
                                                    dealPrice: dataVariantsUpdate.dealPrice,
                                                    deliveryDays: dataVariantsUpdate.deliveryDays,
                                                    desc: dataVariantsUpdate.desc,
                                                    details: dataVariantsUpdate.details,
                                                    dimensions: dataVariantsUpdate.dimensions,
                                                    features: dataVariantsUpdate.features,
                                                    images: dataVariantsUpdate.images,
                                                    ml: ArrayCopy[indexObject].variants[indexFind].ml,
                                                    oldPrice: dataVariantsUpdate.oldPrice,
                                                    price: dataVariantsUpdate.price,
                                                    shippingPrice: dataVariantsUpdate.shippingPrice,
                                                    stock: dataVariantsUpdate.stock,
                                                    title: dataVariantsUpdate.title,
                                                    url: dataVariantsUpdate.url,
                                                    variations: dataVariantsUpdate.variations,
                                                    weight: dataVariantsUpdate.weight,
                                                    itHasScraping: true,
                                                    itHasUpdateMl: false,
                                                }
                                            }
                                        }
                                    }
                                }
                                // afther end for we will update the data
                                setDataProducts(ArrayCopy);
                            }else {
                                let hasNewError = hasErrorData(dataProducto);
                                const indexFind = ArrayCopy.findIndex(v => v.asin === dataProducto.asin);
                                if (hasNewError) {
                                    // ? if has any error we will paused the publications
                                    pausePublication(entId, dataCard[indexFind].ml._id)
                                        .then(() => {
                                            //todo add el set para actualizar
                                            ArrayCopy[indexFind].ml.attrib.status = 'paused';
                                        }).catch(err => {
                                            //todo agg algo para q se sepa que dio erro ral pausar
                                            console.log('error al pausar', err)
                                        }).finally(() => {
                                            ArrayCopy[indexFind].asin = dataProducto.asin;
                                            ArrayCopy[indexFind].brand = dataProducto.brand;
                                            ArrayCopy[indexFind].categoryTree = dataProducto.categoryTree;
                                            ArrayCopy[indexFind].dealPrice = dataProducto.dealPrice;
                                            ArrayCopy[indexFind].deliveryDays = dataProducto.deliveryDays;
                                            ArrayCopy[indexFind].desc = dataProducto.desc;
                                            ArrayCopy[indexFind].details = dataProducto.details;
                                            ArrayCopy[indexFind].dimensions = dataProducto.dimensions;
                                            ArrayCopy[indexFind].features = dataProducto.features;
                                            ArrayCopy[indexFind].images = dataProducto.images;
                                            ArrayCopy[indexFind].oldPrice = dataProducto.oldPrice;
                                            ArrayCopy[indexFind].price = dataProducto.price;
                                            ArrayCopy[indexFind].shippingPrice = dataProducto.shippingPrice;
                                            ArrayCopy[indexFind].stock = dataProducto.stock;
                                            ArrayCopy[indexFind].title = dataProducto.title;
                                            ArrayCopy[indexFind].url = dataProducto.url;
                                            ArrayCopy[indexFind].weight = dataProducto.weight;
                                            ArrayCopy[indexFind].itHasScraping = true;
                                        })
                                } else {
                                    if (dataCard[indexFind].ml.attrib.status === 'paused') {
                                        activePublication(entId, dataCard[indexFind].ml._id)
                                            .then(() => {
                                                ArrayCopy[indexFind].ml.attrib.status = 'active';
                                            }).catch(() => {
                                                console.log('no se pudo activar')
                                            }).finally(() => {
                                                ArrayCopy[indexFind].asin = dataProducto.asin;
                                                ArrayCopy[indexFind].brand = dataProducto.brand;
                                                ArrayCopy[indexFind].categoryTree = dataProducto.categoryTree;
                                                ArrayCopy[indexFind].dealPrice = dataProducto.dealPrice;
                                                ArrayCopy[indexFind].deliveryDays = dataProducto.deliveryDays;
                                                ArrayCopy[indexFind].desc = dataProducto.desc;
                                                ArrayCopy[indexFind].details = dataProducto.details;
                                                ArrayCopy[indexFind].dimensions = dataProducto.dimensions;
                                                ArrayCopy[indexFind].features = dataProducto.features;
                                                ArrayCopy[indexFind].images = dataProducto.images;
                                                ArrayCopy[indexFind].oldPrice = dataProducto.oldPrice;
                                                ArrayCopy[indexFind].price = dataProducto.price;
                                                ArrayCopy[indexFind].shippingPrice = dataProducto.shippingPrice;
                                                ArrayCopy[indexFind].stock = dataProducto.stock;
                                                ArrayCopy[indexFind].title = dataProducto.title;
                                                ArrayCopy[indexFind].url = dataProducto.url;
                                                ArrayCopy[indexFind].weight = dataProducto.weight;
                                                ArrayCopy[indexFind].itHasScraping = true;
                                            })
                                    } else {
                                        ArrayCopy[indexFind].asin = dataProducto.asin;
                                        ArrayCopy[indexFind].brand = dataProducto.brand;
                                        ArrayCopy[indexFind].categoryTree = dataProducto.categoryTree;
                                        ArrayCopy[indexFind].dealPrice = dataProducto.dealPrice;
                                        ArrayCopy[indexFind].deliveryDays = dataProducto.deliveryDays;
                                        ArrayCopy[indexFind].desc = dataProducto.desc;
                                        ArrayCopy[indexFind].details = dataProducto.details;
                                        ArrayCopy[indexFind].dimensions = dataProducto.dimensions;
                                        ArrayCopy[indexFind].features = dataProducto.features;
                                        ArrayCopy[indexFind].images = dataProducto.images;
                                        ArrayCopy[indexFind].oldPrice = dataProducto.oldPrice;
                                        ArrayCopy[indexFind].price = dataProducto.price;
                                        ArrayCopy[indexFind].shippingPrice = dataProducto.shippingPrice;
                                        ArrayCopy[indexFind].stock = dataProducto.stock;
                                        ArrayCopy[indexFind].title = dataProducto.title;
                                        ArrayCopy[indexFind].url = dataProducto.url;
                                        ArrayCopy[indexFind].weight = dataProducto.weight;
                                        ArrayCopy[indexFind].itHasScraping = true;
                                    }
                                }
                                setDataProducts(ArrayCopy);
                            }
                            setTotalScraped(totalScraped++);
                            clearInterval(interval);
                            return resolveOne();
                        }
                        
                    // * julio mmgvo
                    waitingMonitorResponse = false;
                    console.log('No llego', datamazonScrap, url)
                    }
                }, processCheckIntervalTime);
            }else {
                return rejectOne('No tiene data Monitor')
            }
        }
        catch (err) {
            return rejectOne(err);
        };
    }).catch((err) => {
        return handleAxiosError(err,'algo salio mal',true,globalContext);
    })
}

const masiveScraping = async (entId, dataCard, setDataProducts, productSelecteds, productSelectedsWithErrors, axiosCancelTokenSource, globalContext, setTotalScraped, totalScraped, setIsLoader) => {
    setIsLoader(true)
    if (productSelecteds.length > 0) {
        //iniciamos el for para ir haciendo el scraping 
        //de los productos en el array usando su url
        for (let product of productSelecteds) {
            if (product.variants.length > 0) {
                await downloadProduct(entId, product.variants[0].url, dataCard, setDataProducts, axiosCancelTokenSource, globalContext, setTotalScraped, totalScraped);
            } else {
                await downloadProduct(entId, product.url, dataCard, setDataProducts, axiosCancelTokenSource, globalContext, setTotalScraped, totalScraped);
            }
        };
        setIsLoader(false)
    }
    else if (productSelectedsWithErrors.length > 0) {
        await Promise.all(productSelectedsWithErrors.map(async (product) => {
            if (product.variants.length > 0) {
                await downloadProduct(entId, product.variants[0].url, dataCard, setDataProducts, axiosCancelTokenSource, globalContext, setTotalScraped, totalScraped);
            } else {
                await downloadProduct(entId, product.url, dataCard, setDataProducts, axiosCancelTokenSource, globalContext, setTotalScraped, totalScraped);
            }
        }));
        setIsLoader(false)
    } else {
        setIsLoader(false)
        return;
    }
}

export default masiveScraping;