import React, { useState, useContext, useEffect } from "react";
import { Form, Col, Row, Container, Button } from "react-bootstrap";
import {
    keyUserId,
    keyAccessToken,
} from "../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../contexts/GlobalContext";
import axios from 'axios';
import { Redirect } from "react-router-dom";
import Routes from "../../Routes";
import verifyAccessRole from "../../assets/js/verifyAccessRole";

export default function AdminWoo() {
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [inputs, setInputs] = useState({});
    const [redirect, setRedirect] = useState(null);

        /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
         const handleAxiosError = (err, msg = null, consoleLog = true) => {
            if (axios.isCancel(err)) return; //Don't show axios cancel error
    
            if (err.response && err.response.data) {
                if (err.response.data.message) {
                    globalContext.showModalWithMsg(err.response.data.message);
                } else {
                    globalContext.showModalWithMsg(err.response.data);
                }
            } else if (msg) {
                globalContext.showModalWithMsg(msg);
            }
    
            if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
        };
    
        const updateInputState = (e) => {
            const name = e.target.name;
            const newState = Object.assign({}, inputs);
        
            switch (e.target.type) {
              case "checkbox":
                newState[name] = e.target.checked;
                break;
        
              default:
                newState[name] = e.target.value;
                break;
            }
        
            setInputs(newState);
        };

        const sendCategoriesWoo = () => {
            globalContext.setLoaderPage(true);
            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/create-categories`,
                    {
                    country: inputs.wooCountry,
                    enterpriseId: globalContext.currentEnterpriseId
                    },
                    {
                        cancelToken: axiosCancelTokenSource.token,
                        headers: {
                            "x-api-key": process.env.REACT_APP_API_KEY,
                            "x-access-token": localStorage.getItem(keyAccessToken),
                            "x-user-id": localStorage.getItem(keyUserId),
                        },
                    }
                )
                .then((resp) => {
                    const data = resp.data.data;
                    globalContext.setLoaderPage(false);
                    console.log('data',data);
                })
                .catch((err) => {
                    handleAxiosError(
                        err,
                        "Disculpe, no se pudo culminar la descarga de los productos."
                    );
                    globalContext.setLoaderPage(false);
                });       
        };

        const getWooProd = () => {
            globalContext.setLoaderPage(true);
            axios
                .get(
                    `${process.env.REACT_APP_BACKEND_URL}/v1/test/get-prod-woo/${inputs.idWoo}`,
                    {
                        cancelToken: axiosCancelTokenSource.token,
                        headers: {
                            "x-api-key": process.env.REACT_APP_API_KEY,
                            "x-access-token": localStorage.getItem(keyAccessToken),
                            "x-user-id": localStorage.getItem(keyUserId),
                        },
                    }
                )
                .then((resp) => {
                    const data = resp.data.data;
                    globalContext.setLoaderPage(false);
                    console.log('data keepa',data);
                })
                .catch((err) => {
                    handleAxiosError(
                        err,
                        "Disculpe, no se pudo culminar la descarga de los productos."
                    );
                    globalContext.setLoaderPage(false);
                });      
        }

  return (
    <Row>
        <Col md={3}>
        <Form.Control
            size="sm"
            type="text"
            placeholder="País Woo"
            name="wooCountry"
            onChange={updateInputState}
        />
        <Button onClick={() => sendCategoriesWoo()}>enviar categorias Woo</Button>
    </Col>
    <Col md={3}>
        <Form.Control
            size="sm"
            type="text"
            placeholder="id Woo prdo"
            name="idWoo"
            onChange={updateInputState}
        />
        <Button onClick={() => getWooProd()}>buscar prod</Button>
    </Col>
    </Row>
  )
}
