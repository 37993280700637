import React, { useContext, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { BiPlus } from 'react-icons/bi';
import PedidosYaStyle from '../../../../../../../assets/css/components/admin/plugins/PedidosYaStyle';
import { GlobalContext } from '../../../../../../../contexts/GlobalContext';
import getAllRestaurant from '../../../../../plugins/pedidosYa/functions/getAllRestaurant';
export default function StepOne({restaurantId, setRestaurantId, activeModal}) {
    const globalContext = useContext(GlobalContext);
    const [restaurants, setRestaurants] = useState([]);
    const [isLoad, setIsLoad] = useState(true);

    useEffect(() => {
        if(!activeModal) return;
        const entId = globalContext.currentEnterpriseId
        if (!entId) return;
        if(restaurants.length > 0) return;
        getAllRestaurant(entId)
            .then((res) => {
                const datRes = res.data.data;
                setRestaurants(datRes);
                setIsLoad(false)
            })
            .catch(err => {
                console.log('el error res', err)
            })
    }, [globalContext.currentEnterpriseId, restaurants, activeModal]);

    const selectStoreId = (v) => {
        setRestaurantId(v)
    }

    const isSelected = (v) => {
        if(!restaurantId) return;
        if(restaurantId.storeId === v) {
            return true;
        }else {
            return false;
        }
    }
    return (
        <>
        <PedidosYaStyle.ContTable>
            <PedidosYaStyle.HeaderContTable>
                <PedidosYaStyle.Text size="13pt" fw="500">
                    Listado de Restaurantes
                </PedidosYaStyle.Text>
                
            </PedidosYaStyle.HeaderContTable>
            <PedidosYaStyle.TableResult>
                <PedidosYaStyle.TableResultThead>
                    <PedidosYaStyle.TableResultTr>
                        <PedidosYaStyle.TableResultTh width="300px" minWidth="100px" textAlign="center">
                            Nombre del restaurant
                        </PedidosYaStyle.TableResultTh>
                        <PedidosYaStyle.TableResultTh width="300px" minWidth="100px" textAlign="center">
                            Id
                        </PedidosYaStyle.TableResultTh>
                        <PedidosYaStyle.TableResultTh width="100px" minWidth="100px" textAlign="center">
                            Accion
                        </PedidosYaStyle.TableResultTh>
                    </PedidosYaStyle.TableResultTr>
                </PedidosYaStyle.TableResultThead>
                {
                    isLoad ? (
                        <PedidosYaStyle.TableResultTbody>
                            <PedidosYaStyle.TableResultTr>
                                <PedidosYaStyle.TableResultTd width="100%" minWidth="100px" textAlign="center" colSpan="3">
                                    <PedidosYaStyle.contLoader>
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Cargando...</span>
                                        </Spinner>
                                    </PedidosYaStyle.contLoader>
                                </PedidosYaStyle.TableResultTd>
                            </PedidosYaStyle.TableResultTr>

                        </PedidosYaStyle.TableResultTbody>
                    ) :
                    (
                        <PedidosYaStyle.TableResultTbody>
                                {
                                    restaurants.length > 0 &&
                                    restaurants.map(v => {
                                        return (
                                            <PedidosYaStyle.TableResultTr key={v._id}>
                                                <PedidosYaStyle.TableResultTd width="300px" minWidth="100px" textAlign="center">
                                                    {v.store}
                                                </PedidosYaStyle.TableResultTd>
                                                <PedidosYaStyle.TableResultTd width="300px" minWidth="100px" textAlign="center">
                                                    {v.storeId}
                                                </PedidosYaStyle.TableResultTd>
                                                <PedidosYaStyle.TableResultTd width="100px" minWidth="100px" textAlign="center" backGroundColor="#F3F2F7">
                                                    <div className="d-flex justify-content-center align-items-center" style={{ with: '100%' }}>
                                                        <PedidosYaStyle.BtnIcon
                                                        onClick={() => selectStoreId(v)} 
                                                        width="38px"
                                                        height="38px"
                                                        bgColor={isSelected(v.storeId) && "#7367F0"}
                                                        color={isSelected(v.storeId) && "#FFFFFF"}
                                                        >
                                                            <BiPlus />
                                                        </PedidosYaStyle.BtnIcon>
                                                    </div>
                                                </PedidosYaStyle.TableResultTd>
                                            </PedidosYaStyle.TableResultTr>
                                        )
                                    })
                                }
                            </PedidosYaStyle.TableResultTbody>
                        )
                }
            </PedidosYaStyle.TableResult>
        </PedidosYaStyle.ContTable>
        </>
    )
}
