import styled from "styled-components";

const RatesStyles = styled.div`
  /*//////////////////////////////////////////////////////////////////
[ FONT ]*/

  /*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  * {
    margin: 0px;
    box-sizing: border-box;
  }

  /* ------------------------------------ */

  /* ------------------------------------ */

  .limiter,
  .limiterHeader {
    width: 100%;
    margin: 0 auto;
  }
  .ActionsContent {
    padding: 8px 0;
  }
  .colHeader {
    padding: 10px 10px;
  }
  .container-table100,
  .container-header {
    width: 100%;
    min-height: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px 30px;
  }
  display: flex;
  .btn-action-trash {
    border: solid 1px #d3514d;
    color: #d3514d;
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 50px;
    background: transparent;
    margin: 0 3% 0 5%;
    font-size: 11pt;
    &:hover {
      background: #d3514d;
      color: white;
    }
    &:active,
    &:focus,
    &:visited,
    &active:focus {
      outline: none;
      border: none;
    }
  }
  .btn-action-update {
    border: 1px solid #5e548e;
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 50px;
    background: transparent;
    margin: 0 3% 0 5%;
    color: #5e548e;
    font-size: 11pt;
    &:hover {
      background: #5e548e;
      color: white;
    }
    &:active,
    &:focus,
    &:visited,
    &active:focus {
      outline: none;
      border: none;
    }
  }
  .rate-description,
  .rate-table-products {
    background-color: white;
    margin-top: 2%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-box-shadow: 0px 25px 55px -9px rgba(201, 201, 201, 1);
    -moz-box-shadow: 0px 25px 55px -9px rgba(201, 201, 201, 1);
    box-shadow: 0px 25px 55px -9px rgba(201, 201, 201, 1);
    font-family: "Roboto Slab", serif !important;
    p {
      margin-bottom: 0;
    }
  }
  .goBack {
    .btn-back {
      border: 1px solid rgb(94, 84, 142);
      color: rgb(94, 84, 142);
      width: 30px;
      height: 30px;
      background: transparent;
      border-radius: 50%;
      transition: all ease 450ms;
      &:hover {
        background: rgb(94, 84, 142);
        color: white;
      }
      &:focus,
      &:active,
      &:active:focus {
        outline: none;
      }
    }
  }
  .add-enterprise {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    background-color: #5e548e;
    color: white;
    font-weight: bolder;
    transition: all ease 450ms;

    &:hover {
      color: #5e548e;
      background-color: #cec28e;
    }
    &:active,
    &:focus,
    &:visited,
    &active:focus {
      outline: none;
      border: none;
    }
  }

  select {
    box-shadow: none;
    font-family: "Roboto Slab", serif !important;
    outline: none;
    border: 0.5px solid #5e548e;
    transition: all ease 450ms;
    width: 100%;
    &:hover {
      box-shadow: none;
      color: #5e548e;
      border-color: #cec28e;
    }
    &:focus {
      box-shadow: none;
      color: #5e548e;
      border-color: #cec28e;
    }
    &:active {
      box-shadow: none;
      color: #5e548e;
      border-color: #cec28e;
    }
  }
  .wrap-table100 {
    width: 650px;
  }
  .ActionsContent {
    border: 1px solid #e5e9f2;
    background: white;
    border-radius: 10px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    font-family: "Roboto Slab", serif !important;
  }
  table {
    border-spacing: 0;
    overflow: hidden;
    border-collapse: collapse;
    border: 1px solid #e5e9f2;
    background: white;
    border-radius: 10px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    font-weight: bold;
    font-family: "Roboto Slab", serif !important;
    -webkit-box-shadow: 0px 25px 55px -9px rgba(201, 201, 201, 1);
    -moz-box-shadow: 0px 25px 55px -9px rgba(201, 201, 201, 1);
    box-shadow: 0px 25px 55px -9px rgba(201, 201, 201, 1);
  }
  table * {
    position: relative;
  }
  table td,
  table th {
    padding-left: 8px;
  }
  table thead tr {
    height: 60px;
    background-color: #ffffff;
  }
  table tbody tr {
    height: 50px;
  }
  table tbody tr:last-child {
    border: 0;
  }
  table td,
  table th {
    text-align: left;
  }
  table td.l,
  table th.l {
    text-align: right;
  }
  table td.c,
  table th.c {
    text-align: center;
  }
  table td.r,
  table th.r {
    text-align: center;
  }

  .table100-head th {
    font-size: 18px;
    font-weight: bold;
    color: #5e548e;
    box-shadow: rgba(0, 0, 0, 0.07) 0 1px;
    line-height: 1.2;
    font-weight: unset;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: "Roboto Slab", serif !important;
  }

  tbody tr {
    box-shadow: rgba(0, 0, 0, 0.07) 0 1px;
    font-family: "Roboto Slab", serif !important;
    font-size: 15px;
    color: #808080;
    line-height: 1.2;
  }

  tbody tr:hover {
    color: #555555;
    background-color: #f5f5f5;
  }

  .column1 {
    width: 200px;
    padding-left: 10px;
  }

  .column2 {
    width: 200px;
  }

  .column3 {
    width: 200px;
  }

  .column4 {
    width: 50px;
    text-align: center;
    padding-right: 10px;
  }
  @media screen and (max-width: 900px) {
    .wrap-table100 {
      padding: 0;
    }
    .container-table100 {
      padding-left: 0px;
      padding-right: 0px;
    }

    table {
      display: block;
      width: 270px;
    }
    table > *,
    table tr,
    table td,
    table th {
      display: block;
    }
    table thead {
      display: none;
    }
    table tbody tr {
      height: auto;
      padding: 37px 0;
    }
    table tbody tr td {
      padding-left: 40% !important;
      margin-bottom: 24px;
    }
    table tbody tr td:last-child {
      margin-bottom: 0;
    }
    table tbody tr td:before {
      font-family: "Roboto Slab", serif !important;
      font-size: 14px;
      color: #999999;
      line-height: 1.2;
      font-weight: unset;
      position: absolute;
      width: 40%;
      left: 30px;
      top: 0;
    }
    table tbody tr td:nth-child(1):before {
      content: "Campaña";
    }
    table tbody tr td:nth-child(2):before {
      content: "Tarifa";
    }
    table tbody tr td:nth-child(3):before {
      content: "%";
    }
    table tbody tr td:nth-child(4):before {
      content: "";
    }

    .column1,
    .column2,
    .column3,
    .column4,
    .column5 {
      width: 100%;
    }
    .column5 {
      display: flex;
      justify-content: space-between;
    }

    tbody tr {
      font-size: 14px;
    }
  }

  @media (max-width: 576px) {
    .container-table100 {
      padding-left: 0px;
      padding-right: 0px;
    }

    .column1,
    .column2,
    .column3,
    .column4,
    .column5 {
      width: 100%;
    }
    .column5 {
      display: flex;
      justify-content: space-between;
    }
  }
  @media (min-width: 300px) and (max-width: 575px) {
    .column1,
    .column2,
    .column3,
    .column4,
    .column5 {
      width: 100%;
    }
    .column5 {
      display: flex;
      justify-content: space-between;
    }
    .IconsActions {
      .btn-action-trash,
      .btn-action-update {
        width: 30px;
        height: 30px;
        margin-right: 0%;
        margin-left: 0%;
      }
    }
  }
  .input-group {
    input {
      outline: none;
      font-family: "Roboto Slab", serif !important;
      padding-left: 5px;
      border: 0.5px solid #5e548e;
      transition: all ease 450ms;
      &:focus {
        outline: none !important;
        outline-width: 0 !important;
        box-shadow: none;
        color: #5e548e;
        border-color: #cec28e;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
      }
    }
    .input-group-append {
      button {
        font-family: "Roboto Slab", serif !important;
        outline: none;
        border: none;
        padding: 0 16px;
        font-weight: bold;
        color: #ffffff;
        background-color: #5e548e;
        &:focus,
        &:active,
        &:checked,
        &:active:focus {
          outline: none !important;
          outline-width: 0 !important;
          box-shadow: none;
          -moz-box-shadow: none;
          -webkit-box-shadow: none;
        }
      }
    }
  }
`;
export default RatesStyles;
