import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
const getDataMonitor = async (data, axiosCancelTokenSource) => {
    const headers = {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
    };
    const res = await axios
    .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
        {
            cancelToken: axiosCancelTokenSource.token,
            headers,
        }
    )
    return res;
}

export default getDataMonitor;