import React, { useState, useEffect, useContext } from "react";
import SelectNavbarStyle from "../../../..//assets/css/components/admin/menus/SelectNavbarStyle.css";
import { Form, Col, Row, ProgressBar } from "react-bootstrap";
import {
  keyUserId,
  keyAccessToken,
  keyUserActiveEnterpriseId,
} from "../../../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../../../contexts/GlobalContext";

export default function StepTwo(props) {
  const globalContext = useContext(GlobalContext);
  const axios = require("axios");
  const { befStep } = props;
  const [enterpricesOptions, setEnterpricesOptions] = useState([]);
  const [enterprice, setEnterprice] = useState("");

  const saveEnterprise = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/compUpd`,
      params: {
        pageNumber: 1,
        pageItemCount: 500,
      },
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
      data: {
        enterpriseId: localStorage.getItem(keyUserActiveEnterpriseId),
        companyId: enterprice,
      },
    })
      .then((res) => {
        globalContext.showModalWithMsg("Registro exitoso");
        console.log(res.data.data);
      })
      .catch((err) => {
        globalContext.showModalWithMsg(
          "Disculpe ha ocurrido un error al guardar la empresa"
        );
        console.log(err.data);
      });
  };

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/odooCompany`,
      params: {
        pageNumber: 1,
        pageItemCount: 500,
      },
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
      data: {
        enterpriseId: localStorage.getItem(keyUserActiveEnterpriseId),
      },
    })
      .then((res) => {
        setEnterpricesOptions(res.data.data);
      })
      .catch((err) => {
        globalContext.showModalWithMsg(
          "Disculpe ha ocurrido un error al intentar cargar la lista de empresas"
        );
        console.log(err, err.response);
      });
    //eslint-disable-next-line
  }, []);

  let prevStep = (e) => {
    e.preventDefault();
    befStep();
  };

  return (
    <div className="StepTwo">
      <Form onSubmit={(e) => saveEnterprise(e)}>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="mb-3 progressBar"
          >
            <ProgressBar animated now={90} label="Seleccione su Empresa" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
            <SelectNavbarStyle>
              <Form.Control
                as="select"
                value={enterprice}
                onChange={(e) => setEnterprice(e.target.value)}
              >
                <option key="" value="">
                  Seleccione una empresa
                </option>
                {enterpricesOptions.map((values) => (
                  <option key={values.id} value={values.id}>
                    {values.name}
                  </option>
                ))}
              </Form.Control>
            </SelectNavbarStyle>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <button id="btnPrev" onClick={prevStep}>
              Anterior
            </button>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <button id="save">Guardar</button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
