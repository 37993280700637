import React from "react";

export default function stepFour(props) {
  const { befStep, restStep } = props;
  let restart = (e) => {
    e.preventDefault();
    restStep();
  };
  let before = (e) => {
    e.preventDefault();
    befStep();
  };
  return (
    <div>
      Step four
      <button
        onClick={(e) => {
          before(e);
        }}
      >
        before
      </button>
      <button
        onClick={(e) => {
          restart(e);
        }}
      >
        retart
      </button>
    </div>
  );
}
