import React, { useContext, useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap';
import { BiCloudDownload, BiPlus } from 'react-icons/bi'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle'
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import Pager from '../../../../general/Pager';
import getBrands from '../functions/getBrands';
import getDownloadBrands from '../functions/getDownloadBrands';
import getDownloadProducts from '../functions/getDownloadProducts';
import getProducts from '../functions/getProducts';

export default function SucursalList(props) {
    const globalContext = useContext(GlobalContext);
    const [brands, setBrands] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [pageItemCount, setPageItemCount] = useState(15);
    const [total, setTotal] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState('')


    const downloadBrands = () => {
        setIsLoad(true)
        const entId = globalContext.currentEnterpriseId;
        getDownloadBrands(entId)
        .then((res) => {
            setIsLoad(false)
            console.log('res anicam brands', res)
            globalContext.showModalWithMsg('Las marcas para anicam se descargaron con exito!')
        }).catch((err) => {
            setIsLoad(false)
            globalContext.showModalWithMsg('Las marcas para anicam no fueron descargadas!', err)
        })
    }

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if(!entId) return;
        setIsLoad(true)
        getBrands(entId, currentPage, pageItemCount, filter)
        .then(resp => {
            setTotal(resp.data.data.total)
            setIsLoad(false)
            setBrands(resp.data.data.products)
            console.log('data ani',resp.data.data.products)
        }).catch(err => {
            setIsLoad(false)
            globalContext.showModalWithMsg('Error al listar las marcas!', err)
        })
    }, [globalContext.currentEnterpriseId, currentPage, filter, pageItemCount])
    

    const downloadProductFromBrands  = (brandName) => {
        const entId = globalContext.currentEnterpriseId;
        if(!entId) return;
        getDownloadProducts(entId,brandName)
        .then(resp => {
            globalContext.showModalWithMsg(`Se descargaron todos los producto para la marca ${brandName}`);
        }).catch(err => {
            globalContext.showModalWithMsg(`No se descargaron todos los productos para la marca ${brandName}`);
        })
    }


    return (
        <PedidosYaStyle.ContTable>
            <PedidosYaStyle.HeaderContTable>
                <PedidosYaStyle.Text size="13pt" fw="500">
                    Listado marcas
                </PedidosYaStyle.Text>
                <Pager
                            handleSetPage={setCurrentPage}
                            totalResults={total}
                            currentPage={currentPage}
                            resultsPerPage={pageItemCount}
                        />
                <PedidosYaStyle.BtnAndIcon onClick={() => downloadBrands()}>
                    <PedidosYaStyle.ContIconBtn>
                        <BiPlus />
                    </PedidosYaStyle.ContIconBtn>
                    Descargar
                </PedidosYaStyle.BtnAndIcon>
            </PedidosYaStyle.HeaderContTable>
            <PedidosYaStyle.TableResult>
                <PedidosYaStyle.TableResultThead>
                    <PedidosYaStyle.TableResultTr>
                        <PedidosYaStyle.TableResultTh width="300px" minWidth="100px" textAlign="center">
                            Nombre
                        </PedidosYaStyle.TableResultTh>
                        <PedidosYaStyle.TableResultTh width="300px" minWidth="100px" textAlign="center">
                            Total de productos
                        </PedidosYaStyle.TableResultTh>
                        <PedidosYaStyle.TableResultTh width="100px" minWidth="100px" textAlign="center">
                            Accion
                        </PedidosYaStyle.TableResultTh>
                    </PedidosYaStyle.TableResultTr>
                </PedidosYaStyle.TableResultThead>
                <PedidosYaStyle.TableResultTbody>
                    {
                        brands.length > 0 &&
                        brands.map((v) => {
                            return (
                                <PedidosYaStyle.TableResultTr key={v._id}>
                                    <PedidosYaStyle.TableResultTd width="300px" minWidth="100px" textAlign="center">
                                        {v.name}
                                    </PedidosYaStyle.TableResultTd>
                                    <PedidosYaStyle.TableResultTd width="300px" minWidth="100px" textAlign="center">
                                        {v.total}
                                    </PedidosYaStyle.TableResultTd>
                                    <PedidosYaStyle.TableResultTd width="100px" minWidth="100px" textAlign="center" backGroundColor="#F3F2F7">
                                        <div className="d-flex justify-content-center align-items-center" style={{with:'100%'}}>
                                        {
                                            isLoad ? 
                                            (
                                                <PedidosYaStyle.contLoader>
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Cargando...</span>
                                                    </Spinner>
                                                </PedidosYaStyle.contLoader>
                                            )
                                            :
                                            (
                                                <>
                                                <PedidosYaStyle.BtnIcon
                                                    onClick={() => downloadProductFromBrands(v.name)}
                                                    mRight="auto"
                                                    mLeft="auto"
                                                    width="30px"
                                                    height="30px">
                                                    <BiCloudDownload />
                                                </PedidosYaStyle.BtnIcon>
                                                </>
                                            )
                                        }
                                        </div>
                                    </PedidosYaStyle.TableResultTd>
                                </PedidosYaStyle.TableResultTr>
                            )
                        })
                    }
                </PedidosYaStyle.TableResultTbody>
            </PedidosYaStyle.TableResult>
        </PedidosYaStyle.ContTable>
    )
}
