import React, { useState } from 'react'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle';
import Navbar from '../components/Navbar';
import CredentialsWoo from '../components/CredentialsWoo';
import UsuariosWoo from '../components/UsuariosWoo';
import Productos from '../components/Productos';

export default function FormIndexWoo() {

    const [currentStep, setCurrentStep] = useState(1);//change to one

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (<CredentialsWoo />);
            case 2:
                return (<UsuariosWoo />)
            case 3:
                return (<Productos />)
            default:
                //return (<SectionsList />)
                return (<CredentialsWoo />);
        }
    }

    return (
        <PedidosYaStyle.Cont>
            <PedidosYaStyle.Header>
                <PedidosYaStyle.Text size="15pt" fw="500">
                    Configuracion para Woocommerce
                </PedidosYaStyle.Text>
            </PedidosYaStyle.Header>
            <Navbar currentStep={currentStep} setCurrentStep={setCurrentStep} />
            <PedidosYaStyle.ContStep>
                {renderStep()}
            </PedidosYaStyle.ContStep>
        </PedidosYaStyle.Cont>
    )
}
