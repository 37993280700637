import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { keyUserId, keyAccessToken, userEmail } from "../../../../../assets/js/SessionStorageNames";
import axios from "axios";

export default function SyncPrice(props) {
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    /**
     * Cancel axios requests before unmount
     */
    useEffect(() => {
        return () => {
            axiosCancelTokenSource.cancel("Canceled by unmount");
        };
        //eslint-disable-next-line
    }, []);

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (msg) globalContext.showModalWithMsg(msg);
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const syncPrice = () => {
        const entId = globalContext.currentEnterpriseId;

        if (!entId) return;
        
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        switch (props.currentPlugin) {
            case "odoo":
                globalContext.setLoaderPage(true);
                axios
                    .get(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}/sync-price`, {
                        cancelToken: axiosCancelTokenSource.token,
                        headers,
                    })
                    .then(() => {
                        globalContext.setLoaderPage(false);
                        globalContext.showModalWithMsg(
                            `La descarga se esta ejecutando en segundo plano!`
                        );
                    })
                    .catch((err) => {
                        handleAxiosError(
                            err,
                            "Disculpe, no se pudo culminar la descarga de los productos."
                        );
                        globalContext.setLoaderPage(false);
                    });
                break;
                default:
                    break;
        }
    };

    return (
        <div
            className={`items`}
            onClick={syncPrice}
        >
            <span className="ic">
                <FontAwesomeIcon icon={faDownload} />
            </span>
            <p>Descargar Precios</p>

        </div>
    );
}
