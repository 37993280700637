import { keyAccessToken, keyUserId } from "../../../../assets/js/SessionStorageNames";
import axios from 'axios';

const getAllParents = (currentPage, pageItemCount, filter, axiosCancelTokenSource) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/categories`, {
        params: {
            pageNumber: currentPage,
            pageItemCount: pageItemCount,
            filter: {
                includeTree: false,
                getAll: filter.length > 0  ? true : false,
                search: filter,
                lang: 'en',
                linked: true,
            },
        },
        cancelToken: axiosCancelTokenSource.token,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
}

export default getAllParents;