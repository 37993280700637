import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
const activeOne = async (entId,idProduc) => {
    const res = await  axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/activate/${idProduc}`, {}, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    });
    return res;
}

const activePublications = async (entId, productSelecteds,  productSelectedsWithErrors, dataCard, setDataCard, setTotalPaused, setTotalPausedVariants, totalPaused, totalPausedVariants, setIsLoader) => {
    setIsLoader(true)
    let arrayCopy = [...dataCard];
    let tP = totalPaused;
    let tPVariants = totalPausedVariants;
    if (productSelecteds.length > 0) {
        await Promise.all(productSelecteds.map( async (v) => {
            const indexOne = arrayCopy.findIndex(vc => vc._id === v._id)
            if (v.variants.length > 0) {
                for await (let va of v.variants) {
                    if(va.ml) {
                        const indexTwo = arrayCopy[indexOne].variants.findIndex(v => v.asin === va.asin);
                        await activeOne(entId, va.ml._id)
                        .then(res => {
                            arrayCopy[indexOne].variants[indexTwo].ml.attrib.status = 'active';
                            setDataCard(arrayCopy);
                            if(tPVariants > 0) {
                                tPVariants--
                                setTotalPausedVariants(tPVariants)
                            }
                            console.log('se activo', va.asin, res)
                        }).catch(err => {
                            console.log('no se activo', va.asin, err)
                        })
                    }
                }
            }else {
                if(v.ml) {
                    await activeOne(entId, v.ml._id)
                    .then(res => {
                        arrayCopy[indexOne].ml.attrib.status = 'active';
                        setDataCard(arrayCopy);
                        if(tP > 0) {
                            tP--
                            setTotalPaused(tP)
                        }
                        console.log('se activo', v.asin, res)
                    }).catch(err => {
                        console.log('no se activo', v.asin, err)
                    })
                }
            }
        }))
        setIsLoader(false)
    }else {
        await Promise.all(productSelectedsWithErrors.map( async (v) => {
            const indexOne = arrayCopy.findIndex(vc => vc._id === v._id)
            if (v.variants.length > 0) {
                for await (let va of v.variants) {
                    if(va.ml) {
                        const indexTwo = arrayCopy[indexOne].variants.findIndex(v => v.asin === va.asin);
                        await activeOne(entId, va.ml._id)
                        .then(res => {
                            if(tPVariants > 0) {
                                tPVariants--
                                setTotalPausedVariants(tPVariants)
                            }
                            arrayCopy[indexOne].variants[indexTwo].ml.attrib.status = 'active';
                            setDataCard(arrayCopy)
                            console.log('se activo', va.asin, res)
                        }).catch(err => {
                            console.log('no se activo', va.asin, err)
                        })
                    }
                }
            }else {
                if(v.ml) {
                    await activeOne(entId, v.ml._id)
                    .then(res => {
                        arrayCopy[indexOne].ml.attrib.status = 'active';
                        setDataCard(arrayCopy)
                        if(tP > 0) {
                            tP--
                            setTotalPaused(tP)
                        }
                        console.log('se activo', v.asin, res)
                    }).catch(err => {
                        console.log('no se activo', v.asin, err)
                    })
                }
            }
        }))
        setIsLoader(false)
    }
}
export default activePublications;