import React from 'react'
import { ContCard, ContDataInfoOne, ContImgLoad, ContLabelLoad } from '../../../../../../../assets/css/components/admin/marketplace-to-marketplace/cardItem/CardItemStyle'
export default function CardLoad() {
    return (
        <ContCard>
            <ContImgLoad />
            <div style={{marginTop: 10, padding: '0 10px', width: '100%'}}>
                <ContLabelLoad />
            </div>
            <ContDataInfoOne style={{marginTop: 10}}>
                <ContLabelLoad w="120px" />
                <ContLabelLoad w="120px" />
            </ContDataInfoOne>
        </ContCard>
    )
}
