const funcAdditem = (v, setProductsSelectedsWithErrors, setProductsSelecteds, isInclude, isIncludeErrors) => {
    if(v.hasError || v.noMlData) {
        console.log('se agg e; error', v)
        const isHere = isIncludeErrors.find((e) => (e._id === v._id));
        if (isHere === undefined) {
            setProductsSelectedsWithErrors([...isIncludeErrors, v]);
        } else {
            setProductsSelectedsWithErrors(isIncludeErrors.filter((e) => (e._id !== v._id)))
        }
    }
    else {
        const isHere = isInclude.find((e) => (e._id === v._id));
        if (isHere === undefined) {
            setProductsSelecteds([...isInclude, v]);
        } else {
            setProductsSelecteds(isInclude.filter((e) => (e._id !== v._id)))
        }
    }
}
export default funcAdditem;