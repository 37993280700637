import React from 'react'
import { BackgroundModal, ContModal, HeaderModal, Text, BtnCloseModal, BodyModal, FooterModal, BtnGeneral } from '../../../../../../assets/css/components/admin/marketplace-to-marketplace/modals/ModalSelectStoreAndSection';
import { GlobalContext } from '../../../../../../contexts/GlobalContext';
import { useContext } from 'react';
import {  BiX } from 'react-icons/bi'
import { useState } from 'react';
import { useEffect } from 'react';
import StepOne from './modal-link-product/StepOne'
import StepTwo from './modal-link-product/StepTwo'
import SendData from './modal-link-product/SendData';
import sendDataToLink from '../../functions/sendDataToLinkProduct';
export default function ModalLinkProduct() {
    const globalContext = useContext(GlobalContext);
    const [currentStep, setCurrentStep] = useState(1);
    const [load, setLoad] = useState(false);
    const [restaurantId, setRestaurantId] = useState(null);
    const [barCode, setBarCode] = useState(null);
    const [hasError, setHasError] = useState(null);
    const [success, setSuccess] = useState(false)

    const sendDataToLinkProduct = () => {
        const entId = globalContext.currentEnterpriseId
        if(!entId) return;
        setLoad(true);
        sendDataToLink(globalContext.idProductLink, entId, barCode, restaurantId.storeId )
            .then(res => {
                console.log(res);
                setLoad(false)
                setSuccess(true)
            }).catch(error => {
                console.log(error)
                setHasError(error)
            })
    }

    useEffect(() => {
        if (!globalContext.modalLinkOneProduct) {
            setCurrentStep(1)
            setRestaurantId(null)
            setBarCode(null)
            setHasError(null)
            setLoad(false)
            setSuccess(false)
        }
    }, [globalContext.modalLinkOneProduct])

    const renderBotonSteps = () => {
        switch (currentStep) {
            case 1:
                return (
                    <BtnGeneral onClick={() => setCurrentStep(currentStep + 1)} disabled={!restaurantId}>
                        Siguiente
                    </BtnGeneral>
                    )
                    case 2:
                        return (
                            <>
                        <BtnGeneral
                        bgc="#E3E3E3"
                        left="10px"
                        right="auto"
                        color="#515151"
                        disabled={!barCode}
                        onClick={() => setCurrentStep(currentStep - 1)}>
                            Volver
                        </BtnGeneral>
                        <BtnGeneral onClick={() => setCurrentStep(currentStep + 1)}>
                            Siguiente
                        </BtnGeneral>
                    </>
                )
                case 3:
                    return (
                        <>
                            <BtnGeneral
                            bgc="#E3E3E3"
                            left="10px"
                            right="auto"
                            color="#515151"
                            onClick={() => setCurrentStep(currentStep - 1)}>
                                Volver
                            </BtnGeneral>
                            <BtnGeneral onClick={() => sendDataToLinkProduct()} disabled={!globalContext.idProductLink}>
                                Linkear
                            </BtnGeneral>
                        </>
                        )
        
            default:
                break;
        }
    } 

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return ( 
                    <StepOne
                        activeModal={globalContext.modalLinkOneProduct} 
                        restaurantId={restaurantId}
                        setRestaurantId={setRestaurantId}
                    />
                    )
                
            case 2:
                return ( 
                <StepTwo
                    activeModal={globalContext.modalLinkOneProduct}
                    barCode={barCode} 
                    setBarCode={setBarCode}
                />
                )
            case 3:
                return (
                <SendData
                    restaurantId={restaurantId}
                    barCode={barCode}
                    hasError={hasError}
                    load={load}
                    success={success}
                />
                )
        
            default:
                break;
        }
    }

    const renderLabelModal = () => {
        switch (currentStep) {
            case 1:
                return '1: Seleccione su Tienda';
                
            case 2:
                return '2: Coloque el Codigo de barra a asociar'
            case 3:
                return '3: Envie los datos'
        
            default:
                break;
        }
    }
//     setModalLinkOneProduct
// modalLinkOneProduct

    return (
        <BackgroundModal active={globalContext.modalLinkOneProduct}>
            <ContModal active={globalContext.modalLinkOneProduct} w="auto">
                <HeaderModal>
                    <Text fw="500" size="13pt">
                        {renderLabelModal()}
                    </Text>
                    <BtnCloseModal onClick={() => globalContext.setModalLinkOneProduct(false)} >
                        <BiX/>
                    </BtnCloseModal>
                </HeaderModal>
                <BodyModal>
                    {renderStep()}
                </BodyModal>
                <FooterModal>
                    {
                        renderBotonSteps()
                    }
                    
                </FooterModal>
            </ContModal> 
        </BackgroundModal>
    )
}
