import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { keyUserId, keyAccessToken, keyUserRoles, userEmail } from "../../../../../assets/js/SessionStorageNames";
import axios from "axios";

export default function SyncLinkedOption(props) {
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [userEmailVali, setUserEmailVali] = useState(null)

    /**
     * Cancel axios requests before unmount
     */
    useEffect(() => {
        return () => {
            axiosCancelTokenSource.cancel("Canceled by unmount");
        };
        //eslint-disable-next-line
    }, []);

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    // useEffect(() => {

    //     const v_userEmail = localStorage.getItem(userEmail);

    //     if (!v_userEmail) {
    //         // setRedirect(<Redirect to="/login" />);
    //         globalContext.setVerifyLogin(true);
    //     } else {
    //         globalContext.setVerifyLogin(false);
    //         setUserEmailVali(v_userEmail);

    //     }
    // }, [localStorage.getItem(userEmail)]);

    /**
     * Updates the linked products
     */
    const syncLinked = () => {
        globalContext.setLoaderPage(true);
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        const processCheckIntervalTime = 5000;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        globalContext.setLoaderPage(true);
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/product/sync-linked/${entId}/${props.currentPlugin}`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers,
                }
            )
            .then((resp) => {
                const data = resp.data.data;

                if (data.monitor) {
                    //If the process ID was received, start monitoring
                    let waitingMonitorResponse = false;
                    const interval = setInterval(() => {
                        if (!waitingMonitorResponse) {
                            axios
                                .get(
                                    `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                                    {
                                        cancelToken: axiosCancelTokenSource.token,
                                        headers,
                                    }
                                )
                                .then((resp) => {
                                    const data = resp.data.data;

                                    if (data.advance >= 100) {
                                        clearInterval(interval);
                                        let modalMsg = "";
                                        let modalConfig = {};

                                        if (data.data.failedProducts) {
                                            modalMsg = (<>
                                                <span>
                                                    Se han actualizado {data.data.updatedProducts} productos.
                                                    Fallo la atualizacion de {data.data.failedProducts} productos.
                                                </span>
                                                <br />
                                                <span>
                                                    Se encontraron los siguientes errores:
                                                </span>
                                                {
                                                    data.data.clientMsg && data.data.clientMsg.map((msg, idx) => {
                                                        return <span key={idx}><br />- {msg}</span>;
                                                    })
                                                }
                                            </>);

                                            modalConfig = { size: 'lg' };
                                        } else {
                                            modalMsg = `Se han actualizado los productos asociados`;
                                        }

                                        globalContext.showModalWithMsg(modalMsg, "Proceso culminado", modalConfig);
                                        props.setNumRefresh(props.numRefresh + 1);
                                        globalContext.setLoaderPage(false);
                                    } else {
                                        const advance = data.advance.toFixed(2);
                                        globalContext.setLoaderPage(true, `${advance}%`);
                                    }
                                })
                                .catch((err) => {
                                    handleAxiosError(
                                        err,
                                        "Disculpe, no se pudo culminar la actualizacion de los productos asociados."
                                    );
                                    clearInterval(interval);
                                    globalContext.setLoaderPage(false);
                                })
                                .finally(() => {
                                    waitingMonitorResponse = false;
                                });
                        }
                    }, processCheckIntervalTime);
                } else {
                    globalContext.showModalWithMsg(
                        `Se han actualizado los productos asociados`
                    );
                    props.setNumRefresh(props.numRefresh + 1);
                    globalContext.setLoaderPage(false);
                }
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo culminar la actualizacion de los productos asociados."
                );
                globalContext.setLoaderPage(false);
            });
    };

    return (
        <div
            className={`items`}
            onClick={syncLinked}
        >
            <span className="ic">
                <FontAwesomeIcon icon={faSyncAlt} />
            </span>
            <p>Actualizar asociados</p>
        </div>
    )
}
