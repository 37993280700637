import styled from 'styled-components';

const cont = styled.div`
width: 100%;
display: flex;
justify-content:flex-start;
align-items:  center;
flex-direction: column;
height: 100%;
background: #FFFFFF;
box-shadow: 0 4px 25px 0 rgb(34 41 47 / 10%);
border-radius: .5rem;
`;
const header = styled.div`
width:100%;
height: 60px;
background:#FFFF;
margin-top:0;
padding:10px;
display:flex;
justify-content: left;
align-items:center;
`;
const body = styled.div`
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
min-height: 500px;
`;

const footer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
height: 60px;
padding: 0 15px;
`;

const text = styled.p`
margin-bottom:  ${props=> props.mb || '0'};;
color: ${props=> props.active ? "#7367F0" : '#707070'};
font-size: ${props=> props.size || '12pt'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
font-weight: ${props=> props.active ? '500' : 'lighter'};
margin-top: ${props=> props.mt || '0'};
transition: all ease-in-out 0.2s;
.fa-asterisk {
        color: #ea5455!important;
        font-size: 8pt;
    }
`;

const input = styled.input`
    margin-right:10px;
    font-family: "Berlin Rounded";
    width: 100%;
    height: 38px;
    background: #fff;
    outline: none !important;
    color: rgba(112, 112, 112, 0.61);
    font-size: 12pt;
    margin-left: ${props=> props.ml || '0'};
    font-weight: 300;
    padding: .438rem 1rem;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: .357rem;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
        }
`;

const gridData = styled.div`
margin-bottom:10px;
width:100%;
display: grid;
grid-gap: 0.5rem;
padding: 10px;
grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`;

const labelInput = styled.div`
display: flex;
flex-direction:column;
align-items: flex-start;
justify-content:flex-start;
min-width: 300px;
`;
const contRow = styled.div`
display: flex;
justify-content: ${props=> props.jc || 'flex-start'};
align-items: ${props=> props.ai || 'center'};
flex-direction: ${props=> props.fd || 'row'};
width: ${props=> props.w || '100%'};
padding: ${props=> props.pd || 'none'};
overflow-y: ${props=> props.ov || ''};
`;

const select = styled.select`
    padding-left:10px;
    padding-right:10px;
    font-family: "Berlin Rounded";
    width: ${props=> props.wd || '100%'};
    margin-left: ${props=> props.ml || '0'};
    margin-right: ${props=> props.mr || '0'};
    height: 38px;
    background: #fff;
    outline: none !important;
    color: rgba(112, 112, 112, 0.61);
    font-size: 12pt;
    font-weight: 300;
    padding: .438rem 1rem;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: .357rem;

    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
        }
        @media(max-width: ${props => props.selectMq || '989px'}) {
        width:100%;
    }
`;

const dataPikerInput = styled.div`
width: 100%;
.input-date {
    width: 100%;
    outline:none !important;
    height: 38px;
    background: #fff;
    color: rgba(112, 112, 112, 0.61);
    font-weight: 600;
    cursor: pointer;
    font-size:14px;
    padding: .438rem 1rem;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: .357rem;
}
.btn-date {
    border-radius: 50px;
    width: 35px;
    height: 35px;
    outline: none !important;
    background: #7367F0;
    box-shadow: 2px 0px 12px #7367f0;
    border: none;
    color: white;
    transition: all ease-in-out 0.02s;
}
.cont-calendar {
    display: 'flex'; 
    flex-flow: 'column nowrap';
    background: #fff;
    box-shadow: 0px 3px 21px rgba(0, 0, 0, 0.16);
    display: none;
    transition: ease-out;
    position: absolute;
    border-radius:15px;
    margin-top:10px;
}
.btn-date.active{
    color: white;
    font-weight: 600;
    background-color: #7367F0;
    border-color: transparent;
}
.cont-calendar.active {
    display: block;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: translate(0px, 5px);
    z-index: 9999;
}
`;
const contLoader = styled.div`
width: 100%;
height: 100%;
margin-top: auto;
margin-bottom: auto;
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
.spinner-border {
    border: .25em solid #7367f0;
    border-right-color: transparent;
}
`;
const btnBack = styled.button`
outline: none !important;
padding: 8px 16px;
border: 1px solid #707070;
border-radius: .5rem;
color: #707070;
font-size: 12pt;
text-align: center;
`;
const btnNext = styled.button`
outline: none !important;
padding: 8px 16px;
border: 1px solid #7367F0;
border-radius: .5rem;
color: #7367F0;
font-size: 12pt;
text-align: center;
`;
const BuildFormCss = {
    cont,
    header,
    body,
    footer,
    text,
    input,
    contRow,
    select,
    gridData,
    labelInput,
    dataPikerInput,
    contLoader,
    btnBack,
    btnNext
}

export default BuildFormCss;