import React from 'react'
import CardFilterStyle from '../../../../assets/css/components/admin/products/CardFilter.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from 'react-bootstrap'

export default function CardFilter(props) {
    /**
     * Update the filters
     * @param {Event} e The event
     * @param {Object} data The new filter data
     */
    const updateFilters = (e, data) => {
        e.preventDefault();
        props.updateFilters(data);
    }

    //List of items
    const tagsFilter = [
        {
            state: "filterLinkedTo",
            valueOn: "linked",
            name: "Sync",
            onClickAction: (e) => updateFilters(e, {"filterLinkedTo": ""}),
        },
        {
            state: "filterLinkedTo",
            valueOn: "unlinked",
            name: "No Sync",
            onClickAction: (e) => updateFilters(e, {"filterLinkedTo": ""}),
        },
        {
            state: "filterPrice",
            valueOn: "=0",
            name: "Precio = 0",
            onClickAction: (e) => updateFilters(e, {"filterPrice": ""}),
        },
        {
            state: "filterPrice",
            valueOn: ">0",
            name: "Precio > 0",
            onClickAction: (e) => updateFilters(e, {"filterPrice": ""}),
        },
        {
            state: "filterStock",
            valueOn: "=0",
            name: "Stock = 0",
            onClickAction: (e) => updateFilters(e, {"filterStock": ""}),
        },
        {
            state: "filterStock",
            valueOn: ">0",
            name: "Stock > 0",
            onClickAction: (e) => updateFilters(e, {"filterStock": ""}),
        },
        {
            state: "amazonMaxManufacturingTime",
            valueOn: true,
            name: "Tiempo < 6",
            onClickAction: (e) => updateFilters(e, {"amazonMaxManufacturingTime": false}),
        },
    ];
//show filters if currentPlugin is ml
    if(props.currentPlugin==="ml"){
        tagsFilter.push(
            {
                state: "onCbtMarketplaces",
                valueOn: true,
                name: "publicados",
                onClickAction: (e) =>updateFilters(e, {"onCbtMarketplaces": null})
            },
            {
                state: "onCbtMarketplaces",
                valueOn: false,
                name: "no publicados",
                onClickAction: (e) =>updateFilters(e, {"onCbtMarketplaces": null})
            },
        )
    }
    if (props.currentPlugin === "odoo") {
        tagsFilter.push(
            {
                state: "filterWithCompany",
                valueOn: true,
                name: "Con empresa",
                onClickAction: (e) => updateFilters(e, {"filterWithCompany": null}),
            },
            {
                state: "filterWithCompany",
                valueOn: false,
                name: "Sin empresa",
                onClickAction: (e) => updateFilters(e, {"filterWithCompany": null}),
            },
            {
                state: "filterWithTaxes",
                valueOn: true,
                name: "Con impuestos",
                onClickAction: (e) => updateFilters(e, {"filterWithTaxes": null}),
            },
            {
                state: "filterWithTaxes",
                valueOn: false,
                name: "Sin impuestos",
                onClickAction: (e) => updateFilters(e, {"filterWithTaxes": null}),
            }
        );
    }

    return (
        <CardFilterStyle>
            <Row>
                {tagsFilter.map((values, index) => {
                    if (props[values.state] === values.valueOn) {
                        return (
                            <Col
                                key={index}
                                className="d-flex justify-content-center align-items-center"
                                style={{ cursor: "pointer" }}
                                xs={12}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                onClick={(e) => values.onClickAction(e)}
                            >
                                <div className={`card-body`}>
                                    <div className="card-text">
                                        <p>{values.name}</p>
                                    </div>
                                    <div className="card-close">
                                        <button>
                                            <span>
                                                <FontAwesomeIcon icon={faTimesCircle} />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        );
                    }
                })}
            </Row>
        </CardFilterStyle>
    );
}
