import styled from "styled-components";

const ContNavBarAdmin = styled.div`
width: 100%;
`;

const NavBarAdmin = styled.div`
height: 60px;
margin: 0 0px 1rem 0;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
position: relative;
padding: .5rem;
background: #FFFFFF;
border-radius: 0.428rem;
display: flex;
justify-content: space-between;
align-items: center;
`;

const ListIcons = styled.ul`
display: flex;
justify-content: flex-start;
align-items: center;
padding-left: 0;
margin-bottom: 0;
`;

const ItemListIcon = styled.li`
width: 35px;
height: 35px;
display: flex;
justify-content: center;
align-items: center;
`;

const BtnIconList = styled.button`
outline: none !important;
border: none;
font-size: 30pt;
width: 35px;
height: 35px;
display: flex;
justify-content: center;
align-items: center;
background: transparent;
color: #6e6b7b;
border-radius: 50px;
&:hover {
  background: #F0F0F0;
}
`
const NavbarAdminMenuStyle = {
    ContNavBarAdmin,
    NavBarAdmin,
    ListIcons,
    BtnIconList,
    ItemListIcon
}

export default NavbarAdminMenuStyle;