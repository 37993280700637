import styled from 'styled-components';

const cont = styled.div`
width:100%;
padding:10px;
display:flex;
flex-direction:column;
justify-content:center;
align-items: flex-start;
`;

const contInputsAndButtonsActions = styled.div`
    border: none;
    width: 100%;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin-bottom:10px;
`;

const contInputAndLabel = styled.div`
width:100%;
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'center'};
align-items:${props => props.ali || 'flex-start'};
`;

const input = styled.input`
    padding-left:10px;
    font-family: "Berlin Rounded";
    width: ${props => props.wd || '100%'};
    margin-left: ${props => props.ml || '0'};
    margin-right: ${props => props.mr || '0'};
    height: 40px;
    border-radius: 5px;
    background: #fff;
    border: 0.5px solid rgba(112, 112, 112, 0.7);
    outline: none !important;
    color: rgba(112, 112, 112, 0.61);
    font-size: 12pt;
    font-weight: 300;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
        }
`;
const text = styled.p`
margin-bottom:  ${props => props.mb || '0'};
color: ${props => props.color || '#707070'};
font-size: ${props => props.size || '13pt'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '0'};
text-align: start;
transition: all ease-in-out 0.2s;

`;

const btn = styled.button`
border-radius: ${props => props.bdr || '5px'};
background-color:${props => props.bg || '#7367f0'};
color:${props => props.color || '#ffffff'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
outline: none!important;
display: ${props => props.dflex || 'block'};
justify-content: ${props => props.just || 'center'};
align-items:${props => props.align || 'center'};
border:1px solid;
border-color: ${props => props.bdc || '#7367f0'};
font-size: 12pt;
font-weight: 400;
padding: ${props => props.p || " 6px 18px"};
transition: all ease 0.2s;
width: ${props => props.w || "auto"};
height: ${props => props.h || "auto"};
&:hover {
    background-color:${props => props.bgHover || '#7367f0'};
    color:${props => props.colorHover || '#ffffff'};
}
`;

const tableRangos = styled.table`
width: 100%;
border-collapse: collapse;
min-height:100px;
max-height:400px !important;
overflow:auto;
table-layout: fixed;
th {
  color: #707070;
  height:50px;
}
.input-td {
    width:100px;
}
tbody tr td  {
    width: auto;
    word-break: break-all;
    height:50px;
}
`;

const inputChecbox = styled.input`
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    
        --active: #EA5455;
        --active-inner: #fff;
        --focus: 2px #EA5455;
        --border: #EA5455;
        --border-hover: #EA5455;
        --background: #fff;
        --disabled: #EA5455;
        --disabled-inner: #e1e6f9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: inherit;
        position: relative;
        margin: 10;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
        margin-left: ${props => props.ml || '0'};
        margin-right: ${props => props.mr || '0'};
        &:after {
            content: "";
            display: block;
            left: 0;
            top: 0;
            position: absolute;
            transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
            opacity var(--d-o, 0.2s);
        }
        &:checked {
            --b: var(--active);
            --bc: var(--active);
            --d-o: 0.3s;
            --d-t: 0.6s;
            --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
        }
        &:disabled {
            --b: var(--disabled);
            cursor: not-allowed;
            opacity: 0.9;
            &:checked {
            --b: var(--disabled-inner);
            --bc: var(--border);
            }
            & + label {
            cursor: not-allowed;
            }
        }
        &:hover {
            &:not(:checked) {
            &:not(:disabled) {
                --bc: var(--border-hover);
            }
            }
        }
        &:focus {
            box-shadow: 0 0 0 var(--focus);
        }
        &:not(.switch) {
            width: 21px;
            &:after {
            opacity: var(--o, 0);
            }
            &:checked {
            --o: 1;
            }
        }
        & + label {
            font-size: 14px;
            line-height: 21px;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            margin-left: 4px;
        }
        
        &:not(.switch) {
            border-radius: 7px;
            &:after {
                width: 5px;
                height: 9px;
                border: 2px solid var(--active-inner);
                border-top: 0;
                border-left: 0;
                left: 7px;
                top: 4px;
                transform: rotate(var(--r, 20deg));
            }
            &:checked {
            --r: 43deg;
            }
        }
        &.switch {
            width: 38px;
            border-radius: 11px;
            &:after {
            left: 2px;
            top: 2px;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            background: var(--ab, var(--border));
            transform: translateX(var(--x, 0));
            }
            &:checked {
            --ab: var(--active-inner);
            --x: 17px;
            }
            &:disabled {
                &:not(:checked) {
                    &:after {
                    opacity: 0.6;
                    }
                }
            }
        }
        
    }
`
const contInputRadio = styled.div`
display: flex;
align-items:center;
width:100%;
justify-content: flex-start;
margin-bottom:10px;
`;
const inputRadio = styled.input`

@supports(-webkit-appearance: none) or (-moz-appearance: none) {

  --active: #7367f0;
  --active-inner: #fff;
  --focus: 2px rgba(39, 94, 254, .3);
  --border: #7367f0;
  --border-hover: #7367f0;
  --background: #fff;
  --disabled: #F6F8FF;
  --disabled-inner: #E1E6F9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background .3s, border-color .3s, box-shadow .2s;
  margin-left: ${props => props.ml || '0'};
  margin-right: ${props => props.mr || '0'};
  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
  }
  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }
  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: .9;
    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
    & + label {
      cursor: not-allowed;
    }
  }
  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }
  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  &:not(.switch) {
    width: 21px;
    &:after {
      opacity: var(--o, 0);
    }
    &:checked {
      --o: 1;
    }
  }
  & + label {
    font-size: 12pt;
    line-height: 14px;
    display: inline-block;
    vertical-align: inherit;
    cursor: pointer;
    margin-left: 5px;
    color: #707070;
    margin-bottom: 0 !important;
  }


  border-radius: 50%;
  &:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, .7));
  }
  &:checked {
    --s: .5;
  }
}
`;
const btnUpdate = styled.button`
border-radius:50px;
width: 25px;
height:25px;
outline:none !important;
border: none;
background: #7367f0;
display:flex;
justify-content:center;
align-items:center;
font-size: 10pt;
color: #FFFFFF;
margin-left:10px;
`;
const btnSelecCategory = styled.button`
background: #7367F0;
border-radius:.5rem;
outline: none !important;
width:100%;
font-size: 12pt;
font-weight:500;
color: #FFFFFF;
height:40px;
border:none;

`;
const contLoader = styled.div`
width: 100%;
height: 100%;
margin-top: auto;
margin-bottom: auto;
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
.spinner-border {
    border: .25em solid #7367f0;
    border-right-color: transparent;
}
`;
const RangosStepsCss = {
    cont,
    contInputsAndButtonsActions,
    input,
    contInputAndLabel,
    text,
    btn,
    tableRangos,
    inputChecbox,
    contInputRadio,
    inputRadio,
    btnSelecCategory,
    contLoader,
    btnUpdate
}
export default RangosStepsCss;