import React from "react";

/**
 * !import table
 */
import TableDashboard from "./dashboard/TableDashboard";

export default function UserEnterpriseDas() {
  return <TableDashboard />;
}
