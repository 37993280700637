import styled from "styled-components";

const ContainerGeneral = styled.div`
/* border: 1px solid red; */
padding: 10px;
height: auto;
box-sizing: border-box;
`;

const header = styled.div`
width:100%;
align-self: flex-start;
display:flex;
align-items:center;
justify-content:flex-start;
margin-top:10px;
`;

const text = styled.p`
margin-bottom:  ${props=> props.mb || '0'};;
color: ${props=> props.cl || '#707070'};
font-size: ${props=> props.size || '12px'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
font-weight: ${props=> props.fw || 'lighter'};
margin-top: ${props=> props.mt || '0'};
`;

const btnSend = styled.button`
width: auto;
height: 38px;
background: #7367f0;
padding: 8px 16px;
border-radius:0.5rem;
border: none;
outline: none !important;
font-weight: 500;
transition: all ease 0.5s;
font-size:12pt;
color: white;
margin-right: ${props=> props.mr || '0px'};
margin-left: ${props=> props.ml || '0px'};
&:hover {
    box-shadow: 0 3px 10px 0 #7367f0;
}
&:disabled {
    cursor: no-drop;
    background: #7367f099;
}
`;

const input = styled.input`
    width: ${props => props.w || '100%'};
    height: 38px;
    border-radius: .357rem;
    background: #fff;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    border: 1px solid #d8d6de;
    outline: none !important;
    padding:6px 16px;
    font-size:12pt;
    color: rgb(112, 112, 112);
    font-weight: 600;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12px;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus, &:active {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
            box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
        }
    margin-top : ${props => props.mt || 0};
`;

const inputChecbox = styled.div`
    .cbx {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    }
    .cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
    }
    .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #9098A9;
    transition: all 0.2s ease;
    }
    .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
    }
    .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #7367f0;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
    }
    .cbx:hover span:first-child {
    border-color: #7367f0;
    }

    .inp-cbx:checked + .cbx span:first-child {
    background: #7367f0;
    border-color: #7367f0;
    animation: wave 0.4s ease;
    }
    .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
    }
    .inp-cbx:checked + .cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
    }

    @keyframes wave {
    50% {
        transform: scale(0.9);
    }
    }
    @media(max-width: 600px) {
        width:100%;
    }
`;

const select = styled.select`
    padding-left:10px;
    padding-right:10px;
    font-family: "Berlin Rounded";
    width: ${props => props.wd || '100%'};
    margin-left: ${props => props.ml || '0'};
    margin-right: ${props => props.mr || '0'};
    height: 38px;
    border-radius: .357rem;
    background: white;
    border: 1px solid #d8d6de;
    outline: none !important;
    font-family: "Berlin Rounded";
    font-weight: 300;
    font-size: 12px;
    color: #707070;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12px;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
            box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
        }
        @media(max-width: ${props => props.selectMq || '989px'}) {
        width:100%;
    }
`;

const textArea = styled.textarea`
    padding-left:10px;
    padding-right:10px;
    font-family: "Berlin Rounded";
    width: 100%;
    margin-left: ${props=> props.ml || '0'};
    margin-right: ${props=> props.mr || '0'};
    height: ${props=> props.hg || '150px'};
    border-radius: .357rem;
    background: #fff;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    border: 1px solid #d8d6de;
    outline: none !important;
    color: #707070;
    font-size: 12pt;
    font-weight: 300;
    resize:none;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12px;
        color: rgba(112, 112, 112, 0.61);
        }
    &:focus {
        border: 0.5px solid rgba(115, 103, 240, 0.7);
        box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    }
        /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg,#7367f0,rgba(115,103,240,.7));
        border-radius: 50px;
        
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {        
        box-shadow: 2px 0px 6px #7367f0;
        
    }
`;

const ContMenuAndForms = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
box-sizing: border-box;
margin-top: 15px;
@media (max-width: 990px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
`;

const MenuSidebar = styled.div`
width: 300px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
margin-right: 10px;
position: sticky;
top: 0;
right: 10px;
bottom: auto;
align-self: flex-start;
@media (max-width: 990px) {
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 15px;
    justify-content: space-between;
}
/* border: 1px solid blue; */
`;

const MenuItems = styled.div`
margin-top: 0px;
width: 100%;
border-radius: .429rem;
height: 40px;
padding: 10px;
font-size: 12pt;
font-weight: ${props=> props.active ? '500' : 'lighter'};;
cursor: pointer;
color: ${props=> props.active ? '#FFFFFF' : '#707070'};
display: flex;
justify-content: flex-start;
align-items: center;
transition: all ease-out .2s;
background: ${props=> props.active ? 'linear-gradient(#7367f0 0%, rgba(115, 103, 240, 0.7) 100%)' : 'trasnparenta'};
filter: ${props=> props.active ? 'drop-shadow(2px 0px 6px #7367f0)' : 'none'};
@media (max-width: 990px) {
    width: 280px;
    margin-left: 5px;
    margin-right: 5px;
}
`;

const ContIcon = styled.span`
margin-right: 10px;
color: ${props=> props.active ? '#FFFFFF' : '#707070'};
font-size: 12pt;
display: flex;
justify-content: center;
align-items: center;
svg {
    path {
        stroke: ${props=> props.active ? '#FFFFFF' : '#707070'};
    }
}
`;

const ContSteps = styled.div`
margin-left: 0;
margin-left: 0;
width: 100%;
height: auto;
/* border: 1px solid red; */
align-self: flex-start;
`;

const ContForm = styled.div`
width: 100%;
border: none;
border-radius: .428rem;
background-clip: border-box;
background: ${props=> props.color || 'white'};
align-items: center;
box-shadow: ${props=> props.notShador ? 'none' :  '0 4px 24px 0 rgb(34 41 47 / 10%)'};
padding: 10px;
display: grid;
grid-gap: 0.5rem;
max-width: 100%;
grid-template-columns: ${props=> props.isSingle ? 'repeat(auto-fill, minmax(100%, 100%))' : 'repeat(auto-fill, minmax(300px, 1fr))'};
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`;

const ContFormTwo = styled.div`
width: 100%;
border: none;
border-radius: .428rem;
background-clip: border-box;
background: ${props=> props.color || 'white'};
align-items: center;
box-shadow: ${props=> props.notShador ? 'none' :  '0 4px 24px 0 rgb(34 41 47 / 10%)'};
padding: 20px 10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`;

const ContSectionFomr = styled.div.attrs(props => ({
    display: props.d || 'grid'
}))`
border: 1px solid rgba(112, 112, 112, 0.32);
border-radius: .429rem;
width: 100%;
max-width: 100%;
padding: 15px 5px;
position: relative;
display: ${props => props.display};
grid-gap: 0.5rem;
grid-template-columns: ${props=> props.isSingle ? 'repeat(auto-fill, minmax(100%, 100%))' : 'repeat(auto-fill, minmax(300px, 1fr))'};
margin-bottom: 15px;
`;

const TagSectionForm = styled.span`
background: #FFFF;
font-size: 12pt;
color:#7367f0;
text-align: center;
line-height: 1;
font-weight: 600;
width: auto;
position: absolute;
top: -10px;
left: auto;
right: 10px;
`;

const ContInputAndLabel = styled.div`
width:100%;
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'space-between'};
align-items:${props => props.ali || 'flex-start'};
`;
const ContLoader = styled.div`
width: 100%;
height: 100%;
margin-top: auto;
margin-bottom: auto;
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
.spinner-border {
    border: .25em solid #7367f0;
    border-right-color: transparent;
}
`;

const ARow = styled.div`
width: 100%;
display: flex;
justify-content: ${props=> props.jc || 'flex-end'};
align-items: center;
margin-top: 10px;
`;

const ConfigStyled = {
    ContainerGeneral,
    header,
    text,
    ContMenuAndForms,
    MenuSidebar,
    MenuItems,
    ContIcon,
    ContSteps,
    input,
    select,
    textArea,
    ContForm,
    ContInputAndLabel,
    ContLoader,
    ARow,
    btnSend,
    ContFormTwo,
    ContSectionFomr,
    TagSectionForm,
    inputChecbox
}

export default ConfigStyled;