import React, { useEffect, useState, useContext } from "react";
import MlStyled from "../../../../assets/css/components/admin/plugins/MlStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Container, Form } from "react-bootstrap";
import MlLogo from "../../../../assets/img/png/ml_logo.png";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  keyUserId,
  keyAccessToken,
  keyUserActiveEnterpriseId,
} from "../../../../assets/js/SessionStorageNames";
import { Redirect } from "react-router-dom";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import Routes from "../../../../Routes";
import verifyAccessRole from "../../../../assets/js/verifyAccessRole";

export default function MercadoLibreConf() {
  const globalContext = useContext(GlobalContext);

  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [redirect, setRedirect] = useState(null);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");

  
useEffect(() => {
  const hasAccess = verifyAccessRole(Routes, 46);
  if(!hasAccess) {
      setRedirect(
          <Redirect to={`/admin/dashboard`} />
      )
  }
},[]);

  /**
   * Handle the axios error
   * @param {Error} err The thrown error
   * @param {string} msg A message to show in the modal
   * @param {boolean} consoleLog If should log the error in the console
   */
  const handleAxiosError = (err, msg = null, consoleLog = true) => {
    if (axios.isCancel(err)) return; //Don't show axios cancel error
    if (msg) globalContext.showModalWithMsg(msg);
    if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
  };

  /**
   * Update the selected country
   */
  const updateCountry = (e) => {
    setCountry(e.target.value);
  }

  useEffect(() => {
    axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/country/available-for-plugin/ml`, {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      }
    ).then(resp => {
      const data = resp.data.data;
      setCountries(data.map(r => {
        return {
          name: r.name,
          id: r.ml.id,
        };
      }));
    }).catch((err) =>
      handleAxiosError(
        err,
        "Disculpe, no se pudo cargar la lista de paises."
      )
    );
  }, []);

  /**
   * Listener to obtain the ML code
   */
  useEffect(() => {
    const msgEvent = (e) => {
      if (e.origin === `${process.env.REACT_APP_URL}` && e.data.code) {
        const entId = localStorage.getItem(keyUserActiveEnterpriseId);

        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/v1/ml-account/${entId}`,
            {
              code: e.data.code,
              country: country.toString().toLowerCase(),
            },
            {
              headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
              },
            }
          )
          .then((res) => {
            setRedirect(
              <Redirect to="/admin/plugins/my-plugins/mercado-libre-success" />
            );
          })
          .catch((err) => {
            const msg =
              err.response && err.response.data
                ? err.response.data.message
                : undefined;
            globalContext.showModalWithMsg(
              msg ||
                "Disculpe ha ocurrido un error al intentar asociar su cuenta de Mercadolibre"
            );
            if (err.response) {
              console.log("Respuesta: ", err.response.data);
            }
            console.error("Error: ", err);
          });
      }
    };
    window.addEventListener("message", msgEvent);

    return () => {
      window.removeEventListener("message", msgEvent);
    };
  });

  /**
   * On ml add account link click
   */
  const onLinkClick = (e) => {
    e.preventDefault();
    const entId = localStorage.getItem(keyUserActiveEnterpriseId);

    if (!entId) {
      globalContext.showModalWithMsg("Debe seleccionar una empresa");
      return;
    }

    if (!country) {
      globalContext.showModalWithMsg("Debe seleccionar un País");
      return;
    }

    const countryCode = country.toString().toLowerCase();

    
    let appClientId = process.env.REACT_APP_ML_CLIENT_ID;

    switch (countryCode) {
      case "cbt":
        appClientId = process.env.REACT_APP_ML_CLIENT_ID_CBT;
        break;
      case "co":
        appClientId = process.env.REACT_APP_ML_CLIENT_ID_CO;
        break;
    }

    if (countryCode == "cbt") {
      window.open(
        `https://global-selling.mercadolibre.com/authorization?response_type=code&client_id=${appClientId}`
          + `&redirect_uri=${process.env.REACT_APP_ML_REDIRECT_URI}`
      );
    } else {
      window.open(
        `https://auth.mercadolibre.com.${countryCode}/authorization?response_type=code&client_id=${appClientId}`
          + `&redirect_uri=${process.env.REACT_APP_ML_REDIRECT_URI}`
      );
    }
  };

  /**
   * Sync ML brands
   */
  const syncBrands = (e) => {
    const entId = localStorage.getItem(keyUserActiveEnterpriseId);

    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-brand/${entId}/sync`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      })
      .then((resp) => {
        globalContext.showModalWithMsg(
          "Las tiendas oficiales fueron actualizadas"
        );
      })
      .catch((err) => {
        const msg =
          err.response && err.response.data
            ? err.response.data.message
            : undefined;
        globalContext.showModalWithMsg(
          msg ||
            "Disculpe ha ocurrido un error al intentar sincronizar las tiendas"
        );
        if (err.response) {
          console.log("Respuesta: ", err.response.data);
        }
        console.error("Error: ", err);
      });
  };

  return (
    <MlStyled>
      {redirect}
      <div className="goBack d-flex justify-content-start mt-2">
        <Link to="/admin/plugins/my-plugins/my-menu-plugins">
          <button className="btn-back">
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        </Link>
      </div>
      <Container fluid>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex justify-content-center  align-items-center"
          >
            <Container className="bodyMlForm">
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="d-flex justify-content-center  align-items-center"
                >
                  <div className="formimg">
                    <img src={MlLogo} alt="ml" />
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="d-flex justify-content-center  align-items-center"
                >
                  <div className="formTxt">
                    <p>
                      Agrega tus credenciales de Mercado Libre a E-kommers.com
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="d-flex justify-content-center  align-items-center mb-5"
                >
                  <Form.Control
                    as="select"
                    size="sm"
                    name="country"
                    value={country}
                    onChange={updateCountry}
                    className="d-flex justify-content-center  align-items-center"
                  >
                    <option value="">Seleccione País</option>
                    {countries.length > 0 &&
                      countries.map((p) => {
                        return (
                          <option value={p.id} key={p.id}>
                            {p.name}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="d-flex justify-content-center  align-items-center mb-2"
                >
                  <button className="btn-action" onClick={onLinkClick}>
                    Ir a MercadoLibre{" "}
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </button>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="d-flex justify-content-center  align-items-center mb-2"
                >
                  <button className="btn-action" onClick={syncBrands}>
                    Sincronizar tiendas oficiales{" "}
                  </button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </MlStyled>
  );
}
