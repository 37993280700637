import styled from "styled-components";

const ContModal = styled.div.attrs(props => ({
    // we can define static props
    activeOne: props.active ? 'flex' : 'none',
    activeTwo: props.active ? '1' : '0',
}))`
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
width: 100%;
height: 100%;
justify-content: center;
align-items: center;
padding: 1rem;
box-sizing: border-box;
background: white;
opacity: ${props => props.activeTwo};
display: ${props => props.activeOne};
transition: opacity ease-in-out .15s, display ease .5s;
`;

const ContIconAndTitle = styled.div`
    min-width: 300px;
    position: absolute;
    left: 10px;
    top: 10px;
    right: auto;
    bottom: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const ContLogo = styled.div`
width: 50px;
height: 50px;
background-image: url(${props => props.url || ''});
background-position: center;
background-size: 100%;
background-repeat: no-repeat;
`


const FormLogin = styled.form`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
border: 2px solid #ebe9f1;
padding: 1rem;
border-radius: .429rem;
min-width: 300px;
max-width: 500px;
min-height: 300px;
max-height: 700px;
overflow: hidden;
@media (max-width: 990px){
    max-width: 500px;
    width: 100%;
}
`
const ContInputAndLabel = styled.div`
width:100%;
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'space-between'};
align-items:${props => props.ali || 'flex-start'};
`;

const Text = styled.p`
color: ${props => props.cl || '#707070'};
font-size: ${props => props.size || '.857rem'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '.2857rem'};
margin-bottom: ${props => props.mb || '.2857rem'};
text-overflow:${props => props.longText ? 'ellipsis' : ''};
overflow:${props => props.longText ? 'hidden' : ''};
white-space:${props => props.longText ? 'nowrap' : ''};
`;

const Input = styled.input`
display: block;
width: 100%;
height: 38px;
font-size: .875rem;
font-weight: 400;
line-height: 1.45;
color: #6e6b7b;
padding: 0.438rem 1rem;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #d8d6de;
border-radius: 0.357rem;
position: relative;
&:focus {
    color: #6e6b7b;
    outline: 0;
    background-color: #fff;
    border-color: #7367f0;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}
::placeholder {
    color: #c0c0c1;
    font-size: 1rem;
}
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
`;


const BtnSend = styled.button`
outline: none;
border: none;
border-color: #7367f0;
width: 100%;
height: 38px;
color: white;
font-size: 12pt;
font-weight: 500;
text-align: center;
background-color: #7367f0;
padding: .150rem;
border-radius: 0.358rem;
margin-top: 1rem;
&:disabled {
    border-color: #a49df5;
    background-color: #a49df5;
}
`
const ContBadgeError = styled.div`
border-radius: 0.358rem;
background: #ea5455!important;
width: 100%;
height: ${props => props.active ? 'auto' : '0px'};
padding: ${props => props.active ? '1rem' : '0'};
opacity:  ${props => props.active ? '1' : '0'};
position: relative;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
margin-bottom: 1rem;
overflow: hidden;
transition: opacity .15s ease-in-out, height .15s ease-in-out, padding .1s ease-in-out;
`;

const BtnToggleBadge = styled.button`
width: 25px;
height: 25px;
outline: none !important;
border-radius: 50px;
display: flex;
justify-content: center;
align-items: center;
border: 1px solid #FFFFFF;
color: #FFFFFF;
background-color: transparent;
cursor: pointer;
position: absolute;
left: auto;
top: auto;
right: 10px;
bottom: auto;
`;

const ContIconNadInputPass = styled.div`
width: 100%;
position: relative;
height: auto;
box-sizing: border-box;
display: flex;
justify-content: center;
align-items: center;
`
const BtnShowPass = styled.button`
width: 30px;
height: 30px;
outline: none !important;
border-radius: 50px;
display: flex;
justify-content: center;
align-items: center;
border: 1px solid transparent;
color: #707070;
background-color: transparent;
cursor: pointer;
position: absolute;
left: auto;
top: auto;
right: 10px;
bottom: auto;
&:hover {
    background-color: #f0f0f0;
}
`;

const VerifyLoginStyle = {
    ContModal,
    ContIconAndTitle,
    ContLogo,
    FormLogin,
    ContInputAndLabel,
    Text,
    Input,
    BtnSend,
    ContBadgeError,
    BtnToggleBadge,
    ContIconNadInputPass,
    BtnShowPass
}

export default VerifyLoginStyle;