import styled from 'styled-components';

const contModalTracking = styled.div`
position: fixed;
top:0;
right:0;
bottom:0;
left:0;
width:100%;
height:100%;
background: rgba(112, 112, 112, 0.38);
z-index:100000;
display: ${props=>props.active ? 'flex' : 'none'};
justify-content:center;
align-items:center;
padding:10px;
overflow: auto;
`
const modalTracking = styled.div`
display: flex;
flex-direction: column;
justify-content:flex-start;
align-items:center;
background-color: #ffffff;
border-radius:5px;
min-width:300px;
min-height: 400px;
margin-top:auto;
margin-bottom:auto;
width:600px;
height:auto;
`;

const headerModal = styled.div`
padding:10px;
height: 60px;
width:100%;
background:#F5F5F6;
display: flex;
justify-content:flex-start;
align-items:center;
align-self: flex-start;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
`
const textHeader = styled.p`
margin-bottom: 0;
color: #707070;
font-size: ${props=> props.size || '12pt'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
font-weight:500;
`
const footerModal = styled.div`
padding:10px;
height: 60px;
width:100%;
display: flex;
justify-content:flex-end;
align-items:center;
align-self: flex-end;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
border-top:0.5px solid rgba(112, 112, 112, 0.5);
margin-top: auto;
`;
const btnCloseModal = styled.button`
width:38px;
height: 38px;
border-radius:50px;
border:none;
color: #ffffff;
background-color: #7367f0;
display:flex;
justify-content:center;
align-items:center;
outline:none !important;
cursor: pointer;
margin-right: auto;
`
const bodyModal = styled.div`
width:100%;
min-width:300px;
display:flex;
flex-direction:column;
justify-content: center;
align-items: center;
align-self:center;
padding: 0 10px;
.cont-tracking-nro {
    width:100%;
    padding:10px;
    margin-top:0;
    margin-bottom:10px;
    text-align:right;
    color:#7367f0;
    font-weight:500;
    font-size:12pt;
}
`;

const contInputAndLabel = styled.div`
width:100%;
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'space-between'};
align-items:${props => props.ali || 'flex-start'};
`;
const btnActionFooter = styled.button`
border: 0.5px solid;
border-color: ${props=> props.bc || '#707070'};
background-color: ${props=> props.bgc || 'transparent'};
padding: 8px 16px;
font-size: 12pt;
margin-left: ${props=> props.ml || '0'};
color: ${props=> props.cl || '#707070'};
border-radius:5px;
font-weight:600;
outline:none !important;
`;
const contLoader = styled.div`
    background-color: #ffffff;
    height:300px;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    z-index:100000;
    .spinner-border {
        border: .25em solid #7367f0;
        border-right-color: transparent;
    }
`;
const input = styled.input`
    padding-left:10px;
    font-family: "Berlin Rounded";
    width: ${props=> props.wd || '100%'};
    margin-left: ${props=> props.ml || '0'};
    margin-right: ${props=> props.mr || '0'};
    height: 38px;
    border-radius: 5px;
    background: #fff;
    border: 0.5px solid rgba(112, 112, 112, 0.7);
    outline: none !important;
    color: rgba(112, 112, 112, 0.61);
    font-size: 12pt;
    font-weight: 300;
    margin-bottom: 10px;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
        }
    @media(max-width: ${props => props.inputMq || '989px'}) {
        width:100%;
    }
`;
const text = styled.p`
margin-bottom:  ${props => props.mb || '0'};
color: ${props => props.color || '#707070'};
font-size: ${props => props.size || '13pt'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '0'};
`;

const contDataTracking = styled.div`
width:100%;
height:auto;
max-height:500px;
overflow-y:auto;
display:flex;
flex-direction:column;
justify-content:flex-start;
align-items:flex-start;
.cont-text-data-tracking {
    width:100%;
}
`;
const dataTracking = styled.div`
/* border:1px solid; */
width:100%;
height: 50px;
padding:10px;
display:flex;
justify-content:flex-start;
align-items:center;
margin-top: 15px;
margin-bottom:15px;
cursor: pointer;
`;

const contNroTracking = styled.div`
height:40px;
width:40px;
border-radius:.8rem;
background: ${props => props.active ? '#7367f0' : '#FFFFFF'};
color: ${props => props.active ? '#FFFFFF' : '#7367f0'};
border: 1px solid #7367f0;
display:flex;
justify-content:center;
align-items:center;
z-index:2;
transition: all ease-in-out 0.3s;
`;

const lineStatustracking = styled.div`
width:10px;
min-height:65px;
border-radius:50px;
max-height: 280px;
height: ${props => props.h || '65px'};
background: #7367F0;
align-self: flex-start;
z-index:1;
position: absolute;
transform: translate(25px, 20px);
transition: all ease-in-out 0.3s;
`;
const Tracking = {
    contModalTracking,
    modalTracking,
    headerModal,
    footerModal,
    btnCloseModal,
    textHeader,
    btnActionFooter,
    bodyModal,
    contInputAndLabel,
    input,
    text,
    contDataTracking,
    dataTracking,
    contNroTracking,
    lineStatustracking
}

export default Tracking