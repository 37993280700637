import React, { useState, useContext, useEffect } from "react";
import { Form, Col, Row, Container, Button } from "react-bootstrap";
import {
    keyUserId,
    keyAccessToken,
} from "../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../contexts/GlobalContext";
import axios from 'axios';
import { Redirect } from "react-router-dom";
import Routes from "../../Routes";
import verifyAccessRole from "../../assets/js/verifyAccessRole";
import DateTime from "../admin/cron-reports/components/DateTime";

export default function TestAdmin() {
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [inputs, setInputs] = useState({});
    const [redirect, setRedirect] = useState(null);
    const [pageReport, setPageReport] = useState(1);
    const [limitReport, setLimitReport] = useState(1000);
    const [dateReportUrl, setDateReportUrl] = useState('');

    // useEffect(() => {
    //     const hasAccess = verifyAccessRole(Routes, 64);
    //     if(!hasAccess) {
    //         setRedirect(
    //             <Redirect to={`/admin/dashboard`} />
    //         )
    //     }
    // },[]);

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const updateInputState = (e) => {
        const name = e.target.name;
        const newState = Object.assign({}, inputs);
    
        switch (e.target.type) {
          case "checkbox":
            newState[name] = e.target.checked;
            break;
    
          default:
            newState[name] = e.target.value;
            break;
        }
    
        setInputs(newState);
      };

    const downloadCategories = () => {
        if (!inputs.mlCountry) {
            globalContext.showModalWithMsg("El País de ML");
            return;
        }

        const processCheckIntervalTime = 5000;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        globalContext.setLoaderPage(true);
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/ml-category/sync/${inputs.mlCountry.toLowerCase()}`,
                {},
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                const data = resp.data.data;

                if (data.monitor) {
                    //If the process ID was received, start monitoring
                    let waitingMonitorResponse = false;
                    const interval = setInterval(() => {
                        if (!waitingMonitorResponse) {
                            waitingMonitorResponse = true;

                            axios
                                .get(
                                    `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                                    {
                                        cancelToken: axiosCancelTokenSource.token,
                                        headers,
                                    }
                                )
                                .then((resp) => {
                                    const data = resp.data.data;

                                    if (data.advance >= 100) {
                                        clearInterval(interval);
                                        globalContext.showModalWithMsg(
                                            `Se descargaron`
                                        );
                                        globalContext.setLoaderPage(false);
                                    } else {
                                        const advance = data.advance.toFixed(2);
                                        globalContext.setLoaderPage(true, `${advance}% (Categorias: ${data.data.savedCategories}/${data.data.totalCategories}) (Subcategorias: ${data.data.savedSubcategories}/?)`);
                                    }
                                })
                                .catch((err) => {
                                    handleAxiosError(
                                        err,
                                        "Disculpe, no se pudo culminar la descarga de los productos."
                                    );
                                    clearInterval(interval);
                                    globalContext.setLoaderPage(false);
                                })
                                .finally(() => {
                                    waitingMonitorResponse = false;
                                });
                        }
                    }, processCheckIntervalTime);
                } else {
                    globalContext.setLoaderPage(false);
                }
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo culminar la descarga de los productos."
                );
                globalContext.setLoaderPage(false);
            });       
    };

    const downloadAmazonCategories = () => {
        if (!inputs.numAmazonLevel) {
            globalContext.showModalWithMsg("El numero de niveles");
            return;
        }

        const processCheckIntervalTime = 5000;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        globalContext.setLoaderPage(true);
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/parse-categories`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                    params: {
                        maxDepth: inputs.numAmazonLevel,
                    }
                }
            )
            .then((resp) => {
                const data = resp.data.data;

                if (data.monitor) {
                    //If the process ID was received, start monitoring
                    let waitingMonitorResponse = false;
                    const interval = setInterval(() => {
                        if (!waitingMonitorResponse) {
                            waitingMonitorResponse = true;

                            axios
                                .get(
                                    `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                                    {
                                        cancelToken: axiosCancelTokenSource.token,
                                        headers,
                                    }
                                )
                                .then((resp) => {
                                    const data = resp.data.data;

                                    if (data.advance >= 100) {
                                        clearInterval(interval);
                                        globalContext.showModalWithMsg(
                                            `Se descargaron`
                                        );
                                        globalContext.setLoaderPage(false);
                                    } else {
                                        const advance = data.advance.toFixed(2);
                                        globalContext.setLoaderPage(true, `${advance}% (Categorias: ${data.data.savedCategories}/${data.data.totalCategories})`);
                                    }
                                })
                                .catch((err) => {
                                    handleAxiosError(
                                        err,
                                        "Disculpe, no se pudo culminar la descarga de los productos."
                                    );
                                    clearInterval(interval);
                                    globalContext.setLoaderPage(false);
                                })
                                .finally(() => {
                                    waitingMonitorResponse = false;
                                });
                        }
                    }, processCheckIntervalTime);
                } else {
                    globalContext.setLoaderPage(false);
                }
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo culminar la descarga de los productos."
                );
                globalContext.setLoaderPage(false);
            });       
    };

    const scrapAmazonCategoryNode = () => {
        const entId = globalContext.currentEnterpriseId;
        const cateId = inputs.categoryToScrap;
        const maxProdCount = inputs.numProdToScrapFromCategory;        

        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        } else if (!cateId) {
            globalContext.showModalWithMsg("La categoria...");
            return;
        } else if (!maxProdCount) {
            globalContext.showModalWithMsg("La cantidad de productos...");
            return;
        }

        const processCheckIntervalTime = 5000;
        let processModitorId = 0;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };
        
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/parse-category-products`, {
            headers,
            params: {
                cateId,
                maxProdCount
            }
        }).then((resp) => {
            globalContext.setLoaderPage(false);
            globalContext.showModalWithMsg("El proceso se esta ejecutando en segundo plano");
        }).catch(err => handleAxiosError(err));
    }

    const updateMlUsers = () => {
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-account/update-users`, {}, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const fixScrapMl = () => {
        const processCheckIntervalTime = 5000;
        let processModitorId = 0;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };
        
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/fix-ml`, {
            headers,
        }).then((resp) => {
            const data = resp.data.data;

            if (data.monitor) {
                processModitorId = data.monitor;

                //If the process ID was received, start monitoring
                let waitingMonitorResponse = false;
                const interval = setInterval(() => {
                    if (!waitingMonitorResponse) {
                        waitingMonitorResponse = true;

                        axios
                            .get(
                                `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                                {
                                    cancelToken: axiosCancelTokenSource.token,
                                    headers,
                                }
                            )
                            .then((resp) => {
                                const data = resp.data.data;

                                if (data.advance< 100) {
                                    const advance = data.advance.toFixed(2);
                                    globalContext.setLoaderPage(true, `${advance}% (${data.data.currentProd}/${data.data.totalProducts})`);
                                } else {
                                    globalContext.setLoaderPage(false);
                                    clearInterval(interval);
                                    globalContext.showModalWithMsg("Termino");
                                }
                            })
                            .catch((err) => {
                                handleAxiosError(
                                    err,
                                    "Disculpe, no se pudo culminar."
                                );
                                clearInterval(interval);
                                globalContext.setLoaderPage(false);
                            })
                            .finally(() => {
                                waitingMonitorResponse = false;
                            });
                    }
                }, processCheckIntervalTime);
            } else {
                globalContext.setLoaderPage(false);
            }
        }).catch(err => handleAxiosError(err));
    }

    const syncCurrencies = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-currency/sync`, {}, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const syncCountries = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-country/sync`, {}, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const startAmazonScrapCron = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/cron/start-scrap-amazon-scraped-products`, {}, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    /* useEffect(() => {
        setInterval(() => {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/get-all`, {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            }).then(data => console.log("Respuesta: ", data.data.data)).catch(err => console.error("Fallo: ", err))
        }, 5000)
    }, []); */

    const syncNordServers = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/nord/sync-servers`, {}, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const nordVpnUser = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/test/nordVpn`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const getAmazonScrapConfig = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/config`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => {
            console.log(resp.data);
            const newState = Object.assign({}, inputs);
            newState.amazonScrapConfig = JSON.stringify(resp.data.data.config, null, 4);
            setInputs(newState);
        }).catch(err => handleAxiosError(err));
    }

    const setAmazonScrapConfig = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/config`, {
            config: JSON.parse(inputs.amazonScrapConfig),
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const scrapedVariantToProduct = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/set-scraped-variant-as-product`, {}, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const addProxyAcc = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/proxy-acc/`, {
            brand: 'NordVPN',
            enabled: true,
            user: inputs.proxyAccUser,
            maxConn: 6,
            pass: inputs.proxyAccPass,
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }
    const sendMlProd = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/ml-post-one-product-from-odoo`, {
            dataProd: {
                productId: '618d64c21f6cfbf9db5c6e54',
                title: 'TEST DE ACEITUNAS PARA LF',
                price: 15,
                body: 'descripcion de prueba',
                condition: 'new',
                images: 'algo',
                pausePublication: 'paused',
                aditionalsParams: {
                    brand: 'algo',
                    model: 'test',
                    width: '1',
                    height: '1',
                    length_prod: '1',
                    weight: '1'
                }
                },
                entId: '5f0eaeb398c3f413782defb5',
                categoryMl: '61c9eb53a17fb2e14ef98bbc',
                listingType: 'gold_special',
                officialStoreId: '933'
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const updateProxyAcc = () => {
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/proxy-acc/${inputs.proxyAccId}`, {
            pass: inputs.proxyAccPass,
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const getProxyAcc = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/proxy-acc/`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            params: {
                pageNumber: 1,
                pageItemCount: 20,
            }
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }
    
    const disableProxyAcc = () => {
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/v1/proxy-acc/${inputs.proxyAccId}`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }
    
    const delNoUrl = () => {
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/delete-products-without-url`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }


    const downloadCategoriesAnican = () => {
        globalContext.setLoaderPage(true);
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/anicam/products/list`,
                {
                    enterpriseId: "5f0eaeb398c3f413782defb5",
                    page: 1,
                    limit: 10,
                    filter: {
                        title: 'Estufa'
                    }
                },
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                const data = resp.data.data;
                globalContext.setLoaderPage(true);
                console.log('marcas anican',data);
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo culminar la descarga de los productos."
                );
                globalContext.setLoaderPage(false);
            });       
    };

    const testJesus = () => {
        globalContext.setLoaderPage(true);
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/aliexp-prod/${globalContext.currentEnterpriseId}/cat`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                const data = resp.data.data;
                globalContext.setLoaderPage(true);
                console.log('marcas anican',data);
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo culminar la descarga de los productos."
                );
                globalContext.setLoaderPage(false);
            });       
    };

    const downloadodooCategories = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo/odooCat`, {
            enterpriseId: globalContext.currentEnterpriseId,
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const removeBackground = () => {
        globalContext.setLoaderPage(true);
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/clean-img/send-list-img-to-clean`,
                {
    listImgsBase64: ('any hash on base64'),
enterpriseId: "5f0eaeb398c3f413782defb5"
                },
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                const data = resp.data.data;
                globalContext.setLoaderPage(false);
                console.log('data clean img',data);
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo culminar la descarga de los productos."
                );
                globalContext.setLoaderPage(false);
            });       
    };

    const sendCategoriesWoo = () => {
        globalContext.setLoaderPage(true);
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/create-categories`,
                {
                plugin: 'AmazonScrap',
                enterpriseId: "60944575461add5b1957ef8b"
                },
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                const data = resp.data.data;
                globalContext.setLoaderPage(false);
                console.log('data clean img',data);
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo culminar la descarga de los productos."
                );
                globalContext.setLoaderPage(false);
            });       
    };


    const testKeepa = () => {
        globalContext.setLoaderPage(true);
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/clean-prod-keepa/${globalContext.currentEnterpriseId}`,
                {
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                const data = resp.data.data;
                globalContext.setLoaderPage(false);
                console.log('data keepa',data);
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo culminar la descarga de los productos."
                );
                globalContext.setLoaderPage(false);
            });       
    }

    const lodeml = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/test/update-no-translated`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const freeShipping = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/test/update-shipping`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const getDataPrice = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-report/pricesissues/${globalContext.currentEnterpriseId}/${pageReport}/${limitReport}`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }
    const downloadAllImg = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo-image/${globalContext.currentEnterpriseId}/download-all-images`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const downloadAllImgVariants = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo-image/${globalContext.currentEnterpriseId}/download-all-images-variants`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const downloadAllImgProduct = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo-image/${globalContext.currentEnterpriseId}/download-all-images-products`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const getScrapedReport = () => {
        globalContext.setLoaderPage(true);
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-report/scraped-url-by-acc-type/${dateReportUrl}`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                const data = resp.data.data;
                console.log(data);
                globalContext.setLoaderPage(false);
                globalContext.showModalWithMsg("Se esjecuta en segundo plano");
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo culminar la descarga de los productos."
                );
                globalContext.setLoaderPage(false);
            });       
    };

    const getRescrapedReport = () => {
        if (!dateReportUrl) {
            globalContext.showModalWithMsg("La fecha");
            return;
        }

        globalContext.setLoaderPage(true);
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-report/rescraped-by-ent/${dateReportUrl}`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                const data = resp.data.data;
                console.log(data);
                globalContext.setLoaderPage(false);
                globalContext.showModalWithMsg("Se esjecuta en segundo plano");
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo culminar la descarga del reporte."
                );
                globalContext.setLoaderPage(false);
            });       
    };

    const getRbacs = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/rbac/`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            params: {
                pageNumber: 1,
                pageItemCount: 10,
                filter: {
                    free: null
                }
            }
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const getRbac = () => {
        const rbacId = inputs.rbacId;

        if (!rbacId) {
            globalContext.showModalWithMsg("El ID...");
            return;
        }

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/rbac/${rbacId}`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            }
        }).then(resp => {
            const data = resp.data.data;
            const newState = Object.assign({}, inputs);
            newState.rbacRoute = data.route;
            newState.rbacEnabled = data.enabled;
            newState.rbacFree = data.free;
            newState.rbacMethod = data.method;
            newState.rbacRoles = data.rolesIds.join(",");
            setInputs(newState);
        }).catch(err => handleAxiosError(err));
    }

    const postRbacs = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/rbac/`, {
            route: inputs.rbacRoute,
            method: inputs.rbacMethod,
            free: inputs.rbacFree || false,
            enabled: inputs.rbacEnabled || false,
            rolesIds: inputs.rbacRoles && inputs.rbacRoles.split(",") || [],
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            }
        }).then(resp => {
            console.log(resp.data)
            globalContext.showModalWithMsg("Registrado");
            const data = resp.data.data.rbac;
            const newState = Object.assign({}, inputs);
            newState.rbacId = data._id;
            newState.rbacRoute = data.route;
            newState.rbacEnabled = data.enabled;
            newState.rbacFree = data.free;
            newState.rbacMethod = data.method;
            newState.rbacRoles = data.rolesIds.join(",");
            setInputs(newState);
        }).catch(err => handleAxiosError(err));
    }
    
    const putRbac = () => {
        const rbacId = inputs.rbacId;

        if (!rbacId) {
            globalContext.showModalWithMsg("El ID...");
            return;
        }

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/rbac/${rbacId}`, {
            free: true,
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            }
        }).then(resp => {
            console.log(resp.data)
            globalContext.showModalWithMsg("Actualizado");
            const data = resp.data.data.rbac;
            const newState = Object.assign({}, inputs);
            newState.rbacRoute = data.route;
            newState.rbacEnabled = data.enabled;
            newState.rbacFree = data.free;
            newState.rbacMethod = data.method;
            newState.rbacRoles = data.rolesIds.join(",");
            setInputs(newState);
        }).catch(err => handleAxiosError(err));
    }
    
    const delRbac = () => {
        const rbacId = inputs.rbacId;

        if (!rbacId) {
            globalContext.showModalWithMsg("El ID...");
            return;
        }

        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/v1/rbac/${rbacId}`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            }
        }).then(resp => {
            console.log(resp.data);
            globalContext.showModalWithMsg("Eliminado");
        }).catch(err => handleAxiosError(err));
    }

    const getDuplicatedReport = () => {
        globalContext.setLoaderPage(true);
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-report/duplicated-titles`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                const data = resp.data.data;
                console.log(data);
                globalContext.setLoaderPage(false);
                globalContext.showModalWithMsg("Se esjecuta en segundo plano");
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo culminar la descarga del reporte."
                );
                globalContext.setLoaderPage(false);
            });       
    };

    const deleteDuplicatedTitle = () => {
        globalContext.setLoaderPage(true);
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/amaz-prod/delete-duplicated-titles`,
                {
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                const data = resp.data.data;
                globalContext.setLoaderPage(false);
                console.log('data: ', data);
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo culminar."
                );
                globalContext.setLoaderPage(false);
            });       
    }
    const weightToMl = () => {
        
        const entId = globalContext.currentEnterpriseId;      

        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }
        globalContext.setLoaderPage(true);
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-report/weight-for-ml/${globalContext.currentEnterpriseId}`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                const data = resp.data.data;
                console.log(data);
                globalContext.setLoaderPage(false);
                globalContext.showModalWithMsg("Se esjecuta en segundo plano");
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo culminar la descarga del reporte."
                );
                globalContext.setLoaderPage(false);
            });       
    };

    const amazonPublishSimulation = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amaz-prod/simulate-publishing`, {
            prodIds: ["638e702c9b766e6b4986e998"],
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            }
        }).then(resp => {
            console.log(resp.data)
        }).catch(err => handleAxiosError(err));
    }

    return (
        <Container>
            {redirect}
            
            <Form className="mt-3">
            <Row>
                <Col>
                    <Button onClick={() => downloadAllImg()}>
                        Descargar todas las imagenes de odoo
                    </Button>
                </Col>
                <Col>
                    <Button onClick={() => downloadAllImgVariants()}>
                        Descargar todas las imagenes de odoo Variantes
                    </Button>
                </Col>
                <Col>
                    <Button onClick={() => downloadAllImgProduct()}>
                        Descargar todas las imagenes de odoo Productos
                    </Button>
                </Col>
            </Row>
            <Row>
            <Col md={3}>
                    <Button onClick={() => testKeepa()}>
                        Test Keepa
                    </Button>
                </Col>
                <Col md={3}>
                    <Button onClick={() => downloadodooCategories()}>
                        Descargar Cat odoo
                    </Button>
                </Col>
                    <Col md={3}>
                    <Button onClick={() => testJesus()}>sync categories ali</Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                    <Button onClick={() => sendCategoriesWoo()}>enviar categorias Woo</Button>
                    </Col>
                </Row>
                <Row className="mt-1">
                <Col md={3}>
                    <Button onClick={() => removeBackground()}>Limpiar imagenes</Button>
                </Col>
                <Col md={3}>
                    <Button onClick={() => downloadCategoriesAnican()}>Braands Anican</Button>
                </Col>
                    <Col md={3}>
                        
                        <Button onClick={() => sendMlProd()}>Publica ml odoo ML</Button>
                    </Col>
                    <Col md={3}>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="País ML"
                            name="mlCountry"
                            value={inputs.mlCountry || ""}
                            onChange={updateInputState}
                        />
                        <Button onClick={downloadCategories}>Descargar Categorias ML</Button>
                    </Col>
                    <Col md={3}>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Num Niveles"
                            name="numAmazonLevel"
                            value={inputs.numAmazonLevel || ""}
                            onChange={updateInputState}
                        />
                        <Button onClick={downloadAmazonCategories}>Descargar Categorias Amazon</Button>
                    </Col>
                    <Col md={12}>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="ID categoria Amazon"
                            name="categoryToScrap"
                            value={inputs.categoryToScrap || ""}
                            onChange={updateInputState}
                        />
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Cantidad Productos"
                            name="numProdToScrapFromCategory"
                            value={inputs.numProdToScrapFromCategory || ""}
                            onChange={updateInputState}
                        />
                        <Button onClick={scrapAmazonCategoryNode}>Scrap</Button>
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col md={2}>
                        <Button onClick={updateMlUsers}>Update ML Users</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={fixScrapMl}>fixScrapMl</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={syncCurrencies}>syncCurrencies</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={syncCountries}>syncCountries</Button>
                    </Col>
                </Row>


                <Row className="mt-1">
                    <Col md={2}>
                        <Button onClick={startAmazonScrapCron}>Start Amazon Scrap Cron</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={syncNordServers}>Sync Nord Servers</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={nordVpnUser}>NordVpnUser</Button>
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col md={3}>
                        <Button onClick={getAmazonScrapConfig}>GetAmazonScrapConfig</Button>
                        <Button onClick={setAmazonScrapConfig}>SetAmazonScrapConfig</Button>
                    </Col>

                    <Col md={9}>
                        <Form.Control
                            size="sm"
                            as="textarea"
                            placeholder="Config"
                            name="amazonScrapConfig"
                            value={inputs.amazonScrapConfig || ""}
                            onChange={updateInputState}
                        />
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col md={2}>
                        <Button onClick={scrapedVariantToProduct}>scrapedVariantToProduct</Button>
                    </Col>
                </Row>
                
                <Row className="mt-1">
                    <Col md={2}>
                        <Button onClick={addProxyAcc}>addProxyAcc</Button>
                    </Col>
                    <Col md={2}>
                        <Button onClick={updateProxyAcc}>updateProxyAcc</Button>
                    </Col>
                    <Col md={2}>
                        <Button onClick={getProxyAcc}>getProxyAcc</Button>
                    </Col>
                    <Col md={2}>
                        <Button onClick={disableProxyAcc}>disableProxyAcc</Button>
                    </Col>
                    <Col md={4}>
                        <Form.Control
                            size="sm"
                            placeholder="ProxyAcc Mongo ID"
                            name="proxyAccId"
                            value={inputs.proxyAccId || ""}
                            onChange={updateInputState}
                        />
                        <Form.Control
                            size="sm"
                            placeholder="ProxyAcc User"
                            name="proxyAccUser"
                            value={inputs.proxyAccUser || ""}
                            onChange={updateInputState}
                        />
                        <Form.Control
                            size="sm"
                            placeholder="ProxyAcc Pass"
                            name="proxyAccPass"
                            value={inputs.proxyAccPass || ""}
                            onChange={updateInputState}
                        />
                    </Col>
                </Row>
 
                <Row className="mt-1">
                    <Col md={2}>
                        <Button onClick={delNoUrl}>delNoUrl</Button>
                    </Col>
                    <Col md={2}>
                        <Button onClick={lodeml}>Traducir titulos</Button>
                    </Col>
                    <Col md={2}>
                        <Button onClick={freeShipping}>Free shipping</Button>
                    </Col>
                </Row>
                <Row>
                <Col md={2}>
                        <Button onClick={getDataPrice}>getDataPrice</Button>
                    </Col>
                    <Col md={2}>
                    <input
                            size="sm"
                            placeholder="Pagina"
                            name="pagina"
                            onChange={(e) => setPageReport(e.target.value)}
                        />
                    </Col>
                    <Col md={2}>
                    <input
                            size="sm"
                            placeholder="Limite por paginal"
                            name="total"
                            onChange={(e) => setLimitReport(e.target.value)}
                        />
                    </Col>
                </Row>
            </Form>
            <Row className="mt-1">
                <Col md={2}>
                    <Button onClick={getScrapedReport}>Descargar reporte de scrapeados</Button>
                    <Button onClick={getRescrapedReport}>Descargar reporte de rescrapeados</Button>
                </Col>
                <Col md={2}>
                    Fecha reporte:
                    <DateTime
                        setValue={setDateReportUrl}
                        valueDefaultText="Fecha Desde"
                    />
                </Col>
                <Col md={5}>
                    <Form.Control
                        size="sm"
                        placeholder="Rbac ID"
                        name="rbacId"
                        value={inputs.rbacId || ""}
                        onChange={updateInputState}
                        />
                    <Form.Control
                        size="sm"
                        placeholder="Rbac route"
                        name="rbacRoute"
                        value={inputs.rbacRoute || ""}
                        onChange={updateInputState}
                        />
                    <Form.Switch
                        label="Rbac enabled"
                        name="rbacEnabled"
                        id="rbacEnabled"
                        checked={inputs.rbacEnabled || false}
                        onChange={updateInputState}
                        />
                    <Form.Switch
                        label="Rbac free"
                        name="rbacFree"
                        id="rbacFree"
                        checked={inputs.rbacFree || false}
                        onChange={updateInputState}
                        />
                    <Form.Control
                        size="sm"
                        placeholder="Rbac method"
                        name="rbacMethod"
                        value={inputs.rbacMethod || ""}
                        onChange={updateInputState}
                        />
                    <Form.Control
                        as="textarea"
                        size="sm"
                        placeholder="Rbac roles"
                        name="rbacRoles"
                        value={inputs.rbacRoles || ""}
                        onChange={updateInputState}
                        />
                </Col>
                <Col md={2}>
                    <Button onClick={getRbacs}>getAllRbacs</Button>
                    <br/><Button onClick={getRbac}>getRbac</Button>
                    <br/><Button onClick={postRbacs}>postRbacs</Button>
                    <br/><Button onClick={putRbac}>putRbac</Button>
                    <br/><Button onClick={delRbac}>delRbac</Button>
                </Col>
            </Row>

            <Row className="mt-1">
                <Col md={2}>
                    <Button onClick={getDuplicatedReport}>getDuplicatedReport</Button>
                </Col>
                
            </Row>
            <Row className="mt-1">
                <Col md={2}>
                    <Button onClick={weightToMl}>weightToMl</Button>
                </Col>
                <Col><Form.Control
                        size="sm"
                        placeholder="entWeigth"
                        name="entWeigth"
                        value={inputs.entWeigth || ""}
                        onChange={updateInputState}
                        /></Col>
                <Col md={2}>
                    <Button onClick={amazonPublishSimulation}>amazonPublishSimulation</Button>
                </Col>
            </Row>
        </Container>
    );
}