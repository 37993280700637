import styled from "styled-components";
const FilterProductStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  .input-group {
    input {
      font-family: "Roboto Slab", serif !important;
      outline: none;
      padding-left: 5px;
      border: 0.5px solid #5e548e;
      transition: all ease 450ms;
      &:focus {
        outline: none !important;
        outline-width: 0 !important;
        box-shadow: none;
        color: #5e548e;
        border-color: #cec28e;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
      }
    }
    .input-group-append {
      button {
        outline: none;
        border: none;
        padding: 0 16px;
        font-weight: bold;
        color: #ffffff;
        background-color: #5e548e;
        &:focus,
        &:active,
        &:checked,
        &:active:focus {
          outline: none !important;
          outline-width: 0 !important;
          box-shadow: none;
          -moz-box-shadow: none;
          -webkit-box-shadow: none;
        }
      }
    }
  }
`;
export default FilterProductStyle;
