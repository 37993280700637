import axios from "axios";
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
const getDataOne = async (fechaDesde, entId) => {
        const res = axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-report/${entId}/reportPublishedByUser/${fechaDesde}`,
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
    return res;
}
export default getDataOne;