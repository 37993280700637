import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
const saveData = async (entId, data) => {
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/df-camp/${entId}/send-from-odoo-pricelist`, {
        isRegister: data.isRegister,
        campaignName: data.campaignName,
        pricelistId: data.pricelistId,
        plusPercent: data.plusPercent,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd
    }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res
}

export default saveData;