import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';

const getCategory = (currentPage, pageItemCount, categoriesP, includeTree, getAll, filter, axiosCancelTokenSource, entId) => {
    const dataSend = axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/categories`, {
        params: {
            pageNumber: currentPage,
            pageItemCount: pageItemCount,
            parent: categoriesP,
            filter: {
                includeTree: includeTree,
                getAll: getAll,
                search: filter,
                lang: 'en',
                enterpriseId: entId,
                productsCount: true
                
            },
        },
        cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
    });
    return dataSend;
}

export default getCategory;