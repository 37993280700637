import React, { useContext, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { BiPause, BiPencil, BiPlay, BiPlus, BiSync } from 'react-icons/bi'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle'
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import getAllRestaurant from '../functions/getAllRestaurant';
import getSections from '../functions/getSections';
import disabledSeccion from '../functions/disabledSeccion';
import FormLinkSections from './FormLinkSections';

export default function SectionsList({ sections, setSections, setCurrentStep, setCurrentSections }) {
    const globalContext = useContext(GlobalContext);
    const [isLoad, setIsLoad] = useState(false);
    const [isLoadTwo, setIsLoadTwo] = useState(true);
    const [restaurants, setRestaurants] = useState([])

    const funcSetStep = (v) => {
        setCurrentStep(v);
    }

    const funcSetCurrentSucursal = (v) => {
        setCurrentSections(v);
        funcSetStep(5);
    }

    const sectSectionsFunc = (v) => {
        setSections(v);
    }
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId
        if (!entId) return;
        getAllRestaurant(entId)
            .then((res) => {
                const datRes = res.data.data;
                setRestaurants(datRes);
            })
            .catch(err => {
                console.log('el error res', err)
            })
    }, [globalContext.currentEnterpriseId])

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId
        if (!entId) return;
        getSections(entId)
            .then((res) => {
                const dataSections = res.data.data;
                sectSectionsFunc(dataSections);
                setIsLoadTwo(false)
            })
            .catch(err => {
                setIsLoadTwo(false)
                console.log('el error', err)
            })
    }, [globalContext.currentEnterpriseId])

    const renderOneRestauran = (id) => {
        if (restaurants.length > 0) {
            const dataRes = restaurants.find(v => v._id === id);
            if (!dataRes) return '';
            return dataRes.store;
            //return 'si hay algo'
        } else {
            return ''
        }
    }

    const changeStatus = ({ _id, enabled, restaurant }) => {
        setIsLoad(true);
        const entId = globalContext.currentEnterpriseId;
        disabledSeccion(_id, enabled, restaurant, entId)
            .then(() => {
                let copyArray = [...sections];
                copyArray.find(v => v._id === _id).enabled = !enabled;
                setSections(copyArray);
                setIsLoad(false);
                console.log('se actualizo')
            })
            .catch(err => {
                setIsLoad(false);
                console.log('no se actualizo')
            })
    }

    return (
        <>
        <PedidosYaStyle.ContTable>
            <PedidosYaStyle.HeaderContTable>
                <PedidosYaStyle.Text size="13pt" fw="500">
                    Listado de Secciones o Categorias
                </PedidosYaStyle.Text>
                <div className="d-flex justify-content-center" style={{ width: 'auto' }}>
                    <PedidosYaStyle.BtnAndIcon mr="5px" onClick={() => funcSetStep(5)}>
                        <PedidosYaStyle.ContIconBtn>
                            <BiPlus />
                        </PedidosYaStyle.ContIconBtn>
                        Nueva
                    </PedidosYaStyle.BtnAndIcon>
                    <PedidosYaStyle.BtnAndIcon ml="5px" onClick={() => globalContext.setLinkSections(true)}>
                        <PedidosYaStyle.ContIconBtn>
                            <BiSync />
                        </PedidosYaStyle.ContIconBtn>
                        Sincronizar
                    </PedidosYaStyle.BtnAndIcon>
                </div>
            </PedidosYaStyle.HeaderContTable>
            <PedidosYaStyle.TableResult>
                <PedidosYaStyle.TableResultThead>
                    <PedidosYaStyle.TableResultTr>
                        <PedidosYaStyle.TableResultTh width="300px" minWidth="100px" textAlign="center">
                            Nombre de la seccion
                        </PedidosYaStyle.TableResultTh>
                        <PedidosYaStyle.TableResultTh width="300px" minWidth="100px" textAlign="center">
                            Sucursal
                        </PedidosYaStyle.TableResultTh>
                        <PedidosYaStyle.TableResultTh width="100px" minWidth="100px" textAlign="center">
                            Accion
                        </PedidosYaStyle.TableResultTh>
                    </PedidosYaStyle.TableResultTr>
                </PedidosYaStyle.TableResultThead>
                {
                    isLoadTwo ? (
                        <PedidosYaStyle.TableResultTbody>
                            <PedidosYaStyle.TableResultTr>
                                <PedidosYaStyle.TableResultTd width="100%" minWidth="100px" textAlign="center">
                                    <PedidosYaStyle.contLoader>
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Cargando...</span>
                                        </Spinner>
                                    </PedidosYaStyle.contLoader>
                                </PedidosYaStyle.TableResultTd>
                            </PedidosYaStyle.TableResultTr>

                        </PedidosYaStyle.TableResultTbody>
                    ) :
                    (
                        <PedidosYaStyle.TableResultTbody>
                                {
                                    sections.length > 0 &&
                                    sections.map(v => {
                                        return (
                                            <PedidosYaStyle.TableResultTr key={v._id}>
                                                <PedidosYaStyle.TableResultTd width="300px" minWidth="100px" textAlign="center">
                                                    {v.name}
                                                </PedidosYaStyle.TableResultTd>
                                                <PedidosYaStyle.TableResultTd width="300px" minWidth="100px" textAlign="center">
                                                    {
                                                        restaurants.length > 0 &&
                                                        //v.restaurant
                                                        renderOneRestauran(v.restaurant)
                                                    }
                                                </PedidosYaStyle.TableResultTd>
                                                <PedidosYaStyle.TableResultTd width="100px" minWidth="100px" textAlign="center" backGroundColor="#F3F2F7">
                                                    <div className="d-flex justify-content-center align-items-center" style={{ with: '100%' }}>
                                                        {
                                                            isLoad ?
                                                            (
                                                                <PedidosYaStyle.contLoader>
                                                                        <Spinner animation="border" role="status">
                                                                            <span className="sr-only">Cargando...</span>
                                                                        </Spinner>
                                                                    </PedidosYaStyle.contLoader>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        <PedidosYaStyle.BtnIcon
                                                                            onClick={() => funcSetCurrentSucursal(v)}
                                                                            mRight="auto"
                                                                            mLeft="auto"
                                                                            width="30px"
                                                                            height="30px">
                                                                            <BiPencil />
                                                                        </PedidosYaStyle.BtnIcon>
                                                                        {
                                                                            v.enabled ?
                                                                                (
                                                                                    <PedidosYaStyle.BtnIcon
                                                                                    onClick={() => changeStatus(v)}
                                                                                        mRight="auto"
                                                                                        mLeft="auto"
                                                                                        width="30px"
                                                                                        height="30px">
                                                                                        <BiPause />
                                                                                    </PedidosYaStyle.BtnIcon>
                                                                                ) :
                                                                                (
                                                                                    <PedidosYaStyle.BtnIcon
                                                                                    onClick={() => changeStatus(v)}
                                                                                    mRight="auto"
                                                                                        mLeft="auto"
                                                                                        width="30px"
                                                                                        height="30px">
                                                                                        <BiPlay />
                                                                                    </PedidosYaStyle.BtnIcon>
                                                                                )
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                    </div>
                                                </PedidosYaStyle.TableResultTd>
                                            </PedidosYaStyle.TableResultTr>
                                        )
                                    })
                                }
                            </PedidosYaStyle.TableResultTbody>
                        )
                }
            </PedidosYaStyle.TableResult>
        </PedidosYaStyle.ContTable>
        <FormLinkSections />
        </>
    )
}