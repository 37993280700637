import React, { useState } from 'react'
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as locales from 'react-date-range/dist/locale';
import { Calendar } from 'react-date-range';
import BuildFormCss from '../../../../../../assets/css/components/admin/products/post-product/steps/BuildFormCss';

export default function DateTime(props) {
    const {setValue, valuesCategorys, index} = props;
    const [date, setDate] = useState(null);
    const [open, setOpen] = useState(false);
    const [text, setText] = useState(valuesCategorys[index].value)
    const changeDate = date => {
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        setDate(date)
        //setState(yyyy +'-'+ mm +'-'+ dd);
        setText(dd + '-' + mm + '-' + yyyy)
        handleChangeValue(dd + '-' + mm + '-' + yyyy)
        setOpen(false)
    }
    const handleChangeValue = (valor) => {
        const copyArray = [...valuesCategorys];
        copyArray[index].value = valor;
        setValue(copyArray);
        console.log('valores', valuesCategorys);
    }

    return (
        <BuildFormCss.dataPikerInput>
            <div 
                className="input-date d-flex justify-content-between align-items-center"
                onClick={e => setOpen(!open)}
            >
            {text}
            <button className={`btn-date ${open && 'active'}`} >
                <FontAwesomeIcon icon={faCalendar} />
            </button>
            </div>
            <div className={`cont-calendar ${open && 'active'}`} >
            <Calendar 
                onChange={item => changeDate(item)}
                locale={locales['es']} date={date} />
            </div>
        </BuildFormCss.dataPikerInput>
    )
}
