import axios from "axios";
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
const getDataErrorCron = async (dateOne, dataTwo, entId, typeReport) => {
    if (typeReport === 1) {
        const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-report/${entId}/reportCronError`,
        {
            dateFrom: dateOne,
            dateTo: dataTwo,
        },{
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
    return res;
    }else {
        const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-report/${entId}/reportCronAct`,
        {
            dateFrom: dateOne,
            dateTo: dataTwo,
        },{
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
    return res;
    }
    
}
export default getDataErrorCron;