import styled from "styled-components";

const Cont = styled.div.attrs(props => ({
    heightContLoader: props.heightContLoader || '20px',
    bgcContLoader: props.bgcContLoader || '#F3F2F7',
}))`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;
border-radius: 50px;
height: ${props => props.heightContLoader};
background: ${props =>  props.bgcContLoader};
`;

const BarLoader = styled.div.attrs(props => ({
    bgcBarLoader: props.bgcBarLoader || '#7367F0',
    wBarLoader: props.wBarLoader || '0',
}))`
width: ${props => props.wBarLoader};
background: ${props =>  props.bgcBarLoader};
height: 100%;
position: absolute;
left: 0;
top: 0;
bottom: 0;
`

const CustomProgressBarStyle = {
    Cont,
    BarLoader
};

export default CustomProgressBarStyle;