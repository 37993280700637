import React, { useContext, useState } from 'react'
import { BiX, BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { BtnNextImg, BtnOptionsCard, ContImgCard } from '../../../../../../../assets/css/components/admin/marketplace-to-marketplace/cardItem/CardItemStyle';
import { GlobalContext } from '../../../../../../../contexts/GlobalContext';

export default function CardContImage({ urlImage, id }) {
    const [imgPosition, setImgPosition] = useState(0);
    const globalContext = useContext(GlobalContext)

    const deleteItem = (data) => {
        let copyArr = [...globalContext.listProductsToSend];
        //? buscamos el valor a ver si ya existe
        const existId = copyArr.find(v => v._id === data);
        if (existId) {
            const newData = copyArr.filter(v => v._id !== data);
            globalContext.setListProductsToSend(newData);
        }
    }

    const nextImg = () => {
        const maxImg = urlImage.length - 1;
        if (imgPosition === maxImg) return;
        setImgPosition(imgPosition + 1);
    }
    const backImg = () => {
        if (imgPosition === 0) return;
        setImgPosition(imgPosition - 1);
    }
    //console.log('la img q llega', urlImage)
    return (
        <ContImgCard url={urlImage[imgPosition]}>
            <BtnOptionsCard onClick={() => deleteItem(id)}>
                <BiX />
            </BtnOptionsCard>
            {
                imgPosition > 0 && (
                    <BtnNextImg onClick={() => backImg()}>
                        <BiChevronLeft />
                    </BtnNextImg>
                )
            }
            {
                (imgPosition < urlImage.length - 1 && urlImage.length > 1) && (
                    <BtnNextImg l="auto" r="10px" onClick={() => nextImg()}>
                        <BiChevronRight />
                    </BtnNextImg>
                )
            }
        </ContImgCard>
    )
}
