import axios from 'axios';
import React, { useContext } from 'react'
import OtrosParametrosCss from '../../../../../assets/css/components/admin/products/ml/rangos-publicacion/OtrosParametrosCss'
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';
import { GlobalContext } from '../../../../../contexts/GlobalContext';

export default function OtrosParametros(props) {
    const globalContext = useContext(GlobalContext);
    const {
        setCourierPlus,
        setCourierPlusIsPercent,
        setPoundPrice,
        setAdditionalPoundPrice,
        setSafe,
        setSafeIsPercent,
        setCcTax,
        setCcTaxIsPercent,
        setDefaultTariff,
        setDefaultTariffIsPercent,
        setIva,
        tariff,
        earning,
        courierPlus,
        courierPlusIsPercent,
        poundPrice,
        additionalPoundPrice,
        safe,
        safeIsPercent,
        ccTax,
        ccTaxIsPercent,
        defaultTariff,
        defaultTariffIsPercent,
        iva,
        defaultComission,
        setDefaultComission,
        defaultComissionIsPercent,
        setDefaultComissionIsPercent,
        defaultMaxStock,
        setDefaultMaxStock,
        deliveryDaysPlus,
        setDeliveryDaysPlus,
    } = props;

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const funcSetCourierPlus = (v) => {
        setCourierPlus(v)
    }
    const funcSetCourierPlusIsPercent = (v) => {
        setCourierPlusIsPercent(v)
    }
    const funcSetPoundPrice = (v) => {
        setPoundPrice(v)
    }
    const funcSetAdditionalPoundPrice = (v) => {
        setAdditionalPoundPrice(v)
    }
    const funcSetSafe = (v) => {
        setSafe(v)
    }
    const funcSetSafeIsPercent = (v) => {
        setSafeIsPercent(v)
    }
    const funcSetCcTax = (v) => {
        setCcTax(v)
    }
    const funcSetCcTaxIsPercent = (v) => {
        setCcTaxIsPercent(v)
    }
    const funcSetDefaultTariff = (v) => {
        setDefaultTariff(v)
    }
    const funcSetDefaultTariffIsPercent = (v) => {
        setDefaultTariffIsPercent(v)
    }
    const funcSetIva = (v) => {
        setIva(v)
    }
    const funcSetDefaultComission = (v) => {
        setDefaultComission(v)
    }
    const funcSetDefaultComissionIsPercent = (v) => {
        setDefaultComissionIsPercent(v)
    }

    const functSetDefaultMaxStock = (v) => {
        setDefaultMaxStock(v)
    }
    const functSetDeliveryDaysPlus = (v) => {
        setDeliveryDaysPlus(v)
    }     


    const save = () => {
        // const publishPriceCalculations=  {
        //   tariff:tariff,
        //   earning:earning,
        //   courierPlus:parseFloat(courierPlus),
        //   courierPlusIsPercent:courierPlusIsPercent,
        //   poundPrice:parseFloat(poundPrice),
        //   additionalPoundPrice:parseFloat(additionalPoundPrice),
        //   safe:parseFloat(safe.toString().replace(',','.')) + ' safe',
        //   safeIsPercent:safeIsPercent,
        //   ccTax:parseFloat(ccTax.toString().replace(',','.')),
        //   ccTaxIsPercent:ccTaxIsPercent,
        //   defaultTariff:parseFloat(defaultTariff),
        //   defaultTariffIsPercent:defaultTariffIsPercent,
        //   iva:parseFloat(iva),
        //   ivaIsPercent:true,
        // }
        // console.log('send', publishPriceCalculations)
        globalContext.setLoaderPage(true);
        const entId = globalContext.currentEnterpriseId
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
            config: {
                publishPriceCalculation: {
                    tariff:tariff,
                    earning:earning,
                    courierPlus:parseFloat(courierPlus),
                    courierPlusIsPercent:courierPlusIsPercent,
                    poundPrice:parseFloat(poundPrice),
                    additionalPoundPrice:parseFloat(additionalPoundPrice),
                    safe:parseFloat(safe.toString().replace(',','.')),
                    safeIsPercent:safeIsPercent,
                    ccTax:parseFloat(ccTax.toString().replace(',','.')),
                    ccTaxIsPercent:ccTaxIsPercent,
                    defaultTariff:parseFloat(defaultTariff),
                    defaultTariffIsPercent:defaultTariffIsPercent,
                    iva:parseFloat(iva),
                    ivaIsPercent:true,
                    defaultComission:parseFloat(defaultComission),
                    defaultComissionIsPercent:defaultComissionIsPercent,
                },
                defaultMaxStock: parseInt(defaultMaxStock),
                deliveryDaysPlus: parseInt(deliveryDaysPlus)
            }
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => {
            globalContext.setLoaderPage(false);
            globalContext.showModalWithMsg(
                `Plantilla guardada exitosamente!`
            );
            //console.log(resp.data)
        }).catch(err => {
            globalContext.setLoaderPage(false);
            handleAxiosError(err)
        });
    }

    return (
        <OtrosParametrosCss.contParent>

        <OtrosParametrosCss.cont>
            <OtrosParametrosCss.contInputAndLabel>
                <OtrosParametrosCss.text fw="bolder" mb="5px">
                    Monto de protección
                </OtrosParametrosCss.text>
                <OtrosParametrosCss.input type="number" name="courierPlus" value={courierPlus} onChange={(e) => funcSetCourierPlus(e.target.value)} placeholder="Escriba aqui.." />
            </OtrosParametrosCss.contInputAndLabel>

            <OtrosParametrosCss.contInputAndLabel jsc="space-between">
                <OtrosParametrosCss.text fw="bolder" mb="5px">
                    ¿Monto de protección es porcentaje?
                </OtrosParametrosCss.text>
                <OtrosParametrosCss.contInputRadio>
                    <OtrosParametrosCss.inputRadio type="radio" id="r1" value={true} name="courierPlusIsPercent" checked={courierPlusIsPercent} onChange={(e) => funcSetCourierPlusIsPercent(true)}/>
                    <label htmlFor="r1">Si</label>
                    <OtrosParametrosCss.inputRadio type="radio" id="r2" value={false} name="courierPlusIsPercent" checked={!courierPlusIsPercent} ml="10px" onChange={(e) => funcSetCourierPlusIsPercent(false)}/>
                    <label htmlFor="r2">No</label>
                </OtrosParametrosCss.contInputRadio>
            </OtrosParametrosCss.contInputAndLabel>

            <OtrosParametrosCss.contInputAndLabel>
                <OtrosParametrosCss.text fw="bolder" mb="5px">
                    Precio para la primera libra
                </OtrosParametrosCss.text>
                <OtrosParametrosCss.input type="number" name="poundPrice" value={poundPrice} onChange={(e) => funcSetPoundPrice(e.target.value)} placeholder="Escriba aqui.." />
            </OtrosParametrosCss.contInputAndLabel>

            <OtrosParametrosCss.contInputAndLabel>
                <OtrosParametrosCss.text fw="bolder" mb="5px">
                    Precio para la segunda libra
                </OtrosParametrosCss.text>
                <OtrosParametrosCss.input type="number" name="poundPrice" value={additionalPoundPrice} onChange={(e) =>  funcSetAdditionalPoundPrice(e.target.value)} placeholder="Escriba aqui.." />
            </OtrosParametrosCss.contInputAndLabel>

            <OtrosParametrosCss.contInputAndLabel>
                <OtrosParametrosCss.text fw="bolder" mb="5px">
                    IVA
                </OtrosParametrosCss.text>
                <OtrosParametrosCss.input type="number" placeholder="Escriba aqui.." value={iva}   onChange={(e) => funcSetIva(e.target.value)} />
            </OtrosParametrosCss.contInputAndLabel>

            <OtrosParametrosCss.contInputAndLabel>
                <OtrosParametrosCss.text fw="bolder" mb="5px">
                    Impuesto de la tarjeta de crédito
                </OtrosParametrosCss.text>
                <OtrosParametrosCss.input type="number" name="poundPrice" value={ccTax} onChange={(e) =>  funcSetCcTax(e.target.value)} placeholder="Escriba aqui.." />
            </OtrosParametrosCss.contInputAndLabel>

            <OtrosParametrosCss.contInputAndLabel jsc="space-between">
                <OtrosParametrosCss.text fw="bolder" mb="5px">
                ¿El impuesto de la tarjeta de crédito es un porcentaje?
                </OtrosParametrosCss.text>
                <OtrosParametrosCss.contInputRadio>
                    <OtrosParametrosCss.inputRadio type="radio" id="tp1" value={true} checked={ccTaxIsPercent} name="ccTaxIsPercent" onChange={(e) =>  funcSetCcTaxIsPercent(true)}/>
                    <label htmlFor="tp1">Si</label>
                    <OtrosParametrosCss.inputRadio type="radio" id="tp2" value={false} checked={!ccTaxIsPercent} name="ccTaxIsPercent" ml="10px" onChange={(e) =>  funcSetCcTaxIsPercent(false)}/>
                    <label htmlFor="tp2">No</label>
                </OtrosParametrosCss.contInputRadio>
            </OtrosParametrosCss.contInputAndLabel>

            <OtrosParametrosCss.contInputAndLabel>
                <OtrosParametrosCss.text fw="bolder" mb="5px">
                    Arancel promedio por defecto
                </OtrosParametrosCss.text>
                <OtrosParametrosCss.input type="text" placeholder="Escriba aqui.." value={defaultTariff}  onChange={(e) =>  funcSetDefaultTariff(e.target.value)} />
            </OtrosParametrosCss.contInputAndLabel>

            <OtrosParametrosCss.contInputAndLabel jsc="space-between">
                <OtrosParametrosCss.text fw="bolder" mb="5px">
                    ¿El arancel por defecto es un porcentaje?
                </OtrosParametrosCss.text>
                <OtrosParametrosCss.contInputRadio>
                    <OtrosParametrosCss.inputRadio type="radio" id="st1" value={true} checked={defaultTariffIsPercent} name="defaultTariffIsPercent" onChange={(e) =>  funcSetDefaultTariffIsPercent(true)}/>
                    <label htmlFor="st1">Si</label>
                    <OtrosParametrosCss.inputRadio type="radio" id="st2" value={false} checked={!defaultTariffIsPercent} name="defaultTariffIsPercent" ml="10px" onChange={(e) =>  funcSetDefaultTariffIsPercent(false)}/>
                    <label htmlFor="st2">No</label>
                </OtrosParametrosCss.contInputRadio>
            </OtrosParametrosCss.contInputAndLabel>

            <OtrosParametrosCss.contInputAndLabel>
                <OtrosParametrosCss.text fw="bolder" mb="5px">
                    Costo del seguro de la mercancía
                </OtrosParametrosCss.text>
                <OtrosParametrosCss.input type="number" name="poundPrice" value={safe} onChange={(e) =>  funcSetSafe(e.target.value)} placeholder="Escriba aqui.." />
            </OtrosParametrosCss.contInputAndLabel>

            <OtrosParametrosCss.contInputAndLabel jsc="space-between">
                <OtrosParametrosCss.text fw="bolder" mb="5px">
                    ¿El costo para seguro de la mercancía es un porcentaje?
                </OtrosParametrosCss.text>
                <OtrosParametrosCss.contInputRadio>
                    <OtrosParametrosCss.inputRadio type="radio" id="a1" value={true} checked={safeIsPercent} name="safeIsPercent" onChange={(e) =>  funcSetSafeIsPercent(true)}/>
                    <label htmlFor="a1">Si</label>
                    <OtrosParametrosCss.inputRadio type="radio" id="a2" value={false} checked={!safeIsPercent} name="safeIsPercent" onChange={(e) =>  funcSetSafeIsPercent(false)} ml="10px"/>
                    <label htmlFor="a2">No</label>
                </OtrosParametrosCss.contInputRadio>
            </OtrosParametrosCss.contInputAndLabel>

            <OtrosParametrosCss.contInputAndLabel>
                <OtrosParametrosCss.text fw="bolder" mb="5px">
                    Comision del MarketPlace
                </OtrosParametrosCss.text>
                <OtrosParametrosCss.input type="number" name="defaultCommission" value={defaultComission} onChange={(e) => funcSetDefaultComission(e.target.value)} placeholder="Escriba aqui.." />
            </OtrosParametrosCss.contInputAndLabel>
            <OtrosParametrosCss.contInputAndLabel jsc="space-between">
                <OtrosParametrosCss.text fw="bolder" mb="5px">
                    ¿Comision del MarketPlace es un porcentaje?
                </OtrosParametrosCss.text>
                <OtrosParametrosCss.contInputRadio>
                    <OtrosParametrosCss.inputRadio type="radio" id="a1" value={true} checked={defaultComissionIsPercent} onChange={() => funcSetDefaultComissionIsPercent(true)} name="" />
                    <label htmlFor="a1">Si</label>
                    <OtrosParametrosCss.inputRadio type="radio" id="a2" value={false} checked={!defaultComissionIsPercent} onChange={() => funcSetDefaultComissionIsPercent(false)}  name=""  ml="10px"/>
                    <label htmlFor="a2">No</label>
                </OtrosParametrosCss.contInputRadio>
            </OtrosParametrosCss.contInputAndLabel>

            <OtrosParametrosCss.contInputAndLabel>
                <OtrosParametrosCss.text fw="bolder" mb="5px">
                    Limite maximo de stock
                </OtrosParametrosCss.text>
                <OtrosParametrosCss.input type="number" name="poundPrice" value={defaultMaxStock} onChange={(e) =>  functSetDefaultMaxStock(e.target.value)} placeholder="Escriba aqui.." />
            </OtrosParametrosCss.contInputAndLabel>

            <OtrosParametrosCss.contInputAndLabel>
                <OtrosParametrosCss.text fw="bolder" mb="5px">
                    Tiempo de envio adicional
                </OtrosParametrosCss.text>
                <OtrosParametrosCss.input type="number" name="poundPrice" value={deliveryDaysPlus} onChange={(e) =>  functSetDeliveryDaysPlus(e.target.value)} placeholder="Escriba aqui.." />
            </OtrosParametrosCss.contInputAndLabel>

        </OtrosParametrosCss.cont>

        <OtrosParametrosCss.btn onClick={() => save()}>
                Guardar
        </OtrosParametrosCss.btn>
        </OtrosParametrosCss.contParent>
    )
}
