import React from 'react'
import CustomProgressBarStyle from '../../assets/css/components/general/CustomProgressBarStyle'

export default function CustomProgressBar(props) {
    const {wLoader} = props;
    return (
        <CustomProgressBarStyle.Cont>
            <CustomProgressBarStyle.BarLoader wBarLoader={wLoader}/>
        </CustomProgressBarStyle.Cont>
    )
}
