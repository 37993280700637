import styled from "styled-components";
const MlStyled = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  .content-breadcrumb {
    padding: 10px;
  }
  .bodyMlForm {
    padding: 10px 10px;
    background-color: #f8f9fa;
  }
  .formTxt {
    font-family: "Roboto Slab", serif !important;
    color: #2c318b;
    font-size: 25pt;
    font-weight: bold;
  }
  .btn-action {
    font-family: "Roboto Slab", serif !important;
    border: none;
    border-radius: 5px;
    padding: 18px 32px;
    font-size: 16pt;
    color: #2c318b;
    background-color: #f6dc31;
  }
  .goBack {
    .btn-back {
      border: 1px solid rgb(94, 84, 142);
      color: rgb(94, 84, 142);
      width: 30px;
      height: 30px;
      background: transparent;
      border-radius: 50%;
      transition: all ease 450ms;
      &:hover {
        background: rgb(94, 84, 142);
        color: white;
      }
      &:focus,
      &:active,
      &:active:focus {
        outline: none;
      }
    }
  }
  /**
  * ? select style
   */
  select {
    font-family: "Roboto Slab", serif !important;
    box-shadow: none;
    outline: none;
    border: 0.5px solid #5e548e;
    transition: all ease 450ms;
    width: 50%;
    &:hover {
      box-shadow: none;
      color: #5e548e;
      border-color: #cec28e;
    }
    &:focus {
      box-shadow: none;
      color: #5e548e;
      border-color: #cec28e;
    }
    &:active {
      box-shadow: none;
      color: #5e548e;
      border-color: #cec28e;
    }
  }
`;
export default MlStyled;
