import React, { useEffect, useContext, useState } from 'react'
import QuestionsListStyle from '../../../../assets/css/components/admin/ml-questions/QuestionsListStyle'
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
import axios from 'axios';
import { GoPrimitiveDot } from 'react-icons/go';
import { Redirect } from 'react-router';
export default function InfoProduct(props) {
    const numberFormat = new Intl.NumberFormat('de-DE');
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const globalContext = useContext(GlobalContext);
    const { 
        showInfoProduct, refInfoProduct, data, 
        dataPrice, dataAmazon, pauseMl,
        activateMl
    } = props;
    const [brands, setBrands] = useState('');
    const [mlBrandName, setMlBrandName] = useState('');
    const [amazon, setAmazon] = useState(null);
    const [precios, setPrecios] = useState(null);
    const [asinVariante, setAsinVariante] = useState(0);
    const [redirectTo, setRedirectTo] = useState(null);
    const [idActual, setIdActual] = useState(null)
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/ml-brand/${entId}`,
            {
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            }
        ).then((resp) => {
            const brands = resp.data.data.brands;
            setBrands(brands);
        }).catch((err) => {
            if (axios.isCancel(err)) return; //Don't show axios cancel error
            console.error(err, err.response);
        });
    }, [globalContext.currentEnterpriseId]);

    useEffect(() => {
        let name = "";
        if (data) {
            if (brands.length > 0) {
                const brand = brands.filter((b) => b.id === data.attrib.official_store_id);
                if (brand.length > 0) {
                    name = brand[0].name;
                }
            }
        }

        setMlBrandName(name);
    }, [brands, data]);

    useEffect(() => {
        if(dataAmazon) {
            setIdActual(dataAmazon._id)
            if(dataAmazon.variants.length > 0) {
                setAmazon(dataAmazon.variants[0]);
                setAsinVariante(dataAmazon.variants[0].asin)
            }else {
                setAmazon(dataAmazon);
            }
        }
    }, [dataAmazon]);

    useEffect(() => {
        if(dataPrice) {
            setPrecios(dataPrice)
        }
    }, [dataPrice]);

    //* metodo para cambiar el valor de las cosas segun la variante
    const setValuesByShow = (index) => {
        setAmazon(
            dataAmazon.variants[index]
        );
        setAsinVariante(dataAmazon.variants[index].asin)
    }

    const goToAmazon = (url) => {
        return window.open(url, '_blank');
    }

    //redirec to detail
    const redirectDetailt = () => {
        setRedirectTo(
            <Redirect to={`/admin/productos/detail-kbt/${idActual}`} />
        )
    }

    return (
        <QuestionsListStyle.contInfoProduct activeList={showInfoProduct} ref={refInfoProduct}>
            {redirectTo}
            {
                data && (
                    <>
                        {
                            amazon ? (
                                <>
                                <QuestionsListStyle.textInfo otherCase={true}>
                                    <QuestionsListStyle.text fw="500" size="12pt" cl="#FFFFFF">
                                        <GoPrimitiveDot />
                                        Variantes
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>
                                <QuestionsListStyle.contVariants>
                                    {
                                        (dataAmazon) &&
                                            (dataAmazon.variants.length > 0) &&
                                            dataAmazon.variants.map((v, index) => {
                                                return (
                                                    <QuestionsListStyle.variantImg key={index} active={v.asin === asinVariante} img={v.images[0]} onClick={() => setValuesByShow(index)} />
                                                )

                                            })
                                    }
                                </QuestionsListStyle.contVariants>

                                <QuestionsListStyle.textInfo otherCase={true}>
                                    <QuestionsListStyle.text fw="700" size="14pt" cl="#FFFFFF">
                                        <GoPrimitiveDot />
                                        Datos de Amazon
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>

                                <QuestionsListStyle.textInfo>
                                    <QuestionsListStyle.text fw="500" size="14pt" cl="#6e6b7b">
                                        {amazon.title.es || amazon.title.en}
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>

                                <QuestionsListStyle.conImgproduct img={amazon.images[0]} />

                                <QuestionsListStyle.textInfo onClick={() => goToAmazon(amazon.url)} style={{cursor: 'pointer'}}>
                                    <QuestionsListStyle.text fw="500" size="12pt" cl="#7367f0">
                                        Ir a Amazon
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>
                                <QuestionsListStyle.textInfo onClick={() => redirectDetailt()} style={{cursor: 'pointer'}}>
                                    <QuestionsListStyle.text size="12pt" cl="#6e6b7b">
                                        {`SKU Amazon: ${amazon.asin}`}
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>

                                <QuestionsListStyle.textInfo>
                                    <QuestionsListStyle.text size="12pt" cl="#6e6b7b">
                                        {`Precio de Proveedor: ${amazon.price ? `$${numberFormat.format(amazon.price)}` : 'No disponible'}`}
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>

                                <QuestionsListStyle.textInfo>
                                    <QuestionsListStyle.text size="12pt" cl="#6e6b7b">
                                        {`Precio Envio Provedor: ${amazon.shippingPrice ? `$${numberFormat.format(amazon.shippingPrice)}` : 'No disponible'}`}
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>

                                <QuestionsListStyle.textInfo>
                                    <QuestionsListStyle.text size="12pt" cl="#6e6b7b">
                                        {`Peso de Envio Proveedor (LBS): ${amazon.weight ? amazon.weight : 'No disponible'}`}
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>

                                <QuestionsListStyle.textInfo>
                                    <QuestionsListStyle.text size="12pt" cl="#6e6b7b">
                                        {`Dimensiones del Paquete (LBS): ${amazon.dimensions ? `A ${amazon.dimensions.height} L ${amazon.dimensions.width} LONG ${amazon.dimensions.length}` : 'No disponible'}`}
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>

                                <QuestionsListStyle.textInfo>
                                    <QuestionsListStyle.text size="12pt" cl="#6e6b7b">
                                        {`Peso Volumetrico (LBS): ${amazon.dimensions ? `${(((parseFloat(amazon.dimensions.height) * parseFloat(amazon.dimensions.width) * parseFloat(amazon.dimensions.length)) * 1.2) / 166).toString().slice(0, 5)} (LBS) ` : 'No disponble'}`}
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>

                                <QuestionsListStyle.textInfo>
                                    <QuestionsListStyle.text size="12pt" cl="#6e6b7b">
                                        Caracteristicas
                                    </QuestionsListStyle.text>
                                    {
                                        amazon.features &&
                                            amazon.features.map((f,index) =>
                                                {
                                                    return (
                                                        <QuestionsListStyle.text key={index} size="10pt" cl="#6e6b7b">
                                                            {`# ${f.es || f.en}`}
                                                        </QuestionsListStyle.text>
                                                    )
                                                }
                                        )}
                                </QuestionsListStyle.textInfo>

                                <QuestionsListStyle.textInfo>
                                    <QuestionsListStyle.text size="12pt" cl="#6e6b7b">
                                        Detalles:
                                    </QuestionsListStyle.text>
                                    {
                                        amazon.details.productDetails &&
                                            amazon.details.productDetails.map((r, index) =>
                                                {
                                                    return (
                                                        <QuestionsListStyle.text key={index} size="10pt" cl="#6e6b7b">
                                                            {`# ${r.name.es || r.name.en}: ${r.value.es || r.value.en}`}
                                                        </QuestionsListStyle.text>
                                                    )
                                                }
                                        )}
                                        
                                </QuestionsListStyle.textInfo>
                                </>
                            ) :
                            (
                                <QuestionsListStyle.textInfo otherCase={true}>
                                    <QuestionsListStyle.text fw="700" size="14pt" cl="#FFFFFF">
                                        <GoPrimitiveDot />
                                        Cargando Data de Amazon...
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>
                            )
                        }
                        {
                            precios ?
                            (
                                <>
                                <QuestionsListStyle.textInfo otherCase={true}>
                                    <QuestionsListStyle.text fw="700" size="14pt" cl="#FFFFFF">
                                        <GoPrimitiveDot />
                                        Detalles del Precio
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>

                                <QuestionsListStyle.textInfo>
                                    <QuestionsListStyle.text size="12pt" cl="#6e6b7b">
                                        {
                                            (!precios.variants) ?
                                            `Precio en (US): $ ${numberFormat.format(Math.ceil(precios.totalUsd))}`
                                            :
                                            precios.variants.map(v => {
                                                if (v.asin === asinVariante) {
                                                    return `Precio en (US): $ ${numberFormat.format(Math.ceil(v.totalUsd))}`
                                                }
                                            })
                                        }
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>

                                <QuestionsListStyle.textInfo>
                                    <QuestionsListStyle.text size="12pt" cl="#6e6b7b">
                                        {
                                            (!precios.variants) ?
                                            `Precio en (COL/BSF) ${numberFormat.format(Math.ceil(precios.total))}`
                                            :
                                            precios.variants.map(v => {
                                                if (v.asin === asinVariante) {
                                                    return `Precio en (COL/BSF) ${numberFormat.format(Math.ceil(v.total))}`
                                                }
                                            })
                                        }
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>

                                <QuestionsListStyle.textInfo>
                                    <QuestionsListStyle.text size="12pt" cl="#6e6b7b">
                                        {`Precio ML: $${data.price}`}
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>

                                <QuestionsListStyle.textInfo>
                                    <QuestionsListStyle.text size="12pt" cl="#6e6b7b">
                                        {
                                            (!precios.variants) ?
                                            `Monto Total de la Ganancia: $ ${numberFormat.format(precios.earningUsd)}`
                                            :
                                            precios.variants.map(v => {
                                                if (v.asin === asinVariante) {
                                                    return `Monto Total de la Ganancia: ${numberFormat.format(v.earningUsd)}`
                                                }
                                            })
                                        }
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>
                            </>
                            ) :
                            (
                                <QuestionsListStyle.textInfo otherCase={true}>
                                    <QuestionsListStyle.text fw="700" size="14pt" cl="#FFFFFF">
                                        Calculando Precios...
                                    </QuestionsListStyle.text>
                                </QuestionsListStyle.textInfo>
                            )
                        }

                        

                        <QuestionsListStyle.textInfo otherCase={true}>
                            <QuestionsListStyle.text fw="700" size="14pt" cl="#FFFFFF">
                                <GoPrimitiveDot />
                                Datos de ML
                            </QuestionsListStyle.text>
                        </QuestionsListStyle.textInfo>
                        <QuestionsListStyle.textInfo>
                            <QuestionsListStyle.text fw="500" size="14pt" cl="#6e6b7b">
                                {data.title}
                            </QuestionsListStyle.text>
                        </QuestionsListStyle.textInfo>
                        <QuestionsListStyle.textInfo>
                            <QuestionsListStyle.text size="12pt" color="#6e6b7b">
                                {`Tienda oficial: ${mlBrandName}`}
                            </QuestionsListStyle.text>
                        </QuestionsListStyle.textInfo>
                        <QuestionsListStyle.textInfo>
                            <QuestionsListStyle.text size="12pt" cl="#6e6b7b">
                                {`Stock: ${data.stock}`}
                            </QuestionsListStyle.text>
                        </QuestionsListStyle.textInfo>
                        <QuestionsListStyle.textInfo>
                            <QuestionsListStyle.text size="12pt" cl="#6e6b7b">
                                {`SKU: ${data.attrib.sku}`}
                            </QuestionsListStyle.text>
                        </QuestionsListStyle.textInfo>
                        <QuestionsListStyle.textInfo>
                            <QuestionsListStyle.text size="12pt" cl="#6e6b7b">
                                {`Status de la publicacion: ${data.attrib.status}`}
                            </QuestionsListStyle.text>
                        </QuestionsListStyle.textInfo>
                        <QuestionsListStyle.textInfo>
                            <QuestionsListStyle.text size="12pt" cl="#6e6b7b">
                                {`Codigo de Barra: ${data.attrib.barCode}`}
                            </QuestionsListStyle.text>
                        </QuestionsListStyle.textInfo>

                        {
                            data.attrib.status === 'active' ? (
                                <QuestionsListStyle.btnForPublication isPaused={true} onClick={() => pauseMl(data._id)}>
                                    Pausar publicacion
                                </QuestionsListStyle.btnForPublication>
                            ) 
                            :
                            (
                                <QuestionsListStyle.btnForPublication className="btn-procesar mt-2" onClick={() => activateMl(data._id)}>
                                    Activar publicacion
                                </QuestionsListStyle.btnForPublication>
                            )
                        }
                    </>
                )
            }
        </QuestionsListStyle.contInfoProduct>
    )
}
