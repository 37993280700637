import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useContext, useState, useRef } from 'react'
import GridStyles from '../../../../../assets/css/components/admin/products/Orders/GridStyles'
import { Spinner } from 'react-bootstrap';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames'
import axios from 'axios';
import { GlobalContext } from '../../../../../contexts/GlobalContext'
import te from 'date-fns/esm/locale/te/index.js';
export default function ChatClient(props) {
    const {idOrder, dataOrden} = props;
    const [dataChat, setDataChat] = useState('')
    const globalContext = useContext(GlobalContext);
    const [textSend, setTextSend] = useState('');
    const [idMsgActual, setIdMsgActual] = useState(null);
    const inputMsgSend = useRef(null);
    const [initialOffset, setInitialOffset] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const getOrderMsg = (entId, orderId, offset=0, limit =null, lastMsgId=null) => {
        const res = axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-message/${entId}/order/${orderId}`, {
            params: {
                offset: offset,
                limit: limit,
                lastMsgId: lastMsgId
            },
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            }
        })
        return res
    }

    const postOrderMsg = () => {
        const regex = /<div>/ig;
        document.getElementById("inputChat").style.color = 'rgba(112, 112, 112, 0.61)'
        let dataInputChat = document.getElementById("inputChat").innerHTML.toString();
        dataInputChat = dataInputChat.replaceAll(regex, '');
        dataInputChat = dataInputChat.replaceAll('</div>', '\n');
        dataInputChat = dataInputChat.replaceAll('<br>', '\n');
        const entId = globalContext.currentEnterpriseId;
        console.log('sera', dataInputChat);
        if (!entId) {
            return;
        }
        if (dataInputChat.length === 0) {
            return;
        }
        setIsSending(true)
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-message/${entId}/order/${idOrder}`,
        {
            text: dataInputChat
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => {
            //console.log(resp.data);
            document.getElementById("inputChat").innerHTML = ''

        }).catch(err => {
            handleAxiosError(err)
            document.getElementById("inputChat").innerHTML =''
        }).finally(() => {
            setIsSending(false);
        });
    }

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if (!idOrder) return;
        getOrderMsg(entId, idOrder)
        .then(resp => {
            const data = resp.data.data.messages;
            //console.log('longitud de la original',data.length);
            setDataChat(data);
            setIdMsgActual(data[0].id);
            
            //setInitialOffset(data.length);
            // console.log('el offset',initialOffset);
        }).catch(err => handleAxiosError(err))
        .finally(() => {
        })
    }, [globalContext.currentEnterpriseId, idOrder]);

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if(!entId) return;
        if(!idOrder) return;
        if(dataChat.length === 0) return;
        if(!idMsgActual)return;
        let consulta = false;
        const interval = setInterval(() => {
            if(consulta)return;
            consulta = true;
            let initialOff = dataChat.length;
            let limit
            getOrderMsg(entId, idOrder, 0, null, idMsgActual)
            .then(resp => {
                const data = resp.data.data.messages.reverse();
                //console.log('data new', data)
                //const copyChat = dataChat.filter((v) => v.id === data.id);
                //console.log('lo que trae el set interval cuando consigue algo', data.slice(initialOff));
                //const dataToPaste = data.slice(initialOff);
               if(data.length > 0) {
                setIdMsgActual(data[0].id)
                let copyData = [...dataChat.reverse(), ...data];
                setDataChat(copyData.reverse());
               }

                
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                consulta = false;
            });
        }, 2000);
        return () => clearInterval(interval);

    }, [globalContext.currentEnterpriseId, idOrder, dataChat, initialOffset, idMsgActual]);

    return (
        <GridStyles.chatCont>
            <GridStyles.chatHeader>
                <GridStyles.chatContAvatar>
                {dataOrden && dataOrden.buyerNickname.charAt(0)}
                </GridStyles.chatContAvatar>
                <GridStyles.text size="12pt" cl="#7367F0" ml="10px" mr="auto" fw="500" mt="0" mb="0">
                    {dataOrden && dataOrden.buyerNickname}
                </GridStyles.text>
            </GridStyles.chatHeader>
            <GridStyles.chactContBody>
                {
                    dataChat.length > 0 &&
                    dataChat.map((msg, i) => {
                        return (
                                <GridStyles.contMsgChat key={i} courier={msg.from.isSeller ? '1' : '2' }>
                                <GridStyles.contMsgChatSend courier={msg.from.isSeller ? '1' : '2' }>
                                    {
                                        msg.text
                                    }
                                </GridStyles.contMsgChatSend>
                                </GridStyles.contMsgChat>
                        )
                    })
                }
            </GridStyles.chactContBody>
            <GridStyles.chatFooter>
                <GridStyles.inputByChat  id="inputChat" contentEditable="true" onChange={(e) => setTextSend(e.target.value)} useRef={inputMsgSend} />
                <GridStyles.btnSendMsg onClick={() => postOrderMsg()} disabled={isSending}>
                    {
                        isSending ? 
                        (
                            <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                    <span className="sr-only">Enviando...</span>
                            </Spinner>
                        ) :
                        (
                            <FontAwesomeIcon icon={faPaperPlane}/>
                        )
                    }
                </GridStyles.btnSendMsg>
            </GridStyles.chatFooter>
        </GridStyles.chatCont>
    )
}
