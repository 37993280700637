import React, { useState, useEffect, useContext } from 'react';
import CityCss from '../../../assets/css/components/admin/city/CityCss'
import Pager from '../../general/Pager'
import axios from "axios";
import { Spinner } from 'react-bootstrap';
import {
    keyUserId,
    keyAccessToken,
} from "../../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { Redirect } from "react-router-dom";
import verifyAccessRole from '../../../assets/js/verifyAccessRole';
import Routes from '../../../Routes';

export default function LinkCity() {
    const globalContext = useContext(GlobalContext);
    const pageItemCount = 15;
    const [totalCategories, setTotalCategories] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState("");
    const [isLoad, setIsLoad] = useState(true);
    const [cities, setCity] = useState([]);
    const [valueInputFilter, setValueInputFilter] = useState('');
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 57);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };
    useEffect(() => {
        setIsLoad(true);
        const entId = globalContext.currentEnterpriseId
        if (!entId) return; 
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo/odooCityList`,
        {
            entId: entId,
            pageNumber: currentPage,
            pageItemCount: 15,
            ...(filter.length > 0 &&  {searchVal: filter})
        },{
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
            .then((r) => {
                const data = r.data.data.cities;
                setCity(data);
                console.log(data)
                console.log(r.data.data.totalCities)
                setTotalCategories(r.data.data.totalCities) //asi si julio marico
                setIsLoad(false);
            })
            .catch((e) => { handleAxiosError(e, 'Presentamos un error al cargar las ciudades'); setIsLoad(false) })
            
        

    }, [currentPage, filter, globalContext.currentEnterpriseId]);

    const showModalFunc = (v) => {
        globalContext.setActiveModalLinkCity(true);
        globalContext.setCityText(v.name);
        globalContext.setCityId(v.odooId)
    }
    const setFilterData = (event) => {
        event.preventDefault();
        setCurrentPage(1);
        setFilter(valueInputFilter)
    }

    const createOdooCities = () => {
        globalContext.setLoaderPage(true)
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("Agregue la empresa'");
            return;
        }

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo/odooCity`, {
            enterpriseId: entId
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            }
        }
        ).then(resp => {
            globalContext.setLoaderPage(false)
            globalContext.showModalWithMsg('Ciudades descargadas reacargue la pagina');
        }
            ).catch(err => {
                handleAxiosError(err);
                globalContext.setLoaderPage(false)
            }
                );

    };

    return (
        <CityCss.cont>
            {redirect}
            <CityCss.text ml="10px" fw="600" size="15pt" style={{alignSelf: 'flex-start'}}>
                Vinculacion de ciudades
            </CityCss.text>
            <CityCss.constGrid>
                <CityCss.heaterGrid>
                    <form onSubmit={(e) => setFilterData(e)} style={{ width: '100%' }}>
                        <CityCss.inputSearch readOnly={isLoad} type="text" placeholder="Filtre por..." onChange={(e) => setValueInputFilter(e.target.value)} />
                    </form>
                    <CityCss.btnUpdate style={{marginLeft: 10, }} onClick={() => createOdooCities()}>
                        Descargar Ciudades
                    </CityCss.btnUpdate>
                    
                </CityCss.heaterGrid>
                    {
                        isLoad ? 
                        (
                            <div className={`load-screen ${isLoad ? 'active' : ''}`}>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                        ) : 
                        (
                            <CityCss.bodyGrid>
                                {
                                    cities.length > 0 &&
                                    cities.map((v) => {
                                        return (
                                            <CityCss.constDataGrid key={v._id}>
                                            <CityCss.constDataGridHeader>
                                            <CityCss.text cl="white" size="12pt" fw="500">
                                                Id de Odoo: {v.odooId}
                                            </CityCss.text>
                                            <CityCss.text cl="white" ml="10px" size="12pt">
                                                Estado: {v.stateName}
                                            </CityCss.text>
                                            </CityCss.constDataGridHeader>
                                            <CityCss.contGridData>
                                                <CityCss.dataGrid>
                                                    <p>Ciudad de Odoo</p>
                                                    <p>{v.name}</p>
                                                </CityCss.dataGrid>
                                                <CityCss.dataGrid>
                                                    <p>Ciudad de ML</p>
                                                    {v.ml && v.ml}
                                                </CityCss.dataGrid>
                                                <CityCss.dataGrid>
                                                    <p>Ciudad de LN</p>
                                                    {v.ln && v.ln}
                                                </CityCss.dataGrid>
                                                <CityCss.dataGrid>
                                                    <p>Ciudad de DF</p>
                                                    {v.df && v.df}
                                                </CityCss.dataGrid>
                                            </CityCss.contGridData>
                    
                                            <CityCss.constDataGridFooter>
                                                <CityCss.btnUpdate onClick={() => { showModalFunc(v) }}>
                                                    Vincular
                                                </CityCss.btnUpdate>
                                            </CityCss.constDataGridFooter>
                    
                                        </CityCss.constDataGrid>
                                        )
                                    })
                                }
                            </CityCss.bodyGrid>
                        )
                    }
                <CityCss.footerGrid>
                    <Pager
                        handleSetPage={setCurrentPage}
                        totalResults={totalCategories}
                        currentPage={currentPage}
                        resultsPerPage={pageItemCount} />
                </CityCss.footerGrid>
            </CityCss.constGrid>

        </CityCss.cont>
    )
}
