import React, { useState } from 'react'
import GridStyles from '../../../../../assets/css/components/admin/products/Orders/GridStyles'

export default function NavbarDetail({setSteps, isActiveItem}) {
    const funcSetStep = (v) => {
        setSteps(v)
    }
    return (
        <GridStyles.contNavbar>
            <GridStyles.navBarItems active={1 === isActiveItem} onClick={() => {funcSetStep(1)}}>
                DATOS DEL PRODUCTO
            </GridStyles.navBarItems>
            <GridStyles.navBarItems active={2 === isActiveItem} onClick={() => {funcSetStep(2)}}>
                DATOS DEL CLIENTE
            </GridStyles.navBarItems>
            <GridStyles.navBarItems active={3 === isActiveItem} onClick={() => {funcSetStep(3)}}>
                DIRECCION DE ENVIO
            </GridStyles.navBarItems>
            <GridStyles.navBarItems active={4 === isActiveItem} onClick={() => {funcSetStep(4)}}>
                DIRECCION DE FACTURACION
            </GridStyles.navBarItems>
            <GridStyles.navBarItems active={5 === isActiveItem} onClick={() => {funcSetStep(5)}}>
                NOVEDADES
            </GridStyles.navBarItems>
        </GridStyles.contNavbar>
    )
}
