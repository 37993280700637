import axios from "axios";
import {
  keyAccessToken,
  keyUserId,
} from "../../../../../assets/js/SessionStorageNames";
//...(warranty_type && { warranty_type: warranty_type }),
//...(warranty_value && { warranty_days: warranty_value })
const sendMlProd = (
  {
    productId,
    title,
    price,
    body,
    imagesPlus,
    brand,
    model,
    quantity,
    sku,
    width,
    height,
    length_prod,
    weight,
    technical_specifications,
    list_price,
    low_price,
    pausePublication,
  },
  entId,
  categoryMl,
  listingType,
  officialStoreId
) => {

  return axios.post(
    `${process.env.REACT_APP_BACKEND_URL_ANICAN}/v1/anicam/publish-one-product`,
    {
      dataProd: {
        productId,
        title,
        price: parseInt(price),
        body,
        condition: "new",
        imagesPlus,
        pausePublication,
        brand,
        model,
        quantity,
        sku,
        width,
        height,
        length_prod,
        weight,
        technical_specifications,
        list_price,
        low_price,
      },
      entId: entId,
      categoryMl: categoryMl,
      listingType: listingType,
      officialStoreId: officialStoreId,
    },
    {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
    }
  );
};

const postOneAnicamMl = async (
  entId,
  paramsToSend,
  categoryMl,
  listingType,
  officialStoreId
) => {
  let datagood = [];
  let databad = [];
  await new Promise(async (resolve) => {
    for (const prod of paramsToSend) {
        await sendMlProd(
          prod,
          entId,
          categoryMl,
          listingType,
          officialStoreId
        ).then(resp => {
          console.log('se hace el push good');
          datagood.push(resp.data);
        }).catch(error => {
          console.log('se hace el push error');
          databad.push(error);
        })
    }
    console.log('hacemos el resolve')
    resolve('');
  });
  console.log('****', datagood, databad)
  return { datagood, databad };
};

export default postOneAnicamMl;
