import { faBoxes, faEllipsisV, faExclamationTriangle, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react'
import MultiScraperStyles from '../../../../../assets/css/components/admin/products/amazon/MultiScraperStyles';
import OptionsProductsHistory from '../OptionsProductsHistory';
import { Spinner } from 'react-bootstrap';
import imgNoFound from "../../../../../assets/img/jpeg/img-no-found.jpg";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';

export default function CardComponentAli({
    product,
    i,
    changeToRow, 
    isDeleted, 
    inDeleteProcess, 
    openMenuOptionItem, 
    setOpenMenuOptionItem,
    findCategoryByicon,
    deleteAProduct,
    funcAdditem,
    addOneToPublishAndUpdate,
    addItemByDeleteWithOutErrorFunc,
    itemsAdds,
    itemsToUpdateAndPublish,
    setOpenMenuOptionItemFunc,
    addProductOdoo
}) {
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const numberFormat = new Intl.NumberFormat('de-DE');

    // const funcAdditem = (v,v2) => {
    //     addItem(v, v2)
    // }
    

    /// add new product on odoo
    // const addProductOdoo = async () => {
    //     const entId = globalContext.currentEnterpriseId;
    //     globalContext.setLoaderPage(true);
    //     await axios.post(
    //         `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}/product`, {
    //             product:{
    //                 name: product.variants.length === 0 ?
    //                 product.title ? product.title.es || product.title.en : 'NP' : 
    //                 product.variants[0].title.es || product.variants[0].title.en,
    //                 default_code: product.asin,
    //                 barcode: product.asin,
    //                 list_price: product.price,
    //                 weight : product.weight,
    //                 volume: (((parseFloat(product.dimensions.height) * parseFloat(product.dimensions.width) * parseFloat(product.dimensions.length)) * 1.2) / 166).toString().slice(0, 5),
    //             },

    //     }, {
    //         cancelToken: axiosCancelTokenSource.token,
    //         headers: {
    //             "x-api-key": process.env.REACT_APP_API_KEY,
    //             "x-access-token": localStorage.getItem(keyAccessToken),
    //             "x-user-id": localStorage.getItem(keyUserId),
    //         },
    //     }
    //     )
    //     .then((res) => {
    //         globalContext.setLoaderPage(false);
    //         alert('Producto creado con exito!...')
    //     })
    //     .catch((err) => {
    //         globalContext.setLoaderPage(false);
    //         console.log('respuesta de err odoo', err);
    //     })
    // }

    const resturnedAnImage = (product) => {
        if(product.images.length > 0) {
            return product.images[0];
            
        }
    }

    return (
        <MultiScraperStyles.cardsGrid key={product._id} changeToRow={changeToRow} isDeleted={isDeleted.includes(product._id)}>
            <MultiScraperStyles.screenLoader IsScrapingAgain={inDeleteProcess.includes(product._id)}>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </MultiScraperStyles.screenLoader>
            <MultiScraperStyles.contImgCard changeToRow={changeToRow}>
                <OptionsProductsHistory
                    active={i === openMenuOptionItem}
                    setActive={setOpenMenuOptionItem}
                    filterTo={findCategoryByicon}
                    catId={product.categoryId === 0 && product.categoryId }
                    deleteMethod={deleteAProduct}
                    idDeleteByProduct={product._id}
                    indexProducByDelete={i}
                />
                {
                    isDeleted.includes(product._id) ?
                        (
                            <div className="cont-dots">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="dot-one" style={{ color: "#ea5455" }} />
                            </div>
                        )
                        :
                        (product.variants.length  === 0) ?
                        !product.ml ?
                        (
                            <>
                                <input
                                    className="inp-cbx"
                                    style={{ display: 'none' }}
                                    id={`cbx${product._id}`}
                                    type="checkbox"
                                    onChange={() => { funcAdditem(product, product._id); addOneToPublishAndUpdate(product._id); addItemByDeleteWithOutErrorFunc(product._id,i) }}
                                    checked={itemsAdds.includes(product._id)}
                                />
                                <label className="cbx" htmlFor={`cbx${product._id}`}>
                                    <span>
                                        <svg width="12px" height="10px" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                    </span>
                                </label>
                            </>
                        ) :
                        (
                            <>
                                <input
                                    className="inp-cbx"
                                    style={{ display: 'none' }}
                                    id={`cbx${product._id}`}
                                    type="checkbox"
                                    onChange={() => { addOneToPublishAndUpdate(product._id) }} 
                                    checked={itemsToUpdateAndPublish.includes(product._id)}
                                />
                                <label className="cbx" htmlFor={`cbx${product._id}`}>
                                    <span>
                                        <svg width="12px" height="10px" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                    </span>
                                </label>
                            </>
                        )
                        :
                        !product.ml ?
                        (
                            <>
                                <input
                                    className="inp-cbx"
                                    style={{ display: 'none' }}
                                    id={`cbx${product._id}`}
                                    type="checkbox"
                                    onChange={() => { funcAdditem(product, product._id) }}
                                    checked={itemsAdds.includes(product._id)}
                                />
                                <label className="cbx" htmlFor={`cbx${product._id}`}>
                                    <span>
                                        <svg width="12px" height="10px" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                    </span>
                                </label>
                            </>
                        ) :
                        (
                            <>
                                <input
                                    className="inp-cbx"
                                    style={{ display: 'none' }}
                                    id={`cbx${product._id}`}
                                    type="checkbox"
                                    onChange={() => { addOneToPublishAndUpdate(product._id) }} 
                                    checked={itemsToUpdateAndPublish.includes(product._id)}
                                />
                                <label className="cbx" htmlFor={`cbx${product._id}`}>
                                    <span>
                                        <svg width="12px" height="10px" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                    </span>
                                </label>
                            </>
                        )
                }
                <MultiScraperStyles.imgCard changeToRow={changeToRow}
                    url={ resturnedAnImage(product) }
                />
                <div className="d-block text-center">
                    <div className="cont-dots">
                        <MultiScraperStyles.btnOptions onClick={() => setOpenMenuOptionItemFunc(i)}>
                            <FontAwesomeIcon icon={faEllipsisV} />
                        </MultiScraperStyles.btnOptions>
                    </div>
                    {
                        product.variants.length > 0 &&
                        (
                            <div className="cont-dots" style={{ marginBottom: 5, marginTop: 5 }}>
                                <FontAwesomeIcon icon={faBoxes} className="dot-one" style={{ color: "#ffcf56" }} />
                            </div>
                        )
                    }
                    {
                        product.variants.length > 0 ?
                        (product.variants[0].ml) &&
                            (
                                <div className="cont-dots" style={{ marginBottom: 5, marginTop: 5 }}>
                                    <FontAwesomeIcon icon={faThumbsUp} className="dot-one" style={{ color: "#2ec4b6" }} />
                                </div>
                            )
                            :
                        (product.ml) &&
                        (
                            <div className="cont-dots" style={{ marginBottom: 5, marginTop: 5 }}>
                                <FontAwesomeIcon icon={faThumbsUp} className="dot-one" style={{ color: "#2ec4b6" }} />
                            </div>
                        )
                    }
                </div>
            </MultiScraperStyles.contImgCard>
            <MultiScraperStyles.dataCardText changeToRow={changeToRow}>
                {!changeToRow && (
                    <div className="price">
                        {product.originalPrice ? `$${numberFormat.format(product.originalPrice.max)}` : `$${numberFormat.format(product.originalPrice.min)}`}
                    </div>
                )}
                <MultiScraperStyles.titleDataCardtext changeToRow={changeToRow}>
                    {
                        product.title ? product.title : ''
                    }
                </MultiScraperStyles.titleDataCardtext>
                <MultiScraperStyles.preciosCardText>
                    <div className="precio-oferta">
                        <p>Precio de oferta -</p>
                        <p>{product.originalPrice ? `$${numberFormat.format(product.originalPrice.max)}` : `$${numberFormat.format(product.originalPrice.min)}`}</p>
                    </div>
                    <div className="precio-old">
                        <p>Stock -</p>
                        <p>{`${numberFormat.format(product.totalAvailableQuantity)}`}</p>
                    </div>
                    <div className="precio-old">
                        <p>SKU -</p>
                        <p>{product.variants ? product.variants.prices.length > 0 ? product.variants.prices[0].skuId : 'NP': 'NP'}</p>
                    </div>
                </MultiScraperStyles.preciosCardText>
            </MultiScraperStyles.dataCardText>
            <MultiScraperStyles.contBtnCardActions changeToRow={changeToRow}>
                {changeToRow && (
                    <div className="price">
                        {product.originalPrice ? `$${numberFormat.format(product.originalPrice.max)}` : `$${numberFormat.format(product.originalPrice.min)}`}
                    </div>
                )}
                {
                    !isDeleted.includes(product._id) &&
                    product.variants.length > 0 ?
                    (!product.variants[0].ml) ?
                    (
                        <>
                            <MultiScraperStyles.btnActionsCard
                                onClick={() => addProductOdoo()}
                                brbl="5px" colorFont="#707070" bgColor="#F5F5F6"
                                changeToRow={changeToRow}
                            >
                                Crear en odoo
                            </MultiScraperStyles.btnActionsCard>
                            <MultiScraperStyles.btnActionsCard
                                brbr="5px"
                                colorFont="#ffffff"
                                bgColor="#7367F0"
                                onClick={() => { globalContext.setModalPublishProduct(true); globalContext.setModalPublishProductData(product.variants[0], product._id) }}
                                changeToRow={changeToRow}
                            >
                                Publicar
                            </MultiScraperStyles.btnActionsCard>
                        </>
                    ) :
                    (
                        <MultiScraperStyles.btnActionsCard
                                onClick={() => addProductOdoo()}
                                brbl="5px" colorFont="#707070" bgColor="#F5F5F6"
                                changeToRow={changeToRow}
                            >
                                Crear en odoo
                            </MultiScraperStyles.btnActionsCard>
                    )
                    : 
                    (!product.ml) ?
                    (
                        <>
                            <MultiScraperStyles.btnActionsCard
                                onClick={() => addProductOdoo()}
                                brbl="5px" colorFont="#707070" bgColor="#F5F5F6"
                                changeToRow={changeToRow}
                            >
                                Crear en odoo
                                </MultiScraperStyles.btnActionsCard>
                            <MultiScraperStyles.btnActionsCard
                                brbr="5px"
                                colorFont="#ffffff"
                                bgColor="#7367F0"
                                onClick={() => { globalContext.setModalPublishProduct(true); globalContext.setModalPublishProductData(product, product._id) }}
                                changeToRow={changeToRow}
                            >
                                Publicar
                            </MultiScraperStyles.btnActionsCard>
                        </>
                    )
                    :
                    (
                        <MultiScraperStyles.btnActionsCard
                                onClick={() => addProductOdoo()}
                                brbl="5px" colorFont="#707070" bgColor="#F5F5F6"
                                changeToRow={changeToRow}
                            >
                                Crear en odoo
                            </MultiScraperStyles.btnActionsCard>
                    )
                }
            </MultiScraperStyles.contBtnCardActions>
        </MultiScraperStyles.cardsGrid>
    )
}
