import React from 'react'
import ScrapingUrlStyle from '../../../../../../assets/css/components/admin/products/amazon/ScrapingUrlStyle'
import CustomProgressBar from '../../../../../general/CustomProgressBar';

export default function ListTableTbody(props) {
    const { data } = props;
    return (
        <ScrapingUrlStyle.TableResultTbody>
            {
                data.length > 0 &&
                data.map((v, i) => {
                    return (
                        <ScrapingUrlStyle.TableResultTr key={i}>
                            <ScrapingUrlStyle.TableResultTd
                                width="200px"
                                minWidth="120px"
                            >
                                {v.url}
                            </ScrapingUrlStyle.TableResultTd>
                            <ScrapingUrlStyle.TableResultTd
                                width="170px"
                                minWidth="120px"
                            >
                                {v.result}
                            </ScrapingUrlStyle.TableResultTd>
                            <ScrapingUrlStyle.TableResultTd
                                width="170px"
                                minWidth="120px"
                                backGroundColor="#F3F2F7"
                            >
                                {v.message}
                            </ScrapingUrlStyle.TableResultTd>
                            <ScrapingUrlStyle.TableResultTd
                                minWidth="20px"
                                width="20px"
                                textAlign="center"
                                verticalAlign="center"
                            >
                                <CustomProgressBar wLoader={`${v.statusProcess}%`} />
                            </ScrapingUrlStyle.TableResultTd>
                            <ScrapingUrlStyle.TableResultTd
                                minWidth="20px"
                                width="20px"
                                textAlign="center"
                                verticalAlign="center"
                            >
                                <ScrapingUrlStyle.BtnIcon
                                    mRight="auto"
                                    mLeft="auto"
                                    mTop="auto"
                                    mBottom="auto"
                                    bgColor="#7367F0"
                                    color="#FFFFFF"
                                >
                                    Visualizar
                                </ScrapingUrlStyle.BtnIcon>
                            </ScrapingUrlStyle.TableResultTd>
                        </ScrapingUrlStyle.TableResultTr>

                    )
                })
            }
        </ScrapingUrlStyle.TableResultTbody>
    )
}
