import React, { useState, useContext, useEffect } from "react";
import { Form, Col, Row, Container, Button } from "react-bootstrap";
import {
    keyUserId,
    keyAccessToken,
} from "../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../contexts/GlobalContext";
import axios from 'axios';
import { Redirect } from "react-router-dom";
import Routes from "../../Routes";
import verifyAccessRole from "../../assets/js/verifyAccessRole";

export default function TestAdminEnterprise() {
    const globalContext = useContext(GlobalContext);
    const [inputs, setInputs] = useState({});
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [redirect, setRedirect] = useState(null);

    // useEffect(() => {
    //     const hasAccess = verifyAccessRole(Routes, 62);
    //     if(!hasAccess) {
    //         setRedirect(
    //             <Redirect to={`/admin/dashboard`} />
    //         )
    //     }
    // },[]);

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const updateInputState = (e) => {
        const name = e.target.name;
        const newState = Object.assign({}, inputs);
    
        switch (e.target.type) {
          case "checkbox":
            newState[name] = e.target.checked;
            break;

        case "file":
            newState[name] = e.target.files[0];
            break;
    
          default:
            newState[name] = e.target.value;
            break;
        }
    
        setInputs(newState);
    };

    const getScrapedProducts = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/products`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            params: {
                pageNumber: 1,
                pageItemCount: 10,
                filter: {publishedOnMl:true}
            }
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const getMlCategories = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-category/${entId}`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            params: {
                pageNumber: 1,
                pageItemCount: 10,
                filter: { publishable: true, getAll: true }
            }
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const plublishProduct = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        if (!inputs.amazonProductId) {
            globalContext.showModalWithMsg("Llena el 'ID Producto'");
            return;
        }

        if (!inputs.mlCategoryId) {
            globalContext.showModalWithMsg("Llena el 'ID Categoria ML'");
            return;
        }

        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/products/${inputs.amazonProductId}`, {
            headers,
        }).then(resp => {
            const prod = resp.data.data.product;

            if (!prod) {
                globalContext.showModalWithMsg("No encontrado");
                return;
            }
            
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/publish-to-ml`, {
                productId: prod._id,
                title: (!prod.variants || prod.variants.length == 0) ?
                    (prod.title.es || prod.title.en) 
                    : (prod.variants[0].title.es || prod.variants[0].title.en),
                price: 4000000,
                body: (
                    (!prod.variants || prod.variants.length == 0) ?
                        (prod.desc.es || prod.desc.en) 
                        : (prod.variants[0].desc.es || prod.variants[0].desc.en)
                ) || "No tenia body",
                categoryMl: inputs.mlCategoryId,
                condition: 'new',
                images: (!prod.variants || prod.variants.length == 0) ?
                    (prod.images || prod.images) 
                    : (prod.variants[0].images || prod.variants[0].images),
                listingType: 'bronze'
            }, {headers}).then(resp => globalContext.showModalWithMsg("Publicado")).catch(err => handleAxiosError(err))
            
        }).catch(err => handleAxiosError(err));
    }

    const createPluginPublishFilter = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!inputs.pluginPublishFilterTitle) {
            globalContext.showModalWithMsg("Llena el 'Filtro titulo'");
            return;
        }

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-publish-filter/${entId}`, {
            countries: ['ve'],
            plugins: ['ml'],
            attr: 'title',
            regexp: inputs.pluginPublishFilterTitle,
            value: null,
            deny: true,
            message: `El titulo no puede contener "${inputs.pluginPublishFilterTitle}"`,
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            }
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    };

    const getAllFilters = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-publish-filter/${entId}`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            params: {
                pageNumber: 1,
                pageItemCount: 10,
            }
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const getFilter = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-publish-filter/${entId}/602ffe62c22dbb4cc2cc265a`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const updateFilter = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-publish-filter/${entId}/602ffe62c22dbb4cc2cc265a`, {
            regexp: 'asd',
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const delFilter = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-publish-filter/${entId}/602ffe62c22dbb4cc2cc265a`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const getDataToPublishFromSku = () => {
        const entId = globalContext.currentEnterpriseId;
        const skus = inputs.skusToPublish;

        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        } else if (!skus) {
            globalContext.showModalWithMsg("Los sku...");
            return;
        }

        const processCheckIntervalTime = 5000;
        let processModitorId = 0;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };
        
        // TODO Hacer la peticion
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/report-data-to-publish`, {
            skus: "B002MXTJ32, B07FKCHHPC",
        }, {
            headers,
        }).then((resp) => {
            const data = resp.data.data;

            if (data.monitor) {
                processModitorId = data.monitor;

                //If the process ID was received, start monitoring
                let waitingMonitorResponse = false;
                const interval = setInterval(() => {
                    if (!waitingMonitorResponse) {
                        waitingMonitorResponse = true;

                        axios
                            .get(
                                `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                                {
                                    cancelToken: axiosCancelTokenSource.token,
                                    headers,
                                }
                            )
                            .then((resp) => {
                                const data = resp.data.data;

                                if (data.advance< 100) {
                                    const advance = data.advance.toFixed(2);
                                    globalContext.setLoaderPage(true, `${advance}% (${data.data.currentProduct}/${data.data.totalProducts})`);
                                } else {
                                    //Download the file
                                    axios
                                        .get(
                                            `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${processModitorId}/download`,
                                            {
                                                cancelToken: axiosCancelTokenSource.token,
                                                headers,
                                                responseType: 'json',
                                            }
                                        ).then(respMonitorFile => {
                                            globalContext.setLoaderPage(false);
                                            const FileDownload = require("js-file-download");
                                            FileDownload(respMonitorFile.data.data, "Reporte.csv");
                                        }).catch(err => {
                                            handleAxiosError(
                                                err,
                                                "Disculpe, no se pudo culminar la generacion del reporte."
                                            );
                                            clearInterval(interval);
                                            globalContext.setLoaderPage(false);
                                        });
                                }
                            })
                            .catch((err) => {
                                handleAxiosError(
                                    err,
                                    "Disculpe, no se pudo culminar la descarga de los productos."
                                );
                                clearInterval(interval);
                                globalContext.setLoaderPage(false);
                            })
                            .finally(() => {
                                waitingMonitorResponse = false;
                            });
                    }
                }, processCheckIntervalTime);
            } else {
                globalContext.setLoaderPage(false);
            }
        }).catch(err => handleAxiosError(err));
    }

    const scrapAmazonCategoryNode = () => {
        const entId = globalContext.currentEnterpriseId;
        const cateId = inputs.categoryToScrap;
        const maxProdCount = inputs.numProdToScrapFromCategory;        

        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        } else if (!cateId) {
            globalContext.showModalWithMsg("La categoria...");
            return;
        } else if (!maxProdCount) {
            globalContext.showModalWithMsg("La cantidad de productos...");
            return;
        }

        const processCheckIntervalTime = 5000;
        let processModitorId = 0;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };
        
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/parse-category-products`, {
            headers,
            params: {
                cateId,
                maxProdCount
            }
        }).then((resp) => {
            globalContext.setLoaderPage(false);
            globalContext.showModalWithMsg("El proceso se esta ejecutando en segundo plano");
        }).catch(err => handleAxiosError(err));
    }

    const getAmazonScrapConfig = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const setAmazonScrapConfig = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
            config: {
                publishPriceCalculation: {
                    courierPlus: 3,
                    courierPlusIsPercent: false,
                    poundPrice: 7,
                    additionalPoundPrice: 1.8,
                    safe: 0.6,
                    safeIsPercent: true,
                    ccTax: 1.4,
                    ccTaxIsPercent: true,
                    tariff: [{
                        categoryId: "605cd12d2d447a4a18dc9ea3",
                        amount: 20,
                        isPercent: true,
                    }],
                    defaultTariff: 10,
                    defaultTariffIsPercent: true,
                    iva: 19,
                    ivaIsPercent: true,
                    earning: [{
                        maxAmount: 100,
                        amount: 20,
                        isPercent: true,
                    }, {
                        minAmount: 101,
                        maxAmount: 190,
                        amount: 17,
                        isPercent: true,
                    }, {
                        minAmount: 191,
                        amount: 13,
                        isPercent: true,
                    }],
                    defaultComission: 10,
                    defaultComissionIsPercent: true,
                },
                defaultMaxStock: 2,
                deliveryDaysPlus: 3,
                mlMaxImagesToUse: 3,
            }
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/general`, {
            config: {
                usdRate: 20000000,
            }
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const getDafitiConfig = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/df`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),    
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const setDafitiConfig = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/df`, {
            config: {
                version: '1.0',
                url: 'https://sellercenter-api.dafiti.com.co/?',
                format: 'JSON',
            }
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const setDafitiAcc = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/dafiti-acc/save`, {
            enterpriseId: entId,
            usuario: 'alfonso.r@212global.com',
            apiKey: 'c35b2f2e143d28dffdb83ac16514eec347f43212',
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const getPublishingPrice = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/calculate-publish-price`, {
            prodId: "605cff8fa870a265575d5b6b",
            config: {
                courierPlus: 3,
                courierPlusIsPercent: false,
                poundPrice: 7,
                additionalPoundPrice: 1.8,
                safe: 0.6,
                safeIsPercent: true,
                ccTax: 1.4,
                ccTaxIsPercent: true,
                tariff: [{
                    categoryId: "605cd12d2d447a4a18dc9ea3",
                    amount: 20,
                    isPercent: true,
                }],
                defaultTariff: 10,
                defaultTariffIsPercent: true,
                iva: 19,
                ivaIsPercent: true,
                earning: [{
                    maxAmount: 100,
                    amount: 20,
                    isPercent: true,
                }, {
                    minAmount: 101,
                    maxAmount: 190,
                    amount: 17,
                    isPercent: true,
                }, {
                    minAmount: 191,
                    amount: 13,
                    isPercent: true,
                }],
                defaultComission: 10,
                defaultComissionIsPercent: true,
                countryCode: entId === "60944575461add5b1957ef8b" ? 'VE' : 'CO'
            }
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const updatePriceByRate = () => {
        const entId = globalContext.currentEnterpriseId;

        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        const processCheckIntervalTime = 5000;
        let processModitorId = 0;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };
        
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/product-price-rate/${entId}/sync/amazonScrap`, {}, {
            headers,
        }).then((resp) => {
            const data = resp.data.data;

            if (data.monitor) {
                processModitorId = data.monitor;

                //If the process ID was received, start monitoring
                let waitingMonitorResponse = false;
                const interval = setInterval(() => {
                    if (!waitingMonitorResponse) {
                        waitingMonitorResponse = true;

                        axios
                            .get(
                                `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                                {
                                    cancelToken: axiosCancelTokenSource.token,
                                    headers,
                                }
                            )
                            .then((resp) => {
                                const data = resp.data.data;

                                if (data.advance< 100) {
                                    const advance = data.advance.toFixed(2);
                                    globalContext.setLoaderPage(true, `${advance}% (${data.data.currentProduct}/${data.data.totalProducts})`);
                                } else {
                                    clearInterval(interval);
                                    globalContext.setLoaderPage(false);
                                }
                            })
                            .catch((err) => {
                                handleAxiosError(
                                    err,
                                    "Disculpe, no se pudo culminar la descarga de los productos."
                                );
                                clearInterval(interval);
                                globalContext.setLoaderPage(false);
                            })
                            .finally(() => {
                                waitingMonitorResponse = false;
                            });
                    }
                }, processCheckIntervalTime);
            } else {
                globalContext.setLoaderPage(false);
            }
        }).catch(err => handleAxiosError(err));
    }

    const setPublishImage = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        } else if (!inputs.img) {
            globalContext.showModalWithMsg("La imagen...");
            return;
        }

        const formData = new FormData();
        formData.append('img', inputs.img);

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/set-publish-image`, formData, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
                'Content-Type': 'multipart/form-data',
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const pauseMl = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/pause/607dc76469f73126f5c3c214`, {}, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const activateMl = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/activate/607dc76469f73126f5c3c214`, {}, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const updateMl = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/607dc76469f73126f5c3c213`, {
            price: 3700000,
            stock: 10,
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const getOrders = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/order/${entId}/amazon-scrap/ml`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            params: {
                pageNumber: 1,
                pageItemCount: 10,
                filter:{orderId: "5ef56360dd28d42b68ae7b92"}
            }
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const setStatus = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/order/${entId}/amazon-scrap-status/5ef56360dd28d42b68ae7b92/status`, {
            status: 'bought',
            novelty: 'asddasdas d', //Este es opcional
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const setNovelty = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/order/${entId}/amazon-scrap-status/5ef56360dd28d42b68ae7b92/novelty`, {
            novelty: 'asddasdas d',
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const setTracking = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/order/${entId}/amazon-scrap-status/5ef56360dd28d42b68ae7b92`, {
            trackingNumber: 456535435,
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const getOrderMsg = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        const orderId = inputs.mlOrderId
        if (!orderId) {
            globalContext.showModalWithMsg("La orden...");
            return;
        }

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-message/${entId}/order/${orderId}`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }
    
    const postOrderMsg = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        const orderId = inputs.mlOrderId
        if (!orderId) {
            globalContext.showModalWithMsg("La orden...");
            return;
        }

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-message/${entId}/order/${orderId}`,{}, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const scrapProductsResultsUrl = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        const url = inputs.productsResultsUrl;
        if (!url) {
            globalContext.showModalWithMsg("La url...");
            return;
        }
        
        const maxProdCount = inputs.numProdToScrapFromResultsUrl;
        
        const processCheckIntervalTime = 5000;
        let processModitorId = 0;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };
        
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/parse-products-results-url`, {
            headers,
            params: {
                url,
                maxProdCount
            }
        }).then((resp) => {
            globalContext.setLoaderPage(false);
            globalContext.showModalWithMsg("El proceso se esta ejecutando en segundo plano");
        }).catch(err => handleAxiosError(err));
    }

    const getTemplate = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }
        globalContext.setLoaderPage(true);
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo/getCities/${entId}`, {
            params: {
                pageNumber: 1,
                pageItemCount: 1,
                filter: {
                    name: '',
                },
            },
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        .then(resp => {console.log(resp.data); globalContext.setLoaderPage(false);}).catch(err => {handleAxiosError(err); globalContext.setLoaderPage(false);});
    }

    const delAmazonProduct = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }
        const prodId = inputs.idProdToDel;
        if (!prodId) {
            globalContext.showModalWithMsg("El ID del producto...");
            return;
        }

        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/products`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            data: {
                //prodId: '607dbb89c3a2841e73407bc7',
                prodIds: ['607dbdd8c3a2841e73407bd0']
            }
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const getCurrencies = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-currency/${entId}/my-currencies`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const createOdooCities = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("Llena el 'Filtro titulo'");
            return;
        }

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo/odooCity`, {
            enterpriseId: entId
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            }
        }
        ).then(resp =>
            console.log(resp)
            ).catch(err => 
                handleAxiosError(err)
                );

    };

    const categoryLinkReport = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/category/${entId}/report/amazonScrap`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const getAvailableRoutes = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/rbac/available-routes`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const linkMlAmazon = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amaz-prod/fix-link-with-ml/${entId}`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const rescrapedHistory = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amaz-prod/rescraped-history/${entId}`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            params: {
                pageNumber: 1,
                pageItemCount: 10,
                filter: {
                    // cron: "scrapScrapedProductsWithKeepa" o "scrapScrapedProducts"
                }
            }
        }).then(resp => console.log(resp.data)).catch(err => handleAxiosError(err));
    }

    const syncOdooProduct = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }
        globalContext.setLoaderPage(true);
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}/sync/6371471632f12100919e75c1`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        .then(resp => {console.log(resp.data); globalContext.setLoaderPage(false);}).catch(err => {handleAxiosError(err); globalContext.setLoaderPage(false);});
    }

    const checkMlProduct = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        const mlIdToCheck = inputs.mlIdToCheck;
        if (!mlIdToCheck) {
            globalContext.showModalWithMsg("El ID del producto...");
            return;
        }
        
        globalContext.setLoaderPage(true);
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/from-ml/${mlIdToCheck}`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        .then(resp => {console.log(resp.data); globalContext.setLoaderPage(false);}).catch(err => {handleAxiosError(err); globalContext.setLoaderPage(false);});
    }

    const cbtToMarket = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        globalContext.setLoaderPage(true);
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/publish-cbt-to-market`, {
            countries: [{site:"MCO"}],
            products: ["63c0b2b8d4b75f60921d1243"],
            status: "paused",
        }, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        .then(resp => {console.log(resp.data); globalContext.setLoaderPage(false);}).catch(err => {handleAxiosError(err); globalContext.setLoaderPage(false);});
    }

    return (
        <Container>
            {redirect}
            <Form className="mt-3">
                <Row className="mt-1">
                    <Col md={3}>
                        <Button onClick={getScrapedProducts}>Listado Scraped Products</Button>
                        <br/><Button onClick={getMlCategories}>Categorias ML</Button>
                    </Col>

                    <Col md={3}>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="ID Producto"
                            name="amazonProductId"
                            value={inputs.amazonProductId || ""}
                            onChange={updateInputState}
                        />
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="ID Categoria ML"
                            name="mlCategoryId"
                            value={inputs.mlCategoryId || ""}
                            onChange={updateInputState}
                        />
                        <Button onClick={plublishProduct}>Publicar Producto</Button>
                    </Col>

                    <Col md={3}>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Filtro titulo"
                            name="pluginPublishFilterTitle"
                            value={inputs.pluginPublishFilterTitle || ""}
                            onChange={updateInputState}
                        />
                        <Button onClick={createPluginPublishFilter}>Crear FiltroPlugin</Button>
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col md={2}>
                        <Button onClick={getAllFilters}>Get All Filters</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={getFilter}>Get Filter</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={updateFilter}>Update Filter</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={delFilter}>Delete Filter</Button>
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col md={2}>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="SKUs"
                            name="skusToPublish"
                            value={inputs.skusToPublish || ""}
                            onChange={updateInputState}
                        />
                        <Button onClick={getDataToPublishFromSku}>Reporte</Button>
                    </Col>

                    <Col md={2}>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="ID categoria Amazon"
                            name="categoryToScrap"
                            value={inputs.categoryToScrap || ""}
                            onChange={updateInputState}
                        />
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Cantidad Productos"
                            name="numProdToScrapFromCategory"
                            value={inputs.numProdToScrapFromCategory || ""}
                            onChange={updateInputState}
                        />
                        <Button onClick={scrapAmazonCategoryNode}>Scrap</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={getAmazonScrapConfig}>Get Config Precio</Button>
                        <Button onClick={setAmazonScrapConfig}>Set Config Precio</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={getDafitiConfig}>Get Config Dafiti</Button>
                        <Button onClick={setDafitiConfig}>Set Config Dafiti</Button>
                        <Button onClick={setDafitiAcc}>Set Dafiti Acc</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={getPublishingPrice}>Get Publish Price</Button>
                    </Col>

                    <Col md={2}>
                        <Button onClick={updatePriceByRate}>Update Products Price By Rate</Button>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.File
                            name="img"
                            onChange={updateInputState}
                        />
                        <Button onClick={setPublishImage}>Set Img</Button>
                    </Col>
                    <Col md={2}>
                        <Button onClick={pauseMl}>Pausar ML</Button>
                        <Button onClick={activateMl}>Activar ML</Button>
                        <Button onClick={updateMl}>Actualizar ML</Button>
                    </Col>
                    <Col md={2}>
                        <Button onClick={getOrders}>Ordenes</Button>
                    </Col>
                    <Col md={2}>
                        <Button onClick={setStatus}>setOrderStatus</Button>
                        <Button onClick={setNovelty}>setOrderNovelty</Button>
                        <Button onClick={setTracking}>setTracking</Button>
                    </Col>
                </Row>

                <Row>
                    <Col md={2}>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Order ID"
                            name="mlOrderId"
                            value={inputs.mlOrderId || ""}
                            onChange={updateInputState}
                        />
                        <Button onClick={getOrderMsg}>getOrderMsg</Button>
                    </Col>
                    
                    <Col md={2}>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Order ID"
                            name="mlOrderId"
                            value={inputs.mlOrderId || ""}
                            onChange={updateInputState}
                        />
                        <Button onClick={postOrderMsg}>post orders</Button>
                    </Col>
                    
                    <Col md={6}>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Products Results Url"
                            name="productsResultsUrl"
                            value={inputs.productsResultsUrl || ""}
                            onChange={updateInputState}
                        />
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Num Products"
                            name="numProdToScrapFromResultsUrl"
                            value={inputs.numProdToScrapFromResultsUrl || ""}
                            onChange={updateInputState}
                        />
                        <Button onClick={scrapProductsResultsUrl}>Scrap</Button>
                    </Col>
                    
                    <Col md={2}>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="ID Prod Amazon"
                            name="idProdToDel"
                            value={inputs.idProdToDel || ""}
                            onChange={updateInputState}
                        />
                        <Button onClick={delAmazonProduct}>delAmazonProduct</Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <Button onClick={getCurrencies}>getCurrencies</Button>
                    </Col>
                    <Col md={2}>
                        <Button onClick={categoryLinkReport}>categoryLinkReport</Button>
                    </Col>
                    <Col md={2}>
                        <Button onClick={getAvailableRoutes}>getAvailableRoutes</Button>
                    </Col>
                    <Col md={2}>
                        <Button onClick={linkMlAmazon}>linkMlAmazon</Button>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-3">
                        <Button onClick={rescrapedHistory}>rescrapedHistory</Button>
                    </Col>
                    <Col md={2}>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="mlIdToCheck"
                            name="mlIdToCheck"
                            value={inputs.mlIdToCheck || ""}
                            onChange={updateInputState}
                        />
                        <Button onClick={checkMlProduct}>checkMlProduct</Button>
                    </Col>
                </Row>
            </Form>
            <Row>
                <Col>
                <Button onClick={() => getTemplate()}>
                    template
                </Button>
                </Col>
                <Col>
                    <Button onClick={() => syncOdooProduct()}>
                        syncOdooProduct
                    </Button>
                </Col>
                <Col>
                    <Button onClick={() => cbtToMarket()}>
                        cbtToMarket
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={() => createOdooCities()}> Ciudades </Button>
                </Col>
            </Row>
        </Container>
    );
}
