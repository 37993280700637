import React from 'react'
import HeadHeaderStyle from '../../../../../../assets/css/components/admin/dashboard/dashboard-head-header/HeaderStyle.css'
import { Row, Col } from 'react-bootstrap';
export default function HeadHeader() {
    return (
      <HeadHeaderStyle>
        <div className="head-content d-flex justify-content-between align-items-center">
          <div className="welcome-text">
            <p>Bienvenido a E-kommers</p>
          </div>
        </div>
      </HeadHeaderStyle>
    );
}
