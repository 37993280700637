import React, { useContext, useState } from 'react'
import ListOfProductsToSetParams from '../../../../../../assets/css/components/admin/marketplace-to-marketplace/products/ListOfProductsToSetParams'
import { GlobalContext } from '../../../../../../contexts/GlobalContext'

export default function ListProductsToSetParams({ sendToFormData }) {
    const globalContext = useContext(GlobalContext);
    const [isOn, setIsOn] = useState(0);

    return (
        <ListOfProductsToSetParams.ContListProd>
            <ListOfProductsToSetParams.ListProduts>
                {
                    globalContext.listProductsToSend.length > 0 &&
                    globalContext.listProductsToSend.map((v, i) => {
                        return (
                            <ListOfProductsToSetParams.ItemsListProduts
                                key={v._id}
                                active={isOn === i}
                                url={v.thumbnail}
                                load={false}
                                onClick={() => {
                                    sendToFormData(v, i);
                                    setIsOn(i)
                                }
                            }
                                >
                            </ListOfProductsToSetParams.ItemsListProduts>
                        )
                    })
                }
                
                
            </ListOfProductsToSetParams.ListProduts>
        </ListOfProductsToSetParams.ContListProd>
    )
}
