import React from 'react'
import ScrapingUrlStyle from '../../../../../../assets/css/components/admin/products/amazon/ScrapingUrlStyle'

export default function ListTableThead() {
    return (
        <ScrapingUrlStyle.TableResultThead>
            <tr>
                <ScrapingUrlStyle.TableResultTh
                    width="200px"
                    minWidth="120px"
                >
                    Url
                </ScrapingUrlStyle.TableResultTh>
                <ScrapingUrlStyle.TableResultTh
                    width="170px"
                    minWidth="120px"
                >
                    Resultado
                </ScrapingUrlStyle.TableResultTh>
                <ScrapingUrlStyle.TableResultTh
                    width="170px"
                    minWidth="120px"
                >
                    Mensaje de error
                </ScrapingUrlStyle.TableResultTh>
                <ScrapingUrlStyle.TableResultTh
                    minWidth="20px"
                    width="20px"
                    textAlign="center"
                >
                    Estatus
                </ScrapingUrlStyle.TableResultTh>
                <ScrapingUrlStyle.TableResultTh
                    minWidth="20px"
                    width="20px"
                    textAlign="center"
                >
                    Accion
                </ScrapingUrlStyle.TableResultTh>
            </tr>
        </ScrapingUrlStyle.TableResultThead>
    )
}
