import { faCheck, faHourglassStart, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import Tracking from '../../../../../assets/css/components/admin/products/Orders/Tracking'
import { userEmail } from '../../../../../assets/js/SessionStorageNames';
import { GlobalContext } from "../../../../../contexts/GlobalContext";
export default function ModalTracking() {
    const [porcentLine, setPorcentLine] = useState(65);
    const [actualPosition, setActualPosition] = useState(1);
    const [badStatus, setBadStatus] = useState('')
    const globalContext = useContext(GlobalContext);
    const statusTracking = globalContext.statusTracking;

    const renderStatusName = (status) => {
        switch (status) {
            case 'paid':
                return 'Pedido de venta';
            case 'confirmed':
                return 'Orden pagada';
            case 'bought':
                return 'Comprado';
            case 'bought_delayed':
                return 'Comprado en demora';
            case 'received_warehouse_int':
                return 'Recibido bodega miami';
            case 'transit_int':
                return 'En transito internacional';
            case 'customs_received':
                return 'Recibido aduana';
            case 'received_warehouse_local':
                return 'Recivido bodega venezuela';
            case 'transit_local':
                return 'Transito cliente';
            case 'delivered':
                return 'Producto entregado cliente';
            case 'lost':
                return 'Perdido';
            case 'canceled':
                return 'Orden cancelada';
            case 'defective':
                return 'Orden defectuosa';
            case 'return_provider':
                return 'Devolucion a provedor';
            case 'return_store':
                return 'Devolucion a tienda';
            case 'other':
                return 'Otros';
        }
    }


    const findBadStatus = (v) => {
        switch (v) {
            case 'delivered':
                return true;
            case 'lost':
                return true;
            case 'canceled':
                return true;
            case 'defective':
                return true;
            case 'return_provider':
                return true;
            case 'return_store':
                return true;
            case 'other':
                return true;
        }
    }

    const arrayStatus = [
        'paid',
        'confirmed',
        'bought',
        'bought_delayed',
        'received_warehouse_int',
        'transit_int',
        'customs_received',
        'received_warehouse_local',
        'transit_local'
    ]

    const badStatusArray = [
        'delivered',
        'lost',
        'canceled',
        'defective',
        'return_provider',
        'return_store',
        'other',
    ];
    //console.log(statusTracking);
    return (
        <Tracking.contModalTracking active={globalContext.openModalTracking}>
            <Tracking.modalTracking>
                <Tracking.headerModal>
                    <Tracking.btnCloseModal onClick={() => globalContext.setOpenModalTracking(false)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Tracking.btnCloseModal>
                    <Tracking.textHeader ml="10px" mr="auto" size="15pt">
                        Tracking del producto
                    </Tracking.textHeader>
                </Tracking.headerModal>
                <Tracking.bodyModal>
                    <div className="cont-tracking-nro">
                        {
                            (globalContext.nroTracking) &&
                            `Tracking Nro: ${globalContext.nroTracking}`
                        }
                    </div>
                    <Tracking.contDataTracking>
                    {
                        arrayStatus.map((v,i) => {
                            return (
                                <Tracking.dataTracking key={i}>
                                    <Tracking.contNroTracking active={statusTracking.find(s => s.status === v) }>
                                        {
                                            statusTracking.find(s => s.status === v) ? (
                                                <FontAwesomeIcon icon={faCheck} />
                                            ) :
                                            (
                                                <FontAwesomeIcon icon={faHourglassStart} />
                                            )
                                        }
                                    </Tracking.contNroTracking>
                                    <div className="cont-text-data-tracking">
                                        <Tracking.text color="#7367f0" size="15pt" ml="10px" fw="540">
                                            {renderStatusName(v)}
                                        </Tracking.text>
                                        <Tracking.text color="#7367f0" size="10pt" ml="10px">
                                            {
                                                statusTracking.map(s => {
                                                    if(s.status === v) {
                                                        return s.user ? `Actualizado por: ${s.user.name}` : ''
                                                    } 
                                                })
                                            }
                                        </Tracking.text>
                                    </div>
                                </Tracking.dataTracking>
                            )
                        })
                    }
                    

                    </Tracking.contDataTracking>
                </Tracking.bodyModal>
                <Tracking.footerModal>
                    <Tracking.btnActionFooter
                        bc="#7367F0"
                        bgc="#7367F0"
                        cl="#ffffff"
                    >
                        Guardar
                    </Tracking.btnActionFooter>
                </Tracking.footerModal>
            </Tracking.modalTracking>
        </Tracking.contModalTracking>
    )
}
