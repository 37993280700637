import React, { useEffect, useState, useContext } from "react";
import TableDashboardStyle from "../../../../assets/css/components/admin/enterprise/dashboard/TableDashboardStyle.css";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
/**
 * ? import filter and pagination
 */
import FilterEnterprise from "./FilterEnterprise";
import Pager from "../../../general/Pager";
import { Container, Row, Col } from "react-bootstrap";

export default function TableDashboard() {
  const globalContext = useContext(GlobalContext);
  const pageItemCount = 20;
  const axios = require("axios");
  const accessTokenKey = localStorage.getItem("accessTokenKey");
  const idUser = localStorage.getItem("_id");
  const [datos, setDatos] = useState([]);
  const [filter, setFilter] = useState("");
  const [totalEnterprises, setTotalEnterprises] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  /**
   * Updates the filter
   */
  const updateFilter = (filter) => {
    setFilter(filter);
    setCurrentPage(1);
  };
  /**
   * * delete enterprice
   */
  const deleteEnterprice = (id) => {
    let r = window.confirm("¿Desea eliminar esta empresa?");
    if (r === true) {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_BACKEND_URL}/v1/enterprise/${id}`,
        params: {
          pageNumber: currentPage,
          pageItemCount: pageItemCount,
          filter: { name: filter },
        },
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": accessTokenKey,
          "x-user-id": idUser,
        },
      })
        .then((res) => {
          globalContext.showModalWithMsg(
            "La empresa fue eliminada exitosamente"
          );
        })
        .catch((err) => {
          globalContext.showModalWithMsg(
            "Disculpe ha ocurrido un error al intentar eliminar la empresas"
          );
        });
    }
  };
  /**
   * Loads the enterprise list
   */
  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/enterprise/`,
      params: {
        pageNumber: currentPage,
        pageItemCount: pageItemCount,
        filter: { name: filter },
      },
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": accessTokenKey,
        "x-user-id": idUser,
      },
    })
      .then((res) => {
        setDatos(res.data.data.enterprises);
        setTotalEnterprises(res.data.data.totalEnterprises);
      })
      .catch((err) => {
        globalContext.showModalWithMsg(
          "Disculpe ha ocurrido un error al intentar cargar la lista de empresas"
        );
        if (err.response) {
          console.log(err.response.data);
        } else {
          console.log(err);
        }
      });
    //eslint-disable-next-line
  }, [filter, currentPage]);

  return (
    <TableDashboardStyle>
      <Container fluid>
        <Row className="mt-5">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="limiterHeader">
              <div className="container-header">
                <div className="ActionsContent">
                  <Row>
                    <Col
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      className="colHeader"
                    >
                      <Link to="/admin/enterprise/new-enterprise">
                        <button className="add-enterprise">Agg Empresa</button>
                      </Link>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={8}
                      lg={8}
                      xl={8}
                      className="colHeader"
                    >
                      <FilterEnterprise
                        filter={filter}
                        updateFilter={updateFilter}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="p-0">
            <div className="limiter">
              <div className="container-table100">
                <div className="wrap-table100 col-12 mt-5">
                  <div className="table100">
                    <table>
                      <thead>
                        <tr className="table100-head">
                          <th className="column1"> Nombre </th>
                          <th className="column2"> Telefono </th>
                          <th className="column3"> Responsable </th>
                          <th className="column4"> Direccion </th>
                          <th className="column5"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {datos.map((value) => (
                          <tr key={value._id}>
                            <td className="column1"> {value.name} </td>
                            <td className="column2"> {value.tlf} </td>
                            <td className="column3"> {value.responsable} </td>
                            <td className="column4"> {value.direccion} </td>
                            <td className="column5">
                              <div className="IconsActions justify-content-between">
                                <Link
                                  to={`/admin/enterprise/update-enterprise/${value._id}`}
                                >
                                  <button className="btn-action-update">
                                    <FontAwesomeIcon icon={faPen} />
                                  </button>
                                </Link>

                                <button
                                  className="btn-action-trash"
                                  onClick={() => {
                                    deleteEnterprice(value._id);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            xl={12}
            lg={12}
            className="d-flex justify-content-center mt-2"
          >
            <Pager
              handleSetPage={setCurrentPage}
              totalResults={totalEnterprises}
              currentPage={currentPage}
              resultsPerPage={pageItemCount}
            />
          </Col>
        </Row>
      </Container>
    </TableDashboardStyle>
  );
}
