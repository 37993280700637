import React, { useContext, useEffect, useState } from "react";
import UserConfigUserLockStyled from "../../../assets/css/components/admin/user-config/UserConfigLock.css";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import UserConfigUserFilter from "./UserConfigUserFilter";
import axios from "axios";
import {
    keyUserId,
    keyAccessToken,
} from "../../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../../contexts/GlobalContext";
import Pager from "../../general/Pager";
import { Redirect } from "react-router-dom";
import verifyAccessRole from "../../../assets/js/verifyAccessRole";
import Routes from "../../../Routes";

export default function UserBlockGrid() {
    const globalContext = useContext(GlobalContext);
    const pageItemCount = 50;
    const [redirect, setRedirect] = useState(null);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [blacklisted, setBlacklisted] = useState([]);
    const [blacklistedReload, setBlacklistedReload] = useState(0);

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 8);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (msg) globalContext.showModalWithMsg(msg);
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    /**
     * Updates the filter
     */
    const updateFilter = (filter) => {
        setFilter(filter);
        setCurrentPage(1);
    };

    /**
     * Get buyers
     */
    const loadBuyers = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        globalContext.setLoaderPage(true);

        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/ml-buyer/${entId}`,
                {
                    params: {
                        pageNumber: currentPage,
                        pageItemCount: pageItemCount,
                        filter,
                    },
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                    cancelToken: axiosCancelTokenSource.token,
                }
            )
            .then((resp) => {
                const data = resp.data.data;
                setTotalUsers(data.totalBuyers);
                setUsers(data.buyers.map(u => {
                    u.isBlacklisted = blacklisted.filter(b => b.id.toString() === u.id.toString()).length > 0 ? true : false;
                    return u;
                }));
            }).catch(err => handleAxiosError(err, "Disculpe, no se pudo cargar la lista de usuarios."))
            .finally(() => {
                globalContext.setLoaderPage(false);
            });
    };

    /**
     * Load buyers
     */
    useEffect(() => {
        if (currentPage === 1) {
            loadBuyers();
        } else {
            setCurrentPage(1);
        }
    }, [blacklisted, filter]);

    /**
     * Load the buyers list when the page number changes
     */
    useEffect(() => {
        loadBuyers();
    }, [currentPage]);

    /**
     * Load the blacklisted users
     */
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        globalContext.setLoaderPage(true);

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-buyer/${entId}/blacklist`, {
            params: {
                pageNumber: currentPage,
                pageItemCount: pageItemCount,
            },
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            cancelToken: axiosCancelTokenSource.token,
        }).then(resp => {
            const data = resp.data.data;
            setBlacklisted(data.blacklisted);
        }).catch(err => handleAxiosError(err, "Disculpe, no se pudo cargar la lista de usuarios bloqueados."))
            .finally(() => {
                globalContext.setLoaderPage(false);
            });
    }, [globalContext.currentEnterpriseId, blacklistedReload]);


    /**
     * Cancel axios requests before unmount
     */
    useEffect(() => {
        return () => {
            axiosCancelTokenSource.cancel("Canceled by unmount");
        };
        //eslint-disable-next-line
    }, []);

    /**
     * Blacklist an user
     * @param {string} userId The user id 
     */
    const blacklistUser = (userId) => {
        if (!window.confirm("¿Desea bloquear este usuario?")) return;

        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-buyer/${entId}/blacklist`, {
            userId,
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            cancelToken: axiosCancelTokenSource.token,
        }).then(resp => {
            //Reload the blacklisted list
            globalContext.setLoaderPage(true);
            setUsers([]);
            setTimeout(function () {
                setBlacklistedReload(blacklistedReload + 1);
            }, 2000);
        }).catch(err => handleAxiosError(err, "Disculpe, no se pudo bloquear el usuario."));;
    }

    /**
     * Remove an user from the blacklist
     * @param {string} userId The user id 
     */
    const whitelistUser = (userId) => {
        if (!window.confirm("¿Desea desbloquear este usuario?")) return;

        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-buyer/${entId}/blacklist`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            cancelToken: axiosCancelTokenSource.token,
            params: {
                userId,
            }
        }).then(resp => {
            //Reload the blacklisted list
            globalContext.setLoaderPage(true);
            setUsers([]);
            setTimeout(function () {
                setBlacklistedReload(blacklistedReload + 1);
            }, 2000);
        }).catch(err => handleAxiosError(err, "Disculpe, no se pudo desbloquear el usuario."));;
    }


    return (
        <UserConfigUserLockStyled>
            {redirect}
            <div className="UserConfigLockContainer">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-2 mt-3">
                            <div className="limiterHeader">
                                <div className="container-header">
                                    <div className="ActionsContent">
                                        <Row>
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                className="colHeader"
                                            >
                                                <UserConfigUserFilter
                                                    updateFilter={updateFilter}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="container-table100">
                                <div className="wrap-table100 col-12 mt-2">
                                    <div className="table100">
                                        <table>
                                            <thead>
                                                <tr className="table100-head">
                                                    <th className="column1">ID</th>
                                                    <th className="column2">Nombre</th>
                                                    <th className="column3">Status</th>
                                                    <th className="column4">Accion</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    users.map(u => {
                                                        return (
                                                            <tr key={u.id}>
                                                                <td className="column1">{u.id}</td>
                                                                <td className="column2">{
                                                                    u.nicknames.map(nick => {
                                                                        return <span key={nick}>{nick}<br/></span>;
                                                                    })
                                                                }</td>
                                                                <td className="column3">{
                                                                    u.isBlacklisted ? "Bloqueado" : "Desbloqueado"
                                                                }</td>
                                                                <td className="column4">
                                                                    <div className="IconsActions">
                                                                        {
                                                                            u.isBlacklisted ? (
                                                                                <button
                                                                                    className="btn-refresh"
                                                                                    title="Desbloquear"
                                                                                    onClick={() => whitelistUser(u.id.toString())}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faLockOpen} />
                                                                                </button>
                                                                            ) : (
                                                                                    <button
                                                                                        className="btn-refresh"
                                                                                        title="Bloquear"
                                                                                        onClick={() => blacklistUser(u.id.toString())}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faLock} />
                                                                                    </button>
                                                                                )
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        
                    </Row>
                   <Row>
                       <Col xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="mt-2 d-flex justify-content-center">
                       <Pager
                            handleSetPage={setCurrentPage}
                            totalResults={totalUsers}
                            currentPage={currentPage}
                            resultsPerPage={pageItemCount}
                            />
                       </Col>
                   </Row>
                </Container>
            </div>
        </UserConfigUserLockStyled>
    );
}
