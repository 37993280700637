import React, { useEffect, useState, useContext } from "react";
import RatesStylesDetail from "../../assets/css/components/admin/rates/RatesStylesDetail";
import { GlobalContext } from "../../contexts/GlobalContext";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import axios from 'axios';
import {
  keyUserId,
  keyAccessToken,
} from "../../assets/js/SessionStorageNames";
import Pager from "../general/Pager";
import Routes from "../../Routes";
import verifyAccessRole from "../../assets/js/verifyAccessRole";

const RatesDetail = ({ match }) => {
  const globalContext = useContext(GlobalContext);
  const pageItemCount = 20;

  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [redirect, setRedirect] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numRefresh, setNumRefresh] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [products, setProducts] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [pricelistName, setPricelistName] = useState("");
  const [plusPercent, setPlusPercent] = useState(null);

  useEffect(() => {
          const hasAccess = verifyAccessRole(Routes, 21);
          if(!hasAccess) {
              setRedirect(
                  <Redirect to={`/admin/dashboard`} />
              )
          }
      },[]);

  /**
   * Handle the axios error
   * @param {Error} err The thrown error
   * @param {string} msg A message to show in the modal
   * @param {boolean} consoleLog If should log the error in the console
   */
  const handleAxiosError = (err, msg = null, consoleLog = true) => {
    if (axios.isCancel(err)) return; //Don't show axios cancel error

    if (err.response && err.response.data) {
      if (err.response.data.message) {
        globalContext.showModalWithMsg(err.response.data.message);
      } else {
        globalContext.showModalWithMsg(err.response.data);
      }
    } else if (msg) {
      globalContext.showModalWithMsg(msg);
    }

    if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
  };

  /**
   * Load the campaign data
   */
  const loadCampaignData = () => {
    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;

    globalContext.setLoaderPage(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-campaign/${entId}/${match.params.campaignId}/products`, {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
        params: {
          pageNumber: currentPage,
          pageItemCount,
        }
      }).then(resp => {
        const data = resp.data.data;
        setCampaignName(data.campaign.name);
        setPricelistName(data.campaign.odooPricelistName);
        setPlusPercent(data.campaign.plusPercent);
        setTotalProducts(data.totalProducts);
        setProducts(data.products);
      })
      .catch((err) =>
        handleAxiosError(
          err,
          "Disculpe, no se pudo cargar la lista de productos de la campaña."
        )
      )
      .finally(() => {
        globalContext.setLoaderPage(false);
      });
  }

  /**
   * Load the data if currently is in the first page, else, set the currentPage to 1
   */
  useEffect(() => {
    if (currentPage === 1) {
      loadCampaignData();
    } else {
      setCurrentPage(1);
    }
  }, [globalContext.currentEnterpriseId]);

  /**
   * Load the data when the page number changes or a refresh is requested
   */
  useEffect(() => {
    loadCampaignData();
  }, [currentPage, numRefresh]);

  return (
    <RatesStylesDetail>
      {redirect}
      <div className="goBack d-flex justify-content-start mt-2">
        <button
          className="btn-back"
          onClick={() => setRedirect(<Redirect to="/admin/rates" />)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      </div>
      <Container fluid className="d-block">
        <div className="rate-description d-flex justify-content-around align-items-center">
          <div>
            <p style={{ color: "#5e548e", fontWeight: "bold" }}>Campaña</p>
            <p>{campaignName}</p>
          </div>
          <div>
            <p style={{ color: "#5e548e", fontWeight: "bold" }}>Tarifa</p>
            <p>{pricelistName}</p>
          </div>
          <div>
            <p style={{ color: "#5e548e", fontWeight: "bold" }}>%</p>
            <p>{plusPercent}</p>
          </div>
          <div>
            <button className="btn-action-trash">
              {" "}
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        </div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="p-0">
            <div className="limiter">
              <div className="container-table100">
                <div className="wrap-table100 col-12 mt-5">
                  <div className="table100">
                    <table>
                      <thead>
                        <tr className="table100-head">
                          <th className="column1"> Titulo </th>
                          <th className="column2"> Precio Original </th>
                          <th className="column3"> Precio Con Aumento </th>
                          <th className="column4">Precio de la Oferta</th>
                          <th className="column5">Estatus</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((p) => {
                          return (
                            <tr key={p.item_id}>
                              <td className="column1" style={{ color: "#5e548e" }}>
                                {p.title}
                              </td>
                              <td className="column2" style={{ color: "#5e548e" }}>
                                {p.current_price}
                              </td>
                              <td className="column3" style={{ color: "#5e548e" }}>
                                {p.regular_price}
                              </td>
                              <td className="column4" style={{ color: "#5e548e" }}>
                                {p.deal_price}
                              </td>
                              <td className="column5" style={{ color: "#5e548e" }}>
                                {p.status}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            xl={12}
            lg={12}
            className="d-flex justify-content-center mt-2"
          >
            <Pager
              handleSetPage={setCurrentPage}
              totalResults={totalProducts}
              currentPage={currentPage}
              resultsPerPage={pageItemCount}
            />
          </Col>
        </Row>
      </Container>
    </RatesStylesDetail>
  );
};

export default RatesDetail;
