import React from 'react'
import { ContItems, SelectPuglinToPostBtn } from '../../../../../assets/css/components/admin/marketplace-to-marketplace/modal-post-product/StepsStyle'

export default function SelectPluginToPost({currentPlugin, setCurrentPlugin, nextStep}) {

    const selectedpluginPost = (v) => {
        setCurrentPlugin(v)
        nextStep()
    }

    return (
        <ContItems>
            <SelectPuglinToPostBtn
                active={currentPlugin === 'ml'}
                onClick={() => {
                    selectedpluginPost('ml');
                }}
                >
                Mercado Libre
            </SelectPuglinToPostBtn>
            <SelectPuglinToPostBtn
                active={currentPlugin === 'py'}
                onClick={() => selectedpluginPost('py')}
            >
                Pedidos Ya
            </SelectPuglinToPostBtn>
        </ContItems>
    )
}
