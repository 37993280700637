import styled from "styled-components";

const SelectNavbarStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  .content-select-navbar {
    width: 100%;
  }
  select {
    box-shadow: none;
    font-family: "Roboto Slab", serif !important;
    outline: none;
    border: 0.5px solid #5e548e;
    transition: all ease 450ms;
    width: 100%;
    &:hover {
      box-shadow: none;
      color: #5e548e;
      border-color: #cec28e;
    }
    &:focus {
      box-shadow: none;
      color: #5e548e;
      border-color: #cec28e;
    }
    &:active {
      box-shadow: none;
      color: #5e548e;
      border-color: #cec28e;
    }
  }
`;
export default SelectNavbarStyle;
