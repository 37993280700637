import React from 'react'
import { Spinner } from 'react-bootstrap';
import GridStyles from '../../../../../assets/css/components/admin/products/Orders/GridStyles'
export default function DetailClient(props) {
    const { isLoader, dataOrder } = props;
    return (
        <GridStyles.stepTwo>
            {
                isLoader ?
                    (
                        <GridStyles.contLoader>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Cargando...</span>
                            </Spinner>
                        </GridStyles.contLoader>
                    )
                    :
                    (
                        <>
                            <GridStyles.stepTwoHeader>
                                <GridStyles.text fw="500" size="15pt" ml="10px">
                                    Datos del cliente
                                </GridStyles.text>
                            </GridStyles.stepTwoHeader>
                            <GridStyles.stepTwoBody>
                                <GridStyles.textAreaCont>
                                    <GridStyles.contTwoText>
                                        <GridStyles.text mt="0" mb="0">
                                            NOMBRE-
                                        </GridStyles.text>
                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                            {dataOrder.ml.buyerData ? (dataOrder.ml.buyerData.firstName || 'No posse') : 'No posee'}
                                        </GridStyles.text>
                                    </GridStyles.contTwoText>
                                    <GridStyles.contTwoText>
                                        <GridStyles.text mt="0" mb="0">
                                            APELLIDO-
                                        </GridStyles.text>
                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                            {dataOrder.ml.buyerData ? (dataOrder.ml.buyerData.lastName || 'No posse') : 'No posee'}
                                        </GridStyles.text>
                                    </GridStyles.contTwoText>
                                    <GridStyles.contTwoText>
                                        <GridStyles.text mt="0" mb="0">
                                            USUARIO-
                                        </GridStyles.text>
                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                        {dataOrder.ml.buyerData ? (dataOrder.ml.buyerNickname || 'No posse') : 'No posee'}
                                        </GridStyles.text>
                                    </GridStyles.contTwoText>
                                    <GridStyles.contTwoText>
                                        <GridStyles.text mt="0" mb="0">
                                            ID-
                                        </GridStyles.text>
                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                            {dataOrder.ml.buyerData ? (dataOrder.ml.buyerData.docNumber || 'No posee') : 'No posee'}
                                        </GridStyles.text>
                                    </GridStyles.contTwoText>
                                    <GridStyles.contTwoText>
                                        <GridStyles.text mt="0" mb="0">
                                            TLF-
                                    </GridStyles.text>
                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                        {dataOrder.ml.buyerData ? (dataOrder.ml.buyerData.phone || 'No posee') : 'No posee'}
                                    </GridStyles.text>
                                    </GridStyles.contTwoText>
                                    <GridStyles.contTwoText>
                                        <GridStyles.text mt="0" mb="0">
                                            E-MAIL-
                                    </GridStyles.text>
                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                            {dataOrder.ml.buyerData ? (dataOrder.ml.buyerData.email || 'No posse') : 'No posee'}
                                    </GridStyles.text>
                                    </GridStyles.contTwoText>
                                    <GridStyles.contTwoText>
                                        <GridStyles.text mt="0" mb="0">
                                            País-
                                    </GridStyles.text>
                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                        {dataOrder.ml.buyerData ? (dataOrder.ml.buyerData.country || 'No posee') : 'No posee'}
                                    </GridStyles.text>
                                    </GridStyles.contTwoText>
                                    <GridStyles.contTwoText>
                                        <GridStyles.text mt="0" mb="0">
                                            ESTADO-
                                    </GridStyles.text>
                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                            {dataOrder.ml.buyerData ? (dataOrder.ml.buyerData.state || 'No posee') : 'No posee'}
                                    </GridStyles.text>
                                    </GridStyles.contTwoText>
                                    <GridStyles.contTwoText>
                                        <GridStyles.text mt="0" mb="0">
                                            CIUDAD-
                                    </GridStyles.text>
                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                            {dataOrder.ml.buyerData ? (dataOrder.ml.buyerData.city || 'No posee') : 'No posee'}
                                    </GridStyles.text>
                                    </GridStyles.contTwoText>
                                </GridStyles.textAreaCont>
                                <GridStyles.textAreaCont>
                                    <GridStyles.contTwoText>
                                        <GridStyles.text mt="0" mb="0">
                                            DIRECCION
                                    </GridStyles.text>
                                    </GridStyles.contTwoText>
                                </GridStyles.textAreaCont>
                                <GridStyles.textAreaCont style={{ padding: '10px' }}>
                                    <GridStyles.text mt="0" mb="0" fw="450" style={{ textAlign: 'justify' }}>
                                        {dataOrder.ml.buyerData ? (dataOrder.ml.buyerData.address || 'No posse') : 'No posee'}
                                    </GridStyles.text>
                                </GridStyles.textAreaCont>
                            </GridStyles.stepTwoBody>
                        </>
                    )
            }
        </GridStyles.stepTwo>
    )
}
