import React, {useContext, useEffect, useState} from "react";
import CardProductDetailStyle from "../../../../../assets/css/components/admin/products/CardProductDetail.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import ImgTest from "../../../../../assets/img/jpeg/img_test.jpg";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import axios from 'axios';
import { keyAccessToken, keyUserId } from "../../../../../assets/js/SessionStorageNames";
export default function CardProductDetail(props) {
  const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());

    /**
     * Cancel axios requests before unmount
     */
    useEffect(() => {
        return () => {
            axiosCancelTokenSource.cancel("Canceled by unmount");
        };
        //eslint-disable-next-line
    }, []);

  const sendProductToFtp = (productToLinkId) => {
    globalContext.setOpenAddProductFtpId(productToLinkId);
    globalContext.setOpenAddProductFtp(true);
  }
  const downloadImages = () => {
    console.log('props', props)
    const entId = globalContext.currentEnterpriseId;
    globalContext.setLoaderPage(true);
    const headers = {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
    };
      axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo-image/${entId}/download-images-product/${props.productToLinkId}`, {
              cancelToken: axiosCancelTokenSource.token,
              headers, 
          }).then((res) => {
            console.log(res);
            globalContext.setLoaderPage(false);
            globalContext.showModalWithMsg(
              `El proceso se estara ejecutando en segundo plano`
          );
          }).catch(error => {
            console.log(error);
            globalContext.showModalWithMsg(
              `${error}`
          );
          globalContext.setLoaderPage(false);
          })
  }

  const syncOdooProduct = () => {
    const entId = globalContext.currentEnterpriseId;
    if (!entId) {
        globalContext.showModalWithMsg("La empresa...");
        return;
    }
    globalContext.setLoaderPage(true);
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}/sync/${props.productToLinkId}`, {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    .then(resp => {
      console.log(resp.data);
      globalContext.setLoaderPage(false);
            globalContext.showModalWithMsg(
              `El proceso finalizo con exito`
          );
    }).catch(err => {globalContext.showModalWithMsg(
      err.message
  ); globalContext.setLoaderPage(false);});
}
  console.log('-----',props.thumbnail)
  return (
    <CardProductDetailStyle>
      <div className="CardBody  ">
        <Container>
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className=" d-flex p-2">
              <Image
                src={props.thumbnail || ImgTest}
                alt="img product"
                rounded
              />
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className=" align-middle text-left"
                >
                  <div className="info-produc">
                    <p className="text-lp">{props.pluginName} </p>
                    <h1 className="text-np">{props.title}</h1>
                    {props.mlBrandName && (
                      <>
                        <br />
                        <span>
                          <i>{props.mlBrandName}</i>
                        </span>
                      </>
                    )}
                    {props.variantDesc && (
                      <p className="text-lp">{props.variantDesc} </p>
                    )}
                    {props.barCode && (
                      <h2 className="text-pp">{props.barCode}</h2>
                    )}
                    <h2 className="text-pp">{props.price}</h2>
                    <p className="text-sp">{props.stock}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className=" d-flex justify-content-center"
                >
                  {
                    props.productToLinkId && (
                      <div className="button">
                        <button onClick={() => sendProductToFtp(props.productToLinkId)}>
                          Agregar para FTP
                        </button>
                      </div>
                    )
                  }
                  <div className="button">
                      <button onClick={() => {syncOdooProduct()}}>
                        Descargar datos
                      </button>
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className=" d-flex justify-content-center"
                >
                  <div className="button">
                    <button onClick={() => downloadImages()}>
                      Descargar Imagenes faltantes
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </CardProductDetailStyle>
  )
}

