import React, { useState, useContext, useEffect } from 'react'
import { BtnClose, ContLabelStep, ContStep, BtnGeneral } from '../../../../../../assets/css/components/admin/marketplace-to-marketplace/modal-post-product/ModalContStyles';
import {  BiX } from 'react-icons/bi'
import { Text } from '../../../../../../assets/css/components/admin/marketplace-to-marketplace/modals/ModalSelectStoreAndSection';
import StepTwo from './modal-post-product/StepTwo';
import { GlobalContext } from '../../../../../../contexts/GlobalContext';
import StepThree from './modal-post-product/StepThree';
import SendData from './modal-post-product/SendData';
import axios from 'axios';
import Success from './modal-post-product/Success';
import postProducts from '../../functions/postProducts';
import PedidosYaStyle from '../../../../../../assets/css/components/admin/plugins/PedidosYaStyle';
import { Spinner } from 'react-bootstrap';
export default function LayoutModalPy({currentStep, setCurrentStep, defaultPlugin}) {
    const globalContext = useContext(GlobalContext);
    const [restaurantId, setRestaurantId] = useState(null);
    const [sectionId, setSectionId] = useState(null);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [msgSuccess, setMsgSuccess] = useState('')
    const [isLoad, setIsLoad] = useState(false);

    

    const renderLabelStepPy = () => {
        switch (currentStep) {
            case 2:
                return 'Seleccione su restaurant o tienda';
            case 3:
                return 'Seleccione la categoria';
            case 4:
                return 'Verifique los datos y publique';
            default:
                break;
        }
    }

    useEffect(() => {
        if(!globalContext.modalPostProduct) {
            setCurrentStep(1)
            setRestaurantId(null)
            setSectionId(null)
        }
    }, [globalContext.modalPostProduct])

    const renderStep = () => {
        switch (currentStep) {

            case 2:
                return (
                    <StepTwo 
                    restaurantId={restaurantId}
                    setRestaurantId={setRestaurantId} 
                    activeModal={globalContext.modalPostProduct}
                    currentStep={currentStep}
                    />
                );
            case 3:
                return (
                    <StepThree 
                    sectionId={sectionId}
                    setSectionId={setSectionId}
                    activeModal={globalContext.modalPostProduct}
                    currentStep={currentStep}
                    />
                );
            case 4:
                return (
                    <SendData 
                    sectionId={sectionId}
                    restaurantId={restaurantId}
                    />
                );
            case 5:
                return (
                    <Success
                    info={msgSuccess}
                    />
                );
        
            default:
                break;
        }
    }

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    }

    const sendData = async () => {
        const entId = globalContext.currentEnterpriseId;
        setIsLoad(true)
        await postProducts(entId, globalContext.listProductsToSend, restaurantId, sectionId,  axiosCancelTokenSource, nextStep, setMsgSuccess);
        setIsLoad(false);
    }

    const renderBtnStep = () => {

        switch (currentStep) {

            case 2:
                return (
                    <div style={{width: 500, height: 'auto'}} className="d-flex justify-content-between align-items-center">
                        <BtnGeneral
                        bgc="#F3F2F7"
                        color="#979797"
                            onClick={() => {
                                setCurrentStep(currentStep - 1);
                                defaultPlugin()

                            }
                        }
                        >
                            Volver
                        </BtnGeneral>
                        <BtnGeneral
                            disabled={!restaurantId}
                            onClick={() => setCurrentStep(currentStep + 1)}
                        >
                            Siguiente
                        </BtnGeneral>
                    </div>
                );
            case 3:
                return (
                    <div style={{width: 500, height: 'auto'}} className="d-flex justify-content-between align-items-center">
                        <BtnGeneral
                        bgc="#F3F2F7"
                        color="#979797"
                            onClick={() => setCurrentStep(currentStep - 1)}
                        >
                            Volver
                        </BtnGeneral>
                        <BtnGeneral
                            onClick={() => setCurrentStep(currentStep + 1)}
                        >
                            Siguiente
                        </BtnGeneral>
                    </div>
                );
            case 4:
                return (
                    <div style={{width: 500, height: 'auto'}} className="d-flex justify-content-between align-items-center">
                        <BtnGeneral
                        bgc="#F3F2F7"
                        color="#979797"
                            onClick={() => setCurrentStep(currentStep - 1)}
                        >
                            Volver
                        </BtnGeneral>
                        <BtnGeneral
                            onClick={() => {sendData()}}
                        >
                            Publicar
                        </BtnGeneral>
                    </div>
                );
            case 5:
                return (
                    <div style={{width: '100%', height: 'auto'}} className="d-flex justify-content-center align-items-center">
                        <BtnGeneral
                        bgc="#F3F2F7"
                        color="#979797"
                        onClick={() => globalContext.setModalPostProduct(false)}
                        >
                            Cerrar
                        </BtnGeneral>
                    </div>
                );
            default:
                break;
        }
    }

    return (
        <>
            <div style={{width: '100%'}} className="d-flex justify-content-end align-items-center">
                <BtnClose onClick={() => globalContext.setModalPostProduct(false)}>
                    <BiX/>
                </BtnClose>
            </div>
            <ContLabelStep>
                <Text size="15pt" fw="500">
                    {renderLabelStepPy()}
                </Text>
            </ContLabelStep>
            <ContStep>
                {
                    renderStep()
                }
            </ContStep>
            <div style={{width: '100%', marginTop: 10, height: 'auto'}} className="d-flex justify-content-center align-items-center">
                {
                    isLoad ? 
                    (
                        <PedidosYaStyle.contLoader>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Cargando...</span>
                            </Spinner>
                        </PedidosYaStyle.contLoader>
                    ) :
                    renderBtnStep()
                    }
            </div>

        </>
    )
}
