import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState, useEffect, useContext} from 'react'
import SelectcategorieCss from '../../../../../assets/css/components/admin/products/post-product/steps/SelectcategorieCss'
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import Pager from '../../../../general/Pager';
import axios from 'axios';
import {Spinner} from 'react-bootstrap';
export default function SelectCategorie(props) {

    const {setCategoryAttri, nextStep, listOfLastParents, setListOfLastParents} = props;

    const [isLoader, setIsLoader] = useState(true);
    const [categorias, setCategorias] = useState(null);
    const [categoryData, setCategoryData] = useState(null);
    const [categoryParentId, setCategoryParentId] = useState(null);
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const entId = globalContext.currentEnterpriseId;

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
                } else {
                    globalContext.showModalWithMsg(err.response.data);
                }
                } else if (msg) {
                    globalContext.showModalWithMsg(msg);
                }
        
            if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
        };

    const categoryAttribs = (v) => {
        setCategoryAttri(v)
    }
    const setListOfLastParentsFunc = (v) => {
        setListOfLastParents(v)
    }

    const getCategories = (entId, categoryParentId) => {
        if(!entId) return;
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/linio-cat/getCat/`,
        {
            entId: entId,
            ...(categoryParentId && {catId : categoryParentId})
        },
        {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        }
        }
        ).then((resp) => {
            //console.log(resp)
            const data = resp.data.data;
            if(data.length === 0) {
                categoryAttribs(categoryData.categoryId);
                nextStep()
            }else {
                setCategorias(data);
                setIsLoader(false);
            }
        }).catch((res) => {
            setCategorias([]);
            setIsLoader(false);
        });
    }

    const goBack = () => {
        setIsLoader(true);
        if (listOfLastParents.length > 0) {
            const lastId = listOfLastParents.pop();
            console.log('last id',lastId);
            lastId.parent.length === 0 ? setCategoryParentId(null) : setCategoryParentId(lastId.parent);
            setListOfLastParentsFunc(listOfLastParents.filter(e => e.parent !== lastId.parent))
        }
    }

    const addParentCategorie = (v) => {
        setIsLoader(true);
        setCategoryParentId(v.categoryId);
        setListOfLastParentsFunc([...listOfLastParents, v]);
        setCategoryData(v);
    }

    useEffect(() => {
        getCategories(entId, categoryParentId)
        //console.log(listOfLastParents);
    }, [entId, categoryParentId]);
    return (
        <SelectcategorieCss.cont>
            <SelectcategorieCss.header>
                <SelectcategorieCss.text size="18pt">
                    Seleccione su categoria
                </SelectcategorieCss.text>
            </SelectcategorieCss.header>
            <SelectcategorieCss.body>
                <SelectcategorieCss.contRow>
                    {listOfLastParents.length > 0 && (
                        <SelectcategorieCss.buttonBack onClick={() => goBack()}>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </SelectcategorieCss.buttonBack>
                        )}
                    <SelectcategorieCss.input ml="10px" placeholder="Nombre de la categoria ...." />
                </SelectcategorieCss.contRow>
                <SelectcategorieCss.contRow pd="10px">
                    {listOfLastParents.length > 0 &&
                        listOfLastParents.map(v => {
                        return (
                                <SelectcategorieCss.text key={v._id} size="15pt" style={{color: "#7367F0"}}>
                                    {v.name} {" > "}
                                </SelectcategorieCss.text>
                        )
                        })
                    }
                </SelectcategorieCss.contRow>
                <SelectcategorieCss.contRow pd="10px" ov="auto">
                <SelectcategorieCss.contItemsCategories>
                {
                    isLoader ?
                    (<SelectcategorieCss.contLoader>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Cargando...</span>
                        </Spinner>
                    </SelectcategorieCss.contLoader>)
                    :
                    categorias && categorias.map(c => {
                        return (
                            <SelectcategorieCss.contItemCategorie key={c._id} onClick={() => addParentCategorie(c)}>
                                {c.name}
                            </SelectcategorieCss.contItemCategorie>
                        )
                    })
                }
                </SelectcategorieCss.contItemsCategories>
                </SelectcategorieCss.contRow>
            </SelectcategorieCss.body>
            <SelectcategorieCss.footer>
                
            </SelectcategorieCss.footer>
        </SelectcategorieCss.cont>
    )
}
