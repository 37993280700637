import React, { useState } from "react";
//* import steps components
import StepCero from "./stepCero";
import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import StepFour from "./stepFour";
//import StepThree from "./StepThree";
//import Success from "./Success";

export default function PrintSteps() {
  //* esta constante almacena el valor de los step
  const [step, setStep] = useState(0);

  /**
   * ! method next step
   */
  let nextStep = () => {
    setStep(step + 1);
  };
  /**
   * !method prev step
   */
  let befStep = () => {
    setStep(step - 1);
  };
  /**
   * 1 method restart step
   */
  let restStep = () => {
    setStep(1);
  };

  switch (step) {
    case 0:
      return <StepCero nextStep={nextStep} />;
    case 1:
      return <StepOne nextStep={nextStep} befStep={befStep} />;
    case 2:
      return <StepTwo nextStep={nextStep} befStep={befStep} />;
    case 3:
      return <StepThree nextStep={nextStep} befStep={befStep} />;
    case 4:
      return <StepFour restStep={restStep} befStep={befStep} />;
    default:
      return <StepCero nextStep={nextStep} />;
  }
}
