import React, { useEffect } from 'react';
import { faAngleLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PublishProduct from "../../../../assets/css/components/admin/products/amazon/ModalPublishProduct";
import SelectCategory from './steps/SelectCategory';
import ParametrosPublicacion from './steps/ParametrosPublicacion';
import { useState, useContext } from 'react';
import Succes from './steps/Succes';
import { GlobalContext } from "../../../../contexts/GlobalContext";
import { Spinner } from 'react-bootstrap';
import axios from "axios";
import {
    keyUserId,
    keyAccessToken,
} from "../../../../assets/js/SessionStorageNames";
function ModalPublishProduct() {
    const numberFormat = new Intl.NumberFormat('de-DE');
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const globalContext = useContext(GlobalContext);
    const [steps, setSteps] = useState(1);
    const [title, setTitle] = useState(null);
    const [tienda, setTienda] = useState(null);
    const [tiendaText, setTiendaText] = useState(null);
    const [peso, setPeso] = useState(null);
    const [precio, setPrecio] = useState(0);
    const [descripcion, setDescripcion] = useState(null);
    const [categoryMl, setCategoryMl] = useState(null);
    const [categoryMlText, setCategoryMlText] = useState(null);
    const [categoryAmazon, setCategoryAmazon] = useState(null);
    const [listingType, setListingType] = useState('');
    const [listingTypeText, setListingTypeText] = useState('');
    const [succesPublish, setSucces] = useState(false);
    const [succesPublishMsg, setSuccesMsg] = useState('');
    const [isError, setIsError] = useState(false);
    const [dataPrice, setDataPrice] = useState(null);
    //* id del producto a publicar
    const [idProduct, setIdProduct] = useState(null);
    const [dataTest, setDataTest] = useState([]);
    /**
     * states para la categoria que predice ml
     */
    const [treeCategoryPrediction, setTreeCategoryPrediction] = useState([])
    const [categoryMlPrediction, setCategoryMlPrediction] = useState(null);
    const [categoryMlTextPrediction, setCategoryMlTextPrediction] = useState(null);
    const [totalCateA, setTotalCateA] = useState(0);
    const [lastCategorie, setLastCategorie] = useState('');
    const [howToPost, setHowToPost] = useState('paused');
    /**
    * * state para el loader  
    */
    const [isLoad, setIsLoad] = useState(true);
    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const escFunction = (event) => {
        if (event.keyCode === 27) {
            closeModalPublish()
        }
    }

    const nextStep = () => {
        setSteps(steps + 1);
    }
    const beforeStep = () => {
        setSteps(steps - 1);
    }

    /**
     * 
     * @param {*} steps 
     * * este metodo imprime en pantalla el formulario dependiendo del step
     */
    const stepRender = (steps) => {
        switch (steps) {
            case 1:
                return <SelectCategory
                    setCategoryMl={setCategoryMl}
                    setCategoryMlText={setCategoryMlText}
                    nextStep={nextStep}
                />
            case 2:
                return <ParametrosPublicacion
                    setTitle={setTitle}
                    setPeso={setPeso}
                    setPrecio={setPrecio}
                    setDescripcion={setDescripcion}
                    title={title}
                    peso={peso}
                    precio={precio}
                    descripcion={descripcion}
                    setTienda={setTienda}
                    setListingType={setListingType}
                    setTiendaText={setTiendaText}
                    setListingTypeText={setListingTypeText}
                    tienda={tienda}
                    tiendaText={tiendaText}
                    listingType={listingType}
                    setHowToPost={setHowToPost}
                />
            case 3:
                return <Succes
                    title={title}
                    peso={peso}
                    precio={precio}
                    descripcion={descripcion}
                    categoryMl={categoryMl}
                    categoryMlText={categoryMlText}
                    tiendaText={tiendaText}
                    listingTypeText={listingTypeText}
                    dataTest={dataTest}
                />
            default:
                return <SelectCategory
                    setCategoryMl={setCategoryMl}
                    setCategoryMlText={setCategoryMlText}
                    nextStep={nextStep}
                />
        }
    }
    const titlePublication = (steps) => {
        switch (steps) {
            case 1:
                return 'Seleccione su categoria para ML'
            case 2:
                return 'Parametros para la publicacion'
            case 3:
                return 'Verifique sus datos'
            default:
                return 'Seleccione su categoria para ML'
        }
    }
    const getDescriptionProduct = (setDescripcion, objProduct = null) => {
        const entId = globalContext.currentEnterpriseId;
        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then(resp => {
            
            let dataReplace = resp.data.data.configData.descriptionTemplate;
            // * este objeto es el que remplazara la etiquetas
            console.log('q es?:',(typeof objProduct.desc !== 'undefined'), '***', objProduct)
            const mapObjToReplace = {
                titulo: (objProduct.title) && objProduct.title.es ? objProduct.title.es : objProduct.title.en ? objProduct.title.en : '' + '\n',
                titulo_en: (objProduct.title) && objProduct.title.en ? objProduct.title.en : '' + '\n',

                d_tecnicos: (objProduct.details) && objProduct.details.productDetails ?
                    objProduct.details.productDetails.length > 0 ?
                        objProduct.details.productDetails.map(v =>
                            '- ' + v.name.es || "" + " " + v.value.es || "" + '\n'
                        ) : '' : ''
                ,

                informacion_adicional: (objProduct.details) && objProduct.details.additionalInfo ?
                    objProduct.details.additionalInfo.length > 0 ?
                        objProduct.details.additionalInfo.technicalDetails ?
                            objProduct.details.additionalInfo.technicalDetails.length > 0 ?
                                objProduct.details.additionalInfo.technicalDetails.map(v =>
                                    '- ' + v.name.es || "" + " " + v.value.es || "" + '\n'
                                ) : '' : '' : '' : '',

                caracteristicas: (objProduct.features) && objProduct.features.length > 0 ?
                    objProduct.features.map(v =>
                        '- ' + v.es + '\n')
                    : '',

                especificaciones_producto: (objProduct.details) && objProduct.details.productSpecifications ?
                    objProduct.details.productSpecifications.length > 0 ?
                        objProduct.details.productSpecifications.map(v =>
                            '- ' + v.name.es || '' + " " + v.value.es || '' + '\n'
                        ) : "" : '',

                sku: objProduct.asin + '\n',
                descripcion: (typeof objProduct.desc !== 'undefined') ? objProduct.desc.es ? objProduct.desc.es : objProduct.desc.en ? objProduct.desc.en : '' : '',
                stock: objProduct.stock ? objProduct.stock : '',
                dias_entrega: objProduct.deliveryDays ? objProduct.deliveryDays : '',
                variations: objProduct.variations ? objProduct.variations.map((vari) => {
                    return `- ${vari.name.es || ''}: ${vari.value.es || ''} \n`
                }) : ''
            }
            /**
             * ? remplazamos el lugar de las etiqutas por sus valores
             */
            dataReplace = dataReplace.replace('@titulo', mapObjToReplace.titulo.toString());
            dataReplace = dataReplace.replace('@titulo_en_ingles', mapObjToReplace.titulo_en.toString());
            dataReplace = dataReplace.replace('@detalles_tecnicos', mapObjToReplace.d_tecnicos.toString().replace(',', ''));
            dataReplace = dataReplace.replace('@caracteristicas', mapObjToReplace.caracteristicas.toString().replace(',', ''));
            dataReplace = dataReplace.replace('@sku', mapObjToReplace.sku.toString());
            dataReplace = dataReplace.replace('@descripcion', mapObjToReplace.descripcion.toString().replace(',', ''));
            dataReplace = dataReplace.replace('@informacion_adicional', mapObjToReplace.informacion_adicional.toString().replace(',', ''));
            dataReplace = dataReplace.replace('@especificaciones_producto', mapObjToReplace.especificaciones_producto.toString().replace(',', ''));
            dataReplace = dataReplace.replace('@stock', mapObjToReplace.stock.toString());
            dataReplace = dataReplace.replace('@tiempo_envio', mapObjToReplace.dias_entrega.toString());
            dataReplace = dataReplace.replace('@variations', mapObjToReplace.variations.toString().replace(',', ''));
            
            setDescripcion(dataReplace)
        }).catch((err) =>
            handleAxiosError(
                err,
                "Ocurrio un Error al cargar la plantilla de publicacion"
            )
        );
    }
    const getValues = async (entId) => {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }

    const getPublishingPrice = async (prodId, obj, entId) => {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/calculate-publish-price`, {
            prodId: prodId,
            config: {
                tariff: obj.tariff,
                earning: obj.earning,
                courierPlus: parseInt(obj.courierPlus),
                courierPlusIsPercent: parseInt(obj.courierPlusIsPercent),
                poundPrice: parseInt(obj.poundPrice),
                additionalPoundPrice: parseInt(obj.additionalPoundPrice),
                safe: obj.safe,
                safeIsPercent: obj.safeIsPercent,
                ccTax: obj.ccTax,
                ccTaxIsPercent: obj.ccTaxIsPercent,
                defaultTariff: parseInt(obj.defaultTariff),
                defaultTariffIsPercent: obj.defaultTariffIsPercent,
                iva: parseInt(obj.iva),
                ivaIsPercent: true,
                defaultComission: obj.defaultComission,
                defaultComissionIsPercent: obj.defaultComissionIsPercent,
                countryCode: entId === "60944575461add5b1957ef8b" ? 'VE' : 'CO'
            }
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res
    }

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        /**
         * * se ejecutara la accion cuando la variable para mostrar la modal este activa
         */
        if (globalContext.modalPublishProduct) {

            if (globalContext.modalPublishProductData) {
                setIdProduct(globalContext.idModalProductModal)

                //setTitle(globalContext.modalPublishProductData.title.es.length > 0 ? globalContext.modalPublishProductData.title.es : globalContext.modalPublishProductData.title.en)

                setPeso(globalContext.modalPublishProductData.weight && globalContext.modalPublishProductData.weight / 16);
                /**
                 * ? llamamos el metodo para agg la descripcion de o la plantilla de publicacion
                 */
                getDescriptionProduct(setDescripcion, globalContext.modalPublishProductData);
                /**
                 * * verificamos el total de objetos en el array de  las categorias
                 */
                setTotalCateA(globalContext.modalPublishProductData.categoryTree.length);
                /**
                 * ? obtenemos losd atos del precio
                 */
                getValues(entId)
                    .then(async (res) => {
                        const dataParaCalcular = res.data.data.configData.publishPriceCalculation;
                        await getPublishingPrice(globalContext.idModalProductModal, dataParaCalcular, entId)
                            .then((res) => {
                                const data = res.data.data;
                                setDataPrice(data);
                            }).catch((e) => {
                                console.log(e);
                            })
                    }).catch((err) => {
                        console.log(err);
                    })

                /**
                 * ! si el total de objetos en el array de categoryTree es mayor a cero seteamos el el atributo de total categories para amazon
                 */
                totalCateA > 0 ?
                    setLastCategorie(globalContext.modalPublishProductData.categoryTree[totalCateA - 1].name ? globalContext.modalPublishProductData.categoryTree[totalCateA - 1].name.en : '')
                    :
                    setLastCategorie('No posee')
                /**
                 * ! validamos que el state de last categorie no este vacio
                 */
                lastCategorie.length > 0 && (
                    // * buscamos lacategoria de que predice MercadoLibre
                    axios.get(
                        `${process.env.REACT_APP_BACKEND_URL}/v1/ml-category/${entId}/predict-category`, {
                        cancelToken: axiosCancelTokenSource.token,
                        headers: {
                            "x-api-key": process.env.REACT_APP_API_KEY,
                            "x-access-token": localStorage.getItem(keyAccessToken),
                            "x-user-id": localStorage.getItem(keyUserId),
                        },
                        params: {
                            title: lastCategorie,
                        }
                    }
                    ).then(resp => {
                        setTreeCategoryPrediction(resp.data.data.cate.tree)
                        setCategoryMlPrediction(resp.data.data.cate._id)
                        setCategoryMlTextPrediction(resp.data.data.cate.name)
                        setIsLoad(false);
                    }).catch((err) => {
                        setCategoryMlTextPrediction('Error al obtener la categoria')
                        setIsLoad(false);
                        handleAxiosError(
                            err,
                            "Disculpe, no se pudo cargar la informacion."
                        );
                        globalContext.setLoaderPage(false);
                    })
                )
            }
        }
        /**
         * validamos que la data sea indistinta a null para asiganar la ultima categoria de amazon
         * a setCategoryAmazon
         */
        if (globalContext.modalPublishProductData !== null) {
            //console.log(globalContext.modalPublishProductData)
            if (globalContext.modalPublishProductData.categoryTree.length > 0) {
                let lastValue = globalContext.modalPublishProductData.categoryTree.length - 1;
                setCategoryAmazon(globalContext.modalPublishProductData.categoryTree[lastValue]._id);
            } else {
                setCategoryAmazon('Ocurrio un error al obtener la categoria de Amazon')
            }
        }

        if (!globalContext.modalPublishProduct) {
            setSteps(1);
            setTotalCateA(0);
            setCategoryMlPrediction(null);
            setTreeCategoryPrediction([])
            setCategoryMlTextPrediction(null);
            setIsLoad(true);
            globalContext.setModalPublishProduct(false)
            setLastCategorie('');
            setCategoryAmazon(null);
            setTitle(null);
            setPeso(null);
            setIsError(false);
            setSucces(false);
            globalContext.setModalPublishProductData(null, null)
        }

    }, [
        globalContext.modalPublishProductData,title,
        totalCateA, lastCategorie, categoryMl,
        globalContext.currentEnterpriseId,
        globalContext.modalPublishProduct,
        globalContext.idModalProductModal
    ])

    const closeModalPublish = () => {
        setIsError(false);
        setSucces(false)
        globalContext.setTestFiltres(false)
        setSteps(1);
        setTotalCateA(0);
        setCategoryMlPrediction(null);
        setTreeCategoryPrediction([]);
        setCategoryMlTextPrediction(null);
        setIsLoad(true);
        globalContext.setModalPublishProductData(null, null)
        setLastCategorie('');
        setCategoryAmazon(null);
        setTitle(null);
        setPeso(null);
        setPrecio(0);
        globalContext.setModalPublishProduct(false)
    }
    /**
     * * metodo para publicar el producto de ml
     */
    const publicOneProduct = (entId) => {
        const res = axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/publish-to-ml`, {
            productId: idProduct, //Id del producto del scraper
            ...((title && title.length > 0) && { title: title }), //si el titulo esta seteado se manda
            body: descripcion,
            categoryMl: categoryMl ? categoryMl : categoryMlPrediction, //Id de la categoria de ml
            condition: 'new',
            images: globalContext.modalPublishProductData.images,
            listingType: listingType,
            ...(tienda && { officialStoreId: tienda }),
            pausePublication: howToPost === 'active' ? false : true,
            ...( globalContext.testFiltres && { onlySimulate: true} )
        }, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        )
        return res;
    }
    const saveData = async () => {
        setIsLoad(true);
        const entId = globalContext.currentEnterpriseId;
        await publicOneProduct(entId)
            .then((res) => {
                const data = res.data;
                if (data.data) {
                    if(globalContext.testFiltres) {
                        console.log('lo q se envia ', data.data.dataToSend)
                        setDataTest(data.data);
                        setSucces(true);
                        setIsLoad(false);
                    } else {
                        if (data.data.errors.length > 0) {
                            setSuccesMsg({ message: data.data.errors.toString() })
                            setIsError(true);
                            setSucces(true);
                            setIsLoad(false);
                        } else {
                            setSuccesMsg('Publicacion Exitosa!');
                            setSucces(true);
                            setIsLoad(false);
                        }
                    }
                } else {
                    setSuccesMsg('Publicacion Exitosa!');
                    setSucces(true);
                    setIsLoad(false);
                }
            }).catch((err) => {
                setSuccesMsg(JSON.parse(err.request.response));
                setSucces(true)
                setIsError(true)
                setIsLoad(false);
            })
    }
    //* instaciamos la varable para mostrar el text d las categorias de amazon
    const amazonTextCategorie = (globalContext.modalPublishProductData) &&
        globalContext.modalPublishProductData.categoryTree.map((c, i) => {
            return (c.name) ?
                (c.name.es) ?
                    (i !== globalContext.modalPublishProductData.categoryTree.length - 1) ? c.name.es + ' > ' : c.name.es
                    :
                    (i !== globalContext.modalPublishProductData.categoryTree.length - 1) ? c.name.en + ' > ' : c.name.en
                :
                'No posee'
        }
        )

    return (
        <PublishProduct.ContModal d={globalContext.modalPublishProduct ? 'flex' : 'none'}>
            <PublishProduct.ModalProduct>
                <PublishProduct.headerModal>
                    <PublishProduct.btnCloseModal onClick={() => { closeModalPublish() }}>
                        <FontAwesomeIcon icon={faTimes} />
                    </PublishProduct.btnCloseModal>
                    <PublishProduct.textHeader size='15pt' ml='auto' mr='auto'>
                        {titlePublication(steps)}
                    </PublishProduct.textHeader>
                    <PublishProduct.textHeader size='12pt' ml='auto' mr='0'>
                        Paso {steps}
                    </PublishProduct.textHeader>
                </PublishProduct.headerModal>
                {
                    isLoad ? (
                        <PublishProduct.bodyModal>
                            <div className={`load-screen`}>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                        </PublishProduct.bodyModal>
                    ) : (
                        <PublishProduct.bodyModal>
                            {
                                succesPublish ? 
                                globalContext.testFiltres ?
                                (
                                    <>
                                        <div className='d-flex justify-content-start'>
                                            <PublishProduct.textBody size='12pt' fw="600" ml='10px'>
                                                TITULO -
                                            </PublishProduct.textBody>
                                            <PublishProduct.textBody cl='#707070' size='12pt' ml='3px' mr='10px' fw='600'>
                                                {dataTest.dataToSend.title}
                                            </PublishProduct.textBody>
                                        </div>
                                        <div className='' styled={{ width: 100 + '%', padding: ".5rem" }}>
                                            <PublishProduct.textBody mb='5px' fw='bolder' mt='5px' >
                                                DESCRIPCION
                                            </PublishProduct.textBody>
                                            <PublishProduct.textArea value={dataTest.dataToSend.description.plain_text} />
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <div className='text-center' style={{ width: '100%', textAlign: 'center' }}>
                                        <PublishProduct.textBody ml='10px' cl='#7367F0' size='18pt' fw='500' style={{ textAlign: 'left' }} >
                                            {isError ? succesPublishMsg.message : 'Publicacion Exitosa!'}
                                        </PublishProduct.textBody>
                                        {isError && (
                                            <PublishProduct.btnActionFooter bc='#7367f0' bgc='#7367f0' ml='5px' cl='#FFFFFF' onClick={() => { setIsError(false); setSucces(false) }}>
                                                Volver a intentar
                                            </PublishProduct.btnActionFooter>
                                        )
                                        }
                                    </div>
                                ) :
                                    (
                                        <>
                                            <div className='cont-text-body-modal'>
                                                <PublishProduct.textBody size='12pt' fw='600' style={{ textAlign: 'start' }}>
                                                    Amazon -
                                                    <a
                                                        style={{ color: "#707070" }}
                                                        href={globalContext.modalPublishProductData && globalContext.modalPublishProductData.url}
                                                        target="_blank" rel="noopener noreferrer">{globalContext.modalPublishProductData && globalContext.modalPublishProductData.url}
                                                    </a>
                                                </PublishProduct.textBody>
                                            </div>
                                            <div className='d-flex justify-content-start'>
                                                <PublishProduct.textBody size='12pt' fw="600" ml='10px'>
                                                    TITULO -
                                                </PublishProduct.textBody>
                                                <PublishProduct.textBody cl='#707070' size='12pt' ml='3px' mr='10px' fw='600'>
                                                    {globalContext.modalPublishProductData ? globalContext.modalPublishProductData.title.es : ''}
                                                </PublishProduct.textBody>
                                            </div>
                                            <div className='cont-text-body-modal'>
                                                <PublishProduct.textBody size='12pt' mr='3px'>
                                                    Imagenes del producto
                                                </PublishProduct.textBody>
                                            </div>
                                            <div className='cont-text-body-modal'>
                                                <PublishProduct.carouselImagesProduct>
                                                    {
                                                        globalContext.modalPublishProductData &&
                                                        globalContext.modalPublishProductData.images.map((v, i) => {
                                                            return (
                                                                <PublishProduct.carouselImageProducts img={v} key={i} alt={v} />
                                                            )
                                                        })
                                                    }
                                                </PublishProduct.carouselImagesProduct>
                                            </div>
                                            <div className='cont-text-body-modal'>
                                                <div className='d-flex justify-content-start'>
                                                    <PublishProduct.textBody size='12pt' mr='3px'>
                                                        SKU -
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody cl='#7367F0' size='12pt' ml='3px' mr='10px' fw='600'>
                                                        {globalContext.modalPublishProductData ? globalContext.modalPublishProductData.asin : ''}
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' ml='10px' mr='3' mlr='20px'>
                                                        USD PROVEDOR -
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody cl='#7367F0' size='12pt' ml='3px' mr='10px' fw='600'>
                                                        {globalContext.modalPublishProductData ? `$${numberFormat.format(globalContext.modalPublishProductData.price)}` : ''}
                                                    </PublishProduct.textBody>
                                                </div>
                                                <div className='d-flex justify-content-start'>
                                                    <PublishProduct.textBody size='12pt' ml='10px' mr='3px'>
                                                        PRECIO OFERTA PROVEDOR -
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' cl='#7367F0' ml='3px' mr='10px' fw='600'>
                                                        {globalContext.modalPublishProductData ? `$${numberFormat.format(globalContext.modalPublishProductData.dealPrice)}` : ''}
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' ml='10px' mr='3px' mlr='20px' >
                                                        PRECIO VIEJO PROVEDOR -
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody cl='#7367F0' size='12pt' ml='3px' fw='600'>
                                                        {globalContext.modalPublishProductData ? `$${numberFormat.format(globalContext.modalPublishProductData.oldPrice)}` : ''}
                                                    </PublishProduct.textBody>
                                                </div>
                                            </div>
                                            <div className={`cont-text-body-modal`}>
                                                <div className='d-flex justify-content-start'>
                                                    <PublishProduct.textBody size='12pt' mr='3px'>
                                                        TIEMPO DE ENVIO PROVEDOR-
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody cl='#7367F0' size='12pt' ml='3px' mr='10px' fw='600'>
                                                        {globalContext.modalPublishProductData ? globalContext.modalPublishProductData.deliveryDays ? `${globalContext.modalPublishProductData.deliveryDays} Dias` : 'No disponible' : 'No disponible'}
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' ml='10px' mr='3' mlr='20px'>
                                                        PESO VOLUMETRICO -
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody cl='#7367F0' size='12pt' ml='3px' mr='10px' fw='600'>
                                                        {
                                                            globalContext.modalPublishProductData ?
                                                                globalContext.modalPublishProductData.dimensions ?
                                                                    'Alto ' + globalContext.modalPublishProductData.dimensions.height + ' Largo ' + globalContext.modalPublishProductData.dimensions.width + ' Longitud ' + globalContext.modalPublishProductData.dimensions.length
                                                                    : 'No disponible' : 'NO disponible'}
                                                    </PublishProduct.textBody>
                                                </div>
                                                <div className='d-flex justify-content-start'>
                                                    <PublishProduct.textBody size='12pt' ml='10px' mr='3px'>
                                                        STOCK -
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' cl='#7367F0' ml='3px' mr='10px' fw='600'>
                                                        {globalContext.modalPublishProductData ? globalContext.modalPublishProductData.stock : ''}
                                                    </PublishProduct.textBody>
                                                </div>
                                            </div>
                                            <div className='cont-text-body-modal'>
                                                <div className='d-flex justify-content-start'>
                                                    <PublishProduct.textBody size='12pt' mr='3px'>
                                                        PRECIO EN (USD) -
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody cl='#7367F0' size='12pt' ml='3px' mr='10px' fw='600'>
                                                        {
                                                            dataPrice ?
                                                                (!dataPrice.variants) ?
                                                                    `$ ${numberFormat.format(Math.ceil(dataPrice.totalUsd))}`
                                                                    :
                                                                    `$ ${numberFormat.format(Math.ceil(dataPrice.variants[0].totalUsd))}`
                                                                : "Calculando ..."
                                                        }
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' ml='10px' mr='3' mlr='20px'>
                                                        PRECIO EN (COB/BSF) -
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody cl='#7367F0' size='12pt' ml='3px' mr='10px' fw='600'>
                                                        {
                                                            globalContext.modalPublishProductData &&
                                                                dataPrice ?
                                                                (!dataPrice.variants) ?
                                                                    `${numberFormat.format(Math.ceil(dataPrice.total))}`
                                                                    :
                                                                    `${numberFormat.format(Math.ceil(dataPrice.variants[0].total))}`
                                                                : "Calculando ..."
                                                        }
                                                    </PublishProduct.textBody>
                                                </div>
                                                <div className='d-flex justify-content-start'>
                                                    <PublishProduct.textBody size='12pt' ml='10px' mr='3px'>
                                                        MONTO TOTAL DE LA GANANCIA (USD)-
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' cl='#7367F0' ml='3px' mr='10px' fw='600'>
                                                        {
                                                            globalContext.modalPublishProductData &&
                                                                dataPrice ?
                                                                (!dataPrice.variants) ?
                                                                    `$ ${numberFormat.format(Math.ceil(dataPrice.earningUsd))}`
                                                                    :
                                                                    `$ ${numberFormat.format(Math.ceil(dataPrice.variants[0].earningUsd))}`
                                                                : "Calculando ..."
                                                        }
                                                    </PublishProduct.textBody>
                                                </div>
                                            </div>
                                            <div className='cont-text-body-modal'>
                                                <div className='d-flex justify-content-start'>
                                                    <PublishProduct.textBody size='12pt' ml='' mr='3px'>
                                                        MONTO TOTAL DE LA GANANCIA (%)-
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' cl='#7367F0' ml='3px' mr='10px' fw='600'>
                                                        {
                                                            globalContext.modalPublishProductData &&
                                                                dataPrice ?
                                                                (!dataPrice.variants) ?
                                                                    dataPrice.earningUsd > 0 ? `${Math.ceil((dataPrice.earningUsd / dataPrice.totalUsd) * 100)}%` : 0
                                                                    :
                                                                    dataPrice.variants[0].earningUsd > 0 ? `${Math.ceil((dataPrice.variants[0].earningUsd / dataPrice.variants[0].totalUsd) * 100)}%` : 0
                                                                : "Calculando ..."
                                                        }
                                                    </PublishProduct.textBody>
                                                </div>
                                            </div>
                                            <div className='text-left'>
                                                <PublishProduct.textBody size='12pt' ml='10px'>
                                                    Categoria de Amazon:
                                                </PublishProduct.textBody>
                                                <PublishProduct.textBody ml='10px' cl='#7367F0' size='12pt' fw='500'>
                                                    {amazonTextCategorie}
                                                </PublishProduct.textBody>
                                            </div>
                                            <div className='text-left'>
                                                <PublishProduct.textBody size='12pt' ml='10px' >
                                                    Categoria de ML por prediccion:
                                                </PublishProduct.textBody>
                                                <PublishProduct.textBody ml='10px' cl='#7367F0' size='12pt' fw='500' >
                                                    {
                                                        treeCategoryPrediction.length > 0 &&
                                                        treeCategoryPrediction.map((v, i) => {
                                                            return `${v.name} >`
                                                        })
                                                    }
                                                    {categoryMlTextPrediction}
                                                </PublishProduct.textBody>
                                                {categoryMlTextPrediction === 'Otros' && (
                                                    <PublishProduct.textBody ml='15px' mt='3px' cl='red' size='10pt' fw='500' >
                                                        Esta Categoria no permite la publicacion de Variantes*
                                                    </PublishProduct.textBody>
                                                )}
                                            </div>
                                            {
                                                steps > 1 && (
                                                    <div className='text-left'>
                                                        <PublishProduct.textBody size='12pt' ml='10px'>
                                                            Categooria de Ml por seleccion propia:
                                                        </PublishProduct.textBody>
                                                        <PublishProduct.textBody ml='10px' cl='#7367F0' size='12pt' fw='500'>
                                                            {categoryMlText ? categoryMlText : 'No se agrego'}
                                                        </PublishProduct.textBody>
                                                        {categoryMlText ?
                                                            categoryMlText === 'Otros' && (
                                                                <PublishProduct.textBody ml='15px' mt='5px' cl='red' size='12pt' fw='500' >
                                                                    Esta Categoria no permite la publicacion de Variantes*
                                                                </PublishProduct.textBody>
                                                            )
                                                            : ''}
                                                    </div>
                                                )
                                            }
                                            <div style={{ width: 100 + '%' }}>
                                                {stepRender(steps)}
                                            </div>
                                        </>
                                    )
                            }
                        </PublishProduct.bodyModal>
                    )
                }

                <PublishProduct.footerModal>
                    <PublishProduct.btnActionFooter bc='#707070' mr='auto' onClick={() => { globalContext.setModalPublishProduct(false) }} >
                        Cerrar
                    </PublishProduct.btnActionFooter>
                    {
                        steps > 1 && !succesPublish && (
                            <PublishProduct.btnActionFooter bc='#7367f0' bgc='#7367f0' ml='auto' cl='#FFFFFF' onClick={() => beforeStep()}>
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </PublishProduct.btnActionFooter>
                        )
                    }
                    {
                        steps < 3 && !succesPublish && (
                            <PublishProduct.btnActionFooter bc='#7367f0' bgc='#7367f0' ml='5px' cl='#FFFFFF' onClick={() => nextStep()}>
                                {steps > 1 ? 'Siguiente' : 'Saltar'}
                            </PublishProduct.btnActionFooter>
                        )
                    }
                    {
                        steps === 3 && listingType && !succesPublish ? (
                            <PublishProduct.btnActionFooter bc='#7367f0' bgc='#7367f0' ml='5px' cl='#FFFFFF' onClick={() => saveData()}>
                                Guardar
                            </PublishProduct.btnActionFooter>
                        ) : ''
                    }
                </PublishProduct.footerModal>
            </PublishProduct.ModalProduct>
        </PublishProduct.ContModal>
    );
}

export default ModalPublishProduct;