import React from "react";

export default function stepCero(props) {
  const { nextStep } = props;
  let next = (e) => {
    e.preventDefault();
    nextStep();
  };
  return (
    <div>
      Step Cero
      <button
        onClick={(e) => {
          next(e);
        }}
      >
        next
      </button>
    </div>
  );
}
