import React, { useContext, useState } from 'react'
import ConfigStyled from '../../../../assets/css/components/admin/config/ConfigStyled'
import { GlobalContext } from '../../../../contexts/GlobalContext';
import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
import { useEffect } from 'react';
export default function Four() {
    const [isSending, setIsSending] = useState(false);
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [currencies, setCurrencies] = useState([]);
    const [config, setConfig] = useState({});
    const [defaultConfig, setDefaultConfig] = useState({});

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (msg) globalContext.showModalWithMsg(msg);
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    /**
     * Change the config state
     * @param {Event} e The change event
     */
    const updateConfigState = (e) => {
        const name = e.target.name;
        const newConfig = Object.assign({}, config);

        switch (e.target.type) {
            case "checkbox":
                newConfig[name] = e.target.checked;
                break;

            default:
                newConfig[name] = e.target.value;
                break;
        }

        setConfig(newConfig);
    };

    /**
     * Send the changes to the server
     */
    const saveChanges = () => {
        const entId = globalContext.currentEnterpriseId;
        setIsSending(true);
        if (!entId) return;
        if (!window.confirm("Desea guardar los cambios?")) return;

        globalContext.setLoaderPage(true);

        const configToSend = {};
        const confKeys = Object.keys(config);

        for (const key of confKeys) {
            configToSend[key] = config[key];
        }

        axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/ml`, {
            config: configToSend,
        }, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then(resp => {
            //Set the actual config as the default data
            setDefaultConfig(Object.assign(defaultConfig, config));
            globalContext.showModalWithMsg(`Los datos fueron guardados`);
            setIsSending(false)
        }).catch((err) => {
            setIsSending(false)
            handleAxiosError(
                err,
                "Disculpe, no se pudieron guardar los cambios."
            )
        }
        ).finally(() => {
            globalContext.setLoaderPage(false);
            setIsSending(false)
        });
    }

    /**
     * Load the currencies
     */
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/ml-currency/${entId}/my-currencies`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then(r => {
            const data = r.data.data;
            setCurrencies(data.currencies);
        }).catch((err) =>
            handleAxiosError(
                err,
                "Disculpe, no se pudo cargar la lista de monedas."
            )
        );
    }, [globalContext.currentEnterpriseId])

    /**
     * Load the plugin config
     */
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/ml`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then(resp => {
            const data = resp.data.data.configData;

            //Set the default config
            const defaultConf = data;
            setDefaultConfig(defaultConf);

            //Set the actual config as a copy of the default
            setConfig(Object.assign({}, defaultConf));
        }).catch((err) =>
            handleAxiosError(
                err,
                "Disculpe, no se pudo cargar la configuracion actual."
            )
        );
    }, [globalContext.currentEnterpriseId]);


    /**
     * Cancel axios requests before unmount
     */
    useEffect(() => {
        return () => {
            axiosCancelTokenSource.cancel("Canceled by unmount");
        };
        //eslint-disable-next-line
    }, []);
    return (
        <ConfigStyled.ContForm>
            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                    ¿Desea utilizar el monto minimo en caso de que un precio de por debajo de el?
                </ConfigStyled.text>
                <ConfigStyled.inputChecbox>
                    <input
                        className="inp-cbx"
                        style={{ display: 'none' }}
                        id='cbx-useMinPrice'
                        type="checkbox"
                        name="useMinPrice"
                        checked={config.useMinPrice ? true : false}
                        disabled={isSending}
                        onChange={updateConfigState}
                    />
                    <label className="cbx" htmlFor='cbx-useMinPrice'>
                        <span>
                            <svg width="12px" height="10px" viewBox="0 0 12 10">
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </svg>
                        </span>
                    </label>
                </ConfigStyled.inputChecbox>
            </ConfigStyled.ContInputAndLabel>

            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                    Sincronizar stock
                </ConfigStyled.text>
                <ConfigStyled.inputChecbox>
                    <input
                        className="inp-cbx"
                        style={{ display: 'none' }}
                        id='cbx-syncStock'
                        type="checkbox"
                        name="syncStock"
                        checked={config.syncStock ? true : false}
                        disabled={isSending}
                        onChange={updateConfigState}
                    />
                    <label className="cbx" htmlFor='cbx-syncStock'>
                        <span>
                            <svg width="12px" height="10px" viewBox="0 0 12 10">
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </svg>
                        </span>
                    </label>
                </ConfigStyled.inputChecbox>
            </ConfigStyled.ContInputAndLabel>

            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size="12pt" style={{ textAlign: 'left' }}>
                    Sincronizar precio
                </ConfigStyled.text>
                <ConfigStyled.inputChecbox>
                    <input
                        className="inp-cbx"
                        style={{ display: 'none' }}
                        id='cbx-syncPrice'
                        type="checkbox"
                        name="syncPrice"
                        checked={config.syncPrice ? true : false}
                        disabled={isSending}
                        onChange={updateConfigState}
                    />
                    <label className="cbx" htmlFor='cbx-syncPrice'>
                        <span>
                            <svg width="12px" height="10px" viewBox="0 0 12 10">
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </svg>
                        </span>
                    </label>
                </ConfigStyled.inputChecbox>
            </ConfigStyled.ContInputAndLabel>

            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size="12pt">
                    Moneda a utilizar
                </ConfigStyled.text>
                <ConfigStyled.select
                    value={config.useCurrencyId}
                    disabled={isSending}
                    name="useCurrencyId"
                    onChange={updateConfigState}
                >
                    <option value="">Seleccione</option>
                    {currencies && currencies.length && currencies.map(c => (<option key={c.id} value={c.id}>{c.name} ({c.symbol})</option>))}
                </ConfigStyled.select>
            </ConfigStyled.ContInputAndLabel>

            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size="12pt">
                    Como publicar las variantes de Odoo a ML
                </ConfigStyled.text>
                <ConfigStyled.select
                >
                    <option value="">Seleccione</option>
                    <option value="">Como productos individuales</option>
                    <option value="">Agrupadas</option>
                </ConfigStyled.select>
            </ConfigStyled.ContInputAndLabel>
            
            <ConfigStyled.ARow>
                <ConfigStyled.btnSend disabled={isSending} onClick={saveChanges}>
                    Guardar
                </ConfigStyled.btnSend>
            </ConfigStyled.ARow>
        </ConfigStyled.ContForm>
    )
}
