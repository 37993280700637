import React from 'react'
import ConfigStyled from '../../../../assets/css/components/admin/config/ConfigStyled'
import { GrConfigure } from 'react-icons/gr'
import { RiSettingsLine } from 'react-icons/ri'
import { RiFolderSettingsLine } from 'react-icons/ri';
import { AiOutlineUser } from 'react-icons/ai'
import { keyUserRoles } from '../../../../assets/js/SessionStorageNames';
export default function MenuSidebar(props) {
    const {step, setStep} = props;
    const userRoles = localStorage.getItem(keyUserRoles).split(",").filter(String);
    const funcSetStep = v => {
        setStep(v)
    }
    const hasPermision = userRoles.find( e => e === "ADMIN");
    return (
        <ConfigStyled.MenuSidebar>

            <ConfigStyled.MenuItems active={step === 1} onClick={() => funcSetStep(1)}>
                <ConfigStyled.ContIcon active={step === 1}>
                    <RiFolderSettingsLine />
                </ConfigStyled.ContIcon>
                Configuracion General
            </ConfigStyled.MenuItems>
            {
              hasPermision && (
                <ConfigStyled.MenuItems active={step === 2} onClick={() => funcSetStep(2)}>
                    <ConfigStyled.ContIcon active={step === 2}>
                        <GrConfigure />
                    </ConfigStyled.ContIcon>
                    Configuracion del Admin
                </ConfigStyled.MenuItems>
              )
            }
            

            <ConfigStyled.MenuItems active={step === 3} onClick={() => funcSetStep(3)}>
                <ConfigStyled.ContIcon active={step === 3}>
                    <RiSettingsLine />
                </ConfigStyled.ContIcon>
                    Presupuestos
            </ConfigStyled.MenuItems>

            <ConfigStyled.MenuItems active={step === 10} onClick={() => funcSetStep(10)}>
                <ConfigStyled.ContIcon active={step === 10}>
                    <RiSettingsLine />
                </ConfigStyled.ContIcon>
                Plantilla de publicaciones
            </ConfigStyled.MenuItems>

            <ConfigStyled.MenuItems active={step === 8} onClick={() => funcSetStep(8)}>
                <ConfigStyled.ContIcon active={step === 8}>
                    <RiSettingsLine />
                </ConfigStyled.ContIcon>
                Configuracion de Amazon
            </ConfigStyled.MenuItems>

            <ConfigStyled.MenuItems active={step === 4} onClick={() => funcSetStep(4)}>
                <ConfigStyled.ContIcon active={step === 4}>
                    <RiSettingsLine />
                </ConfigStyled.ContIcon>
                Configuracion de ML
            </ConfigStyled.MenuItems>

            <ConfigStyled.MenuItems active={step === 5} onClick={() => funcSetStep(5)}>
                <ConfigStyled.ContIcon active={step === 5}>
                    <RiSettingsLine />
                </ConfigStyled.ContIcon>
                Configuracion de LN
            </ConfigStyled.MenuItems>

            <ConfigStyled.MenuItems active={step === 6} onClick={() => funcSetStep(6)}>
                <ConfigStyled.ContIcon active={step === 6}>
                    <RiSettingsLine />
                </ConfigStyled.ContIcon>
                Configuracion de DF
            </ConfigStyled.MenuItems>

            
            {
              hasPermision ? (
                <ConfigStyled.MenuItems active={step === 9} onClick={() => funcSetStep(9)}>
                    <ConfigStyled.ContIcon active={step === 9}>
                        <GrConfigure />
                    </ConfigStyled.ContIcon>
                    Usuarios e-kommers.com
                </ConfigStyled.MenuItems>
              ) :
              (
                <ConfigStyled.MenuItems active={step === 7} onClick={() => funcSetStep(7)}>
                    <ConfigStyled.ContIcon active={step === 7}>
                        <AiOutlineUser />
                    </ConfigStyled.ContIcon>
                        Usuarios
                </ConfigStyled.MenuItems>
              )
            }
        </ConfigStyled.MenuSidebar>
    )
}
