import styled from "styled-components";

const ContSelect = styled.div`
min-width: 200px;
width: ${props => props.w || '400px'};
max-width: ${props => props.maxW || '400px'};
margin-left: auto;
margin-right: auto;
position: relative;
`;

const SelectCustom = styled.div`
width: 100%;
font-weight: 400;
line-height: 1.45;
color: #6e6b7b;
padding: 0.438rem 1rem;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #d8d6de;
border-radius: 0.357rem;
height: 40px;
display: flex;
justify-content: space-between;
align-items: center;
cursor: pointer;
transition: all ease-in-out .3s;
&:hover {
    border: 1px solid #7367f0;
}
`;

const ContListOptions = styled.div`
background: #FFFFFF;
z-index: 12;
border-radius: 0.357rem;
min-width: 200px;
width: ${props => props.w || '400px'};
max-width: ${props => props.maxW || '400px'};
min-height: 100px;
height: auto;
max-height: 120px;
overflow-y: auto;
position: absolute;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
transform: translate(0px, 5px);
padding: 1rem;
display: ${props=> props.active ? 'block': 'none'};
    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg,#7367f0,rgba(115,103,240,.7));
        border-radius: 50px;
        
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {        
        box-shadow: 2px 0px 6px #7367f0;
        
    }
`;

const ListEnterprises = styled.ul`
list-style: none;
padding-left: 0;
margin-bottom: 0;
width: 100%;
`;

const ItemListEnterprise = styled.li`
width: 100%;
text-align: start;
border-bottom: 1px solid #d8d6de;
padding: 0.2rem;
&:hover {
    cursor: pointer;
    border-color: #7367f0;
}
`

const Text = styled.p`
margin-bottom:  ${props=> props.mb || '0'};;
color: ${props=> props.cl || '#707070'};
font-size: ${props=> props.size || '12px'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
font-weight: ${props=> props.fw || 'lighter'};
margin-top: ${props=> props.mt || '0'};
`;

const BtnShowOptions = styled.div`
width: 30px;
height: 30px;
outline: none !important;
border: 1px solid #d8d6de;
display: flex;
justify-content: center;
align-items: center;
border-radius: 0.357rem;
`
const SelectEnterpriseStyle = {
    ContSelect,
    BtnShowOptions,
    Text,
    ContListOptions,
    ListEnterprises,
    ItemListEnterprise,
    SelectCustom
}

export default SelectEnterpriseStyle;