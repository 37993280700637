import styled from 'styled-components';

const input = styled.input`
    padding-left:10px;
    font-family: "Berlin Rounded";
    width: 100%;
    height: 38px;
    border-radius: 5px;
    background: #fff;
    border: 0.5px solid rgba(112, 112, 112, 0.7);
    outline: none !important;
    color: rgba(112, 112, 112, 0.61);
    font-size: 12pt;
    font-weight: 300;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
        }
        
`;

const categoriesList = styled.div`
.contenerdor-categories {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-around;
}

.header {
    align-self: flex-start;
    font-size: 18pt;
    color: #707070;
    
    
}
.cont-categories {
    width: 60%;
    height: 448px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding:10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: all ease 1s;
    transform: translateX(0px);
    position: absolute;
    top: 15%;
    left: auto;
    right: auto;
    bottom:auto;
    .tittle {
        color: #707070;
        font-weight:300;
        font-size: 15pt;
        align-self: flex-start;
    }
    .header-cont-categories {
        width:100%;
        display:flex;
        justify-content: flex-end;
        align-items:center;
    }
    .i-have-a-link {
        
        color:#7367f0;
        font-size:12pt;
        margin-bottom:3px;
        align-self: flex-end;
    }
}
.cont-categories.active {
    transform: translateX(-5000px);
}
`;

const bodyCategories = styled.div`

    width:100%;
    height:300px;
    overflow: hidden scroll;
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(94, 84, 142);
      border-radius: 50px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #7367f0;
    }
    .pagination {
        margin-bottom: 0px !important;
    }
    .list-c {
        list-style: none;
        li {
            text-align: left;
            border-bottom: 0.3px solid rgba(112, 112, 112, 0.1);
            padding: 15px 10px;
            transition: all ease 0.2s;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
        &:hover {
            background-color: rgba(112, 112, 112, 0.3);
            cursor:pointer;
        }
        }
    }
    .load-screen {
        background-color: #ffffff;
        height:300px;
        width:100%;
        display:none;
        justify-content:center;
        align-items:center;
        z-index:100000;
        .spinner-border {
            border: .25em solid #7367f0;
            border-right-color: transparent;
        }
    }
    .load-screen.active {
        display:flex;
    }
    .list-c.active{
        display:none;
    }
`;


const buttonBack = styled.button`
border-radius: 5px;
background: #ffffff;;
outline: none!important;
border:1px solid #7367f0;
color: #7367f0;
font-size: 12pt;
font-weight: 400;
padding: 6px 18px;
transition: all ease 0.2s;
margin-right:10px;
&:hover {
    background-color:#7367f0;
    color:#ffffff;
}
`;
const btnFloat = styled.button`
    width: 38px;
    height: 38px;
    background: #fff;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:50px;
    border: none !important;
    outline: none !important;
    position: absolute;
    margin-right: 5px;
    transition: all ease 0.5s;
    font-size:12pt;
    color: #707070;
    &:hover {
        background-color: #7367f0;
        color: white;
    }
`;
const btnRedirect = styled.button`
    width: ${props=> props.wd || 'auto'};
    height: auto;
    background-color:${props => props.active ?  "#7367f0" : "#FFFFFF"};
    text-align: center;
    color: ${props => props.active ? "#FFFFFF" : "#7367f0"};
    padding:6px 16px;
    font-size:12pt;
    border: 1px solid #7367f0;
    margin-top: ${props => props.mt || 0};
    margin-left:${props => props.ml || 0};
    margin-right:${props => props.mr || 0};
    border-radius:5px;
    outline: none!important;
    transition: all ease 0.5s;
    font-weight: bold;
    &:hover {
        color: white;
        background-color: #7367f0;
    }
`;
const btnDelete = styled.button`
    width: ${props=> props.wd || 'auto'};
    height: auto;
    background-color:${props => props.active ?  "#ea5455" : "#FFFFFF"};
    text-align: center;
    color: ${props => props.active ? "#FFFFFF" : "#ea5455"};
    padding:6px 16px;
    font-size:12pt;
    border: 1px solid #ea5455;
    margin-top: ${props => props.mt || 0};
    margin-left:${props => props.ml || 0};
    margin-right:${props => props.mr || 0};
    border-radius:5px;
    outline: none!important;
    transition: all ease 0.5s;
    font-weight: bold;
    &:hover {
        color: white;
        background-color: #ea5455;
    }
`;
const btnCurrentCategorie = styled.button`
    width: auto;
    height: auto;
    background-color:${props => props.active ?  "#7367f0" : "#FFFFFF"};
    display:flex;
    align-items: center;
    justify-content: space-evenly;
    color: ${props => props.active ? "#FFFFFF" : "#7367f0"};
    padding:5px;
    font-size:12pt;
    border: 1px solid #7367f0;
    margin-top: ${props => props.mt || 0};
    margin-left:${props => props.ml || 0};
    margin-right:${props => props.mr || 0};
    border-radius:5px;
    outline: none!important;
    transition: all ease 0.5s;
    font-weight: bold;
    &:hover {
        color: white;
        background-color: #7367f0;
    }
`;
const btnClearCategory = styled.button`
    width: 30px;
    height: 30px;
    background-color:${props => props.active ?  "#7367f0" : "#FFFFFF"};
    display:flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.active ? "#FFFFFF" : "#7367f0"};
    font-size:12pt;
    border: 1px solid #7367f0;
    margin-top: ${props => props.mt || 0};
    margin-left:${props => props.ml || 0};
    margin-right:${props => props.mr || 0};
    border-radius:50px;
    outline: none!important;
    transition: all ease 0.5s;
    font-weight: bold;
    &:hover {
        color: white;
        background-color: #7367f0;
    }
`;
/**
 * ? seccion para la lista de productos
 */
const listProducts = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items:flex-start;
    width:100%;
    @media (max-width: 900px){
        display: block;
    }
    .cont {
    width: 100%;
    line-height: 1;
    height:auto;
    display:flex;
    box-sizing: content-box;
    box-sizing:content-box;
    justify-content: flex-end;
}
`;
const divContFilters = styled.div`
    padding: 0 10px;
    width: 320px;
    height: 100%;
    top: 10px;
    position: sticky;
    @media (max-width: 900px){
        width:100%;
        margin-bottom:25px;
        position: relative;
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
    }
`;
const divContData = styled.div`
    padding:0 10px;
    width:100%;
    .cont {
    width: 100%;
    line-height: 1;
    height:auto;
    display:flex;
    box-sizing: content-box;
    box-sizing:content-box;
    justify-content: flex-end;
}
.header {
        display: flex;
    }
    @media (max-width: 900px){
        margin-top:5px;
        .header {
            flex-direction:column;
        }
        .cont-btn-header {
            margin-top:5px;
            width:100%;
            justify-content:center;
        }
    }
    .cont-btn-header {
        width:auto;
        display:flex;
    }
`;
const gridData = styled.div`
    margin-top:10px;
    display: grid;
    grid-gap: 1rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(300px, ${props=> props.changeToRow ? '100%' : '1fr'}));
    @media (max-width: 600px) {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
`;
const cardsGrid = styled.div`
    width: 100%;
    height: auto;
    border-radius: 5px;
    background: ${props => props.isDeleted ? '#F5F5F6' : '#FFFF'};
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    display:flex;
    flex-direction: ${props=> props.changeToRow ? 'row' : 'column'};
    justify-content: ${props=> props.IsScrapingAgain ? 'center' : 'space-between'};
    align-items:center;
    overflow: hidden;
    position: relative;
    @media(max-width: 600px) {
        flex-direction: column;
    }
`;
const screenLoader = styled.div`
    background: #ffffff;
    border-radius: 5px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: z-index transform 0.2s ease-out;
    z-index: ${props => props.IsScrapingAgain ? '9' : '-1'};
    opacity: ${props => props.IsScrapingAgain ? '1' : '0'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .spinner-border {
        border: .25em solid #7367f0;
        border-right-color: transparent;
    }
`;
const contImgCard = styled.div`
align-self: flex-start;
    display:flex;
    justify-content:flex-end;
    align-items:flex-start;
    width: ${props=> props.changeToRow ? '500px' : '100%'};
    padding:10px;
        .cont-dots {
            display:flex;
            justify-content:center;
        }
        .dot-one, .dot-two {
            align-self:center;
            font-size:15pt;
        }
        .dot-filter {
            align-self:flex-start;
            font-size:9pt;
        }
        /***
        * ! styles checbox
         */
    .cbx {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    }
    .cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
    }
    .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #9098A9;
    transition: all 0.2s ease;
    }
    .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
    }
    .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #7367f0;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
    }
    .cbx:hover span:first-child {
    border-color: #7367f0;
    }

    .inp-cbx:checked + .cbx span:first-child {
    background: #7367f0;
    border-color: #7367f0;
    animation: wave 0.4s ease;
    }
    .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
    }
    .inp-cbx:checked + .cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
    }

    @keyframes wave {
    50% {
        transform: scale(0.9);
    }
    }
    @media(max-width: 600px) {
        width:100%;
    }
`;

const imgCard = styled.div`
    margin-left:auto;
    margin-right: auto;
    justify-self: center;
    width:${props=> props.changeToRow ? '100px !important' : '200px !important'};
    height: ${props=> props.changeToRow ? '100px !important' : '200px !important'};
    background-image: url(${props=> props.url ? '"'+`${props.url}`+'"' : ""});
    background-repeat: no-repeat;
    background-size: contain;
    background-position:center;
`;
const dataCardText = styled.div`
width:100%;
padding: ${props=> props.changeToRow ? '10px' : '0 10px'};
height: ${props=> props.changeToRow ? '100%' : 'auto'};
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
.price{
    align-self: flex-end;
    width:100%;
    text-align:right;
    font-size: 12pt;
    color:#7367f0 !important;
    font-weight:600;

    p {
        margin-bottom:0;
    }
}

.precio-oferta,
.precio-old {
    width:100%;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    font-size: 12pt;
    color:#707070;
    font-weight:500;
    p {
        margin-bottom:0;
    }
}
@media(max-width: 600px) {
        flex-direction: column;
        padding: 0 10px;
        height: auto;
    }
`;
const titleDataCardtext = styled.div`
    width:100%;
    text-align:justify;
    color:#707070;
    font-weight:lighter;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${props=> props.changeToRow ? '2' : '2'};
    -webkit-box-orient: vertical;
    p {
        margin-bottom:0;
    }
    @media(max-width: 600px) {
        -webkit-line-clamp: 2;
    }
`
const preciosCardText = styled.div`
width:100%;
margin-top:15px;
margin-bottom:15px;
`
const contBtnCardActions = styled.div`
display:flex;
align-items:center;
width: ${props=> props.changeToRow ? '350px' : '100%'};
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
justify-content:${props=> props.changeToRow ? 'center' : 'space-between'};
padding: ${props=> props.changeToRow ? '10px' : '0'};
flex-direction: ${props=> props.changeToRow ? 'column' : 'row'};
height: ${props=> props.changeToRow ? '100%' : 'auto'};
border-left: ${props=> props.changeToRow ? '0.5px solid rgba(112,112,112, 0.30)' : ''};
@media(max-width: 600px) {
width: 100%;
justify-content: space-between;
padding: 0;
flex-direction: row;
height: auto;
border-left: none;
}
.price{
    align-self: center;
    width:100%;
    text-align:center;
    font-size: 12pt;
    color:#7367f0 !important;
    font-weight:600;

    p {
        margin-bottom:0;
    }
}
`;
const btnActionsCard = styled.button`
width:100%;
height:38px;
display:flex;
justify-content:center;
align-items:center;
font-size:12pt;
border-bottom-left-radius: ${props => props.brbl || 0};
border-bottom-right-radius: ${props => props.brbr || 0};
outline:none !important;
color: ${props => props.colorFont || "#7364060"};
background-color: ${props => props.bgColor || "#7364060"};
border:none;
border-top-left-radius:  ${props=> props.changeToRow ? '5px' : ''};
border-top-right-radius:  ${props=> props.changeToRow ? '5px' : ''};
margin-top: ${props=> props.changeToRow ? '10px' : ''};
@media(max-width: 600px) {
border-top-left-radius:  ${props=> props.changeToRow ? '5px' : ''};
border-top-right-radius:  ${props=> props.changeToRow ? '5px' : ''};
margin-top: 0;
}
`;
const inputOne = styled.input`
    width: 100%;
    height: 38px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: none !important;
    outline: none !important;
    padding:6px 16px;
    font-size:12pt;
    color: rgb(112, 112, 112);
    font-weight: 600;
    padding-right: 40px;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12px;
        color: rgba(112, 112, 112, 0.61);
        }
    margin-top : ${props => props.mt || 0};
`
const header = styled.p`
    color: #707070;
    font-size:18pt;
    margin-bottom:5px;
    align-self:flex-start;
    text-align: left;
`;
const headerCat = styled.p`
    color: #707070;
    font-size:12pt;
    margin-bottom:5px;
    align-self:flex-start;
    text-align: left;
`;
/***
 * ! estilos para la modal de variantes
 */
const continfoModalVariant = styled.div`
position: fixed;
top:0;
right:0;
bottom:0;
left:0;
width:100%;
height:100%;
background: rgba(112, 112, 112, 0.38);
z-index:100000;
display: ${props=>props.d || 'none'};
justify-content:center;
align-items:center;
padding:10px;
overflow: auto;

`;
const modalVariants = styled.div`
display: flex;
flex-direction: column;
justify-content:space-between;
align-items:center;
background-color: #ffffff;
border-radius:5px;
min-width:300px;
min-height: 500px;
margin-top:auto;
margin-bottom:auto;
`
const headerModalVariant = styled.div`
padding:10px;
height: 60px;
width:100%;
background:#F5F5F6;
display: flex;
justify-content:flex-start;
align-items:center;
align-self: flex-start;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
`
const footerModalVariants = styled.div`
padding:10px;
height: 60px;
width:100%;
display: flex;
justify-content:space-between;
align-items:center;
align-self: flex-start;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
border-top:0.5px solid rgba(112, 112, 112, 0.5);
`;
const bodyModalVariant = styled.div`
width:100%;
min-width:300px;
display:flex;
justify-content: flex-start;
align-items:center;
@media(max-width: 900px) {
    flex-direction: column;
}
`;
const btnCloseModal = styled.button`
width:38px;
height: 38px;
border-radius:50px;
border:none;
color: #ffffff;
background-color: #7367f0;
display:flex;
justify-content:center;
align-items:center;
outline:none !important;
cursor: pointer;
margin-right: auto;
`
const titleModalVariant = styled.p`
margin-left: auto;
margin-right:auto;
margin-bottom:0;
color:#707070;
font-size:16pt;
`;
const contImgModal = styled.div`
    display: flex;
    flex-direction: column;
    padding:10px;
    justify-content:center;
    align-items:center;
    width:100%;
    height:auto;
    min-width:300px;
    max-height:700px !important;
`;
const contInfoVariant = styled.div`
width:100%;
text-align:left;
min-width: 300px;
align-self: flex-start;
.title-des-variant{
    text-align:left;
    width:100%;
    min-height:30px;
    height: auto;
    .title { 
        margin-bottom:0;
        font-size:12pt;
        color: #707070;
        font-weight:lighter;
    }
    .value { 
        margin-bottom:0;
        font-size:12pt;
        color: #707070;
        font-weight: bold;
    }
    a {
        font-weight:600;
        word-break: break-all;
        color: #707070;
        text-decoration: none;
        &:hover {
            color: #7367f0;
        }
    }
}
    .title-des-variant-caracteris {
    .title{
        color: #707070;
    }
    ul li{
        color: #707070;
        font-weight: bold;
    }
    }
    @media (max-width: 900px) {
        flex-direction: column;
        justify-content:space-around;
        align-items:center;
        padding:10px;
    }
`;
const contImgCardModal = styled.div`
    background-image: url(${props=> props.img ? '"'+`${props.img}`+'"' : ""});
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 500px;
    background-position: center;
`

const contImgLogoKeepa = styled.div`
    background-image: url(${props=> props.img ? '"'+`${props.img}`+'"' : ""});
    width: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    height: 30px;
    background-position: center;
`

const contImgManyImgCards = styled.div`
    border: none;
    width: 100%;
    max-width:500px;
    padding:10px;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(50px, 50px));
    `;
const contImgGrid = styled.div`
    min-width: 50px;
    max-width: 50px !important;
    width: 50px !important;
    max-height: 50px !important;
    height: 50px !important;
    border: 0.5px solid #707070;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-left: 3%;
    margin-right: 3%;
.img-slider {
        width: 100%;
        
        &:hover {
            cursor: pointer;
            border-color: #7367f0;
        }
    }
`;
const select = styled.select`
padding-left:10px;
padding-right:10px;
font-family: "Berlin Rounded";
width: ${props=> props.wd || '100%'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
margin-top: ${props=> props.mt || '0'};
height: 38px;
border-radius: 5px;
background: #fff;
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
border: none !important;
outline: none !important;
color: rgba(112, 112, 112, 0.61);
font-size: 12pt;
font-weight: 300;
::placeholder {
    font-family: "Berlin Rounded";
    font-weight: 300;
    font-size: 12pt;
    color: rgba(112, 112, 112, 0.61);
    }
    &:focus {
        border: 0.5px solid rgba(115, 103, 240, 0.7);
    }
    @media(max-width: ${props => props.selectMq || '989px'}) {
    width:100%;
}
`;

const btnFilter = styled.button`
width:25px;
height: 25px;
border: none;
outline: none !important;
background: transparent;
color: #7367F0;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
transition: all ease 0.2s;
border-radius:50px;
&:hover {
    background: #7367F0;
    color: #FFFFFF;
}
`;
const btnOptions = styled.button`
width:25px;
height: 25px;
border: none;
outline: none !important;
background: transparent;
color: #707070;
display: flex;
align-items: center;
justify-content: center;
transition: all ease 0.2s;
border-radius:50px;
&:hover {
    background: rgb(112 112 112/ 20%)
}
`;
const text = styled.p`
margin-bottom:  ${props=> props.mb || '0'};;
color: ${props=> props.cl || '#707070'};
font-size: ${props=> props.size || '12px'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
font-weight: ${props=> props.fw || 'lighter'};
margin-top: ${props=> props.mt || '0'};
`;
const totalCount = styled.div`
width: 100%;
text-align:left;
display:flex;
align-items:center;
justify-content: space-between;
padding: 10px;
font-size:12pt;
font-weight:600;
flex-wrap: wrap;
color: #707070;
`
const btnInfo = styled.button`
width:25px;
height:25px;
border:none;
outline:none !important;
font-size:10pt;
display:flex;
justify-content:center;
align-items:center;
background: #7367F0;
color: #777777;
border-radius:50px;
color:#FFFFFF;
`
const formCountData = styled.div`
width: 100%;
flex-direction: column;
display: flex;
align-items: center;
justify-content: center;
transition: all ease 1s;
transform: translateX(${props=> props.active ? '0px' : '5000px'});
padding-right:10px;
`;

const btnSend = styled.button`
margin-top: 10px;

border-radius: .55rem;
border: 1px solid #7367f0;
outline: none !important;
padding: 8px 6px;
color: #7367f0;
background: #FFFFFF;
transition: all ease 0.2s;
display: flex;
align-items: center;
justify-content: center;
&:hover {
    color: #FFFFFF;
    background: #7367F0;
}
`
const headerForForm = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: flex-start;
font-size: 12pt;
color: #707070;
margin-bottom: 10px;
`

const openModalIcons = styled.div`
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    transition: all ease 0.08s;
    display:flex;
    justify-content:center;
    align-items:center;
    transform: ${props => props.active ? 'translateY(0px)': 'translateY(-10000px)'};
    padding:15px;
    overflow:auto;
`;

const modalIcons = styled.div`
background-color: #FFF;
border-radius: .5rem;
width:400px;
min-width:300px;
min-height:200px;
margin-left:auto;
margin-right:auto;
margin-top:auto;
margin-bottom:auto;
transition: all ease 0.08s;
transform: ${props => props.active ? 'translateY(0px)': 'translateY(-10000px)'};
`
const headerModalIcons = styled.div`
width: 100%;
height:45px;
background: #f5f5f6;
border-top-right-radius:.5rem;
border-top-left-radius:.5rem;
display: flex;
flex-direction:row;
justify-content: space-between;
align-items:center;
margin-top:0;
padding: 0 10px;
`
const btnCloseModalIcons = styled.button`
width:30px;
height:30px;
border-radius:50px;
outline:none !important;
background-color: #7367F0;
color:#FFF;
display:flex;
justify-content:center;
align-items:center;
border:none;
`;

const bodyModalIcons = styled.div`
display:flex;
justify-content:center;
align-items:flex-start;
flex-direction: row;
width:100%;
max-height: 500px;
overflow-y: auto;
`;
const listOption = styled.ul`
list-style: none;
padding-left: 0;
width: 100%;
`;
const listOptionItems = styled.li`
padding:10px;
border-bottom: 1px solid rgb(112 112 112/ 36%);
color: #707070;
font-size:12pt;
cursor: pointer;
text-align: start;
display:flex;
justify-content: start;
align-items:center;
&:hover {
    background-color: rgb(112 112 112/ 11%);
}
`;

const contInputRadio = styled.div`
display: flex;
align-items:center;
width:100%;
justify-content: flex-start;
margin-bottom:10px;
`;
const inputRadio = styled.input`

@supports(-webkit-appearance: none) or (-moz-appearance: none) {

  --active: #7367f0;
  --active-inner: #fff;
  --focus: 2px rgba(39, 94, 254, .3);
  --border: #7367f0;
  --border-hover: #7367f0;
  --background: #fff;
  --disabled: #F6F8FF;
  --disabled-inner: #E1E6F9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background .3s, border-color .3s, box-shadow .2s;
  margin-left: ${props => props.ml || '0'};
  margin-right: ${props => props.mr || '0'};
  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
  }
  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }
  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: .9;
    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
    & + label {
      cursor: not-allowed;
    }
  }
  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }
  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  &:not(.switch) {
    width: 21px;
    &:after {
      opacity: var(--o, 0);
    }
    &:checked {
      --o: 1;
    }
  }
  & + label {
    font-size: 12pt;
    line-height: 14px;
    display: inline-block;
    vertical-align: inherit;
    cursor: pointer;
    margin-left: 5px;
    color: #707070;
    margin-bottom: 0 !important;
  }


  border-radius: 50%;
  &:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, .7));
  }
  &:checked {
    --s: .5;
  }
}
`;
const contInputAndLabel = styled.div`
width:100%;
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'space-between'};
align-items:${props => props.ali || 'flex-start'};
margin-top: 10px;
margin-bottom:10px;
`;

const contLoader = styled.div`
width: 100%;
height: 100%;
margin-top: auto;
margin-bottom: auto;
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
.spinner-border {
    border: .25em solid #7367f0;
    border-right-color: transparent;
}
`;

const ContCategorySelection = styled.div.attrs(props => ({
    active: props.active ? 'flex' : 'none',
}))`
    position: fixed;
    display: ${props => props.active};
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color:rgba(112,112,112,0.50);
    z-index:100;
`;

const CategorySelector = styled.div.attrs(props => ({
    active: props.active ? '0px' : '-460px',
}))`
    min-width: 300px;
    width: 100%;
    max-width: 400px;
    height: 100%;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: auto;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;
    padding:10px;
    transition: all ease-in 0.3s;
    overflow-y: auto;
    transform: translateX(${props => props.active});
    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg,#7367f0,rgba(115,103,240,.7));
        border-radius: 50px;
        
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {        
        box-shadow: 2px 0px 6px #7367f0;
        
    }
    
`;

const ContListCategory = styled.div`
width: 100%;
height: 100%;
margin-top: 0;
margin-bottom: 0;
overflow-y: auto;
    //transform: translateX(${props => props.active});
    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg,#7367f0,rgba(115,103,240,.7));
        border-radius: 50px;
        
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {        
        box-shadow: 2px 0px 6px #7367f0;
        
    }
`;
const ListCategoryHeader = styled.div`
width: 100%;
height: 50px;
background: #7367f0;
padding: 10px;
font-size: 13pt;
color: #FFFFFF;
font-weight: 500;
border-radius: .235rem;
display: flex;
justify-content: flex-start;
align-items: center;
margin-top: 0;
`;

const ContInputSearch = styled.form`
width: 100%;
height: 50px;
background: #FFFFFF;
padding: 5px;
display: flex;
justify-content: flex-start;
align-items: center;
`;

const ListCategoryHeaderTwo = styled.div`
width: 100%;
height: 50px;
background: #ffffff;
padding: 5px;
font-size: 13pt;
color: #111111;
font-weight: 450;
display: flex;
justify-content: flex-start;
align-items: center;
margin-top: 0;
`;

const ListCategory = styled.ul`
width: 100%;
list-style: none;
padding-left: 0px;
margin-top: 10px;
`;

const ListcategoryItem = styled.li`
width: 100%;
min-height: 40px;
height: auto;
display: flex;
justify-content: space-between;
align-items: center;
color: #111;
padding: 10px;
cursor: pointer;
&:hover {
    color: #111111;
    background: #eaeded;
}
`;

const BtnPrevCategory = styled.button`
background: #FFFFFF;
border-radius: 5px;
display: flex;
justify-content: center;
align-items: center;
outline: none !important;
border: none !important;
color: #7367f0;
margin-right: 10px;
width: 30px;
height: 30px;
`;

const BtnApliCategory = styled.button`
background: transparent;
display: flex;
justify-content: center;
align-items: center;
outline: none !important;
color: #FFFFFF;
margin-left: auto;
font-weight: 500;
border: none !important;
width: auto;
height: 30px;
transition: all ease-in-out .02s;
&:hover {
    background: #FFFFFF;
    color: #7367f0;
    border-radius: 5px;
}
`;

const ItemLoader = styled.li`
padding: 10px;
width: 100%;
margin-top: 5px; 
height: 40px;
display: flex;
justify-content: center;
align-items: center;
background: 
  linear-gradient(0.25turn, transparent, #fff, transparent),
  linear-gradient(#eee, #eee),
  radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
  linear-gradient(#eee, #eee);
background-repeat: no-repeat;
background-size: 100% 90%,100% 180px,20% 20% ,80% 20%;
animation: loading 1.5s infinite;
@keyframes loading {  
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}
`;

const InputSearch = styled.input`
display: block;
width: 100%;
height: 38px;
font-size: .875rem;
font-weight: 400;
line-height: 1.45;
color: #6e6b7b;
padding: 0.438rem 1rem;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #d8d6de;
border-radius: 0.357rem;
position: relative;
&:focus {
    color: #6e6b7b;
    outline: 0;
    background-color: #fff;
    border-color: #7367f0;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}
::placeholder {
    color: #c0c0c1;
    font-size: 1rem;
}
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
`;



const MultiScraperStyles = {
    BtnApliCategory,
    ItemLoader,
    ListCategoryHeader,
    ListCategoryHeaderTwo,
    text,
    input,
    categoriesList,
    bodyCategories,
    buttonBack,
    listProducts,
    inputOne,
    divContFilters,
    divContData,
    header,
    btnFloat,
    btnRedirect,
    btnDelete,
    gridData,
    cardsGrid,
    contImgCard,
    contBtnCardActions,
    btnActionsCard,
    dataCardText,
    continfoModalVariant,
    modalVariants,
    headerModalVariant,
    footerModalVariants,
    bodyModalVariant,
    btnCloseModal,
    titleModalVariant,
    contImgModal,
    contInfoVariant,
    contImgCardModal,
    contImgManyImgCards,
    select,
    contImgGrid,
    imgCard,
    titleDataCardtext,
    preciosCardText,
    btnFilter,
    btnCurrentCategorie,
    totalCount,
    formCountData,
    btnSend,
    headerForForm,
    btnOptions,
    btnInfo,
    openModalIcons,
    modalIcons,
    headerModalIcons,
    btnCloseModalIcons,
    bodyModalIcons,
    listOption,
    listOptionItems,
    contInputRadio,
    inputRadio,
    contInputAndLabel,
    screenLoader,
    contLoader,
    headerCat,
    btnClearCategory,
    ContCategorySelection,
    CategorySelector,
    ListCategory,
    ListcategoryItem,
    BtnPrevCategory,
    ContListCategory,
    ContInputSearch,
    InputSearch,
    contImgLogoKeepa
}
export default MultiScraperStyles