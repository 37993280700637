import React, { useState } from 'react'
import { BtnAction, ContBtnActons, ContLinkCategories} from '../../../assets/css/components/admin/categories/LinkCategories'
import ListCategoriesAmazon from './ListCategoriesAmazon';
import ListCategoriesMl from './ListCategoriesMl';
import ListUrlReadyToLinkMl from './ListUrlReadyToLinkMl';
import ListCategoriesAli from './ListCategoriesAli';
import getDataReport from './functions/getDataReport'
import { useContext } from 'react';
import { GlobalContext } from '../../../contexts/GlobalContext';
import axios from 'axios'
export default function LinkCategories() {
  const globalContext = useContext(GlobalContext);
  const [listUrlToLinkWithMl, setListUrlToLinkWithMl] = useState([]);
  const [categoryOn, setcategoryOn] = useState(null);
  const [load, setLoad] = useState(false);

      /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
       const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

  const getDataReportFunc = () => {
    const ent = globalContext.currentEnterpriseId;
    setLoad(true)
    getDataReport(ent)
    .then(res => {
      setLoad(false);
      globalContext.showModalWithMsg("El reporte se esta generando en segundo plano")
      console.log(res);
    })
    .catch(err => {
      setLoad(false);
      handleAxiosError(err);
      console.log(err);
    })
  }
  return (
    <>
    <ContBtnActons>
      <BtnAction onClick={() => getDataReportFunc()}>
        { load ? 'cargando' : 'Descargar Reporte' }
      </BtnAction>
    </ContBtnActons>
    <ContLinkCategories>
      <ListCategoriesAmazon
      listUrlToLinkWithMl={listUrlToLinkWithMl}
      setListUrlToLinkWithMl={setListUrlToLinkWithMl}
      />
      <ListUrlReadyToLinkMl 
      categoryOn={categoryOn}
      setcategoryOn={setcategoryOn}
      listUrlToLinkWithMl={listUrlToLinkWithMl}
      setListUrlToLinkWithMl={setListUrlToLinkWithMl}
      />
      <ListCategoriesMl
      categoryOn={categoryOn}
      setcategoryOn={setcategoryOn}
      listUrlToLinkWithMl={listUrlToLinkWithMl}
      setListUrlToLinkWithMl={setListUrlToLinkWithMl}
      />
    </ContLinkCategories>
    </>
  )
}
