import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react'
import MultiScraperStyles from '../../../../../assets/css/components/admin/products/amazon/MultiScraperStyles';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';
import { BiCircle } from 'react-icons/bi';
import { Spinner } from 'react-bootstrap';
export default function ModalAddProductFtp() {
    const globaContext = useContext(GlobalContext);
    const [listStores, setListStore] = useState([])
    const [errors, setError] = useState('');
    const [isLoad, setIsLoad] = useState(true);
    const getStore = async (entId) => {
        const resp = await axios({
            method: "get",
            url: `${process.env.REACT_APP_BACKEND_URL}/v1/peya-acc/peyaStore/${entId}`,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return resp;
    }
    const sendProd = async (productId, storeId) => {
        const entId = globaContext.currentEnterpriseId;
        const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/peya-prod/peyaAdd`,
            {
                productId: productId,
                storeId: storeId,
                enterpriseId: entId
            }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }

    const addProduct = (storeId) => {
        setIsLoad(true);
        if (!globaContext.addProductFtpId && !storeId) {
            setError('datos erroneos');
            setIsLoad(false);
            return
        }
        sendProd(globaContext.addProductFtpId, storeId)
            .then((res) => {
                console.log(res)
                setError('Producto agreagado exitosamente!');
                setIsLoad(false);
            }).catch(err => {
                console.log(err)
                setError('Presentamos problemas para agragar el producto!');
                setIsLoad(false);
            })
    }

    useEffect(() => {
        const entId = globaContext.currentEnterpriseId;
        if (!entId) return;
        if(!globaContext.addProductFtp) return;
        getStore(entId)
            .then((res) => {
                const data = res.data.data;
                console.log(data)
                setListStore(data);
                setIsLoad(false);
            }).catch(err => {
                console.log(err);
                setError('Presentamos algunos problemas!');
                setIsLoad(false);
            })
    }, [globaContext.currentEnterpriseId, globaContext.addProductFtp]);

    const closeModal = () => {
        setListStore([])
        setError('');
        setIsLoad(true);
        globaContext.setOpenAddProductFtp(false);
    }


    return (
        <MultiScraperStyles.openModalIcons active={globaContext.addProductFtp}>
            <MultiScraperStyles.modalIcons active={globaContext.addProductFtp}>
                <MultiScraperStyles.headerModalIcons>
                    <MultiScraperStyles.text size="12pt" fw="600">
                        Seleccione la tienda para
                    </MultiScraperStyles.text>
                    <MultiScraperStyles.btnCloseModalIcons
                        onClick={() => closeModal()}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </MultiScraperStyles.btnCloseModalIcons>
                </MultiScraperStyles.headerModalIcons>
                <MultiScraperStyles.bodyModalIcons>
                    {
                        isLoad ?
                        (
                            <MultiScraperStyles.contLoader>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </MultiScraperStyles.contLoader>
                        ) 
                        :
                        errors.length > 0 ?
                            (
                                <MultiScraperStyles.text size="14pt" fw="500" ml="10px">
                                    {errors}
                                </MultiScraperStyles.text>
                            ) :
                            (
                                <MultiScraperStyles.listOption>
                                    {
                                        listStores.length > 0 &&
                                        listStores.map(v => {
                                            return (
                                                <MultiScraperStyles.listOptionItems key={v._id} onClick={() => addProduct(v.storeId.toString())}>
                                                    <BiCircle />
                                                    <MultiScraperStyles.text size="12pt" fw="200" ml="10px">
                                                        {v.store}
                                                    </MultiScraperStyles.text>
                                                </MultiScraperStyles.listOptionItems>
                                            )
                                        })
                                    }
                                </MultiScraperStyles.listOption>
                            )
                    }
                </MultiScraperStyles.bodyModalIcons>
            </MultiScraperStyles.modalIcons>
        </MultiScraperStyles.openModalIcons>
    )
}
