import React from 'react'
import { useState } from 'react'
import RangosCss from '../../../../../assets/css/components/admin/products/ml/rangos-publicacion/RangosCss'
import One from './rangos-step/One';
import Three from './rangos-step/Three';

export default function Rangos(props) {
    const active = true;
    const off = false;
    const [itemActive, setItemActive] = useState(1);
    const [textStep, setTextStep] = useState('Rentabilidad')
    const {
        tariff,
        setTariff,
        earning,
        setEarning,
        minAmount,
        setMinAmount,
        maxAmount,
        setMaxAmount,
        amount,
        setAmount,
        porcentaje,
        setPorcentaje,
        arrayItemsByDelete,
        setArrayItemsByDelete,
        numeroArray,
        setNumeroArray,
        dataSave,
        setDataSave,
        dataSaveOne,
        setDataSaveOne,
        amountOne,
        setAmountOne,
        porcentajeOne,
        setPorcentajeOne,
        arrayItemsByDeleteOne,
        setArrayItemsByDeleteOne,
        isLoader
    } = props;
    const renderStep = (itemActive) => {
        switch (itemActive) {
            case 2:
                return <One
                tariff={tariff}
                setTariff={setTariff}
                dataSave={dataSaveOne}
                amount={amountOne}
                porcentaje={porcentajeOne}
                arrayItemsByDelete={arrayItemsByDeleteOne}
                setDataSaveOne={setDataSaveOne}
                setAmountOne={setAmountOne}
                setPorcentajeOne={setPorcentajeOne}
                setArrayItemsByDeleteOne={setArrayItemsByDeleteOne}
                isLoader={isLoader}
                />
            case 1:
                return <Three
                earning={earning}
                setEarning={setEarning}
                minAmount={minAmount}
                setMinAmount={setMinAmount}
                maxAmount={maxAmount}
                setMaxAmount={setMaxAmount}
                amount={amount}
                setAmount={setAmount}
                porcentaje={porcentaje}
                setPorcentaje={setPorcentaje}
                arrayItemsByDelete={arrayItemsByDelete}
                setArrayItemsByDelete={setArrayItemsByDelete}
                numeroArray={numeroArray}
                setNumeroArray={setNumeroArray}
                dataSave={dataSave}
                setDataSave={setDataSave}
                isLoader={isLoader}
                />
            default:
                return <Three
                earning={earning}
                setEarning={setEarning}
                minAmount={minAmount}
                setMinAmount={setMinAmount}
                maxAmount={maxAmount}
                setMaxAmount={setMaxAmount}
                amount={amount}
                setAmount={setAmount}
                porcentaje={porcentaje}
                setPorcentaje={setPorcentaje}
                arrayItemsByDelete={arrayItemsByDelete}
                setArrayItemsByDelete={setArrayItemsByDelete}
                numeroArray={numeroArray}
                setNumeroArray={setNumeroArray}
                dataSave={dataSave}
                setDataSave={setDataSave}
                isLoader={isLoader}
                />
        }
    }
    return (
        <RangosCss.cont>
            <RangosCss.NavbarRangos>
                <RangosCss.contBtnAndLabel onClick={(e) => {setItemActive(1); setTextStep('Rentabilidad')}}>
                    <RangosCss.btnNavbar active={itemActive === 1 ? active : off}>
                        1
                    </RangosCss.btnNavbar>
                    <RangosCss.textNavbar ml='10px' active={itemActive === 1 ? active : off} >
                        Rentabilidad
                    </RangosCss.textNavbar>
                </RangosCss.contBtnAndLabel>

                <RangosCss.contBtnAndLabel onClick={(e) => {setItemActive(2); setTextStep('Impuesto por arancel')}}>
                    <RangosCss.btnNavbar active={itemActive === 2 ? active : off}>
                        2
                    </RangosCss.btnNavbar>
                    <RangosCss.textNavbar ml='10px' active={itemActive === 2 ? active : off} >
                        Impuesto por arancel
                    </RangosCss.textNavbar>
                </RangosCss.contBtnAndLabel>

            </RangosCss.NavbarRangos>
            <RangosCss.contenRangos>
                <RangosCss.headerContent>
                    <RangosCss.text size="14pt" style={{ fontWeight: 'bolder' }}>
                        {textStep}
                    </RangosCss.text>
                </RangosCss.headerContent>
                <RangosCss.boydContent>
                    {renderStep(itemActive)}
                </RangosCss.boydContent>
            </RangosCss.contenRangos>
        </RangosCss.cont>
    )
}
