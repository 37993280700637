import styled from "styled-components";
const PaginationStyle = styled.div`
  .pagination li a {
    outline: none;
    box-shadow: none;
    &:active,
    &:focus,
    &:visited,
    &:active:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .page-item a {
    color: #5e548e !important;
  }
  .page-item.active .page-link {
    border: none;
    background: #7367f0 !important;
  }
  .p {
    background: white;
    color: #7367f0;
  }
`;
export default PaginationStyle;
