import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState, useEffect } from 'react'
import DefaultimgPostCss from '../../../../assets/css/components/admin/products/amazon/DefaultimgPostCss'
import { GlobalContext } from '../../../../contexts/GlobalContext';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
export default function ModalPublisInAnotherStore(props) {
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const globalContext = useContext(GlobalContext)
    const [isLoader, setIsLoader] = useState(false);
    const [brands, setBrands] = useState(null);
    const [brandSlected, setBrandSlected] = useState(null);
    const [mesagge, setMesagge] = useState('');
    const {active} = props;
    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
     const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if (!active) return;
        setIsLoader(true)
    
        axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-brand/${entId}`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
              "x-access-token": localStorage.getItem(keyAccessToken),
              "x-user-id": localStorage.getItem(keyUserId),
            },
          })
          .then((resp) => {
            setIsLoader(false)
            const brands = resp.data.data.brands;
            setBrands(brands);
          })
          .catch((err) => {
            handleAxiosError(err);
            setIsLoader(false);
        });
      }, [globalContext.currentEnterpriseId, active]);


    const closeModal = () => {
        globalContext.setItemsPublicOtherStore(null)
        globalContext.setActiveModalPublicOtherStore(false);
    }

    const sendData = async () => {
        const entId = globalContext.currentEnterpriseId;
        setIsLoader(true)
        await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/republish`,
            {
                prodIds: globalContext.itemsPublicOtherStore,
                officialStoreId: brandSlected
            },{
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            }).then(res => {
                setMesagge('Se Publicaron Correctamente!')
                setIsLoader(false)
            }).catch((err) => {
                setMesagge(err);
                setIsLoader(false)
            })
    }

    return (
        <DefaultimgPostCss.cont active={props.active}>
            <DefaultimgPostCss.modalImg>
                <DefaultimgPostCss.headerModal>
                    <DefaultimgPostCss.textHeader size='14pt' mr='auto'>
                        Seleccione la segunda imagen por defecto
                    </DefaultimgPostCss.textHeader>
                    <DefaultimgPostCss.btnCloseModal onClick={() => closeModal()}>
                        <FontAwesomeIcon icon={faTimes} />
                    </DefaultimgPostCss.btnCloseModal>
                </DefaultimgPostCss.headerModal>
                <DefaultimgPostCss.bodyModal>
                    {isLoader ? 
                    (
                        <DefaultimgPostCss.contLoader>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Cargando...</span>
                            </Spinner>
                        </DefaultimgPostCss.contLoader>
                    )
                    :
                    (
                        <>
                        <DefaultimgPostCss.contInputAndLabel>
                            <DefaultimgPostCss.text mb='10px'>
                                Tienda en donde publicar
                            </DefaultimgPostCss.text>
                            <DefaultimgPostCss.Select onChange={(e) => setBrandSlected(e.target.value)}>
                                <option>
                                    Seleccione una
                                </option>
                                {
                                    brands && 
                                        brands.map((b) => (
                                            <option key={b.id} value={b.id}>
                                                {b.name}
                                            </option>
                                            ))
                                }
                            </DefaultimgPostCss.Select>
                        </DefaultimgPostCss.contInputAndLabel>
                        {
                            (mesagge.length > 0) && 
                            (
                                <DefaultimgPostCss.contMesaggeSucces>
                                    <DefaultimgPostCss.text size="16pt" color="#7367F0">
                                        {mesagge}
                                    </DefaultimgPostCss.text>
                                </DefaultimgPostCss.contMesaggeSucces>
                            )
                        }
                        </>
                    )
                }
            </DefaultimgPostCss.bodyModal>
                <DefaultimgPostCss.footerModal>
                {
                    (brandSlected && globalContext.itemsPublicOtherStore) && (
                        <DefaultimgPostCss.btnActionFooter
                        onClick={() => sendData()}
                        bgc='#7367f0' 
                        cl='#fff' 
                        bc='#7367f0' 
                        >
                            Publicar
                        </DefaultimgPostCss.btnActionFooter>
                        
                    )
                }
                </DefaultimgPostCss.footerModal>
            </DefaultimgPostCss.modalImg>
        </DefaultimgPostCss.cont>
    )
}
