import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext, useEffect } from "react"
import LinksAnotherMenuCss from "../../../assets/css/components/admin/menus/LinksAnotherMenuCss";
import { GlobalContext } from "../../../contexts/GlobalContext";
import Routes from "../../../Routes";
import activeRouteV from "../../../assets/js/ActiveRoute";
import React from "react"
import { Link } from "react-router-dom";
import {
    userRole
} from "../../../assets/js/SessionStorageNames";
export default function LinksAnotherMenu() {
    const r = Routes;
    const globalContext = useContext(GlobalContext);

    const toggleSidebar = (v) => {
        globalContext.setStatusMenu(v)
    }

    useEffect(() => {
        const v_userRole = localStorage.getItem(userRole);

        if (!v_userRole || v_userRole === undefined) {
            globalContext.setVerifyLogin(true);
        } else {
            globalContext.setVerifyLogin(false);

        }
    }, [localStorage.getItem(userRole)]);
    return (
        <LinksAnotherMenuCss.cont>
            {
                r.map((l, i) => {
                    if (l.isFather) {
                        if (l.rolAccess.includes(localStorage.getItem(userRole))) {
                            if(!l.enterpriseBlock.find((v) => v === globalContext.currentEnterpriseId)) {
                                return (
                                    <Link key={i} to={l.layout + l.path} style={{ color: `${activeRouteV(l.layout + l.path) === true ? '#FFFFFF' : '#707070'}` }}>
                                        <LinksAnotherMenuCss.LinkSidebar active={activeRouteV(l.layout + l.path)} onClick={() => { toggleSidebar(false) }}>
                                            <span>
                                                <FontAwesomeIcon icon={l.icon} />
                                            </span>
                                            <p >{l.name}</p>
                                        </LinksAnotherMenuCss.LinkSidebar>
                                    </Link>
                                )
                            }
                        }
                    }
                })
            }
        </LinksAnotherMenuCss.cont>
    )
}
