import axios from 'axios'
import {keyAccessToken, keyUserId} from '../../../../../assets/js/SessionStorageNames'
export const getOdooCat = (enterpriseId, webIdl) => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo/get-odoo-web-categories`,
        {
            enterpriseId,
            webIdl
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export default getOdooCat;