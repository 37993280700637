import styled from 'styled-components';

const cont = styled.div`
width:100%;
display: flex;
justify-content:flex-start;
align-items: center;
background:#FFFFFF;
margin-top:15px;
border: 0.5px solid rgba(112, 112, 112, 0.65);
border-radius: 5px;
max-height: 400px;
overflow:auto;
@media (max-width: 800px) {
    flex-direction: column;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`;

const NavbarRangos = styled.div`
    border-right: 0.3px solid rgba(112, 112, 112, 0.50);
    width:350px;
    height:100%;
    padding:10px;
    position: sticky;
    top: 40px;
    bottom: 40px;
    background: #FFFFFF;
    @media (max-width: 800px) {
    width:100%;
    display:flex;
    justify-content: space-around;
    align-items:center;
    top:0;
    min-width:300px;
    width:100%;
    border-right:none;
    border-bottom: 0.3px solid rgba(112, 112, 112, 0.50);
}
`;

const contBtnAndLabel = styled.button`
display: flex;
justify-content:flex-start;
align-items: center;
background: transparent;
outline:none !important;
padding:10px;
border:none;

`;

const btnNavbar = styled.div`
outline: none !important;
border: none;
display:flex;
align-items:center;
justify-content:center;
border-radius:5px;
width:50px;
height:50px;
font-size:12pt;
font-weight: ${props=> props.active ? 'bolder' : 'lighter'};
color: ${props=> props.active ? '#ffffff' : '#707070'};
background: ${props=> props.active ? 'linear-gradient(#7367f0 0%, rgba(115, 103, 240, 0.7) 100%)' : '#DDDAF2'};
box-shadow: ${props=> props.active ? '2px 0px 6px #7367f0' : 'none'};
transition: all ease-in-out 0.2s;
`;

const btn = styled.button`
padding:${props => props.pd || "8px 16px"};
display:flex;
justify-content:center;
align-items:center;
font-size:${props => props.fontSize || "12pt"};
outline:none !important;
color: ${props => props.colorFont || "#7364060"};
background-color: ${props => props.bgColor || "#7364060"};
width: ${props => props.w || "auto"};
height: ${props => props.h || "auto"};
border:none;
border-radius: 5px;
margin-top: ${props=> props.mt || '0'};
`;

const text = styled.p`
margin-bottom:  ${props=> props.mb || '0'};
color: ${props=> props.active ? '#7367f0' : '#707070'};
font-size: ${props=> props.size || '13pt'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
font-weight: ${props=> props.active ? 'bolder' : 'lighter'};
margin-top: ${props=> props.mt || '0'};
text-align: start;
transition: all ease-in-out 0.2s;
display: block;
`;

const textNavbar = styled.p`
margin-bottom:  ${props=> props.mb || '0'};
color: ${props=> props.active ? '#7367f0' : '#707070'};
font-size: ${props=> props.size || '13pt'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
font-weight: ${props=> props.active ? 'bolder' : 'lighter'};
margin-top: ${props=> props.mt || '0'};
text-align: start;
transition: all ease-in-out 0.2s;
display: block;
@media (max-width: 650px) {
        display: none;
    }
`;

const contenRangos = styled.div`
width:100%;
padding:10px;
height: 100%;
display:flex;
flex-direction:column;
justify-content:flex-start;
align-items:center;
align-self:flex-start;
`;
const headerContent = styled.div`
width:100%;
text-align:left;
`;
const boydContent = styled.div`
width:100%;
height:100%;
`;
const contInputRadio = styled.div`
display: flex;
align-items:center;
width:100%;
justify-content: flex-start;
margin-bottom:10px;
`;
const inputRadio = styled.input`

@supports(-webkit-appearance: none) or (-moz-appearance: none) {

  --active: #7367f0;
  --active-inner: #fff;
  --focus: 2px rgba(39, 94, 254, .3);
  --border: #7367f0;
  --border-hover: #7367f0;
  --background: #fff;
  --disabled: #F6F8FF;
  --disabled-inner: #E1E6F9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background .3s, border-color .3s, box-shadow .2s;
  margin-left: ${props => props.ml || '0'};
  margin-right: ${props => props.mr || '0'};
  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
  }
  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }
  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: .9;
    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
    & + label {
      cursor: not-allowed;
    }
  }
  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }
  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  &:not(.switch) {
    width: 21px;
    &:after {
      opacity: var(--o, 0);
    }
    &:checked {
      --o: 1;
    }
  }
  & + label {
    font-size: 12pt;
    line-height: 14px;
    display: inline-block;
    vertical-align: inherit;
    cursor: pointer;
    margin-left: 5px;
    color: #707070;
    margin-bottom: 0 !important;
  }


  border-radius: 50%;
  &:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, .7));
  }
  &:checked {
    --s: .5;
  }
}
`;
const RangosCss = {
    cont,
    NavbarRangos,
    contenRangos,
    btnNavbar,
    text,
    contBtnAndLabel,
    headerContent,
    boydContent,
    textNavbar,
    btn,
    contInputRadio,
    inputRadio
}
export default RangosCss;