import React, { useState, useEffect } from 'react';
import MultiScraperStyles from "../../../../assets/css/components/admin/products/amazon/MultiScraperStyles";
import imgTest from "../../../../assets/img/png/test2.jpg"
import {
    amazonCategoryText
} from "../../../../assets/js/SessionStorageNames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faDownload, faTimes, faDotCircle, faTasks, faGripHorizontal, faGripLines } from '@fortawesome/free-solid-svg-icons';
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom';
import verifyAccessRole from '../../../../assets/js/verifyAccessRole';
import Routes from '../../../../Routes';
function ListProductsCbt(props) {
    const [redirect, setRedirect] = useState(null);
    const [multiSave, setMultiSave] = useState([]);
    const [itemsAdds, setItemsAdds] = useState([]);
    const [selectAllItems, setSelectAllItems] = useState(false);
    const [styleGridCard, setStyleGridCard] = useState(1);
    const [changeToRow, setChangeToRow] = useState(false);

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 35);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);

    const test = []
    for (var i = 0; i < 40; i++) {
        test.push(i);
    }
    const addItem = (item, itemIndex) => {
        const isHere = itemsAdds.find((e) => (e === itemIndex));
        if (isHere === undefined) {
            setMultiSave([...multiSave, item]);
            setItemsAdds([...itemsAdds, itemIndex])
        } else {
            setMultiSave(multiSave.filter((e) => (e !== itemIndex)))
            setItemsAdds(itemsAdds.filter((e) => (e !== itemIndex)))
        }
    }
    const selectAll = (item, itemIndex) => {
        setSelectAllItems(true)
        setMultiSave(item);
        setItemsAdds(itemIndex);
    }
    const desSelectedAll = () => {
        setSelectAllItems(false)
        setMultiSave([]);
        setItemsAdds([]);
    }

    const changeStyledGridToRow = () => {
        setChangeToRow(true);
        setStyleGridCard(2);
    }
    const changeStyledGridToColunm = () => {
        setChangeToRow(false);
        setStyleGridCard(1);
    }

    return (
        <>
        {redirect}
            <MultiScraperStyles.header>
                {localStorage.getItem(amazonCategoryText)}
            </MultiScraperStyles.header>
            <MultiScraperStyles.listProducts>
                <MultiScraperStyles.divContFilters>
                    <MultiScraperStyles.inputOne placeholder="Cantidad de productos" type="number" />
                    <MultiScraperStyles.inputOne placeholder="Filtrar por descripcion...." mt='10px' />
                    {itemsAdds.length > 0 && (
                        <>
                            <MultiScraperStyles.btnRedirect mt="10px" wd="100%">
                                Publicar: {itemsAdds.length} productos
                        </MultiScraperStyles.btnRedirect>
                        </>
                    )}
                </MultiScraperStyles.divContFilters>
                <MultiScraperStyles.divContData>
                    <div className="header">
                        <MultiScraperStyles.btnRedirect mr="10px">
                            <FontAwesomeIcon icon={faDownload} />
                        </MultiScraperStyles.btnRedirect>
                        <div className="cont">
                            <MultiScraperStyles.inputOne placeholder="Escriba la palabra por la q desea buscar" />
                            <MultiScraperStyles.btnFloat>
                                <FontAwesomeIcon icon={faSearch} />
                            </MultiScraperStyles.btnFloat>
                        </div>
                        <div className="cont-btn-header">
                        <Link to="list-products-cbt">
                            <MultiScraperStyles.btnRedirect ml="10px">
                                Individual
                        </MultiScraperStyles.btnRedirect>
                        </Link>
                        {
                            test !== null ?
                                !selectAllItems ?
                                    (
                                        <MultiScraperStyles.btnRedirect ml="10px" onClick={() => selectAll(test, test)} id="activeAll">
                                            <FontAwesomeIcon icon={faTasks} />
                                        </MultiScraperStyles.btnRedirect>
                                    ) : (
                                        <MultiScraperStyles.btnRedirect ml="10px" onClick={() => desSelectedAll()}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </MultiScraperStyles.btnRedirect>
                                    ) : ''
                        }
                        <MultiScraperStyles.btnRedirect ml="10px" 
                        bg={styleGridCard === 1 ? true : false } 
                        color={styleGridCard === 1 ? true : false } 
                        onClick={() => changeStyledGridToColunm()}>
                                <FontAwesomeIcon icon={faGripHorizontal} />
                        </MultiScraperStyles.btnRedirect>
                        <MultiScraperStyles.btnRedirect ml="10px" 
                        color={styleGridCard === 2 ? true : false } 
                        bg={styleGridCard === 2 ? true : false } 
                        onClick={() => changeStyledGridToRow()}>
                            <FontAwesomeIcon icon={faGripLines} />
                        </MultiScraperStyles.btnRedirect>
                        </div>
                    </div>
                    <MultiScraperStyles.gridData changeToRow={changeToRow}>

                        {
                            test.map((t) => {
                                return (
                                    <MultiScraperStyles.cardsGrid key={t} changeToRow={changeToRow}>
                                        <MultiScraperStyles.contImgCard changeToRow={changeToRow}>
                                            <input
                                                className="inp-cbx"
                                                style={{ display: 'none' }}
                                                id={`cbx${t}`}
                                                type="checkbox"
                                                onChange={() => { addItem(t, t) }}
                                                checked={itemsAdds.includes(t)}
                                                defaultChecked={itemsAdds.includes(t)}
                                            />
                                            <label className="cbx" htmlFor={`cbx${t}`}>
                                                <span>
                                                    <svg width="12px" height="10px" viewBox="0 0 12 10">
                                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                            <MultiScraperStyles.imgCard url={imgTest} changeToRow={changeToRow}  />
                                            <div className="cont-dots">
                                                <FontAwesomeIcon icon={faDotCircle} className="dot-one" style={{ color: "#46F313" }} />
                                                <FontAwesomeIcon icon={faDotCircle} className="dot-two" style={{ color: "#7367F0" }} />
                                            </div>
                                        </MultiScraperStyles.contImgCard>
                                        <MultiScraperStyles.dataCardText changeToRow={changeToRow}>
                                           {!changeToRow && (
                                            <div className="price">
                                                $12212
                                            </div>
                                           )}
                                            <MultiScraperStyles.titleDataCardtext changeToRow={changeToRow}>
                                                Hanes Sport Men's X-Temp Performance Training Pant with Pockets Hanes Sport Men's X-Temp Performance Training Pant with Pockets
                                        </MultiScraperStyles.titleDataCardtext>
                                        <MultiScraperStyles.preciosCardText>
                                            <div className="precio-oferta">
                                                <p>Precio de oferta -</p>
                                                <p>$121.2</p>
                                            </div>
                                            <div className="precio-old">
                                                <p>Precio antiguo -</p>
                                                <p>$100</p>
                                            </div>
                                        </MultiScraperStyles.preciosCardText>
                                        </MultiScraperStyles.dataCardText>
                                        <MultiScraperStyles.contBtnCardActions changeToRow={changeToRow}>
                                            {changeToRow && (
                                            <div className="price">
                                                $12212
                                            </div>
                                           )}
                                            <MultiScraperStyles.btnActionsCard brbl="5px" colorFont="#707070" bgColor="#F5F5F6" changeToRow={changeToRow}>
                                                Crear producto
                                        </MultiScraperStyles.btnActionsCard>
                                            <MultiScraperStyles.btnActionsCard brbr="5px" colorFont="#ffffff" bgColor="#7367F0" changeToRow={changeToRow}>
                                                Publicar
                                        </MultiScraperStyles.btnActionsCard>
                                        </MultiScraperStyles.contBtnCardActions>
                                    </MultiScraperStyles.cardsGrid>
                                )
                            })
                        }

                    </MultiScraperStyles.gridData>
                </MultiScraperStyles.divContData>

            </MultiScraperStyles.listProducts>
        </>
    );
}

export default ListProductsCbt;