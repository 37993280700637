import styled from 'styled-components';

const cont = styled.div`
width: 100%;
display: flex;
justify-content:flex-start;
align-items:  center;
flex-direction: column;
height: 100%;
background: #FFFFFF;
box-shadow: 0 4px 25px 0 rgb(34 41 47 / 10%);
border-radius: .5rem;
`;
const header = styled.div`
width:100%;
height: 60px;
background:#FFFF;
margin-top:0;
padding:10px;
display:flex;
justify-content: left;
align-items:center;
`;
const body = styled.div`
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
min-height: 500px;
padding:10px;
`;

const footer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
padding: 0 15px;
align-items: center;
height: 60px;
`;

const text = styled.p`
margin-bottom:  ${props=> props.mb || '0'};;
color: ${props=> props.cl || '#707070'};
font-size: ${props=> props.size || '12pt'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
font-weight: ${props=> props.fw || 'lighter'};
margin-top: ${props=> props.mt || '0'};
transition: all ease-in-out 0.2s;

`;

const input = styled.input`
    margin-right:10px;
    font-family: "Berlin Rounded";
    width: 100%;
    height: 38px;
    background: #fff;
    outline: none !important;
    color: rgba(112, 112, 112, 0.61);
    font-size: 12pt;
    margin-left: ${props=> props.ml || '0'};
    font-weight: 300;
    padding: .438rem 1rem;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: .357rem;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
        }
`;

const gridData = styled.div`
margin-bottom:10px;
width:100%;
display: grid;
grid-gap: 0.5rem;
padding: 10px 0;
grid-template-columns: repeat(auto-fill, minmax(auto, 140px));
border-radius: .5rem;
background: #f5f5f6;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`;
const contRow = styled.div`
display: flex;
justify-content: ${props=> props.jc || 'flex-start'};
align-items: ${props=> props.ai || 'center'};
flex-direction: ${props=> props.fd || 'row'};
width: ${props=> props.w || '100%'};
padding: ${props=> props.pd || 'none'};
overflow-y: ${props=> props.ov || ''};
`;

const contLoader = styled.div`
width: 100%;
height: 100%;
margin-top: auto;
margin-bottom: auto;
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
.spinner-border {
    border: .25em solid #7367f0;
    border-right-color: transparent;
}
`;

const contImage = styled.div`
background-image: url(${props=> props.img ? '"'+`${props.img}`+'"' : ""});
min-width: ${props => props.minw || '140px'};
max-width: ${props => props.maxw || '140px'};
background-repeat: no-repeat;
background-size: contain;
background-color: #7367F0;
min-height: ${props => props.minh || '140px'};
max-height: ${props => props.maxh || '140px'};
background-position: center;
margin-left: 5px;
margin-right:5px;
border:  ${props => props.active ? '1px solid #7367F0' : '1px solid #707070'};
border-radius: .5rem;
box-sizing:border-box;
box-shadow: ${props => props.active ? '1px 1px 7px 0px #7367f0' : '0px 0px 0px transparent'};
transition: all ease-in-out 0.3s;
&:hover {
    cursor: pointer;
}
`;
const btnBack = styled.button`
outline: none !important;
padding: 8px 16px;
border: 1px solid #707070;
border-radius: .5rem;
color: #707070;
font-size: 12pt;
text-align: center;
`;

const labelInput = styled.div`
display: flex;
flex-direction:column;
align-items: flex-start;
justify-content:flex-start;
min-width: 300px;
`;
const gridDataLabels = styled.div`
margin-bottom:10px;
width:100%;
display: grid;
grid-gap: 0.5rem;
padding: 10px;
grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`;

const SendDataCss = {
    cont,
    header,
    body,
    footer,
    text,
    input,
    gridData,
    contRow,
    contLoader,
    contImage,
    btnBack,
    labelInput,
    gridDataLabels
}

export default SendDataCss;