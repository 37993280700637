import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
const activePublication = async (entId, idProduc) => {
    const res = axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/activate/${idProduc}`, {}, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}
export default activePublication;