import axios from "axios";

const handleAxiosError = (err, msg = null, consoleLog = true, globalContext) => {
    if (axios.isCancel(err)) return; //Don't show axios cancel error
    if (err.response && err.response.data) {
        if (err.response.data.message) {
            globalContext.showModalWithMsg(err.response.data.message);
        } else {
            globalContext.showModalWithMsg(err.response.data);
        }
    } else if (msg) {
        globalContext.showModalWithMsg(msg);
    }
    if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
};

export default handleAxiosError;