import React, { useState } from "react";
import DropDownButtonStyle from "../../../../assets/css/components/admin/products/DropDownButton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle, faFilter } from "@fortawesome/free-solid-svg-icons";

export default function DropDownButtonOptionsFilter(props) {
  const [openDropContent, setOpenDropContent] = useState(false);
  const [changeIcon, setChangeIcon] = useState(false);

  /**
   * Update the filters
   * @param {Event} e The event
   * @param {Object} data The new filter data
   */
  const updateFilters = (e, data) => {
    e.preventDefault();
    props.updateFilters(data);
  }

  const changeStatusDorpFocus = (e) => {
    e.preventDefault();
    setOpenDropContent(!openDropContent);
    setChangeIcon(!changeIcon);
  };
  const changeStatusDorpBlur = (e) => {
    e.preventDefault();
    setOpenDropContent(false);
    setChangeIcon(false);
  };
  const iconActive = (e, index) => {
    e.preventDefault();
  };

  return (
    <DropDownButtonStyle>
      <button
        className={`DropBtn ${changeIcon ? "open" : ""}`}
        onClick={ changeStatusDorpFocus }
        onBlur={ changeStatusDorpBlur }
      >
        <div className="content-text-icon">
          <div className="content-text">
            <p>Filtrar por</p>
          </div>
          <div className="content-icon">
            <span className="icon">
              <span
                className={`rectangle rectangle1 ${changeIcon ? "open" : ""}`}
              />
              <span
                className={`rectangle rectangle2 ${changeIcon ? "open" : ""}`}
              />
            </span>
          </div>
        </div>
        <div className={`optionsDrop ${changeIcon ? "open" : ""}`}>
          <div
            className={`items item`}
            onClick={(e) => {
              updateFilters(e, {
                "filterLinkedTo": "",
                "filterPrice": "",
                "filterStock": "",
                "filterWithCompany": null,
              });
              iconActive(e);
            }}
            key="1"
          >
            <span className="ic">
              <FontAwesomeIcon icon={faFilter} />
            </span>
            <p>Todos los productos</p>
            <span
              className={`icon-active  ${
                props.filterLinkedTo === "" &&
                  props.filterPrice === "" &&
                  props.filterStock === "" &&
                  props.filterWithCompany === null
                  ? "open"
                  : ""
                }`}
            >
              <FontAwesomeIcon icon={faDotCircle} />
            </span>
          </div>
          <div
            className={`items item ${
              props.filterLinkedTo === "linked" ? "hidden" : ""
              }`}
            onClick={(e) => {
              updateFilters(e, { "filterLinkedTo": "linked" });
              iconActive(e);
            }}
            key="2"
          >
            <span className="ic">
              <FontAwesomeIcon icon={faFilter} />
            </span>
            <p>prod sincronizados</p>

          </div>
          <div
            className={`items item ${
              props.filterLinkedTo === "unlinked" ? "hidden" : ""
              }`}
            onClick={(e) => {
              updateFilters(e, { "filterLinkedTo": "unlinked" });
              iconActive(e);
            }}
            key="3"
          >
            <span className="ic">
              <FontAwesomeIcon icon={faFilter} />
            </span>
            <p>prod no sincronizados</p>

          </div>
          <div
            className={`items item ${
              props.filterPrice === "=0" ? "hidden" : ""
              }`}
            onClick={(e) => {
              updateFilters(e, { "filterPrice": "=0" });
              iconActive(e);
            }}
            key="4"
          >
            <span className="ic">
              <FontAwesomeIcon icon={faFilter} />
            </span>
            <p>Prod con precio 0</p>

          </div>
          <div
            className={`items item ${
              props.filterPrice === ">0" ? "hidden" : ""
              }`}
            onClick={(e) => {
              updateFilters(e, { "filterPrice": ">0" });
              iconActive(e);
            }}
            key="5"
          >
            <span className="ic">
              <FontAwesomeIcon icon={faFilter} />
            </span>
            <p>Prod con precio diferente de 0</p>

          </div>
          <div
            className={`items item ${
              props.filterStock === "=0" ? "hidden" : ""
              }`}
            onClick={(e) => {
              updateFilters(e, { "filterStock": "=0" });
              iconActive(e);
            }}
            key="6"
          >
            <span className="ic">
              <FontAwesomeIcon icon={faFilter} />
            </span>
            <p>Prod con stock 0</p>
            <span
              className={`icon-active  ${
                props.filterStock === "=0" ? "open" : ""
                }`}
            >
              <FontAwesomeIcon icon={faDotCircle} />
            </span>
          </div>
          <div
            className={`items item ${
              props.filterStock === ">0" ? "hidden" : ""
              }`}
            onClick={(e) => {
              updateFilters(e, { "filterStock": ">0" });
              iconActive(e);
            }}
            key="7"
          >
            <span className="ic">
              <FontAwesomeIcon icon={faFilter} />
            </span>
            <p>Prod con stock diferente de 0</p>

          </div>
          {props.currentPlugin === "odoo" && (
            <div
              className={`items item ${
                props.filterWithCompany === true ? "hidden" : ""
                }`}
              onClick={(e) => {
                updateFilters(e, { "filterWithCompany": true });
                iconActive(e);
              }}
              key="8"
            >
              <span className="ic">
                <FontAwesomeIcon icon={faFilter} />
              </span>
              <p>Prod con empresa</p>

            </div>
          )}
          {props.currentPlugin === "odoo" && (
            <div
              className={`items item ${
                props.filterWithCompany === false ? "hidden" : ""
                }`}
              onClick={(e) => {
                updateFilters(e, { "filterWithCompany": false });
                iconActive(e);
              }}
              key="9"
            >
              <span className="ic">
                <FontAwesomeIcon icon={faFilter} />
              </span>
              <p>Prod sin empresa</p>

            </div>
          )}
          {props.currentPlugin === "odoo" && (
            <div
              className={`items item ${
                props.filterWithTaxes === true ? "hidden" : ""
                }`}
              onClick={(e) => {
                updateFilters(e, { "filterWithTaxes": true });
                iconActive(e);
              }}
              key="10"
            >
              <span className="ic">
                <FontAwesomeIcon icon={faFilter} />
              </span>
              <p>Prod con impuestos</p>

            </div>
          )}
          {props.currentPlugin === "odoo" && (
            <div
              className={`items item ${
                props.filterWithTaxes === false ? "hidden" : ""
                }`}
              onClick={(e) => {
                updateFilters(e, { "filterWithTaxes": false });
                iconActive(e);
              }}
              key="11"
            >
              <span className="ic">
                <FontAwesomeIcon icon={faFilter} />
              </span>
              <p>Prod sin impuestos</p>
            </div>
          )}
          {props.currentPlugin === "ml" && (
            <div
              className={`items item ${
                props.amazonMaxManufacturingTime ? "hidden" : ""
                }`}
              onClick={(e) => {
                updateFilters(e, { "amazonMaxManufacturingTime": true });
                iconActive(e);
              }}
              key="11"
            >
              <span className="ic">
                <FontAwesomeIcon icon={faFilter} />
              </span>
              <p>Tiempo de envio menor a 6 Dias</p>
            </div>
          )}
           {props.currentPlugin === "ml" && (
             <div
            className={`items item ${
              props.onCbtMarketplaces === true ? "hidden" : ""
              }`}
              onClick={(e) => {
                updateFilters(e, { "onCbtMarketplaces": true });
                iconActive(e);
              }}
              key="12"
          >
            <span className="ic">
              <FontAwesomeIcon icon={faFilter} />
            </span>
            <p>Productos Publicados</p>

          </div>
          )}
            {props.currentPlugin === "ml" && (
             <div
            className={`items item ${
              props.onCbtMarketplaces === false ? "hidden" : ""
              }`}
              onClick={(e) => {
                updateFilters(e, { "onCbtMarketplaces": false });
                iconActive(e);
              }}
              key="13"
          >
            <span className="ic">
              <FontAwesomeIcon icon={faFilter} />
            </span>
            <p>Productos no publicados</p>

          </div>
          )}
        </div>
      </button>
    </DropDownButtonStyle>
  );
}
