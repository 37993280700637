import React, {useContext, useState} from 'react'
import { ContGridAndCards, ContGridCards, ContIconInputSearch, ContInputSearch, InputSearch, Text } from '../../../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts';
import { BiSearch } from "react-icons/bi";
import CardOdooProd from './cards/odoo/CardOdooProd';
import CardLoad from './cards/general/CardLoad';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import CardMlProd from './cards/ml/CardMlProd';
import CardPyProd from './cards/pedidos-ya/CardPyProd';
import CardAnicamProd from './cards/anican/CardAnicamProd';

export default function ContGrid({listProd, totalProducts, load, pluginSearch, setFilter}) {
    const globalContext = useContext(GlobalContext);
    const [filterValue, setFilterValue] = useState('')
    
    const addItemToSend = (data) => {
        let copyArr = [...globalContext.listProductsToSend];
        //? buscamos el valor a ver si ya existe
        const existId = copyArr.find(v => v._id === data._id);
        if (existId) {
            const newData = copyArr.filter(v => v._id !== data._id);
            globalContext.setListProductsToSend(newData);
        }else {
            copyArr.push(data);
            globalContext.setListProductsToSend(copyArr);
        }
    }

    const setFilterValueFunc = (e) => {
        e.preventDefault();
        if(filterValue.length > 0) {
            setFilter(filterValue)
        }else {
            return
        }
    }

    const renderCardByPlugin = (pluginSearch, v, globalContext, addItemToSend) => {
        switch (pluginSearch) {
            case 'odoo':
                return (
                    <CardOdooProd
                        pluginSearch={pluginSearch}
                        key={v._id}
                        active={ globalContext.listProductsToSend.find(vs => vs._id === v._id ) }
                        data={v}
                        addItemToSend={addItemToSend}
                    />
                );
            case 'ml':
                return (
                    <CardMlProd
                        pluginSearch={pluginSearch}
                        key={v._id}
                        active={ globalContext.listProductsToSend.find(vs => vs._id === v._id ) }
                        data={v}
                        addItemToSend={addItemToSend} 
                />
                )
            case 'py':
                return (
                    <CardPyProd 
                        pluginSearch={pluginSearch}
                        key={v._id}
                        active={ globalContext.listProductsToSend.find(vs => vs._id === v._id ) }
                        data={v}
                        addItemToSend={addItemToSend} 
                    />
                )
            case 'anicam':
                return (
                    <CardAnicamProd 
                        pluginSearch={pluginSearch}
                        key={v._id}
                        active={ globalContext.listProductsToSend.find(vs => vs._id === v._id ) }
                        data={v}
                        addItemToSend={addItemToSend} 
                    />
                )
            default:
                break;
        }
    }
    
    return (
        <ContGridAndCards>
            <Text size="14pt" fw="500" cl="#5e5873" style={{alignSelf: 'flex-start'}} mb="1rem">{`Total de resultados: ${totalProducts}`}</Text>
            <ContInputSearch onSubmit={(e) => setFilterValueFunc(e)}>
                <InputSearch placeholder='Que desea buscar?' onChange={(e) => setFilterValue(e.target.value)} disabled={load} />
                <ContIconInputSearch>
                    <BiSearch />
                </ContIconInputSearch>
            </ContInputSearch>

            <ContGridCards>
                {
                    (listProd.length > 0 && !load) ?
                    listProd.map(v => renderCardByPlugin(pluginSearch, v, globalContext, addItemToSend))
                    :
                    (
                        <>
                            <CardLoad />
                            <CardLoad />
                            <CardLoad />
                            <CardLoad />
                            <CardLoad />
                            <CardLoad />
                        </>
                    )
                }
            </ContGridCards>
        </ContGridAndCards>
    )
}
