import React, {useRef, useState, useContext} from 'react'
import QuestionsListStyle from '../../../../assets/css/components/admin/ml-questions/QuestionsListStyle'
import { FiSend } from 'react-icons/fi';
import { AiOutlineClockCircle } from 'react-icons/ai'
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { RiCheckDoubleFill } from 'react-icons/ri';
export default function ChatQuestions(props) {
    const [textSend, setTextSend] = useState('');
    const [loadMsg, setLoadMesage] = useState(true);
    const {data, isGetproduct, indexChange, dataQuestions, setDataQuestions} = props;
    const resTextSend = useRef(null);
    const globalContext = useContext(GlobalContext);

    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const getQuestions = async (text, questionId) => {
        const entId = globalContext.currentEnterpriseId;
        const resp = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/v1/ml-question/${entId}/answer/${questionId}`,
            {
                answer: text
            },
            {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                }
            }
        );
        return resp;
    };
    const sendAnwser = () => {
        setLoadMesage(false);
        if(textSend.length > 0) {
            let dataCopy = [...dataQuestions];
            dataCopy[indexChange].answerText = textSend;
            
            setDataQuestions(dataCopy);
            getQuestions(textSend, dataCopy[indexChange]._id)
            .then((res) => {
                console.log(res)
                setTextSend('');
                setLoadMesage(true)
                resTextSend.current.value = '';
            }).catch((err) => {
                handleAxiosError(err);
                setLoadMesage(false);
            })
            
        } 
    } 
    return (
        <QuestionsListStyle.contChatQuestions>
            <QuestionsListStyle.loadDataMl isLoad={isGetproduct}>
                <Spinner animation="border" role="status" size="md">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </QuestionsListStyle.loadDataMl>
            <QuestionsListStyle.contTextChat>
                { data &&
                    (
                        <QuestionsListStyle.textChat isQuestion={true}>
                            {data.text}
                        </QuestionsListStyle.textChat>
                    )
                }
                {
                    (data) &&
                    (data.answerText) &&
                    (
                        <QuestionsListStyle.textChat isQuestion={false}>
                            {data.answerText}
                            <QuestionsListStyle.iconSending>
                                {
                                    loadMsg ?
                                    (
                                        <RiCheckDoubleFill />
                                    )
                                    :
                                    (
                                        <AiOutlineClockCircle />
                                    )
                                }
                                
                            </QuestionsListStyle.iconSending>
                        </QuestionsListStyle.textChat>
                    )
                }
            </QuestionsListStyle.contTextChat>
            <QuestionsListStyle.contInput>
                <QuestionsListStyle.input
                    ref={resTextSend}
                    value={textSend}
                    placeholder="..."
                    readOnly={data && data.status === 'ANSWERED'}
                    onChange={(e) => setTextSend(e.target.value)} />
                <QuestionsListStyle.btnSendAnwser onClick={() => sendAnwser()} disabled={textSend.length === 0}>
                    <FiSend />
                </QuestionsListStyle.btnSendAnwser>
            </QuestionsListStyle.contInput>
        </QuestionsListStyle.contChatQuestions>
    )
}
