import React from 'react'
import PublishProduct from "../../../../../assets/css/components/admin/products/amazon/ModalPublishProduct";
export default function Succes(props) {
    const {title, peso, descripcion, tiendaText, listingTypeText} = props;
    return (
        <PublishProduct.contSuccess>
            
            <div className='text-left'>
                <PublishProduct.textBody size='12pt' mr='3px'>
                    Titulo del producto:
                </PublishProduct.textBody>
                <PublishProduct.textBody cl='#7367F0' size='12pt' fw='600'>
                    {title}
                </PublishProduct.textBody>
            </div>
            <div className='text-left'>
                <PublishProduct.textBody size='12pt' mr='3px'>
                    Tienda oficial:
                </PublishProduct.textBody>
                <PublishProduct.textBody cl='#7367F0' size='12pt' fw='600'>
                    {tiendaText}
                </PublishProduct.textBody>
            </div>
            <div className='text-left'>
                <PublishProduct.textBody size='12pt' mr='3px'>
                    Tipo de la publicacion:
                </PublishProduct.textBody>
                <PublishProduct.textBody cl='#7367F0' size='12pt' fw='600'>
                    {listingTypeText}
                </PublishProduct.textBody>
            </div>
            <div className='text-left'>
                <PublishProduct.textBody size='12pt' mr='3px'>
                    Peso en libras:
                </PublishProduct.textBody>
                <PublishProduct.textBody cl='#7367F0' size='12pt' fw='600'>
                    {peso}(Libras)
                </PublishProduct.textBody>
            </div>
            <div className='text-left'>
                <PublishProduct.textBody size='12pt' mr='3px'>
                    Descripcion:
                </PublishProduct.textBody>
                <PublishProduct.textArea readOnly hg='500px' bdgc='transparent'  size='12pt' fw='600' defaultValue={descripcion}/>
            </div>
        </PublishProduct.contSuccess>
    )
}
