import styled from "styled-components";

const cont = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 10px;
  position: relative;
  @media screen and (max-width: 990px) {
    align-items: center;
    justify-content: center;
    padding: 0;
    padding-bottom: 0;
    padding-top: 0;
    padding-right: 0;
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
`;
const header = styled.div`
  width: 100%;
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  @media screen and (max-width: 990px) {
    display: none;
  }
`;
const text = styled.p`
  margin-bottom: ${(props) => props.mb || "0"};
  color: ${(props) => props.cl || "#707070"};
  font-size: ${(props) => props.size || "12px"};
  margin-left: ${(props) => props.ml || "0"};
  margin-right: ${(props) => props.mr || "0"};
  font-weight: ${(props) => props.fw || "lighter"};
  margin-top: ${(props) => props.mt || "0"};
`;
const contListQuestions = styled.div`
  width: 100%;
  align-self: center;
  border-radius: 0.428rem;
  margin-top: 10px;
  min-height: 700px;
  max-height: 700px;
  background-color: white;
  border: 1px solid rgb(112 112 112 / 32%);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  @media screen and (max-width: 990px) {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    /* min-height:auto;
    max-height: 100%;
    position: absolute;
    top:0;
    bottom: 0; */
    border: none;
    /* border-bottom: 1px solid red;
    border-top: 1px solid red; */
    border-radius: 0px;
  }
`;

const listQuestions = styled.div`
  min-width: 375px;
  max-width: 377px;
  min-height: 700px;
  max-height: 700px;
  overflow-y: auto;
  border-top-left-radius: 0.428rem;
  border-bottom-left-radius: 0.428rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  transition: all ease-in-out 0.2s;
  z-index: 99;
  padding: 10px;
  /* width */
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 50px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #7367f0;
  }
  @media screen and (max-width: 990px) {
    width: 63%;
    min-height: auto;
    max-height: none;
    height: auto;
    position: absolute;
    top: 5px;
    bottom: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    background: white;
    border-radius: 0.428rem;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    transform: ${(props) =>
        props.activeList ? "translateX(5px)" : "translateX(-600px)"};
  }
`;

const contInfoProduct = styled.div`
  min-width: 300px;
  width: 35%;
  min-height: 700px;
  max-height: 700px;
  overflow-y: auto;
  border-top-left-radius: 0.428rem;
  border-bottom-left-radius: 0.428rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  transition: all ease-in-out 0.2s;
  z-index: 99;
  padding: 10px;
  /* width */
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 50px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #7367f0;
  }
  @media screen and (max-width: 990px) {
    width: 30%;
    min-height: auto;
    max-height: none;
    height: auto;
    position: absolute;
    top: 5px;
    bottom: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    background: white;
    border-radius: 0.428rem;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    right: ${props=> props.activeList ? '10px' : '-320px'};
  }
`;
const contChatQuestions = styled.div`
  background-image: url("/img-questions.png");
  background-repeat: repeat;
  background-size: 300px;
  background-color: #f5f5f6;
  width: 100%;
  min-height: 700px;
  max-height: 700px;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.alig || 'flex-start'};
  justify-content:flex-end;
  position: relative;
  @media screen and (max-width: 990px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }
`;

const btnShowMenus = styled.button`
  width: 30px;
  height: 30px;
  border-bottom-left-radius: ${(props) => props.blr || "0"};
  border-bottom-right-radius: ${(props) => props.brr || "0"};
  border: none;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  outline: none !important;
  justify-content: center;
  align-items: center;
  background: white;
  position: absolute;
  left: ${(props) => props.left || "0"};
  top: 0;
  bottom: auto;
  right: ${(props) => props.right || "0"};
  display: ${(props) => props.display || "none"};
  color: #7367f0;
  z-index: 99;
  @media screen and (max-width: 990px) {
    display: flex;
  }
`;

const cardQuestionCont = styled.div`
  width: 100%;
  background-color: #f5f5f6;
  border-radius: 0.428rem;
  min-height: 70px;
  max-height: 70px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  position: relative;
  transition: all ease-in 0.2s;
`;

const cardQuestionTextAndStatus = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: center;
  .cont-text {
    width: 100%;
    height: 33px;
    max-height: 33px;
    text-align: initial;
    display: block;
    display: -webkit-box;
    line-height: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: lighter;
    color: #707070;
    font-size: 12pt;
  }
`;

const isFocusAnyCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  width: 25px;
  height: 25px;
  border-top-left-radius: 0.428rem;
  border-bottom-right-radius: 50%;
  background: #7367f0;
  color: white;
  display: ${(props) => (props.active ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;
const isAnswer = styled.div`
  position: absolute;
  top: auto;
  left: auto;
  right: 0;
  bottom: 0;
  width: 25px;
  height: 25px;
  border-bottom-right-radius: 0.428rem;
  border-top-left-radius: 50%;
  background: #7367f0;
  color: white;
  display: ${(props) => (props.active ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const contIconCheckmar = styled.span`
  width: 40px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #707070;
`;

const contInput = styled.div`
  width: 100%;
  height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  margin-bottom: 0;
  align-self: flex-end;
`;

const input = styled.input`
  width: ${(props) => props.w || "100%"};
  height: 38px;
  border-radius: 0.428rem;
  background: #fff;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  /* border: 1px solid #d8d6de; */
  border: none;
  outline: none !important;
  padding: 6px 16px;
  font-size: 12pt;
  color: rgb(112, 112, 112);
  font-weight: 600;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  ::placeholder {
    font-family: "Berlin Rounded";
    font-weight: 300;
    font-size: 12px;
    color: rgba(112, 112, 112, 0.61);
  }
  &:focus,
  &:active {
    border: 0.5px solid rgba(115, 103, 240, 0.7);
  }
  margin-top: ${(props) => props.mt || 0};
`;

const btnSendAnwser = styled.button`
  width: 40px;
  height: 40px;
  color: white;
  background-color: #7367f0;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none !important;
  margin-left: 10px;
  border: none;
  border-radius: 50%;
  transition: box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  &:hover {
    box-shadow: 0 4px 24px 0 rgb(115 103 240 / 85%);
  }
`;
const contTextChat = styled.div`
width: 100%;
height: 100%;
/* border: 1px solid red; */
margin-top: 0;
margin-bottom: 0;
margin-left: 0;
padding: 30px;
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: flex-start;
margin-right: 0;
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 50px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #7367f0;
  }
`;

const textChat = styled.div`
min-width: 280px;
width: auto;
max-width: 300px;
word-break: break-all;
padding: .7rem 1rem;
text-align: justify;
height: auto;
position: relative;
background-color: ${props => props.isQuestion ? '#7367F0' : '#FFFFFF'};
border-radius: .429rem;
color: ${props => props.isQuestion ? '#FFFFFF' : '#6e6b7b'};
font-weight: 400;
font-size: 12pt;
align-self: ${props => props.isQuestion ? 'flex-start' : 'flex-end'};
margin-top: 5px;
margin-bottom: 5px;
box-shadow: ${props => props.isQuestion ? '0 4px 24px 0 rgb(115 103 240/ 10%)' : '0 4px 24px 0 rgb(34 41 47 / 10%)'};
`;

const iconSending = styled.span`
width: 20px;
height: 20px;
top:auto;
bottom: 0;
left: auto;
right: 0;
border-bottom-right-radius: .429rem;
border-top-left-radius: .429rem;
background: #7367F0;
color: #FFFFFF;
position: absolute;
display: flex;
justify-content: center;
align-items: center;
`;

const loadDataMl = styled.div`
width: 40px;
height: 40px;
border-radius: 50%;
background: #FFFFFF;
display: flex;
justify-content: center;
align-items: center;
color: #7367F0;
position: absolute;
left: 50%;
right: 50%;
top:0;
font-size: 13pt;
transition: all ease-in-out .02s;
transform : ${props => props.isLoad ? 'translateY(60px)' : 'translateY(-60px)'};
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
`;

const conImgproduct = styled.div`
width: 100%;
min-height: 300px;
max-height: 300px;
background-image: url(${props => props.img || ''});
background-size: contain;
background-repeat: no-repeat;
background-position: center;
`;
const textInfo = styled.div`
width: 100%;
padding: 10px;
text-align: justify;
margin-bottom: 5px;
margin-top:5px;
border-radius: .429rem;
background: ${props=> props.otherCase ? '#7367F0' : '#F0F0F0'};
`
const contLoader = styled.div`
width: 100%;
height: 100%;
margin-top: auto;
margin-bottom: auto;
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
.spinner-border {
    border: .25em solid #7367f0;
    border-right-color: transparent;
}
`;

const footerPaginator = styled.div`
width: 100%;
background: #FFFFFF;
display: flex;
justify-content: center;
align-items: center;
margin-top: auto;
margin-bottom: 0;
height: 50px;
`;

const contVariants = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
flex-wrap: wrap;
width: 100%;
padding: 10px;
text-align: justify;
margin-bottom: 5px;
margin-top:5px;
border-radius: .429rem;
background: #FFFFFF;
overflow-x: auto;
min-height: 200px;
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 50px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #7367f0;
  }
`;

const variantImg = styled.div`
width: 100px;
height: 100px;
overflow: auto;
border-radius: .429rem;
border: ${props=> props.active ? '1px solid #7367f0' : '1 px solid #f0f0f0'};
background-image: url(${props => props.img || ''});
background-size: contain;
background-repeat: no-repeat;
background-position: center;
margin-left: 5px;
margin-right: 5px;
margin-bottom: 5px;
`;

const btnForPublication = styled.button`
width: 100%;
border-radius: .429rem;
border:none;
outline: none !important;
background: ${props => props.isPaused ? '#ea5455': '#28c76f'};
display: flex;
justify-content: center;
align-items: center;
color: #ffffff;
margin-top:5px;
height: 50px;
`;
const QuestionsListStyle = {
    cont,
    header,
    text,
    contListQuestions,
    listQuestions,
    contChatQuestions,
    btnShowMenus,
    contInfoProduct,
    cardQuestionCont,
    contInput,
    input,
    cardQuestionTextAndStatus,
    contIconCheckmar,
    isFocusAnyCard,
    isAnswer,
    btnSendAnwser,
    contTextChat,
    textChat,
    loadDataMl,
    conImgproduct,
    textInfo,
    contLoader,
    iconSending,
    footerPaginator,
    contVariants,
    variantImg,
    btnForPublication
};
export default QuestionsListStyle;
