import React from 'react'
import LinioCampaingStyle from '../../../../assets/css/components/admin/linio-campaign/LinioCampaingStyle'


export default function ListCamapaing(props) {
    const {data, priceListsVal, setIdCampaing, setOpenModal} = props;

    const renderPricesList = (id) => {
        if(priceListsVal) {
            const list = priceListsVal.find(v => v.id === id);
            if(list) {
                return list.name;
            }else {
                return 'no se encontro'
            }
        }
    }

    const getDataProdCampaing = (id) => {
        setIdCampaing(id);
        setOpenModal(true)
    }
    return (
        <LinioCampaingStyle.ConstList>
            <LinioCampaingStyle.HeaderForm>
                <LinioCampaingStyle.Text size="13pt" fw="400">
                    Lista de Campañas
                </LinioCampaingStyle.Text>
            </LinioCampaingStyle.HeaderForm>
            {
                (data.length > 0) &&
                data.map((v) => {
                    return (

                    <LinioCampaingStyle.BodyForm active={true}
                        key={v._id} 
                        onClick={() => getDataProdCampaing( v._id)}>

                        <LinioCampaingStyle.ContInputAndLabel jsc="flex-start">
                            <LinioCampaingStyle.Text size="12pt" fw="500">
                                Nombre de la Campaña
                            </LinioCampaingStyle.Text>
                            <LinioCampaingStyle.Text size="12pt" style={{justifyContent: 'center'}}>
                                {v.name}
                            </LinioCampaingStyle.Text>
                        </LinioCampaingStyle.ContInputAndLabel>
                        <LinioCampaingStyle.ContInputAndLabel jsc="flex-start">
                            <LinioCampaingStyle.Text size="12pt" fw="500">
                                Lista de Precio
                            </LinioCampaingStyle.Text>
                            <LinioCampaingStyle.Text size="12pt" style={{textAlign: 'justify'}}>
                                {renderPricesList(v.odooPricelistId)}
                            </LinioCampaingStyle.Text>
                        </LinioCampaingStyle.ContInputAndLabel>
                        <LinioCampaingStyle.ContInputAndLabel jsc="flex-start">
                            <LinioCampaingStyle.Text size="12pt" fw="500">
                                % de Aumento
                            </LinioCampaingStyle.Text>
                            <LinioCampaingStyle.Text cl="#7367f0" size="12pt" style={{marginLeft: '1rem'}}>
                                {`${v.plusPercent}%`}
                            </LinioCampaingStyle.Text>
                        </LinioCampaingStyle.ContInputAndLabel>

                    </LinioCampaingStyle.BodyForm>
                    )
                })
            }

        </LinioCampaingStyle.ConstList>
    )
}
