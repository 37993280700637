import { faAngleLeft, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from 'react'
import MassiveScrapingCss from '../../../../assets/css/components/admin/products/amazon/MassiveScrapingCss'
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
import axios from 'axios';
import { GlobalContext } from "../../../../contexts/GlobalContext";
import { Link } from 'react-router-dom';
import verifyAccessRole from '../../../../assets/js/verifyAccessRole';
import Routes from '../../../../Routes';
import { Redirect } from "react-router-dom";
import MultiScraperStyles from '../../../../assets/css/components/admin/products/amazon/MultiScraperStyles';

export default function MassiveScraping() {
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const globalContext = useContext(GlobalContext)
    const urlRef = useRef(null);
    const limitRef = useRef(null);
    const [url, setUrl] = useState('');
    const [limit, setLimit] = useState(0);
    const [isLoader, setisloader] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [actualPlugin, setActualPlugin] = useState('');

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 34);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);

    const clearInputOne = () => {
        urlRef.current.value = '';
        setUrl('');
    }
    const clearInputTwo = () => {
        limitRef.current.value = '';
        setLimit(0);
    }
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const scrapProductsResultsUrl = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            setisloader(false)
            globalContext.showModalWithMsg("Por favcor seleccione una empresa");
            return;
        }
        if (url.length === 0) {
            setisloader(false)
            globalContext.showModalWithMsg("La url no puede estar vacia");
            return;
        }
        if (limit === 0) {
            setisloader(false)
            globalContext.showModalWithMsg("Necesita un limite de productos");
            return;
        }
        setisloader(true)
        
        const processCheckIntervalTime = 5000;
        let processModitorId = 0;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };
        
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/parse-products-results-url`, {
            headers,
            params: {
                url: url,
                maxProdCount: limit
            }
        }).then((resp) => {
            setisloader(false)
            globalContext.setLoaderPage(false);
            globalContext.showModalWithMsg("El proceso se esta ejecutando en segundo plano");
        }).catch(err => handleAxiosError(err));
    }

    const scrapProductsResultsUrl2 = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            setisloader(false)
            globalContext.showModalWithMsg("Por favcor seleccione una empresa");
            return;
        }
        if (url.length === 0) {
            setisloader(false)
            globalContext.showModalWithMsg("La url no puede estar vacia");
            return;
        }
        if (limit === 0) {
            setisloader(false)
            globalContext.showModalWithMsg("Necesita un limite de productos");
            return;
        }
        setisloader(true)
        
        const processCheckIntervalTime = 5000;
        let processModitorId = 0;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/aliexp-prod/${entId}/sync`,
        {
            url:url
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            }
        }
        ).then((resp) => {
            setisloader(false)
            globalContext.setLoaderPage(false);
            globalContext.showModalWithMsg("El proceso se esta ejecutando en segundo plano");
        }).catch(err => {
            setisloader(false)
            handleAxiosError(err)
        });
    }

    return (
        <MassiveScrapingCss.cont>
            {redirect}
            <MassiveScrapingCss.header>
            <MassiveScrapingCss.btnBack onClick={() => window.history.back()}>
                <FontAwesomeIcon icon={faAngleLeft} />
            </MassiveScrapingCss.btnBack>
            <MassiveScrapingCss.text size="16pt" fw='500'>
                Scraping Masivo
            </MassiveScrapingCss.text>
            <Link to="list-products-cbt-history-products" style={{marginLeft:'auto', marginRight:10}}>
                <MassiveScrapingCss.btnRedirect>
                    Historial
                </MassiveScrapingCss.btnRedirect>
            </Link>
            <Link to="list-products-cbt" style={{marginLeft:10, marginRight:10}}>
                <MassiveScrapingCss.btnRedirect>
                    Individual
                </MassiveScrapingCss.btnRedirect>
            </Link>
            <Link to="scraping-by-url" style={{marginLeft:10, marginRight:10}}>
                <MassiveScrapingCss.btnRedirect>
                    URLs
                </MassiveScrapingCss.btnRedirect>
            </Link>
            </MassiveScrapingCss.header>
            <MassiveScrapingCss.contForm>
                <MassiveScrapingCss.headerForm>
                    <MassiveScrapingCss.text size="15pt">
                        Agregue su URL y el limite de productos
                    </MassiveScrapingCss.text>
                </MassiveScrapingCss.headerForm>
                <MassiveScrapingCss.bodyForm>

                <MultiScraperStyles.contInputAndLabel jsc="space-between">
                    <MultiScraperStyles.text fw="bolder" mb="5px">
                        Seleccione el plugin
                    </MultiScraperStyles.text>
                    <MultiScraperStyles.contInputRadio>
                        <MultiScraperStyles.inputRadio type="radio" id="publis1" value='amazonScrap' checked={'amazonScrap' === actualPlugin} name="isPublish" onChange={() => setActualPlugin('amazonScrap')} />
                        <label htmlFor="st1">Amazon</label>
                        <MultiScraperStyles.inputRadio type="radio" id="publis2" value='ali' checked={'ali' === actualPlugin} name="isPublish" ml="10px" onChange={() => setActualPlugin('ali')} />
                        <label htmlFor="st2">Ali</label>
                    </MultiScraperStyles.contInputRadio>
                </MultiScraperStyles.contInputAndLabel>

                    <MassiveScrapingCss.contInputAndLabel>
                        <MassiveScrapingCss.text size="12pt">
                            Agregue su URL y el limite de productos
                        </MassiveScrapingCss.text>
                        <div style={{width:'100%'}} className="d-flex justify-content-end align-items-center">
                            <MassiveScrapingCss.inputOne placeholder="URL" type="text" onChange={(e)=> setUrl(e.target.value)} ref={urlRef} />
                            <MassiveScrapingCss.btnFloat onClick={() => clearInputOne()}>
                                <FontAwesomeIcon icon={faTimes} />
                            </MassiveScrapingCss.btnFloat>
                        </div>
                    </MassiveScrapingCss.contInputAndLabel>
                    <MassiveScrapingCss.contInputAndLabel>
                        <MassiveScrapingCss.text size="12pt">
                            Limite de productos
                        </MassiveScrapingCss.text>
                        <div style={{width:'100%'}} className="d-flex justify-content-end align-items-center">
                            <MassiveScrapingCss.inputOne placeholder="Limite" type="number" onChange={(e)=> setLimit(e.target.value)} ref={limitRef} />
                            <MassiveScrapingCss.btnFloat onClick={() => clearInputTwo()}>
                                <FontAwesomeIcon icon={faTimes} />
                            </MassiveScrapingCss.btnFloat>
                        </div>
                    </MassiveScrapingCss.contInputAndLabel>
                </MassiveScrapingCss.bodyForm>
                <MassiveScrapingCss.footerForm>
                    <MassiveScrapingCss.btnSave 
                    onClick={
                        () => {
                        if(actualPlugin === 'amazonScrap') { 
                            scrapProductsResultsUrl();
                        }else {
                            scrapProductsResultsUrl2()
                        }
                    }
                    } 
                    disabled={isLoader || actualPlugin === ''}>
                        {
                            isLoader ? 
                                'Cargando...'
                            :
                            'Iniciar'
                        }
                    </MassiveScrapingCss.btnSave>
                </MassiveScrapingCss.footerForm>
            </MassiveScrapingCss.contForm>
        </MassiveScrapingCss.cont>
    )
}
