import styled from 'styled-components';

const ContListProd = styled.div`
width: 100%;
height: 150px;
overflow-y: hidden;
overflow-x: auto;
position: relative;
margin-top: 10px;
margin-bottom: 10px;
padding: 10px;
background: rgba(196, 196, 196, 0.34);
border-radius: 10px;
`;

const ListProduts = styled.ul`
    list-style: none;
    padding-left: 0px;
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const ItemsListProduts = styled.li.attrs(props => ({
    url: props.url || '',
    brbc: props.active ? 'solid 10px #7367F0' : 'solid 10px transparent',
    }))`
    background-image: ${props => `url(${props.url})`};
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
    width: 130px;
    height: 130px;
    margin-left: 10px;
    border-radius: 10px;
    border-bottom: ${props => props.brbc};
`
const ListOfProductsToSetParams = {
    ContListProd,
    ListProduts,
    ItemsListProduts
}

export default ListOfProductsToSetParams;