import axios from "axios";
import { keyAccessToken, keyUserId } from "../../../../../assets/js/SessionStorageNames";
const disabledSeccion = (id, enabled, restaurant, enterprise) => {
    const res = axios.put(`${process.env.REACT_APP_BACKEND_URL_PEYA}/v1/peya-sdk/change-status-section`,
        {
            status: enabled ? false : true,
            id: id,
            restaurant: restaurant,
            enterprise: enterprise
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export default disabledSeccion;