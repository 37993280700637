import React, { useEffect, useState, useContext } from 'react'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle'
import { BiX } from 'react-icons/bi'
import createSucursal from '../functions/createSucursal';
import validateDataBeforeSend from '../functions/validateDataBeforeSend';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import Select from '../../../../general/Select';
import getAllRestaurant from '../functions/getAllRestaurant';
import saveDataSeccion from '../functions/saveDataSeccion';
export default function FormSections(props) {
    const globalContext = useContext(GlobalContext)
    const { setCurrentStep, sections, setSections, currentSections } = props;
    const [sectionName, setSectionName] = useState('');
    const [enabled, setEnabled] = useState(true);
    const [restaurant, setRestaurant] = useState([]);
    const [error, setError] = useState('');
    const [colorError, setColorError] = useState(true);
    const [isLoad, setIsLoad] = useState(false);
    const [showBadge, setShowBadge] = useState(false);
    const [index, setIndex] = useState('');
    const [listRestaurants, setListRestaurants] = useState([]);

    const funcSetSucursal = (v) => {
        let copyArr = [...sections, v];
        setSections(copyArr)
    }

    const statusSection = [
        {id: 1, name: 'Si'},
        {id: 2, name: 'No'},

    ]

    const closeBadge = (e) => {
        e.preventDefault();
        setShowBadge(false);
    }

    const funcSetStep = (v) => {
        setCurrentStep(v);
    }


    useEffect(() => {
        if (currentSections) {
            console.log('currentSections', currentSections)
            setSectionName(currentSections.name);
            setEnabled(currentSections.enabled ? 1 : 2);
            setIndex(currentSections.index);
            setRestaurant(currentSections.restaurant)
        }
    }, [currentSections])

    const sendData = () => {
        const entId = globalContext.currentEnterpriseId
        if (!entId) {
            setColorError(true);
            setError(`Por favor seleccione una empresa`);
            setShowBadge(true);
            setIsLoad(false);
        }
        setIsLoad(true)
        const hasAnyError = validateDataBeforeSend([index, enabled, sectionName, restaurant, entId]);
        if (hasAnyError) {
            const idUpdate = currentSections ? currentSections._id : null
            saveDataSeccion(index, enabled, sectionName, restaurant, entId, idUpdate)
                .then(() => {
                    setColorError(false);
                    setError('Registro exitoso');
                    setShowBadge(true);
                    setIsLoad(false);
                })
                .catch((err) => {
                    setColorError(true);
                    setError(`Algo salio mal ${err}`);
                    setShowBadge(true);
                    setIsLoad(false)
                })
        } else {
            setError('Verifique que todos los datos son correctos o si le falta algun valor!')
            setShowBadge(true);
            setIsLoad(false)
        }
    }

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId
        if (!entId) return;
        getAllRestaurant(entId)
            .then(async (res) => {
                const datRes = res.data.data;
                let listRes = [];
                for await (const restaurant of datRes) {
                    listRes.push({
                        id: restaurant._id,
                        name: restaurant.store,
                    })
                }
                setListRestaurants(listRes);
            })
            .catch(err => {
                console.log('el error res', err)
            })
    }, [globalContext.currentEnterpriseId]);


    return (
        <>
            <PedidosYaStyle.ContBadgeError active={showBadge} error={colorError}>
                <PedidosYaStyle.Text size=".875rem" cl="#FFFFFF">
                    {error}
                </PedidosYaStyle.Text>
                <PedidosYaStyle.BtnToggleBadge onClick={(e) => closeBadge(e)}>
                    <BiX />
                </PedidosYaStyle.BtnToggleBadge>
            </PedidosYaStyle.ContBadgeError>
            <PedidosYaStyle.ContForm>
                <PedidosYaStyle.HeaderForm>
                    <PedidosYaStyle.Text size="14pt" fw="500" cl="#898989">
                        Ingrese los datos para registrar una nueva seccion
                    </PedidosYaStyle.Text>
                </PedidosYaStyle.HeaderForm>
                <PedidosYaStyle.BodyForm>
                    <PedidosYaStyle.ContInputAndLabel w="100%">
                        <PedidosYaStyle.Text size="12pt">
                            Nombre de la seccion
                        </PedidosYaStyle.Text>
                        <PedidosYaStyle.Input
                            placeholder="Escriba aqui nombre de su seccion"
                            onChange={(e) => setSectionName(e.target.value)}
                            defaultValue={sectionName}
                        />
                    </PedidosYaStyle.ContInputAndLabel>
                    <PedidosYaStyle.ContInputAndLabel w="100%">
                        <PedidosYaStyle.Text size="12pt">
                            Habilitada
                        </PedidosYaStyle.Text>
                        <Select
                            StatusDropDown={statusSection}
                            idOption={enabled}
                            setIdOption={setEnabled}
                        />
                    </PedidosYaStyle.ContInputAndLabel>
                    <PedidosYaStyle.ContInputAndLabel w="100%">
                        <PedidosYaStyle.Text size="12pt">
                            Index de la seccion
                        </PedidosYaStyle.Text>
                        <PedidosYaStyle.Input
                            placeholder="Por defecto es 1"
                            onChange={(e) => setIndex(e.target.value)}
                            defaultValue={index}
                        />
                    </PedidosYaStyle.ContInputAndLabel>
                    <PedidosYaStyle.ContInputAndLabel w="100%">
                            <PedidosYaStyle.Text size="12pt">
                                Sucursal
                            </PedidosYaStyle.Text>
                            <Select
                                StatusDropDown={listRestaurants}
                                idOption={restaurant}
                                setIdOption={setRestaurant}
                            />
                        </PedidosYaStyle.ContInputAndLabel>
                </PedidosYaStyle.BodyForm>
                <PedidosYaStyle.FooterForm>
                    <PedidosYaStyle.BtnSend background="#F0F0F0" color="#707070" onClick={() => funcSetStep(4)}>
                        Volver
                    </PedidosYaStyle.BtnSend>
                    <PedidosYaStyle.BtnSend disabled={isLoad} onClick={() => sendData()}>
                        {isLoad ? 'Enviando' : 'Guardar'}
                    </PedidosYaStyle.BtnSend>
                </PedidosYaStyle.FooterForm>
            </PedidosYaStyle.ContForm>
        </>
    )
}
