import styled from "styled-components";
const DropDownButtonStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");

  .content-text-icon {
    display: flex;
    justify-content: space-between;
    width: 300px;
    font-family: "Roboto Slab", serif !important;
  }

  .DropBtn {
    min-width: 300px;
    background-color: rgb(94, 84, 142);
    padding: 8px 16px;
    display: flex;
    justify-content: space-evenly;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 0.5rem;
    &:active,
    &:visited,
    &:focus,
    &active:focus {
      outline: none !important;
      border: none !important;
    }
  }
  .icon {
    display: block;
    position: relative;
    width: 24px;
    height: 24px;
  }
  .rectangle {
    position: absolute;
    top: 8px;
    width: 3px;
    height: 10px;
    border-radius: 2px;
    background: #ffffff;
    transition: transform 0.35s;
  }

  .rectangle1 {
    left: 8px;
    transform: rotate(-45deg) scaleY(1) translate(0);
  }
  .rectangle2 {
    right: 8px;
    transform: rotate(45deg) scaleY(1) translate(0);
  }

  .optionsDrop {
    background-color: rgb(94, 84, 142);
    display: none;
    transition: ease-out;
    position: absolute;
    padding: 5px 10px;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .items {
    display: flex;
    color: #ffffff;
    border-radius: 10px;
    padding: 5px 10px;
    justify-content: start;
    align-content: center;
    text-align: left;
    font-family: "Roboto Slab", serif !important;
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
      cursor: pointer;
    }
    .ic {
      margin-right: 5px;
    }
  }
  .icon-active {
    display: none;
  }
  /**
  *! clases active 
   */
  .rectangle1.open {
    transform: rotate(-45deg) scaleY(1.75) translate(2px, 1px);
  }
  .rectangle2.open {
    transform: rotate(45deg) scaleY(1.75) translate(-2px, 1px);
  }
  .DropBtn.open {
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .icon-active.open {
    display: block;
    font-size: 6pt;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 5px;
  }

  .optionsDrop.open {
    min-width: 332px;
    display: block;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: translate(0px, 32px);
    z-index: 9999;
  }
  .items.open {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .items.hidden {
    display: none;
  }
  @media (min-width: 400px) and (max-width: 499px) {
    .DropBtn {
      min-width: 300px;
      width: 300px;
      max-width: 300px;
    }
    .optionsDrop.open {
      min-width: 300px;
      width: 300px;
      max-width: 300px;
    }
  }
  @media (min-width: 300px) and (max-width: 399px) {
    .DropBtn {
      min-width: 200px;
      width: 200px;
      max-width: 200px;
    }
    .optionsDrop.open {
      min-width: 200px;
      width: 200px;
      max-width: 200px;
    }
  }
`;
export default DropDownButtonStyle;
