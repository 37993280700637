import styled from 'styled-components';
const HeadHeaderStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  .head-content {
    margin-top: 20px;

    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    padding: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background: #fff;
    overflow: hidden;
  }
  .welcome-text {
    font-family: "Roboto Slab", serif !important;
    color: rgb(94, 84, 142);
    font-size:18pt;
  }
`;
export default HeadHeaderStyle;