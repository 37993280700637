import { faEllipsisV, faExclamationTriangle, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import MultiScraperStyles from '../../../../../assets/css/components/admin/products/amazon/MultiScraperStyles';
import OptionsProductsHistory from '../OptionsProductsHistory';
import { Spinner } from 'react-bootstrap';
import imgNoFound from "../../../../../assets/img/jpeg/img-no-found.jpg";
export default function CardErrorComponent(props) {
    const numberFormat = new Intl.NumberFormat('de-DE');

    const changeDate = date => {
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        
        return `${dd}-${mm}-${yyyy}`
    }

    const {
        t, i, id, changeToRow, isDeleted, openMenuOptionItem,
        setOpenMenuOptionItem, findCategoryByicon, deleteAProduct,
        scarpingAgain, itemsAddsByDelete, 
        setOpenMenuOptionItemFunc, inDeleteProcess, isScrapingAgain,
        addItemByDeleteFunc, itemsToUpdateAndPublish, setItemsToUpdateAndPublish, addItemByDeleteWithOutErrorFunc
    } = props;

    const funcDeleteAProduct = (p1, p2) => {
        deleteAProduct(p1,p2);
    }
    const funcScarpingAgain = (p1, p2, p3) => {
        scarpingAgain(p1, p2, p3);
    }

    //* agg un elemento al array
    const addOneToPublishAndUpdate = (idItem) => {
        console.log('id del item a enviar', idItem)
        const isHere = itemsToUpdateAndPublish.find((e) => (e === idItem));
        if (isHere === undefined) {
            setItemsToUpdateAndPublish([...itemsToUpdateAndPublish, idItem]);
        } else {
            setItemsToUpdateAndPublish(itemsToUpdateAndPublish.filter((e) => (e !== idItem)))
        }
    }

    return (
        <MultiScraperStyles.cardsGrid IsScrapingAgain={!isScrapingAgain.includes(id)} isDeleted={isDeleted.includes(id)} key={id} changeToRow={changeToRow}>
            <MultiScraperStyles.screenLoader IsScrapingAgain={isScrapingAgain.includes(id) || inDeleteProcess.includes(id)}>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
                <MultiScraperStyles.text cl="#7367F0" fw="700">
                    Estamos procesando su solicitud...
                </MultiScraperStyles.text>
            </MultiScraperStyles.screenLoader>
            <MultiScraperStyles.contImgCard changeToRow={changeToRow}>
                <OptionsProductsHistory
                    active={i === openMenuOptionItem}
                    setActive={setOpenMenuOptionItem}
                    filterTo={findCategoryByicon}
                    catId={t.categoryTree}
                    deleteMethod={deleteAProduct}
                    idDeleteByProduct={id}
                    indexProducByDelete={i}
                />
                {
                    isDeleted.includes(id) ?
                        (
                            <div className="cont-dots">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="dot-one" style={{ color: "#ea5455" }} />
                            </div>
                        )
                        :
                        !t.ml ?
                        (
                            <>
                                <input
                                    className="inp-cbx"
                                    style={{ display: 'none' }}
                                    id={`cbx${id}`}
                                    type="checkbox"
                                    onChange={() => {
                                        addItemByDeleteFunc(id, i);
                                        addItemByDeleteWithOutErrorFunc(id,i)
                                        addOneToPublishAndUpdate(id)
                                    }
                                }
                                    checked={itemsAddsByDelete.includes(id) || itemsToUpdateAndPublish.includes(id)}
                                />
                                <label className="cbx" htmlFor={`cbx${id}`}>
                                    <span>
                                        <svg width="12px" height="10px" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                    </span>
                                </label>
                            </>
                        ) :
                        (
                            <>
                                <input
                                    className="inp-cbx"
                                    style={{ display: 'none' }}
                                    id={`cbx${id}`}
                                    type="checkbox"
                                    onChange={() => { addOneToPublishAndUpdate(id) }}
                                    checked={itemsToUpdateAndPublish.includes(id)}
                                />
                                <label className="cbx" htmlFor={`cbx${id}`}>
                                    <span>
                                        <svg width="12px" height="10px" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                    </span>
                                </label>
                            </>
                        )
                }
                <MultiScraperStyles.imgCard changeToRow={changeToRow}
                    url={t.images.length > 0 ? t.images[0] : imgNoFound}
                />
                {
                    !isDeleted.includes(id) &&
                    (
                        <div className="d-block text-center">
                            <div className="cont-dots">
                                <MultiScraperStyles.btnOptions onClick={() => setOpenMenuOptionItemFunc(i)}>
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                </MultiScraperStyles.btnOptions>
                            </div>
                            <div className="cont-dots" style={{ marginBottom: 5, marginTop: 5 }}>
                                <FontAwesomeIcon icon={faExclamationTriangle} className="dot-one" style={{ color: "#ea5455" }} />
                            </div>
                            {
                        (t.ml) &&
                        (
                            <div className="cont-dots" style={{ marginBottom: 5, marginTop: 5 }}>
                                <FontAwesomeIcon icon={faThumbsUp} className="dot-one" style={{ color: "#2ec4b6" }} />
                            </div>
                        )
                    }
                        </div>
                    )
                }
                
            </MultiScraperStyles.contImgCard>
            
            <MultiScraperStyles.dataCardText changeToRow={changeToRow}>
                {!changeToRow && (
                    <div className="price">
                        {`$${numberFormat.format(t.price)}`}
                    </div>
                )}
                <MultiScraperStyles.titleDataCardtext changeToRow={changeToRow}>
                    {t.title ? t.title.es || t.title.en : 'NP'}
                </MultiScraperStyles.titleDataCardtext>
                <MultiScraperStyles.preciosCardText>
                    <div className="precio-oferta">
                        <p>Precio de oferta -</p>
                        <p>{`$${numberFormat.format(t.dealPrice)}`}</p>
                    </div>
                    <div className="precio-old">
                        <p>Precio antiguo -</p>
                        <p>{`$${numberFormat.format(t.oldPrice)}`}</p>
                    </div>
                    <div className="precio-old">
                        <p>SKU - </p>
                        <p>{t.asin}</p>
                    </div>
                    <div className="precio-old">
                        <p>Actualizado - </p>
                        <p>{t.updatedAt ? changeDate(new Date(t.updatedAt)) : ''}</p>
                    </div>
                </MultiScraperStyles.preciosCardText>
            </MultiScraperStyles.dataCardText>
            <MultiScraperStyles.contBtnCardActions changeToRow={changeToRow}>
                {changeToRow && (
                    <div className="price">
                        {`$${numberFormat.format(t.price)}`}
                    </div>
                )}
                {
                    !isDeleted.includes(id) &&
                    (
                        <>
                            <MultiScraperStyles.btnActionsCard
                                brbl="5px" colorFont="#FFFFFF" bgColor="#ea5455"
                                changeToRow={changeToRow}
                                onClick={() => funcDeleteAProduct(id, i)}
                            >
                                Eliminar
                            </MultiScraperStyles.btnActionsCard>
                            <MultiScraperStyles.btnActionsCard
                                brbr="5px"
                                colorFont="#ffffff"
                                bgColor="#7367F0"
                                changeToRow={changeToRow}
                                onClick={() => funcScarpingAgain(t.url, id, i)}
                            >
                                Volver a Scrapear
                            </MultiScraperStyles.btnActionsCard>
                        </>
                    )
                }
            </MultiScraperStyles.contBtnCardActions>
        </MultiScraperStyles.cardsGrid>
    )
}
