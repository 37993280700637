import getProductAmazonScrap from "./getProductAmazonScrap";
import hasErrorData from "./hasErrorData";

const renderCards = async (
    entId, dataIdsProducts,axiosCancelTokenSource, setDataCard, setTotalVariants, setTotalGetProducts, setTotalWithError,setTotalVariantWithError,setTotalPaused, setTotalPausedVariants,setPercent,updatePercentState
    ) => {

    let dataNew =[];
    let totalProductos=0;
    let totalErros=0;
    let totalVariantsWithError=0;
    let totalPausados=0;
    let totalVariantesPausadas=0;
    let totalVariantes=0;
    let newIndex =0;
    //init for
    for await (let v of dataIdsProducts) {
        newIndex++;
        //get data amazonScrap
        await getProductAmazonScrap(entId, v, axiosCancelTokenSource)
                // eslint-disable-next-line no-loop-func
                .then(v => {
                    //increment the product
                    totalProductos++;
                    const dataThen = v.data.data.product;
                    // validamos si el producto es variante
                    if (dataThen.variants.length > 0) {
                        let hasError = false;
                        let noMlData = false;
                        dataNew = [...dataNew, {
                            updatedAt: dataThen.updatedAt,
                            enterprise: dataThen.enterprise,
                            variants: dataThen.variants.map((variants) => {
                                    totalVariantes++;
                                    //si no tiene datos de ml la variante
                                    if(!variants.ml || typeof(variants.ml) === 'undefined') {
                                        totalVariantsWithError++;
                                        noMlData=true;
                                        hasError = true
                                    }
                                    else if(variants.ml && variants.ml.attrib.status === 'paused') {
                                        totalVariantesPausadas++;
                                    }
                                    else if (hasErrorData(variants)) {
                                        hasError = true
                                    }
                                    return {
                                        asin: variants.asin,
                                        brand: variants.brand,
                                        categoryTree: variants.categoryTree,
                                        dealPrice: variants.dealPrice,
                                        deliveryDays: variants.deliveryDays,
                                        desc: variants.desc,
                                        details: variants.details,
                                        dimensions: variants.dimensions,
                                        features: variants.features,
                                        images: variants.images,
                                        ml: variants.ml,
                                        oldPrice: variants.oldPrice,
                                        price: variants.price,
                                        shippingPrice: variants.shippingPrice,
                                        stock: variants.stock,
                                        title: variants.title,
                                        url: variants.url,
                                        variations: variants.variations,
                                        weight: variants.weight,
                                        itHasScraping: false,
                                        itHasUpdateMl: false,
                                    }
                                })
                            ,
                            zipCode: dataThen.zipCode,
                            _id: dataThen._id,
                            hasError: hasError,
                            noMlData: noMlData
                        }];
                    } else {
                        let hasError = false;
                        let noMlData = false;
                        //si no tiene datos de ml el producto
                        if(!dataThen.ml || typeof(dataThen.ml) === 'undefined') {
                            totalErros++;
                            noMlData = true;
                            hasError = true;
                        }
                        else if(dataThen.ml && dataThen.ml.attrib.status === 'paused') {
                            totalPausados++;
                        }
                        else if (hasErrorData(dataThen)) {
                            hasError = true;
                        }
                        dataNew = [...dataNew, {
                            asin: dataThen.asin,
                            brand: dataThen.brand,
                            categoryTree: dataThen.categoryTree,
                            dealPrice: dataThen.dealPrice,
                            deliveryDays: dataThen.deliveryDays,
                            desc: dataThen.desc,
                            details: dataThen.details,
                            dimensions: dataThen.dimensions,
                            enterprise: dataThen.enterprise,
                            features: dataThen.features,
                            images: dataThen.images,
                            ml: dataThen.ml,
                            oldPrice: dataThen.oldPrice,
                            price: dataThen.price,
                            reScrapingSocket: dataThen.reScrapingSocket,
                            shippingPrice: dataThen.shippingPrice,
                            stock: dataThen.stock,
                            title: dataThen.title,
                            updatedAt: dataThen.updatedAt,
                            url: dataThen.url,
                            variants: [],
                            weight: dataThen.weight,
                            zipCode: dataThen.zipCode,
                            _id: dataThen._id,
                            hasError: hasError,
                            noMlData: noMlData,
                            itHasScraping: false,
                            itHasUpdateMl: false,
                        }];
                    }

                })
                .catch(e => console.log('error', e))
                .finally(() => {
                    setDataCard(dataNew);
                    setTotalVariants(totalVariantes);
                    setTotalGetProducts(totalProductos);
                    setTotalWithError(totalErros);
                    setTotalVariantWithError(totalVariantsWithError);
                    setTotalPaused(totalPausados);
                    setTotalPausedVariants(totalVariantesPausadas)
                    setPercent(updatePercentState(dataIdsProducts.length, newIndex));
                });
    }
}

export default renderCards

