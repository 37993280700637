import React, {useState, useEffect, useContext } from 'react'
import CronReportsStyle from '../../../../assets/css/components/admin/cron-reports/CronReportsStyle'
import DateTime from '../components/DateTime';
import { BiSearchAlt2 } from "react-icons/bi";
import ListItems from '../components/ListItems';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { Spinner } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import Routes from '../../../../Routes';
import verifyAccessRole from '../../../../assets/js/verifyAccessRole';
import Select from '../../../general/Select';
import getDataOne from '../functions/getDataOne';
import getDataTwo from '../functions/getDataTwo';
export default function GridReports() {
    const globalContext = useContext(GlobalContext);
    const [dateOne, setDataeOne] = useState('');
    const [data, setData] = useState([]);
    const [dataTwo, setDataTwo] = useState([]);
    const [noData, setNoData] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [countData, setCountData] = useState(0);
    const [typeReport, setTypeReport] = useState(1);

    const listTypeReport = [
        {id: 1, name: 'Publicaciones por usuario'},
        {id: 2, name: 'Total de scraping por usuario'},
    ];

    const findDataReport = async () => {
        setIsLoad(true)
        const entId = globalContext.currentEnterpriseId;
        await getDataOne(dateOne, entId, typeReport)
        .then((data) => {
            setData(data.data.data.result)
            setCountData(data.data.data.result.length)
            console.log('data del reporte', data.data.data)
        }).catch((err) => {
            console.log('error del reporte', err);
        })

        await getDataTwo(dateOne, entId, typeReport)
        .then((data) => {
            setDataTwo(data.data.data.result)
            console.log('data del reporte 2', data.data.data)
        }).catch((err) => {
            console.log('error del reporte 2', err);
        })

        setIsLoad(false)
    }
    
    return (
        <CronReportsStyle.Cont>
            <CronReportsStyle.Header>
                <CronReportsStyle.Text size="15pt" fw="600">
                    Reportes KBT
                </CronReportsStyle.Text>
            </CronReportsStyle.Header>
            <CronReportsStyle.ContHeaderOptions>
                <CronReportsStyle.ContInputAndLabel mr="10px">
                    <CronReportsStyle.Text size="12pt">
                        Fecha a consultar
                    </CronReportsStyle.Text>
                    <DateTime
                        setValue={setDataeOne}
                        valueDefaultText="Fecha Desde"
                    />
                </CronReportsStyle.ContInputAndLabel>
                <CronReportsStyle.ButtonSearch onClick={() => findDataReport()}>
                    <BiSearchAlt2 />
                </CronReportsStyle.ButtonSearch>
            </CronReportsStyle.ContHeaderOptions>
            {
                dataTwo.length > 0 && (
                    <CronReportsStyle.ContBodyListUsers>
                        <CronReportsStyle.ContTotalData>
                            <CronReportsStyle.Text cl="#FFFFFF" size="12pt" fw="500">
                                {`Efectividad del scraping`}
                            </CronReportsStyle.Text>
                        </CronReportsStyle.ContTotalData>
                        {
                            <CronReportsStyle.ContListItems>
                            <CronReportsStyle.ContInputAndLabel mr="10px">
                                <CronReportsStyle.Text fw="500" size="12pt">
                                    Total de productos scrapeados
                                </CronReportsStyle.Text>
                                <CronReportsStyle.Text>
                                    {dataTwo[0].total}
                                </CronReportsStyle.Text>
                            </CronReportsStyle.ContInputAndLabel>
    
                            <CronReportsStyle.ContInputAndLabel mr="10px">
                                <CronReportsStyle.Text fw="500" size="12pt">
                                    Totales actualizados
                                </CronReportsStyle.Text>
                                <CronReportsStyle.Text>
                                    {dataTwo[0].totalActualizado}
                                </CronReportsStyle.Text>
                            </CronReportsStyle.ContInputAndLabel>

                            <CronReportsStyle.ContInputAndLabel mr="10px">
                                <CronReportsStyle.Text fw="500" size="12pt">
                                    Totales sin Cambios
                                </CronReportsStyle.Text>
                                <CronReportsStyle.Text>
                                    {dataTwo[0].totalSinCambios}
                                </CronReportsStyle.Text>
                            </CronReportsStyle.ContInputAndLabel>

                            <CronReportsStyle.ContInputAndLabel mr="10px">
                                <CronReportsStyle.Text fw="500" size="12pt">
                                    Totales con errores
                                </CronReportsStyle.Text>
                                <CronReportsStyle.Text>
                                    {dataTwo[0].totalConError}
                                </CronReportsStyle.Text>
                            </CronReportsStyle.ContInputAndLabel>
    
                            <CronReportsStyle.ContInputAndLabel mr="10px">
                                <CronReportsStyle.Text fw="500" size="12pt">
                                    Totales sin errores
                                </CronReportsStyle.Text>
                                <CronReportsStyle.Text>
                                    {dataTwo[0].totalSinError}
                                </CronReportsStyle.Text>
                            </CronReportsStyle.ContInputAndLabel>

                        </CronReportsStyle.ContListItems>
                        }
                    </CronReportsStyle.ContBodyListUsers>
                    )
            }

            <CronReportsStyle.ContBodyListUsers>
                <CronReportsStyle.ContTotalData>
                    <CronReportsStyle.Text cl="#FFFFFF" size="12pt" fw="500">
                        {`Total de registros: ${countData}`}
                    </CronReportsStyle.Text>
                </CronReportsStyle.ContTotalData>
                {
                    isLoad ? (
                        <CronReportsStyle.contLoader>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Cargando...</span>
                            </Spinner>
                        </CronReportsStyle.contLoader>
                    ):
                    data.length > 0 ?
                        (<ListItems data={data} />)
                        :
                        (
                            !noData ?
                            (
                                <CronReportsStyle.Text fw="500" size="14pt">
                                    Agrege la fecha a consultar
                                </CronReportsStyle.Text>
                            ) :
                            (
                                <CronReportsStyle.Text fw="500" size="14pt">
                                    No se encontro ningun registro para este rango de fechas!
                                </CronReportsStyle.Text>
                            )
                        )
                }
            </CronReportsStyle.ContBodyListUsers>
        </CronReportsStyle.Cont>
    )
}
