import React, { useRef, useState } from 'react'
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as locales from 'react-date-range/dist/locale';
import { Calendar } from 'react-date-range';
import CronReportsStyle from '../../../../assets/css/components/admin/cron-reports/CronReportsStyle';
import useOutsideAlerterMenu from '../functions/useOutsideAlerterMenu';

export default function DateTime(props) {
    const {setValue, valueDefaultText="", originalDate=false} = props;
    const [date, setDate] = useState(null);
    const [open, setOpen] = useState(false);
    const [text, setText] = useState(valueDefaultText);
    const dataRefInput = useRef(null)
    const changeDate = date => {
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        setDate(date)
        //setState(yyyy +'-'+ mm +'-'+ dd);
        setText(`${dd}-${mm}-${yyyy}`);
        if(originalDate) {
            handleChangeValue(date)
        } else {
            handleChangeValue(`${yyyy}-${mm}-${dd}`)
        }
        setOpen(false)
    }

    const handleChangeValue = (valor) => {
        setValue(valor);
    }
    useOutsideAlerterMenu(dataRefInput, setOpen);

    return (
        <CronReportsStyle.dataPikerInput ref={dataRefInput}>
            <div 
                className="input-date d-flex"
                onClick={e => setOpen(!open)}
            >
            {text}
            <button className={`btn-date ${open && 'active'}`} >
                <FontAwesomeIcon icon={faCalendar} />
            </button>
            </div>
            <div className={`cont-calendar ${open && 'active'}`} >
            <Calendar 
                onChange={item => changeDate(item)}
                locale={locales['es']} date={date} />
            </div>
        </CronReportsStyle.dataPikerInput>
    )
}
