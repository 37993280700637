import React, { useEffect, useState } from 'react'
import PublishProduct from "../../../../../assets/css/components/admin/products/amazon/ModalPublishProduct";
import { Spinner } from 'react-bootstrap';

export default function Sending({isSending, totalSend, errors}) {
    const [active, setActive] = useState(0);
    const [error, setError] = useState(null);
    const [complete, setIsComplete] = useState(false)
    const showError = (v,i) => {
        setActive(i)
        setError(v.errors.message)
    }
    useEffect(() => {
        if (errors) {
            setIsComplete(true)
        }
    }, [errors])
    return (
        <PublishProduct.bodyModal>
            <PublishProduct.contRowCard>
                <PublishProduct.cardTotal>
                    <PublishProduct.textBody cl='#FFFFFF' size='15pt' fw='500'>
                        Total de productos publicados
                    </PublishProduct.textBody>
                    
                    {
                        isSending ? (
                            <Spinner animation="border" role="status" style={{color:"#FFFFFF"}}>
                                <span className="sr-only">Cargando...</span>
                            </Spinner>
                        ) : (
                            <PublishProduct.textBody cl='#FFFFFF' size='18pt' fw='500'>
                                {totalSend}
                            </PublishProduct.textBody>
                        )
                    }
                    
                </PublishProduct.cardTotal>

                <PublishProduct.cardTotal bgc="#ea5455" s="2px 0px 6px #707070">
                    <PublishProduct.textBody cl='#FFFFFF' size='15pt' fw='500' >
                        Total de productos con errores
                    </PublishProduct.textBody>
                    {
                        isSending ? (
                            <Spinner animation="border" role="status" style={{color:"#FFFFFF"}}>
                                <span className="sr-only">Cargando...</span>
                            </Spinner>
                        ) : (
                            <PublishProduct.textBody cl='#FFFFFF' size='18pt' fw='500'>
                                {errors ? errors.length : 0}
                            </PublishProduct.textBody>
                        )
                    }
                </PublishProduct.cardTotal>
            </PublishProduct.contRowCard>
            {
                complete && (
                    <div styled={{width:'100%'}} className="d-flex justify-content-center align-items-center">
                        <PublishProduct.listImagesCont>
                            <PublishProduct.listImagesBody w="400px">
                                <PublishProduct.listImages minh="100px" h="100px">
                                    {errors &&
                                        errors.map((e, i) => {
                                            return (
                                                <PublishProduct.listImageCont 
                                                minw="60px"
                                                maxw="60px"
                                                minh="60px"
                                                maxh="60px"
                                                active={active === i} 
                                                key={i} 
                                                img={ e.image} 
                                                onClick={() => showError(e, i)} 
                                                />
                                            )
                                        })
                                    }
                                </PublishProduct.listImages>
                            </PublishProduct.listImagesBody>
                        </PublishProduct.listImagesCont>
                    </div>
                )
            }
            
                <PublishProduct.textBody ml="10px" mr="10px" cl='#707070' size='12pt' fw='400'>
                    {error}
                </PublishProduct.textBody>
        </PublishProduct.bodyModal>
    )
}
