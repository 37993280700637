import React from "react";
import { Line } from "react-chartjs-2";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GraphicsStyle from "../../../../../../assets/css/components/admin/dashboard/dashboard-graphics-header/GraphicsStyle.css";

export default function GraphicOne(props) {
  const data = {
    labels: props.labelsData,
    datasets: [
      {
        label: "Sales for 2019 (M)",
        data: props.data,
        borderColor: props.borderColorLines,
        backgroundColor: props.backgroundColorLines,
        pointBackgroundColor: props.backgroundColorPoints,
        pointBorderColor: props.borderColorPoints,
        pointBorderWidth: props.borderWidthPoints
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: props.pointsRadio,
      },
    },

    title: {
      display: false,
      text: props.titleText,
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
            min: 0,
            max: 15,
            stepSize: 1,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  return (
    <GraphicsStyle>
      <div className={`lineChartContent ${props.backgroundColor}`}>
        <Row>
          <Col
            className="d-flex justify-content-start align-items-center"
            xs={4}
            md={4}
            sm={4}
            lg={4}
            xl={4}
          >
            <span className="icon-graphic">
              <FontAwesomeIcon icon={props.iconGraphicCard} />
            </span>
          </Col>
          <Col
            className="d-flex justify-content-start align-items-center"
            xs={8}
            md={8}
            sm={8}
            lg={8}
            xl={8}
          >
            <div className="text-graphic">
              <p className="title">{props.textCard}</p>
              <p className="sub-title">{props.subTitleCard}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            md={12}
            sm={12}
            lg={12}
            xl={12}
            className="d-flex justify-content-center align-items-center"
          >
            <Line data={data} options={options} width={140} height={140} />
          </Col>
        </Row>
      </div>
    </GraphicsStyle>
  );
}
