import styled from 'styled-components';

const Contenedor = styled.div`
width:100%;
padding: 10px;
`;
const HeaderContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
`;
const headerOptions = styled.div`
display: flex;
width: 100%;
.cont {
    width: 100%;
    line-height: 1;
    height:auto;
    display:flex;
    box-sizing: content-box;
    box-sizing:content-box;
    justify-content: flex-end;
}

input {
    width: 100%;
    height: 38px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: none !important;
    outline: none !important;
    padding:6px 16px;
    font-size:12pt;
    color: rgb(112, 112, 112);
    font-weight: 600;
    padding-right: 50px;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12px;
        color: rgba(112, 112, 112, 0.61);
        }
    }
.btn-action-one {
    width: 38px;
    height: 38px;
    background: #fff;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:50px;
    border: none !important;
    outline: none !important;
    position: absolute;
    margin-right: 5px;
    transition: all ease 0.5s;
    .fa-cloud-download-alt {
        font-size:12pt;
    transition: all ease 0.5s;
        color: #707070;
    }
    &:hover {
        background-color: #7367f0;
        .fa-cloud-download-alt {
        color: white;
    }
    }
}
.button-redirect {
    width: auto;
    height: auto;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #7367f0;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    outline: none !important;
    padding:6px 16px;
    font-size:12pt;
    color: #7367f0;
    font-weight:bold;
    transition: all ease 0.5s;
    &:hover {
        background-color: #7367f0;
        color: white;
    }
}

.cont-two-buttons {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    width: 100px;
    height:auto;
    display: flex;
    justify-content:center;
    border: none;
    border-radius: 5px;
    .btn-one {
        width: 100%;
        height: auto;
        background-color:white;
        padding:6px 16px;
        font-size:12pt;
        text-align: center;
        color:#7367f0;
        border: none;
        border-top-left-radius:5px;
        border-bottom-left-radius:5px;
        border: 1px solid #7367f0;
        outline: none!important;
        transition: all ease 0.5s;
        &:hover {
            color: white;
            background-color: #7367f0;
        }
    }
    .btn-two {
        width: 100%;
        height: auto;
        background-color:white;
        text-align: center;
        color:#7367f0;
        padding:6px 16px;
        font-size:12pt;
        border: 1px solid #7367f0;
        border-left: none !important;
        border-top-right-radius:5px;
        border-bottom-right-radius:5px;
        outline: none!important;
        transition: all ease 0.5s;
        &:hover {
            color: white;
            background-color: #7367f0;
        }
    }
}
`
const btnBack = styled.button`
width:30px;
height:30px;
outline: none !important;
border: 1px solid #7367F0;
display: flex;
justify-content:center;
align-items: center;
font-size: 12pt;
color: #7367F0;
border-radius:50px;
background-color: #FFFFFF;
transition: all ease 0.02s;
margin-right:10px;
&:hover {
    background: #7377f0;
    color: #FFFFFF;
}
`;
const breadCrumbs = styled.div`
    width: 100%;
    line-height: 1;
    height:auto;
    display:flex;
    box-sizing: content-box;
    box-sizing:content-box;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
`
const cardProduct = styled.div`
.item-photo {
    display: flex;
    flex-direction: column;
    padding:10px;
    justify-content:center;
    align-items:center;
    width:40%;
    height:100%;
    position: sticky;
    top: 20px;
    max-height:1000px !important;
}
.item-text {
    width:100%;
    height:auto;
    text-align:left;
    margin-top:10px;
    margin-right:10px;
}
.variants {
    width:100%;
    height:auto;
    text-align:left;
    margin-right:10px;
}
.variants { grid-area: variants; margin-bottom:5%; }
.product-cont {
    margin-top:10px;
    width: 100%;
    height: auto;
    background-color: white;
    border-radius: 5px;
    border: none !important;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content:flex-start;
}
.img-one {
    width:100%;
}
.cont-img-seconds {
    border: none;
    width: 100%;
    max-width:500px;
    padding:10px;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(50px, 50px));
}
.img-second {
    width: 50px !important;
    height: 60px !important;
    border: 0.5px solid #707070;
    margin-left: 3%;
    margin-right: 3%;
     &:hover {
        cursor: pointer;
        border-color: #7367f0;
     }
}
.info-product {
    width:100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: column;
    -webkit-box-pack: space-around;
    -webkit-justify-content: space-around;
    -ms-flex-pack: space-around;
    justify-content: space-between;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}
.texto-n {color : #707070}
.sub-texto {
    font-weight:bolder;
    font-size: 15px;
    color: #707070;
}
.text-color {
        color: #7367f0;
        font-weight:bold;
    }
.text-link {
    cursor: pointer;
    &:hover {
        text-decoration: underline
    }
}
.text-color-light {
        color: #7367f0;
        font-weight:lighter;
    }
    .list-caracteristicas{
        color: #707070;
        font-size: 11pt;
    }
    .text-long {
        color: #707070;
        font-weight:lighter;
    }
    .variants-cont-img{
        border: none;
        width: 100%;
        padding: 5px 10px;
        border-top: 0.5px rgba(112,112,112,0.3) solid;
        border-bottom: 0.5px rgba(112,112,112,0.3) solid;
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(60px, 60px));
    }
    
    .img-variant {
        width: 50px !important;
        height: 60px !important;
        cursor: pointer;
    }
    .btn-procesar {
        background: #7367f0;
        outline: none!important;
        border: none;
        color: white;
        font-weight: 600;
        padding: 10px;
        border-radius: .5rem;
        margin-left: 5px;
        margin-right:5px;
        align-self: flex-end;
    }
    .btn-update {
        background: #28c76f!important;
        outline: none!important;
        border: none;
        color: white;
        font-weight: 600;
        padding: 10px;
        border-radius: .5rem;
        margin-left: 5px;
        margin-right:5px;
        align-self: flex-end;
    }
    .cont-btn-actions {
        width:100%;
        display:flex;
        justify-content:flex-end;
        align-items:center;
        padding:10px;
    }
    @media (max-width: 920px) {
        .product-cont {
            flex-direction: column;
            justify-content: space-around;
            align-items: center
        }
        .item-photo {
            width:100%;
            position:relative;
        }
        .variants, .item-text {
            padding: 0 10px;
            word-break: break-all
        }
    }
`;
const contImgCardModal = styled.div`
    background-image: url(${props=> props.img ? '"'+`${props.img}`+'"' : ""});
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 400px !important;
    background-position: center;
`
const contImgManyImgCards = styled.div`
    border: none;
    width: 100%;
    max-width:500px;
    padding:10px;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(60px, 60px));
    `;
    const contImgGrid = styled.div`
    min-width: 50px;
    max-width: 50px !important;
    width: 50px !important;
    max-height: 50px !important;
    height: 50px !important;
    border: 0.5px solid #707070;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-left: 3%;
    margin-right: 3%;
    background-image: url(${props=> props.img ? '"'+`${props.img}`+'"' : ""});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    box-shadow:${props => props.sh ? '0px 0px 12px 2px #7367f0' : ''};
    &:hover {
            cursor: pointer;
            border-color: #7367f0;
            box-shadow:0px 0px 12px 2px #7367f0;
        }
.img-slider {
        width: 100%;
        
        
    }
`;
const contImgManyImgVariants = styled.div`
    border: none;
    width: 100%;
    padding: 5px 10px;
    border-top: 0.5px rgba(112,112,112,0.3) solid;
    border-bottom: 0.5px rgba(112,112,112,0.3) solid;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: ${props => props.isClothing ? 'repeat(auto-fit, minmax(60px, 60px))' : 'repeat(auto-fit, minmax(150px, auto))' };
    `;
    const contImgGridVariants = styled.div`
    min-width: 50px;
    max-width: 50px !important;
    width: 50px !important;
    max-height: 50px !important;
    height: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-left: 3%;
    margin-right: 3%;
    background-image: url(${props=> props.img ? '"'+`${props.img}`+'"' : ""});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    box-shadow:${props => props.sh ? '0px 0px 12px 2px #7367f0' : ''};
    &:hover {
            cursor: pointer;
            border-color: #7367f0;
            box-shadow:0px 0px 12px 2px #7367f0;
        }
.img-slider {
        width: 100%;
        
        &:hover {
            cursor: pointer;
            border-color: #7367f0;
        }
    }
`;
const constDataTextVariant = styled.div`
    border: 0.5px solid #7367F0;
    min-width:50px;
    width:auto;
    padding:7px;
    min-height:60px;
    transition: all ease 0.2s;
    color: ${props => props.isActive ? '#FFFFFF' : '#7367F0'};
    display:flex;
    justify-content:center;
    align-items:center;
    font-weight: 550;
    background: ${props => props.isActive ? '#7367F0' : 'white'};
    &:hover {
        color: #FFFFFF;
        background: #7367F0;
    }
`;
const btn = styled.button`
padding:${props => props.pd || "8px 16px"};
display:flex;
justify-content:center;
align-items:center;
font-size:${props => props.fontSize || "12pt"};
outline:none !important;
color: ${props => props.colorFont || "#7364060"};
background-color: ${props => props.bgColor || "#7364060"};
border:none;
border-radius: 5px;
margin-top: ${props=> props.mt || '0'};
margin-left:10px;
`;
const textArea = styled.textarea`
    padding-left:10px;
    padding-right:10px;
    font-family: "Berlin Rounded";
    width: 100%;
    margin-left: ${props=> props.ml || '0'};
    margin-right: ${props=> props.mr || '0'};
    height: ${props=> props.hg || '300px'};
    border-radius: 5px;
    background: #fff;
    border: 0.5px solid ${props=> props.bdgc || 'rgba(112, 112, 112, 0.7)'};
    outline: none !important;
    color: #707070;
    font-size: 12pt;
    font-weight: 300;
    resize:none;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
    &:focus {
        border: 0.5px solid rgba(115, 103, 240, 0.7);
    }
        /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg,#7367f0,rgba(115,103,240,.7));
        border-radius: 50px;
        
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {        
        box-shadow: 2px 0px 6px #7367f0;
        
    }
`;

const inputTitle = styled.input.attrs((props) => ({
    border: props.border || 'none',
}))`
    width: 90%;
    color: #707070;
    font-size: 25px;
    outline: none !important;
    border: none;
    border-bottom: 1px solid transparent;
    &:focus {
        border-bottom: 1px solid #212529;
    }
`;

const inputTitle2 = styled.input.attrs((props) => ({
    border: props.border || 'none',
    width: props.w || '100%'
}))`
    width: ${props => props.width};
    color: #707070;
    font-size: 12pt;
    outline: none !important;
    border: none;
    height: 20px;
    padding-left: 10px;
    border-bottom: 1px solid transparent;
    &:focus {
        border-bottom: 1px solid #212529;
    }
`;
const AmazonStyles = {
    headerOptions,
    Contenedor,
    cardProduct,
    contImgCardModal,
    contImgManyImgCards,
    contImgGrid,
    contImgManyImgVariants,
    contImgGridVariants,
    constDataTextVariant,
    breadCrumbs,
    btnBack, 
    btn,
    textArea,
    HeaderContainer,
    inputTitle,
    inputTitle2
}
export default AmazonStyles;