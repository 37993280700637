import React from 'react'
import GridStyles from '../../../../../assets/css/components/admin/products/Orders/GridStyles'

export default function ShippingInformation() {
    return (
        <GridStyles.stepThree>
            <GridStyles.stepThreeHeader>
                <GridStyles.text fw="500" size="15pt" ml="10px">
                    Datos de envío
                </GridStyles.text>
            </GridStyles.stepThreeHeader>
            <GridStyles.stepThreeBody>
                <GridStyles.contInputs>
                    <GridStyles.contInputAndLabel>
                        <GridStyles.text fw="500" size="12pt">
                            Nombre
                        </GridStyles.text>
                        <GridStyles.input w="300px" placeholder="Esriba aqui"/>
                    </GridStyles.contInputAndLabel>
                    <GridStyles.contInputAndLabel>
                        <GridStyles.text fw="500" size="12pt">
                            Apellido
                        </GridStyles.text>
                        <GridStyles.input w="300px" placeholder="Esriba aqui"/>
                    </GridStyles.contInputAndLabel>
                </GridStyles.contInputs>
                <GridStyles.contInputs>
                    <GridStyles.contInputAndLabel>
                        <GridStyles.text fw="500" size="12pt">
                            ID
                        </GridStyles.text>
                        <GridStyles.input w="300px" placeholder="Esriba aqui"/>
                    </GridStyles.contInputAndLabel>
                    <GridStyles.contInputAndLabel>
                        <GridStyles.text fw="500" size="12pt">
                            Correo
                        </GridStyles.text>
                        <GridStyles.input w="300px" placeholder="Esriba aqui"/>
                    </GridStyles.contInputAndLabel>
                </GridStyles.contInputs>
                <GridStyles.contInputs>
                    <GridStyles.contInputAndLabel>
                        <GridStyles.text fw="500" size="12pt">
                            País
                        </GridStyles.text>
                        <GridStyles.select wd="300px">
                            <option value="">Seleccione uno</option>
                        </GridStyles.select>
                    </GridStyles.contInputAndLabel>
                    <GridStyles.contInputAndLabel>
                        <GridStyles.text fw="500" size="12pt">
                            Estado
                        </GridStyles.text>
                        <GridStyles.select wd="300px">
                            <option value="">Seleccione uno</option>
                        </GridStyles.select>
                    </GridStyles.contInputAndLabel>
                </GridStyles.contInputs>
                <GridStyles.contInputs>
                    <GridStyles.contInputAndLabel>
                        <GridStyles.text fw="500" size="12pt">
                            Ciudad
                        </GridStyles.text>
                        <GridStyles.select wd="300px">
                            <option value="">Seleccione uno</option>
                        </GridStyles.select>
                    </GridStyles.contInputAndLabel>
                    <GridStyles.contInputAndLabel>
                        <GridStyles.text fw="500" size="12pt">
                            Direccion
                        </GridStyles.text>
                        <GridStyles.input w="300px" placeholder="Esriba aqui"/>
                    </GridStyles.contInputAndLabel>
                </GridStyles.contInputs>
                <GridStyles.contInputs>
                    <GridStyles.contInputAndLabel>
                        <GridStyles.text fw="500" size="12pt">
                            Codigo postal
                        </GridStyles.text>
                        <GridStyles.input w="300px" placeholder="Esriba aqui"/>
                    </GridStyles.contInputAndLabel>
                    <GridStyles.contInputAndLabel>
                        <GridStyles.text fw="500" size="12pt">
                            Telefono
                        </GridStyles.text>
                        <GridStyles.input w="300px" placeholder="Esriba aqui"/>
                    </GridStyles.contInputAndLabel>
                </GridStyles.contInputs>
                <div className="text-right wd-100 p-2">
                    <GridStyles.btnSave>
                        Guardar
                    </GridStyles.btnSave>
                </div>
            </GridStyles.stepThreeBody>
        </GridStyles.stepThree>
    )
}
