import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
const pausePublication = async (entId,idProduc) => {
    const res = await  axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/pause/${idProduc}`, {}, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    });
    return res;
}

export default pausePublication;