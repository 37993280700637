import React, { useContext, useEffect, useState } from 'react'
import LinioCampaingStyle from '../../../../assets/css/components/admin/linio-campaign/LinioCampaingStyle';
import ListCamapaing from '../components/ListCamapaing';
import getListPrices from '../functions/getPricesList';
import { GlobalContext } from '../../../..//contexts/GlobalContext'
import saveData from '../functions/saveData';
import getCampains from '../functions/getCampains';
import axios from 'axios';
import DateTime from '../components/DateTime';
import ProductsCampaing from '../components/ProductsCampaing';
import { Redirect } from "react-router-dom";
import Routes from '../../../../Routes';
import verifyAccessRole from '../../../../assets/js/verifyAccessRole';

export default function LinioCampaings() {
    const [priceLists, setPriceLists] = useState([]);
    const [priceListsVal, setPriceListsVal] = useState(null);
    const [nameCampaing, setNameCampaing] = useState(null);
    const [percentVal, setPercenVal] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageItemCount, setPageItemCount] = useState(50);
    const [totalData, setTotalData] = useState(0);
    const [isSending, setIsSending] = useState(false)
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [data, setData] = useState([]);
    const [openModal, setOpenModal] = useState(false)
    const [idCampaing, setIdCampaing] = useState(null);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [redirect, setRedirect] = useState(null);
    const globalContext = useContext(GlobalContext);

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 13);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        getListPrices(entId).then(resp => {
            const data = resp.data.data;
            setPriceLists(data.priceLists);
        }).catch((err) => {
            console.log(err)
        }
        );
    }, [globalContext.currentEnterpriseId]);

    const sendData = async () => {
        if(!priceListsVal) return;
        if(!nameCampaing) return;
        if(!percentVal) return;
        if(dateStart.length === 0) return;
        if(dateEnd.length === 0) return;
        setIsSending(true);
        const data = {
            isRegister: true,
            campaignName: nameCampaing,
            pricelistId: priceListsVal,
            plusPercent: percentVal,
            dateStart: dateStart,
            dateEnd: dateEnd
        }
        const entId = globalContext.currentEnterpriseId;
        saveData(entId, data)
            .then((res) => {
                globalContext.showModalWithMsg('Registro exitoso!')
                getCampains(entId, axiosCancelTokenSource, pageNumber, pageItemCount)
                .then(res => {
                    const data = res.data.data.campaigns;
                    setData(data)
                    setIsSending(false);
                    console.log('la data', data);
                }).catch(err => {
                    console.log(err);
                    setIsSending(false);
                })
            }).catch((err) => {
                console.log('no se guardo?', err);
                setIsSending(false);
            })
    }

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        getCampains(entId, axiosCancelTokenSource, pageNumber, pageItemCount)
            .then(res => {
                const data = res.data.data.campaigns;
                setData(data)
                console.log('la data', data);
            }).catch(err => {
                console.log(err)
            })
    }, [globalContext.currentEnterpriseId])
    return (
        <>
            <LinioCampaingStyle.Cont>
                {redirect}
                <LinioCampaingStyle.Header>
                    <LinioCampaingStyle.Text size="15pt" fw="500">
                        Campañas de Linio
                    </LinioCampaingStyle.Text>
                </LinioCampaingStyle.Header>
                <LinioCampaingStyle.ContForm>
                    <LinioCampaingStyle.HeaderForm>
                        <LinioCampaingStyle.Text size="13pt" fw="400">
                            Nueva Campaña
                        </LinioCampaingStyle.Text>
                    </LinioCampaingStyle.HeaderForm>
                    <LinioCampaingStyle.BodyForm>
                        <LinioCampaingStyle.ContInputAndLabel>
                            <LinioCampaingStyle.Text>
                                Nombre de la Campaña
                            </LinioCampaingStyle.Text>
                            <LinioCampaingStyle.Input onChange={(e) => setNameCampaing(e.target.value)} placeholder="Escriba aqui..." />
                        </LinioCampaingStyle.ContInputAndLabel>
                        <LinioCampaingStyle.ContInputAndLabel>
                            <LinioCampaingStyle.Text>
                                Lista de Precio
                            </LinioCampaingStyle.Text>
                            <LinioCampaingStyle.Select onChange={(e) => setPriceListsVal(e.target.value)}>
                                <option value={null}>Seleccione uno</option>
                                {
                                    priceLists.length > 0 ?
                                        priceLists.map((v) => {
                                            return (
                                                <option value={v.id} key={v.id}>{v.name}</option>
                                            )
                                        }) :
                                        (
                                            <option value={""}>Obteniendo Data...</option>
                                        )
                                }
                            </LinioCampaingStyle.Select>
                        </LinioCampaingStyle.ContInputAndLabel>

                        <LinioCampaingStyle.ContInputAndLabel>
                            <LinioCampaingStyle.Text>
                                % de Aumento
                            </LinioCampaingStyle.Text>
                            <LinioCampaingStyle.Input onChange={(e) => setPercenVal(e.target.value)} placeholder="Escriba aqui..." />
                        </LinioCampaingStyle.ContInputAndLabel>

                        <LinioCampaingStyle.ContInputAndLabel>
                            <LinioCampaingStyle.Text>
                                Fecha de inicio
                            </LinioCampaingStyle.Text>
                            <DateTime setValue={setDateStart} valueDefaultText="Fecha de inicio" />
                        </LinioCampaingStyle.ContInputAndLabel>

                        <LinioCampaingStyle.ContInputAndLabel>
                            <LinioCampaingStyle.Text>
                                Fecha de fin
                            </LinioCampaingStyle.Text>
                            <DateTime setValue={setDateEnd} valueDefaultText="Fecha de culminacion" />
                        </LinioCampaingStyle.ContInputAndLabel>

                    </LinioCampaingStyle.BodyForm>
                    <LinioCampaingStyle.FooterForm>
                        <LinioCampaingStyle.BtnSend disabled={isSending} onClick={() => sendData()}>
                            {isSending ? 'Enviando...': `Guardar`}
                        </LinioCampaingStyle.BtnSend>
                    </LinioCampaingStyle.FooterForm>
                </LinioCampaingStyle.ContForm>
                <ListCamapaing
                    data={data}
                    setIdCampaing={setIdCampaing}
                    setOpenModal={setOpenModal}
                    priceListsVal={priceLists}
                />
            </LinioCampaingStyle.Cont>
            <ProductsCampaing 
            idCampaing={idCampaing}
            setIdCampaing={setIdCampaing}
            active={openModal}
            setOpenModal={setOpenModal}
            entId={globalContext.currentEnterpriseId} 
            axiosCancelTokenSource={axiosCancelTokenSource} 
            dataInit={data}
            setDataInit={setData}
            />
        </>
    )
}
