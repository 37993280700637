import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  keyUserId,
  keyAccessToken,
} from "../../../../../assets/js/SessionStorageNames";
import axios from "axios";
import TableCategories from "./TableCategories";
import { FaDownload } from "react-icons/fa";

export default function SyncProdByCategories() {
  const globalContext = useContext(GlobalContext);
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());

  const handleAxiosError = (err, msg = null, consoleLog = true) => {
    if (axios.isCancel(err)) return; //Don't show axios cancel error

    if (err.response && err.response.data && err.response.data.message) {
      globalContext.showModalWithMsg(err.response.data.message);
    } else if (msg) {
      globalContext.showModalWithMsg(msg);
    }

    if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
  };

  /**
   * Cancel axios requests before unmount
   */
  useEffect(() => {
    return () => {
      axiosCancelTokenSource.cancel("Canceled by unmount");
    };
    //eslint-disable-next-line
  }, []);

  

  

  /**
   * Show the modal to select the pricelists to download
   * @param {*} selectValue The values currently selected
   */
  const showModal = (selectValue) => {
    globalContext.showModalWithMsg(
      <TableCategories downloadProd={downloadProd} />,
      "Seleccione la categoria para descargar",
      {
        size: "xl",
        useConfirmButton: true,
        confirmButtonText: "Cerrar",
        handleConfirm: () => {
          globalContext.setShowModal(false);
        },
      }
    );
  };

  const downloadProd = (categoryId) => {
    const entId = globalContext.currentEnterpriseId;
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}/downProdCat/${categoryId}`,
        {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        console.log("se descarga", resp.data);
        globalContext.showModalWithMsg("El proceso se esta ejecutando en segundo plano con exito!")
      })
      .catch((err) => {
        handleAxiosError(
          err,
          "Disculpe, no se pudo cargar la lista de tarifas."
        );
      });
  };

  return (
    <div
      className={`items`}
      onClick={() => {
        showModal([]);
      }}
    >
      <span className="ic"></span>
      <p>Descargar productos por categorias</p>
    </div>
  );
}
