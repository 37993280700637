import axios from "axios";
import { keyAccessToken, keyUserId } from "../../../../../assets/js/SessionStorageNames";

export const sendCredencials = (data) => {
    const {url, consumerKey, consumerSecret, version, enterpriseId} = data;

    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/woo-acc/wooUser`,
        {
            consumerKey,
            consumerSecret,
            enterpriseId,
            url,
            version,
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}


export const getCredencials = async  (enterpriseId) => {
    const resp = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/v1/woo-acc/get-cred/${enterpriseId}`,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return resp
}