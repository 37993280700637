import sendProductWooAxios from "./sendProductWooAxios"

const sendProductWoo = async (arrayIds, entId, axiosCancelTokenSource) => {
for await (let id of arrayIds) {
    sendProductWooAxios(id, 'amazonScrap', entId, axiosCancelTokenSource)
    .then(res => {
        console.log('okok', res);
    }).catch(err => {
        console.log('nono', err);
    })
}
console.log('temino el for')
}

export default sendProductWoo