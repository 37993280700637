import GridStyles from '../../../../../assets/css/components/admin/products/Orders/GridStyles'
import React, { useContext, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames'
import axios from 'axios';
import { GlobalContext } from '../../../../../contexts/GlobalContext'
export default function Novelties(props) {
    const { dataOrder, actualNoveltys, setactualNoveltys, amazonData, dataPrice, idProdAmazon, idOrder} = props;
    const globalContext = useContext(GlobalContext);
    const [status, setStatus] = useState('');
    const [novelty, setNovelty] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [tracking, setTracking] = useState('');

    const setactualNoveltysFunc = (v) => {
        setactualNoveltys(v);
    }

    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    //? enviamos el tracking 

    const setTrackingFunc = async (entId) => {
        const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/order/${entId}/amazon-scrap-status/${dataOrder._id}`, {
            trackingNumber: tracking,
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }

    //? meytodo para crear o agg una novedad
    const addNewNovelty = async (entId) => {
        const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/order/${entId}/amazon-scrap-status/${dataOrder._id}/status`, {
            status: status,
            novelty: novelty, //Este es opcional
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }
    //? meytodo para crear el producto y generar el presupuesto
    //todo verificar de donde sale el list price
    const addProdOdoo = async (entId, dataProd, dataPrice, idProdAmazon) => {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}/product`, 
        {
            product: {
                ekomId: idProdAmazon,
                idOrder: idOrder,
                name: dataProd.title.es,
                default_code: dataProd.asin,
                barcode: dataProd.asin,
                list_price: dataProd.price,
                weight: dataProd.weight,
                volume: (dataProd.dimensions.length + dataProd.dimensions.width + dataProd.dimensions.height),
                price: dataPrice ? !dataPrice.variants ? dataPrice.total : dataPrice.variants[0].total : '',
            }, //Este es opcional
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        console.log('lo que envia', res)
        return res;
    }

    const saveData = async () => {

        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }
        if (tracking.length === 0 && novelty.length === 0 && status.length === 0) {
            globalContext.showModalWithMsg("Debe aggregar algun dato");
            return;
        }
        setIsSending(true);
        if (novelty.length > 0 && status.length > 0) {
            //? aqui va lo de jesus
            //? validamos si el status es orden pagada
            if(!idProdAmazon) {
                handleAxiosError('no posee id');
                setIsSending(false);
            };
            if (status === 'confirmed') {

                addProdOdoo(entId, amazonData, dataPrice, idProdAmazon)
                .then(res => {
                        //console.log(resp.data)
                        addNewNovelty(entId)
                            .then(resp => {
                                //console.log(resp.data);
                                let dataCopy = [...actualNoveltys];
                                dataCopy.push({
                                    novelties: [{
                                        novelty: novelty
                                    }],
                                    status: status
                                });
                                //console.log(dataCopy);
                                setactualNoveltysFunc(dataCopy);
                                globalContext.showModalWithMsg("Datos guardados exitosamente!");
                                setIsSending(false);
                            }).catch(err => {
                                handleAxiosError(err);
                                setIsSending(false);
                            });
                    
                }).catch(error => {
                    handleAxiosError(error);
                    setIsSending(false);
                })
            } else {
                await setTrackingFunc(entId)
                    .then(async resp => {
                        //console.log(resp.data)
                        await addNewNovelty(entId)
                            .then(resp => {
                                //console.log(resp.data);
                                let dataCopy = [...actualNoveltys];
                                dataCopy.push({
                                    novelties: [{
                                        novelty: novelty
                                    }],
                                    status: status
                                });
                                //console.log(dataCopy);
                                setactualNoveltysFunc(dataCopy);
                                globalContext.showModalWithMsg("Datos guardados exitosamente!");
                                setIsSending(false);
                            }).catch(err => {
                                handleAxiosError(err);
                                setIsSending(false);
                            });
                    }).catch(err => {
                        handleAxiosError(err);
                        setIsSending(false);
                    });
            }
        } else if (tracking.length === 0) {
            if (status.length === 0) {
                globalContext.showModalWithMsg("El estatus no puede estar vacio");
                setIsSending(false);
                return;
            }
            if (novelty.length === 0) {
                globalContext.showModalWithMsg("La novedad no puede estar vacia");
                setIsSending(false);
                return;
            }
            await addNewNovelty(entId)
                .then(resp => {
                    console.log(resp.data);
                    let dataCopy = [...actualNoveltys];
                    dataCopy.push({
                        novelties: [{
                            novelty: novelty
                        }],
                        status: status
                    });
                    //console.log(dataCopy);
                    setactualNoveltysFunc(dataCopy);
                    globalContext.showModalWithMsg("Datos guardados exitosamente!");
                    setIsSending(false);
                }).catch(err => {
                    handleAxiosError(err);
                    setIsSending(false);
                });
        } else if (tracking.length > 0 && novelty.length === 0 && status.length === 0) {
            setTrackingFunc(entId)
                .then(async resp => {
                    console.log(resp.data)
                    console.log(resp.data);
                    globalContext.showModalWithMsg("Nro tracking guadado exitosamente!");
                    setIsSending(false);
                }).catch(err => {
                    handleAxiosError(err);
                    setIsSending(false);
                });
        }
    }

    const renderStatusName = (status) => {
        switch (status) {
            case 'paid':
                return 'Pedido de venta';
            case 'confirmed':
                return 'Orden pagada';
            case 'bought':
                return 'Comprado';
            case 'bought_delayed':
                return 'Comprado en demora';
            case 'received_warehouse_int':
                return 'Recibido bodega miami';
            case 'transit_int':
                return 'En transito internacional';
            case 'customs_received':
                return 'Recibido aduana';
            case 'received_warehouse_local':
                return 'Recibido bodega venezuela';
            case 'transit_local':
                return 'Transito cliente';
            case 'delivered':
                return 'Producto entregado cliente';
            case 'lost':
                return 'Perdido';
            case 'canceled':
                return 'Orden cancelada';
            case 'defective':
                return 'Orden defectuosa';
            case 'return_provider':
                return 'Devolucion a provedor';
            case 'return_store':
                return 'Devolucion a tienda';
            case 'other':
                return 'Otros';
            default:
                return '';
        }
    }
    // console.log('noedades Viejas', actualNoveltys);
    // console.log('status', status)

    return (
        <GridStyles.stepThree>
            <GridStyles.stepThreeHeader>
                <GridStyles.text fw="500" size="15pt" ml="10px">
                    Novedades
                </GridStyles.text>
            </GridStyles.stepThreeHeader>
            <GridStyles.stepThreeBody>
                <GridStyles.contInputs>
                    <GridStyles.contInputAndLabel style={{ width: '100%' }}>
                        <GridStyles.text fw="500" size="12pt">
                            Estado
                        </GridStyles.text>
                        <GridStyles.select wd="100%" onChange={(e) => setStatus(e.target.value)} disabled={isSending}>
                            <option value="">Seleccione uno</option>
                            <option value="paid">Pedido de venta</option>
                            <option value="confirmed">Orden pagada</option>
                            <option value="bought">Comprado</option>
                            <option value="bought_delayed">Comprado en demora</option>
                            <option value="received_warehouse_int">Recibido bodega miami</option>
                            <option value="transit_int">En transito internacional</option>
                            <option value="customs_received">Recibido aduana</option>
                            <option value="received_warehouse_local">Recibido bodega venezuela</option>
                            <option value="transit_local">Transito cliente</option>
                            <option value="delivered">Producto entregado cliente</option>
                            <option value="lost">Perdido</option>
                            <option value="canceled">Orden cancelada</option>
                            <option value="defective">Orden defectuosa</option>
                            <option value="return_provider">Devolucion a provedor</option>
                            <option value="return_store">Devolucion a tienda</option>
                            <option value="other">Otros</option>
                        </GridStyles.select>
                    </GridStyles.contInputAndLabel>
                </GridStyles.contInputs>
                {
                    (status !== 'confirmed') &&
                    (
                        <GridStyles.contInputs>
                            <GridStyles.contInputAndLabel style={{ width: '100%' }}>
                                <GridStyles.text fw="500" size="12pt">
                                    Tracking Nro
                                </GridStyles.text>
                                <GridStyles.input placeholder='Escriba aqui' onChange={(e) => setTracking(e.target.value)} />
                                <GridStyles.text size="11pt">
                                    { dataOrder.trackingNumber ? dataOrder.trackingNumber : ''}
                                </GridStyles.text>
                            </GridStyles.contInputAndLabel>
                        </GridStyles.contInputs>
                    )
                }
                <GridStyles.contInputs>
                    <GridStyles.contInputAndLabel style={{ width: '100%' }}>
                        <GridStyles.text fw="500" size="12pt">
                            Novedad
                        </GridStyles.text>
                        <GridStyles.textArea placeholder="Esriba aqui" onChange={(e) => setNovelty(e.target.value)} disabled={isSending} />
                    </GridStyles.contInputAndLabel>
                </GridStyles.contInputs>
                <div className="text-right wd-100 p-2">
                    <GridStyles.btnSave onClick={() => saveData()} disabled={isSending}>
                        {
                            !isSending ? 'Guardar' : (
                                <Spinner animation="border" role="status" style={{ width: '1rem', height: '1rem' }}>
                                    <span className="sr-only">Enviando...</span>
                                </Spinner>
                            )
                        }
                    </GridStyles.btnSave>
                </div>
                <GridStyles.textAreaCont>
                    <GridStyles.contTwoText>
                        <GridStyles.text mt="0" mb="0" fw="500">
                            Listado de novedades
                        </GridStyles.text>
                    </GridStyles.contTwoText>
                </GridStyles.textAreaCont>
                {
                    actualNoveltys.length > 0 && actualNoveltys.map((v, i) => {
                        return (
                            <div style={{ width: '100%' }} key={i}>
                                <GridStyles.textAreaCont>
                                    <GridStyles.contTwoText>
                                        <GridStyles.text mt="0" mb="0" fw="500">
                                            {`Estatus: ${renderStatusName(v.status)}`}
                                        </GridStyles.text>
                                    </GridStyles.contTwoText>
                                </GridStyles.textAreaCont >
                                {
                                    v.novelties && v.novelties.map((v, i) => {
                                        return (
                                            <GridStyles.textAreaCont key={i} style={{ padding: 10 }}>
                                                <GridStyles.textAreaView defaultValue={v.novelty} readOnly />
                                            </GridStyles.textAreaCont>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                    )
                }
            </GridStyles.stepThreeBody>
        </GridStyles.stepThree>
    )
}
