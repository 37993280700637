import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';

const sendToLinkCat = (dataCatToLink, entId, axiosCancelTokenSource) => {
    return axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/category/${entId}/link`, {
          categories: dataCatToLink.dataToSend
        }, {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
        }
      )
}

export default sendToLinkCat