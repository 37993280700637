import axios from 'axios'
import {keyAccessToken, keyUserId} from '../../../../../assets/js/SessionStorageNames'
export const updateCatOdoo = (enterpriseId) => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/update-all-odoo-categories-by-ent`,
        {
            enterpriseId,
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export default updateCatOdoo;