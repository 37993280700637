import styled from 'styled-components';

const Cont = styled.div`
display: block;
width: 100%;
padding-right:10px;
@media (max-width: 990px){
    padding: 10px;
}
`;

const Header = styled.div `
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
/* border: 1px solid; */
margin-top: 10px;
margin-bottom: 10px;
`;

const ContInputAndLabel = styled.div `
width:${props=> props.w || '300px'};
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'space-between'};
align-items:${props => props.ali || 'flex-start'};
margin-right: ${props=> props.mr || '0'};
`;

const Text = styled.p `
color: ${props => props.cl || '#707070'};
font-size: ${props => props.size || '.857rem'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '.2857rem'};
margin-bottom: ${props => props.mb || '.2857rem'};
text-overflow:${props => props.longText ? 'ellipsis' : ''};
overflow:${props => props.longText ? 'hidden' : ''};
white-space:${props => props.longText ? 'nowrap' : ''};
`;

const TextLink = styled.a `
color: ${props => props.cl || '#707070'};
font-size: ${props => props.size || '.857rem'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '.2857rem'};
margin-bottom: ${props => props.mb || '.2857rem'};
text-overflow:${props => props.longText ? 'ellipsis' : ''};
overflow:${props => props.longText ? 'hidden' : ''};
white-space:${props => props.longText ? 'nowrap' : ''};
text-decoration: none;
`;

const ContForm = styled.div`
min-width: 300px;
width: auto;
max-width: 1000px;
border-radius: .428rem;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
background: #FFFFFF;
display: flex;
justify-content: center;
align-items: flex-end;
flex-wrap: wrap;
margin-left: auto;
margin-right: auto;
`;

const HeaderForm = styled.div`
width: 100%;
padding: .5rem;
text-align: left;
border-radius: .428rem  .428rem 0 0;
border-bottom: 1px solid #ebe9f1;
`;

const BodyForm = styled.div`
min-height: 100px;
background: #FFFFFF;
padding: 1rem;
width: 100%;
display: grid;
grid-gap: .5rem;
grid-auto-rows: auto;
grid-template-columns: repeat(auto-fill, minmax(300px, 300px));
transition: all ease-in-out .2s;
&:hover {
    background: ${props=> props.active ? '#f0f0f0' : '#FFFFFF'};
}
`;
const FooterForm = styled.div`
width: 100%;
min-height: 50px;
background: #FFFFFF;
display: flex;
justify-content: flex-end;
align-items: flex-end;
margin-bottom: 0px;
border-top: 1px solid #ebe9f1;
`;

const BtnSend = styled.div`
padding: 8px 16px;
border-radius: .428rem 0 .428rem 0;
outline: none !important;
background: #7367F0;
color: #FFFFFF;
border: none !important;
&:disabled {
background: #9991f5;
}
cursor: pointer;
`;

const Input = styled.input`
    width: ${props => props.w || '100%'};
    height: 38px;
    border-radius: .357rem;
    background: #fff;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    border: 1px solid #d8d6de;
    outline: none !important;
    padding:6px 16px;
    font-size:12pt;
    color: rgb(112, 112, 112);
    font-weight: 600;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12px;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus, &:active {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
            box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
        }
    margin-top : ${props => props.mt || 0};
`;

const Select = styled.select`
    padding-left:10px;
    padding-right:10px;
    font-family: "Berlin Rounded";
    width: ${props => props.wd || '100%'};
    margin-left: ${props => props.ml || '0'};
    margin-right: ${props => props.mr || '0'};
    height: 38px;
    border-radius: .357rem;
    background: white;
    border: 1px solid #d8d6de;
    outline: none !important;
    font-family: "Berlin Rounded";
    font-weight: 300;
    font-size: 12px;
    color: #707070;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12px;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
            box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
        }
        @media(max-width: ${props => props.selectMq || '989px'}) {
        width:100%;
    }
`;

const ConstList = styled.div`
min-width: 300px;
width: auto;
max-width: 1000px;
border-radius: .428rem;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
background: #FFFFFF;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 1rem;
`

const ContModal = styled.div`
height: 100%;
transition: all ease-in 0.01s;
width:100%;
background-color:rgba(112,112,112,0.50);
position: fixed;
left:0;
top:0;
right:0;
bottom:0;
transition: all ease-out 0.1s;
transform: translateY(${props=> props.active ? '0px' : '-2000px'});
z-index:100000000 !important;
display:flex;
justify-content:center;
align-items:center;
display: flex;
justify-content:center;
align-items:center;
overflow-y: auto;
`;

const ModalCamp = styled.div`
min-width:300px;
min-height: 300px;
width: ${props=> props.w || "50%"};
margin-top:auto;
margin-bottom:auto;
margin-left:10px;
background: #FFF;
border-radius: .429rem;
filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
transition: all ease-out 0.30s;
transform: translateY(${props=> props.active ? '0px' : '-2000px'});
display: flex;
flex-direction: column;
justify-content:space-between;
align-items:center;
margin-left:60px;

@media (max-width: 990px) {
    margin-left:10%;
    margin-right:10%;
}
`
const HeaderModal = styled.div`
padding:10px;
height: 60px;
width:100%;
background:#F5F5F6;
display: flex;
justify-content:space-between;
align-items:center;
align-self: flex-start;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
`
const FooterModal = styled.div`
height: 60px;
width:100%;
display: flex;
justify-content:flex-end;
align-items:center;
padding: .5rem;
align-self: flex-end;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
border-top: 1px solid #ebe9f1;
margin-top: auto;
margin-bottom:0;
`;

const BodyModal = styled.div`
padding:1rem;
height: auto;
width:100%;
max-height: 600px;
overflow: auto;
margin-top: auto;
margin-bottom:auto;
/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 50px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #7367f0;
  }
`;

const BtnCloseModal = styled.button`
width:38px;
height: 38px;
border-radius:50px;
border:none;
color: #ffffff;
background-color: #7367f0;
display:flex;
justify-content:center;
align-items:center;
outline:none !important;
cursor: pointer;
margin-right: 0;
`
const listProducts = styled.ul`
width: 100%;
padding-left: 0;
list-style: none;
`;

const itemList = styled.li`
width: 100%;
padding: .5rem;
border-bottom: 1px solid #ebe9f1;
height: auto;
`;

const BtnDeleteCampaing = styled.button`
color: #FFFFFF;
font-size: 12pt;
text-align: center;
padding: 8px 16px;
font-weight: 500;
outline: none !important;
border-radius: .429rem;
background: #ea5455;
border: none;
&:disabled {
    background: rgba(234, 84, 85, 0.1);
}
`;
const LinioCampaingStyle = {
    Cont,
    Header,
    ContInputAndLabel,
    Text,
    TextLink,
    ContForm,
    HeaderForm,
    BodyForm,
    FooterForm,
    BtnSend,
    Input,
    Select,
    ConstList,
    ContModal,
    ModalCamp,
    HeaderModal,
    FooterModal,
    BodyModal,
    BtnCloseModal,
    listProducts,
    itemList,
    BtnDeleteCampaing
}

export default LinioCampaingStyle;