import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';

const downloadProduct = (
    entId,
    storeId,
    sectionId,
    axiosCancelTokenSource
    ) => {
    const res = axios.get(
        `${process.env.REACT_APP_BACKEND_URL_PEYA}/v1/peya-sdk/get-all-products/${storeId}/${entId}/${sectionId}`,
        {
            
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(
                    keyAccessToken
                ),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
    )
    return res;
}

export default downloadProduct;