import React, { useState } from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import FilterProductStyle from "../../../../assets/css/components/admin/products/FiltreProduct.css";

export default function FilterProduct(props) {
  const [currentFilter, setCurrentFilter] = useState(props.filter);

  /**
   * Updates the filter value
   */
  const updateCurrentFilter = (e) => {
    setCurrentFilter(e.target.value);
  };

  return (
    <FilterProductStyle>
      <InputGroup>
        <FormControl
          placeholder="Descripcion de tu producto"
          aria-label="Descripcion de tu producto"
          aria-describedby="basic-addon2"
          value={currentFilter}
          onChange={updateCurrentFilter}
          onKeyDown={e => e.key === 'Enter' ? props.updateFilter(currentFilter) : ''}
        />
        <InputGroup.Append>
          <Button
            onClick={() => {
              props.updateFilter(currentFilter);
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroup.Append>
      </InputGroup>  
    </FilterProductStyle>
  );
}
