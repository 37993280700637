import styled from "styled-components";

const AdminStyle = styled.div`
  * {
    /* width */
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #7367f0;
      border-radius: 50px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .page-wrapper {
    min-height: 100vh;
  }
  .spinnerLoader {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9999;
    top: 0;
    bottom: 0;
    background: rgb(255, 255, 255);
    .spinner-grow {
      background: rgb(94, 84, 142);
    }
    .contImgLogo {
      min-width: 300px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 1rem;
      p {
        margin-bottom: 0;
        font-size: 1;
        color: #7367F0;
        font-weight: 500;
        font-size: 18pt;
      }
    }
  }
`;
export default AdminStyle;
