import React from 'react'
import { useState, useEffect, useContext } from 'react'
import LinkeoMasivoCss from '../../../assets/css/components/admin/products/LinkeoMasivoCss'
import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../assets/js/SessionStorageNames';
import { GlobalContext } from "../../../contexts/GlobalContext";
import { Redirect } from "react-router-dom";
import Routes from '../../../Routes';
import verifyAccessRole from '../../../assets/js/verifyAccessRole';

export default function LinkeoMasivo() {
    const [plugin, setPlugin] = useState(null);
    const [divStock, setDivStock] = useState('');
    const [mulPrecio, setMulPrecio] = useState('');
    const [maxStock, setMaxStock] = useState('');
    const [listSku, setListSku] = useState('');
    const [mainPlugin, setMainPlugin] = useState(null);
    const globalContext = useContext(GlobalContext);
    const [redirect, setRedirect] = useState(null);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const entId = globalContext.currentEnterpriseId;

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 60);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);

        /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
         const handleAxiosError = (err, msg = null, consoleLog = true) => {
            if (axios.isCancel(err)) return; //Don't show axios cancel error
            if (err.response && err.response.data) {
                if (err.response.data.message) {
                    globalContext.showModalWithMsg(err.response.data.message);
                } else {
                    globalContext.showModalWithMsg(err.response.data);
                }
            } else if (msg) {
                globalContext.showModalWithMsg(msg);
            }
            if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
        };
    //search main plugin
    useEffect(() => {
        axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/v1/main-plugin/available`, {
            headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        .then((res) => setMainPlugin(res.data.data))
        .catch((err) => console.log(err));
    }, []);

    const senddata = () => {
        globalContext.setLoaderPage(true);
        if(!entId) return;
        axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/v1/link-product/link/${entId}`,{
                skus: listSku, 
                plugin: plugin, 
                priceMult: mulPrecio, 
                //maxStock: maxStock,
                stockDiv: divStock
            },{
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then((res) => {
            console.log(res)
            alert("Linkeo masivo exitoso");
            globalContext.setLoaderPage(false);
        })
        .catch((e) => { handleAxiosError(e, 'Presentamos un error al procesar su solicitud'); globalContext.setLoaderPage(false); })
    }
    return (
        <LinkeoMasivoCss.cont>
        {redirect}
            <LinkeoMasivoCss.contForm>
                <LinkeoMasivoCss.contRowForm j='center'>
                    <LinkeoMasivoCss.textBody fw="500">
                        Linkeo Masivo
                    </LinkeoMasivoCss.textBody>
                </LinkeoMasivoCss.contRowForm>
                <LinkeoMasivoCss.contRowForm j='center'>
                <LinkeoMasivoCss.contInputLabel mt="10px">
                    <LinkeoMasivoCss.textBody fw="500">
                        Seleccione su plugin
                    </LinkeoMasivoCss.textBody>
                    <LinkeoMasivoCss.select onChange={(e) => setPlugin(e.target.value)}>
                    <option value=''>Seleccione uno</option>
                    {
                        mainPlugin && 
                        mainPlugin.map((v,i) => {
                            return (<option key={i} value={v.id}>{v.name}</option>)
                        })
                    }
                    </LinkeoMasivoCss.select>
                </LinkeoMasivoCss.contInputLabel>
                </LinkeoMasivoCss.contRowForm>
                <LinkeoMasivoCss.contRowForm>
                <LinkeoMasivoCss.contInputLabel mt="10px" wd="300px">
                    <LinkeoMasivoCss.textBody fw="500">
                        Divisor de stock
                    </LinkeoMasivoCss.textBody>
                    <LinkeoMasivoCss.input placeholder="Escriba aqui" onChange={(e) => setDivStock(e.target.value)} />
                </LinkeoMasivoCss.contInputLabel>
                <LinkeoMasivoCss.contInputLabel mt="10px" wd="300px">
                    <LinkeoMasivoCss.textBody fw="500">
                        Multiplicador de precio
                    </LinkeoMasivoCss.textBody>
                    <LinkeoMasivoCss.input placeholder="Escriba aqui" onChange={(e) => setMulPrecio(e.target.value)} />
                </LinkeoMasivoCss.contInputLabel>
                
                </LinkeoMasivoCss.contRowForm>
                <LinkeoMasivoCss.contRowForm j='center'>
                <LinkeoMasivoCss.contInputLabel mt="10px">
                <LinkeoMasivoCss.textBody fw="500">
                        Lista de SKU
                    </LinkeoMasivoCss.textBody>
                    <LinkeoMasivoCss.textArea placeholder="pegue aqui sus sku separados por (,) " onChange={(e) => setListSku(e.target.value)} />
                </LinkeoMasivoCss.contInputLabel>
                </LinkeoMasivoCss.contRowForm>
                {
                    (plugin && listSku.length > 0 && mulPrecio.length > 0 && divStock.length > 0) && 
                    (
                        <LinkeoMasivoCss.contRowForm j="flex-end">
                            <LinkeoMasivoCss.btnActionFooter bc="#7367F0" bgc="#7367F0" cl="#FFFFFF" onClick={()=> senddata()}>
                                Procesar...
                            </LinkeoMasivoCss.btnActionFooter>
                        </LinkeoMasivoCss.contRowForm>
                    )
                }
            </LinkeoMasivoCss.contForm>
        </LinkeoMasivoCss.cont>
    )
}
