import React, {useState, useContext, useEffect} from 'react'
import IndexCss from '../../../../../assets/css/components/admin/products/ml/rangos-publicacion/IndexCss'
import CardProduct from './CardProduct'
import OtrosParametros from './OtrosParametros'
import Rangos from './Rangos'
import axios from 'axios';
import { GlobalContext } from '../../../../../contexts/GlobalContext'
import { keyAccessToken, keyUserId, userEmail } from '../../../../../assets/js/SessionStorageNames'
import { Redirect } from "react-router-dom";
import Routes from '../../../../../Routes'
import verifyAccessRole from '../../../../../assets/js/verifyAccessRole'
export default function Index() {
    const userEmailVali = localStorage.getItem(userEmail);
    const globalContext = useContext(GlobalContext);
    const [tariff, setTariff] = useState([]);
    const [earning, setEarning] = useState([]);
    const [isLoader, setIsLoader] = useState(true);
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 59);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);
    /**
     * * state by three
     */
     const [minAmount, setMinAmount] = useState('');
     const [maxAmount, setMaxAmount] = useState('');
     const [amount, setAmount] = useState('')
     const [porcentaje, setPorcentaje] = useState(true);
     const [arrayItemsByDelete, setArrayItemsByDelete] = useState([]);
     const [numeroArray, setNumeroArray] = useState(0)
     const [dataSave, setDataSave] = useState([]);
    /**
     * *state by one
     */
     const [dataSaveOne, setDataSaveOne] = useState([]);
     const [amountOne, setAmountOne] = useState('');
     const [porcentajeOne, setPorcentajeOne] = useState(true);
     const [arrayItemsByDeleteOne, setArrayItemsByDeleteOne] = useState([]);

    const [courierPlus, setCourierPlus] = useState('');
    const [courierPlusIsPercent, setCourierPlusIsPercent] = useState(false);
    const [poundPrice, setPoundPrice] = useState('');
    const [additionalPoundPrice, setAdditionalPoundPrice] = useState('');
    const [safe, setSafe] = useState('');
    const [safeIsPercent, setSafeIsPercent] = useState(true);
    const [ccTax, setCcTax] = useState('');
    const [ccTaxIsPercent, setCcTaxIsPercent] = useState(true);
    const [defaultTariff, setDefaultTariff] = useState('');
    const [defaultTariffIsPercent, setDefaultTariffIsPercent] = useState(true);
    const [iva, setIva] = useState('');
    const [defaultComission, setDefaultComission] = useState('');
    const [defaultComissionIsPercent, setDefaultComissionIsPercent] = useState(false);
    const [defaultMaxStock, setDefaultMaxStock] = useState('');
    const [deliveryDaysPlus, setDeliveryDaysPlus] = useState('');
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    
    const getCategoryById = async (id, entId) => {
        const res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/category`, {
            params: {
                cateId: id,
                entId: entId,
            },
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            }
        }
        )
        return res;
    }

    const setCategoryTextByArray = async (array, entId) => {
        if(array.length > 0) {
            const copyArray = [];
            await array.forEach((v) => {
                //console.log('el arr',entId)
                getCategoryById(v.categoryId, entId)
                .then((r) => {
                    const data = r.data.data.category
                    const newPropierity = {
                        categoryText: data.name.en,
                        categoryId: v.categoryId,
                        amount:parseInt(v.amount),
                        isPercent: v.porcentaje,
                    }
                    copyArray.push(newPropierity);
                    //console.log('la c', data.name.en)
                }).catch((e) => console.log('algo salio mal', e))
            });
            //console.log('la c', copyArray)
            setDataSaveOne(copyArray);
        }
    }

    const getValues = (entId) => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(async resp => {
            const data = resp.data.data.configData.publishPriceCalculation;
            const dataTwo = resp.data.data.configData
            // console.log('another',resp.data.data.configData)
            if (data) {
                await setCategoryTextByArray(data.tariff, entId)
            //setDataSaveOne([...data.tariff])
                data.earning ? setDataSave([...data.earning]) : setDataSave([])
                data.tariff ? setTariff([...data.tariff]) : setTariff([])
                data.earning ? setEarning([...data.earning]) : setEarning([])
                data.courierPlus ? setCourierPlus(data.courierPlus) : setCourierPlus('')
                data.courierPlusIsPercent ? setCourierPlusIsPercent(data.courierPlusIsPercent) : setCourierPlusIsPercent(false)
                data.poundPrice ? setPoundPrice(data.poundPrice) : setPoundPrice('')
                data.additionalPoundPrice ? setAdditionalPoundPrice(data.additionalPoundPrice) : setAdditionalPoundPrice('')
                data.safe ? setSafe(parseFloat(data.safe)) : setSafe('')
                data.safeIsPercent ? setSafeIsPercent(data.safeIsPercent) : setSafeIsPercent(false)
                data.ccTax ? setCcTax(parseFloat(data.ccTax)) : setCcTax('')
                data.ccTaxIsPercent ? setCcTaxIsPercent(data.ccTaxIsPercent) : setCcTaxIsPercent(false)
                data.defaultTariff ? setDefaultTariff(data.defaultTariff) : setDefaultTariff('')
                data.defaultTariffIsPercent ? setDefaultTariffIsPercent(data.defaultTariffIsPercent) : setDefaultTariffIsPercent(false)
                data.iva ? setIva(data.iva) : setIva('')
                data.defaultComission ? setDefaultComission(data.defaultComission) : setDefaultComission('')
                data.defaultComissionIsPercent ? setDefaultComissionIsPercent(data.defaultComissionIsPercent) : setDefaultComissionIsPercent(false)
                dataTwo.deliveryDaysPlus && setDeliveryDaysPlus(dataTwo.deliveryDaysPlus);
                dataTwo.defaultMaxStock && setDefaultMaxStock(dataTwo.defaultMaxStock);
            }
            //console.log('loq re',data)
            setIsLoader(false);
        }).catch(err => {
            handleAxiosError(err)
        });
    }
    useEffect(()=>{
        const entId = globalContext.currentEnterpriseId;
        
        if (!entId) return;
        getValues(entId)
        
    },[globalContext.currentEnterpriseId]);

    return (
        <IndexCss>
            {redirect}
            <CardProduct
                tariff={tariff}
                earning={earning}
                courierPlus={parseFloat(courierPlus)}
                courierPlusIsPercent={courierPlusIsPercent}
                poundPrice={parseFloat(poundPrice)}
                additionalPoundPrice={parseFloat(additionalPoundPrice)}
                safe={parseFloat(safe)}
                safeIsPercent={safeIsPercent}
                ccTax={parseFloat(ccTax)}
                ccTaxIsPercent={ccTaxIsPercent}
                defaultTariff={parseFloat(defaultTariff)}
                defaultTariffIsPercent={defaultTariffIsPercent}
                iva={parseFloat(iva)}
                ivaIsPercent={true}
                defaultComission={parseFloat(defaultComission)}
                defaultComissionIsPercent={defaultComissionIsPercent}
            />
            {
                userEmailVali !== 'edithson.g@212global.com' && userEmailVali !== 'barbara.a@212global.com' && (
                    <>
                        <Rangos
                        tariff={tariff}
                        setTariff={setTariff}
                        earning={earning}
                        setEarning={setEarning}
                        minAmount={minAmount}
                        setMinAmount={setMinAmount}
                        maxAmount={maxAmount}
                        setMaxAmount={setMaxAmount}
                        amount={amount}
                        setAmount={setAmount}
                        porcentaje={porcentaje}
                        setPorcentaje={setPorcentaje}
                        arrayItemsByDelete={arrayItemsByDelete}
                        setArrayItemsByDelete={setArrayItemsByDelete}
                        numeroArray={numeroArray}
                        setNumeroArray={setNumeroArray}
                        dataSave={dataSave}
                        setDataSave={setDataSave}
                        dataSaveOne={dataSaveOne}
                        setDataSaveOne={setDataSaveOne}
                        amountOne={amountOne}
                        setAmountOne={setAmountOne}
                        porcentajeOne={porcentajeOne}
                        setPorcentajeOne={setPorcentajeOne}
                        arrayItemsByDeleteOne={arrayItemsByDeleteOne}
                        setArrayItemsByDeleteOne={setArrayItemsByDeleteOne}
                        isLoader={isLoader}
                        />
                        <OtrosParametros 
                            setCourierPlus={setCourierPlus}
                            setCourierPlusIsPercent={setCourierPlusIsPercent}
                            setPoundPrice={setPoundPrice}
                            setAdditionalPoundPrice={setAdditionalPoundPrice}
                            setSafe={setSafe}
                            setSafeIsPercent={setSafeIsPercent}
                            setCcTax={setCcTax}
                            setCcTaxIsPercent={setCcTaxIsPercent}
                            setDefaultTariff={setDefaultTariff}
                            setDefaultTariffIsPercent={setDefaultTariffIsPercent}
                            setIva={setIva}
                            tariff={tariff}
                            earning={earning}
                            courierPlus={courierPlus}
                            courierPlusIsPercent={courierPlusIsPercent}
                            poundPrice={poundPrice}
                            additionalPoundPrice={additionalPoundPrice}
                            safe={safe}
                            safeIsPercent={safeIsPercent}
                            ccTax={ccTax}
                            ccTaxIsPercent={ccTaxIsPercent}
                            defaultTariff={defaultTariff}
                            defaultTariffIsPercent={defaultTariffIsPercent}
                            iva={iva}
                            defaultComission={defaultComission}
                            setDefaultComission={setDefaultComission}
                            defaultComissionIsPercent={defaultComissionIsPercent}
                            setDefaultComissionIsPercent={setDefaultComissionIsPercent}
                            defaultMaxStock={defaultMaxStock}
                            setDefaultMaxStock={setDefaultMaxStock}
                            deliveryDaysPlus={deliveryDaysPlus}
                            setDeliveryDaysPlus={setDeliveryDaysPlus}
                            isLoader={isLoader}
                        />
                    </>
                )
            }
        </IndexCss>
    )
}
