import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState, useEffect } from 'react'
import DefaultimgPostCss from '../../../../assets/css/components/admin/products/amazon/DefaultimgPostCss'
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
export default function DefaultimgPost() {
    const globalContext = useContext(GlobalContext)
    const [file, setFile] = useState(null);
    const [urlImg, setUrlImg] = useState('');
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [isLoader, setIsLoader] = useState(false);
    const [mlMaxImagesToUseValue, setMlMaxImagesToUseValue] = useState('');
    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const seleccImage = (e) => {
        const fileSelect = e.target.files;
        setFile(fileSelect[0])
        const url = URL.createObjectURL(fileSelect['0'])
        console.log(url);
        setUrlImg(url)
    }

    const setValueTotalImgPublish = async () => {
        const entId = globalContext.currentEnterpriseId;
        const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
            config: {
                mlMaxImagesToUse: mlMaxImagesToUseValue
            }
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }

    const publisImgDefault = async (formData) => {
        const entId = globalContext.currentEnterpriseId;
        const resp = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/set-publish-image`, formData, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
                'Content-Type': 'multipart/form-data',
            },
        })
        return resp;
    }
    const setPublishImage = async () => {
        setIsLoader(true);
        if (!file) {
            await setValueTotalImgPublish()
            .then((resp) => {
                console.log(resp)
                setIsLoader(false);
            }).catch((err) => {
                handleAxiosError(err); 
                setIsLoader(false);
                console.log('aquii')
            })
        }else {
            const formData = new FormData();
            formData.append('img', file);
            
            publisImgDefault(formData).then(async(resp) => {
                console.log(resp.data); 
                await setValueTotalImgPublish()
                .then((resp) => {
                    console.log(resp)
                    setIsLoader(false);
                }).catch((err) => {
                    handleAxiosError(err); 
                    setIsLoader(false);
                    console.log('aquii')
                })
            }).catch((err) => {
                handleAxiosError(err); 
                setIsLoader(false);
                console.log('aquii2')
            });
        }
    }
    //https://back.e-kommers.com/image/

    const getValue = (entId) => {
        console.log('la em', entId)
        setIsLoader(true)
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            }).then(resp => {
                setIsLoader(false)
                const data = resp.data.data.configData;
                //mlPublishImage
                setUrlImg(`https://back.e-kommers.com/image/${data.mlPublishImage}`)
                setMlMaxImagesToUseValue(data.mlMaxImagesToUse)
            }).catch(err => {
                setIsLoader(false)
                handleAxiosError(err)
                console.log('90086E')
            });
    }

    useEffect(() => {
        if(globalContext.modalSelectDefaultImg){
            const entId = globalContext.currentEnterpriseId;
            if (!entId) return;
            getValue(entId)
        }
    }, [globalContext.currentEnterpriseId, globalContext.modalSelectDefaultImg])
    
    const closeModal = () => {
        globalContext.setModalSelectDefaultImg(false);
        setFile(null)
        setUrlImg('')
        setMlMaxImagesToUseValue('')
    }
    return (
        <DefaultimgPostCss.cont active={globalContext.modalSelectDefaultImg}>
            <DefaultimgPostCss.modalImg>
                <DefaultimgPostCss.headerModal>
                    <DefaultimgPostCss.textHeader size='14pt' mr='auto'>
                        Seleccione la segunda imagen por defecto
                    </DefaultimgPostCss.textHeader>
                    <DefaultimgPostCss.btnCloseModal onClick={() => closeModal()}>
                        <FontAwesomeIcon icon={faTimes} />
                    </DefaultimgPostCss.btnCloseModal>
                </DefaultimgPostCss.headerModal>
                <DefaultimgPostCss.bodyModal>
                    {isLoader ? 
                    (
                        <DefaultimgPostCss.contLoader>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Cargando...</span>
                            </Spinner>
                        </DefaultimgPostCss.contLoader>
                    )
                    :
                    (
                        <>
                        <DefaultimgPostCss.inputFile>
                        <label className="file">
                            <input type="file"  aria-label="File browser example" onChange={seleccImage} />
                            <span className="file-custom"></span>
                        </label>
                        </DefaultimgPostCss.inputFile>
                        <DefaultimgPostCss.contImgSend img={urlImg} />
                        <DefaultimgPostCss.contInputAndLabel>
                            <DefaultimgPostCss.text mb='10px'>
                                Cantidad de imagenes
                            </DefaultimgPostCss.text>
                            <DefaultimgPostCss.input value={mlMaxImagesToUseValue} placeholder='Cantidad de imagenes a publicar' type='number' onChange={(e) => setMlMaxImagesToUseValue(parseInt(e.target.value))} />
                        </DefaultimgPostCss.contInputAndLabel>
                        </>
                    )
                }
            </DefaultimgPostCss.bodyModal>
                <DefaultimgPostCss.footerModal>
                    <DefaultimgPostCss.btnActionFooter bgc='#7367f0' cl='#fff' bc='#7367f0' onClick={() => setPublishImage()}>
                        Guardar
                    </DefaultimgPostCss.btnActionFooter>
                </DefaultimgPostCss.footerModal>
            </DefaultimgPostCss.modalImg>
        </DefaultimgPostCss.cont>
    )
}
