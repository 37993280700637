import axios from "axios";
import { keyAccessToken, keyUserId } from "../../../../../assets/js/SessionStorageNames";
const getProductsAnicam = (entId, page, limit, filter) => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL_ANICAN}/v1/anicam/products/list`,
        {
            enterpriseId: entId,
            admin: localStorage.getItem(keyUserId),
            page,
            limit,
            filter
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}
export default getProductsAnicam