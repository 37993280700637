import { faBoxes, faExclamationTriangle, faThumbsUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react'
import MultiScraperStyles from '../../../../assets/css/components/admin/products/amazon/MultiScraperStyles'
import { GlobalContext } from '../../../../contexts/GlobalContext'

export default function InfoIcons() {
    const globaContext = useContext(GlobalContext);
    return (
        <MultiScraperStyles.openModalIcons active={globaContext.openIcont}>
            <MultiScraperStyles.modalIcons active={globaContext.openIcont}>
                <MultiScraperStyles.headerModalIcons>
                    <MultiScraperStyles.text size="12pt" fw="600">
                        Leyenda de iconos
                    </MultiScraperStyles.text>
                    <MultiScraperStyles.btnCloseModalIcons
                        onClick={() => globaContext.setOpenInfoIcons(false)}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </MultiScraperStyles.btnCloseModalIcons>
                </MultiScraperStyles.headerModalIcons>
                <MultiScraperStyles.bodyModalIcons>
                <MultiScraperStyles.listOption>
                <MultiScraperStyles.listOptionItems>
                    <FontAwesomeIcon icon={faThumbsUp} className="dot-one" style={{ color: "#2ec4b6" }} />
                    <MultiScraperStyles.text size="12pt" fw="200" ml="10px">
                        para productos ya publicados
                    </MultiScraperStyles.text>
                </MultiScraperStyles.listOptionItems>
                <MultiScraperStyles.listOptionItems>
                    <FontAwesomeIcon icon={faBoxes} className="dot-one" style={{ color: "#ffcf56" }} />
                    <MultiScraperStyles.text size="12pt" fw="200" ml="10px">
                        Para los productos que tienen variantes
                    </MultiScraperStyles.text>
                </MultiScraperStyles.listOptionItems>
                <MultiScraperStyles.listOptionItems>
                    <FontAwesomeIcon icon={faExclamationTriangle} className="dot-one" style={{ color: "#ea5455" }} />
                    <MultiScraperStyles.text size="12pt" fw="200" ml="10px">
                        Problemas al scrapear el producto
                    </MultiScraperStyles.text>
                </MultiScraperStyles.listOptionItems>
                </MultiScraperStyles.listOption>
                </MultiScraperStyles.bodyModalIcons>
            </MultiScraperStyles.modalIcons>
        </MultiScraperStyles.openModalIcons>
    )
}
