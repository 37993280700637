const transformStringToArray = async (string, setUrlsArray, globalLimit) => {
    // ? instanciamos un array vacio
    let arrayOne = [];
    // ? transformamos el string de urls para luego recorrerlo en eun for
    // ? y asi creear el objeto para luego ejecutrar el scrapin masivo
    for(let url of string.split(',')) {
        const objAddTo = {
            limit: globalLimit,
            url: url,
            result: '',
            message: '',
            statusProcess: 0,
        }
        // ? agg el valor al array
        arrayOne.push(objAddTo);
    }
    //seteamos el array principal
    return setUrlsArray(arrayOne);    
}

export default transformStringToArray;