import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  keyUserId,
  keyAccessToken,
} from "../../../../../assets/js/SessionStorageNames";
import axios from "axios";

export default function DownloadLinioOrders(props) {
  const globalContext = useContext(GlobalContext);
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());

  /**
   * Cancel axios requests before unmount
   */
  useEffect(() => {
    return () => {
      axiosCancelTokenSource.cancel("Canceled by unmount");
    };
    //eslint-disable-next-line
  }, []);

  /**
   * Handle the axios error
   * @param {Error} err The thrown error
   * @param {string} msg A message to show in the modal
   * @param {boolean} consoleLog If should log the error in the console
   */
  const handleAxiosError = (err, msg = null, consoleLog = true) => {
    if (axios.isCancel(err)) return; //Don't show axios cancel error
    if (msg) globalContext.showModalWithMsg(msg);
    if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
  };
  //http://localhost:4000/v1/linio-orders/orders/5f59092f5f8c84465e058171
  //totdo esperaR POR JESUS
  const downloadOrders = () => {
    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;
    globalContext.setLoaderPage(true);
    const headers = {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "x-access-token": localStorage.getItem(keyAccessToken),
      "x-user-id": localStorage.getItem(keyUserId),
    };

    switch (props.currentPlugin) {
      case "ln":
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/linio-orders/orders/${entId}`,
            {
              cancelToken: axiosCancelTokenSource.token,
              headers,
            }
          )
          .then(() => {
            globalContext.showModalWithMsg("Proceso culminado");
            globalContext.setLoaderPage(false);
          })
          .catch((err) => {
            handleAxiosError(
              err,
              "Disculpe, no se pudo culminar la descarga de las ordenes."
            );
            globalContext.setLoaderPage(false);
          });

        break;
      case "df":
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/dafiti-orders/orders/${entId}`,
            {
              cancelToken: axiosCancelTokenSource.token,
              headers,
            }
          )
          .then(() => {
            globalContext.showModalWithMsg("Proceso culminado");
            globalContext.setLoaderPage(false);
          })
          .catch((err) => {
            handleAxiosError(
              err,
              "Disculpe, no se pudo culminar la descarga de las ordenes."
            );
            globalContext.setLoaderPage(false);
          });

        break;
      default:
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/linio-orders/orders/${entId}`,
            {
              cancelToken: axiosCancelTokenSource.token,
              headers,
            }
          )
          .then(() => {
            globalContext.showModalWithMsg("Proceso culminado");
            globalContext.setLoaderPage(false);
          })
          .catch((err) => {
            handleAxiosError(
              err,
              "Disculpe, no se pudo culminar la descarga de las ordenes."
            );
            globalContext.setLoaderPage(false);
          });
    }
  };

  return (
    <div className={`items`} onClick={downloadOrders}>
      <span className="ic">
        <FontAwesomeIcon icon={faDownload} />
      </span>
      <p>Descargar Ordenes</p>
    </div>
  );
}
