import React, { useContext, useState } from 'react'
import VerifyLoginStyle from '../../assets/css/components/general/VerifyLoginStyle'
import { keyAccessToken, keyRefreshToken, keyUserId, keyUserName, keyUserRoles, userRole, userEmail } from '../../assets/js/SessionStorageNames';
import { GlobalContext } from '../../contexts/GlobalContext';
import { BiBullseye, BiHide, BiShow, BiX } from 'react-icons/bi'
import axios from 'axios';
export default function VerifyLogin() {
    const globalContext = useContext(GlobalContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showBadge, setShowBadge] = useState(false);
    const [showErrorPassword, setShowErrorPassword] = useState(false);
    const [showErrorEmail, setShowErrorEmail] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [error, setError] = useState('');
    const [errorEmail, setErrorEmail] = useState('');

    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPass(!showPass)
    }

    const closeBadge = (e) => {
        e.preventDefault();
        setShowBadge(false);
    }

    const submitHandler = (e) => {
        e.preventDefault();
        if(email.length === 0) {
            setError("Debe agregar un correo para poder ingresar");
            setErrorEmail('Correo invalido');
            setShowBadge(true);
            setShowErrorEmail(true);
            return
        }
        if(password.length === 0) {
            setError("El campo de clave no puede estar vacio");
            setShowBadge(true);
            setShowErrorPassword(true)
            return
        }
        setIsLoad(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BACKEND_URL}/v1/login/`,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
            },
            data: {
                email: email.trim(),
                password: password.trim(),
            },
        })
            .then((res) => {
                setIsLoad(false);
                let datos = {
                    name: res.data.data.user.name,
                    roles: {},
                    tokens: {
                        accessTokenKey: res.data.data.tokens.accessToken,
                        refreshTokenKey: res.data.data.tokens.refreshToken,
                    },
                    _id: res.data.data.user._id,
                };
                localStorage.setItem(keyUserName, res.data.data.user.name);
                localStorage.setItem(
                    keyAccessToken,
                    res.data.data.tokens.accessToken
                );
                localStorage.setItem(
                    keyRefreshToken,
                    res.data.data.tokens.refreshToken
                );
                localStorage.setItem(keyUserId, res.data.data.user._id);
                const rolesUsuario = res.data.data.user.roles.map((r) => {
                    var re = "";
                    return re + r.code + ",";
                });
                localStorage.setItem(keyUserRoles, rolesUsuario.toString());
                localStorage.setItem(userEmail, res.data.data.user.email);
                localStorage.setItem(userRole, res.data.data.user.rolRoute);
                globalContext.setUserData(datos);
                window.location.reload();
                globalContext.setVerifyLogin(false);
            })
            .catch((err) => {
                setIsLoad(false);

                if (err.response) {
                    if (err.response.data) {
                        if (err.response.data.message === "User not registered") {
                            setError("La combinación de usuario y clave es errónea");
                            setShowBadge(true);
                        } else if (err.response.data.message === "Credential not set") {
                            setError("La combinación de usuario y clave es errónea");
                            setShowBadge(true);
                        } else if (
                            err.response.data.message === "Credential not set" ||
                            err.response.data.message === "Authentication failure"
                        ) {
                            setError("La combinación de usuario y clave son errónea");
                            setShowBadge(true);
                        }
                    } else {
                        setError("En estos momentos presentamos fallas internas!");
                        setShowBadge(true);
                    }
                } else {
                    setError("Error de comunicación, revise su conexión a internet");
                    setShowBadge(true);
                }
            });
    };
    return (
        <VerifyLoginStyle.ContModal active={globalContext.verifyLogin}>
            <VerifyLoginStyle.FormLogin>
                    <VerifyLoginStyle.Text style={{ alignSelf: 'flex-start' }} size="30px" cl="#5e5873" fw="500">
                        Bienvenidos a e-kommers.com!
                    </VerifyLoginStyle.Text>
                    <VerifyLoginStyle.Text style={{ alignSelf: 'flex-start' }} cl="#ea5455" size="12pt">
                        Verifique su identidad antes de continuar.
                    </VerifyLoginStyle.Text>
                    <VerifyLoginStyle.ContBadgeError active={showBadge}>
                        <VerifyLoginStyle.Text size=".875rem" cl="#FFFFFF">
                            {error}
                        </VerifyLoginStyle.Text>
                        <VerifyLoginStyle.BtnToggleBadge onClick={(e) => closeBadge(e)}>
                            <BiX />
                        </VerifyLoginStyle.BtnToggleBadge>
                    </VerifyLoginStyle.ContBadgeError>
                    <VerifyLoginStyle.ContInputAndLabel>
                        <VerifyLoginStyle.Text size=".875rem" cl="#5e5873">
                            Correo
                        </VerifyLoginStyle.Text>
                        <VerifyLoginStyle.Input placeholder="Nombre de usuario" onChange={(e) => setEmail(e.target.value)} />
                        {
                            showErrorEmail && (
                                <VerifyLoginStyle.Text size=".850rem" cl="#ea5455">
                                    {errorEmail}
                                </VerifyLoginStyle.Text>
                            )
                        }
                    </VerifyLoginStyle.ContInputAndLabel>
                    <VerifyLoginStyle.ContInputAndLabel>
                        <VerifyLoginStyle.Text size=".875rem" cl="#5e5873">
                            Clave
                        </VerifyLoginStyle.Text>
                        <VerifyLoginStyle.ContIconNadInputPass>
                            <VerifyLoginStyle.Input placeholder="Clave de acceso" type={showPass ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} />
                            <VerifyLoginStyle.BtnShowPass onClick={(e) => toggleShowPassword(e)}>
                                {
                                    showPass ? (
                                        <BiHide />
                                    ) : (
                                        <BiShow />
                                    )
                                }
                            </VerifyLoginStyle.BtnShowPass>
                        </VerifyLoginStyle.ContIconNadInputPass>
                        {
                            showErrorPassword && (
                                <VerifyLoginStyle.Text size=".850rem" cl="#ea5455">
                                    Debe agregar una clave de acceso!
                                </VerifyLoginStyle.Text>
                            )
                        }
                    </VerifyLoginStyle.ContInputAndLabel>
                    <VerifyLoginStyle.BtnSend disabled={isLoad} onClick={(e) => submitHandler(e)}>
                        {isLoad ? 'Verificando...': 'Iniciar'}
                    </VerifyLoginStyle.BtnSend>
                </VerifyLoginStyle.FormLogin>
        </VerifyLoginStyle.ContModal>
    )
}
