import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Routes from "../../../../Routes";
export default function IndexOrders() {
  return (
    <Switch>
      {Routes.map((prop, key) => {
        if (prop.father === "/orders") {
          return (
            <Route
              path={prop.layout + prop.father + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/admin/orders" to="/admin/orders/grid" />
    </Switch>
  );
}