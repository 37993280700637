import React from 'react'
import BuildFormCss from '../../../../../../assets/css/components/admin/products/post-product/steps/BuildFormCss'

export default function InputText(props) {
    const {typeInput, data, setValue, valuesCategorys, index} = props;
    const handleChangeValue = (e) => {
        const copyArray = [...valuesCategorys];
        copyArray[index].value = e.target.value;
        setValue(copyArray);
    }
    return (
        <BuildFormCss.input 
        type={typeInput} 
        value={valuesCategorys[index].value} 
        placeholder={data.valuePlaceholder} 
        onChange={handleChangeValue} 
        name={data.nameInput} />
        )
    
}
