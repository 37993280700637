import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
import addValuesToTemplate from './addValuesToTemplate';
const updateOneMlDescription = (entId, mlPrductId, descriptionProduct) => {
    const resp = axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/${mlPrductId}/description`, {
        body: descriptionProduct
    }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return resp
}

const updateDescriptions = async (entId, productSelecteds, productSelectedsWithErrors, dataCard, setDataCard, setTotalUpdate, setTotalUpdateVariants, setIsLoader, templateDesc) => {
    setIsLoader(true)
    let arrayCopy = [...dataCard];
    let totalUpdate = 0;
    let totalUpdateVariants = 0;
    if (productSelecteds.length > 0) {
        await Promise.all(productSelecteds.map(async (v) => {
            const indexOne = arrayCopy.findIndex(vc => vc._id === v._id)
            if (v.variants.length > 0) {
                for await (let va of v.variants) {
                    const dataDesc = await addValuesToTemplate(templateDesc, va);
                    if (dataDesc) {
                        if (va.ml) {
                            const indexTwo = arrayCopy[indexOne].variants.findIndex(v => v.asin === va.asin);
                            await updateOneMlDescription(entId, va.ml._id, dataDesc)
                                .then(res => {
                                    totalUpdateVariants++;
                                    setTotalUpdateVariants(totalUpdateVariants)
                                    arrayCopy[indexOne].variants[indexTwo].itHasUpdateMl = true;
                                    setDataCard(arrayCopy)
                                    console.log('se actualizo', va.asin, res)
                                }).catch(err => {
                                    console.log('no se actualizo', va.asin, err)
                                })
                        }
                    }
                }
            } else {
                const dataDesc = await addValuesToTemplate(templateDesc, v);
                if(dataDesc) {
                    if (v.ml) {
                        //console.log('precios sin v', v); return;
                        await updateOneMlDescription(entId, v.ml._id, dataDesc)
                            .then(res => {
                                totalUpdate++;
                                setTotalUpdate(totalUpdate);
                                arrayCopy[indexOne].itHasUpdateMl = 'paused';
                                setDataCard(arrayCopy)
                                console.log('se actualizo', v.asin, res)
                            }).catch(err => {
                                console.log('no se actualizo', v.asin, err)
                            })
                    }
                }
            }
        }))
        setIsLoader(false)
    } else {
        await Promise.all(productSelectedsWithErrors.map(async (v) => {
            const indexOne = arrayCopy.findIndex(vc => vc._id === v._id)
            if (v.variants.length > 0) {
                for await (let va of v.variants) {
                    const dataDesc = await addValuesToTemplate(templateDesc, va);
                    if (dataDesc) {
                        if (va.ml) {
                            const indexTwo = arrayCopy[indexOne].variants.findIndex(v => v.asin === va.asin);
                            await updateOneMlDescription(entId, va.ml._id, dataDesc)
                                .then(res => {
                                    totalUpdateVariants++;
                                    setTotalUpdateVariants(totalUpdateVariants)
                                    arrayCopy[indexOne].variants[indexTwo].itHasUpdateMl = true;
                                    setDataCard(arrayCopy)
                                    console.log('se actualizo', va.asin, res)
                                }).catch(err => {
                                    console.log('no se actualizo', va.asin, err)
                                })
                        }
                    }
                }
            } else {
                const dataDesc = await addValuesToTemplate(templateDesc, v);
                if(dataDesc) {
                    if (v.ml) {
                        //console.log('precios sin v', v); return;
                        await updateOneMlDescription(entId, v.ml._id, dataDesc)
                            .then(res => {
                                totalUpdate++;
                                setTotalUpdate(totalUpdate);
                                arrayCopy[indexOne].itHasUpdateMl = 'paused';
                                setDataCard(arrayCopy)
                                console.log('se actualizo', v.asin, res)
                            }).catch(err => {
                                console.log('no se actualizo', v.asin, err)
                            })
                    }
                }
            }
        }))
        setIsLoader(false);
    }
}

export default updateDescriptions;