import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';

const deleteCamp = async (entId, campId) => {
    let res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/v1/df-camp/${entId}/${campId}`, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
        
    })
    return res;
}

export default deleteCamp;