import React, { useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import GridStyles from '../../../../../assets/css/components/admin/products/Orders/GridStyles';
import { GlobalContext } from '../../../../../contexts/GlobalContext';

export default function OptionsCont(props) {
    const {active, setActive, idAmazon, idorder, statusTracking, nroTracking} = props;
    const OptionsProductsRef = useRef(null);
    const globalContext = useContext(GlobalContext);

    const setActiveFunc = (v) => {
        setActive(v)
    }
    function useOutsideAlerterMenu(refe) {
        useEffect(() => {
            if(active) {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutsideMenu(event) {
              if (refe.current && !refe.current.contains(event.target)) {
                setActiveFunc('')
              }
            }
            // Bind the event listener
            document.addEventListener("click", handleClickOutsideMenu);
            return () => {
              // Unbind the event listener on clean up
              document.removeEventListener("click", handleClickOutsideMenu);
            };
          }
          }, [refe, active]);
        }
        useOutsideAlerterMenu(OptionsProductsRef);

        /**
         * <GridStyles.listOptionItems>
                  Historico
                </GridStyles.listOptionItems>
<GridStyles.listOptionItems>
                  Facturacion y envio
                </GridStyles.listOptionItems>
                <GridStyles.listOptionItems>
                  Solicitar factura
                </GridStyles.listOptionItems>
         */

    return (
        <GridStyles.contOptions active={active} ref={OptionsProductsRef}>
            <GridStyles.listOption>
              <Link to={`/admin/orders/detail/${idAmazon}/${idorder}`} style={{textDecoration: 'none', width:'100%'}}>
                <GridStyles.listOptionItems>
                  Detalles del producto
                </GridStyles.listOptionItems>
              </Link>
              {
                nroTracking &&
                (
                  <GridStyles.listOptionItems onClick={() => {
                    globalContext.setOpenModalTracking(true);
                    setActiveFunc(''); 
                    globalContext.setStatusTracking(statusTracking);
                    globalContext.setNroTracking(nroTracking)
                    }}>
                    Tracking
                  </GridStyles.listOptionItems>
                )
              }
            </GridStyles.listOption>
        </GridStyles.contOptions>
    )
}
