import React, { Component } from "react";
import { Pagination } from "react-bootstrap";
import PaginationStyle from "../../assets/css/components/general/Pagination.css";

export default class Pager extends Component {
  /**
   * Define el num de pag actual
   * @param {integer} pageNum El nro de pagina a asignar
   */
  setPage(pageNum) {
    this.props.handleSetPage(pageNum);
  }

  /**
   * Renderiza el componente
   */
  render() {
    const currentPage = this.props.currentPage;
    const totalResults = this.props.totalResults;
    const totalPages =
      totalResults === 0
        ? 1
        : Math.ceil(totalResults / this.props.resultsPerPage);
    const numSidePages = 2;

    let pages = [];

    //Agrega los links de pagina anterior y primera pagina
    if (currentPage !== 1) {
      pages.push(
        <Pagination.First key="first" onClick={this.setPage.bind(this, 1)} />
      );

      pages.push(
        <Pagination.Prev
          key="prev"
          onClick={this.setPage.bind(this, currentPage - 1)}
        />
      );
    }

    //Si deben agregarse los ...
    // if (currentPage - (numSidePages + 1) > 0) {
    //   pages.push(<Pagination.Ellipsis key="prev-dots" />);
    // }

    //Imprime los links de paginas anteriores
    for (let subtr = numSidePages; subtr > 0; subtr--) {
      const diff = currentPage - subtr;

      if (diff > 0) {
        pages.push(
          <Pagination.Item key={diff} onClick={this.setPage.bind(this, diff)}>
            {diff}
          </Pagination.Item>
        );
      }
    }

    //Imprime la pagina actual
    pages.push(
      <Pagination.Item active key={currentPage}>
        {currentPage}
      </Pagination.Item>
    );

    //Imprime los links de paginas siguientes
    for (let plus = 1; plus <= numSidePages; plus++) {
      const sum = currentPage + plus;

      if (sum <= totalPages) {
        pages.push(
          <Pagination.Item key={sum} onClick={this.setPage.bind(this, sum)}>
            {sum}
          </Pagination.Item>
        );
      }
    }

    //Si deben agregarse los ...
    // if (currentPage + (numSidePages + 1) < totalPages) {
    //   pages.push(<Pagination.Ellipsis key="next-dots" />);
    // }

    //Agrega los links de pagina siguiente y ultima pagina
    if (currentPage !== totalPages) {
      pages.push(
        <Pagination.Next
          key="next"
          onClick={this.setPage.bind(this, currentPage + 1)}
        />
      );

      pages.push(
        <Pagination.Last
          key="last"
          onClick={this.setPage.bind(this, totalPages)}
        />
      );
    }

    return (
      <PaginationStyle>
        <Pagination style={{marginBottom:0}}>{pages}</Pagination>
      </PaginationStyle>
    );
  }
}
