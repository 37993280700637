import React, { useContext } from 'react'
import {
  ContListcategories,
  HeaderListCategories,
  BodyListCategories,
  ContItemCategoriesToLinkMl,
  ContLabelItem,
  ContIconOpenSubList,
  BtnLinkCat
} from '../../../assets/css/components/admin/categories/LinkCategories'
import { Text } from '../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts'
import { BiCaretRight, BiTrashAlt, BiRefresh } from 'react-icons/bi'
import { GlobalContext } from '../../../contexts/GlobalContext';
import { useState } from 'react';
import axios from 'axios';
import sendToLinkCat from './functions/sendToLinkCat';
export default function ListUrlReadyToLinkMl({ listUrlToLinkWithMl, setListUrlToLinkWithMl, categoryOn, setcategoryOn }) {

  const globalContext = useContext(GlobalContext);
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const deleteCategoryMl = (node) => {
    let copyArrayCategoriesToLink = [...listUrlToLinkWithMl];
    const currentPosition = copyArrayCategoriesToLink.findIndex(v => v.plugin === 'amazonScrap' && v.nodeCategoryamazon === node)
    copyArrayCategoriesToLink[currentPosition].hasMlId = false;
    copyArrayCategoriesToLink[currentPosition].mlCaTegoryNode = '';
    copyArrayCategoriesToLink[currentPosition].mlCategoryName = '';
    copyArrayCategoriesToLink[currentPosition].dataToSend.pop()
    setListUrlToLinkWithMl(copyArrayCategoriesToLink);
  }

  const deleteCategoryAll = (node) => {
    const exists = listUrlToLinkWithMl.find((v) => v.plugin === 'amazonScrap' && v.nodeCategoryamazon === node)
    if(exists !== undefined) {
      const copyArrayCategoriesToLink = listUrlToLinkWithMl.filter((v) => v.plugin === 'amazonScrap' && v.nodeCategoryamazon !== exists.nodeCategoryamazon)
      
      setListUrlToLinkWithMl(copyArrayCategoriesToLink);
      setcategoryOn(null)
    }
  }

  const SendData = async () => {
    const entId = globalContext.currentEnterpriseId;
    const dataToSend = listUrlToLinkWithMl;
    //? validamos si es mayor a cero el arreglo
    if(dataToSend.length > 0) {
      for (const dataCat of dataToSend) {
        console.log('se envia', dataCat)
        await new Promise(async (resolve) => {
          await sendToLinkCat(dataCat, entId, axiosCancelTokenSource)
          .then(async (resp) => {
            console.log('la res de', dataCat)
            console.log('la respuesta al linkear', resp.data)
            resolve('')
          }).catch(err => {
            console.log('el error al linkear', err)
            resolve(err)
          })
        })
      }
      setListUrlToLinkWithMl([]);
      globalContext.showModalWithMsg('Todas las categorias en la lista fueron linkeadas con exito!')
    }
  }
  return (
    <ContListcategories w="300px" style={{position: 'sticky', top: '10px'}}>
      <HeaderListCategories>
        <Text size='13pt' fw="bold" cl="#696969">
          Listas para linkeo de ML
        </Text>
        {
          (listUrlToLinkWithMl.length > 0) &&
          (
            <BtnLinkCat onClick={() => SendData()}>
              <BiRefresh />
            </BtnLinkCat>
          )
        }
      </HeaderListCategories>
      <BodyListCategories>
        {
          (listUrlToLinkWithMl.length > 0) &&
          listUrlToLinkWithMl.map((v, i) => {
            return (
              <ContItemCategoriesToLinkMl key={i}>
                <ContLabelItem active="true" onClick={() => deleteCategoryAll(v.nodeCategoryamazon)}>
                  {v.nameCategory.en} - {v.nodeCategoryamazon}
                  <ContIconOpenSubList>
                    <BiTrashAlt />
                  </ContIconOpenSubList>
                </ContLabelItem>
                <ContLabelItem style={{ marginTop: '10px' }} active={v.hasMlId} onClick={() => {
                  if (v.hasMlId) {
                    deleteCategoryMl(v.nodeCategoryamazon)
                  } else {
                    setcategoryOn(v.nodeCategoryamazon)
                  }
                }
              }>
                  {!v.hasMlId ? categoryOn === v.nodeCategoryamazon ? 'Esperando Categoria de ML...' : 'Agregar Categoria de ML' : `${v.mlCategoryName} - ${v.mlCaTegoryNode}`}
                  {
                    v.hasMlId ?
                      (
                        <ContIconOpenSubList>
                          <BiTrashAlt />
                        </ContIconOpenSubList>
                      )
                      :
                      (
                        <ContIconOpenSubList>
                          <BiCaretRight />
                        </ContIconOpenSubList>
                      )
                  }

                </ContLabelItem>
              </ContItemCategoriesToLinkMl>
            )
          })
        }
      </BodyListCategories>
    </ContListcategories>
  )
}
