import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';

/**
 * 
 * @param {*} entId 
 * @param {*} currentPage 
 * @param {*} pageItemCount 
 * @param {*} filter 
 * @param {*} filterLinkedTo 
 * @param {*} currentBrand 
 * @param {*} filterStock 
 * @param {*} filterPrice 
 * @param {*} axiosCancelTokenSource 
 */
const getDataMl = (
    entId, currentPage, pageItemCount,
    filter, filterLinkedTo, currentBrand,
    filterStock, filterPrice, axiosCancelTokenSource
) => {
    const res = axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}`,
        {
            params: {
                pageNumber: currentPage,
                pageItemCount: pageItemCount,
                filter: {
                    title: filter,
                    barCode: filter,
                    variBarCode: filter,
                    sku: filter,
                    variSku: filter,
                    filterLinkedTo,
                    brand: currentBrand,
                    skipVariants: true,
                    filterPrice,
                    filterStock,
                },
                checkIsFullyLinked: true,
                includeVariantData: true,
            },
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(
                    keyAccessToken
                ),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
    )
    return res;
}

export default getDataMl;