import React, { useContext, useEffect, useState } from 'react'
import GridStyles from '../../../../assets/css/components/admin/products/Orders/GridStyles'
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames'
import ChatClient from './components/ChatClient'
import NavbarDetail from './components/NavbarDetail'
import BillingInformation from './steps/BillingInformation'
import DetailClient from './steps/DetailClient'
import DetailProduct from './steps/DetailProduct'
import Novelties from './steps/Novelties'
import ShippingInformation from './steps/ShippingInformation'
import axios from 'axios';
import { GlobalContext } from '../../../../contexts/GlobalContext'
import { Link, Redirect  } from 'react-router-dom'
import Routes from '../../../../Routes'
import verifyAccessRole from '../../../../assets/js/verifyAccessRole'

export default function DetailProductOrders({match}) {
    const globalContext = useContext(GlobalContext);
    const [steps, setStep] = useState(1);
    const [brands, setBrands] = useState([]);
    const [variantsData, setVariantsData] = useState(null);
    const [isClothing, setIsClothing] = useState(null);
    const [imgActiveTwo, setImgActiveTwo] = useState(0);
    const [isLoader, setIsLoader] = useState(true);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [amazonData, setAmazonData] = useState([]);
    const [idProdAmazon, setIdProd] = useState('');
    const [dataOrder, setDataOrder] = useState([]);
    const [defaultImg, setDefaultImg] = useState('');
    const [showVariation, setShowVariation] = useState(null);
    const [imgActiveVariant, setImgActiveVariant] = useState(0);
    const [actualNoveltys, setactualNoveltys] = useState([]);
    const amazonId = match.params.idAmazon;
    const idOrder = match.params.idOrder;
    const [dataPrice, setDataPrice] = useState(null);
    const [asin, setAsin] = useState('');
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 25);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);

    const renderStep = () => {
        switch (steps) {
            case 1:
                return <DetailProduct 
                            isLoader={isLoader} 
                            variantsData={variantsData}
                            setVariantsData={setVariantsData}
                            amazonData={amazonData} 
                            dataPrice={dataPrice}
                            setAmazonData={setAmazonData} 
                            asin={asin} 
                            setAsin={setAsin}
                            defaultImg={defaultImg} 
                            setDefaultImg={setDefaultImg}
                            isClothing={isClothing}
                            imgActiveTwo={imgActiveTwo}
                            setImgActiveTwo={setImgActiveTwo}
                            showVariation={showVariation}
                            setShowVariation={setShowVariation}
                            setImgActiveVariant={setImgActiveVariant}
                            
                            />
            case 2:
                return <DetailClient isLoader={isLoader} dataOrder={dataOrder} />
            case 3:
                return <ShippingInformation/>
            case 4:
                return <BillingInformation/>
                case 5:
                return <Novelties
                    idProdAmazon={idProdAmazon}
                    isLoader={isLoader} 
                    dataOrder={dataOrder}
                    dataPrice={dataPrice}
                    actualNoveltys={actualNoveltys} 
                    setactualNoveltys={setactualNoveltys}
                    amazonData={amazonData}
                    idOrder={idOrder}
                    />
            default:
                return <DetailProduct 
                            isLoader={isLoader} 
                            variantsData={variantsData}
                            setVariantsData={setVariantsData}
                            amazonData={amazonData} 
                            dataPrice={dataPrice}
                            setAmazonData={setAmazonData} 
                            asin={asin} 
                            setAsin={setAsin}
                            defaultImg={defaultImg} 
                            setDefaultImg={setDefaultImg}
                            isClothing={isClothing}
                            imgActiveTwo={imgActiveTwo}
                            setImgActiveTwo={setImgActiveTwo}
                            showVariation={showVariation}
                            setShowVariation={setShowVariation}
                            setImgActiveVariant={setImgActiveVariant}
                            
                            />
        }
    }
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

        //? con este metodo obtenemos las tiendas oficiales
        useEffect(() => {
            const entId = globalContext.currentEnterpriseId;
            if (!entId) return;
    
            axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/ml-brand/${entId}`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            ).then((resp) => {
                const brands = resp.data.data.brands;
                setBrands(brands);
                //console.log('las marcas', brands);
            }).catch((err) => {
                handleAxiosError(err)
            });
        }, [globalContext.currentEnterpriseId]);

    const getDataAmazon = async (amazonId, entId) => {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/products/${amazonId}`, {
            params: {
                pageNumber: 1,
                pageItemCount: 1,
                filter: {
                    lang: 'es',
                },
            },
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }
    const getDataOrders = async (idOrder, entId) => {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/order/${entId}/amazon-scrap/ml`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            params: {
                pageNumber: 1,
                pageItemCount: 1,
                filter: {
                    orderId: idOrder
                }
            }
        })
        return res;
    }

    const getValues = async  (entId) => {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }

    const getPublishingPrice = async (prodId, obj, entId) => {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/calculate-publish-price`, {
            prodId: prodId,
            config: {
                tariff:obj.tariff,
                earning:obj.earning,
                courierPlus:parseInt(obj.courierPlus),
                courierPlusIsPercent: obj.courierPlusIsPercent,
                poundPrice:parseInt(obj.poundPrice),
                additionalPoundPrice:parseInt(obj.additionalPoundPrice),
                safe:obj.safe,
                safeIsPercent:obj.safeIsPercent,
                ccTax:obj.ccTax,
                ccTaxIsPercent:obj.ccTaxIsPercent,
                defaultTariff:parseInt(obj.defaultTariff),
                defaultTariffIsPercent:obj.defaultTariffIsPercent,
                iva:parseInt(obj.iva),
                ivaIsPercent:true,
                defaultComission:obj.defaultComission,
                defaultComissionIsPercent:obj.defaultComissionIsPercent,
                countryCode: entId === "60944575461add5b1957ef8b" ? 'VE' : 'CO'
            }
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res
    }

        // ? y con este la renderizamos
        const renderBrandMl = (mlData) => {
            let name = "";
            if (mlData.length > 0) {
                if (brands.length > 0) {
                    const brand = brands.filter((b) => b.id === mlData[0].product.attrib.official_store_id);
    
                    if (brand.length > 0) {
                        name = brand[0].name;
                    }
                }
            }
            //console.log('Nombre de la tienda', name)
            return name;
        }

    useEffect(()=>{
        const entId = globalContext.currentEnterpriseId;
        if(!entId) return;
        //? obtenemos los datos del producto de amazon
        getDataAmazon(amazonId, entId)
        .then((resp) => {
            const data = resp.data.data.product;
            const idProd = resp.data.data.product._id
            // console.log('para el id',resp.data.data.product);
                // ? validamos cuando sea variante
            if (data.variants.length > 0) {
                setIdProd(idProd)
                setAmazonData(data.variants[0]);
                setVariantsData(data.variants);
                setShowVariation(data.variants[0].variations);
                setAsin(data.variants[0].asin);
                setDefaultImg(data.variants[0].images[0]);
                setIsClothing(data.variants[0].categoryTree.find(c => c.node === '7141123011'))
            }
            else {
                setIdProd(idProd)
                setAmazonData(data);
                setDefaultImg(data.images[0]);
            }
            // ? obtenemos los valores para calcular el precio del producto de amazon
            getValues(entId).then(async resp => {
                const data = resp.data.data.configData.publishPriceCalculation;
                //console.log('primero',data)
                //? ya con los valores de configuracion obtenidos calculamos el precio;
                await getPublishingPrice(amazonId, data, entId)
                .then((res) => {
                    const data = res.data.data
                    setDataPrice(data);
                    // console.log('precio final',data)
                }).catch((e) => { handleAxiosError(e, 'Presentamos un error 598'); globalContext.setLoaderPage(false); })
            }).catch(err => {
                handleAxiosError(err)
                globalContext.setLoaderPage(false);
            });
            // ? obtenemos los datos de la orden
            getDataOrders(idOrder, entId)
            .then((resp) => {
                const  data = resp.data.data.orders[0];
                // console.log('data orden',data);
                setIsLoader(false)
                setDataOrder(data);
                setactualNoveltys(data.amazonScrapStatusHistory ? data.amazonScrapStatusHistory : [] )
            })
            .catch((err) => {
                handleAxiosError(err);
                setIsLoader(false)
            })
        })
        .catch((err) => {
            handleAxiosError(err);
        })

        
    },[amazonId,idOrder,globalContext.currentEnterpriseId])


    return (
        <GridStyles.cont >
            {redirect}
            <GridStyles.header>
                <Link to="/admin/orders/grid">
                    <GridStyles.text fw="500" size="15pt">
                        Pedidos |
                    </GridStyles.text>
                </Link>
                <GridStyles.text fw="400" size="15pt" ml="10px">
                    Detalles del producto
                </GridStyles.text>
            </GridStyles.header>
            <GridStyles.header>
                <GridStyles.text fw="400" size="15pt">
                    Tienda:
                </GridStyles.text>
                <GridStyles.text fw="500" size="15pt" ml="10px" cl="#7367f0">
                    {dataOrder.ml && renderBrandMl(dataOrder.ml.items)}
                </GridStyles.text>
            </GridStyles.header>
            <NavbarDetail isActiveItem={steps} setSteps={setStep} />
            <GridStyles.contChatAndOthers>
                <GridStyles.contSteps active={isLoader}>
                    {renderStep()}
                </GridStyles.contSteps>
                <ChatClient idOrder={idOrder} dataOrden={dataOrder.ml}/>
            </GridStyles.contChatAndOthers>
        </GridStyles.cont>
    )
}
