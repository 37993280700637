import axios from "axios";
import { keyAccessToken, keyUserId } from "../../../../../assets/js/SessionStorageNames";
const getBrands = (entId, page, limit, filter) => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL_ANICAN}/v1/anicam/brands/list`,
        {
            enterpriseId: entId,
            admin: localStorage.getItem(keyUserId),
            page: page,
            limit: limit,
            filter: filter,
            //idUpdate: ipUpdate ? ipUpdate : null
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export default getBrands