import React, { useContext } from 'react'
import NotificationsStyle from '../../../assets/css/components/admin/notifications/NotificationsStyle'
import { GlobalContext } from '../../../contexts/GlobalContext'

export default function Notifications() {
    const globalContext = useContext(GlobalContext);
    
    return (
        <NotificationsStyle.Cont a={globalContext.showNotifications}>
            <NotificationsStyle.HeaderNotifications>
                <NotificationsStyle.Text size="13pt" fw="500">
                    Lista de Notificaciones
                </NotificationsStyle.Text>
            </NotificationsStyle.HeaderNotifications>
            <NotificationsStyle.BodyNotifications>
                <NotificationsStyle.ListItems>

                    <NotificationsStyle.ItemList>
                        <NotificationsStyle.Text size="12pt">
                            Se Genero una nueva compra de ml para Licores Factory
                        </NotificationsStyle.Text>
                    </NotificationsStyle.ItemList>
                    <NotificationsStyle.ItemList>
                        <NotificationsStyle.Text size="12pt">
                            Se Genero una nueva compra de ml para Licores Factory
                        </NotificationsStyle.Text>
                    </NotificationsStyle.ItemList>
                    <NotificationsStyle.ItemList>
                        <NotificationsStyle.Text size="12pt">
                            Se Genero una nueva compra de ml para Licores Factory
                        </NotificationsStyle.Text>
                    </NotificationsStyle.ItemList>
                    <NotificationsStyle.ItemList>
                        <NotificationsStyle.Text size="12pt">
                            Se Genero una nueva compra de ml para Licores Factory
                        </NotificationsStyle.Text>
                    </NotificationsStyle.ItemList>
                    <NotificationsStyle.ItemList>
                        <NotificationsStyle.Text size="12pt">
                            Se Genero una nueva compra de ml para Licores Factory
                        </NotificationsStyle.Text>
                    </NotificationsStyle.ItemList>
                    <NotificationsStyle.ItemList>
                        <NotificationsStyle.Text size="12pt">
                            Se Genero una nueva compra de ml para Licores Factory
                        </NotificationsStyle.Text>
                    </NotificationsStyle.ItemList>
                    

                </NotificationsStyle.ListItems>
            </NotificationsStyle.BodyNotifications>
        </NotificationsStyle.Cont>
    )
}


