import React, { useState } from 'react'
import { ContCard, ContDataInfoOne, ContDataInfoTitle, BtnSelectCard } from '../../../../../../../assets/css/components/admin/marketplace-to-marketplace/cardItem/CardItemStyle'
import { ContInputAndLabel, Text } from '../../../../../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts';
import CardContImage from './CardContImage';
import MenuCard from './MenuCard';

export default function CardPyProd({active, data, addItemToSend, pluginSearch}) {
    const [showMenu, setShowMenu] = useState(false);
    //console.log('la data del prod', data);
    return (
        <ContCard>
            <CardContImage urlImage={data.thumbnail} dataIconInfo={data} setShowMenu={setShowMenu} pluginSearch={pluginSearch} />
            <ContDataInfoOne>
                <Text fw="bold" cl="#515151" size="12px">
                    {`${data.stock} Cant.`}
                </Text>
                <Text fw="bold" cl="#515151" size="12px">
                    {data.price}
                </Text>
            </ContDataInfoOne>
            <ContDataInfoTitle>
                {data.title}
            </ContDataInfoTitle>
            <ContDataInfoOne>
                <ContInputAndLabel>
                    <Text fw="bold" cl="#515151" size="12px" mb="0">
                        Cod Bar:
                    </Text>
                    <Text cl="#515151" size="12px" mt="2px" ml="10px">
                        {data.barCode}
                    </Text>
                </ContInputAndLabel>
                <ContInputAndLabel>
                    <Text fw="bold" cl="#515151" size="12px" mb="0">
                        Ref Int:
                    </Text>
                    <Text cl="#515151" size="12px" mt="2px" ml="10px">
                        {data.sku}
                    </Text>
                </ContInputAndLabel>
            </ContDataInfoOne>
                {
                    (pluginSearch !== 'py') &&
                    (data.stock !== '0' && data.stock > 0) &&
                    (
                        <BtnSelectCard active={active} onClick={() => addItemToSend(data)}>
                            <Text fw="bold" cl={active ? "#FFFFFF" : "#515151"} size="12px">
                                {
                                    active ? 'Remover' : 'Seleccionar'
                                }
                            </Text>
                        </BtnSelectCard>
                    )
                }
            <MenuCard active={showMenu} setActive={setShowMenu} data={data} pluginSearch={pluginSearch} />
        </ContCard>
    )
}
