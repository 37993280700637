import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
const getDataScraper = async (url,entId, axiosCancelTokenSource) => {
    const headers = {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
    };
    const resp = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/parse-product`, {
        cancelToken: axiosCancelTokenSource.token,
        headers,
        params: {
            url,
            langEs: true,
            langEn: true,
        }
    })
    return resp;
}
export default getDataScraper;