import React, { useRef, useState } from 'react';
import RangosStepsCss from '../../../../../../assets/css/components/admin/products/ml/rangos-publicacion/RangosStepsCss';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPen } from '@fortawesome/free-solid-svg-icons';
function Three(props) {
    const [isUpdate, setIsUpdate] = useState(false);
    const [indexUpdate, setIndexUpdate] = useState(null);
    const {
        earning,
        minAmount,
        maxAmount,
        amount,
        porcentaje,
        numeroArray,
        dataSave,
        setEarning,
        setMinAmount,
        setMaxAmount,
        arrayItemsByDelete,
        setAmount,
        setPorcentaje,
        setArrayItemsByDelete,
        setNumeroArray,
        setDataSave,
        isLoader
    } = props;


    const minAmountInput = useRef(null);
    const maxAmountInput = useRef(null);
    const amountInput = useRef(null);

    const funcSetMinAmount = (v) => { setMinAmount(v) }
    const funcSetMaxAmount = (v) => { setMaxAmount(v) }
    const funcSetAmount = (v) => { setAmount(v) }
    const funcSetPorcentaje = (v) => { setPorcentaje(v) }
    const funcSetArrayItemsByDelete = (v) => { setArrayItemsByDelete(v) }
    const funcSetNumeroArray = (v) => { setNumeroArray(v) }
    const funcSetDataSave = (v) => { setDataSave(v) }

    const addEarningArray = (v) => {
        setEarning(v)
    }

    const cleanInputsAndState = () => {
        minAmountInput.current.value = ''
        maxAmountInput.current.value = ''
        amountInput.current.value = '';
        funcSetMinAmount('')
        funcSetMaxAmount('')
        funcSetAmount('')
        funcSetPorcentaje(true)
    }

    const addValueToTable = () => {
        funcSetArrayItemsByDelete([])
        funcSetNumeroArray(numeroArray + 1);
        /**
        * * primero validamos que los state no esten vacion
        */
        //console.log('amount', amount)
        if (amount.length === 0) {
            alert('Debe agregar el monto para la ganancia')
        } else {
            funcSetDataSave([...dataSave, {
                minAmount: minAmount.length > 0 && parseInt(minAmount),
                maxAmount: maxAmount.length > 0 && parseInt(maxAmount),
                amount: parseInt(amount),
                isPercent: porcentaje ? true : false,
            }]);

            addEarningArray([...earning, {
                minAmount: minAmount.length > 0 && parseInt(minAmount),
                maxAmount: maxAmount.length > 0 && parseInt(maxAmount),
                amount: parseInt(amount),
                isPercent: porcentaje ? true : false,
            }])
            cleanInputsAndState();
        }
    }

    const addItemByDelete = (index) => {
        const isHere = arrayItemsByDelete.find((e) => (e === index));
        if (isHere === undefined) {
            funcSetArrayItemsByDelete([...arrayItemsByDelete, index]);
        } else {
            funcSetArrayItemsByDelete(arrayItemsByDelete.filter(e => e !== index))
        }
    }
    const deleteAnyItem = () => {
        let itemDelete = [...dataSave];
        let arr = itemDelete.filter(item => !arrayItemsByDelete.includes(item.minAmount + item.maxAmount + item.amount))
        funcSetArrayItemsByDelete([])
        funcSetDataSave(arr);
        addEarningArray(arr)
    }

    const addByEdit = (index) => {
        setIndexUpdate(index)
        setIsUpdate(true);
        minAmountInput.current.value = dataSave[index].minAmount.toString();
        maxAmountInput.current.value = dataSave[index].maxAmount.toString();
        amountInput.current.value = dataSave[index].amount.toString();
        funcSetMinAmount(!dataSave[index].minAmount ? '': dataSave[index].minAmount.toString())
        funcSetMaxAmount(!dataSave[index].maxAmount ? '': dataSave[index].maxAmount.toString())
        funcSetAmount(!dataSave[index].amount ? '': dataSave[index].amount.toString())
    }

    const updateARow = () => {
        if (amount.length === 0) {
            alert('Debe agregar el monto para la ganancia')
        }else {
            let copyArr = [...dataSave];
            copyArr[indexUpdate].minAmount = minAmount.length > 0 && parseInt(minAmount);
            copyArr[indexUpdate].maxAmount = maxAmount.length > 0 && parseInt(maxAmount);
            copyArr[indexUpdate].amount = parseInt(amount);
            copyArr[indexUpdate].isPercent = porcentaje
            setDataSave(copyArr);
            setIsUpdate(false);
            cleanInputsAndState();
        }
    }
    
    return (
        <RangosStepsCss.cont>
            {
                isLoader ?
                    (
                        <RangosStepsCss.contLoader>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Cargando...</span>
                            </Spinner>
                        </RangosStepsCss.contLoader>
                    )
                    :
                    (
                        <>
                            <RangosStepsCss.contInputsAndButtonsActions>
                                <RangosStepsCss.contInputAndLabel>
                                    <RangosStepsCss.text fw="bolder" size="13pt" mb="5px">
                                        Monto minimo
                                    </RangosStepsCss.text>
                                <RangosStepsCss.input placeholder="Monto minimo" onChange={(e) => funcSetMinAmount(e.target.value)} ref={minAmountInput} type="number" />
                                    <RangosStepsCss.text fw="bolder" size="8pt" mb="5px">
                                        Si su monto minimo es de cero no lo coloque!
                                    </RangosStepsCss.text>
                                </RangosStepsCss.contInputAndLabel>
                                <RangosStepsCss.contInputAndLabel>
                                    <RangosStepsCss.text fw="bolder" size="13pt" mb="5px">
                                        Monto maximo
                                    </RangosStepsCss.text>
                                <RangosStepsCss.input placeholder="Mont maximo" onChange={(e) => funcSetMaxAmount(e.target.value)} ref={maxAmountInput} type="number" />
                                    <RangosStepsCss.text fw="bolder" size="8pt" mb="5px">
                                        Si su ganancia es de 500$ en adelante no rellene este campo
                                    </RangosStepsCss.text>
                                </RangosStepsCss.contInputAndLabel>
                                <RangosStepsCss.contInputAndLabel>
                                    <RangosStepsCss.text fw="bolder" size="13pt" mb="5px">
                                        Margen
                                    </RangosStepsCss.text>
                                    <RangosStepsCss.input placeholder="Margen" onChange={(e) => funcSetAmount(e.target.value)} ref={amountInput} type="number" />
                                    <RangosStepsCss.text fw="bolder" size="8pt" mb="5px">
                                        &nbsp;
                                    </RangosStepsCss.text>
                                </RangosStepsCss.contInputAndLabel>

                                <RangosStepsCss.contInputAndLabel jsc="space-between">
                                    <RangosStepsCss.text fw="bolder" mb="5px">
                                        ¿Es un porcentaje?
                                    </RangosStepsCss.text>
                                    <RangosStepsCss.contInputRadio>
                                        <RangosStepsCss.inputRadio type="radio" id="tp1" checked={porcentaje} name="tipoPublicacion" onChange={(e) => funcSetPorcentaje(true)} />
                                        <label htmlFor="tp1">Si</label>
                                        <RangosStepsCss.inputRadio type="radio" id="tp2" checked={!porcentaje} name="tipoPublicacion" ml="10px" onChange={(e) => funcSetPorcentaje(false)} />
                                        <label htmlFor="tp2">No</label>
                                    </RangosStepsCss.contInputRadio>
                                    <RangosStepsCss.text fw="bolder" size="8pt" mb="5px">
                                        &nbsp;
                                    </RangosStepsCss.text>
                                </RangosStepsCss.contInputAndLabel>

                                <RangosStepsCss.contInputAndLabel jsc="flex-end" fld="row" ali="flex-end">
                                    {isUpdate ?
                                    (
                                    <RangosStepsCss.btn mr="10px" onClick={() => updateARow()}>
                                        Actualizar
                                    </RangosStepsCss.btn>
                                    )
                                    :
                                    (
                                    <RangosStepsCss.btn mr="10px" onClick={() => addValueToTable()}>
                                        Agregar
                                    </RangosStepsCss.btn>
                                    )
                                    }
                                    <RangosStepsCss.btn bg="#FFFFFF"
                                        color="#EA5455"
                                        bdc="#EA5455"
                                        bgHover="#EA5455"
                                        colorHover="#FFFFFF"
                                        onClick={() => deleteAnyItem()}
                                    >
                                        Eliminar
                                    </RangosStepsCss.btn>
                                    
                                </RangosStepsCss.contInputAndLabel>
                            </RangosStepsCss.contInputsAndButtonsActions>
                            <div className="table-responsive">
                                <RangosStepsCss.tableRangos className="table table-bordered ">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                Numero
                                            </th>
                                            <th scope="col">
                                                Monto minimo
                                            </th>
                                            <th scope="col">
                                                Monto maximo
                                            </th>
                                            <th scope="col">
                                                Margen
                                            </th>
                                            <th scope="col">
                                                ¿Es un porcentaje?
                                            </th>
                                            <th scope='col' >
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataSave.length > 0 &&
                                            dataSave.map((e, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            {i + 1}
                                                        </td>
                                                        <td>
                                                            {e.minAmount}
                                                        </td>
                                                        <td>
                                                            {e.maxAmount}
                                                        </td>
                                                        <td>
                                                            {e.amount}
                                                        </td>
                                                        <td>
                                                            {e.isPercent ? 'Si' : 'No'}
                                                        </td>
                                                        <td className="d-flex justify-content-center align-items-center">
                                                            <RangosStepsCss.inputChecbox
                                                                type="checkbox"
                                                                id={`id-input-${e.i}`}
                                                                onChange={() => addItemByDelete(e.minAmount + e.maxAmount + e.amount)}
                                                                checked={arrayItemsByDelete.includes(e.minAmount + e.maxAmount + e.amount)}
                                                            />
                                                            <RangosStepsCss.btnUpdate onClick={() => addByEdit(i)}>
                                                                <FontAwesomeIcon icon={faPen}/>
                                                            </RangosStepsCss.btnUpdate>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </RangosStepsCss.tableRangos>
                            </div>
                        </>
                    )
            }
        </RangosStepsCss.cont>
    );
}
export default Three;