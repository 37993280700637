import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';

const sendData = (entId, prod, storeId, sectionId, axiosCancelTokenSource) => {
    const resp = axios.post(`${process.env.REACT_APP_BACKEND_URL_PEYA}/v1/peya-sdk/new-product`, {
        sectionId: sectionId,
        storeId: storeId,
        enterprise: entId,
        idproduct: prod._id
    }, {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return resp
}

const postProducts = async (entId, listProducts, storeId, sectionId, axiosCancelTokenSource, nextStep, setMsgSuccess) => {
    
    let msgSuccess = {
        data: [],
        errors: []
    };
    await Promise.all(listProducts.map(async (v) => {
        try {
            const send = await sendData(entId, v, storeId.storeId, sectionId._id, axiosCancelTokenSource)
            console.log('el resultado total',send.data.data)
            console.log('el resultado para el [0]',send.data.data[0])
            console.log('el resultado total data[0].message',send.data.data[0].message)
            if(send.data.data[0].error) {
                msgSuccess.data.push({message: send.data.data[0].error.message[0], barCode: send.data.data[0].dataToSend.name });
            }else {
                msgSuccess.data.push({message: send.data.data[0].message, barCode: send.data.data[0].data.name });
            }
        } catch (error) {
            console.log('el valor error', error)
            msgSuccess.data.push({message: error, barCode: 'NP' });
        }
    }));
    setMsgSuccess(msgSuccess);
    return nextStep()
}

export default postProducts;