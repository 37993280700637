import styled from 'styled-components';

const cont = styled.div`
width: 100%;
display: flex;
justify-content:flex-start;
align-items:  center;
flex-direction: column;
height: 100%;
background: #FFFFFF;
box-shadow: 0 4px 25px 0 rgb(34 41 47 / 10%);
border-radius: .5rem;
`;
const header = styled.div`
width:100%;
height: 60px;
background:#FFFF;
margin-top:0;
padding:10px;
display:flex;
justify-content: left;
align-items:center;
`;
const body = styled.div`
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
height: 500px;
`;

const footer = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
height: 60px;
ul {
    margin-bottom: 0 !important;
}
`;

const buttonBack = styled.button`
border-radius: 5px;
background: #ffffff;;
outline: none!important;
border:1px solid #7367f0;
color: #7367f0;
font-size: 12pt;
font-weight: 400;
padding: 6px 18px;
transition: all ease 0.2s;
margin-right:5px;
margin-left:10px;
&:hover {
    background-color:#7367f0;
    color:#ffffff;
}
`;

const text = styled.p`
margin-bottom:  ${props=> props.mb || '0'};;
color: ${props=> props.active ? "#7367F0" : '#707070'};
font-size: ${props=> props.size || '12px'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
font-weight: ${props=> props.active ? '500' : 'lighter'};
margin-top: ${props=> props.mt || '0'};
transition: all ease-in-out 0.2s;
`;

const input = styled.input`
    margin-right:10px;
    padding-left:10px;
    font-family: "Berlin Rounded";
    width: 100%;
    height: 38px;
    border-radius: 5px;
    background: #fff;
    border: 0.5px solid rgba(112, 112, 112, 0.7);
    outline: none !important;
    color: rgba(112, 112, 112, 0.61);
    font-size: 12pt;
    margin-left: ${props=> props.ml || '0'};
    font-weight: 300;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
        }
`;

const select = styled.select`
    padding-left:10px;
    padding-right:10px;
    font-family: "Berlin Rounded";
    width: ${props=> props.wd || '100%'};
    margin-left: ${props=> props.ml || '0'};
    margin-right: ${props=> props.mr || '0'};
    height: 38px;
    border-radius: 5px;
    background: #fff;
    border: 0.5px solid rgba(112, 112, 112, 0.7);
    outline: none !important;
    color: rgba(112, 112, 112, 0.61);
    font-size: 12pt;
    font-weight: 300;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
        }
        @media(max-width: ${props => props.selectMq || '989px'}) {
        width:100%;
    }
`;

const contRow = styled.div`
display: flex;
justify-content: ${props=> props.jc || 'flex-start'};
align-items: ${props=> props.ai || 'center'};
flex-direction: ${props=> props.fd || 'row'};
width: ${props=> props.w || '100%'};
padding: ${props=> props.pd || 'none'};
overflow-y: ${props=> props.ov || ''};
`;

const contItemsCategories = styled.ul`
list-style: none;
padding-left: 0;
width: 100%;
margin-top: auto;
`;

const contItemCategorie = styled.li`
text-align: left;
text-indent:10px;
height: 50px;
width:100%;
border-bottom: 0.3px solid rgba(112, 112, 112, 0.2);
background: transparent;
border-radius: 5px;
padding: 10px;
&:hover {
    background: rgba(115, 103, 240, 0.09);
    cursor:pointer;
}
`;

const contLoader = styled.div`
width: 100%;
height: 100%;
margin-top: auto;
margin-bottom: auto;
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
.spinner-border {
    border: .25em solid #7367f0;
    border-right-color: transparent;
}
`;

const SelectcategorieCss = {
    cont,
    header,
    body,
    footer,
    input,
    text,
    contRow,
    buttonBack,
    contItemsCategories,
    contItemCategorie,
    contLoader,
    select
}

export default SelectcategorieCss;