import React, { useState, useContext, useEffect } from 'react'
import ListFiltersStyles from '../../../../assets/css/components/admin/filters/ListFiltersCss'
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import axios from 'axios';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import verifyAccessRole from '../../../../assets/js/verifyAccessRole';
import Routes from '../../../../Routes';

export default function FormFilters() {
    const [redirect, setRedirect] = useState(null);
    const countriesInput = useRef(null);
    const pluginsInput = useRef(null);
    const attrInput = useRef(null);
    const regexpInput = useRef(null);
    const valueInput = useRef(null);
    const denyInput = useRef(null);
    const messageInput = useRef(null);
    const globalContext = useContext(GlobalContext);
    const [countries, setCountries] = useState('');
    const [plugins, setPlugins] = useState('');
    const [attr, setAttr] = useState('');
    const [regexp, setRegexp] = useState('');
    const [value, setValue] = useState('');
    const [deny, setDeny] = useState(false);
    const [message, setMessage] = useState('');
    const [caseInsensitive, setCaseInsensitive] = useState(false);
    const [deleteFound, setDeleteFound] = useState(false);
    const [deleteBetweenText, setDeleteBetweenText] = useState(false)


    const [loader, setLoader] = useState(false)
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 18);
        if (!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    }, []);

    const createPluginPublishFilter = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg('Necesita selccionaruna empresa!')
            return
        }

        if ((!deny && !deleteFound) || (deny === "false" && deleteFound === 'false')) {
            globalContext.showModalWithMsg('Es necesario que por lo menos este un valor en si entre negar la palabra o eliminarla si la encuentra!')
            return
        }
        setLoader(true);
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-publish-filter/${entId}`, {
            countries: [countries],
            plugins: [plugins],
            attr: attr,
            ...(regexp.length > 0 && { regexp: regexp }),
            ...(value.length > 0 && { value: value }),
            deny: deny === 'false' ? false : true,
            message: message,
            ...(value.length === 0 && { caseInsensitive: caseInsensitive }),
            ...(value.length === 0 && { deleteFound: deleteFound }),
            deleteBetweenText: deleteBetweenText
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            }
        }).then(() => {
            globalContext.showModalWithMsg('Creacion exitosa!')
            setLoader(false);
            setCountries('')
            setPlugins('')
            setAttr('')
            setRegexp('')
            setValue('')
            setDeny(false)
            setMessage('')
            setCaseInsensitive(false);
            setDeleteFound(false);
            countriesInput.current.value = ''
            pluginsInput.current.value = ''
            attrInput.current.value = ''
            regexpInput.current.value = ''
            valueInput.current.value = ''
            denyInput.current.value = ''
            messageInput.current.value = ''
        }).catch(err => {
            setLoader(false);
            handleAxiosError(err);
        });
    };

    return (
        <ListFiltersStyles.cont>
            {redirect}
            <ListFiltersStyles.header>
                <ListFiltersStyles.text fw="700" size="15pt" mr="10px">
                    Filtros
                </ListFiltersStyles.text>
                <ListFiltersStyles.text size="15pt" style={{ marginRight: 10, color: "#7357f0", cursor: 'pointer' }} onClick={() => window.history.back()}>
                    | Lista de Filtros
                </ListFiltersStyles.text>
                <ListFiltersStyles.text size="15pt">
                    | Crear una nueva restricción
                </ListFiltersStyles.text>
            </ListFiltersStyles.header>

            <ListFiltersStyles.formFilter>
                <ListFiltersStyles.anotherCont jc="flex-start">
                    <ListFiltersStyles.text size="15pt" fw="500">
                        Formulario para nueva restricción
                    </ListFiltersStyles.text>
                </ListFiltersStyles.anotherCont>
                <ListFiltersStyles.gridForm>
                    <ListFiltersStyles.contInputAndLabel>
                        <ListFiltersStyles.text fw="400" size="12pt">
                            País
                        </ListFiltersStyles.text>
                        <ListFiltersStyles.selectCuston onChange={(e) => setCountries(e.target.value)} ref={countriesInput}>
                            <option value="">
                                Seleccione
                            </option>
                            <option value="co">
                                Colombia
                            </option>
                            <option value="ve">
                                Venezuela
                            </option>
                        </ListFiltersStyles.selectCuston>
                    </ListFiltersStyles.contInputAndLabel>

                    <ListFiltersStyles.contInputAndLabel>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip id='tooltip-markeplace'>
                                    Debe seleccionar el markeplace donde quiere que se aplique dicho filtro
                                </Tooltip>
                            }
                        >
                            <ListFiltersStyles.text fw="400" size="12pt">
                                Markeplace
                            </ListFiltersStyles.text>
                        </OverlayTrigger>
                        <ListFiltersStyles.selectCuston onChange={(e) => setPlugins(e.target.value)} ref={pluginsInput}>
                            <option value="">
                                Seleccione
                            </option>
                            <option value="ml">
                                Mercado Libre
                            </option>
                            <option value="ln">
                                Linio
                            </option>
                        </ListFiltersStyles.selectCuston>
                    </ListFiltersStyles.contInputAndLabel>

                    <ListFiltersStyles.contInputAndLabel>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip id='tooltip-markeplace'>
                                    Debe seleccionar el campo donde quiere validar que este su Expresión Regular o el valor exacto!
                                </Tooltip>
                            }
                        >
                            <ListFiltersStyles.text fw="400" size="12pt">
                                Campo a validar
                            </ListFiltersStyles.text>
                        </OverlayTrigger>
                        <ListFiltersStyles.selectCuston onChange={(e) => setAttr(e.target.value)} ref={attrInput}>
                            <option value="">
                                Seleccione
                            </option>
                            <option value="title">
                                Titulo
                            </option>
                            <option value="description.plain_text">
                                Descripcion
                            </option>
                            <option value="all_attr">
                                Caracteristicas y titulo
                            </option>

                        </ListFiltersStyles.selectCuston>
                    </ListFiltersStyles.contInputAndLabel>

                    <ListFiltersStyles.contInputAndLabel>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip id='tooltip-markeplace'>
                                    Esta Expresión Regular sera buscada en el campo para el cual se aplique la restricción "es sensible a mayusculas y minisculas"
                                </Tooltip>
                            }
                        >
                            <ListFiltersStyles.text fw="400" size="12pt">
                                Expresión Regular
                            </ListFiltersStyles.text>
                        </OverlayTrigger>
                        <ListFiltersStyles.input
                            placeholder="Escriba aqui"
                            onChange={(e) => setRegexp(e.target.value)}
                            ref={regexpInput}
                            readOnly={value.length > 0}
                        />
                    </ListFiltersStyles.contInputAndLabel>



                    <ListFiltersStyles.contInputAndLabel>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip id='tooltip-markeplace'>
                                    Si usted coloca este valor como "si" esta restricción no le permitira publicar nada que possea la Expresión Regular o el valor exacto que usted coloco,
                                    si lo coloca como "no" no dejara publicar nada que no possea la Expresión Regular o el valor exacto
                                </Tooltip>
                            }
                        >
                            <ListFiltersStyles.text fw="400" size="12pt">
                                Negar
                            </ListFiltersStyles.text>
                        </OverlayTrigger>
                        <ListFiltersStyles.selectCuston onChange={(e) => setDeny(e.target.value)} ref={denyInput} >
                            <option value="">
                                Seleccione
                            </option>
                            <option value={true}>
                                Si
                            </option>
                            <option value={false}>
                                No
                            </option>
                        </ListFiltersStyles.selectCuston>
                    </ListFiltersStyles.contInputAndLabel>
                    {
                        value.length === 0 &&
                        (
                            <>
                                <ListFiltersStyles.contInputAndLabel>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id='tooltip-markeplace'>
                                                Para que la busqueda la haga ignorando mayusculas y Minúsculas,
                                                solo aplica para el campo de Expresión Regular
                                            </Tooltip>
                                        }
                                    >
                                        <ListFiltersStyles.text fw="400" size="12pt">
                                            Ignorar Mayusculas y Minúsculas?
                                        </ListFiltersStyles.text>
                                    </OverlayTrigger>
                                    <ListFiltersStyles.selectCuston onChange={(e) => setCaseInsensitive(e.target.value)} >
                                        <option value={null}>
                                            Seleccione
                                        </option>
                                        <option value={true}>
                                            Si
                                        </option>
                                        <option value={false}>
                                            No
                                        </option>
                                    </ListFiltersStyles.selectCuston>
                                </ListFiltersStyles.contInputAndLabel>


                                <ListFiltersStyles.contInputAndLabel>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id='tooltip-markeplace'>
                                                Si desea eliminar la palabra cuando se encuentre aunque este dentro de un texto Ej: si buscas ala y esta eso en true, la va a encontrar en sala
                                            </Tooltip>
                                        }
                                    >
                                        <ListFiltersStyles.text fw="400" size="12pt">
                                            Eliminar palabra dentro de otras
                                        </ListFiltersStyles.text>
                                    </OverlayTrigger>
                                    <ListFiltersStyles.selectCuston defaultValue={deleteBetweenText} onChange={(e) => setDeleteBetweenText(e.target.value)}>
                                        <option value={null}>
                                            Seleccione
                                        </option>
                                        <option value={true}>
                                            Si
                                        </option>
                                        <option value={false}>
                                            No
                                        </option>
                                    </ListFiltersStyles.selectCuston>
                                </ListFiltersStyles.contInputAndLabel>

                                {
                                    (deny === "false" || !deny) &&
                                    (
                                        <ListFiltersStyles.contInputAndLabel>
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip id='tooltip-markeplace'>
                                                        Si desea eliminar la palabra cuando se encuentre debe colocar este campo como "si",
                                                        solo aplica para el campo de Expresión Regular
                                                    </Tooltip>
                                                }
                                            >
                                                <ListFiltersStyles.text fw="400" size="12pt">
                                                    Eliminar palabra si la encuentra?
                                                </ListFiltersStyles.text>
                                            </OverlayTrigger>
                                            <ListFiltersStyles.selectCuston onChange={(e) => setDeleteFound(e.target.value)}>
                                                <option value={null}>
                                                    Seleccione
                                                </option>
                                                <option value={true}>
                                                    Si
                                                </option>
                                                <option value={false}>
                                                    No
                                                </option>
                                            </ListFiltersStyles.selectCuston>
                                        </ListFiltersStyles.contInputAndLabel>
                                    )
                                }
                            </>
                        )
                    }
                </ListFiltersStyles.gridForm>

                <ListFiltersStyles.anotherCont pl='10px' pr='10px' jc="flex-start">
                    <ListFiltersStyles.contInputAndLabel>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip id='tooltip-markeplace'>
                                    Este mensaje le aparecera cuando se cumpla la condicion de la restricción
                                </Tooltip>
                            }
                        >
                            <ListFiltersStyles.text fw="400" size="12pt">
                                Mensaje
                            </ListFiltersStyles.text>
                        </OverlayTrigger>
                        <ListFiltersStyles.textArea placeholder="Escriba aqui" onChange={(e) => setMessage(e.target.value)} ref={messageInput} />
                    </ListFiltersStyles.contInputAndLabel>
                </ListFiltersStyles.anotherCont>

                <ListFiltersStyles.anotherCont top="10px" jc="flex-end">
                    <ListFiltersStyles.btnAction disabled={loader} onClick={() => createPluginPublishFilter()}>
                        Guardar
                    </ListFiltersStyles.btnAction>
                </ListFiltersStyles.anotherCont>

            </ListFiltersStyles.formFilter>

        </ListFiltersStyles.cont>
    )
}
