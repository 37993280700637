import React, { useState, useContext } from 'react'
import { useEffect } from 'react';
import {
  ContListcategories,
  HeaderListCategories,
  BodyListCategories,
  FooterListCategories,
  ListCategories,
  ItemListCategories,
  ContLabelItem,
  ContIconOpenSubList,
  SubListCategories,
  ContbtnAddCategoryToLink,
  BtnAddToList
} from '../../../../../../assets/css/components/admin/categories/LinkCategories'
import { Text } from '../../../../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts'


import axios from 'axios';

import { Spinner } from 'react-bootstrap';
import { BiCaretDown, BiCheckCircle } from 'react-icons/bi'

import MassiveScrapingCss from '../../../../../../assets/css/components/admin/products/amazon/MassiveScrapingCss'
import { useRef } from 'react';
import { GlobalContext } from '../../../../../../contexts/GlobalContext';
import getAllParentsMl from '../../../../categories/functions/getAllParentsMl';
import getChildsML from '../../../../categories/functions/getChildsMl';
import Pager from '../../../../../general/Pager';

export default function SelectMlCategory({ category, setCategory, isLoad, setIsLoad, currentStep, setCurrentStep}) {

  const pageItemCount = 20;
  const globalContext = useContext(GlobalContext);
  const [datacategoryOn, setdatacategoryOn] = useState(null);
  const [totalCategories, setTotalCategories] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [actualCategory, setActualCategory] = useState(null);
  const [loadChild, setLoadChild] = useState(null);
  const [load, setLoad] = useState(true);
  const [filter, setFilter] = useState('');
  const [categories, setCategories] = useState([]);
  const [hasChild, setHasChild] = useState([]);
  const [childOnFocus, setChildOnFocus] = useState([]);
  const [childByparent, setChildByparent] = useState([]);
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [idReadyToLink, setIdReadyToLink] = useState([]);
  const [filterTSearch, setFilterTSearch] = useState('')
  const urlRef = useRef(null);


  /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
  const handleAxiosError = (err, msg = null, consoleLog = true) => {
    if (axios.isCancel(err)) return; //Don't show axios cancel error
    if (err.response && err.response.data) {
      if (err.response.data.message) {
        globalContext.showModalWithMsg(err.response.data.message);
      } else {
        globalContext.showModalWithMsg(err.response.data);
      }
    } else if (msg) {
      globalContext.showModalWithMsg(msg);
    }

    if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
  };

  const setFilterToPublish = (e) => {
    e.preventDefault();
    setFilterTSearch(filter);
}

  useEffect(() => {

    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;
    getAllParentsMl(entId, currentPage, pageItemCount, filterTSearch, axiosCancelTokenSource)
      .then((r) => {
        const data = r.data.data
        setCategories(data.categories)
        setTotalCategories(data.totalCategories)
      })
      .catch((e) => { console.log(e); handleAxiosError(e, 'Presentamos un error al cargar las categorias de ml', e) })

  }, [currentPage, filterTSearch, globalContext.currentEnterpriseId]);





  const removeAll = () => {
    //? buscamos el padre y lo eliminamos
    setHasChild([])
    setChildOnFocus([])
    setChildByparent([])
    setActualCategory(null)
  }

  /**
 * 
 * @param {*} node
 *  @description buscamos los hijos de los padres principales o de los que no tiene padre
 */
  const searchChild = (id) => {

    if (actualCategory === id) {
      removeAll();
      return;
    }

    const entId = globalContext.currentEnterpriseId;

    setLoadChild(id);
    setActualCategory(id);
    getChildsML(entId, id, axiosCancelTokenSource)
      .then((r) => {
        if (r.data.data.categories.length > 0) {
          setHasChild([...hasChild, id]);
          setChildByparent([...childByparent, {
            parent: id,
            childs: r.data.data.categories
          }])
        }else {
          setHasChild([...hasChild, id]);
          setChildByparent([...childByparent, {
            parent: id,
            childs: []
          }])
        }
        setLoadChild(null)
      }).catch(e => { handleAxiosError(e, 'Presentamos un error al cargar las categorias'); setLoadChild(null); console.log(e) })

    //? agg el node al array de padres con hijos
  }


  const renderChild = (node, name, _id) => {
    if (node) {
        const childs = childByparent.find((c) => c.parent === node);
        if (typeof childs !== undefined) {
            //? mapeamos el array de child
            if (childs.childs.length > 0) {
                return (
                    <SubListCategories>
                        {
                            childs.childs.map((v) => {
                                return (
                                    <ItemListCategories key={v._id}>
                                        <ContLabelItem active={childOnFocus.includes(v._id)} onClick={() => searchSubChild(v._id, v._id)} >
                                                {v.name} - {v.id}
                                            {
                                                loadChild === v._id ?
                                                    (
                                                        <ContIconOpenSubList>
                                                            <Spinner animation="border" role="status">
                                                                <span className="sr-only">Cargando...</span>
                                                            </Spinner>
                                                        </ContIconOpenSubList>
                                                    ) :
                                                    idReadyToLink.includes(v._id) ?
                                                    (
                                                        <ContIconOpenSubList>
                                                            <BiCheckCircle />
                                                        </ContIconOpenSubList>
                                                    )
                                                    :
                                                    (
                                                        <ContIconOpenSubList active={childOnFocus.includes(v._id)}>
                                                            <BiCaretDown />
                                                        </ContIconOpenSubList>
                                                    )
                                            }
                                        </ContLabelItem>
                                        {
                                            hasChild.includes(v._id) &&
                                            renderChild(v._id, v.name, v.id)
                                        }
                                    </ItemListCategories>
                                )
                            })
                        }
                    </SubListCategories>
                )
            } else {
                return (
                    <ContbtnAddCategoryToLink j="flex-start">
                    <BtnAddToList onClick={() => setCategoryToPost(node)}>
                        Seleccionar
                    </BtnAddToList>
                  </ContbtnAddCategoryToLink>
                )
            }
        }
    }
}

const removeChild = (id) => {
  //? buscamos el padre y lo eliminamos

  let copyhasChildArray = hasChild.filter((nv) => nv !== id)
  let copyChildByParent = childByparent.filter((nv) => nv.parent !== id)
  const copyChildOnFocus = childOnFocus.filter((nv) => nv !== id)
  
  setChildOnFocus(copyChildOnFocus)
  setChildByparent(copyChildByParent)
  setHasChild(copyhasChildArray);
}

    /**
     * 
     * @param {*} node 
     * @description buscamos los hijos para las categorias que si tiene padre
     */
    const searchSubChild = (node, id) => {
      const entId = globalContext.currentEnterpriseId;

      if(childOnFocus.includes(node)) {
          removeChild(node);
          return
      }

      setLoadChild(node);
      setChildOnFocus([...childOnFocus, node]);
      getChildsML(entId, node, axiosCancelTokenSource)
          .then((r) => {
              let copyhasChildArray = [...hasChild];
              let copyChildByParent = [...childByparent];

              if (r.data.data.categories.length > 0) {
                  copyhasChildArray.push(node);
                  copyChildByParent.push({
                      parent: node,
                      childs: r.data.data.categories
                  })
                  setChildByparent(copyChildByParent)
                  setHasChild(copyhasChildArray);
              } else {
                  copyhasChildArray.push(node);
                  copyChildByParent.push({
                      parent: node,
                      childs: []
                  })
                  setChildByparent(copyChildByParent)
                  setHasChild(copyhasChildArray);
                  let copyArrayReadyToLink = [...idReadyToLink];
                  copyArrayReadyToLink.push(id);
                  setIdReadyToLink(copyArrayReadyToLink);
              }
              setLoadChild(null)
          }).catch(e => { handleAxiosError(e, 'Presentamos un error al cargar las categorias'); setLoadChild(null); console.log(e) })
      //? agg el node al array de padres con hijos
    }

    const setCategoryToPost = (v) => {
        setCategory(v);
        setCurrentStep(currentStep + 1);
      }

  return (
    <ContListcategories w="400px" mh="100vh">
      <HeaderListCategories>
        <Text size='13pt' fw="bold" cl="#696969">
          Categorias de ML {datacategoryOn ? `- ${datacategoryOn.nameCategory.en}` : ''}
        </Text>
        <form style={{width: '100%'}} onSubmit={(e) => setFilterToPublish(e)}>
          <MassiveScrapingCss.inputOne placeholder="URL" type="text" onChange={(e)=> setFilter(e.target.value)} ref={urlRef} />
        </form>
      </HeaderListCategories>
      <BodyListCategories>
        <ListCategories>
          {
            categories.length > 0 &&
            categories.map((v) => {
              return (
                <ItemListCategories key={v._id} >
                  <ContLabelItem active={actualCategory === v._id} onClick={() => {searchChild(v._id)}}>
                    {v.country} - {v.name} - {v.id}
                    {
                      loadChild === v._id ?
                        (
                          <ContIconOpenSubList>
                            <Spinner animation="border" role="status">
                              <span className="sr-only">Cargando...</span>
                            </Spinner>
                          </ContIconOpenSubList>
                        ) :
                        (
                          <ContIconOpenSubList active={actualCategory === v._id}>
                            <BiCaretDown />
                          </ContIconOpenSubList>
                        )
                    }
                  </ContLabelItem>
                  {
                    loadChild !== v._id &&
                    (actualCategory === v._id) &&
                    hasChild.includes(v._id) &&
                    renderChild(v._id, v.name, v.id)
                  }
                </ItemListCategories>
              )
            })
          }
        </ListCategories>
      </BodyListCategories>
      <FooterListCategories>
        <Pager
          handleSetPage={setCurrentPage}
          totalResults={totalCategories}
          currentPage={currentPage}
          resultsPerPage={pageItemCount}
        />
      </FooterListCategories>
    </ContListcategories>
  )
}
