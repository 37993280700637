import React, { useEffect, useContext, useState } from 'react'
import { Form, Col, Row, Container, Button } from "react-bootstrap";
import {
    keyUserId,
    keyAccessToken,
} from "../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../contexts/GlobalContext";
import axios from 'axios';
import { Redirect } from "react-router-dom";
import Routes from "../../Routes";
import verifyAccessRole from "../../assets/js/verifyAccessRole";



export default function Duplicate() {
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [inputs, setInputs] = useState({});
    const [srcEmp, setSrcEmp] = useState([]);
    const [desEmp, setDesEmp] = useState([]);


    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };
    const getEnterprise = () => {

        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/enterprise/listAll`,{},
            {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                }
        })
    };
    const updateInputState = (e) => {
        const name = e.target.name;
        const newState = Object.assign({}, inputs);

        switch (e.target.type) {
            case "checkbox":
                newState[name] = e.target.checked;
                break;

            default:
                newState[name] = e.target.value;
                break;
        }

        setInputs(newState);
    };
    useEffect(() => { 
        getEnterprise()
        .then((res)=>{
            const data = res.data.data.enterprises;
            setSrcEmp(data);
            setDesEmp(data);
        })
        .catch((e)=>{console.log(e)});
    }, []);

    const duplicateProd = () => {
        globalContext.setLoaderPage(true);
        if(inputs.srcEmp===inputs.desEmp){
            globalContext.showModalWithMsg('Las empresas deben ser distintas');
            globalContext.setLoaderPage(false);
        }else{
            axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/product/duplicate/${inputs.srcEmp}/${inputs.desEmp}/${inputs.plugin}`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                const data = resp.data.data;
                globalContext.setLoaderPage(false);
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo duplicar los productos."
                );
                globalContext.setLoaderPage(false);
            }); 
        }
             
    }

    return (
        <Row>
            <Col md={6}>
                Empresa Origen
                <br />
                <Form.Control
                    label=""
                    as="select"
                    size="sm"
                    name="srcEmp"
                    onChange={updateInputState}

                >
                    <option value="">Seleccione</option>
                    {
                        srcEmp.length > 0 &&
                        srcEmp.map((v) => {
                            return (
                                <option key={v._id} value={v._id}>{v.name}</option>
                            )
                        })
                    }
                </Form.Control>
            </Col>
            <Col md={6}>
                Empresa Destino
                <br />
                <Form.Control
                    label=""
                    as="select"
                    size="sm"
                    name="desEmp"
                    onChange={updateInputState}
                >
                    <option value="">Seleccione</option>
                    {
                        desEmp.length > 0 &&
                        desEmp.map((v) => {
                            return (
                                <option key={v._id} value={v._id}>{v.name}</option>
                            )
                        })
                    }
                </Form.Control>
            </Col>
            <Col md={6}>
                Plugin
                <br />
                <Form.Control
                    label=""
                    as="select"
                    size="sm"
                    name="plugin"
                    onChange={updateInputState}
                >
                    <option value="">Seleccione</option>
                    <option value="amazon">Amazon</option>
                    <option value="ali">Ali-Express</option>
                </Form.Control>
            </Col>
            <Col md={3}>

                <Button onClick={() => duplicateProd()}>Duplicar!!</Button>
            </Col>
        </Row>
    )
}
