import React, { useContext, useEffect, useRef, useState } from 'react'
import { Text } from '../../../../../assets/css/components/admin/marketplace-to-marketplace/modals/ModalSelectStoreAndSection'
import TableSearch from '../../../../../assets/css/components/admin/marketplace-to-marketplace/table-list/TableSearch'
import { BiChevronRight, BiX, BiArrowBack, BiCheck } from "react-icons/bi";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import Pager from '../../../../general/Pager';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';
export default function TableCategories({
    downloadProd
}) {
    const [listCategories, setListCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageItemCount, setPageItemCount] = useState(16);
    const [totalCategories, setTotalCategories] = useState(0);
    const [filter, setFilter] = useState('');
    const [isLoad, setIsLoad] = useState(true);
    const refInputSearch = useRef(null);
    const [wordBySearch, setWordBySearch] = useState('');
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());

    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data && err.response.data.message) {
            globalContext.showModalWithMsg(err.response.data.message);
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo/odooCategoriesList`, {

                entId: entId,
                pageNumber: currentPage,
                pageItemCount: pageItemCount,
                ...(filter !== '' & {searchVal: filter})
            }, {
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            })
            .then((resp) => {
                console.log('datra categories', resp.data)
                const data = resp.data.data;
                setListCategories(data.cat);
                setTotalCategories(data.totalCat)
                setIsLoad(false);
            })
            .catch((err) => {
                setIsLoad(false);
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo cargar la lista de tarifas."
                )
            }
            );
    }, [globalContext.currentEnterpriseId]);

    const setWordBySearchACategory = (e) => {
        e.preventDefault();
        if (wordBySearch.length > 0) {
            setFilter(wordBySearch.trim());
        }
    }

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <TableSearch.ContTable>
            <TableSearch.HeaderContTable>

                <Text size="13pt" fw="500" style={{ alignSelf: 'flex-start' }}>
                    Listado de Categorias para Odoo
                </Text>

                <TableSearch.ContInputSearch onSubmit={(e) => setWordBySearchACategory(e)}>
                    <TableSearch.ContIconInputSearch>
                        <TableSearch.InputSearch
                            placeholder='Busque por el nombre de la categoria'
                            ref={refInputSearch}
                            onChange={(e) => setWordBySearch(e.target.value)} />
                        {
                            filter.length > 0 &&
                            (
                                <TableSearch.BtnInputSearch onClick={() => {
                                    setFilter('')
                                    setWordBySearch('')
                                    refInputSearch.current.value = ''

                                }}>
                                    <BiX />
                                </TableSearch.BtnInputSearch>
                            )
                        }
                    </TableSearch.ContIconInputSearch>
                </TableSearch.ContInputSearch>
            </TableSearch.HeaderContTable>
            <TableSearch.TableResult>
                <TableSearch.TableResultThead>
                    <TableSearch.TableResultTr>
                        <TableSearch.TableResultTh width="300px" minWidth="100px" textAlign="center">
                            Nombre
                        </TableSearch.TableResultTh>
                        <TableSearch.TableResultTh width="300px" minWidth="100px" textAlign="center">
                            ID Odoo
                        </TableSearch.TableResultTh>
                        <TableSearch.TableResultTh width="100px" minWidth="100px" textAlign="center">

                        </TableSearch.TableResultTh>
                    </TableSearch.TableResultTr>
                </TableSearch.TableResultThead>
                {
                    isLoad ? (
                        <TableSearch.TableResultTbody>
                            <TableSearch.TableResultTr>
                                <TableSearch.TableResultTd width="100%" minWidth="100px" textAlign="center" colSpan="3">
                                    <TableSearch.contLoader>
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Cargando...</span>
                                        </Spinner>
                                    </TableSearch.contLoader>
                                </TableSearch.TableResultTd>
                            </TableSearch.TableResultTr>

                        </TableSearch.TableResultTbody>
                    ) :
                        (
                            <TableSearch.TableResultTbody>
                                {
                                    listCategories.length > 0 &&
                                    listCategories.map((v) => {
                                        return (
                                            <TableSearch.TableResultTr key={v._id}>
                                                <TableSearch.TableResultTd width="300px" minWidth="100px" textAlign="center">
                                                    {v.name}
                                                </TableSearch.TableResultTd>
                                                <TableSearch.TableResultTd width="300px" minWidth="100px" textAlign="center">
                                                    {v.odooId}
                                                </TableSearch.TableResultTd>
                                                <TableSearch.TableResultTd width="100px" minWidth="100px" textAlign="center" backGroundColor="#F3F2F7">
                                                    <div className="d-flex justify-content-center align-items-center" style={{ with: '100%' }}>
                                                        <TableSearch.BtnIcon
                                                            onClick={() => downloadProd(v.odooId)}
                                                            width="38px"
                                                            height="38px"
                                                        >
                                                            <BiChevronRight />
                                                        </TableSearch.BtnIcon>
                                                    </div>
                                                </TableSearch.TableResultTd>
                                            </TableSearch.TableResultTr>
                                        )
                                    })
                                }
                            </TableSearch.TableResultTbody>
                        )
                }
            </TableSearch.TableResult>
            <TableSearch.FooterContTable>
                {
                    totalCategories > 0 && (
                        <Pager
                            handleSetPage={setCurrentPage}
                            totalResults={totalCategories}
                            currentPage={currentPage}
                            resultsPerPage={pageItemCount}
                        />
                    )
                }
            </TableSearch.FooterContTable>

        </TableSearch.ContTable>
        </div>
    )
}
