import styled from "styled-components";

const ContForm = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content:  space-between;
align-items: center;
box-sizing: border-box;
`;

const ContHeader = styled.div`
align-self: flex-start;
display: flex;
align-items: center;
justify-content: flex-start;
`;
const Form = styled.div`
border-radius: 0.429rem;
background: #FFFFFF;
min-width: 300px;
width: 800px;
max-width: 800px;
padding: 1rem;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
`
const GridForm = styled.div`
width: 100%;
display: grid;
grid-gap: 0.5rem;
grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`;

const contInputAndLabel = styled.div`
width:100%;
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'space-between'};
align-items:${props => props.ali || 'flex-start'};
`;

const BtnSend = styled.button`
outline: none;
border: none;
border-color: #7367f0;
width: 100%;
height: 38px;
color: white;
font-size: 12pt;
font-weight: 500;
text-align: center;
background-color: #7367f0;
padding: .150rem;
border-radius: 0.358rem;
margin-top: 1rem;
&:disabled {
    border-color: #a49df5;
    background-color: #a49df5;
}
`;
const Text = styled.p `
color: ${props => props.cl || '#707070'};
font-size: ${props => props.size || '.857rem'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '.2857rem'};
margin-bottom: ${props => props.mb || '.2857rem'};
text-overflow:${props => props.longText ? 'ellipsis' : ''};
overflow:${props => props.longText ? 'hidden' : ''};
white-space:${props => props.longText ? 'nowrap' : ''};
cursor: pointer;
`;

const TextLink = styled.a `
color: ${props => props.cl || '#707070'};
font-size: ${props => props.size || '.857rem'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '.2857rem'};
margin-bottom: ${props => props.mb || '.2857rem'};
text-overflow:${props => props.longText ? 'ellipsis' : ''};
overflow:${props => props.longText ? 'hidden' : ''};
white-space:${props => props.longText ? 'nowrap' : ''};
text-decoration: ${props => props.textDecoration ? 'underline' : 'none'};
`

const Input = styled.input`
display: block;
width: 100%;
height: 38px;
font-size: .875rem;
font-weight: 400;
line-height: 1.45;
color: #6e6b7b;
padding: 0.438rem 1rem;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #d8d6de;
border-radius: 0.357rem;
position: relative;
&:focus {
    color: #6e6b7b;
    outline: 0;
    background-color: #fff;
    border-color: #7367f0;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}
::placeholder {
    color: #c0c0c1;
    font-size: 1rem;
}
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
`;

const ContBadgeError = styled.div`
border-radius: 0.358rem;
background: ${props => props.isError ? '#ea5455!important' : '#28c76f!important'};
width: 100%;
min-height: ${props => props.active ? '50px' : '0px'};;
height: ${props => props.active ? 'auto' : '0px'};
padding: ${props => props.active ? '1rem' : '0'};
opacity:  ${props => props.active ? '1' : '0'};
position: relative;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
margin-bottom: 1rem;
overflow: hidden;
transition: opacity .15s ease-in-out, height .15s ease-in-out, padding .1s ease-in-out;
`;

const BtnToggleBadge = styled.button`
width: 25px;
height: 25px;
outline: none !important;
border-radius: 50px;
display: flex;
justify-content: center;
align-items: center;
border: 1px solid #FFFFFF;
color: #FFFFFF;
background-color: transparent;
cursor: pointer;
position: absolute;
left: auto;
top: auto;
right: 10px;
bottom: auto;
`;

const FormNewUserStyle = {
    ContForm,
    Form,
    GridForm,
    contInputAndLabel,
    BtnSend,
    Text,
    Input,
    ContHeader,
    TextLink,
    ContBadgeError,
    BtnToggleBadge
}
export default FormNewUserStyle;