import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import ListFiltersStyles from "../../../../assets/css/components/admin/filters/ListFiltersCss";
import TableData from "../components/TableData";
import {
    keyAccessToken,
    keyUserId,
} from "../../../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Routes from "../../../../Routes";
import verifyAccessRole from "../../../../assets/js/verifyAccessRole";
import MultiScraperStyles from "../../../../assets/css/components/admin/products/amazon/MultiScraperStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { set } from "date-fns/esm";


export default function ListFilters() {
    const globalContext = useContext(GlobalContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageItemCount, setPageItemCount] = useState(15);
    const [isLoader, setIsLoader] = useState(true);
    const [redirect, setRedirect] = useState(null);
    const [data, setData] = useState([]);
    const [regexp, setRegexp] = useState('');
    const [valueInputFilterRegex, setValueInputFilterRegex] = useState('');
    const [attr, setAttr] = useState('')
    const regexInput = useRef(null);
    const [deny, setDeny] = useState('');
    const [deleteFound, setDeleteFound] = useState('');

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 17);
        if (!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    }, []);

    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const getAllFilters = (entId, pageNumber, pageItemCount, regexp, attr, deny, deleteFound) => {
        const resp = axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-publish-filter/${entId}`,
            {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
                params: {
                    pageNumber: pageNumber,
                    pageItemCount: pageItemCount,
                    filter: {
                        ...((regexp.length > 0) && { regexp: regexp }),
                        ...((attr.length > 0) && { attr: attr }),
                        ...((deny.lengh > 0) && { deny: deny === 'false' ? false : true }),
                        ...((deleteFound.lengh > 0) && { deleteFound: deleteFound === 'false' ? false : true })
                    }
                },
            }
        );
        return resp;
    };

    // * este metodo nos servira para pausar cualquier filtro creado
    const disabledFilter = (id, index) => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("Necesita seleccionar una empresa para continuar!");
            return;
        }
        setIsLoader(true)
        axios
            .delete(
                `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-publish-filter/${entId}/${id}`,
                {
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then(() => {
                let copyArray = [...data];
                copyArray[index].enabled = false;
                setData(copyArray);
                globalContext.showModalWithMsg('El proceso se culmino con exito')
                setIsLoader(false)
            })
            .catch((err) => {
                setIsLoader(false)
                handleAxiosError(err)
            });
    };

    //* este metodo nos ayuda a activar cualquier filtro desactivado
    const enabledFilter = (id, index) => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("Necesita seleccionar una empresa para continuar!");
            return;
        }
        setIsLoader(true)
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-publish-filter/${entId}/${id}`, {
            enabled: 'true',
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(() => {
            let copyArray = [...data];
            copyArray[index].enabled = true;
            setData(copyArray);
            globalContext.showModalWithMsg('El proceso se culmino con exito')
            setIsLoader(false)
        })
            .catch((err) => {
                setIsLoader(false)
                handleAxiosError(err)
            });
    }

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        getAllFilters(entId, currentPage, pageItemCount, regexp, attr, deny, deleteFound)
            .then((res) => {
                const data = res.data.data;
                console.log('filtros', res)
                setData(data.filters);
                setIsLoader(false);
            })
            .catch((err) => {
                handleAxiosError(err);
                setIsLoader(false);
            });
    }, [globalContext.currentEnterpriseId, currentPage, pageItemCount, regexp, attr, deny, deleteFound]);

    const setFilterRegex = (e) => {
        e.preventDefault();
        if (valueInputFilterRegex.length > 0) {
            setRegexp(valueInputFilterRegex);
        } else {
            setRegexp('')
        }
    }

    const cleanSkuInput = () => {
        regexInput.current.value = '';
        setValueInputFilterRegex('')
        setRegexp('');
    }

    return (
        <ListFiltersStyles.cont>
            {redirect}
            <ListFiltersStyles.header>
                <ListFiltersStyles.text fw='700' size='15pt' mr='10px'>
                    Filtros
                </ListFiltersStyles.text>
                <ListFiltersStyles.text size='15pt'>
                    | Lista de Filtros para las Publicaciones
                </ListFiltersStyles.text>
            </ListFiltersStyles.header>
            <ListFiltersStyles.contTable>
                {!isLoader ? (
                    <>
                        <ListFiltersStyles.headerContTable>
                            <ListFiltersStyles.contActionOne>
                                <ListFiltersStyles.text size='12pt' mr='10px'>
                                    Registros por pagina
                                </ListFiltersStyles.text>
                                <ListFiltersStyles.selectCuston
                                    wd='70px'
                                    value={pageItemCount}
                                    onChange={(e) => setPageItemCount(e.target.value)}>
                                    <option value={15}>15</option>
                                    <option value={30}>30</option>
                                    <option value={45}>45</option>
                                    <option value={65}>65</option>
                                    <option value={85}>85</option>
                                    <option value={100}>100</option>
                                    <option value={120}>120</option>
                                    <option value={140}>140</option>
                                    <option value={160}>160</option>
                                    <option value={200}>200</option>
                                    <option value={500}>500</option>
                                    <option value={1000}>1000</option>
                                    <option value={10000}>Mas de 10000</option>
                                </ListFiltersStyles.selectCuston>
                                <Link to='create' style={{ textDecoration: 'none' }}>
                                    <ListFiltersStyles.btnAction>
                                        Crear Nuevo
                                    </ListFiltersStyles.btnAction>
                                </Link>
                                <ListFiltersStyles.btnAction
                                    onClick={() => globalContext.setTestFiltres(true)}
                                >
                                    Test
                                </ListFiltersStyles.btnAction>

                                <div style={{ width: 150, position: "relative", display: "flex", justifyContent: "flex-end", alignItems: "center", marginLeft: "auto", marginRight: 10 }}>
                                    <ListFiltersStyles.selectCuston onChange={(e) => setAttr(e.target.value)}>
                                        <option value="">
                                            Campo
                                        </option>
                                        <option value="title">
                                            Titulo
                                        </option>
                                        <option value="description.plain_text">
                                            Descripcion
                                        </option>

                                    </ListFiltersStyles.selectCuston>
                                </div>
                                <div style={{ width: 150, position: "relative", display: "flex", justifyContent: "flex-end", alignItems: "center", marginRight: 10 }}>
                                    <ListFiltersStyles.selectCuston onChange={(e) => setDeny(e.target.value)} >
                                        <option value="">
                                            ¿Está Negada?
                                        </option>
                                        <option value={true}>
                                            Si
                                        </option>
                                        <option value={false}>
                                            No
                                        </option>
                                    </ListFiltersStyles.selectCuston>
                                </div>
                                <div style={{ width: 150, position: "relative", display: "flex", justifyContent: "flex-end", alignItems: "center", marginRight: 10 }}>
                                    <ListFiltersStyles.selectCuston onChange={(e) => setDeleteFound(e.target.value)}>
                                        <option value={null}>
                                            Eliminar Palabra
                                        </option>
                                        <option value={true}>
                                            Si
                                        </option>
                                        <option value={false}>
                                            No
                                        </option>
                                    </ListFiltersStyles.selectCuston>
                                </div>


                                <div style={{ position: "relative", display: "flex", justifyContent: "flex-end", alignItems: "center", marginRight: 0 }}>
                                    <form onSubmit={(e) => setFilterRegex(e)} style={{ width: '250px' }}>
                                        <ListFiltersStyles.input placeholder="Expresión Regular" onChange={(e) => { setValueInputFilterRegex(e.target.value) }} ref={regexInput} />
                                    </form>
                                    <MultiScraperStyles.btnFloat onClick={(e) => cleanSkuInput()} style={{ marginTop: 10 }}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </MultiScraperStyles.btnFloat>
                                </div>
                            </ListFiltersStyles.contActionOne>
                            <ListFiltersStyles.contActionTwo>

                            </ListFiltersStyles.contActionTwo>
                        </ListFiltersStyles.headerContTable>
                        <ListFiltersStyles.bodyContentTable>
                            <TableData
                                data={data}
                                disabledFilter={disabledFilter}
                                enabledFilter={enabledFilter}
                            />
                        </ListFiltersStyles.bodyContentTable>
                        <ListFiltersStyles.footerContTable>

                        </ListFiltersStyles.footerContTable>
                    </>
                ) : (
                    <ListFiltersStyles.contLoader>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Cargando...</span>
                        </Spinner>
                    </ListFiltersStyles.contLoader>
                )}
            </ListFiltersStyles.contTable>
        </ListFiltersStyles.cont>
    );
}
