import React from 'react'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle'

export default function Navbar(props) {
    const {setCurrentStep, currentStep} = props;

    const changeStep = (v) => {
        setCurrentStep(v)
    }
    return (
        <PedidosYaStyle.ContNavbar>
            <PedidosYaStyle.MenuOptions>
                <PedidosYaStyle.OptionMenu active={currentStep === 4} onClick={() => changeStep(4)}>
                    Crear Campañas pagina web 
                </PedidosYaStyle.OptionMenu>
                <PedidosYaStyle.OptionMenu active={currentStep === 5} onClick={() => changeStep(5)}>
                    Listado de Campañas pagina web
                </PedidosYaStyle.OptionMenu>
            </PedidosYaStyle.MenuOptions>
        </PedidosYaStyle.ContNavbar>
    )
}
