import { keyAccessToken, keyUserId } from "../../../../assets/js/SessionStorageNames";
import axios from 'axios';

const getAllParentsMl = (entId, currentPage, pageItemCount, filter, axiosCancelTokenSource) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-category/${entId}`, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
        cancelToken: axiosCancelTokenSource.token,
        params: {
            pageNumber: currentPage,
            pageItemCount: pageItemCount,
            filter: {
                getAll: filter.length > 0 ? true : false,
                name: filter,
                lang: 'es',
                tree: false

            },
        }
    })
}

export default getAllParentsMl;