import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { keyUserId, keyAccessToken } from "../../../../../assets/js/SessionStorageNames";
import axios from "axios";

export default function DetailedReportOption(props) {
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const configData = {};

    switch (props.format) {
        case 'csv':
            configData.icon = faFileCsv;
            configData.title = "Reporte detalle en CSV";
            configData.responseType = 'json';
            break;

        case 'xlsx':
            configData.icon = faFileExcel;
            configData.title = "Reporte detalle en Excel"; 
            configData.responseType = 'arraybuffer';
            break;
    }

    /**
     * Cancel axios requests before unmount
     */
    useEffect(() => {
        return () => {
            axiosCancelTokenSource.cancel("Canceled by unmount");
        };
        //eslint-disable-next-line
    }, []);

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (msg) globalContext.showModalWithMsg(msg);
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    /**
     * Odoo detailed report
     */
    const detailedReport = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        globalContext.setLoaderPage(true);

        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-report/${entId}/report-detail/${props.format}`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers,
                    params: { 
                        filter: {
                            title: props.filter,
                            barCode: props.filter,
                            refInt: props.filter,
                            filterLinkedTo: props.filterLinkedTo,
                            filterPrice: props.filterPrice,
                            filterStock: props.filterStock,
                            filterWithCompany: props.filterWithCompany,
                        },
                    },
                }
            )
            .then(() => {
                globalContext.setLoaderPage(false);
                globalContext.showModalWithMsg(
                    `La descarga se esta generando en segundo plano!`
                );
            })
            .catch((err) => {
                globalContext.setLoaderPage(false);
                console.error(err);
                globalContext.showModalWithMsg(
                    `Ocurrio un error al descargar su archivo`
                );
            });
    };

    return (
        <div
            className={`items`}
            onClick={detailedReport}
        >
            <span className="ic">
                <FontAwesomeIcon icon={configData.icon} />
            </span>
            <p>{configData.title}</p>
        </div>
    );
}