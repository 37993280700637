import React from 'react'
import { ProgressBar } from 'react-bootstrap';
import OfflineProccesStyle from '../../../../assets/css/components/admin/offline-process/OfflineProccesStyle';
import {FaStop} from 'react-icons/fa';
import { VscRunAll } from 'react-icons/vsc'
import { AiOutlineCloudDownload } from 'react-icons/ai';
export default function TableData(props) {

    const changeDate = date => {
        //const newDate = new Date(date)
        // var dd = String(newDate.getDate()).padStart(2, '0');
        // var mm = String(newDate.getMonth() + 1).padStart(2, '0');
        // var yyyy = newDate.getFullYear();
        // return `${dd}-${mm}-${yyyy}`
        const repOne = date.replace('T', ' ');
        const repTwo = repOne.replace('Z', 'S');
        return repTwo
    }
    const {data, imgToDownload, dowloadimg} = props;

    
    return (
        <OfflineProccesStyle.tableData>
            <OfflineProccesStyle.tableDataThead>
                <tr>
                <OfflineProccesStyle.tableDataTh
                w="100px"
                >
                    Empezo
                </OfflineProccesStyle.tableDataTh>
                <OfflineProccesStyle.tableDataTh
                w="100px"
                >
                    Actualizo
                </OfflineProccesStyle.tableDataTh>

                <OfflineProccesStyle.tableDataTh
                w="60px"
                >
                    Tipo de Data
                </OfflineProccesStyle.tableDataTh>
                <OfflineProccesStyle.tableDataTh
                w="120px"
                >
                    Nombre de la tarea
                </OfflineProccesStyle.tableDataTh>
                <OfflineProccesStyle.tableDataTh
                w="120px"
                >
                    Avance
                </OfflineProccesStyle.tableDataTh>
                
                <OfflineProccesStyle.tableDataTh
                w="50px"
                >
                    Data
                </OfflineProccesStyle.tableDataTh>
                
                <OfflineProccesStyle.tableDataTh
                w="50px"
                >
                </OfflineProccesStyle.tableDataTh>
                </tr>
            </OfflineProccesStyle.tableDataThead>
            <OfflineProccesStyle.tableDataTbody>
                    {
                        data.length > 0 ?
                        data.map(v => {
                            return (
                                <OfflineProccesStyle.tableDataTr key={v._id}>
                                <OfflineProccesStyle.tableDataTd
                                w="100px"
                                >
                                    {changeDate(v.createdAt)}
                                </OfflineProccesStyle.tableDataTd>
                                <OfflineProccesStyle.tableDataTd
                                w="100px"
                                >
                                    {changeDate(v.updatedAt)}
                                </OfflineProccesStyle.tableDataTd>
                                <OfflineProccesStyle.tableDataTd
                                w="60px"
                                >
                                    {(v.info) && (v.info.type) && v.info.type}
                                </OfflineProccesStyle.tableDataTd>
                                <OfflineProccesStyle.tableDataTd
                                w="120px"
                                >
                                    {v.name}
                                </OfflineProccesStyle.tableDataTd>
                                <OfflineProccesStyle.tableDataTd
                                w="120px"
                                >
                                    <ProgressBar animated now={v.advance} label={`${v.advance}%`} />
                                </OfflineProccesStyle.tableDataTd>
                                
                                <OfflineProccesStyle.tableDataTd
                                w="50px"
                                style={{textAlign:'center'}}
                                >
                                    {
                                        (v.info) && (v.info.filePath) ?
                                        (!v.info.isPeya )?
                                        (
                                            <a 
                                            href ={`${process.env.REACT_APP_BACKEND_URL}/${v.info.filePath.replace(/.*\/public\/downloads/, 'download')}`}
                                            target="_black" 
                                            download="Report"
                                            >
                                                <OfflineProccesStyle.ContIcon color='#7367F0'>
                                                    </OfflineProccesStyle.ContIcon>
                                                <AiOutlineCloudDownload />
                                            </a>
                                        ) :
                                        (
                                            <a 
                                            href ={`${process.env.REACT_APP_BACKEND_URL_PEYA}/${v.info.filePath.replace(/.*\/public\/downloads/, 'download')}`}
                                            target="_black" 
                                            download="Report"
                                            >
                                                <OfflineProccesStyle.ContIcon color='#7367F0'>
                                                    </OfflineProccesStyle.ContIcon>
                                                <AiOutlineCloudDownload />
                                            </a>
                                        ) 
                                        :''

                                    }
                                </OfflineProccesStyle.tableDataTd>
                                
                                <OfflineProccesStyle.tableDataTd
                                w="50px"
                                >
                                    <OfflineProccesStyle.ContIcon color={v.active ? '#28c76f' : ''}>
                                        {v.active ? (<VscRunAll />) : (<FaStop />)}
                                        
                                    </OfflineProccesStyle.ContIcon>
                                </OfflineProccesStyle.tableDataTd>
                                
                            </OfflineProccesStyle.tableDataTr>
                            )
                        }) :
                        <OfflineProccesStyle.tableDataTr>
                            <OfflineProccesStyle.tableDataTdImg
                                w="100%"
                                colSpan="7"
                            >
                                <div className="d-flex justify-content-center align-items-center w-100">
                                    <OfflineProccesStyle.ContDataImgNotFound />
                                </div>
                            </OfflineProccesStyle.tableDataTdImg>
                        </OfflineProccesStyle.tableDataTr>
                    }

            </OfflineProccesStyle.tableDataTbody>
        </OfflineProccesStyle.tableData>
    )
}
