import styled from "styled-components";
const ProductDetailStyle = styled.div`
  .goBack {
    .btn-back {
      border: 1px solid rgb(94, 84, 142);
      color: rgb(94, 84, 142);
      width: 30px;
      height: 30px;
      background: transparent;
      border-radius: 50%;
      transition: all ease 450ms;
      &:hover {
        background: rgb(94, 84, 142);
        color: white;
      }
      &:focus,
      &:active,
      &:active:focus {
        outline: none;
      }
    }
  }
`;
export default ProductDetailStyle;
