import styled from "styled-components";
const CardProductDetailStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  body {
    font-family: "Roboto Slab", serif !important;
  }
  .CardBody {
    background-color: white;
    border: 1px solid #e5e9f2;
    border-radius: 0.25rem;
    visibility: visible;
    direction: inherit;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    min-height: 300px !important;
    max-height: 300px !important;
    height: 300px !important;
    overflow: auto scroll;
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(94, 84, 142);
      border-radius: 50px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .info-produc {
    word-break: break-word;
  }
  button {
    width: auto;
    padding: .5rem;
    height: 40px;
    background: rgb(94, 84, 142);
    display: inline-block;
    outline: 0;
    border: 0;
    margin: 1.5px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    border-radius: .425rem;
    letter-spacing: 1px;
    color: white;
    cursor: pointer;
    font-size: 12pt;
  }
  img {
    min-width: 140px !important;
    width: 140px !important;
    max-width: 140px !important;
  }
  .text-lp {
    font-size: 0.9em;
    letter-spacing: 1px;
  }
  .text-pp {
    margin-top: -5px;
  }
  .text-np {
    font-size: 1.2em;
    margin-top: -5px;
  }
  .text-sp {
    font-size: 10pt;
  }
  .text-lp,
  .text-pp,
  .text-np,
  .text-sp {
    font-family: "Roboto Slab", serif !important;
    margin-top: 0;
    margin-bottom: 4px;
    color: rgb(94, 84, 142);
    font-weight: bold;
  }
`;
export default CardProductDetailStyle;
