import React, { useContext, useEffect, useState } from "react";
import ConfigStyled from "../../../../assets/css/components/admin/config/ConfigStyled";
import axios from "axios";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import {
    keyAccessToken,
    keyUserId,
} from "../../../../assets/js/SessionStorageNames";
import { BiRadioCircleMarked } from "react-icons/bi";

export default function Ten() {
    const [isSending, setIsSending] = useState(false);
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [config, setConfig] = useState({});
    const [defaultConfig, setDefaultConfig] = useState({});
    

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (msg) globalContext.showModalWithMsg(msg);
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    /**
     * Change the config state
     * @param {Event} e The change event
     */
    const updateConfigState = (e) => {
        const name = e.target.name;
        const newConfig = Object.assign({}, config);

        switch (e.target.type) {
            case "checkbox":
                newConfig[name] = e.target.checked;
                break;

            default:
                newConfig[name] = e.target.value;
                break;
        }

        setConfig(newConfig);
    };

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        setIsSending(true);
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/odoo`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                const data = resp.data.data.configData;
                setIsSending(false);
                //Set the default config
                const defaultConf = {
                    descriptionTemplate: data.descriptionTemplate || "",
                    descriptionTemplateAnicam: data.descriptionTemplateAnicam || ""
                };
                setDefaultConfig(defaultConf);

                //Set the actual config as a copy of the default
                setConfig(Object.assign({}, defaultConf));
            })
            .catch((err) => {
                setIsSending(false);
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo cargar la configuracion actual."
                );
            });
    }, [globalContext.currentEnterpriseId]);

    /**
     * Send the changes to the server
     */
    const saveChanges = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if (!window.confirm("Desea guardar los cambios?")) return;
        setIsSending(true);
        globalContext.setLoaderPage(true);

        axios
            .put(
                `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/odoo`,
                {
                    config: {
                        descriptionTemplate: config.descriptionTemplate,
                        ...(config.descriptionTemplateAnicam.length > 0 && {descriptionTemplateAnicam: config.descriptionTemplateAnicam})
                    },
                },
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                //Set the actual config as the default data
                setDefaultConfig(Object.assign(defaultConfig, config));
                setIsSending(false);
                globalContext.showModalWithMsg(`Los datos fueron guardados`);
            })
            .catch((err) => {
                handleAxiosError(err, "Disculpe, no se pudieron guardar los cambios.");
                setIsSending(false);
            })
            .finally(() => {
                globalContext.setLoaderPage(false);
                setIsSending(false);
            });
    };

    return (
        <ConfigStyled.ContFormTwo>
            <ConfigStyled.ContSectionFomr d="block">
                <ConfigStyled.TagSectionForm>
                    Odoo a ML {<BiRadioCircleMarked />}
                </ConfigStyled.TagSectionForm>
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: "left" }}>
                        Etiquetas disponibles
                    </ConfigStyled.text>
                    <ConfigStyled.text size="12pt" fw="500" style={{ textAlign: "left" }}>
                        @descripcion @codigodebarra @titulo
                    </ConfigStyled.text>
                    <ConfigStyled.textArea
                        hg="300px"
                        readOnly={isSending}
                        placeholder="Plantilla de los productos"
                        name="descriptionTemplate"
                        value={config.descriptionTemplate || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>
                <ConfigStyled.ARow jc="space-between">
                    <ConfigStyled.btnSend
                        onClick={() => saveChanges()}
                        disabled={isSending}
                    >
                        Guardar
                    </ConfigStyled.btnSend>
                </ConfigStyled.ARow>
            </ConfigStyled.ContSectionFomr>

            <ConfigStyled.ContSectionFomr d="block">
                <ConfigStyled.TagSectionForm>
                    Anican a ML {<BiRadioCircleMarked />}
                </ConfigStyled.TagSectionForm>
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt" style={{ textAlign: "left" }}>
                        Etiquetas disponibles para Anican
                    </ConfigStyled.text>
                    <ConfigStyled.text size="12pt" fw="500" style={{ textAlign: "left" }}>
                    @tipoProducto @marca @descripcion @titulo @modelo
                    </ConfigStyled.text>
                    <ConfigStyled.textArea
                        hg="300px"
                        readOnly={isSending}
                        placeholder="Plantilla de los productos"
                        name="descriptionTemplateAnicam"
                        value={config.descriptionTemplateAnicam || ""}
                        onChange={updateConfigState}
                    />
                </ConfigStyled.ContInputAndLabel>
                <ConfigStyled.ARow jc="space-between">
                    <ConfigStyled.btnSend
                        onClick={() => saveChanges()}
                        disabled={isSending}
                    >
                        Guardar
                    </ConfigStyled.btnSend>
                </ConfigStyled.ARow>
            </ConfigStyled.ContSectionFomr>

        </ConfigStyled.ContFormTwo>
    );
}
