import React from "react";
import { Modal, Button } from "react-bootstrap";
export default function ModalMsm(props) {
  const { error, title, config, show, handleClose, txtButton } = props;
  return (
    <Modal show={show} onHide={handleClose} size={(config && config.size) || ""} style={{zIndex: 999999999999}}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{error}</Modal.Body>
      <Modal.Footer>
        {
          config && config.useConfirmButton && config.handleConfirm
          && config.confirmButtonText && 
          <Button variant="primary" onClick={config.handleConfirm}>
            {config.confirmButtonText}
          </Button>
        }
        <Button variant="secondary" onClick={handleClose}>
          {txtButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
