import React, { useState, useContext, useEffect } from 'react';
import CityCss from '../../../assets/css/components/admin/city/CityCss'
import {
    keyUserId,
    keyAccessToken,
} from "../../../assets/js/SessionStorageNames";
import axios from "axios";
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { GlobalContext } from "../../../contexts/GlobalContext";
import Pager from '../../general/Pager'
export default function ModalLinkCity(props) {
    const globalContext = useContext(GlobalContext);
    const { active, cityText, setActive, cityId } = props;
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [isLoad, setIsLoad] = useState(false);
    const [isLoadSend, setIsLoadSend] = useState(false);
    const [isSucces, setIsSucces] = useState(false);
    const [mlCity, setMlCity] =  useState('');
    const [lnCity, setLnCity] =  useState('');
    const [dfCity, setDfCity] =  useState('');

    const closeModal = (v) => {
        globalContext.setCityText(null);
        globalContext.setCityId(null)
        setActive(v);
        setIsSucces(false)
    }
    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const sendCitiesLink = (id) => {
        const entId = globalContext.currentEnterpriseId;
        setIsLoadSend(true)
        axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/odooCityUpdate/${entId}`, {
            values: [
                {
                    odooId: cityId,
                    ml: mlCity,
                    ln: lnCity,
                    df: dfCity
                }
            ]
        }, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then((response) => {
            console.log(response);
            setIsLoadSend(false);
            setIsSucces(true)
        }).catch((e) => { console.log(e); handleAxiosError(e, 'Presentamos un error al vincular las categorias') })
    }
     const sendCitiesInsertLink = (id) => {
        const entId = globalContext.currentEnterpriseId;
        setIsLoadSend(true)
        axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/odooCityInsert/${entId}`, {
            values: [
                {
                    odooId: cityId,
                    ml: mlCity,
                    ln: lnCity,
                    df: dfCity
                }
            ]
        }, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then((response) => {
            console.log(response);
            setIsLoadSend(false);
            setIsSucces(true)
        }).catch((e) => { console.log(e); handleAxiosError(e, 'Presentamos un error al vincular las categorias') })
    }
    return (
        <CityCss.contModal active={active}>
            <CityCss.modalCategorie active={active} w="1000px">
                <CityCss.headerModal>
                    <CityCss.textHeader size='12pt'>
                        Agrege los valores a vincular para la ciudad de odoo: {cityText}
                    </CityCss.textHeader>
                    <CityCss.btnCloseModal onClick={() => closeModal(false)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </CityCss.btnCloseModal>
                </CityCss.headerModal>
                <CityCss.bodyModal>
                    {isLoadSend ? (
                        <div className={`load-screen ${isLoadSend ? 'active' : ''}`}>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    ) :
                        isSucces ? (
                            <CityCss.textHeader size='15pt' color="#7367f0" fontWeigth="600">
                                Vinculacion exitosa!
                            </CityCss.textHeader>
                        ) :
                            (
                                <CityCss.constGrid p="relative" shadow="none" w="100%" ml="0" mt='10px'>
                                    <CityCss.heaterGrid>
                                        <CityCss.textHeader size='12pt'>
                                            Agregue los parametros para vincular
                                        </CityCss.textHeader>
                                    </CityCss.heaterGrid>
                                    <CityCss.bodyGrid h="auto">
                                        <CityCss.constDataGrid>
                                            <CityCss.contGridData>
                                                <CityCss.dataGrid>
                                                    <CityCss.contInputAndLabel>
                                                        <CityCss.text>
                                                            Ciudad de ML
                                                        </CityCss.text>
                                                        <CityCss.input placeholder="Escriba aqui.." onChange={(e) => setMlCity(e.target.value)} />
                                                    </CityCss.contInputAndLabel>
                                                </CityCss.dataGrid>
                                                <CityCss.dataGrid>
                                                    <CityCss.contInputAndLabel>
                                                        <CityCss.text>
                                                            Ciudad de DF
                                                        </CityCss.text>
                                                        <CityCss.input placeholder="Escriba aqui.." onChange={(e) => setDfCity(e.target.value)} />
                                                    </CityCss.contInputAndLabel>
                                                </CityCss.dataGrid>
                                                <CityCss.dataGrid>
                                                    <CityCss.contInputAndLabel>
                                                        <CityCss.text>
                                                            Ciudad de LN
                                                        </CityCss.text>
                                                        <CityCss.input placeholder="Escriba aqui.." onChange={(e) => setLnCity(e.target.value)} />
                                                    </CityCss.contInputAndLabel>
                                                </CityCss.dataGrid>
                                            </CityCss.contGridData>
                                        </CityCss.constDataGrid>
                                    </CityCss.bodyGrid>
                                </CityCss.constGrid>
                            )
                    }
                </CityCss.bodyModal>
                <CityCss.footerModal>
                    <CityCss.btnUpdate onClick={() => sendCitiesLink()}>
                        Actualizar
                    </CityCss.btnUpdate>
                    <CityCss.btnUpdate onClick={() => sendCitiesInsertLink()}>
                        Insertar
                    </CityCss.btnUpdate>
                </CityCss.footerModal>
            </CityCss.modalCategorie>
        </CityCss.contModal>
    )
}
