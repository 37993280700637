import React, { useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import OptionsProductsHistoryCss from '../../../../assets/css/components/admin/products/amazon/OptionsProductsHistoryCss'
import { GlobalContext } from '../../../../contexts/GlobalContext';

export default function OptionsProductsHistory(props) {
    const globalContext = useContext(GlobalContext);
    const {
      active, setActive, filterTo, catId,
      idDeleteByProduct,indexProducByDelete, deleteMethod, testFilters
    } = props;
    const OptionsProductsHistoryRef = useRef(null);
    const setActiveFunc = (v) => {
        setActive(v)
    }
    const deleteProduct = (idDeleteByProduct, indexProducByDelete) => {
      deleteMethod(idDeleteByProduct, indexProducByDelete)
    }
    function useOutsideAlerterMenu(refe) {
        useEffect(() => {
            if(active) {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutsideMenu(event) {
              if (refe.current && !refe.current.contains(event.target)) {
                setActiveFunc('')
              }
            }
            // Bind the event listener
            document.addEventListener("click", handleClickOutsideMenu);
            return () => {
              // Unbind the event listener on clean up
              document.removeEventListener("click", handleClickOutsideMenu);
            };
          }
          }, [refe, active]);
        }
        useOutsideAlerterMenu(OptionsProductsHistoryRef);

        const addDataExternalMarketplace = () => {
          console.log('okok');
          globalContext.setModalOrdenOtherMarketplace(true);
        }

    return (
        <OptionsProductsHistoryCss.cont active={active} ref={OptionsProductsHistoryRef}>
            <OptionsProductsHistoryCss.listOption>
              <Link to={`detail-kbt/${idDeleteByProduct}`} style={{width:'100%', textDecoration: 'none'}}>
              <OptionsProductsHistoryCss.listOptionItems>
                Detalles del producto
              </OptionsProductsHistoryCss.listOptionItems>
              </Link>
              <OptionsProductsHistoryCss.listOptionItems onClick={() => {filterTo(catId);setActiveFunc('')}}>
                Filtrar por esta categoria
              </OptionsProductsHistoryCss.listOptionItems>
              <OptionsProductsHistoryCss.listOptionItems onClick={() => {addDataExternalMarketplace();setActiveFunc('')}}>
                Orden de otro MarketPlace
              </OptionsProductsHistoryCss.listOptionItems>
              <OptionsProductsHistoryCss.listOptionItems onClick={() => testFilters()}>
                Probar los filtros
              </OptionsProductsHistoryCss.listOptionItems>
              <OptionsProductsHistoryCss.listOptionItems onClick={() => {deleteProduct(idDeleteByProduct, indexProducByDelete);}}>
                Eliminar
              </OptionsProductsHistoryCss.listOptionItems>
              
            </OptionsProductsHistoryCss.listOption>
        </OptionsProductsHistoryCss.cont>
    )
}
