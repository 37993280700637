import axios from 'axios'
import {keyAccessToken, keyUserId} from '../../../../../assets/js/SessionStorageNames'
const getAllCampaign = async  (entId) => {
    const resp = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/v1/woo-campaign/${entId}`,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
        params: {
            pageNumber: 1,
            pageItemCount: 100
        }
    })
    return resp
}

export default getAllCampaign;