import styled from 'styled-components';

const container = styled.div`
width: 300px;
height: auto;
margin-top: ${props => props.mt || '0'};
display:flex;
justify-content:center;
align-items:center;
transition: all ease-out 0.3s;
@media (max-width: 900px) {
    height: ${props=> props.showDiv? 'auto': '0px'};
    overflow: ${props=> props.showDiv? 'auto': 'hidden'};
    justify-content:center;
    align-items:center;
    width: ${props=> props.showDiv? '100%': '0%'};
    overflow-y: auto;
}
`
const containerFilter = styled.div`
width: 300px;
max-width:300px;
height: auto;
max-height:900px;
border-radius: 4px;
background: #fff;
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
margin-top: ${props => props.mt || '0'};
display:flex;
flex-direction:column;
justify-content:flex-start;
align-items:center;
@media (max-width: 900px) {
    width:100%;
    max-width:100%;
}
`

const header = styled.div`
width:100%;
height: 60px;
background:#FFFF;
margin-top:0;
padding:10px;
display:flex;
justify-content: left;
align-items:center;
`;
const body = styled.div`
width:100%;
height: 600px;
max-height:700px;
overflow-y: auto;
padding:10px;

.load-screen {
        background-color: #ffffff;
        height:480px;
        width:100%;
        display:flex;
        justify-content:center;
        align-items:center;
        z-index:100000;
        .spinner-border {
            border: .25em solid #7367f0;
            border-right-color: transparent;
        }
    }
`;
const footer = styled.div`
width:100%;
height: 60px;
background: #FFF;
padding:10px;
display:flex;
justify-content: center;
align-items:center;
margin-bottom:0;
`;
const inputOne = styled.input`
width: 100%;
height: 40px;
border-radius: 4px;
background: #fff;
border: 0.5px solid rgba(112,112,112, 0.30);
outline: none !important;
padding:6px 16px;
font-size:12pt;
color: rgb(112, 112, 112);
font-weight: 600;
padding-right: 40px;
::placeholder {
    font-family: "Berlin Rounded";
    font-weight: 300;
    font-size: 12px;
    color: rgba(112, 112, 112, 0.61);
    }
margin-top : ${props => props.mt || 0};
`
const buttonBack = styled.button`
border-radius: 5px;
background: #ffffff;;
outline: none!important;
border:1px solid #7367f0;
color: #7367f0;
font-size: 12pt;
font-weight: 400;
padding: 6px 18px;
transition: all ease 0.2s;
margin-right:10px;
&:hover {
    background-color:#7367f0;
    color:#ffffff;
}
`;
const listcategories = styled.ul`
list-style: none;
padding-left: 0;
width:100%;
`
const itemListCategorie = styled.li`
width:100%;
height: 50px;
border-bottom: 0.5px solid rgba(112,112,112, 0.30);
padding: 10px 0;
display: flex;
justify-content: flex-start;
align-items: center;
font-size: 11pt;
cursor: pointer;
`

const shwFilterCategorie = styled.button`
border: 0.5px solid;
margin-top: 10px;
margin-bottom:10px;
border-color: ${props=> props.bc || '#7367F0'};
background-color: ${props=> props.bgc || '#7367F0'};
padding: 8px 16px;
width: 100%;
font-size: 12pt;
margin-left: ${props=> props.ml || '0'};
color: ${props=> props.cl || '#FFFFFF'};
border-radius:5px;
font-weight:600;
outline:none !important;
display:none;
@media (max-width: 900px) {
    display:block;
}
`;
const FilterCategoriesAmazonCss = {
    container,
    containerFilter,
    header,
    body,
    footer,
    inputOne,
    buttonBack,
    listcategories,
    itemListCategorie,
    shwFilterCategorie
}
export default FilterCategoriesAmazonCss;