import React, { useContext } from "react";
import Spinner from "react-bootstrap/Spinner";
import { GlobalContext } from "../../contexts/GlobalContext";

export default function LoaderSpinner() {
  const globalContext = useContext(GlobalContext);

  return (
    <div className="d-flex justify-content-center align-items-center" style={{flexDirection: 'column'}}>
      <div className="contImgLogo">
        <img src="/e-logo.png" alt="e-kommers.com" />
        <p>e-kommers.com</p>
      </div>
      <Spinner animation="grow" />
      <br/><span style={{color:"#7367F0", fontSize: '14pt'}}>Estamos procesando su solicitud</span>
      <br/><span style={{color:"#7367F0", fontWeight:"bold"}}>{globalContext.loaderPageText}</span>
    </div>
  );
}
