import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';

const powerOff = (entId, idProd, axiosCancelTokenSource) => {
    const resp = axios.put(`${process.env.REACT_APP_BACKEND_URL_PEYA}/v1/peya-sdk/power-off`, {
        enterprise: entId,
        idProduct: idProd
    }, {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return resp
}
export default powerOff;