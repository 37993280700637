import axios from 'axios';
import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import ConfigStyled from '../../../../assets/css/components/admin/config/ConfigStyled'
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
import { GlobalContext } from '../../../../contexts/GlobalContext';

export default function Eight() {
    const globalContext = useContext(GlobalContext);
    const [isSending, setIsSending] = useState(false);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [config, setConfig] = useState({});
    const [defaultConfig, setDefaultConfig] = useState({});
    const [stores, setStores] = useState(null)

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (msg) globalContext.showModalWithMsg(msg);
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-brand/${entId}`, {
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            })
            .then((resp) => {
                const brands = resp.data.data.brands;
                setStores(brands);
            })
            .catch((err) => handleAxiosError(err));
    }, [globalContext.currentEnterpriseId]);

    /**
     * Change the config state
     * @param {Event} e The change event
     */
    const updateConfigState = (e) => {
        const name = e.target.name;
        const newConfig = Object.assign({}, config);

        switch (e.target.type) {
            case "checkbox":
                newConfig[name] = e.target.checked;
                break;

            default:
                newConfig[name] = e.target.value;
                break;
        }

        setConfig(newConfig);
    };

    /**
     * Send the changes to the server
     */
    const saveChanges = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if (!window.confirm("Desea guardar los cambios?")) return;

        setIsSending(true)

        axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
            config: {
                zipCode: config.zipCode,
                mlDiffPriceVariationsManage: config.mlDiffPriceVariationsManage,
                autoPublishToMl: {
                    publish: config.publish,
                    status: config.status,
                    condition: 'new',
                    listingType: config.listingType,
                    officialStoreId: config.officialStoreId,
                }
            }
        }, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then(resp => {
            //Set the actual config as the default data
            console.log(resp.data);
            setDefaultConfig(Object.assign(defaultConfig, config));
            globalContext.showModalWithMsg(`Los datos fueron guardados`);
        }).catch((err) =>
            handleAxiosError(
                err,
                "Disculpe, no se pudieron guardar los cambios."
            )
        ).finally(() => {
            setIsSending(false);
        });
    }



    /**
     * Load the plugin config
     */
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        setIsSending(true)
        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then(resp => {
            setIsSending(false)
            const data = resp.data.data.configData;
            console.log(data);
            //Set the default config
            const defaultConf = {
                zipCode: data.zipCode || "",
                mlDiffPriceVariationsManage: data.mlDiffPriceVariationsManage || "",
                publish: data.autoPublishToMl && data.autoPublishToMl.publish ? data.autoPublishToMl.publish : false,
                status: data.autoPublishToMl && data.autoPublishToMl.status ? data.autoPublishToMl.status : "",
                condition: data.autoPublishToMl && data.autoPublishToMl.condition ? data.autoPublishToMl.condition : "",
                listingType: data.autoPublishToMl && data.autoPublishToMl.listingType ? data.autoPublishToMl.listingType : "",
                officialStoreId: data.autoPublishToMl && data.autoPublishToMl.officialStoreId ? data.autoPublishToMl.officialStoreId : ""
            };
            setDefaultConfig(defaultConf);

            //Set the actual config as a copy of the default
            setConfig(Object.assign({}, defaultConf));
        }).catch((err) => {
            setIsSending(false)
            handleAxiosError(
                err,
                "Disculpe, no se pudo cargar la configuracion actual."
            )
        }
        );
    }, [globalContext.currentEnterpriseId]);


    /**
     * Cancel axios requests before unmount
     */
    useEffect(() => {
        return () => {
            axiosCancelTokenSource.cancel("Canceled by unmount");
        };
        //eslint-disable-next-line
    }, []);

    return (
        <ConfigStyled.ContForm isSingle={true}>

            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size="12pt">
                    Al publicar en Mercadolibre, ¿qué hacer si las variantes tienen precios distintos?
                </ConfigStyled.text>
                <ConfigStyled.select
                    name="mlDiffPriceVariationsManage"
                    disabled={isSending}
                    value={config.mlDiffPriceVariationsManage || ""}
                    onChange={updateConfigState}
                >
                    <option value="">Seleccione</option>
                    <option
                        value="use_lowest_price"
                        title="Utilizar el menor precio para todas las variantes"
                    >
                        Utilizar el menor precio
                    </option>
                    <option
                        value="use_highest_price"
                        title="Utilizar el precio mas alto para todas las variantes"
                    >
                        Utilizar el mayor precio
                    </option>
                    <option
                        value="publish_separately"
                        title="Publicar cada variante como un producto individual"
                    >
                        Publicar por separado
                    </option>

                </ConfigStyled.select>
            </ConfigStyled.ContInputAndLabel>

            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size="12pt">
                    Activar publicacion automatica
                </ConfigStyled.text>
                <ConfigStyled.select
                    value={config.publish}
                    disabled={isSending}
                    name="publish"
                    onChange={updateConfigState}
                >
                    <option value="">Seleccione</option>
                    <option value={false}>No</option>
                    <option value={true}>Si</option>
                    
                </ConfigStyled.select>
            </ConfigStyled.ContInputAndLabel>

            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size="12pt">
                    Estatus para la publicaciones
                </ConfigStyled.text>
                <ConfigStyled.select
                    value={config.status}
                    disabled={isSending}
                    name="status"
                    onChange={updateConfigState}
                >
                    <option value="">Seleccione</option>
                    <option value='active'>Activa</option>
                    <option value='paused'>Pausada</option>
                    
                </ConfigStyled.select>
            </ConfigStyled.ContInputAndLabel>

            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size="12pt">
                    Tipo de publicacion
                </ConfigStyled.text>
                <ConfigStyled.select
                    value={config.listingType}
                    disabled={isSending}
                    name="listingType"
                    onChange={updateConfigState}
                >
                    <option value="">Seleccionar</option>
                    <option value='gold_pro'>Premiun CO</option>
                    <option value='gold_premium'>Oro Premiun CO</option>
                    <option value='gold'>Oro CO</option>
                    <option value='gold_special'>Premiun VE</option>
                    
                </ConfigStyled.select>
            </ConfigStyled.ContInputAndLabel>

            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size="12pt">
                    Tienda Oficial
                </ConfigStyled.text>
                <ConfigStyled.select
                    value={config.officialStoreId}
                    disabled={isSending}
                    name="officialStoreId"
                    onChange={updateConfigState}
                >
                    <option value="">Seleccionar</option>
                        {
                            stores &&
                            stores.map((s) => {
                                return (
                                    <option key={s.id} value={s.id}>{s.name}</option>
                                )
                            })
                        }
                </ConfigStyled.select>
            </ConfigStyled.ContInputAndLabel>

            <ConfigStyled.ARow>
                <ConfigStyled.btnSend disabled={isSending} onClick={() => saveChanges()}>
                    Guardar
                </ConfigStyled.btnSend>
            </ConfigStyled.ARow>
        </ConfigStyled.ContForm>
    )
}
