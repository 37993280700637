import styled from 'styled-components'

const ContTable = styled.div`
padding: 1rem;
min-width: 300px;
width: 700px;
min-height: 100px;
border-radius: .429rem;
background: #FFFFFF;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
`;

const HeaderContTable = styled.div`
width: 100%;
text-align: left;
border-radius: .428rem  .428rem 0 0;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
margin-bottom: 1rem;
`;
const FooterContTable = styled.div`
width: 100%;
text-align: left;
border-radius: 0 0 .428rem  .428rem;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
margin-top: 1rem;
`;

const TableResult = styled.table`
width: 100%;
border-collapse: collapse;
border: none;
font-size: .857rem;
letter-spacing: .5px;
color: #6e6b7b;
display: block;
`;
const TableResultThead = styled.thead`
width: 100%;
background: #F3F2F7;
border-radius: .429rem;
border-bottom: 2px solid #ebe9f1;
`
const TableResultTh = styled.th.attrs(props => ({
    // we can define static props
    width: props.width || '100px',
    minWidth: props.minWidth || '100px',
    textAlign: props.textAlign || 'start',
    padding: props.padding || '0.50rem',
}))`
text-align: ${props => props.textAlign};
padding: ${props => props.padding};
width: ${props => props.width};
min-width: ${props => props.minWidth};
`

const TableResultTbody = styled.tbody`
width: 100%;
display: table-row-group;
vertical-align: middle;
border-color: inherit;
display: inline-block;
overflow-y: auto;
max-height: 500px;
`;


const TableResultTr = styled.tr`
width: 100%;
border-bottom: 2px solid #ebe9f1;
`
const TableResultTd = styled.td.attrs(props => ({
    // we can define static props
    width: props.width || '100px',
    minWidth: props.minWidth || '100px',
    textAlign: props.textAlign || 'justify',
    backGroundColor: props.backGroundColor || '#FFFFFF',
    padding: props.padding || '0.50rem',
    verticalAlign: props.verticalAlign || 'text-top',
}))`
text-align: ${props => props.textAlign};
padding: ${props => props.padding};
width: ${props => props.width};
max-width: ${props => props.width};
min-width: ${props => props.minWidth};
word-break: break-all;
vertical-align: ${props => props.verticalAlign};
background: ${props => props.backGroundColor};
`;

const BtnAndIcon = styled.button.attrs(props => ({
    mr: props.mr || '0',
    ml: props.ml || '0'
}))`
height: 38px;
border-radius: 50px;
display: flex;
justify-content: flex-start;
align-items: center;
outline: none !important;
border: none;
background: #7367f0;
color: #FFFFFF;
min-width: 100px;
width: auto;
max-width: 200px;
margin-right: ${props => props.mr};
margin-left: ${props => props.ml};
    &:disabled {
        background: #7367f08f;
        cursor: no-drop;
    }
`;

const ContIconBtn = styled.span`
width: 30px;
height: 30px;
border-radius: 50px;
display: flex;
align-items: center;
justify-content: center;
border: 1px solid #FFFFFF; margin-right: 5px
;
`
const ContIconBtnLoad = styled.span`
width: 30px;
height: 30px;
border-radius: 50px;
display: flex;
align-items: center;
justify-content: center;
border: 1px solid #FFFFFF; margin-right: 5px;
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
-webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
`

const BtnIcon = styled.button.attrs(props => ({
    bgColor: props.bgColor || '#FFFFFF',
    color: props.color || '#707070',
    bRadius: props.bRadius || '50px',
    width: props.width || '100px',
    height: props.height || '38px',
    mRight: props.mRight || '0',
    mLeft: props.mLeft || '0',
    mTop: props.mTop || '0',
    mBottom: props.mBottom || '0',
}))`
width: ${props=> props.width};
height: ${props=> props.height};
margin-right: ${props=> props.mRight};
margin-left: ${props=> props.mLeft};
margin-top: ${props=> props.mTop};
margin-bottom: ${props=> props.mBottom};
height: ${props=> props.height};
border: none;
outline: none;
background: ${props=> props.bgColor};
color: ${props=> props.color};
display: flex;
justify-content: center;
align-items: center;
outline: none !important;
border-radius: ${props=> props.bRadius};
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
`

const InputSearch = styled.input`
width: 100%;
height: 40px;
font-size: .875rem;
font-weight: 400;
line-height: 1.45;
color: #6e6b7b;
padding-left: 1.25rem;
background-color: #fff;
border-radius: 0.357rem;
line-height: 1.45;
color: #6e6b7b;
outline: none !important;
border: none !important;
box-shadow: 0 2px 8px 0 rgb(34 41 47 / 14%);
&:focus {
    outline: none !important;
    box-shadow: 0 2px 8px 0 rgb(115 103 240 / 43%);
}
::placeholder {
    color: #c0c0c1;
    font-size: 1rem;
}
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
`;

const ContInputSearch = styled.form`
    border-radius: 0.3rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    background: #FFFFFF;
    min-height: 40px;
    position: relative;
`;

const ContIconInputSearch = styled.div`
min-width: 40px;
width: 100%;
min-height: 40px;
display: flex;
justify-content: center;
align-items: center;
`

const ContBreakCrumbs = styled.div`
width: 100%;
padding: 5px 10px;
display: flex;
align-items: center;
justify-content: flex-start;
`;

const BtnInputSearch = styled.div`
border: none;
border-radius: 50px;
height: 40px;
width: 40px;
background: transparent;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
&:hover {
    background: rgba(196,196,196,0.33);
}
position: absolute;
top: 0;
bottom: 0;
left: auto;
right: 5px;
`;

const BtnBackCategory = styled.div`
border: none;
border-radius: 50px;
height: 25px;
width: 25px;
background: transparent;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
&:hover {
    background: rgba(196,196,196,0.33);
}
`;

const contLoader = styled.div`
width: 100%;
height: 100%;
margin-top: auto;
margin-bottom: auto;
margin-left: 5px;
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
.spinner-border {
    border: .25em solid #7367f0;
    border-right-color: transparent;
}
`;

const TableSearch = {
    ContTable,
    HeaderContTable,
    FooterContTable,
    TableResult,
    TableResultThead,
    TableResultTh,
    TableResultTbody,
    TableResultTr,
    TableResultTd,
    BtnAndIcon,
    ContIconBtn,
    ContIconBtnLoad,
    BtnIcon,
    ContInputSearch,
    InputSearch,
    ContIconInputSearch,
    ContBreakCrumbs,
    BtnInputSearch,
    contLoader,
    BtnBackCategory
}

export default TableSearch;
