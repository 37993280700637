/* eslint-disable no-loop-func */
import React, { useEffect, useState, useContext } from 'react';
import { faAngleLeft, faCheck, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PublishProduct from "../../../../assets/css/components/admin/products/amazon/ModalPublishProduct";
import SelectCategory from './steps-multi-post/SelectCategory';
import ParametrosPublicacion from './steps-multi-post/ParametrosPublicacion';
import Succes from './steps-multi-post/Succes';
import { GlobalContext } from "../../../../contexts/GlobalContext";
import { Spinner } from 'react-bootstrap';
import axios from "axios";
import {
    keyUserId,
    keyAccessToken,
} from "../../../../assets/js/SessionStorageNames";
import Sending from './steps-multi-post/Sending';
function MultiplePost() {
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const globalContext = useContext(GlobalContext);
    const numberFormat = new Intl.NumberFormat('de-DE');
    const [steps, setSteps] = useState(1);
    const [succesPublish, setSucces] = useState(false);
    const [isLoad, setIsLoad] = useState(true);
    const [calculatePrice, setCalculatePrice] = useState(true);
    const [anotherIsLoad, setAnotherIsLoad] = useState(true);
    const [succesPublishMsg, setSuccesMsg] = useState('');
    const [isError, setIsError] = useState(false);
    const [categoryMl, setCategoryMl] = useState(null);
    const [categoryMlText, setCategoryMlText] = useState('')
    const [totalSend, setTotalSend] = useState(0);
    const [errors, setErrors] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const [imagesProduct, setImagesProduct] = useState([])
    const [sku, setSku] = useState('');
    const [stock, setStock] = useState('');
    const [price, setPrice] = useState('');
    const [weight, setWeight] = useState('');
    const [totalUsd, setTotalUsd] = useState('');
    const [total, setTotal] = useState('');
    const [earningUsd, setEarningUsd] = useState('');
    const [pesoVolu, setPesoVolu] = useState('No disponible');
    const [tiempoEnvio, setTiempoEnvio] = useState('No disponible');
    const [oldPrice, setOldPrice] = useState('')
    const [link, setLink] = useState('');
    const [dealPrice, setDealPrice] = useState('');
    const [amazonCategorie, setAmazonCategorie] = useState(null);
    const [template, setTemplate] = useState([]);
    const [tienda, setTienda] = useState(null);
    const [tiendaText, setTiendaText] = useState('');
    const [stores, setStores] = useState(null);
    const [listingType, setListingType] = useState('');
    const [listingTypeText, setListingTypeText] = useState('');
    const [positionItemActive, setPositionItemActive] = useState(0);
    const [dataPrice, setDataPrice] = useState(null);
    const [howToPost, setHowToPost] = useState('paused');
    const [imgActive, setImgActive] = useState('')
    const [categoryMlPrediction, setCategoryMlPrediction] = useState(null)

    const titlePublication = (steps) => {
        switch (steps) {
            case 1:
                return 'Seleccione su categoria para ML'
            case 2:
                return 'Parametros para la publicacion'
            case 3:
                return 'Verifique sus datos'
            default:
                return 'Seleccione su categoria para ML'
        }
    }
    const nextStep = () => {
        setSteps(steps + 1);
    }
    const beforeStep = () => {
        setSteps(steps - 1);
    }
    const closeModalPublish = () => {
        setSteps(1);
        globalContext.setModalMultiplePostData(null);
        setCategoryMlPrediction(null)
        setSku('');
        setImagesProduct([])
        setStock('');
        setPrice('');
        setWeight('');
        setPesoVolu('No disponible')
        setTiempoEnvio('No disponible');
        setOldPrice('');
        setLink('');
        setDealPrice('');
        setAmazonCategorie(null);
        setTemplate([]);
        setTienda(null);
        setTiendaText('');
        setListingTypeText('');
        setPositionItemActive(0);
        setImgActive(0);
        globalContext.setModalMultiplePost(false)
    }

    /**
     * * este metodo buysca la categoria de ml que predice por la que nos da amazon
     * @param {*} categorieText el nombre de la categoria de amazon 
     */
    const findMlCategorie = (categorieText, entId) => {
        if (categorieText) {
            if (categorieText !== 'No posee') {
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/v1/ml-category/${entId}/predict-category`, {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                    params: {
                        title: amazonCategorie.name ? amazonCategorie.name.es ? amazonCategorie.name.es : amazonCategorie.name.en : '',
                    }
                }
                ).then((resp) => {
                    setCategoryMlPrediction(resp.data.data.cate)
                    setIsLoad(false);
                }).catch((err) => {
                    // console.log(err);
                    setCategoryMlPrediction([{
                        id: '',
                        name: 'Categoria no obtenida'
                    }]);
                    setIsLoad(false);
                })
            } else {
                setCategoryMlPrediction([{
                    id: '',
                    name: 'Categoria no obtenida'
                }]);
                setIsLoad(false);
            }
        }
    }
    /**
     * Load the brands list
     */
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if (!tienda) {
            axios
                .get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-brand/${entId}`, {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                })
                .then((resp) => {
                    const brands = resp.data.data.brands;
                    setStores(brands);
                })
                .catch((err) => {
                    console.error(err);
                    setStores(null)
                });
        }
    }, [globalContext.currentEnterpriseId]);

    /**
     * * este metodo setea el state para la categoria de amazon con el ultimo arreglo del objeto 
     * @param {*} obj globalContext.modalMultiplePostData para buscar la ultima categoria de amazon y guardarla en el state
     */
    const setValueCategorieAmazon = (obj) => {
        if (obj[0].variants.length > 0) {
            if (obj[0].variants[0].categoryTree.length > 0) {
                setAmazonCategorie(obj[0].variants[0].categoryTree[obj[0].variants[0].categoryTree.length - 1])
            } else {
                setAmazonCategorie('No posee')
            }
        } else {
            if (obj[0].categoryTree.length > 0) {
                setAmazonCategorie(obj[0].categoryTree[obj[0].categoryTree.length - 1])
            } else {
                setAmazonCategorie('No posee')
            }
        }
    }

    const getDescriptionProduct = (objProduct) => {
        if (template[positionItemActive].descripcion.length > 0) return
        setAnotherIsLoad(true)
        const entId = globalContext.currentEnterpriseId;
        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then(resp => {
            let dataReplace = resp.data.data.configData.descriptionTemplate;
            // * este objeto es el que remplazara la etiquetas
            // * hacemos un foreach para ecorrer el array de objetos y agregarles su descripcion
            
            const mapObjToReplace = {
                titulo: (objProduct.title) && objProduct.title.es || "" + '\n',
                titulo_en: (objProduct.title) && objProduct.title.en || "" + '\n',
                d_tecnicos: (objProduct.details) && objProduct.details.productDetails ?
                    objProduct.details.productDetails.length > 0 ?
                        objProduct.details.productDetails.map(v =>
                            '- ' + v.name.es + " " + v.value.es + '\n'
                        ) : '' : '',
                informacion_adicional: (objProduct.details) && objProduct.details.additionalInfo ?
                    objProduct.details.additionalInfo.length > 0 ?
                        objProduct.details.additionalInfo.technicalDetails ?
                            objProduct.details.additionalInfo.technicalDetails.length > 0 ?
                                objProduct.details.additionalInfo.technicalDetails.map(v =>
                                    '- ' + v.name.es + " " + v.value.es + '\n'
                                ) : '' : '' : '' : '',
                caracteristicas: (objProduct.features) && objProduct.features.length > 0 ?
                    objProduct.features.map(v =>
                        '- ' + v.es + '\n')
                    : '',
                especificaciones_producto: (objProduct.details) && objProduct.details.productSpecifications ?
                    objProduct.details.productSpecifications.length > 0 ?
                        objProduct.details.productSpecifications.map(v =>
                            '- ' + v.name.es + " " + v.value.es + '\n'
                        ) : "" : '',
                sku: objProduct.asin + '\n',
                descripcion: (typeof objProduct.desc !== 'undefined') ? objProduct.desc.es ? objProduct.desc.es : objProduct.desc.en ? objProduct.desc.en : "" : "",
                stock: objProduct.stock ? objProduct.stock : '',
                dias_entrega: objProduct.deliveryDays ? objProduct.deliveryDays : '',
                variations: objProduct.objProduct ? objProduct.objProduct : '',
            }
            /**
             * ? remplazamos el lugar de las etiqutas por sus valores
             */
            dataReplace = dataReplace.replace('@titulo', mapObjToReplace.titulo);
            dataReplace = dataReplace.replace('@titulo_en_ingles', mapObjToReplace.titulo_en);
            dataReplace = dataReplace.replace('@detalles_tecnicos', mapObjToReplace.d_tecnicos);
            dataReplace = dataReplace.replace('@caracteristicas', mapObjToReplace.caracteristicas);
            dataReplace = dataReplace.replace('@sku', mapObjToReplace.sku);
            dataReplace = dataReplace.replace('@descripcion', mapObjToReplace.descripcion);
            dataReplace = dataReplace.replace('@informacion_adicional', mapObjToReplace.informacion_adicional);
            dataReplace = dataReplace.replace('@especificaciones_producto', mapObjToReplace.especificaciones_producto);
            dataReplace = dataReplace.replace('@stock', mapObjToReplace.stock);
            dataReplace = dataReplace.replace('@tiempo_envio', mapObjToReplace.dias_entrega);
            dataReplace = dataReplace.replace('@variations', mapObjToReplace.variations);
            const copyArray = [...template];
            copyArray[positionItemActive].descripcion = dataReplace;
            setTemplate(copyArray);
            setAnotherIsLoad(false);
        }).catch((err) => {
            console.log(err);
            setAnotherIsLoad(false);
        }
        );
    }

    const setParametrosPublicacion = (object) => {
        let arrayDes = [];
        for (const key in object) {
            if (object[key].variants.length > 0) {
                arrayDes.push(
                    {
                        id: object[key]._id,
                        titulo: '',
                        peso: object[key].variants[0].weight && object[key].weight,
                        precio: object[key].variants[0].price,
                        descripcion: '',
                        obj: object[key].variants[0],
                        images: object[key].variants[0].images,
                        status: '',
                        asin: object[key].variants[0].asin,
                        stock: object[key].variants[0].stock,
                        price: object[key].variants[0].price,
                        weight: object[key].variants[0].weight,
                        oldPrice: object[key].variants[0].oldPrice,
                        url: object[key].variants[0].url,
                        dealPrice: object[key].variants[0].dealPrice,
                        deliveryDays: object[key].variants[0].deliveryDays ? object[key].variants[0].deliveryDays : '',
                        dimensions: object[key].variants[0].dimensions ? object[key].variants[0].dimensions : ''
                    }
                )
            } else {
                arrayDes.push(
                    {
                        id: object[key]._id,
                        titulo: '',
                        peso: object[key].weight && object[key].weight,
                        precio: object[key].price,
                        descripcion: '',
                        obj: object[key],
                        images: object[key].images,
                        status: '',
                        asin: object[key].asin,
                        stock: object[key].stock,
                        price: object[key].price,
                        weight: object[key].weight,
                        oldPrice: object[key].oldPrice,
                        url: object[key].url,
                        dealPrice: object[key].dealPrice,
                        deliveryDays: object[key].deliveryDays ? object[key].deliveryDays : '',
                        dimensions: object[key].dimensions ? object[key].dimensions : ''
                    }
                )
            }
            setTemplate(arrayDes);
        }
    }
    /**
     * 
     * @param {*} steps 
     * * este metodo imprime en pantalla el formulario dependiendo del step
     */
    const stepRender = (steps) => {
        switch (steps) {
            case 1:
                return <SelectCategory
                    setCategoryMl={setCategoryMl}
                    setCategoryMlText={setCategoryMlText}
                    nextStep={nextStep}
                />
            case 2:
                return <ParametrosPublicacion
                    listingType={listingType}
                    setListingTypeText={setListingTypeText}
                    setListingType={setListingType}
                    template={template}
                    setTemplate={setTemplate}
                    setTienda={setTienda}
                    setTiendaText={setTiendaText}
                    tienda={tienda}
                    positionItemActive={positionItemActive}
                    anotherIsLoad={anotherIsLoad}
                    stores={stores}
                    howToPost={howToPost}
                    setHowToPost={setHowToPost}
                />
            case 3:
                return <Succes
                    template={template}
                    positionItemActive={positionItemActive}
                    tiendaText={tiendaText}
                    listingTypeText={listingTypeText}
                    anotherIsLoad={anotherIsLoad}
                />
            case 4:
                return <Sending
                    isSending={isSending}
                    totalSend={totalSend}
                    errors={errors}
                />
            default:
                return <SelectCategory
                    setCategoryMl={setCategoryMl}
                    setCategoryMlText={setCategoryMlText}
                    nextStep={nextStep}
                />
        }
    }

    /**
     * este metodo es para setear los valores por defecto al dar click en una imagen
     * @param {*} obj globalContex
     * @param {*} key id del item de globalContex
     */
    const setDataProductShow = (obj, key) => {
        setImgActive(key);
        setPositionItemActive(key);
        setSku(obj.asin)
        setImagesProduct(obj.images)
        setStock(obj.stock ? obj.stock : 'No Disponble')
        setPrice(obj.price)
        setWeight(obj.weight)
        setPesoVolu(obj.dimensions ? obj.dimensions : 'No disponible');
        setTiempoEnvio(obj.deliveryDays || 'No disponible');
        setOldPrice(obj.oldPrice)
        setLink(obj.url)
        setDealPrice(obj.dealPrice)
    }

    const getValues = async (entId) => {
        setCalculatePrice(true)
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/amazonScrap`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }

    const getPublishingPrice = async (prodId, obj, entId) => {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/calculate-publish-price`, {
            prodId: prodId,
            config: {
                tariff: obj.tariff,
                earning: obj.earning,
                courierPlus: parseInt(obj.courierPlus),
                courierPlusIsPercent: parseInt(obj.courierPlusIsPercent),
                poundPrice: parseInt(obj.poundPrice),
                additionalPoundPrice: parseInt(obj.additionalPoundPrice),
                safe: obj.safe,
                safeIsPercent: obj.safeIsPercent,
                ccTax: obj.ccTax,
                ccTaxIsPercent: obj.ccTaxIsPercent,
                defaultTariff: parseInt(obj.defaultTariff),
                defaultTariffIsPercent: obj.defaultTariffIsPercent,
                iva: parseInt(obj.iva),
                ivaIsPercent: true,
                defaultComission: obj.defaultComission,
                defaultComissionIsPercent: obj.defaultComissionIsPercent,
                countryCode: entId === "60944575461add5b1957ef8b" ? 'VE' : 'CO'
            }
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res
    }

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if (globalContext.modalMultiplePost) {
            if (globalContext.modalMultiplePostData) {
                if (template.length === 0) {
                    setParametrosPublicacion(globalContext.modalMultiplePostData);
                }
                /**
                 * * llamamos a la funcion setDefaultValuesShow para setear los valores que se mostraran por defecto
                 * * seteamos la categoria de amazon 
                 * * la cual es la misma para todos los productos
                 * * por ende solo recorremos la primera posiscion del arragle
                 */
                setValueCategorieAmazon(globalContext.modalMultiplePostData);
                /**
                 * * buscamos la categoria de que predice MercadoLibre
                 */
                if (!categoryMlPrediction) {
                    findMlCategorie(amazonCategorie, entId)
                }

                if (template.length > 0 && template[positionItemActive].descripcion.length === 0) {
                    getDescriptionProduct(template[positionItemActive].obj);
                }
            };

            if (template.length > 0) {
                getValues(entId).then(async (res) => {
                    const data = res.data.data.configData.publishPriceCalculation;
                    await getPublishingPrice(template[positionItemActive].id, data, entId).then((res) => {
                        const data = res.data.data
                        setDataPrice(data);
                        setCalculatePrice(false)
                    }).catch((e) => { console.log(e); setCalculatePrice(false) })
                }).catch((e) => {
                    // console.log(e);
                    setCalculatePrice(false)
                })
            }
        } else {
            closeModalPublish();
        }
    }, [globalContext.modalMultiplePostData, globalContext.modalMultiplePost, amazonCategorie, positionItemActive])

    useEffect(() => {
        if (dataPrice) {
            if (dataPrice.variants) {
                const res = dataPrice.variants.find(v => v.asin === sku);
                if (res !== undefined) {
                    setTotalUsd(res.totalUsd);
                    setTotal(res.total);
                    setEarningUsd(res.earningUsd);
                }
            } else {
                setTotalUsd(dataPrice.totalUsd);
                setTotal(dataPrice.total);
                setEarningUsd(dataPrice.earningUsd);
            }
        }
    }, [dataPrice, sku, calculatePrice])

    /**
     * * este metodo nos sirve para eliminar un item cuando su publicacion sea exitosa!
     * @param {*} idItem 
     */
    const changeStatus = (position, newStatus) => {
        const copyArray = [...template];
        copyArray[position].status = newStatus;
        setTemplate(copyArray);
    }

    const sendOneProduct = async (product, entId) => {
        const whatSend = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/publish-to-ml`, {
            productId: product.id,
            ...((product.titulo.length > 0) && { title: product.titulo }),
            categoryMl: categoryMl ? categoryMl : categoryMlPrediction._id,
            condition: 'new',
            images: product.images,
            ...(product.descripcion.length > 0 && { body: product.descripcion }),
            listingType: listingType,
            ...(tienda && { officialStoreId: tienda }),
            pausePublication: howToPost === 'active' ? false : true

        }, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        );
        return whatSend;
    }
    const publicProducts = async (objProducts) => {
        // console.table(objProducts);return;
        const entId = globalContext.currentEnterpriseId;

        setIsSending(true);
        nextStep();

        let errores = [];
        let enviados = 0;

        for (let index = 0; index < objProducts.length; index++) {
            if (objProducts[index].status.length === 0 || objProducts[index].status === 'bad') {
                //* send data product for publish in ml
                await sendOneProduct(objProducts[index], entId)
                    .then((res) => {
                        //* catch the data error if is not null or undefined
                        const respuesta = res.data;
                        if (respuesta.data) {
                            if (respuesta.data.errors.length > 0) {
                                changeStatus(index, 'bad');
                                errores.push({
                                    image: objProducts[index].images[0],
                                    id: objProducts[index].id,
                                    errors: {
                                        message: respuesta.data.errors.toString()
                                    }
                                })
                            } else {
                                // console.log('es igual a cero', respuesta)
                                enviados = enviados + 1;
                                changeStatus(index, 'send');
                            }
                        } else {
                            // console.log('es undefined', respuesta)
                            enviados = enviados + 1;
                            changeStatus(index, 'send');
                        }
                    }).catch((err) => {
                        console.log('el pinch error del json njda', err)
                        changeStatus(index, 'bad');
                        errores.push({
                            image: objProducts[index].images[0],
                            id: objProducts[index].id,
                            errors: err.request.response ? JSON.parse(err.request.response) : JSON.parse(err)
                        })
                    })
            }

        }
        //* end for
        setErrors(errores.length > 0 ? errores : null);
        setTotalSend(enviados);
        setIsSending(false)
    }

    const deleteAProductByTheList = async  (dataToDelete) => {
        const copyArray = template.filter(v => v.id !== dataToDelete.id);
        //console.log('nuevo valor', copyArray);
        setTemplate(copyArray);

        if(dataToDelete.asin === sku) {
            setSku('');
            setImagesProduct([])
            setStock('');
            setPrice('');
            setWeight('');
            setPesoVolu('No disponible')
            setTiempoEnvio('No disponible');
            setOldPrice('');
            setLink('');
            setDealPrice('');
            setAmazonCategorie(null);
            setPositionItemActive(0);
            setImgActive(0);
        }
    }

    return (
        <PublishProduct.ContModal d={globalContext.modalMultiplePost ? 'flex' : 'none'}>
            <PublishProduct.ModalProduct>
                <PublishProduct.headerModal>
                    <PublishProduct.btnCloseModal onClick={() => { closeModalPublish() }}>
                        <FontAwesomeIcon icon={faTimes} />
                    </PublishProduct.btnCloseModal>
                    <PublishProduct.textHeader size='15pt' ml='auto' mr='auto'>
                        {titlePublication(steps)}
                    </PublishProduct.textHeader>
                    <PublishProduct.textHeader size='12pt' ml='auto' mr='0'>
                        Paso {steps}
                    </PublishProduct.textHeader>
                </PublishProduct.headerModal>
                {
                    isLoad ? (
                        <PublishProduct.bodyModal>
                            <div className={`load-screen`}>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                        </PublishProduct.bodyModal>
                    ) :
                        (
                            <PublishProduct.bodyModal>
                                {
                                    succesPublish ? (
                                        <div className='text-center' style={{ width: '100%', textAlign: 'center' }}>
                                            <PublishProduct.textBody ml='10px' cl='#7367F0' size='18pt' fw='500' style={{ textAlign: 'left' }} >
                                                {isError ? succesPublishMsg.message : 'Publicacion Exitosa!'}
                                            </PublishProduct.textBody>
                                            {isError && (
                                                <PublishProduct.btnActionFooter bc='#7367f0' bgc='#7367f0' ml='5px' cl='#FFFFFF' onClick={() => { setIsError(false); setSucces(false) }}>
                                                    Volver a intentar
                                                </PublishProduct.btnActionFooter>
                                            )
                                            }
                                        </div>
                                    ) :
                                        (
                                            <>
                                                <PublishProduct.listImagesCont>
                                                    <PublishProduct.listImagesBody>
                                                        <PublishProduct.listImages>
                                                            {template &&
                                                                template.map((v, i) => {
                                                                    return (
                                                                        <PublishProduct.ContBtnAndImg key={i}>
                                                                            <PublishProduct.listImageCont
                                                                                active={imgActive === i}
                                                                                key={i}
                                                                                img={v.images[0]}
                                                                                onClick={() => setDataProductShow(v, i)}
                                                                            >
                                                                                {
                                                                                    v.status.length > 0 ?
                                                                                        v.status === 'send' ? (
                                                                                            <PublishProduct.iconStatus status={v.status}>
                                                                                                <FontAwesomeIcon icon={faCheck} />
                                                                                            </PublishProduct.iconStatus>
                                                                                        ) : (
                                                                                            <PublishProduct.iconStatus status={v.status}>
                                                                                                <FontAwesomeIcon icon={faTimes} />
                                                                                            </PublishProduct.iconStatus>
                                                                                        ) : ''
                                                                                }
                                                                            </PublishProduct.listImageCont>
                                                                            <PublishProduct.BtnDeleteProductList onClick={() => deleteAProductByTheList(v)}>
                                                                                Eliminar
                                                                            </PublishProduct.BtnDeleteProductList>
                                                                        </PublishProduct.ContBtnAndImg>
                                                                    )
                                                                })
                                                            }
                                                        </PublishProduct.listImages>
                                                    </PublishProduct.listImagesBody>
                                                </PublishProduct.listImagesCont>
                                                <div className='text-left'>
                                                    <a href={link} target="_blank" rel="noopener noreferrer">
                                                        <PublishProduct.textBody size='12pt' fw='600' ml="15px">
                                                            {`Amazon - ${link}`}
                                                        </PublishProduct.textBody>
                                                    </a>
                                                </div>
                                                <div className='cont-text-body-modal'>
                                                    <PublishProduct.textBody size='12pt' mr='3px'>
                                                        IMAGENES DEL PRODUCTO
                                                    </PublishProduct.textBody>
                                                </div>
                                                <div className='cont-text-body-modal'>
                                                    <PublishProduct.carouselImagesProduct>
                                                        {
                                                            imagesProduct.length > 0 &&
                                                            imagesProduct.map((v, i) => {
                                                                return (
                                                                    <PublishProduct.carouselImageProducts img={v} key={i} alt={v} />
                                                                )
                                                            })
                                                        }
                                                    </PublishProduct.carouselImagesProduct>
                                                </div>
                                                <div className='cont-text-body-modal'>
                                                    <div className='d-flex justify-content-start'>
                                                        <PublishProduct.textBody size='12pt' mr='3px'>
                                                            SKU -
                                                        </PublishProduct.textBody>
                                                        <PublishProduct.textBody cl='#7367F0' size='12pt' ml='3px' mr='10px' fw='600'>
                                                            {sku}
                                                        </PublishProduct.textBody>
                                                        <PublishProduct.textBody size='12pt' mr='3px'>
                                                            STOCK -
                                                        </PublishProduct.textBody>
                                                        <PublishProduct.textBody cl='#7367F0' size='12pt' ml='3px' mr='10px' fw='600'>
                                                            {stock}
                                                        </PublishProduct.textBody>
                                                        <PublishProduct.textBody size='12pt' ml='10px' mr='3px'>
                                                            PESO ORIGINAL -
                                                        </PublishProduct.textBody>
                                                        <PublishProduct.textBody size='12pt' cl='#7367F0' ml='3px' mr='10px' fw='600'>
                                                            {weight} (lbs)
                                                        </PublishProduct.textBody>
                                                    </div>
                                                </div>
                                                <div className='cont-text-body-modal'>
                                                    <PublishProduct.textBody size='12pt' mr='3'>
                                                        USD PROVEDOR -
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody cl='#7367F0' size='12pt' ml='3px' mr='10px' fw='600'>
                                                        {numberFormat.format(price)} $
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' ml='10px' mr='3px'>
                                                        PRECIO OFERTA PROVEDOR -
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' cl='#7367F0' ml='3px' mr='10px' fw='600'>
                                                        {numberFormat.format(dealPrice)} $
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' ml='10px' mr='3px' mlr='20px' >
                                                        PRECIO VIEJO PROVEDOR -
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody cl='#7367F0' size='12pt' ml='3px' fw='600'>
                                                        {numberFormat.format(oldPrice)} $
                                                    </PublishProduct.textBody>
                                                </div>
                                                <div className='cont-text-body-modal'>
                                                    <PublishProduct.textBody size='12pt' mr='3px'>
                                                        PESO VOLUMETRICO -
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' cl='#7367F0' ml='3px' mr='10px' fw='600'>
                                                        {
                                                            pesoVolu !== 'No disponible' ?
                                                                (
                                                                    'Alto ' + pesoVolu.height + ' Largo ' + pesoVolu.width + ' Longitud ' + pesoVolu['length']
                                                                )
                                                                :
                                                                'No Disponoble'
                                                        }
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' ml='10px' mr='3px'>
                                                        TIEMPO DE ENVIO PROVEDOR -
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' cl='#7367F0' ml='3px' mr='10px' fw='600'>
                                                        {`${tiempoEnvio} Dias`}
                                                    </PublishProduct.textBody>
                                                </div>
                                                <div className='cont-text-body-modal'>
                                                    <PublishProduct.textBody size='12pt' mr='3px'>
                                                        PRECIO EN (USD)-
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' cl='#7367F0' ml='3px' mr='10px' fw='600'>
                                                        {
                                                            calculatePrice ?
                                                                'Calculando ...'
                                                                :
                                                                `$ ${numberFormat.format(Math.ceil(totalUsd))}`
                                                        }
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' ml='10px' mr='3px'>
                                                        PRECIO EN (COB/BSF)-
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' cl='#7367F0' ml='3px' mr='10px' fw='600'>
                                                        {
                                                            calculatePrice ?
                                                                'Calculando ...'
                                                                :
                                                                `$ ${numberFormat.format(Math.ceil(total))}`
                                                        }
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' ml='10px' mr='3px'>
                                                        MONTO TOTAL DE LA GANANCIA -
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody size='12pt' cl='#7367F0' ml='3px' mr='10px' fw='600'>
                                                        {
                                                            calculatePrice ?
                                                                'Calculando ...'
                                                                :
                                                                `$${numberFormat.format(Math.ceil(earningUsd))}`
                                                        }
                                                    </PublishProduct.textBody>
                                                </div>
                                                <div className='text-left'>
                                                    <PublishProduct.textBody size='12pt' ml='10px'>
                                                        CATEGORIA DE AMAZON:
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody ml='15px' cl='#7367F0' size='12pt' fw='500'>
                                                        {
                                                            (globalContext.modalMultiplePostData) ?
                                                                (globalContext.modalMultiplePostData[0].variants.length > 0) ?
                                                                    globalContext.modalMultiplePostData[0].variants[0].categoryTree.length > 0 ?
                                                                        globalContext.modalMultiplePostData[0].variants[0].categoryTree.map((c, i) => {
                                                                            if (i === globalContext.modalMultiplePostData[0].variants[0].categoryTree.length - 1) {
                                                                                return (c.name) ? c.name.es ? c.name.es : c.name.en : "";
                                                                            } else {
                                                                                return (c.name) ? c.name.es ? c.name.es + '>' : c.name.en + '>' : "";
                                                                            }
                                                                        }) : 'No posee'
                                                                    :
                                                                    globalContext.modalMultiplePostData[0].categoryTree.length > 0 ?
                                                                        globalContext.modalMultiplePostData[0].categoryTree.map((c, i) => {
                                                                            if (i === globalContext.modalMultiplePostData[0].categoryTree.length - 1) {
                                                                                return (c.name) ? c.name.es ? c.name.es : c.name.en : "";
                                                                            } else {
                                                                                return (c.name) ? c.name.es ? c.name.es + '>' : c.name.en + '>' : '';
                                                                            }
                                                                        })
                                                                        : 'No posee'
                                                                :
                                                                ''
                                                        }
                                                    </PublishProduct.textBody>
                                                </div>
                                                <div className='text-left'>
                                                    <PublishProduct.textBody size='12pt' ml='10px'>
                                                        CATEGORIA DE ML POR PREDICCION:
                                                    </PublishProduct.textBody>
                                                    <PublishProduct.textBody ml='15px' cl='#7367F0' size='12pt' fw='500'>
                                                        {categoryMlPrediction && categoryMlPrediction.tree && categoryMlPrediction.tree.map((c, i) => {
                                                            if (categoryMlPrediction.tree.length - 1 === i) {
                                                                return c.name + '>' + categoryMlPrediction.name;
                                                            } else {
                                                                return c.name + '>';
                                                            }
                                                        })}
                                                    </PublishProduct.textBody>
                                                    {categoryMlPrediction ?
                                                        categoryMlPrediction.name === 'Otros' && (
                                                            <PublishProduct.textBody ml='15px' mt='5px' cl='red' size='10pt' fw='400' >
                                                                Esta Categoria no permite la publicacion de Variantes*
                                                            </PublishProduct.textBody>
                                                        )
                                                        : ''}
                                                </div>
                                                {
                                                    steps > 1 && (
                                                        <div className='text-left'>
                                                            <PublishProduct.textBody size='12pt' ml='10px'>
                                                                CATEGOORIA DE ML POR SELECCION PROPIA:
                                                            </PublishProduct.textBody>
                                                            <PublishProduct.textBody ml='15px' cl='#7367F0' size='12pt' fw='500'>
                                                                {categoryMlText ? categoryMlText : 'No se agrego'}
                                                            </PublishProduct.textBody>
                                                            {categoryMlText ?
                                                                categoryMlText === 'Otros' && (
                                                                    <PublishProduct.textBody ml='15px' mt='5px' cl='red' size='10pt' fw='400' >
                                                                        Esta Categoria no permite la publicacion de Variantes*
                                                                    </PublishProduct.textBody>
                                                                )
                                                                : ''}
                                                        </div>
                                                    )
                                                }
                                                <div style={{ width: 100 + '%' }}>
                                                    {stepRender(steps)}
                                                </div>
                                            </>
                                        )
                                }
                            </PublishProduct.bodyModal>
                        )
                }
                <PublishProduct.footerModal>
                    <PublishProduct.btnActionFooter bc='#707070' mr='auto' onClick={() => { globalContext.setModalMultiplePost(false) }} >
                        Cerrar
                    </PublishProduct.btnActionFooter>
                    {
                        steps > 1 && !succesPublish && steps < 4 && (
                            <PublishProduct.btnActionFooter bc='#7367f0' bgc='#7367f0' ml='auto' cl='#FFFFFF' onClick={() => beforeStep()}>
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </PublishProduct.btnActionFooter>
                        )
                    }
                    {
                        steps < 3 && !succesPublish && (
                            <PublishProduct.btnActionFooter bc='#7367f0' bgc='#7367f0' ml='5px' cl='#FFFFFF' onClick={() => nextStep()}>
                                {steps > 1 ? 'Siguiente' : 'Saltar'}
                            </PublishProduct.btnActionFooter>
                        )
                    }
                    {
                        steps === 3 && !succesPublish ? (
                            <PublishProduct.btnActionFooter bc='#7367f0' bgc='#7367f0' ml='5px' cl='#FFFFFF' onClick={() => publicProducts(template)}>
                                Guardar
                            </PublishProduct.btnActionFooter>
                        ) : ''
                    }
                    {
                        steps === 4 && !isSending ? (
                            <PublishProduct.btnActionFooter bc='#7367f0' bgc='#7367f0' ml='5px' cl='#FFFFFF' onClick={() => beforeStep()}>
                                Volver
                            </PublishProduct.btnActionFooter>
                        ) : ''
                    }
                </PublishProduct.footerModal>
            </PublishProduct.ModalProduct>
        </PublishProduct.ContModal>
    );
}
export default MultiplePost;