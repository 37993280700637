import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
const getCampains = async (entId, axiosCancelTokenSource, pageNumber, pageItemCount) => {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/df-camp/${entId}`, {
        params: {
            pageNumber: pageNumber,
            pageItemCount: pageItemCount
        },
        cancelToken: axiosCancelTokenSource.token,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export default getCampains;