import React, { useEffect, useState } from "react";
import MlStyled from "../../../../assets/css/components/admin/plugins/MlStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Container } from "react-bootstrap";
import MlLogo from "../../../../assets/img/png/ml_logo.png";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import verifyAccessRole from "../../../../assets/js/verifyAccessRole";
import Routes from "../../../../Routes";
export default function MercadoLibreSuccess() {
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const hasAccess = verifyAccessRole(Routes, 47);
    if(!hasAccess) {
        setRedirect(
            <Redirect to={`/admin/dashboard`} />
        )
    }
},[]);
  return (
    <MlStyled>
      {redirect}
      <Container>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex justify-content-left mt-3 align-items-center content-breadcrumb"
          >
            <i className="btn-back">
              <Link to="/admin/plugins/my-plugins/my-menu-plugins">
                <FontAwesomeIcon icon={faChevronCircleLeft} />
              </Link>
            </i>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex justify-content-center  align-items-center"
          >
            <Container className="bodyMlForm">
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="d-flex justify-content-center  align-items-center"
                >
                  <div className="formimg">
                    <img src={MlLogo} alt="ml" />
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="d-flex justify-content-center  align-items-center"
                >
                  <div className="formTxt">
                    <p>Gracias por confiar en E-kommers.com</p>
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="d-flex justify-content-center  align-items-center"
                >
                  <button className="btn-action">
                    ¿Desea eliminar sus credenciales?{" "}
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </MlStyled>
  );
}
