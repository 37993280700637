import React, { useEffect, useState, useContext } from 'react'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle'
import { BiX } from 'react-icons/bi'

import { GlobalContext } from '../../../../../contexts/GlobalContext'
export default function FormSucursal(props) {
    const globalContext = useContext(GlobalContext)
    const { setCurrentStep, sucursales, setSucursales, currentSucursal, setCurrentSucursal } = props;
    const [storeName, setStoreName] = useState(null);
    const [storeId, setStoreId] = useState(null);
    const [error, setError] = useState('');
    const [colorError, setColorError] = useState(true);
    const [isLoad, setIsLoad] = useState(false);
    const [showBadge, setShowBadge] = useState(false);

    const funcSetSucursal = (v) => {
        let copyArr = [...sucursales, v];
        setSucursales(copyArr)
    }

    const closeBadge = (e) => {
        e.preventDefault();
        setShowBadge(false);
    }

    const funcSetStep = (v) => {
        setCurrentStep(v);
    }


    useEffect(() => {
        if (currentSucursal) {
            setStoreName(currentSucursal.store);
            setStoreId(currentSucursal.storeId.toString());
        }
    }, [currentSucursal])



    return (
        <>
            <PedidosYaStyle.ContBadgeError active={showBadge} error={colorError}>
                <PedidosYaStyle.Text size=".875rem" cl="#FFFFFF">
                    {error}
                </PedidosYaStyle.Text>
                <PedidosYaStyle.BtnToggleBadge onClick={(e) => closeBadge(e)}>
                    <BiX />
                </PedidosYaStyle.BtnToggleBadge>
            </PedidosYaStyle.ContBadgeError>
            <PedidosYaStyle.ContForm>
                <PedidosYaStyle.HeaderForm>
                    <PedidosYaStyle.Text size="14pt" fw="500" cl="#898989">
                        Ingrese los datos para registrar su sucursal
                    </PedidosYaStyle.Text>
                </PedidosYaStyle.HeaderForm>
                <PedidosYaStyle.BodyForm>
                    <PedidosYaStyle.ContInputAndLabel w="100%">
                        <PedidosYaStyle.Text size="12pt">
                            Nombre de la Tienda
                        </PedidosYaStyle.Text>
                        <PedidosYaStyle.Input
                            placeholder="Escriba aqui el host de acceso"
                            onChange={(e) => setStoreName(e.target.value)}
                            defaultValue={currentSucursal && currentSucursal.store}
                        />
                    </PedidosYaStyle.ContInputAndLabel>
                    <PedidosYaStyle.ContInputAndLabel w="100%">
                        <PedidosYaStyle.Text size="12pt">
                            ID de la Tienda
                        </PedidosYaStyle.Text>
                        <PedidosYaStyle.Input
                            placeholder="Escriba aqui el host de acceso"
                            onChange={(e) => setStoreId(e.target.value)}
                            defaultValue={currentSucursal && currentSucursal.storeId}
                        />
                    </PedidosYaStyle.ContInputAndLabel>
                </PedidosYaStyle.BodyForm>
                <PedidosYaStyle.FooterForm>
                    <PedidosYaStyle.BtnSend background="#F0F0F0" color="#707070" onClick={() => funcSetStep(2)}>
                        Volver
                    </PedidosYaStyle.BtnSend>
                    <PedidosYaStyle.BtnSend disabled={isLoad} onClick={() => console.log('data')}>
                        {isLoad ? 'Enviando' : 'Guardar'}
                    </PedidosYaStyle.BtnSend>
                </PedidosYaStyle.FooterForm>
            </PedidosYaStyle.ContForm>
        </>
    )
}
