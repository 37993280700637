import { faBoxes, faEllipsisV, faExclamationTriangle, faProjectDiagram, faThumbsUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react'
import MultiScraperStyles from '../../../../../assets/css/components/admin/products/amazon/MultiScraperStyles';
import OptionsProductsHistory from '../OptionsProductsHistory';
import { Spinner } from 'react-bootstrap';
import imgNoFound from "../../../../../assets/img/jpeg/img-no-found.jpg";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';
import { faKaggle } from '@fortawesome/free-brands-svg-icons';

export default function CardComponentCron(props) {
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const numberFormat = new Intl.NumberFormat('de-DE');

    const changeDate = date => {
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        
        return `${dd}-${mm}-${yyyy}`
    }

    const {
        t, i, id, changeToRow, isDeleted, openMenuOptionItem, 
        setOpenMenuOptionItem, findCategoryByicon, deleteAProduct,
        itemsAdds, addItem, setOpenMenuOptionItemFunc, inDeleteProcess,
        itemsToUpdateAndPublish, setItemsToUpdateAndPublish, addItemByDeleteWithOutErrorFunc, mlStatusSet, errorMessage
    } = props;
    

    const funcAdditem = (v,v2) => {
        addItem(v, v2)
    }
    //* agg un elemento al array
    const addOneToPublishAndUpdate = (idItem) => {
        console.log('id del item a enviar', idItem)
        const isHere = itemsToUpdateAndPublish.find((e) => (e === idItem));
        if (isHere === undefined) {
            setItemsToUpdateAndPublish([...itemsToUpdateAndPublish, idItem]);
        } else {
            setItemsToUpdateAndPublish(itemsToUpdateAndPublish.filter((e) => (e !== idItem)))
        }
    }

    /// add new product on odoo
    const addProductOdoo = async () => {
        const entId = globalContext.currentEnterpriseId;
        globalContext.setLoaderPage(true);
        await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}/product`, {
                product:{
                    ekomId: t._id,
                    name: t.variants.length === 0 ?
                    t.title ? t.title.es || t.title.en : 'NP' : 
                    t.variants[0].title.es || t.variants[0].title.en,
                    default_code: t.asin,
                    barcode: t.asin,
                    list_price: t.price,
                    weight : t.weight,
                    volume: (((parseFloat(t.dimensions.height) * parseFloat(t.dimensions.width) * parseFloat(t.dimensions.length)) * 1.2) / 166).toString().slice(0, 5),
                    onlyProd: true
                },

        }, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        )
        .then((res) => {
            globalContext.setLoaderPage(false);
            globalContext.showModalWithMsg("Producto creado con exito!...")
        })
        .catch((err) => {
            globalContext.setLoaderPage(false);
            globalContext.showModalWithMsg(`Error de Odoo, ${err.message}`)
        })
    }

    return (
        <MultiScraperStyles.cardsGrid key={id} changeToRow={changeToRow} isDeleted={isDeleted.includes(id)}>
            <MultiScraperStyles.screenLoader IsScrapingAgain={inDeleteProcess.includes(t._id)}>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </MultiScraperStyles.screenLoader>
            <MultiScraperStyles.contImgCard changeToRow={changeToRow}>
                <OptionsProductsHistory
                    active={i === openMenuOptionItem}
                    setActive={setOpenMenuOptionItem}
                    filterTo={findCategoryByicon}
                    catId={t.variants.length === 0 ? t.categoryTree : t.variants[0].categoryTree }
                    deleteMethod={deleteAProduct}
                    idDeleteByProduct={id}
                    indexProducByDelete={i}
                    testFilters={() => { globalContext.setModalPublishProduct(true); globalContext.setModalPublishProductData(t, id) }}
                />
                {
                    isDeleted.includes(id) ?
                        (
                            <div className="cont-dots">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="dot-one" style={{ color: "#ea5455" }} />
                            </div>
                        )
                        :
                        (t.variants.length  === 0) ?
                        !t.ml ?
                        (
                            <>
                                <input
                                    className="inp-cbx"
                                    style={{ display: 'none' }}
                                    id={`cbx${id}`}
                                    type="checkbox"
                                    onChange={() => { funcAdditem(t, id); addOneToPublishAndUpdate(id); addItemByDeleteWithOutErrorFunc(id,i) }}
                                    checked={itemsAdds.includes(id)}
                                />
                                <label className="cbx" htmlFor={`cbx${id}`}>
                                    <span>
                                        <svg width="12px" height="10px" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                    </span>
                                </label>
                            </>
                        ) :
                        (
                            <>
                                <input
                                    className="inp-cbx"
                                    style={{ display: 'none' }}
                                    id={`cbx${id}`}
                                    type="checkbox"
                                    onChange={() => { addOneToPublishAndUpdate(id) }} 
                                    checked={itemsToUpdateAndPublish.includes(id)}
                                />
                                <label className="cbx" htmlFor={`cbx${id}`}>
                                    <span>
                                        <svg width="12px" height="10px" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                    </span>
                                </label>
                            </>
                        )
                        :
                        !t.variants[0].ml ?
                        (
                            <>
                                <input
                                    className="inp-cbx"
                                    style={{ display: 'none' }}
                                    id={`cbx${id}`}
                                    type="checkbox"
                                    onChange={() => { funcAdditem(t, id) }}
                                    checked={itemsAdds.includes(id)}
                                />
                                <label className="cbx" htmlFor={`cbx${id}`}>
                                    <span>
                                        <svg width="12px" height="10px" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                    </span>
                                </label>
                            </>
                        ) :
                        (
                            <>
                                <input
                                    className="inp-cbx"
                                    style={{ display: 'none' }}
                                    id={`cbx${id}`}
                                    type="checkbox"
                                    onChange={() => { addOneToPublishAndUpdate(id) }} 
                                    checked={itemsToUpdateAndPublish.includes(id)}
                                />
                                <label className="cbx" htmlFor={`cbx${id}`}>
                                    <span>
                                        <svg width="12px" height="10px" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                    </span>
                                </label>
                            </>
                        )
                }
                <MultiScraperStyles.imgCard changeToRow={changeToRow}
                    url={ t.variants.length === 0 ? t.images.length > 0 ? t.images[0] : imgNoFound : t.variants[0].images.length > 0 ? t.variants[0].images[0] : imgNoFound }
                />
                <div className="d-block text-center">
                    <div className="cont-dots">
                        <MultiScraperStyles.btnOptions onClick={() => setOpenMenuOptionItemFunc(i)}>
                            <FontAwesomeIcon icon={faEllipsisV} />
                        </MultiScraperStyles.btnOptions>
                    </div>
                    {
                        t.variantGroupId &&
                        (
                            <div className="cont-dots" style={{ marginBottom: 5, marginTop: 5 }}>
                                <FontAwesomeIcon icon={faBoxes} className="dot-one" style={{ color: "#ffcf56" }} />
                            </div>
                        )
                    }
                    {
                        t.variants.length > 0 ?
                        (t.variants[0].ml) &&
                            (
                                <div className="cont-dots" style={{ marginBottom: 5, marginTop: 5 }}>
                                    <FontAwesomeIcon icon={faThumbsUp} className="dot-one" style={{ color: "#2ec4b6" }} />
                                </div>
                            )
                            :
                        (t.ml) &&
                        (
                            <div className="cont-dots" style={{ marginBottom: 5, marginTop: 5 }}>
                                <FontAwesomeIcon icon={faThumbsUp} className="dot-one" style={{ color: "#2ec4b6" }} />
                            </div>
                        )
                    }
                    {
                        t.dataByKeepa && (
                            <div className="cont-dots" style={{ marginBottom: 5, marginTop: 5 }}>
                                <MultiScraperStyles.contImgLogoKeepa img={'/keepa-logo.png'} />
                            </div>
                        )
                    }
                    {
                        errorMessage && (
                            <div className="cont-dots" style={{ marginBottom: 5, marginTop: 5 }}>
                                <FontAwesomeIcon icon={faTimes} className="dot-one" style={{ color: "#FF4A4A" }} />
                            </div>
                        )
                    }
                </div>
            </MultiScraperStyles.contImgCard>
            <MultiScraperStyles.dataCardText changeToRow={changeToRow}>
                {!changeToRow && (
                    <div className="price">
                        {t.variants.length === 0 ? `$${numberFormat.format(t.price)}` : `$${numberFormat.format(t.variants[0].price)}`}
                    </div>
                )}
                <MultiScraperStyles.titleDataCardtext changeToRow={changeToRow}>
                    {
                        t.variants.length === 0 ?
                        t.title ? t.title.es || t.title.en : 'NP' : 
                        t.variants[0].title.es || t.variants[0].title.en
                        }
                </MultiScraperStyles.titleDataCardtext>
                <MultiScraperStyles.preciosCardText>
                    <div className="precio-oferta">
                        <p>Precio de oferta -</p>
                        <p>{t.variants.length === 0 ? `$${numberFormat.format(t.dealPrice)}` : `$${numberFormat.format(t.variants[0].dealPrice)}`}</p>
                    </div>
                    <div className="precio-old">
                        <p>Precio antiguo -</p>
                        <p>{t.variants.length === 0 ? `$${numberFormat.format(t.oldPrice)}` : `$${numberFormat.format(t.variants[0].oldPrice)}`}</p>
                    </div>
                    <div className="precio-old">
                        <p> -</p>
                        <p>{`SKU - ${t.variants.length === 0 ? t.asin : t.variants[0].asin}`}</p>
                    </div>
                    <div className="precio-old">
                        <p>Actualizado - </p>
                        <p>{t.updatedAt ? changeDate(new Date(t.updatedAt)) : ''}</p>
                    </div>
                    <div className="precio-old">
                        <p>{`Estatus en ML - ${mlStatusSet || ''}`}</p>
                    </div>
                    {errorMessage && (
                        <div className="precio-old">
                            <p>{`Error - ${errorMessage || ''}`}</p>
                        </div>
                    )}
                    
                    
                </MultiScraperStyles.preciosCardText>
            </MultiScraperStyles.dataCardText>
            <MultiScraperStyles.contBtnCardActions changeToRow={changeToRow}>
                {changeToRow && (
                    <div className="price">
                        {t.variants.length === 0 ? `$${numberFormat.format(t.price)}` : `$${numberFormat.format(t.variants[0].price)}`}
                    </div>
                )}
                {
                    !errorMessage ?
                    !isDeleted.includes(id) &&
                    t.variants.length > 0 ?
                    (!t.variants[0].ml) ?
                    (
                        <>
                            <MultiScraperStyles.btnActionsCard
                                onClick={() => addProductOdoo()}
                                brbl="5px" colorFont="#707070" bgColor="#F5F5F6"
                                changeToRow={changeToRow}
                            >
                                Crear en odoo
                            </MultiScraperStyles.btnActionsCard>
                            <MultiScraperStyles.btnActionsCard
                                brbr="5px"
                                colorFont="#ffffff"
                                bgColor="#7367F0"
                                onClick={() => { globalContext.setModalPublishProduct(true); globalContext.setModalPublishProductData(t.variants[0], id) }}
                                changeToRow={changeToRow}
                            >
                                Publicar
                            </MultiScraperStyles.btnActionsCard>
                        </>
                    ) :
                    (
                        <MultiScraperStyles.btnActionsCard
                                onClick={() => addProductOdoo()}
                                brbl="5px" colorFont="#707070" bgColor="#F5F5F6"
                                changeToRow={changeToRow}
                            >
                                Crear en odoo
                            </MultiScraperStyles.btnActionsCard>
                    )
                    : 
                    (!t.ml) ?
                    (
                        <>
                            <MultiScraperStyles.btnActionsCard
                                onClick={() => addProductOdoo()}
                                brbl="5px" colorFont="#707070" bgColor="#F5F5F6"
                                changeToRow={changeToRow}
                            >
                                Crear en odoo
                                </MultiScraperStyles.btnActionsCard>
                            <MultiScraperStyles.btnActionsCard
                                brbr="5px"
                                colorFont="#ffffff"
                                bgColor="#7367F0"
                                onClick={() => { globalContext.setModalPublishProduct(true); globalContext.setModalPublishProductData(t, id) }}
                                changeToRow={changeToRow}
                            >
                                Publicar
                            </MultiScraperStyles.btnActionsCard>
                        </>
                    )
                    :
                    (
                        <MultiScraperStyles.btnActionsCard
                                onClick={() => addProductOdoo()}
                                brbl="5px" colorFont="#707070" bgColor="#F5F5F6"
                                changeToRow={changeToRow}
                            >
                                Crear en odoo
                            </MultiScraperStyles.btnActionsCard>
                    ) : ""
                }
            </MultiScraperStyles.contBtnCardActions>
        </MultiScraperStyles.cardsGrid>
    )
}
