import axios from "axios";
import { keyAccessToken, keyUserId } from "../../../../../assets/js/SessionStorageNames";
const sendDataToLinkProduct = (idProductpeya, enterprise, barCode, storeId) => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL_PEYA}/v1/peya-sdk/link-one-product`,
        {
            idProductpeya: idProductpeya,
            enterprise: enterprise,
            barCode: barCode,
            storeId: storeId
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export default sendDataToLinkProduct;