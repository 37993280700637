import React from 'react'
import PublishProduct from "../../../../../assets/css/components/admin/products/amazon/ModalPublishProduct";
import { Spinner } from 'react-bootstrap';
export default function Succes(props) {
    const {template, positionItemActive, tiendaText, listingTypeText, anotherIsLoad} = props;
    return (
        <PublishProduct.contSuccess>
            
            <div className='text-left'>
                <PublishProduct.textBody size='12pt' mr='3px'>
                    Titulo del producto:
                </PublishProduct.textBody>
                <PublishProduct.textBody cl='#7367F0' size='12pt' fw='600'>
                    {template && template[positionItemActive].titulo}
                </PublishProduct.textBody>
            </div>
            <div className='text-left'>
                <PublishProduct.textBody size='12pt' mr='3px'>
                    Tienda oficial:
                </PublishProduct.textBody>
                <PublishProduct.textBody cl='#7367F0' size='12pt' fw='600'>
                    {tiendaText}
                </PublishProduct.textBody>
            </div>
            <div className='text-left'>
                <PublishProduct.textBody size='12pt' mr='3px'>
                    Tipo de la publicacion:
                </PublishProduct.textBody>
                <PublishProduct.textBody cl='#7367F0' size='12pt' fw='600'>
                    {listingTypeText}
                </PublishProduct.textBody>
            </div>
            <div className='text-left'>
                <PublishProduct.textBody size='12pt' mr='3px'>
                    Peso en libras:
                </PublishProduct.textBody>
                <PublishProduct.textBody cl='#7367F0' size='12pt' fw='600'>
                    {template && template[positionItemActive].peso}(Libras)
                </PublishProduct.textBody>
            </div>
            <div className='text-left'>
                <PublishProduct.textBody size='12pt' mr='3px'>
                    Precio final:
                </PublishProduct.textBody>
                <PublishProduct.textBody cl='#7367F0' size='12pt' fw='600'>
                    ${template && template[positionItemActive].precio}
                </PublishProduct.textBody>
            </div>
            {
                anotherIsLoad ? 
                (
                    <PublishProduct.bodyModal>
                            <div className={`load-screen`}>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                        </PublishProduct.bodyModal>
                )
                : (
                    <div className='text-left'>
                        <PublishProduct.textBody size='12pt' mr='3px'>
                            Descripcion:
                        </PublishProduct.textBody>
                        <PublishProduct.textArea readOnly hg='500px' bdgc='transparent'  size='12pt' fw='600' value={template && template[positionItemActive].descripcion}/>
                    </div>
                )
            }
            
        </PublishProduct.contSuccess>
    )
}
