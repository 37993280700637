import React, { useEffect } from 'react'
import AddImagesCss from '../../../../../assets/css/components/admin/products/post-product/steps/AddImagesCss'

export default function AddImages(props) {
    const {
        dataProduct, 
        backStep,
        imgByAdd,
        setImgByAdds,
        imgInArraySend,
        setImgInArraySend
    } = props;
    
    const funcSetImgByAdds = (v) => {
        setImgByAdds(v);
    }
    const funcSetImgInArraySend = (v) => {
        setImgInArraySend(v);
    }
    const addImg = (v) => {
        const isHere = imgInArraySend.find((e) => (e === v));
        if (isHere === undefined) {
            funcSetImgInArraySend([...imgInArraySend, v]);
        } else {
            funcSetImgInArraySend(imgInArraySend.filter((e) => (e !== v)))
        }
    } 
    useEffect(()=> {
        if(!dataProduct) return;
        let arryImg = [];
        arryImg.push({name: dataProduct.thumbnail.name, url: dataProduct.thumbnail.url})
        if (dataProduct.variants.length > 0) {
            dataProduct.variants.map((v) => {
                arryImg.push({name: v.thumbnail.name, url: v.thumbnail.url})
            })
        }

        funcSetImgByAdds([...arryImg]);
    }, [dataProduct])
    return (
        <AddImagesCss.cont>
            <AddImagesCss.header>
                <AddImagesCss.text size="15pt" fw="600">
                    Seleccione las imagenes para su producto
                </AddImagesCss.text>
            </AddImagesCss.header>
            <AddImagesCss.body>
                <AddImagesCss.gridData>
                    {
                        imgByAdd &&
                        imgByAdd.map((v,i) =>{
                            return (
                                <AddImagesCss.contImage active={imgInArraySend.includes(v.url)} img={v.url} key={v.name} onClick={() => {addImg(v.url)}} />
                            )
                        })
                    }
                </AddImagesCss.gridData>
            </AddImagesCss.body>
            <AddImagesCss.footer>
                <AddImagesCss.btnBack onClick={() => backStep()}>
                    Volver
                </AddImagesCss.btnBack>
            </AddImagesCss.footer>
        </AddImagesCss.cont>
    )
}
