import React from "react";
import {
  Container,
  Row,
  Col
} from "react-bootstrap";
import {
  faShoppingCart,
  faHandshake,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faAmazon } from "@fortawesome/free-brands-svg-icons";

import TableDashboardStyle from "../../assets/css/components/admin/products/TableDashboardStyle.css";
import GraphicOneHeader from "./dashboard/components/header-components/graphics/GraphicOne";
import GraphicOneBody from "./dashboard/components/body-components/graphics/GraphicOne";
import GraphicTwo from "./dashboard/components/body-components/graphics/GraphicTwo";
import HeadHeader from "./dashboard/components/header-components/head/HeadHeader";


export default function Dashboard() {
  /**
   * ? calculate width of screen by graphic
   */
  
  const labelsData = ["Jan", "Feb", "Mar", "Apr", "May"];
  const data = [4, 12, 5, 7, 9];
  const dataBody = [
    {
      label: "Ventas del 2019 (M)",
      data: [4, 12, 5, 7, 9],
      borderColor: "rgba(199, 179, 233)",
      backgroundColor: "#9f86c0",
    },
    {
      label: "Ventas del 2020 (M)",
      data: [4, 15, 5, 9, 15],
      borderColor: "rgb(94,84,142)",
      backgroundColor: "rgb(94,84,142)",
    },
  ];
    const dataBodyTwo = [
      {
        label: "Ventas del 2019 (M)",
        data: [4, 12, 5, 7, 9],
        backgroundColor: [
          "rgba(199, 179, 233)",
          "#4776e6",
          "#3A6073",
          "#0ABFBC",
          "#f2709c",
        ],
      },
    ];
  return (
    <TableDashboardStyle>
      <Container fluid>
        <Row>
          <Col className="" xs={12} sm={12} md={12} lg={12} xl={12}>
            <HeadHeader />
          </Col>
        </Row>
        <Row>
          <Col
            className="d-flex justify-content-center align-items-center"
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
          >
            <GraphicOneHeader
              backgroundColor="background-one"
              borderColorLines="rgba(199, 179, 233)"
              backgroundColorLines="rgba(199, 179, 233, 0.2)"
              backgroundColorPoints="rgba(199, 179, 233)"
              borderColorPoints="rgba(199, 179, 233, 0.2)"
              pointsRadio={5}
              borderWidthPoints={1}
              labelsData={labelsData}
              data={data}
              textCard="Total de Productos"
              subTitleCard="Plugin Odoo"
              iconGraphicCard={faShoppingCart}
            />
          </Col>
          <Col
            className="d-flex justify-content-center align-items-center"
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
          >
            <GraphicOneHeader
              backgroundColor="background-two"
              borderColorLines="rgba(199, 179, 233)"
              backgroundColorLines="rgba(199, 179, 233, 0.2)"
              backgroundColorPoints="rgba(199, 179, 233)"
              borderColorPoints="rgba(199, 179, 233, 0.2)"
              pointsRadio={5}
              borderWidthPoints={1}
              labelsData={labelsData}
              data={data}
              textCard="Total de Productos"
              subTitleCard="Plugin Mercado libre"
              iconGraphicCard={faHandshake}
            />
          </Col>
          <Col
            className="d-flex justify-content-center align-items-center"
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
          >
            <GraphicOneHeader
              backgroundColor="background-three"
              borderColorLines="rgba(255, 255, 255)"
              backgroundColorLines="transparent"
              pointsRadio={5}
              backgroundColorPoints="#ffffff"
              borderColorPoints="rgba(0, 0, 0, 0.1)"
              borderWidthPoints={1}
              labelsData={labelsData}
              data={data}
              textCard="Total de Productos"
              subTitleCard="plugin Amazon"
              iconGraphicCard={faAmazon}
            />
          </Col>
          <Col
            className="d-flex justify-content-center align-items-center"
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
          >
            <GraphicOneHeader
              backgroundColor="background-four"
              borderColorLines="rgba(199, 179, 233)"
              backgroundColorLines="rgba(199, 179, 233, 0.2)"
              backgroundColorPoints="rgba(199, 179, 233)"
              borderColorPoints="rgba(199, 179, 233, 0.2)"
              pointsRadio={5}
              borderWidthPoints={1}
              labelsData={labelsData}
              data={data}
              textCard="Total de Productos"
              subTitleCard="Sincronizados"
              iconGraphicCard={faSyncAlt}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="d-flex justify-content-center align-items-center mt-3 mb-3"
          >
            <GraphicOneBody
              dataSets={dataBody}
              labelsData={labelsData}
              titleText="other graphic"
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="d-flex justify-content-center align-items-center mt-3 mb-3"
          >
            <GraphicTwo
              dataSets={dataBodyTwo}
              labelsData={labelsData}
              titleText="other graphic"
            />
          </Col>
        </Row>
      </Container>
    </TableDashboardStyle>
  );
}
