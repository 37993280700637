import React, { useContext, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { BiFile, BiPencil, BiPlus } from 'react-icons/bi'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle'
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import createCsvFile from '../functions/createCsvFile';
import getSucursales from '../functions/getSucursales';
export default function SucursalList(props) {
    const globalContext = useContext(GlobalContext);
    const [sucursales, setSucursales] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const { setCurrentStep, setCurrentSucursal } = props;
    
    const funcSetStep = (v) => {
        setCurrentStep(v);
    }

    const funcSetCurrentSucursal = (v) => {
        setCurrentSucursal(v);
        funcSetStep(3);
    }
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        getSucursales(entId)
            .then(res => {
                const data = res.data.data;
                console.log(data)
                setSucursales(data);

            }).catch(err => {
                console.log(err, 'el error de sucursales')
            })
    }, [globalContext.currentEnterpriseId])

    const createFileCsvAndSend = v => {
        setIsLoad(true)
        if(!v.enterprise) {
            globalContext.showModalWithMsg('la empresa es requerida');
            setIsLoad(false)
            return
        }
        if (!v.storeId) {
            globalContext.showModalWithMsg('El id de la tienda no puede estar vacio');
            setIsLoad(false);
            return
        }
        
        createCsvFile(v.storeId.toString(), v.enterprise)
        .then((res) => {
            console.log('okoko')
            globalContext.showModalWithMsg('El archivo se esta generando en segundo plano con exito!');
            setIsLoad(false)
        }).catch(err => {
            setIsLoad(false);
            globalContext.showModalWithMsg(`Algo salio mal generando el archivo! ${err}`);
            console.log(err)
        })
    }
    return (
        <PedidosYaStyle.ContTable>
            <PedidosYaStyle.HeaderContTable>
                <PedidosYaStyle.Text size="13pt" fw="500">
                    Listado de Sucursales
                </PedidosYaStyle.Text>
                <PedidosYaStyle.BtnAndIcon onClick={() => funcSetStep(3)}>
                    <PedidosYaStyle.ContIconBtn>
                        <BiPlus />
                    </PedidosYaStyle.ContIconBtn>
                    Nueva
                </PedidosYaStyle.BtnAndIcon>
            </PedidosYaStyle.HeaderContTable>
            <PedidosYaStyle.TableResult>
                <PedidosYaStyle.TableResultThead>
                    <PedidosYaStyle.TableResultTr>
                        <PedidosYaStyle.TableResultTh width="300px" minWidth="100px" textAlign="center">
                            Nombre de la Tienda
                        </PedidosYaStyle.TableResultTh>
                        <PedidosYaStyle.TableResultTh width="300px" minWidth="100px" textAlign="center">
                            ID de la Tienda
                        </PedidosYaStyle.TableResultTh>
                        <PedidosYaStyle.TableResultTh width="100px" minWidth="100px" textAlign="center">
                            Accion
                        </PedidosYaStyle.TableResultTh>
                    </PedidosYaStyle.TableResultTr>
                </PedidosYaStyle.TableResultThead>
                <PedidosYaStyle.TableResultTbody>
                    {
                        sucursales.length > 0 &&
                        sucursales.map((v) => {
                            return (
                                <PedidosYaStyle.TableResultTr key={v.storeId}>
                                    <PedidosYaStyle.TableResultTd width="300px" minWidth="100px" textAlign="center">
                                        {v.store}
                                    </PedidosYaStyle.TableResultTd>
                                    <PedidosYaStyle.TableResultTd width="300px" minWidth="100px" textAlign="center">
                                        {v.storeId}
                                    </PedidosYaStyle.TableResultTd>
                                    <PedidosYaStyle.TableResultTd width="100px" minWidth="100px" textAlign="center" backGroundColor="#F3F2F7">
                                        <div className="d-flex justify-content-center align-items-center" style={{with:'100%'}}>
                                        {
                                            isLoad ? 
                                            (
                                                <PedidosYaStyle.contLoader>
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Cargando...</span>
                                                    </Spinner>
                                                </PedidosYaStyle.contLoader>
                                            )
                                            :
                                            (
                                                <>
                                                <PedidosYaStyle.BtnIcon
                                                    onClick={() => funcSetCurrentSucursal(v)}
                                                    mRight="auto"
                                                    mLeft="auto"
                                                    width="30px"
                                                    height="30px">
                                                    <BiPencil />
                                                </PedidosYaStyle.BtnIcon>
                                                <PedidosYaStyle.BtnIcon
                                                    onClick={() => createFileCsvAndSend(v)}
                                                    mRight="auto"
                                                    mLeft="auto"
                                                    width="30px"
                                                    height="30px">
                                                    <BiFile />
                                                </PedidosYaStyle.BtnIcon>
                                                </>
                                            )
                                        }
                                        </div>
                                    </PedidosYaStyle.TableResultTd>
                                </PedidosYaStyle.TableResultTr>
                            )
                        })
                    }
                </PedidosYaStyle.TableResultTbody>
            </PedidosYaStyle.TableResult>
        </PedidosYaStyle.ContTable>
    )
}
