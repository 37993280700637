import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Routes from "../../Routes";

export default function UserEnterprise() {
  return (
    <Switch>
      {Routes.map((prop, key) => {
        if (prop.father === "/user-config") {
          return (
            <Route
              path={prop.layout + prop.father + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/admin/user-config" to="/admin/user-config/index" />
    </Switch>
  );
}
