import React, { useContext, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { BiPencil, BiSync } from 'react-icons/bi'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle'
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import getAllRestaurant from '../functions/getAllRestaurant';
import FormLinkedSucursal from './FormLinkedSucursal';

export default function RestaurantsList() {
    const globalContext = useContext(GlobalContext);
    const [isLoad, setIsLoad] = useState(false);
    const [isLoadTwo, setIsLoadTwo] = useState(true);
    const [restaurants, setRestaurants] = useState([])

    
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId
        if (!entId) return;
        getAllRestaurant(entId)
            .then((res) => {
                const datRes = res.data.data;
                setRestaurants(datRes);
                setIsLoadTwo(false)
            })
            .catch(err => {
                console.log('el error res', err)
            })
    }, [globalContext.currentEnterpriseId])


    return (
        <>
        <PedidosYaStyle.ContTable>
            <PedidosYaStyle.HeaderContTable>
                <PedidosYaStyle.Text size="13pt" fw="500">
                    Listado de Restaurantes
                </PedidosYaStyle.Text>
                <div className="d-flex justify-content-center" style={{ width: 'auto' }}>
                    <PedidosYaStyle.BtnAndIcon ml="5px" onClick={() => globalContext.setLinkSucursal(true)}>
                        <PedidosYaStyle.ContIconBtn>
                            <BiSync />
                        </PedidosYaStyle.ContIconBtn>
                        Sincronizar
                    </PedidosYaStyle.BtnAndIcon>
                </div>
            </PedidosYaStyle.HeaderContTable>
            <PedidosYaStyle.TableResult>
                <PedidosYaStyle.TableResultThead>
                    <PedidosYaStyle.TableResultTr>
                        <PedidosYaStyle.TableResultTh width="300px" minWidth="100px" textAlign="center">
                            Nombre del restaurant
                        </PedidosYaStyle.TableResultTh>
                        <PedidosYaStyle.TableResultTh width="300px" minWidth="100px" textAlign="center">
                            Id
                        </PedidosYaStyle.TableResultTh>
                        <PedidosYaStyle.TableResultTh width="100px" minWidth="100px" textAlign="center">
                            Accion
                        </PedidosYaStyle.TableResultTh>
                    </PedidosYaStyle.TableResultTr>
                </PedidosYaStyle.TableResultThead>
                {
                    isLoadTwo ? (
                        <PedidosYaStyle.TableResultTbody>
                            <PedidosYaStyle.TableResultTr>
                                <PedidosYaStyle.TableResultTd width="100%" minWidth="100px" textAlign="center" colSpan="3">
                                    <PedidosYaStyle.contLoader>
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Cargando...</span>
                                        </Spinner>
                                    </PedidosYaStyle.contLoader>
                                </PedidosYaStyle.TableResultTd>
                            </PedidosYaStyle.TableResultTr>

                        </PedidosYaStyle.TableResultTbody>
                    ) :
                    (
                        <PedidosYaStyle.TableResultTbody>
                                {
                                    restaurants.length > 0 &&
                                    restaurants.map(v => {
                                        return (
                                            <PedidosYaStyle.TableResultTr key={v._id}>
                                                <PedidosYaStyle.TableResultTd width="300px" minWidth="100px" textAlign="center">
                                                    {v.store}
                                                </PedidosYaStyle.TableResultTd>
                                                <PedidosYaStyle.TableResultTd width="300px" minWidth="100px" textAlign="center">
                                                    {v.storeId}
                                                </PedidosYaStyle.TableResultTd>
                                                <PedidosYaStyle.TableResultTd width="100px" minWidth="100px" textAlign="center" backGroundColor="#F3F2F7">
                                                    <div className="d-flex justify-content-center align-items-center" style={{ with: '100%' }}>
                                                        
                                                    </div>
                                                </PedidosYaStyle.TableResultTd>
                                            </PedidosYaStyle.TableResultTr>
                                        )
                                    })
                                }
                            </PedidosYaStyle.TableResultTbody>
                        )
                }
            </PedidosYaStyle.TableResult>
        </PedidosYaStyle.ContTable>
        <FormLinkedSucursal />
        </>
    )
}