import styled from 'styled-components';
const GraphicTwoStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");

  .doughnut-chart-content {
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    font-family: "Roboto Slab", serif !important;
    padding: 40px;
    padding-right: 35px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background: #fff;
    min-width: 575px;
    overflow: hidden;
  }

  @media (min-width: 1200px) and (max-width: 1290px) {
    .doughnut-chart-content {
      min-width: 500px;
      width: 500px;
      max-width: 500px;
      padding: 10px;
    }
  }
  @media (min-width: 1101px) and (max-width: 1199px) {
    .doughnut-chart-content {
      min-width: 490px;
      width: 490px;
      max-width: 490px;
      padding: 10px;
    }
  }

  @media (min-width: 992px) and (max-width: 1100px) {
    .doughnut-chart-content {
      min-width: 425px;
      width: 425px;
      max-width: 425px;
      padding: 10px;
    }
  }

  @media (min-width: 600px) and (max-width: 991px) {
    .doughnut-chart-content {
      min-width: 755px;
      width: 755px;
      max-width: 755px;
      padding: 10px;
    }
  }
  @media (min-width: 500px) and (max-width: 599px) {
    .doughnut-chart-content {
      min-width: 420px;
      width: 420px;
      max-width: 420px;
      padding: 5px;
    }
  }
  @media (min-width: 400px) and (max-width: 499px) {
    .doughnut-chart-content {
      min-width: 380px;
      width: 380px;
      max-width: 380px;
      padding: 1px;
    }
  }
  @media (min-width: 300px) and (max-width: 399px) {
    .doughnut-chart-content {
      min-width: 270px;
      width: 270px;
      max-width: 270px;
      padding: 1px;
    }
  }
`;
export default GraphicTwoStyle;