import axios from 'axios'
import {keyAccessToken, keyUserId} from '../../../../../assets/js/SessionStorageNames'
const getSucursal = async  (entId) => {
    const resp = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/v1/peya-acc/peyaStore/${entId}`,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return resp
}

export default getSucursal;