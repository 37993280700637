import styled from 'styled-components';

const CardFilterStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(94, 84, 142);
    color: #ffffff;
    border-radius: 10px;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-right: 2%;
    margin-left:2%;
    height: 30px;
    padding: 2px 8px;
    min-width: 110px;
    width: auto;
    max-width: 205px;
    font-family: "Roboto Slab", serif !important;
  }
.card-text, .card-close {
  font-size: 10pt;
}
  .card-close button {
    color: #ffffff;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    &:focus,
    &:active,
    &:active:focus {
      outline: none;
    }
  }
`;
export default CardFilterStyle