import React, { useState } from "react";
//* import steps components
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
//import StepThree from "./StepThree";
//import Success from "./Success";

export default function PrintSteps() {
  //* esta constante almacena el valor de los step
  const [step, setStep] = useState(1);

  /**
   * ! method next step
   */
  let nextStep = () => {
    setStep(step + 1);
  };
  /**
   * !method prev step
   */
  let befStep = () => {
    setStep(step - 1);
  };

  switch (step) {
    case 1:
      return <StepOne nextStep={nextStep} />;
    case 2:
      return <StepTwo nextStep={nextStep} befStep={befStep} />;
    default:
      return <StepOne nextStep={nextStep} />;
  }
}
