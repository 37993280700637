import { BsPencil } from 'react-icons/bs';
import { BiPauseCircle, BiPlayCircle } from 'react-icons/bi'
import React from 'react'
import ListFiltersStyles from '../../../../assets/css/components/admin/filters/ListFiltersCss'
import { Link } from 'react-router-dom'
export default function TableData(props) {
    const {data, disabledFilter, enabledFilter} = props;

    const disabledAny = (id, index) => {
        let yesIDelete = window.confirm('Seguro que desea pausar este filtro?');
        if(yesIDelete) {
            disabledFilter(id, index);
        }
    }
    const enabledAny = (id, index) => {
        enabledFilter(id, index)
    }

    const attribLabel = (v) => {
        if(v === 'description.plain_text') {
            return 'Descripcion';
        }else if(v === 'title') {
            return 'Titulo';
        }else if (v === 'all_attr') {
            return 'Caracteristicas';
        } else {
            return 'no tiene'
        }
    }
    return (
        <ListFiltersStyles.tableData>
            <ListFiltersStyles.tableDataThead>
                <tr>
                <ListFiltersStyles.tableDataTh
                w="300px"
                >
                    Mensaje
                </ListFiltersStyles.tableDataTh>
                <ListFiltersStyles.tableDataTh
                w="200px"
                >
                    Campo
                </ListFiltersStyles.tableDataTh>
                <ListFiltersStyles.tableDataTh
                w="70px"
                >
                    País
                </ListFiltersStyles.tableDataTh>
                <ListFiltersStyles.tableDataTh
                w="100px"
                >
                    Marketplace
                </ListFiltersStyles.tableDataTh>
                <ListFiltersStyles.tableDataTh
                w="200px"
                >
                    Expresión Regular
                </ListFiltersStyles.tableDataTh>
                <ListFiltersStyles.tableDataTh
                w="130px"
                >
                    Eliminar Palabra
                </ListFiltersStyles.tableDataTh>
                <ListFiltersStyles.tableDataTh
                w="50px"
                >
                    ¿Está Negada?
                </ListFiltersStyles.tableDataTh>
                <ListFiltersStyles.tableDataTh
                w="100px"
                >
                    Acciones
                </ListFiltersStyles.tableDataTh>
                </tr>
            </ListFiltersStyles.tableDataThead>
            <ListFiltersStyles.tableDataTbody>
                    {
                        data.length > 0 && 
                        data.map( (v, i) => {
                                return (
                                    <ListFiltersStyles.tableDataTr key={v._id}>
                                        <ListFiltersStyles.tableDataTd
                                        w="300px"
                                        >
                                            {v.message}
                                        </ListFiltersStyles.tableDataTd>
                                        <ListFiltersStyles.tableDataTd
                                        w="200px"
                                        >
                                            {attribLabel(v.attr)}
                                        </ListFiltersStyles.tableDataTd>
                                        <ListFiltersStyles.tableDataTd
                                        w="70px"
                                        >
                                            {
                                            v.countries.toString()
                                            }
                                        </ListFiltersStyles.tableDataTd>
                                        <ListFiltersStyles.tableDataTd
                                        w="100px"
                                        >
                                            {v.plugins.toString()}
                                        </ListFiltersStyles.tableDataTd>
                                        <ListFiltersStyles.tableDataTd
                                        w="200px"
                                        >
                                            {v.regexp}
                                        </ListFiltersStyles.tableDataTd>
                                        <ListFiltersStyles.tableDataTd
                                        w="130px"
                                        >
                                            {v.deleteFound ? 'Si' : 'No'}
                                        </ListFiltersStyles.tableDataTd>
                                        <ListFiltersStyles.tableDataTd
                                        w="50px"
                                        >
                                            {v.deny ? 'Si' : 'No'}
                                        </ListFiltersStyles.tableDataTd>
                                        <ListFiltersStyles.tableDataTd
                                        w="100px"
                                        >
                                            <ListFiltersStyles.contIcons>
                                                {
                                                    v.enabled ?
                                                    (
                                                        <>
                                                        <Link to={`update/${v._id}`}>
                                                            <ListFiltersStyles.btnActionIcon >
                                                                <BsPencil size="25px"/>
                                                            </ListFiltersStyles.btnActionIcon>
                                                        </Link>
                                                        <ListFiltersStyles.btnActionIcon onClick={() => disabledAny(v._id, i)}>
                                                            <BiPauseCircle size="25px"/>
                                                        </ListFiltersStyles.btnActionIcon>
                                                        </>
                                                    ) :
                                                    (
                                                        <ListFiltersStyles.btnActionIcon onClick={() => enabledAny(v._id, i)}>
                                                            <BiPlayCircle size="25px"/>
                                                        </ListFiltersStyles.btnActionIcon>
                                                    )
                                                }
                                            </ListFiltersStyles.contIcons>
                                        </ListFiltersStyles.tableDataTd>
                                    </ListFiltersStyles.tableDataTr>
    
                                )
                        })
                    }
                
            </ListFiltersStyles.tableDataTbody>
        </ListFiltersStyles.tableData>
    )
}
