import React from 'react'
import GraphicOneStyle from '../../../../../../assets/css/components/admin/dashboard/dashboard-graphics-body/GraphicOneStyle.css'
import {Bar} from "react-chartjs-2";
export default function GraphicOne(props) {
    const data = {
      labels: props.labelsData,
      datasets: props.dataSets,
    };
    const options = {
      responsive: true,
      maintainAspectRatio: true,

      title: {
        display: true,
        text: props.titleText,
      },
      legend: {
        display: true,
        labels: {
          fontColor: "rgb(94,84,142)",
          usePointStyle:true,
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              display: true,
            },
            ticks: {
              display: true,
              stepSize: 1,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              display: true,
            },
            gridLines: {
              display: true,
            },
          },
        ],
      },
    };
    return (
      <GraphicOneStyle>
        <div className="bar-chart-content">
          <Bar
            data={data}
            options={options}
            width={500}
            height={300}
          />
        </div>
      </GraphicOneStyle>
    );
}
