import React, { useState } from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default function FilterListOtherProduct(props) {
  const [currentFilter, setCurrentFilter] = useState(props.filter);

  /**
   * Updates the filter value
   */
  const updateCurrentFilter = (e) => {
    setCurrentFilter(e.target.value);
  };

  return (
    <div>
      <InputGroup>
        <FormControl
          placeholder="¿Que buscas?"
          aria-label="¿Que buscas?"
          aria-describedby="basic-addon2"
          value={currentFilter}
          onChange={updateCurrentFilter}
        />
        <InputGroup.Append>
          <Button
            className="btn-filter-other-products"
            onClick={() => {
              props.updateFilter(currentFilter);
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
}
