import { keyAccessToken, keyUserId } from "../../../../../../assets/js/SessionStorageNames"
import axios from 'axios';
const sendProductWooAxios = (productId, plugin, entId, axiosCancelTokenSource) =>  {
    const res = axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/createProd`, {
        productId: productId,
        enterpriseId: entId,
        plugin: plugin,
    }, {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    }
    )
    return res;
}

export default sendProductWooAxios;