import { keyAccessToken, keyUserId } from "../../../../assets/js/SessionStorageNames";
import axios from 'axios';
const getProductAmazonScrap = async (entId, id, axiosCancelTokenSource) => {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/products/${id}`, {
        params: {
            pageNumber: 1,
            pageItemCount: 1,
            filter: {
                lang: 'es',
            },
        },
        cancelToken: axiosCancelTokenSource.token,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export default getProductAmazonScrap;