import React from 'react'
import { Spinner } from 'react-bootstrap'
import { ContValuesToSend, ListItemsToSend, ItemToSend, ContInputAndLabel, Text } from '../../../../../../../assets/css/components/admin/marketplace-to-marketplace/modals/ModalSelectStoreAndSection'
import PedidosYaStyle from '../../../../../../../assets/css/components/admin/plugins/PedidosYaStyle'

export default function SendData({ restaurantId, sectionId, hasError, load, success }) {

    return (
        <ContValuesToSend>
            {
                load ?
                    (
                        <PedidosYaStyle.contLoader>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Cargando...</span>
                            </Spinner>
                        </PedidosYaStyle.contLoader>
                    ) :
                    (
                        <ListItemsToSend>
                            {
                                hasError ? (
                                    <ItemToSend>
                                        <Text size='12pt'>
                                            {hasError}
                                        </Text>
                                    </ItemToSend>
                                ) :
                                success ?
                                (
                                    <ItemToSend>
                                        <Text size='12pt' fw="500">
                                            La descarga de productos fue exitosa!
                                        </Text>
                                    </ItemToSend>
                                ):
                                    (
                                        <>
                                            <ItemToSend>
                                                <ContInputAndLabel>
                                                    <Text size='12pt' fw="500">
                                                        Restaurante o Tienda
                                                    </Text>
                                                    <Text size='12pt'>
                                                        {`${restaurantId.storeId} - ${restaurantId.store}`}
                                                    </Text>
                                                </ContInputAndLabel>
                                            </ItemToSend>
                                            <ItemToSend>
                                                <ContInputAndLabel>
                                                    <Text size='12pt' fw="500">
                                                        Seccion o Categoria
                                                    </Text>
                                                    <Text size='12pt'>
                                                        {sectionId.name}
                                                    </Text>
                                                </ContInputAndLabel>
                                            </ItemToSend>
                                        </>
                                    )
                            }
                        </ListItemsToSend>
                    )
            }
        </ContValuesToSend>
    )
}
