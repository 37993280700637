import React, { useEffect, useState, useContext } from "react";
import MlStyled from "../../../../assets/css/components/admin/plugins/MlStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Container, Form } from "react-bootstrap";
import MlLogo from "../../../../assets/img/png/ml_logo.png";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  keyUserId,
  keyAccessToken,
  keyUserActiveEnterpriseId,
} from "../../../../assets/js/SessionStorageNames";
import { Redirect } from "react-router-dom";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import Routes from "../../../../Routes";
import verifyAccessRole from "../../../../assets/js/verifyAccessRole";

export default function AliExpressConf() {
  const globalContext = useContext(GlobalContext);

  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [redirect, setRedirect] = useState(null);

  
useEffect(() => {
  const hasAccess = verifyAccessRole(Routes, 46);
  if(!hasAccess) {
      setRedirect(
          <Redirect to={`/admin/dashboard`} />
      )
  }
},[]);

  /**
   * Handle the axios error
   * @param {Error} err The thrown error
   * @param {string} msg A message to show in the modal
   * @param {boolean} consoleLog If should log the error in the console
   */
  const handleAxiosError = (err, msg = null, consoleLog = true) => {
    if (axios.isCancel(err)) return; //Don't show axios cancel error
    if (msg) globalContext.showModalWithMsg(msg);
    if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
  };

  /**
   * Listener to obtain the AliExpress code
   */
  useEffect(() => {
    const msgEvent = (e) => {
      if (e.origin === `${process.env.REACT_APP_URL}` && e.data.code) {
        const entId = localStorage.getItem(keyUserActiveEnterpriseId);
        console.log(`Llego: ${e.data.code}`)
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/v1/aliexp-account/${entId}`,
            {
              code: e.data.code,
            },
            {
              headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
              },
            }
          )
          .then((res) => {
            setRedirect(
              <Redirect to="/admin/plugins/my-plugins/mercado-libre-success" />
            );
          })
          .catch((err) => {
            const msg =
              err.response && err.response.data
                ? err.response.data.message
                : undefined;
            globalContext.showModalWithMsg(
              msg ||
                "Disculpe ha ocurrido un error al intentar asociar su cuenta de Ali Express"
            );
            if (err.response) {
              console.log("Respuesta: ", err.response.data);
            }
            console.error("Error: ", err);
          });
      }
    };
    window.addEventListener("message", msgEvent);

    return () => {
      window.removeEventListener("message", msgEvent);
    };
  });

  /**
   * On ml add account link click
   */
  const onLinkClick = (e) => {
    e.preventDefault();
    const entId = localStorage.getItem(keyUserActiveEnterpriseId);

    if (!entId) {
      globalContext.showModalWithMsg("Debe seleccionar una empresa");
      return;
    }

    const appClientId = process.env.REACT_APP_ALIEXP_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_ALIEXP_REDIRECT_URI;

    window.open(
      `https://oauth.aliexpress.com/authorize?response_type=code&client_id=${appClientId}&redirect_uri=${redirectUri}&state=13&view=web&sp=ae`
    );
  };

  return (
    <MlStyled>
      {redirect}
      <div className="goBack d-flex justify-content-start mt-2">
        <Link to="/admin/plugins/my-plugins/my-menu-plugins">
          <button className="btn-back">
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        </Link>
      </div>
      <Container fluid>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex justify-content-center  align-items-center"
          >
            <Container className="bodyMlForm">
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="d-flex justify-content-center  align-items-center mb-2"
                >
                  <button className="btn-action" onClick={onLinkClick}>
                    Ir a AliExpress{" "}
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </MlStyled>
  );
}
