import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import CronReportsStyle from '../../../../assets/css/components/admin/cron-reports/CronReportsStyle';
import LinioCampaingStyle from '../../../../assets/css/components/admin/linio-campaign/LinioCampaingStyle'
import deleteCamp from '../functions/deleteCamp';
import getProducstCampaing from '../functions/getProducstCampaing';

export default function ProductsCampaing(props) {
    const { idCampaing, active, entId, axiosCancelTokenSource, setOpenModal, setIdCampaing, dataInit, setDataInit } = props;
    const [data, setData] = useState(null);
    const [isLoader, setIsLoader] = useState(true);
    const [msg, setMsg] = useState('');
    const [isDeleted, setIsDeleted] = useState(false);
    useEffect(() => {
        if (!idCampaing) return;
        if (!entId) return;
        getProducstCampaing(entId, axiosCancelTokenSource, idCampaing)
            .then(res => {
                console.log('d products campaing', res.data.data);
                if(res.data.data.length > 0) {

                    setIsLoader(false);
                    setData(res.data.data);
                }else {
                    setIsLoader(false);
                    setMsg('Esta Campaña no tiene productos asociados')
                }
            }).catch(err => {
                setIsLoader(false);
                setMsg(`algo mal ocurrio ${err}`)
                console.log(err)
            })
    }, [idCampaing, entId])

    const deleteAny = async (id) => {
        if (!idCampaing) {
            setMsg('Debe seleccionar una Campaña!')
        }
        setIsLoader(true);
        await deleteCamp(entId, id)
        .then(() => {
            const dataCopy = [...dataInit];
            const dataFin = dataCopy.filter(v => v._id !== id)
            setDataInit(dataFin);
            setIsLoader(false);
            setIsDeleted(true);
            setMsg('Esta Campaña fue eliminada!')
            setTimeout(() => {
                closeModal();
            }, 1000);
        }).catch(err => {
            setIsLoader(false);
            setIsDeleted(true);
            setMsg(`algo mal ocurrio ${err}`)
            setTimeout(() => {
                closeModal();
            }, 1000);
            console.log(err);
        })
    }

    const closeModal = () => {
        setOpenModal(false)
        setData(null);
        setIdCampaing(false);
        setIsDeleted(false);
    }
    return (
        <LinioCampaingStyle.ContModal active={active}>
            <LinioCampaingStyle.ModalCamp active={active}>
                <LinioCampaingStyle.HeaderModal>
                    <LinioCampaingStyle.Text size="13pt" fw="500">
                        Productos de la Campañas
                    </LinioCampaingStyle.Text>
                    <LinioCampaingStyle.BtnCloseModal onClick={() => closeModal()}>
                        <FontAwesomeIcon icon={faTimes} />
                    </LinioCampaingStyle.BtnCloseModal>
                </LinioCampaingStyle.HeaderModal>
                <LinioCampaingStyle.BodyModal>
                    {
                        isLoader ? (
                            <CronReportsStyle.contLoader>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Cargando...</span>
                                </Spinner>
                            </CronReportsStyle.contLoader>
                        ) : (
                            <LinioCampaingStyle.listProducts>

                                {
                                    data &&
                                    (data.length > 0) ?
                                    data.map(v => {
                                        return (
                                            <LinioCampaingStyle.itemList key={v._id}>
                                                {`${v.title} - ${v.attrib.sellerSku}`}
                                            </LinioCampaingStyle.itemList>
                                        )
                                    }) : (
                                        <LinioCampaingStyle.Text size="14pt" cl="#7367f0" fw="500" >
                                            {msg}
                                        </LinioCampaingStyle.Text>
                                    )
                                }

                            </LinioCampaingStyle.listProducts>
                        )}
                </LinioCampaingStyle.BodyModal>
                <LinioCampaingStyle.FooterModal onClick={() => deleteAny(idCampaing)}>
                    {
                        !isDeleted && (
                            <LinioCampaingStyle.BtnDeleteCampaing>
                                Eliminar
                            </LinioCampaingStyle.BtnDeleteCampaing>
                        )
                    }
                </LinioCampaingStyle.FooterModal>
            </LinioCampaingStyle.ModalCamp>
        </LinioCampaingStyle.ContModal>
    )
}
