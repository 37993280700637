import React, { useContext, useState } from 'react'
import LoginStyle from '../../assets/css/components/home/Login'
import { BiHide, BiShow, BiX } from 'react-icons/bi'
import {
    keyUserName,
    keyUserId,
    keyAccessToken,
    keyRefreshToken,
    keyUserRoles,
    userEmail,
    userRole
} from "../../assets/js/SessionStorageNames";
import axios from 'axios';
import { GlobalContext } from "../../contexts/GlobalContext";
import { Redirect } from "react-router-dom";

export default function Login() {
    const contextProvider = useContext(GlobalContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showBadge, setShowBadge] = useState(false);
    const [showErrorPassword, setShowErrorPassword] = useState(false);
    const [showErrorEmail, setShowErrorEmail] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [error, setError] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [redirect, setRedirect] = useState(null);

    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPass(!showPass)
    }

    const closeBadge = (e) => {
        e.preventDefault();
        setShowBadge(false);
    }

    const submitHandler = (e) => {
        e.preventDefault();
        if(email.length === 0) {
            setError("Debe agregar un correo para poder ingresar");
            setErrorEmail('Correo invalido');
            setShowBadge(true);
            setShowErrorEmail(true);
            return
        }
        if(password.length === 0) {
            setError("El campo de clave no puede estar vacio");
            setShowBadge(true);
            setShowErrorPassword(true)
            return
        }
        setIsLoad(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BACKEND_URL}/v1/login/`,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
            },
            data: {
                email: email.trim(),
                password: password.trim(),
            },
        })
            .then((res) => {
                setIsLoad(false);
                console.log('userData',res)
                let datos = {
                    name: res.data.data.user.name,
                    roles: {},
                    tokens: {
                        accessTokenKey: res.data.data.tokens.accessToken,
                        refreshTokenKey: res.data.data.tokens.refreshToken,
                    },
                    _id: res.data.data.user._id,
                };
                localStorage.setItem(keyUserName, res.data.data.user.name);
                localStorage.setItem(
                    keyAccessToken,
                    res.data.data.tokens.accessToken
                );
                
                localStorage.setItem(
                    keyRefreshToken,
                    res.data.data.tokens.refreshToken
                );
                localStorage.setItem(keyUserId, res.data.data.user._id);
                localStorage.setItem(userRole, res.data.data.user.rolRoute);
                const rolesUsuario = res.data.data.user.roles.map((r) => {
                    var re = "";
                    return re + r.code + ",";
                });
                localStorage.setItem(keyUserRoles, rolesUsuario.toString());
                localStorage.setItem(userEmail, res.data.data.user.email)
                contextProvider.setUserData(datos);
                setRedirect(<Redirect to="/admin" />);
            })
            .catch((err) => {
                setIsLoad(false);

                if (err.response) {
                    console.log('el error si hay response', err.response)
                    if (err.response.data) {
                        if (err.response.data.message === "User not registered") {
                            setError("La combinación de usuario y clave es errónea");
                            setShowBadge(true);
                        } else if (err.response.data.message === "Credential not set") {
                            setError("La combinación de usuario y clave es errónea");
                            setShowBadge(true);
                        } else if (
                            err.response.data.message === "Credential not set" ||
                            err.response.data.message === "Authentication failure"
                        ) {
                            setError("La combinación de usuario y clave son errónea");
                            setShowBadge(true);
                        }
                    } else {
                        setError("En estos momentos presentamos fallas internas!");
                        setShowBadge(true);
                    }
                } else {
                    setError("Error de comunicación, revise su conexión a internet");
                    setShowBadge(true);
                }
            });
    };

    return (
        <LoginStyle.Cont>
            {redirect}
            <LoginStyle.ContImgMessage>
                <LoginStyle.ContIconAndTitle>
                    <LoginStyle.ContLogo url="/logo.svg" />
                    <LoginStyle.Text cl="#7367F0" fw="500" size="18pt">
                        e-kommers.com
                    </LoginStyle.Text>
                </LoginStyle.ContIconAndTitle>
                <LoginStyle.ContImg url='/img-login.png' />
            </LoginStyle.ContImgMessage>
            <LoginStyle.ContForm>
                <LoginStyle.FormLogin>
                    <LoginStyle.Text style={{ alignSelf: 'flex-start' }} size="30px" cl="#5e5873" fw="500">
                        Bienvenidos a e-kommers.com!
                    </LoginStyle.Text>
                    <LoginStyle.Text style={{ alignSelf: 'flex-start' }} size="12pt">
                        Mejora la integración de tus tiendas en linea.
                    </LoginStyle.Text>
                    <LoginStyle.ContBadgeError active={showBadge}>
                        <LoginStyle.Text size=".875rem" cl="#FFFFFF">
                            {error}
                        </LoginStyle.Text>
                        <LoginStyle.BtnToggleBadge onClick={(e) => closeBadge(e)}>
                            <BiX />
                        </LoginStyle.BtnToggleBadge>
                    </LoginStyle.ContBadgeError>
                    <LoginStyle.ContInputAndLabel>
                        <LoginStyle.Text size=".875rem" cl="#5e5873">
                            Correo
                        </LoginStyle.Text>
                        <LoginStyle.Input placeholder="Nombre de usuario" onChange={(e) => setEmail(e.target.value)} />
                        {
                            showErrorEmail && (
                                <LoginStyle.Text size=".850rem" cl="#ea5455">
                                    {errorEmail}
                                </LoginStyle.Text>
                            )
                        }
                    </LoginStyle.ContInputAndLabel>
                    <LoginStyle.ContInputAndLabel>
                        <LoginStyle.Text size=".875rem" cl="#5e5873">
                            Clave
                        </LoginStyle.Text>
                        <LoginStyle.ContIconNadInputPass>
                            <LoginStyle.Input placeholder="Clave de acceso" type={showPass ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} />
                            <LoginStyle.BtnShowPass onClick={(e) => toggleShowPassword(e)}>
                                {
                                    showPass ? (
                                        <BiHide />
                                    ) : (
                                        <BiShow />
                                    )
                                }
                            </LoginStyle.BtnShowPass>
                        </LoginStyle.ContIconNadInputPass>
                        {
                            showErrorPassword && (
                                <LoginStyle.Text size=".850rem" cl="#ea5455">
                                    Debe agregar una clave de acceso!
                                </LoginStyle.Text>
                            )
                        }
                    </LoginStyle.ContInputAndLabel>
                    <LoginStyle.BtnSend disabled={isLoad} onClick={(e) => submitHandler(e)}>
                        {isLoad ? 'Verificando...': 'Iniciar'}
                    </LoginStyle.BtnSend>
                </LoginStyle.FormLogin>
            </LoginStyle.ContForm>
        </LoginStyle.Cont>
    )
}
