import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useContext, useRef, useEffect} from 'react'
import AnotherMenuCss from '../../../assets/css/components/admin/menus/AnotherMenuCss'
import { GlobalContext } from "../../../contexts/GlobalContext";
import LinksAnotherMenu from './LinkAnotherSidebar';
export default function AnotherMenu() {
    const globalContext = useContext(GlobalContext);
    const anotherMenuRef = useRef(null);
    const closeMenuSidebar = (e) => {
      e.preventDefault();
      globalContext.setStatusMenu(false)
    }
    function useOutsideAlerterMenu(refe) {
      useEffect(() => {
          if(globalContext.statusMenu) {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutsideMenu(event) {
            if (refe.current && !refe.current.contains(event.target)) {
              globalContext.setStatusMenu(false)
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutsideMenu);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutsideMenu);
          };
        }
        }, [refe, globalContext.statusMenu]);
      }
      useOutsideAlerterMenu(anotherMenuRef);

      
    return (
        <AnotherMenuCss.contSidebar active={globalContext.statusMenu}>
            <AnotherMenuCss.cont active={globalContext.statusMenu} ref={anotherMenuRef}>
            <AnotherMenuCss.header active={globalContext.statusMenu}>
                <p>E-KOMMERS.COM</p>
                <button onClick={(e) => closeMenuSidebar(e)}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </AnotherMenuCss.header>
            <LinksAnotherMenu />
        </AnotherMenuCss.cont>
        </AnotherMenuCss.contSidebar>
    )
}
