import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Routes from "../../Routes";
export default function Productos() {
  return (
    <Switch>
      {Routes.map((prop, key) => {
        if (prop.father === "/productos") {
          return (
            <Route
              path={prop.layout + prop.father + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/admin/productos" to="/admin/productos/menu-product" />
    </Switch>
  );
}
