import React, { useContext, useState, useEffect } from 'react'
import { BiRotateRight, BiSend, BiX } from 'react-icons/bi'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle'
import Select from '../../../../general/Select';
import { Spinner } from 'react-bootstrap';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import getAllRestaurant from '../functions/getAllRestaurant';
import linkSections from '../functions/linkSections';

export default function FormLinkSections() {
    const globalContext = useContext(GlobalContext);
    const [listRestaurants, setlistRestaurants] = useState([]);
    const [restaurant, setRestaurant] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [messageSucces, setMessageSucces] = useState('');


    useEffect(() => {
        if(!globalContext.linkSections) return;
        const entId = globalContext.currentEnterpriseId
        if (!entId) return;
        setIsLoading(true)
        getAllRestaurant(entId)
            .then(async (res) => {
                const datRes = res.data.data;
                let dataRes = [];
                for await (const restaurant of datRes) {
                    dataRes.push({
                        id: restaurant.storeId,
                        name: restaurant.store,
                    })
                }
                setlistRestaurants(dataRes);
                setIsLoading(false)
            })
            .catch(err => {
                console.log('el error res', err)
            })
    }, [globalContext.currentEnterpriseId, globalContext.linkSections]);

    const linkSectionsByRestaurant = () => {
        if (isSending) return;
        const entId = globalContext.currentEnterpriseId;
        if (!restaurant) return;
        if (!entId) return;
        setIsSending(true)
        setIsLoading(true);
        linkSections(restaurant, entId)
            .then(() => {
                setMessageSucces('Las secciones se sincronizaron con exito!')
                setIsSending(false);
                setIsLoading(false);
            }).catch(() => {
                setIsSending(false);
                setIsLoading(false);
                setMessageSucces('Las secciones no se pudieron sincronizar con exito!')
            })
    }

    useEffect(() => {
        if(!globalContext.linkSections) {
            setlistRestaurants([]);
            setRestaurant(null);
            setIsLoading(false);
            setIsSending(false);
            setMessageSucces('');

        }
    }, [globalContext.linkSections]);


    return (
        <PedidosYaStyle.BackgroundModal active={globalContext.linkSections}>
            <PedidosYaStyle.contModal w="400px" active={globalContext.linkSections}>
                <PedidosYaStyle.HeaderModal>
                    <PedidosYaStyle.Text size="14pt" fw="500">
                        Linkeo de Secciones
                    </PedidosYaStyle.Text>
                    <PedidosYaStyle.BtnCloseModal onClick={() => globalContext.setLinkSections(false)}>
                        <BiX />
                    </PedidosYaStyle.BtnCloseModal>
                </PedidosYaStyle.HeaderModal>
                <PedidosYaStyle.BodyModal>
                    {
                        !isLoading ?
                            messageSucces.length > 0 ?
                                (
                                    <PedidosYaStyle.ContInputAndLabel w="100%">
                                        <PedidosYaStyle.Text size="14pt" fw="400">
                                            {messageSucces}
                                        </PedidosYaStyle.Text>
                                    </PedidosYaStyle.ContInputAndLabel>
                                ) :
                                (
                                    <PedidosYaStyle.ContInputAndLabel w="100%">
                                        <PedidosYaStyle.Text size="13pt" fw="500">
                                            Restaurante
                                        </PedidosYaStyle.Text>
                                        <Select
                                            StatusDropDown={listRestaurants}
                                            setIdOption={setRestaurant}
                                        />
                                    </PedidosYaStyle.ContInputAndLabel>
                                ) :
                            (
                                <PedidosYaStyle.contLoader>
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Cargando...</span>
                                    </Spinner>
                                </PedidosYaStyle.contLoader>
                            )
                    }
                </PedidosYaStyle.BodyModal>
                <PedidosYaStyle.FooterModal>
                    {
                        messageSucces.length === 0 &&
                        (
                            <PedidosYaStyle.BtnAndIcon
                                disabled={!restaurant}
                                onClick={() => linkSectionsByRestaurant()}
                            >
                                {
                                    isSending ?
                                        (
                                            <PedidosYaStyle.ContIconBtnLoad>
                                                <BiRotateRight />
                                            </PedidosYaStyle.ContIconBtnLoad>
                                        ) :
                                        (
                                            <PedidosYaStyle.ContIconBtn
                                            >
                                                <BiSend />
                                            </PedidosYaStyle.ContIconBtn>
                                        )
                                }
                                <PedidosYaStyle.Text size="12pt" cl="#FFFFFF">
                                    {isSending ? 'Enviando..' : 'Procesar'}
                                </PedidosYaStyle.Text>
                            </PedidosYaStyle.BtnAndIcon>
                        )
                    }
                    
                </PedidosYaStyle.FooterModal>
            </PedidosYaStyle.contModal>
        </PedidosYaStyle.BackgroundModal>
    )
}
