import React from "react";
import Routes from "../../../Routes";
/**
 * * import Cards menu
 */
import CardsMenu from "../../../components/admin/menus/CardsMenu";

export default function PluginsMenu() {
  return <CardsMenu routes={Routes} father="/plugins" />;
}
