import React from 'react'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle';
import { BiHide, BiShow, BiX } from 'react-icons/bi'
import Select from '../../../../general/Select';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import { useState, useContext } from 'react';
import { getCredencials, sendCredencials } from '../functions/credentialsMethods';
import axios from "axios";
import { useEffect } from 'react';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';

export default function UsuariosWoo() {

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };


    /* 
    * states 
    */
    const [showBadge, setShowBadge] = useState(false);
    const [colorError, setColorError] = useState(true);
    const [error, setError] = useState('');
    const [showPass, setShowPass] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [url, setUrl] = useState(null);
    const [consumerKey, setConsumerKey] = useState(null);
    const [consumerSecret, setConsumerSecret] = useState(null);
    const [fileUser, setFileUser] = useState(null);
    const globalContext = useContext(GlobalContext)
    /* 
    * states  end       
    */

    useEffect(() => {
        const ent = globalContext.currentEnterpriseId;
        if (!ent) return;

        getCredencials(ent)
            .then((response) => {
                const data = response.data.data;
                console.log(data);
            }).catch((error) => {
                console.log('error al obtener las credenciales', error)
            })
    }, [globalContext])


    const closeBadge = (e) => {
        e.preventDefault();
        setShowBadge(false);
    }

    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPass(!showPass)
    }

    const sendCred = async () => {
        const enterpriseId = globalContext.currentEnterpriseId
        if (!enterpriseId) return;

        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };
        const headersMultipart = { ...headers };
        headersMultipart["Content-Type"] = "multipart/form-data";

        const formData = new FormData();
        formData.append("xlsx", fileUser);


        axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/v1/woo-users/send-users-woo/${enterpriseId}`,
            formData,
            {
                headers: headersMultipart,
            }
        ).then(res => {
            console.log(res);
            setColorError(false);
            setError('Proceso corriendo en segundo plano');
            setShowBadge(true);
            setIsLoad(false);
        }).catch(error => {
            setError('Verifique que todos los datos son correctos o si le falta algun valor!')
            setShowBadge(true);
            setIsLoad(false)
            handleAxiosError(error);
        })

    }




    console.log(fileUser);
    return (
        <>
            <PedidosYaStyle.ContBadgeError active={showBadge} error={colorError}>
                <PedidosYaStyle.Text size=".875rem" cl="#FFFFFF">
                    {error}
                </PedidosYaStyle.Text>
                <PedidosYaStyle.BtnToggleBadge onClick={(e) => closeBadge(e)}>
                    <BiX />
                </PedidosYaStyle.BtnToggleBadge>
            </PedidosYaStyle.ContBadgeError>
            <PedidosYaStyle.ContForm>
                <PedidosYaStyle.HeaderForm>
                    <PedidosYaStyle.Text size="14pt" fw="500" cl="#898989">
                        Seleccione su archivo de usuarios para enviarlos a woocommerce
                    </PedidosYaStyle.Text>
                    <PedidosYaStyle.Text size="12pt" fw="500" cl="#898989">
                    Columnas: nombre = A | correo = B | ciudad = C | calle = D | calle2 = E | telefono = F
                    </PedidosYaStyle.Text>
                </PedidosYaStyle.HeaderForm>
                <PedidosYaStyle.BodyForm>
                    <PedidosYaStyle.BodyFormGrid>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Archivo de usuarios
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.inputFile>
                                <label className="file" >
                                    <input type="file" aria-label="File browser example" onChange={(e) => setFileUser(e.target.files[0])} />
                                    <span className="file-custom"></span>
                                </label>
                            </PedidosYaStyle.inputFile>
                            {fileUser &&
                                (
                                    <PedidosYaStyle.Text size="12pt" fw="500" cl="#898989">
                                        {fileUser.name}
                                    </PedidosYaStyle.Text>
                                )
                            }
                        </PedidosYaStyle.ContInputAndLabel>

                    </PedidosYaStyle.BodyFormGrid>
                </PedidosYaStyle.BodyForm>
                <PedidosYaStyle.FooterForm>
                    {fileUser && (
                        <PedidosYaStyle.BtnSend disabled={isLoad} onClick={sendCred}>
                            {isLoad ? 'Enviando' : 'Enviar'}
                        </PedidosYaStyle.BtnSend>
                    )}
                </PedidosYaStyle.FooterForm>
            </PedidosYaStyle.ContForm>
        </>
    )
}
