import React, { useEffect, useState, useContext } from "react";
import { Form, Col, Row, Container, Button } from "react-bootstrap";
import { keyUserId, keyAccessToken } from "../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../contexts/GlobalContext";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Routes from "../../Routes";
import verifyAccessRole from "../../assets/js/verifyAccessRole";

export default function UploadProductsXlsx() {
	const globalContext = useContext(GlobalContext);
	const [inputs, setInputs] = useState({});
	const [brands, setBrands] = useState([]);
	const [errors, setErrors] = useState([]);
	const [dataLinked, setDataLinked] = useState(null);
	const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
	const [redirect, setRedirect] = useState(null);

	useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 64);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);

	/**
	 * Handle the axios error
	 * @param {Error} err The thrown error
	 * @param {string} msg A message to show in the modal
	 * @param {boolean} consoleLog If should log the error in the console
	 */
	const handleAxiosError = (err, msg = null, consoleLog = true) => {
		if (axios.isCancel(err)) return; //Don't show axios cancel error

		if (err.response && err.response.data) {
			if (err.response.data.message) {
				globalContext.showModalWithMsg(err.response.data.message);
			} else {
				globalContext.showModalWithMsg(err.response.data);
			}
		} else if (msg) {
			globalContext.showModalWithMsg(msg);
		}

		if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
	};

	const updateInputState = (e) => {
		const name = e.target.name;
		const newState = Object.assign({}, inputs);

		switch (e.target.type) {
			case "checkbox":
				newState[name] = e.target.checked;
				break;

			case "file":
				newState[name] = e.target.files[0];
				break;

			default:
				newState[name] = e.target.value;
				break;
		}

		setInputs(newState);
	};



	// //added by jet
	// const getXlsx = () => {
	// 	const entId = globalContext.currentEnterpriseId;
	// 	const officialStoreId = inputs.officialStoreId;
	// 	if (!entId) {
	// 		globalContext.showModalWithMsg("La empresa...");
	// 		return;
	// 	} 
		
	// 	const processCheckIntervalTime = 100;
	// 	let processModitorId = 0;
	// 	const headers = {
	// 		"x-api-key": process.env.REACT_APP_API_KEY,
	// 		"x-access-token": localStorage.getItem(keyAccessToken),
	// 		"x-user-id": localStorage.getItem(keyUserId),
	// 	};
	// 	axios
	// 		.post(
	// 			`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/downMlProd/xlsx`,
	// 			{
	// 				officialStoreId: officialStoreId,
	// 			},
	// 			{
	// 				headers: headers,
	// 			}
	// 		)
	// 		.then((resp) => {
	// 			const data = resp.data.data;

	// 			if (data.monitor) {
	// 				processModitorId = data.monitor;

	// 				//If the process ID was received, start monitoring
	// 				let waitingMonitorResponse = false;
	// 				const interval = setInterval(() => {
	// 					if (!waitingMonitorResponse) {
	// 						waitingMonitorResponse = true;

	// 						axios
	// 							.get(
	// 								`${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
	// 								{
	// 									cancelToken: axiosCancelTokenSource.token,
	// 									headers,
	// 								}
	// 							)
	// 							.then((resp) => {
	// 								const data = resp.data.data;

	// 								clearInterval(interval);
	// 								globalContext.setLoaderPage(false);
	// 								setErrors(data.data.errors);
	// 								globalContext.showModalWithMsg("Proceso finalizado");
	// 							})
	// 							.catch((err) => {
	// 								handleAxiosError(
	// 									err,
	// 									"Disculpe, no se pudo culminar la descarga de los productos."
	// 								);
	// 								clearInterval(interval);
	// 								globalContext.setLoaderPage(false);
	// 							})
	// 							.finally(() => {
	// 								waitingMonitorResponse = false;
	// 							});
	// 					}
	// 				}, processCheckIntervalTime);
	// 			} else {
	// 				globalContext.setLoaderPage(false);
	// 			}
	// 		})
	// 		.catch((err) => handleAxiosError(err));
	// };
	//mielda loco

	const sendXlsx = () => {
		const entId = globalContext.currentEnterpriseId;
		const officialStoreId = inputs.officialStoreId;
		const xlsx = inputs.xlsx;

		if (!entId) {
			globalContext.showModalWithMsg("La empresa...");
			return;
		} else if (!xlsx) {
			globalContext.showModalWithMsg("El archivo...");
			return;
		} 	
		const headers = {
			"x-api-key": process.env.REACT_APP_API_KEY,
			"x-access-token": localStorage.getItem(keyAccessToken),
			"x-user-id": localStorage.getItem(keyUserId),
		};
		const headersMultipart = { ...headers };
		headersMultipart["Content-Type"] = "multipart/form-data";

		const formData = new FormData();
		formData.append("xlsx", xlsx);
		if (officialStoreId) formData.append("officialStoreId", officialStoreId);

		globalContext.setLoaderPage(true);

		axios.post(
				`${process.env.REACT_APP_BACKEND_URL_PEYA}/v1/peya-sdk/${entId}/load-sections-to-send-pedidosya`,
				formData,
				{
					headers: headersMultipart,
				}
			).then(res => {
				console.log('data linked', res.data.data);
				const data = res.data.data;
				setErrors(data.bads);
				setDataLinked(data.done);
				globalContext.setLoaderPage(false);
			}).catch(error => {
				console.log('el error', error);
				globalContext.setLoaderPage(false);
				handleAxiosError(error);
			})
			
	};

	return (
		<Container>
			{redirect}
			<Form className="mt-3">
				<Row className="mt-2">
					<Col md={12}>
						<Form.Label>
							Seleccione el archivo XLSX con los productos a vincular. La hoja
							debe tener las siguientes columnas en el orden especificado:
							<br />
							<b>id_local</b> | <b>id_peya</b> |{" "}
							<b>Seccion</b> | <b>restaurant</b> |{" "}
						</Form.Label>
					</Col>
				</Row>
				<Row className="mt-1">
					<Col md={6}>
						<Form.File name="xlsx" onChange={updateInputState} />
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<Button onClick={sendXlsx}>Enviar</Button>
					</Col>
				</Row>
			</Form>
			{errors && errors.length > 0 && (
				<div className="mt-4 text-justify">
					Proceso culminado con los siguientes errores:
					<br />
					{errors.map((err, idx) => (
						<span key={idx}>
							<br />
								Error: {err}
						</span>
					))}
				</div>
			)}
		</Container>
	);
}
