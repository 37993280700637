import React, { useState } from 'react'
import QuestionsListStyle from '../../../../assets/css/components/admin/ml-questions/QuestionsListStyle'
import CardQuestion from './CardQuestion';
import { Spinner } from 'react-bootstrap';
import Pager from "../../../general/Pager";
export default function ListQuestions(props) {
    const [itemActive, setItemActive] = useState('')
    const {
        refList, data, isActiva, 
        isLoader, setShowList, setProductIdSearch, 
        setDataQuestionSelected, setIndexChange,
        currentPage, pageItemCount, setCurrentPage, totalRecords
    } = props;
    return (
        <QuestionsListStyle.listQuestions ref={refList} activeList={isActiva}>
            {
                isLoader ?
                (
                    <QuestionsListStyle.contLoader>
                        <Spinner animation="border" role="status" size="md">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </QuestionsListStyle.contLoader>
                )
                :
                data.length > 0 &&
                data.map((v, i) => {
                    return (
                        <CardQuestion
                            key={v._id}
                            data={v}
                            setShowList={setShowList}
                            itemActive={itemActive}
                            setItemActive={setItemActive}
                            setProductIdSearch={setProductIdSearch}
                            setDataQuestionSelected={setDataQuestionSelected}
                            setIndexChange={setIndexChange}
                            index={i}
                        />
                    )
                })
            }
            <QuestionsListStyle.footerPaginator>
                <Pager
                    handleSetPage={setCurrentPage}
                    totalResults={totalRecords}
                    currentPage={currentPage}
                    resultsPerPage={pageItemCount}
                />
            </QuestionsListStyle.footerPaginator>
        </QuestionsListStyle.listQuestions>
    )
}
