import React from 'react'
import { BiX } from 'react-icons/bi'
import { BtnCloseMenu, ContMenuCard, ContTitleMenu, ListOptions, OptionList } from '../../../../../../../assets/css/components/admin/marketplace-to-marketplace/cardItem/CardItemStyle'
import { Text } from '../../../../../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts'
export default function MenuCard({ active, setActive, data }) {
    const closeMenu = () => {
        setActive(false);
    }

    return (
        <ContMenuCard active={active}>
            <BtnCloseMenu onClick={() => closeMenu()}>
                <BiX />
            </BtnCloseMenu>
            <ContTitleMenu>
                <Text size="12pt" fw="500">
                    Opciones
                </Text>
            </ContTitleMenu>
            <ListOptions>
                <OptionList>
                    <Text size="12pt" fw="500">
                        Detalle del Producto
                    </Text>
                </OptionList>
            </ListOptions>
        </ContMenuCard>
    )
}
