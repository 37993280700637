import React, { useEffect } from 'react'
import { useState } from 'react'
import ScrapingUrlStyle from '../../../../../../assets/css/components/admin/products/amazon/ScrapingUrlStyle'
import ListTableTbody from '../components/ListTableTbody'
import ListTableThead from '../components/ListTableThead'
import transformStringToArray from '../functions/transformStringToArray'
import axios from 'axios';
import { GlobalContext } from '../../../../../../contexts/GlobalContext';
import { useContext } from 'react'
import runScrape from '../functions/runScraper'
import { Redirect } from "react-router-dom";
import Routes from '../../../../../../Routes'
import verifyAccessRole from '../../../../../../assets/js/verifyAccessRole'

export default function ScrapingUrl() {
    const globalContext = useContext(GlobalContext);
    const [urlsString, setUrlsSting] = useState('');
    const [loader, setLoader] = useState(false);
    const [urlsArry, setUrlsArray] = useState([]);
    const [globalLimit, setGlobalLimit] = useState(0);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 33);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);
    const entId = globalContext.currentEnterpriseId;
    return (
        <ScrapingUrlStyle.Cont>
            {redirect}
            <ScrapingUrlStyle.ContForm>
                <ScrapingUrlStyle.HeaderForm>
                    <ScrapingUrlStyle.Text size="14pt" fw="500">
                        Coloque la lista de URls separadas por ","
                    </ScrapingUrlStyle.Text>
                </ScrapingUrlStyle.HeaderForm>
                <ScrapingUrlStyle.BodyForm>
                    <ScrapingUrlStyle.ContInputAndLabel>
                        <ScrapingUrlStyle.Text size="12pt">
                            URLs
                        </ScrapingUrlStyle.Text>
                        <ScrapingUrlStyle.TextArea placeholder="Coloque aqui sus URLs" onChange={(e) => setUrlsSting(e.target.value)} />
                    </ScrapingUrlStyle.ContInputAndLabel>
                    <ScrapingUrlStyle.ContInputAndLabel>
                        <ScrapingUrlStyle.Text size="12pt">
                            Limite Global
                        </ScrapingUrlStyle.Text>
                        <ScrapingUrlStyle.Input marginTop="10px" placeholder="Coloque aqui su limite global" onChange={(e) => setGlobalLimit(e.target.value)}/>
                    </ScrapingUrlStyle.ContInputAndLabel>
                </ScrapingUrlStyle.BodyForm>
                <ScrapingUrlStyle.FooterForm>
                    <ScrapingUrlStyle.BtnSend onClick={() => transformStringToArray(urlsString,
setUrlsArray, globalLimit, axiosCancelTokenSource, entId)}>
                        Procesar
                    </ScrapingUrlStyle.BtnSend>
                </ScrapingUrlStyle.FooterForm>
            </ScrapingUrlStyle.ContForm>
            <ScrapingUrlStyle.ContTable>
                <ScrapingUrlStyle.HeaderForm marginBottom="1rem">
                    <ScrapingUrlStyle.Text size="14pt" fw="500">
                        Listado de URLs a scrapear
                    </ScrapingUrlStyle.Text>
                    {
                        urlsArry.length > 0 && (
                            <ScrapingUrlStyle.BtnSend disabled={loader} width="150px" onClick={() => runScrape(urlsArry,setUrlsArray, axiosCancelTokenSource, entId, setLoader)}>
                                Ejecutar Scraping
                            </ScrapingUrlStyle.BtnSend>
                        )
                    }
                </ScrapingUrlStyle.HeaderForm>
                <ScrapingUrlStyle.TableResultScraping>
                    <ListTableThead />
                    <ListTableTbody data={urlsArry} />
                </ScrapingUrlStyle.TableResultScraping>
            </ScrapingUrlStyle.ContTable>
        </ScrapingUrlStyle.Cont>
    )
}
