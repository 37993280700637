import styled from "styled-components";

export const ContLinkCategories = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: flex-start;
min-height: 50vh;
`;

export const ContListcategories = styled.div.attrs(props => ({
    w: props.w || '500px',
    mh: props.mh || 'auto',
    h: props.h || 'auto'
}))`
width: ${props => props.w};
min-height: 300px;
height: ${props => props.h};
max-height: ${props => props.mh};
background: #FFFFFF;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
border-radius: 0.428rem;
`;
export const HeaderListCategories = styled.div`
width: 100%;
min-height: 60px;
background: #FFFFFF;
border-bottom: 1px solid #A9A9A9;
border-radius: 0.428rem 0.428rem 0px 0px;
padding: 1rem;
position: relative;
`;

export const ContBtnActons = styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
margin-bottom: 1rem;
`;

export const BtnAction = styled.button`
width: auto;
height: auto;
padding: .3rem;
border-radius: .325rem;
background: #7367F0;
color: #FFFFFF;
font-size: 12pt;
font-weight: bold;
outline: none !important;
border: none;
`;

export const BtnLinkCat = styled.button.attrs(props => ({
    active: props.active || false
}))`
width: 35px;
height: 35px;
border-radius: 50px;
display: flex;
justify-content: center;
align-items: center;
background: #FFFFFF;
outline: none !important;
border: 1px solid #7367F0;
color: #7367F0;
transition: all ease-in .2s;
&:hover {
    background: #7367F0;
    color: #FFFFFF;
}
position: absolute;
top: 1rem;
bottom: auto;
left: auto;
right: 1rem;

`;

export const BodyListCategories = styled.div.attrs(props => ({
    maxH: props.maxH || 'auto',
    h: props.h || '100%'
}))`
width: 100%;
min-height: 300px;
height: ${props => props.h};
max-height: ${props => props.maxH};
padding: .5rem;
overflow-y: auto;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
`;

export const FooterListCategories = styled.div`
width: 100%;
height: auto;
background: #FFFFFF;
border-top: 1px solid #A9A9A9;
border-radius: 0px 0px 0.428rem 0.428rem;
padding: 1rem;
`;

export const ListCategories = styled.ul`
width: 100%;
list-style: none;
padding-left: 0px;
`

export const ItemListCategories = styled.li`
width: 100%;
height: auto;
margin-top: .5rem;
`

export const ContLabelItem = styled.div.attrs(props => ({
    bgc: props.active ? '#8076E8' : '#929292',
}))`
color: #FFFFFF;

font-size: 10pt;
width: 100%;
height: auto;
transition: all ease-in-out .2s;
display: flex;
border-radius: 0.428rem;
justify-content: space-between;
align-items: center;
padding: .3rem;
background: ${props => props.bgc};
cursor: pointer;
`;

export const ContLabelItemLinked = styled.li.attrs(props => ({
    bgc: props.active ? '#8076E8' : '#929292',
}))`
color: #FFFFFF;
font-size: 10pt;
width: auto;
height: 30px;
transition: all ease-in-out .2s;
display: flex;
border-radius: 0.428rem;
justify-content: space-between;
align-items: center;
padding: .3rem;
background: #52c5a9;
cursor: pointer;
`;
export const ContIconOpenSubList = styled.div.attrs(props => ({
    bgc: props.active ? '#FFFFFF' : '#929292',
    cl: props.active ? '#8076E8' : '#FFFFFF',
    rotate: props.active ? '180deg' : '0'
}))`
transition: all ease-in-out .2s;
width: 20px;
height: 20px;
border-radius: 50px;
display: flex;
justify-content: center;
align-items: center;
color: #FFFFFF;
transform: ${props => `rotateZ(${props.rotate})`};
.spinner-border {
    width: 10px !important;
    height: 10px !important;
    border-width: .2rem !important;
}
`;

export const SubListCategories = styled.ul`
width: 100%;
list-style: none;
padding-top: .5rem;
padding-bottom: .5rem;
padding-left: .5rem;
border-bottom: 1px solid black;
border-left: 1px solid black;
box-sizing: border-box;
background: #FFFFFF;
border-width: 0px 0px 1px 1px;
border-style: dashed;
border-color: #000000;
`

export const ContbtnAddCategoryToLink = styled.div.attrs(props => ({
    justify: props.j || 'flex-end'
}))`
width: 100%;
display: flex;
justify-content: ${props => props.justify};
align-items: center;
`;

export const ContbtnAddCategoryToLink2 = styled.div.attrs(props => ({
    justify: props.j || 'flex-end'
}))`
width: 100%;
height: auto;
flex-wrap: wrap;
display: flex;
justify-content: ${props => props.j};
align-items: center;
`;

export const BtnAddToList = styled.button`
width: auto;
padding: .5rem;
border-radius: 0.428rem;
background: #52c5a9;
color: #FFFFFF;
font-size: 10pt;
font-weight: bold;
border: none;
outline: none !important;
`;

export const BtnAddToDelete = styled.button`
width: auto;
width: 30px;
height: 30px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50px;
background: #52c5a9;
color: #FFFFFF;
font-size: 10pt;
font-weight: bold;
border: none;
margin-top: 0.5rem;
outline: none !important;
margin-bottom: 10px;
`;

export const ContItemCategoriesToLinkMl = styled.div`
width: 100%;
height: auto;
display: flex;
flex-direction: column;
justify-content: center;
padding-top: 10px;
padding-bottom: 10px;
align-items: center;
border-bottom: 1px solid #A9A9A9;
`;