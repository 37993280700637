import styled from 'styled-components';

const cont = styled.div`
position: relative;
top: 0;
right:0;
bottom:0;
left:0;
width: 100%;
height:100%;
display:flex;
justify-content:center;
align-items:center;
padding:10px;
overflow-y: auto;
height: auto;
@media (max-width: 990px){
    position: relative;
}
`
const contForm = styled.div`
margin-left: ${props=> props.ml || '60px'};
background: #FFF;
min-width:300px;
width: ${props=> props.w || '1000px'};
border-radius:5px;
box-shadow: ${props=> props.shadow || "0px 3px 6px rgba(0, 0, 0, 0.16)"};
margin-bottom:10px;
display:flex;
flex-direction: column;
justify-content:space-between;
align-items:center;
margin-top: ${props=> props.mt || '5px'};
@media (max-width: 990px) {
    margin-left:0;
}
`;

const header = styled.div`
height: 60px;
width:100%;
padding:10px;
display:flex;
justify-content:flex-start;
align-items:center;
font-size:15pt;
color:#707070;
margin-top:0;
`;
const body = styled.div`
height: auto;
width:100%;
padding:10px;
display:flex;
flex-direction:column;
justify-content:space-between;
align-items:center;
`;
const footer = styled.div`
height: 60px;
width:100%;
padding:10px;
display:flex;
justify-content:space-between;
align-items:center;
margin-top:0;
`;

const btn = styled.button`
background: #7367f0;
padding: 8px 16px;
color:#FFF;
font-size: 12px;
border-radius:5px;
border: none;
outline:none !important;
@media (min-width: 300px) {
    margin-top:10px;
    margin-left:${props=> props.ml || '0'};
}
@media (min-width: 900px) {
    margin-left:${props=> props.ml || '0'};
    margin-right:${props=> props.mr || '0'};
}
`
const input = styled.input`
width: 100%;
height: 40px;
border-radius: 5px;
padding-left:${props=> props.pl || '10px'};
background: #fff;
border: 0.5px solid  rgba(112, 112, 112, 0.44);
outline:none;
color: rgb(112, 112, 112);
::placeholder{
    font-family: "Berlin Rounded";
    font-weight: 300;
    font-size: 12px;
    color: rgba(112, 112, 112, 0.44);
}
`;
const textArea = styled.textarea`
width: 100%;
height: 500px;
border-radius: 5px;
padding:${props=> props.pl || '10px'};
background: #fff;
border: 0.5px solid  rgba(112, 112, 112, 0.44);
outline:none;
color: rgb(112, 112, 112);
resize:none;
::placeholder{
    font-family: "Berlin Rounded";
    font-weight: 300;
    font-size: 12px;
    color: rgba(112, 112, 112, 0.44);
}
`;
const contLabelInput = styled.div`
width: auto;
height: 40px;
border-radius: 5px;
padding: 0 10px;
background: #F5F5F6;
color: rgb(112, 112, 112);
font-size:12pt;
display:flex;
justify-content:center;
align-items:center;
position: absolute;
border: 0.5px solid  rgba(112, 112, 112, 0.44);
`;

const contGrid = styled.div`
width:100%;
max-width:100%;
display: grid;
grid-gap: 0.5rem;
grid-template-columns: repeat(auto-fit, minmax(50px, auto));
@media (min-width: 300px) {
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(50px, 100%));
}
@media (min-width: 900px) {
    justify-content:flex-start  ;
    grid-template-columns: repeat(auto-fit, minmax(50px, ${props=> props.gtcr || '1fr'}));
}
@media (min-width: 1044px) {
    justify-content:flex-start  ;
    grid-template-columns: repeat(auto-fit, minmax(50px, ${props=> props.gtcrt || '1fr'}));
}
`;

const contInputs = styled.div`
text-align: left;
color:#707070;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
width:100%;
padding:5px;
p {
    margin-bottom:0;
}
`;

const contInputsTwo = styled.div`
display:flex;
justify-content:flex-start;
align-items:center;
overflow: hidden;
width:100%;
`;
const subTitle = styled.div`
width:100%;
text-align:left;
font-size:13pt;
color:#707070;
font-weight:500;
`;
const FormPlantillaCss = {
    cont,
    contForm,
    header,
    body,
    footer,
    btn,
    input,
    contGrid,
    contInputs,
    contLabelInput,
    contInputsTwo,
    textArea,
    subTitle
}
export default FormPlantillaCss;