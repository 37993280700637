import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';

const getDataPyByExcel = (
    entId,
    ) => {
    const res = axios.get(
        `${process.env.REACT_APP_BACKEND_URL_PEYA}/v1/peya-sdk/${entId}/load-products-to-send-pedidosya`,
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(
                    keyAccessToken
                ),
                "x-user-id": localStorage.getItem(keyUserId),
            }
        }
    )
    return res;
}

export default getDataPyByExcel;