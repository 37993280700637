import styled from 'styled-components';

export const BackgroundModal = styled.div.attrs(props => ({
    d: props.active ? 'flex' : 'none'
}))`
width: 100%;
display:  ${props => props.d};
justify-content: center;
align-items: center;
padding: 10px;
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
height: 100%;
transition: display .3s ease;
background-color: rgb(251 251 251 / 64%);
z-index: 999;
`;

export const ContModal = styled.div.attrs(props => ({
    bg: props.bg || '#FFFFFF',
    w: props.w || 'auto',
    t: props.active ? 'translateY(0px)' : 'translateY(146px)',
    o: props.active ? '1' : '0'
}))`
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
background: ${props => props.bg};
border-radius: .425rem;
min-width: 300px;
width: ${props => props.w};
transition: transform .9s ease-out, opacity 1s ease-in-out;
min-height: 300px;
height: 100%;
transform: ${props => props.t};
opacity: ${props => props.o};
z-index: 999;
max-width: 1000px;
margin-top: auto;
margin-bottom: auto;
margin-left: auto;
margin-right: auto;
`;

export const HeaderModal = styled.div`
width: 100%;
height: 50px;
border-bottom: 1px solid #ebe9f1;
position: relative;
display: flex;
justify-content: flex-start;
align-items: center;
padding-left: 10px;
`;

export const FooterModal = styled.div`
width: 100%;
height: 50px;
border-top: 1px solid #ebe9f1;
position: relative;
display: flex;
justify-content: flex-end;
align-items: center;
padding-right: 10px;
margin-top: auto;
margin-bottom: 0;
`;

export const BodyModal = styled.div`
width: 100%;
padding: 10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
min-height: 200px;
`;

export const BtnCloseModal = styled.button`
width: 30px;
height: 30px;
border: none;
border-top-right-radius: .425rem;
border-bottom-left-radius: .425rem;
outline: none !important;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
color: #FFFFFF;
background: #7367F0;
position: absolute;
left: auto;
right: 0;
top: 0;
bottom: auto;
`;

export const BtnGeneral = styled.button.attrs(props => ({
    bgc: props.bgc || '#7367F0',
    l: props.left || 'auto',
    r: props.right || '10px',
    t: props.top || 'auto',
    b: props.bottom || 'auto',
    c: props.color || "#FFFFFF"
}))`
width: 100px;
height: 38px;
border: none;
border-radius: .425rem;
outline: none !important;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
color: ${props => props.c};
background: ${props => props.bgc};
position: absolute;
left: ${props => props.l};
right: ${props => props.r};
top: ${props => props.t};
bottom: ${props => props.b};
`;

export const Text = styled.p.attrs(props => ({
    cl: props.cl || '#707070',
    size: props.size || '.857rem',
    ml: props.ml || '0',
    mr: props.mr || '0',
    fw: props.fw || 'lighter',
    mt: props.mt || '.2857rem',
    mb: props.mb || '.2857rem',
    longText: props.longText ? 'ellipsis' : '',
    longTextTwo: props.longText ? 'hidden' : '',
    longTextThree: props.longText ? 'nowrap' : '',
})) `
color: ${props => props.cl };
font-size: ${props => props.size };
margin-left: ${props => props.ml };
margin-right: ${props => props.mr };
font-weight: ${props => props.fw };
margin-top: ${props => props.mt };
margin-bottom: ${props => props.mb };
text-overflow:${props => props.longText };
overflow:${props => props.longTextTwo };
white-space:${props => props.longTextThree };
`;


export const ContInputAndLabel = styled.div.attrs(props => ({
    w: props.w || '300px',
    fld: props.fld || 'column',
    jsc: props.jsc || 'space-between',
    ali: props.ali || 'flex-start',
    mr: props.mr || 0
}))`
height:auto;
display:flex;
width:${props=> props.w};
flex-direction:${props => props.fld};
justify-content: ${props => props.jsc};
align-items:${props => props.ali};
margin-right: ${props=> props.mr};
`

export const ContValuesToSend = styled.div`
width: 100%;
padding: 10px;
margin-top: auto;
margin-bottom: auto;
margin-left: auto;
margin-right: auto;
`

export const ListItemsToSend = styled.ul`
width: 100%;
list-style: none;
padding-left: 0;
`
export const ItemToSend = styled.li`
width: 100%;
height: auto;
background: #FFFFFF;
padding: 10px;
border: 1px solid #ebe9f1;
`