import React from 'react'
import CronReportsStyle from '../../../../assets/css/components/admin/cron-reports/CronReportsStyle'

export default function ListItems(props) {
    const {data} = props;
    return (
            
            data.length > 0 &&
            data.map((v,i) => {
                return (
                    <CronReportsStyle.ContListItems key={i}>
                        <CronReportsStyle.ContInputAndLabel mr="10px">
                            <CronReportsStyle.Text fw="500" size="12pt">
                                Usuario
                            </CronReportsStyle.Text>
                            <CronReportsStyle.Text>
                                {v.user}
                            </CronReportsStyle.Text>
                        </CronReportsStyle.ContInputAndLabel>

                        <CronReportsStyle.ContInputAndLabel mr="10px">
                            <CronReportsStyle.Text fw="500" size="12pt">
                                Empresa
                            </CronReportsStyle.Text>
                            <CronReportsStyle.Text>
                                {v.enterprise}
                            </CronReportsStyle.Text>
                        </CronReportsStyle.ContInputAndLabel>

                        <CronReportsStyle.ContInputAndLabel mr="10px">
                            <CronReportsStyle.Text fw="500" size="12pt">
                                Total de productos publicados
                            </CronReportsStyle.Text>
                            <CronReportsStyle.Text>
                                {v.count}
                            </CronReportsStyle.Text>
                        </CronReportsStyle.ContInputAndLabel>
                    </CronReportsStyle.ContListItems>
                )
            })
    )
}
